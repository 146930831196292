// @flow
import { useViewResource, } from '../../hoc/ViewResource';
import { FirewallPolicyRules } from './edit/FirewallPolicyRules';
import { useAccessControlResourcesById } from "../../hoc/AccessControl";

import type { BbFirewallPolicy,  } from '../../../api/type.fwp';
import type { ResourceCacheStatus } from '../../../state/resource/type';

type FirewallPolicyViewProps = {
    +id: string,
    +dest: string,
    +onDeleteFirewallRule: (id: string) => void,
}

export const FirewallPolicyView = ({ id, dest, onDeleteFirewallRule }: FirewallPolicyViewProps): React$Node => {
    const resource = useViewResource<BbFirewallPolicy, BbFirewallPolicy>('firewall_policy', id);
    const { item: firewallPolicy, } = resource;
    const accessResources = useAccessControlResourcesById();

    return (
        <>
            <FirewallPolicyRules
                groupName={dest}
                cacheStatus={resource.status}
                firewallRules={firewallPolicy?.rules || []}
                direction='in'
                accessResources={accessResources}
                onDeleteFirewallRule={onDeleteFirewallRule}
            />
            <FirewallPolicyRules
                groupName={dest}
                cacheStatus={resource.status}
                firewallRules={firewallPolicy?.rules || []}
                direction='out'
                accessResources={accessResources}
                onDeleteFirewallRule={onDeleteFirewallRule}
            />
        </>
    );
};

export const NoFirewallPolicyView = ({ dest, cacheStatus }: { dest: string, cacheStatus: ResourceCacheStatus }): React$Node => {
    return (
        <>
            <FirewallPolicyRules
                groupName={dest}
                cacheStatus={cacheStatus}
                firewallRules={[]}
                direction='in'
            />
            <FirewallPolicyRules
                groupName={dest}
                cacheStatus={cacheStatus}
                firewallRules={[]}
                direction='out'
            />
        </>
    );

}