// @flow

import { parseIsoDate, parseHttpDate } from '../lib/date';

import type {
    OrbitContainerMeta,
    OrbitContainerHeaders,
    BbOrbitContainer,
    BbRawApiOrbitEntry,
    OrbitObject,
    BbRawApiOrbitObject,
    ContainerAccess,
    OrbitAccountMeta
} from './type.orbit';
import { orbitUrl, containerRegistryNoScheme, containerRegistryUrl } from './url';
import { getObjectId, getSubdirObjectId } from './type.orbit';

type BbApiRawOrbitContainer = {
    ...$Diff<$Shape<BbOrbitContainer>, { id: string }>,
    last_modified: string,
}

export const adaptOrbitContainer = (raw: BbApiRawOrbitContainer): BbOrbitContainer => {
    const { last_modified, ...rest } = raw;

    return {
        ...rest,
        id: rest.name,
        last_modified: parseIsoDate(last_modified) ||
            parseHttpDate(last_modified) ||
            new Date('1970-01-01 00:00'),
    };
};

const acctAccess = /^(acc-[a-z0-9]{5}):(cli-[a-z0-9]{5})$/;
const referrerAccess = /^.r:(-?)(.+)$/;

function parseOrbitAccesses(entries: $ReadOnlyArray<string>, type: 'read' | 'write', result: OrbitContainerMeta) {
    let addedAnyReferrer = false;

    return entries.forEach(value => {
        const cliMatch = value.match(acctAccess);
        const referrerMatch = value.match(referrerAccess);

        if (cliMatch) {
            const existingIndex = result.access.findIndex(x => x.type === 'api' && x.id === cliMatch[0]);
            if (existingIndex === -1) {
                result.access.push(({
                    id: cliMatch[0],
                    type: 'api',
                    accountId: cliMatch[1],
                    cliId: cliMatch[2],
                    read: false,
                    write: false,
                    // $FlowFixMe this spread prop stuff.
                    [type]: true,
                }: ContainerAccess));
            } else {
                // $FlowFixMe existingIndex must refer to an 'api' entry, so [type] is fine.
                result.access[existingIndex][type] = true;
            }
        } else if ((value === '.r:*' || value === '.r:-*' || value === '*:*') && type === 'read') {
            if (!addedAnyReferrer) {
                result.access.push({
                    id: 'any-referrer',
                    type: 'any-referrer',
                    read: (value === '.r:*' || value === '*:*'),
                });
                addedAnyReferrer = true;
            }

        } else if (referrerMatch && type === 'read') {
            result.access.push({
                id: referrerMatch[2],
                type: 'http',
                referrer: referrerMatch[2],
                read: (referrerMatch[1] === ''),
            });
        } else if (value === '.rlistings') {
            result.rlistings[type] = true;
        } else {
            const existingIndex = result.access.findIndex(x => x.type === 'other' && x.id === value);
            if (existingIndex === -1) {
                result.access.push({
                    id: value,
                    type: 'other',
                    read: false,
                    write: false,
                    // $FlowFixMe this spread prop stuff.
                    [type]: true,
                });
            } else {
                // $FlowFixMe existingIndex must refer to a 'other' entry, so [type] is fine.
                result.access[existingIndex][type] = true;
            }
        }
    });
}

export const adaptOrbitContainerMeta = (headers: Object): OrbitContainerMeta => {
    const {
              'x-container-read': read,
              'x-container-write': write,
              'x-container-meta-web-listings-css': css,
              'x-container-meta-web-listings': listings,
              'x-container-meta-web-index': index,
              'x-container-meta-web-error': error,
              'x-container-meta-temp-url-key': tempUrlKey1,
              'x-container-meta-temp-url-key-2': tempUrlKey2,
              'x-history-location': history,
              'x-versions-location': versions,
              'x-container-meta-quota-bytes': bytes,
              'x-container-meta-quota-count': count,
          } = headers;

    let result: OrbitContainerMeta = {
        rlistings: { read: false, write: false },
        access: [],
        web: {
            listings: (typeof listings === 'string' ? listings.toLowerCase() : null),
            index: index || null,
            css: css || null,
            error: error || null,
        },
        tempUrlKey1: tempUrlKey1 || null,
        tempUrlKey2: tempUrlKey2 || null,
        archive: {
            history: history || null,
            versions: versions || null,
        },
        quota: {
            bytes: !isNaN(bytes) ? bytes : null,
            count: !isNaN(count) ? count : null,
        },
    };

    if (read != null && read !== '') {
        const entries = read.split(',');
        parseOrbitAccesses(entries, 'read', result);
    }
    if (write != null && write !== '') {
        const entries = write.split(',');
        parseOrbitAccesses(entries, 'write', result);
    }

    return result;
};

export const adaptOrbitObjectMeta = (containerUrl: string, name: string, headers: Object): OrbitObject => {
    const {
        'content-length': bytes,
        'last-modified': last_modified,
        'content-type': content_type,
    } = headers;

    // seems like this is only in the container-listing response.
    // but we don't use it...
    const hash = '';

    const id = getObjectId(name, content_type);

    return {
        id,
        name,
        content_type,
        bytes,
        hash,
        basename: name.split('/').pop(),
        absolute_url: containerUrl + name,
        last_modified: parseHttpDate(last_modified) || new Date('1970-01-01 00:00'),
        is_resource: false,
    }
}

export const adaptOrbitObject = (argh: BbRawApiOrbitEntry, containerUrl: string): OrbitObject => {
    const raw = argh;
    if (typeof raw.subdir === 'string') {
        const { subdir } = raw;
        const id = getSubdirObjectId(subdir);

        return {
            id,
            name: subdir,
            absolute_url: containerUrl + id,
            basename: subdir.substr(0, subdir.length - 1).split('/').pop(),
            content_type: 'application/directory',
            last_modified: null,
            hash: '',
            bytes: 0,
            is_resource: false,
        };
    } else {
        // that typeof raw.subdir test should refine the type here -
        // can't see why it doesn't. so just go through any :(
        let { name, last_modified, ...rest } = ((raw: any): BbRawApiOrbitObject);
        const basename: string = name.split('/').pop();

        const id = getObjectId(name, rest.content_type);

        return {
            ...rest,
            id,
            name,
            basename,
            absolute_url: containerUrl + id,
            last_modified: parseIsoDate(last_modified) || new Date('1970-01-01 00:00'),
            is_resource: false,
        };
    }
};

function addRemove(headers: OrbitContainerHeaders, value: ?string, add: $Keys<OrbitContainerHeaders>, remove: $Keys<OrbitContainerHeaders>): OrbitContainerHeaders {
    if (typeof value === 'string') {
        headers[add] = value;
    } else {
        headers[remove] = '1';
    }
    return headers;
}

/**
 * We have to be able to map from `OrbitMetaData` to `OrbitMetaHeaders`, but typically
 * only a subset of the headers actually apply to the change being made - eg changing
 * temp URL keys doesn't affect the `x-container-read` headers.
 *
 * So `restrict` is used to force callers to restrict to a subset of the headers.
 *
 * The callers need to know exactly which headers they're expecting, and that's encoded
 * in the adaptOrbitMetaData / orbitMetaToHeaders, so this isn't exactly the perfect
 * solutions, but it's enough.
 */
export function orbitContainerMetaToHeaders(meta: OrbitContainerMeta, restrict: Array<$Keys<OrbitContainerHeaders>>): OrbitContainerHeaders {
    let headers: OrbitContainerHeaders = {
        'x-container-read': '',
        'x-container-write': '',
    };
    let read: Array<string> = [];
    let write: Array<string> = [];

    meta.access.forEach((e: ContainerAccess) => {
        switch(e.type) {
        case 'any-referrer':
            if (e.read) {
                read.push('.r:*');
                read.push('*:*');
            } else {
                read.push('.r:-*');
            }
            break;
        case 'other':
            if (e.read) read.push(e.id);
            if (e.write) write.push(e.id);
            break;
        case 'http':
            read.push(`.r:${e.read ? '' : '-'}${e.referrer}`)
            break;
        case 'api':
            if (e.read) read.push(e.id);
            if (e.write) write.push(e.id);
            break;
        case 'team':
            // we shouldn't get these entries here.
            break;
        default:
            void (e.type: empty);
        }
    });

    if (meta.rlistings.read) read.push('.rlistings');

    headers = addRemove(headers, meta.web.listings, 'x-container-meta-web-listings', 'x-remove-container-meta-web-listings');
    headers = addRemove(headers, meta.web.css, 'x-container-meta-web-listings-css', 'x-remove-container-meta-web-listings-css');
    headers = addRemove(headers, meta.web.index, 'x-container-meta-web-index', 'x-remove-container-meta-web-index');
    headers = addRemove(headers, meta.web.error, 'x-container-meta-web-error', 'x-remove-container-meta-web-error');

    headers = addRemove(headers, meta.tempUrlKey1, 'x-container-meta-temp-url-key', 'x-remove-container-meta-temp-url-key');
    headers = addRemove(headers, meta.tempUrlKey2, 'x-container-meta-temp-url-key-2', 'x-remove-container-meta-temp-url-key-2');

    headers = addRemove(headers, meta.archive.versions, 'x-versions-location', 'x-remove-versions-location');
    headers = addRemove(headers, meta.archive.history, 'x-history-location', 'x-remove-history-location');

    headers = addRemove(headers, meta.quota.bytes ? '' + meta.quota.bytes : null, 'x-container-meta-quota-bytes', 'x-remove-container-meta-quota-bytes');
    headers = addRemove(headers, meta.quota.count ? '' + meta.quota.count : null, 'x-container-meta-quota-count', 'x-remove-container-meta-quota-count');

    const result = {
        ...headers,
        'x-container-read': read.filter(s => s !== '').join(','),
        'x-container-write': write.filter(s => s !== '').join(','),
    };

    return Object.keys(result).filter(x => restrict.indexOf(x) !== -1).reduce((acc: OrbitContainerHeaders, key) => {
        acc[key] = result[key];
        return acc;
    }, ({}: any));
}


export function adaptOrbitAccountMeta(headers: Object): OrbitAccountMeta {
    return {
        tempUrlKey1: headers['x-account-meta-temp-url-key'] || null,
        tempUrlKey2: headers['x-account-meta-temp-url-key-2'] || null,
        bytes: headers['x-account-bytes-used'] || null,
    }
}

export function cloneContainerMeta(meta: OrbitContainerMeta): OrbitContainerMeta {
    return {
        ...meta,
        // this obviously isn't an incompatible-return, it just copies the whole object...
        // $FlowFixMe incompatible-return
        access: meta.access.map(x => ({...x})),
        web: {
            ...meta.web,
        },
        rlistings: {
            ...meta.rlistings,
        },
    }
}

export function getOrbitContainerUrl(accountId: string, name: string): string {
    return orbitUrl + '/' + accountId + '/' + name;
}

export function getContainerRegistryUrl(accountId: string, name: string): [string, string] {
    return [
        containerRegistryUrl + '/' + accountId + '/' + name.substring(0, name.length - 10),
        containerRegistryNoScheme + '/' + accountId + '/' + name.substring(0, name.length - 10),
    ]
}