// @flow

import { Card } from './Card';
import { ResourceChip } from './Chip';
import { useResourceRoutes } from '../../lib/history';
import type { BbCollectedImage, BbImage, BbNestedImage } from '../../api/type';

type ImageCardProps = {
    image: BbImage | BbCollectedImage | BbNestedImage,
}

export const ImageCard = ({ image }: ImageCardProps): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <Card link={getRoute('image', image.id)}><ResourceChip resource={image}/></Card>
    );
};