// @flow
import { useListResource } from '../hoc/ListPage';
import { createServerSearch, TextAndZoneSearchEditor } from './TextAndZoneSearch';
import { usePager } from '../element/ArrayPager';
import { PagerBar } from '../element/PagerBar';
import { Panel, PanelBar, PanelHeader, } from '../element/Panel';
import { ResourceChip } from '../element/Chip';
import { Card } from '../element/Card';
import { CardGroup } from '../element/CardGroup';
import { ResourceSelector } from './ResourceSelector';
import { useSelectorListGrid } from '../element/UiStateHooks';
import { Notice } from '../element/Notice';
import { history, useResourceRoutes } from '../../lib/history';
import { RC_CACHED, RC_ERROR, } from '../../state/resource/type';
import { PanelApiErrorBar } from '../element/PanelApiErrorBar';
import { ServerListTable } from '../section/server/ServerList';
import { Link } from 'react-router-dom';
import { Button } from '../element/Button';
import { RelatedIndicator } from '../element/RelatedIndicator';
import { serverSortFields } from '../section/server/def';
import { isShowDetailsResourceStatus, SkeletonCardPanel } from '../element/Skeleton';
import { LABELS } from '../element/ResourceLabels';

import type { ResourceCacheStatus } from '../../state/resource/type';
import type { BbCollectedServer, BbNestedServer } from '../../api/type.srv';
import type { IdSelectEditor } from './ResourceSelector';
import type { ListSortDef } from '../hoc/ListPage';
import type { DialogState } from '../element/Dialog';
import type { ButtonProps } from '../element/Button';

const searchDef = createServerSearch('server:selector');

export type ServerSelectorOwnProps = {
    +editor: IdSelectEditor,
    +selectSingle?: true,
    +filter?: (server: BbCollectedServer) => boolean,
    +confirmDialog?: DialogState<null>,
    +leftButton?: ButtonProps,
};

export const notDeletedFilter = (server: BbCollectedServer): boolean => server.status !== 'deleted' && server.status !== 'deleting' && server.status !== 'failed';
const isSelected = (server: BbCollectedServer, selectedServers: ?$ReadOnlyArray<BbNestedServer | BbCollectedServer>): boolean =>
    selectedServers
        ? selectedServers.findIndex((s) => s.id === server.id) !== -1
        : false
;

const serverSort: ListSortDef<BbCollectedServer> = {
    name: 'server_selector',
    fields: {
        _default: (a: BbCollectedServer, b: BbCollectedServer) => a.id.localeCompare(b.id),
    }
};

export const serversToIds = (nodes: $ReadOnlyArray<BbNestedServer | BbCollectedServer>): Array<string> => nodes.map(n => n.id);

export const ServerSelector = ({ editor, selectSingle, filter, confirmDialog, leftButton, searchComponent }: ServerSelectorOwnProps): React$Node => {
    return (
        <ResourceSelector
            selector={{
                editor,
                selectSingle,
            }}
            kind='server'
            sortFields={serverSortFields}
            searchDef={searchDef}
            searchComponent={searchComponent || TextAndZoneSearchEditor}
            table={ServerListTable}
            itemsFilter={filter || notDeletedFilter}
            confirmDialog={confirmDialog}
            leftButton={leftButton}
        />
    );
};

export type SelectedServersProps = {
    +cacheStatus: ResourceCacheStatus,
    +editUri: ?string,
    +selectedServers?: $ReadOnlyArray<BbNestedServer | BbCollectedServer>,
}

export type ResourceSelectedServersProps = {
    ...SelectedServersProps,
    +title: string,
    +description?: string,
}

export const ResourceSelectedServers = (props: ResourceSelectedServersProps): React$Node => {
    const list = useListResource<BbCollectedServer>('server', serverSort);
    const getRoute = useResourceRoutes();
    const allSelected = list.items.filter((s) =>
        notDeletedFilter(s) && isSelected(s, props.selectedServers)
    );
    const { items, pager } = usePager('resource_server_selector', allSelected, allSelected.length);
    const listGrid = useSelectorListGrid('grid');
    const { editUri } = props;

    return (
        <Panel>
            <PanelHeader
                title={props.title}
                description={props.description}
                actions={editUri ? { edit: () => history.push(editUri + '/'), } : null}
                mode={listGrid}
            />

            {list.status === RC_ERROR
                ? <PanelApiErrorBar {...LABELS['server']} />
                : null
            }

            {listGrid[0] === 'grid' && list.status !== RC_ERROR
                ? <PanelBar>
                    {items.length === 0 && props.cacheStatus === RC_CACHED && list.status === RC_CACHED
                        ? <Notice>No cloud servers assigned.</Notice>
                        : null
                    }
                    <CardGroup>
                        {items.map((server) =>
                            <Card key={server.id} link={getRoute('server', server.id)}>
                                <ResourceChip resource={server} link={getRoute('server', server.id)}/>
                            </Card>
                        )}
                        {!isShowDetailsResourceStatus(list.status)
                            ? <SkeletonCardPanel />
                            : null
                        }
                    </CardGroup>
                </PanelBar>
                : null
            }
            {listGrid[0] === 'list' && list.status !== RC_ERROR
                ? <ServerListTable
                    items={items}
                    totalCount={allSelected.length}
                    Th={list.Th}
                    status={list.status}
                    context='assigned'
                />
                : null}

            <PagerBar {...pager} />
        </Panel>
    );
};


type ResourceServerTdProps = {
    +id: string,
    +editLabel: string,
    +servers: $ReadOnlyArray<BbNestedServer>,
    +showAddButton: boolean,
}

export const ResourceServerTd = ({ id, servers, editLabel: label, showAddButton, }: ResourceServerTdProps): React$Node => (
    <>
        {servers.length === 0 && showAddButton
            ? <Link to={`${id}/servers/`}>
                <Button kind='bare'>+ Add</Button>
            </Link>
            : <>
                <RelatedIndicator id={id} related={servers} editLink={showAddButton ? {kind: 'servers', id, label} : null} />
            </>
        }
    </>
)