// @flow
import { useMemo } from 'react';
import { textOnlySearch } from '../../common/TextOnlySearch';
import { arrayLengthSort, dateSort, idNameSort, } from '../../element/Sort';
import { store } from '../../../state/cloudgui';
import { useDeleteDialog } from '../../common/CommonDialogs';
import { useDispatch } from 'react-redux';
import { useResourceIdSelector } from '../../hoc/ListSelection';
import { isAllocated } from '../../../api/lib';
import { useResourcesById } from '../../hoc/ListPage';

import type { BbServerGroup } from '../../../api/type';
import type { SortFields } from '../../element/Sort';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { SearchDefinition } from '../../element/Search';
import type { ItemSelectOptions } from '../../common/ResourceSelector';
import type { DialogState } from '../../element/Dialog';
import type { ActionQueueAction } from '../../../state/ActionQueue/type';
import type { Dispatch } from 'redux';

export const serverGroupListSearchDef: SearchDefinition<BbServerGroup> = textOnlySearch<BbServerGroup>('server_group:list');
export const serverGroupSelectorSearchDef: SearchDefinition<BbServerGroup> = textOnlySearch<BbServerGroup>('server_group:selector');

export const serverGroupSortFields: SortFields<BbServerGroup> = {
    _default: idNameSort<BbServerGroup>(),
    id: idNameSort<BbServerGroup>(),
    created_at: dateSort<BbServerGroup>('created_at'),
    servers: arrayLengthSort<BbServerGroup>('servers'),
    firewall_rules: (a: BbServerGroup, b: BbServerGroup) => {
        const state: CloudGuiState = store.getState();

        const aLen = (a.firewall_policy ? state.Resource.firewall_policy.collected?.[a.firewall_policy.id]?.rules?.length : null) || 0;
        const bLen = (b.firewall_policy ? state.Resource.firewall_policy.collected?.[b.firewall_policy.id]?.rules?.length : null) || 0;

        return aLen - bLen;
    }
};

export type ServerGroupListActions = {
    ...ItemSelectOptions<BbServerGroup>,
    +deleteDialog: DialogState<null>,
    +hasAllocatedServers: boolean,
}

function selectableFilter(group: BbServerGroup) {
    return !group.default;
}

export const useServerGroupListActions = (): ServerGroupListActions => {
    const { selected, editor } = useResourceIdSelector('server_group');
    const { resources: server_groups } = useResourcesById<BbServerGroup>('server_group');
    const dispatch = useDispatch<Dispatch<ActionQueueAction>>();

    const [, deleteDialog] = useDeleteDialog(
        true,
        () => {
            selected.forEach(id => {
                if (server_groups[id]?.servers && server_groups[id].servers.length) {
                    dispatch({
                        type: 'ACTION_QUEUE_ADD',
                        payload: {
                            action: {
                                kind: 'server_group',
                                id,
                                method: 'POST',
                                action: 'remove_servers',
                                params: { servers: server_groups[id].servers.map(server => ({ server: server.id })) }
                            }
                        }
                    });
                }

                dispatch({
                    type: 'ACTION_QUEUE_ADD',
                    payload: {
                        action: {
                            kind: 'server_group',
                            id,
                            method: 'DELETE',
                            action: null,
                        }
                    }
                });
            });
            editor.setValue([]);
        },
        'Delete',
    );

    const hasAllocatedServers: boolean = useMemo(
        () => selected.findIndex(
            id => server_groups[id]?.servers?.findIndex(x => isAllocated(x.status)) !== -1
        ) !== -1,
        [selected, server_groups]
    );

    return {
        editor,
        hasAllocatedServers,
        multiItemActions: [
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: () => deleteDialog.show(),
                },
                disabledTooltip: null,
            },
        ],
        selectableFilter,
        deleteDialog,
    };
};

export const DefaultGroupSelectWarning: React$Node = <div>Default group is not selectable here</div>;