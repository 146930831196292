// @flow

import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccountCacheKey } from "./lib";
import { RC_CACHED, RC_FETCHING } from "../../state/resource/type";
import { useClientIpAddress } from "../../lib/ClientIp";

import type { Dispatch } from "redux";
import type { CloudGuiState } from "../../state/cloudgui";
import type { BbServerGroup, BbTargetKinds, BbTargetResourceTypes } from "../../api/type";
import type { ValueSelectChoice } from "../element/ValueSelect";
import type { ResourceCacheStatus, ResourceCollection, ResourceFetchCollected } from "../../state/resource/type";
import type { BbCollectedServer, BbServer } from "../../api/type.srv";
import type { BbCollectedLba, BbLba } from "../../api/type.lba";
import type { BbCollectedDatabaseServer, BbDatabaseServer } from "../../api/type.dbs";
import type { ClientIpAddressHook } from "../../state/Gui/type";

type AccessControlSelectStateProps = {
    +cacheStatus: ResourceCacheStatus,
    +server: ResourceCollection<BbServer, BbCollectedServer>,
    +server_group: ResourceCollection<BbServerGroup, BbServerGroup>,
    +load_balancer: ResourceCollection<BbLba, BbCollectedLba>,
    +database_server: ResourceCollection<BbDatabaseServer, BbCollectedDatabaseServer>,
};

export type AccessControlInclude = {
    +server?: true,
    +any?: true,
    +lba?: true,
    +serverGroup?: true,
    +address?: true,
    +databaseServer?: true,
    +unknown?: true,
    +none?: string,
    +clientIp?: true,
}

type Options = {
    [key: BbTargetKinds]: $ReadOnlyArray<ValueSelectChoice<string>>,
}

const labelSort = (a, b) => a.label.localeCompare(b.label);
export type TargetsById = Map<string, BbTargetResourceTypes>;

export function useFetchAccessControlResources(): AccessControlSelectStateProps {
    const dispatch = useDispatch<Dispatch<ResourceFetchCollected>>();
    const accountCacheKey = useAccountCacheKey();
    useEffect(() => {
        dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: 'server' } });
        dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: 'server_group' } });
        dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: 'load_balancer' } });
        dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: 'database_server' } });
    }, [dispatch, accountCacheKey]);

    return useSelector<CloudGuiState, AccessControlSelectStateProps>((state: CloudGuiState) => ({
        cacheStatus: (
            state.Resource.server.fetched < RC_CACHED
            || state.Resource.server_group.fetched < RC_CACHED
            || state.Resource.load_balancer.fetched < RC_CACHED
            || state.Resource.database_server.fetched < RC_CACHED
        ) ? RC_FETCHING : RC_CACHED,
        server: state.Resource.server,
        server_group: state.Resource.server_group,
        load_balancer: state.Resource.load_balancer,
        database_server: state.Resource.database_server,
    }));
}

export const useAccessControlResourcesById = (): TargetsById => {
    const resources = useFetchAccessControlResources();

    return useMemo(() => {
        const items = ([
            ...Object.keys(resources.server.collected).map(k => ([k, resources.server.collected[k]])),
            ...Object.keys(resources.server_group.collected).map(k => ([k, resources.server_group.collected[k]])),
            ...Object.keys(resources.load_balancer.collected).map(k => ([k, resources.load_balancer.collected[k]])),
            ...Object.keys(resources.database_server.collected).map(k => ([k, resources.database_server.collected[k]])),
        ]: $ReadOnlyArray<[string, BbTargetResourceTypes]>);

        return new Map<string, BbTargetResourceTypes>(items);
    }, [resources.server.collected, resources.server_group.collected, resources.load_balancer.collected, resources.database_server.collected,])
}

export type AccessResourcesHook = {
    +processing: boolean,
    +options: Options,
    +typeOptions: $ReadOnlyArray<ValueSelectChoice<BbTargetKinds>>,
    +clientIp: ClientIpAddressHook;
}

export function useAccessControlOptions(include: AccessControlInclude): AccessResourcesHook {
    const { cacheStatus, server, server_group, load_balancer, database_server } = useFetchAccessControlResources();
    const clientIp = useClientIpAddress();
    const loading = cacheStatus !== RC_CACHED || clientIp.cacheStatus === RC_FETCHING;

    const [options, typeOptions] = useMemo(
        (): [Options, $ReadOnlyArray<ValueSelectChoice<BbTargetKinds>>] => {
            // no point doing load of partial applications of this
            if (loading) {
                return [{}, []];
            }

            let options: Options = {};
            let typeOptions: Array<ValueSelectChoice<BbTargetKinds>> = [];

            if (include.none) {
                typeOptions.push({ label: include.none, value: '' });
            }

            if (include.server) {
                options.srv = [{
                    label: 'Select...',
                    value: ''
                }].concat(Object.keys(server.collected).map(id => ({
                    label: server.collected[id].name || id,
                    value: id
                })).sort(labelSort));
                typeOptions.push({ label: 'Cloud Server', value: 'srv' });
            }

            if (include.serverGroup) {
                options.grp = [{
                    label: 'Select...',
                    value: ''
                }].concat(Object.keys(server_group.collected).map(id => ({
                    label: server_group.collected[id].name || id,
                    value: id
                })).sort(labelSort));
                typeOptions.push({ label: 'Server Group', value: 'grp' });
            }

            if (include.lba) {
                options.lba = [{
                    label: 'Select...',
                    value: ''
                }].concat(Object.keys(load_balancer.collected).map(id => ({
                    label: load_balancer.collected[id].name || id,
                    value: id
                })).sort(labelSort));
                typeOptions.push({ label: 'Load Balancer', value: 'lba' });
            }

            if (include.databaseServer) {
                options.dbs = [{
                    label: 'Select...',
                    value: ''
                }].concat(Object.keys(database_server.collected).map(id => ({
                    label: database_server.collected[id].name || id,
                    value: id
                })).sort(labelSort));
                typeOptions.push({ label: 'Cloud SQL', value: 'dbs' });
            }

            if (include.address) {
                typeOptions.push({ label: 'IPV4 or IPV6 address', value: 'addr' });
            }

            if (include.clientIp && clientIp.cacheStatus === RC_CACHED) {
                typeOptions.push({ label: `My IP Address (${clientIp.clientIp})`, value: 'clientIp' });
            }

            if (include.any != null) {
                typeOptions.unshift({ label: 'Any', value: 'any' });
            }

            if (include.unknown) {
                typeOptions.push({ label: 'Other', value: 'other' });
            }

            return [options, typeOptions]
        }, [server, server_group, load_balancer, database_server, include, loading, clientIp,]
    );

    return {
        processing: loading,
        options,
        typeOptions,
        clientIp,
    }
}
