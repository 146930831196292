// @flow
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SignupSidebar } from './Sidebar';
import { Layout } from './Layout';

import type { Dispatch } from 'redux';
import type { AuthLogoutAction } from '../../state/auth/type';

export const Logout = (): React$Node => {
    const dispatch = useDispatch<Dispatch<AuthLogoutAction>>();

    useEffect(() => {
        dispatch({ type: 'AUTH_LOGOUT', payload: { clearState: true } });
    }, [dispatch]);

    return (
        <Layout
            kind="auth"
            sidebar={<SignupSidebar/>}
            content={
                <div className="c-auth">
                    <div className='c-auth__form'>
                        <h1 className="c-auth__heading">Signing Out</h1>
                    </div>
                </div>
            }
        />
    );
}

