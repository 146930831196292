
export const actionMessages = {
    'firewall_rule': {
        'user_app': {
            'create': 'Created Firewall Rule %{resource_link}',
            'delete': 'Deleted Firewall Rule %{resource_link}',
            'update': 'Updated Firewall Rule %{resource_link}'
        },
        'api_client': {
            'create': 'Created Firewall Rule %{resource_link}',
            'delete': 'Deleted Firewall Rule %{resource_link}',
            'update': 'Updated Firewall Rule %{resource_link}'
        }
    },
    'image': {
        'user_app': { 'delete': 'Deleted Image %{resource_link}', 'update': 'Updated Image %{resource_link}' },
        'api_client': { 'delete': 'Deleted Image %{resource_link}', 'update': 'Updated Image %{resource_link}' },
        'background': {
            'stat_update': 'Image %{resource_link} was updated',
            'deleted': 'Image %{resource_link} was deleted',
            'failed': 'Image %{resource_link} was failed',
            'start_update': 'Image %{resource_link} was updated',
            'delete': 'Image %{resource_link} was deleted',
            'update': 'Image %{resource_link} was updated',
            'available': 'Image %{resource_link} became available'
        }
    },
    'load_balancer': {
        'user_app': {
            'active': 'Activated Load Balancer %{resource_link}',
            'create': 'Created Load Balancer %{resource_link}',
            'delete': 'Deleted Load Balancer %{resource_link}',
            'update': 'Updated Load Balancer %{resource_link}'
        },
        'api_client': {
            'active': 'Activated Load Balancer %{resource_link}',
            'create': 'Created Load Balancer %{resource_link}',
            'delete': 'Deleted Load Balancer %{resource_link}',
            'update': 'Updated Load Balancer %{resource_link}'
        },
        'background': {
            'remove_nodes': 'Load Balancer %{resource_link} had nodes removed',
            'add_nodes': 'Load Balancer %{resource_link} had nodes added',
            'deleted': 'Load Balancer %{resource_link} was deleted',
            'listeners_removed': 'Load Balancer %{resource_link} had listeners removed',
            'add_listeners': 'Load Balancer %{resource_link} had listeners added',
            'failed': 'Load Balancer %{resource_link} was failed'
        }
    },
    'api_client': {
        'user_app': {
            'reset_secret': 'Regenerated secret of API Client %{resource_link}',
            'create': 'Created API Client %{resource_link}',
            'delete': 'Deleted API Client %{resource_link}',
            'update': 'Updated API Client %{resource_link}'
        }
    },
    'account': {
        'user_app': {
            'complete_telephone_verification': 'Completed telephone verification for the Account',
            'credit_card_added': 'Updated billing details for the Account',
            'create': 'Created the Account',
            'make_payment': 'Made a manual payment for the Account',
            'tfa_requirement_added': 'Two-Factor Requirement was added to the Account',
            'tfa_requirement_removed': 'Two-Factor Requirement was removed from the Account',
            'update_billing_method': 'Updated billing details for the Account'
        },
        'background': {
            'activated': 'Account was activated',
            'payment_method_update_succeeded': 'Updated billing details for the Account',
        }
    },
    'database_server': {
        'user_app': {
            'snapshot': 'Issued snapshot request for %{resource_name} %{resource_link}',
            'reset_password': 'Admin password was reset for %{resource_link}',
            'create': 'Created Cloud SQL Instance %{resource_link}',
            'delete': 'Deleted Cloud SQL Instance %{resource_link}',
            'update': 'Updated Cloud SQL Instance %{resource_link}',
            'resize': 'Resized Cloud SQL Instance %{resource_link}',
            'reset': 'Restarted Cloud SQL Instance %{resource_link}'
        },
        'api_client': {
            'snapshot': 'Requested a snapshot of Cloud SQL Instance %{resource_link}',
            'reset_password': '%{resource_link} password was reset by <b>%{api_client}</b>',
            'create': 'Created Cloud SQL Instance %{resource_link}',
            'delete': 'Deleted Cloud SQL Instance %{resource_link}',
            'update': 'Updated Cloud SQL Instance %{resource_link}',
            'resize': 'Resized Cloud SQL Instance %{resource_link}',
            'reset': 'Restarted Cloud SQL Instance %{resource_link}'
        },
        'background': {
            'snapshot': 'Cloud SQL instance %{resource_link} started scheduled snapshot',
            'mark_available': 'Cloud SQL instance %{resource_link} completed scheduled snapshot',
            'active': 'Cloud SQL Instance %{resource_link} became active',
            'deleted': 'Cloud SQL Instance %{resource_link} was deleted',
            'failed': 'Cloud SQL Instance %{resource_link} became failed'
        }
    },
    'cloud_ip': {
        'user_app': {
            'map_request': 'Mapped Cloud IP %{resource_link} to %{affected_resources}',
            'unmap_request': 'Unmapped Cloud IP %{resource_link} from %{affected_resources}',
            'update': 'Updated Cloud IP %{resource_link}'
        },
        'api_client': {
            'map_request': 'Mapped Cloud IP %{resource_link} to %{affected_resources}',
            'unmap_request': 'Unmapped Cloud IP %{resource_link} from %{affected_resources}',
            'update': 'Updated Cloud IP %{resource_link}'
        },
        'background': {
            'allocate': 'Cloud IP %{resource_link} was allocated',
            'unallocate': 'Cloud IP %{resource_link} was unallocated',
            'unmap_complete': 'Cloud IP %{resource_link} completed unmapping',
            'map_complete': 'Cloud IP %{resource_link} completed mapping'
        }
    },
    'collaborator': {
        'user_app': {
            'accept': '<b>%{resource_name}</b> accepted invitation',
            'create': 'Invited <a href=\'mailto:%{resource_name}\'>%{resource_name}</a>',
            'delete': 'Cancelled invitation to <a href=\'mailto:%{resource_name}\'>%{resource_name}</a>'
        },
        'api_client': {
            'create': 'Invited <a href=\'mailto:%{resource_name}\'>%{resource_name}</a>',
            'delete': 'Cancelled invitation to <a href=\'mailto:%{resource_name}\'>%{resource_name}</a>'
        }
    },
    'server': {
        'user_app': {
            'snapshot': 'Issued snapshot command for Cloud Server %{resource_link}',
            'shutdown': 'Issued shutdown command for Cloud Server %{resource_link}',
            'start': 'Issued start command for Cloud Server %{resource_link}',
            'activate_console': 'Requested console activation for Cloud Server %{resource_link}',
            'create': 'Created Cloud Server %{resource_name}  %{resource_link}',
            'reset': 'Issued reset command for Cloud Server %{resource_link}',
            'stop': 'Issued stop command for Cloud Server %{resource_link}',
            'delete': 'Issued delete command for Cloud Server %{resource_link}',
            'update': 'Updated Cloud Server %{resource_link}',
            'reboot': 'Issued reboot command for %{resource_name} %{resource_link}',
            'resize': 'Resized Cloud Server %{resource_name} %{resource_link}'
        },
        'api_client': {
            'snapshot': ' started snapshot of Cloud Server %{resource_link}',
            'shutdown': 'Requested shutdown of Cloud Server %{resource_link}',
            'start': 'Started Cloud Server %{resource_link}',
            'activate_console': 'Requested console activation for Cloud Server %{resource_link}',
            'create': 'Created Cloud Server %{resource_link}',
            'reset': 'Requested reset of Cloud Server %{resource_link}',
            'stop': 'Stopped Cloud Server %{resource_link}',
            'delete': 'Requested deletion of Cloud Server %{resource_link}',
            'update': 'Updated Cloud Server %{resource_link}',
            'reboot': 'Requested reboot of Cloud Server %{resource_link}'
        },
        'background': {
            'fail': '%{resource_link} changed state to failed',
            'inactive': '%{resource_link} changed state to inactive',
            'activate': '%{resource_link} changed state to active',
            'active': '%{resource_link} changed state to active',
            'deleted': '%{resource_link} changed state to deleted',
            'resized': '%{resource_link} was resized'
        }
    },
    'server_group': {
        'user_app': {
            'create': 'Created Server Group %{resource_link}',
            'add_servers': 'Added Servers to Server Group %{resource_link}',
            'delete': 'Deleted Server Group %{resource_link}',
            'update': 'Updated Server Group %{resource_link}'
        },
        'api_client': {
            'create': 'Created Server Group %{resource_link}',
            'add_servers': 'Added Servers to Server Group %{resource_link}',
            'delete': 'Deleted Server Group %{resource_link}',
            'update': 'Updated Server Group %{resource_link}'
        },
        'background': { 'move_servers': 'Server Group %{resource_link} had servers moved' }
    },
    'database_snapshot': {
        'user_app': {
            'delete': 'Deleted Cloud SQL Snapshot %{resource_link}',
            'update': 'Updated Cloud SQL Snapshot %{resource_link}'
        },
        'api_client': {
            'delete': 'Deleted Cloud SQL Snapshot %{resource_link}',
            'update': 'Updated Cloud SQL Snapshot %{resource_link}'
        },
        'background': {
            'started': 'Cloud SQL Snapshot: %{resource_name} %{resource_link} started',
            'deleted': 'Cloud SQL Snapshot %{resource_link} was deleted',
            'mark_available': 'Cloud SQL Snapshot: %{resource_name} %{resource_link} was completed',
            'failed': 'Cloud SQL Snapshot %{resource_link} was failed'
        }
    },
    'volume': {
        'user_app': {
            'create': 'Created Volume %{resource_link}',
            'delete': 'Deleted Volume %{resource_link}',
            'update': 'Updated Volume %{resource_link}',
            'attached': 'Attached Volume %{resource_name} %{resource_link} to %{affected_resources}',
            'detached': 'Detached Volume %{resource_name} %{resource_link} from %{affected_resources}',
        },
        'api_client': {
            'create': 'Created Volume %{resource_link}',
            'delete': 'Deleted Volume %{resource_link}',
            'update': 'Updated Volume %{resource_link}'
        },
        'background': {
            'detached': 'Volume %{resource_name} %{resource_link} was detached',
            'failed': 'Volume %{resource_link} was failed'
        }
    },
};