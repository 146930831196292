// @flow
import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BillingPage } from './BillingPage';
import { animationRouteContext } from '../../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';

import type { Location, Match } from 'react-router-dom';

export const BillingSection = ({ match: accountMatch }: { location: Location, match: Match }): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route path={`${accountMatch.path}`} component={BillingPage}/>
            </Switch>
        </AnimatePresence>
    );
};