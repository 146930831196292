// @flow
import { useState } from 'react';
import { Panel, PanelHeader, PanelBar } from '../../element/Panel';
import { Pill } from '../../element/Pill';
import { ToggleGroup } from '../../element/ToggleGroup';
import { RANGES } from './lib';
import { ResourceAreaChart } from './ResourceAreaChart';
import type { ResourceAreaChartConfig } from './ResourceAreaChart';

type MetricsPanelProps = {
    +id: string,
    +charts: $ReadOnlyArray<ResourceAreaChartConfig<any>>,
}

export const MetricsPanel = ({ id, charts }: MetricsPanelProps): React$Node => {
    const [range, setRange] = useState('HOURS_1');

    return (
        <Panel>
            <PanelHeader title={<>Metrics <Pill className="align-middle -mt-1">Beta</Pill></>}
            />

            <PanelBar border={false} className="c-panel__section--toggle-group">
                <ToggleGroup options={RANGES} selected={range} onSelect={(v) => setRange(v)} />
            </PanelBar>
            <PanelBar className="c-chart-group">
                {charts.map((config,i) => (
                    <ResourceAreaChart key={i} id={id} range={range} {...config} />
                ))}
            </PanelBar>
        </Panel>
    );
};