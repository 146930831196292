// @flow
import { formatResourceCost, wholeSize } from './Styled';
import { Tooltip } from './Tooltip';
import { Pill } from './Pill';
import { Button } from './Button';

type TypeCardProps = {
    +ram: number,
    +cpus?: number,
    +disk: ?number,
    +cost: ?string | number,
    +handle?: ?string,
    +id?: ?string,
    +resourceTitle: string,
}

export const TypeCardContent = ({ ram, cpus, disk, cost, handle, id, resourceTitle }: TypeCardProps): React$Node => {

    return (
        <div className='c-type-card'>

            <h4 className='c-type-card__title'>{wholeSize(ram)} RAM</h4>
            <h5 className='c-type-card__id'>{handle || id}</h5>
            <hr/>

            <div className='c-type-card__details'>
                {cpus
                    ? <div className='c-type-card__detail-item'>
                        <div className='c-type-card__label'>VCPU</div>
                        <div className='c-type-card__value'>{cpus}</div>
                    </div>
                    : null}
                {disk
                    ? <div className='c-type-card__detail-item'>
                        <div className='c-type-card__label'>SSD</div>
                        <div className='c-type-card__value'>{wholeSize(disk)}</div>
                    </div>
                    : null
                }
                {cost == null
                    ? <Tooltip overlay={<div>Pricing information for this item is currently unavailable.</div>}>
                        <div className='c-type-card__detail-item'>
                            <div className='c-type-card__label'>PRICE</div>
                            <div className='c-type-card__value'>n/a</div>
                        </div>
                    </Tooltip>
                    : <div className='c-type-card__detail-item'>
                        <div className='c-type-card__label'>PRICE
                            <Tooltip
                                overlay={<div>
                                    <p>{resourceTitle} are billed by the hour. Monthly prices are shown for convenience, based on 730 hours of usage in an average month.</p>
                                    <p className='mb-2'><a href='https://www.brightbox.com/pricing/'target='_blank' rel='noopener noreferrer'><Button size='xs' color='white' kind='tertiary' postIcon='new-window'>View Full Pricing Information</Button></a></p>
                                </div>}>
                                <Pill>?</Pill>
                            </Tooltip>
                        </div>
                        <div className='c-type-card__value'>£{formatResourceCost(cost)}<span className='text-gray-500 text-xs tracking-wider'>/mo</span> 
                    </div>
                    </div>
                }

            </div>
        </div>
    );
};