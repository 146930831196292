// @flow
import { useMemo, } from 'react';
import { useDispatch } from 'react-redux';
import { useViewResource, } from '../../hoc/ViewResource';
import { ServerGroupServerSelector, ServerGroupServersView } from './ServerGroupServerSelector';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useEditorModal, useResourceEditorModal } from '../../common/Editor';
import { DeleteDialog, } from '../../common/CommonDialogs';
import { useDialog, Dialog } from '../../element/Dialog';
import { Notice } from '../../element/Notice';
import { NameEditPanel } from '../../element/NameEditPanel';
import { TextSetting } from '../../element/Setting/TextSetting';
import { emptyErrors } from '../../common/lib';
import { toServersObject } from '../../../state/resource/DependentResourceLogic';
import { historyNavigateOnDelete } from '../../../lib/history';
import { useCurrentAccountId } from '../../hoc/lib';
import { FirewallPolicyView, NoFirewallPolicyView } from '../firewall_policies/FirewallPolicyView';
import { fwpDescriptionForServerGroup } from './FirewallRuleAdd';
import { LABELS } from '../../element/ResourceLabels';

import type { Match} from 'react-router';
import type { BbServerGroup,  BbServerGroupPatchParams } from '../../../api/type';
import type { EditorDef } from '../../common/Editor';
import type { ResourceDeleteAction, } from '../../../state/resource/type';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { DialogState } from '../../element/Dialog';
import type { PanelHeaderActions } from '../../element/Panel';
import type { Dispatch } from 'redux';

const nameDef: EditorDef<BbServerGroup, string, BbServerGroupPatchParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbServerGroup) => res.name || '',
    onValidate: null,
};
const descriptionDef: EditorDef<BbServerGroup, string, BbServerGroupPatchParams, null> = {
    emptyErrors: null,
    editUri: 'description',
    onEdit: (res: BbServerGroup) => res.description || '',
    onValidate: null,
};

const useServerGroupServersEditor = (resource, matchPath: string) => {
    const { item: serverGroup, simpleAction, } = resource;

    return useEditorModal(
        emptyErrors,
        () => serverGroup ? serverGroup.servers.map(server => server.id) : null,
        null,
        (needed: $ReadOnlyArray<string>) => {
            if (!serverGroup) return;

            const toAdd = needed.filter(id => -1 === serverGroup.servers.findIndex(s => s.id === id));
            const toRemove = serverGroup.servers.filter(s => -1 === needed.findIndex(id => s.id === id)).map(s => s.id);

            if (toAdd.length) {
                simpleAction('add_servers', { servers: toAdd.map(toServersObject) }, null);
            }
            if (toRemove.length) {
                simpleAction('remove_servers', { servers: toRemove.map(toServersObject) }, null);
            }
        },
        matchPath,
        'servers',
    );
}

const useDeleteServerGroup = (serverGroup: ?BbServerGroup) => {
    const dispatch = useDispatch();
    const accountId = useCurrentAccountId();
    const autoDeleteFwpId = useMemo(() => {
        if (serverGroup) {
            const {id, firewall_policy} = serverGroup;

            if (
                firewall_policy &&
                (firewall_policy.description || '').indexOf(fwpDescriptionForServerGroup(id)) !== -1
            ) {
                return firewall_policy.id;
            }
        }

        return null;
    }, [serverGroup])

    return () => {
        dispatch({
            type: 'RESOURCE_DELETE_SERVER_GROUP',
            payload: {
                id: serverGroup?.id,
                autoDeleteFwpId
            }
        });

        historyNavigateOnDelete(accountId || '', 'server_group');
    }
}

const useDeleteFirewallRule = () => {
    const dispatch = useDispatch<Dispatch<ResourceDeleteAction>>();

    return (fwr_id: string) => dispatch({
        type: 'RESOURCE_DELETE_ACTION',
        payload: {
            id: fwr_id,
            kind: 'firewall_rule',
        }
    })
}

type GrpDialogsHook = {
    actions: PanelHeaderActions,
    deleteGroupDialog: DialogState<null>,
    deleteFirewallRuleDialog: DialogState<string>,
}

export const useGrpDialogs = (resource: ViewResourceProps<BbServerGroup, BbServerGroupPatchParams>): GrpDialogsHook => {
    const deleteGroup = useDeleteServerGroup(resource?.item);
    const deleteRule = useDeleteFirewallRule();

    const deleteGroupDialog = useDialog([ { label: 'Delete Group', kind: 'primary', color: 'red', onSelect: () => deleteGroup(), } ]);
    const deleteFirewallRuleDialog = useDialog<string>([
        {
            label: 'Delete Rule',
            kind: 'primary',
            color: 'red',
            onSelect: (fwr_id: ?string) => fwr_id ? deleteRule(fwr_id) : null,
        }
    ]);

    return {
        actions: {
            delete: !resource.item?.default ? deleteGroupDialog.show : null,
        },
        deleteGroupDialog,
        deleteFirewallRuleDialog,
    };
}

export const GrpDialogs = ({name, hook, hasActiveServers, }: {name: string, hook: GrpDialogsHook, hasActiveServers: boolean, }): React$Node => {
    return (
        <>
            <Dialog
                title='Delete Server Group?'
                dialog={hook.deleteGroupDialog}
                render={() =>
                    <>
                        {hasActiveServers
                            ? <Notice type='warning'><b>Note</b> – this group contains active Cloud Servers (the Cloud Servers themselves won't be deleted)</Notice>
                            : null
                        }
                        <p>Are you sure you want to delete {name}?</p>
                    </>
            } />

            <DeleteDialog dialog={hook.deleteFirewallRuleDialog} name={hook.deleteFirewallRuleDialog.data || ''} />
        </>
    );
}

export const ServerGroupView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbServerGroup, BbServerGroupPatchParams>('server_group', id);
    const { item: serverGroup, } = resource;

    const servers = useServerGroupServersEditor(resource, match.path);
    const name = useResourceEditorModal(resource, nameDef, match.path);
    const description = useResourceEditorModal(resource, descriptionDef, match.path);

    const dialogs = useGrpDialogs(resource);

    const hasAllocatedServers = serverGroup?.servers?.findIndex(x => x.status === 'active' || x.status === 'inactive') !== -1;

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.server_group.listTitle}
            resourceName={serverGroup?.name || serverGroup?.id || ''}
            resource={resource}
            match={match}
            dialog={<GrpDialogs name={id} hook={dialogs} hasActiveServers={hasAllocatedServers} />}
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={serverGroup?.name || serverGroup?.id}
                            actions={dialogs.actions}
                            editTitleLink={serverGroup?.default ? null : name.editUri}
                            disableEditHint={serverGroup?.default ? <div>The default server group can't be renamed.</div> : null}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: serverGroup?.id,
                                created_at: serverGroup?.created_at,
                                avatar: serverGroup,
                            }}
                            settings={[
                                [
                                                                        {
                                        name: 'Group Description',
                                        summary: serverGroup ? (serverGroup.description || '-'): null,
                                        route: description.editUri,
                                    },
                                    {
                                        name: 'Firewall Policy',
                                        summary: serverGroup?.firewall_policy ? <div className='c-identifier'>{serverGroup?.firewall_policy.id}</div> : '–',
                                        copyValue: serverGroup?.firewall_policy ? serverGroup?.firewall_policy.id : null,
                                        hint: 'The API identifier for the associated Firewall Policy for this Server Group (i.e. the collection of Firewall Rules)',
                                    },
                                ]
                            ]}
                        />
                    </Panel>

                    <ServerGroupServersView
                        cacheStatus={resource.status}
                        editUri={servers.editUri}
                        selectedServers={serverGroup?.servers}
                    />

                    {serverGroup && serverGroup.firewall_policy
                        ? <FirewallPolicyView
                            id={serverGroup?.firewall_policy.id}
                            dest={id}
                            onDeleteFirewallRule={(id: string) => dialogs.deleteFirewallRuleDialog.show(id)}
                        />
                        : <NoFirewallPolicyView cacheStatus={resource.status} dest={id} />
                    }

                </>
            }
            editors={[
                { editor: servers, component: ServerGroupServerSelector },
                { editor: name, render: () => <NameEditPanel editor={name} />},
                { editor: description, render: () => <TextSetting editor={description} title='Server Group Description' optional={true} label='Description' textarea={true} /> , },
            ]}
        />
    )
};
