// @flow

import { useState } from 'react';
import { Tooltip } from './Tooltip';
import { Button } from './Button';
import { useCloseOnFocusLoss } from '../common/lib';

import type { ButtonProps } from './Button';

type DropdownProps = {
    +children: React$Node,
    +dropdown: React$Node,
}

type DropdownButtonProps = {
    +content?: React$Node,
    +dropdown: React$Node,
    ...ButtonProps,
}

function useDropdownVisible() {
    const [visible, setVisible] = useState(false);

    return {
        visible,
        setVisible,
    };
}

export const DropdownButton = ({ content, dropdown, size, color, kind, postIcon, ...rest }: DropdownButtonProps): React$Node => {
    return (
        <Dropdown dropdown={dropdown}>
            <Button
                size={size || 'sm'}
                kind={kind}
                color={color}
                postIcon={postIcon || 'caret-down'}
                {...rest}
            >
                {content || null}
            </Button>
        </Dropdown>
    );
};

export const Dropdown = ({ children, dropdown }: DropdownProps): React$Node => {
    const { visible, setVisible } = useDropdownVisible();
    const overlayRef = useCloseOnFocusLoss(visible, setVisible);

    return (
        <Tooltip
            placement="bottomRight"
            overlay={
                <div ref={overlayRef} className="c-dropdown__content" onClick={() => setVisible(false)}>{dropdown}</div>
            }
            visible={visible}
            onClick={visible ? null : () => setVisible(true)}
            background="light"
        >
            {children}
        </Tooltip>
    );
};