// @flow

import { useIdleTimer } from 'react-idle-timer';
import { request } from '../../api/rest';
import { userUrl } from '../../api/url';

const TWO_HOURS = 1000 * 3600 * 2;

export function useIdleTimerApiCheck() {
    useIdleTimer({
        timeout: TWO_HOURS,
        // timeout: 1000,
        onActive: () => {
            // just creating this request should be enough;
            // the middleware will spot a 403 and redirect, and
            // it will re-establish the logout timer based on the
            // returned expiry.
            request({
                method: 'HEAD',
                url: userUrl,
                headers: {
                    'X-JWT-Extend': 'off',
                }
            });
        }
    });
}