// @flow
import { Link } from 'react-router-dom';
import { formatDate, RightArrow } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { ServerGroupSearch } from './ServerGroupSearch';
import { serverGroupListSearchDef, serverGroupSortFields, useServerGroupListActions, DefaultGroupSelectWarning } from './def';
import { ResourceChip } from '../../element/Chip';
import { ResourceServerTd } from '../../common/ServerSelector';
import { useResourcesById } from '../../hoc/ListPage';
import { RelatedIndicator } from '../../element/RelatedIndicator';
import { Button } from '../../element/Button';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, SkeletonListRelated } from '../../element/Skeleton';
import { RC_CACHED } from '../../../state/resource/type';
import { ItemSelector } from '../../element/ItemSelector';
import { useResourceRoutes } from '../../../lib/history';
import { Tooltip } from '../../element/Tooltip';
import { Dialog } from '../../element/Dialog';
import { Notice } from '../../element/Notice';

import type { BbServerGroup } from '../../../api/type';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';

export type ServerGroupListTableProps = {
    ...ResourceSelectorTableProps<BbServerGroup>,
    +disableDefaultGroup?: React$Node,
}

export const ServerGroupListTable = ({ items, Th: SortTh, search, itemSelect, status, totalCount, context, disableDefaultGroup }: ServerGroupListTableProps): React$Node => {
    const { resources: firewallPolicies } = useResourcesById('firewall_policy');
    const getRoute = useResourceRoutes();

    const listContext = context === 'list';

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={5}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='id'>Name</SortTh>
                        <SortTh field='servers'>Servers</SortTh>
                        <SortTh field='firewall_rules'>Firewall Rules</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((group: BbServerGroup) =>
                <Tr
                    key={group.id}
                    {...itemSelect?.rowProps(group, group.default && listContext
                        ? { disabled: itemSelect?.selectedLength !== 0, }
                        : {}
                    )}
                >
                    {itemSelect
                        ? <Td selector={true}>
                             <Tooltip overlay={group.default ? disableDefaultGroup : null}>
                                 <ItemSelector item={group} itemSelect={itemSelect} listContext={listContext} />
                            </Tooltip>
                        </Td>
                        : null
                    }
                    <Td resourceName={true}>
                        {disableDefaultGroup && group.default && itemSelect?.selectedLength !== 0
                            ? <Tooltip overlay={disableDefaultGroup}>
                                <ResourceChip resource={group} link={itemSelect?.selectedLength === 0 ? getRoute('server_group', group.id) : null} />
                            </Tooltip>
                            : <ResourceChip resource={group} link={itemSelect?.selectedLength === 0 ? getRoute('server_group', group.id) : null} />
                        }
                    </Td>
                    <Td>
                        <ResourceServerTd id={group.id} servers={group.servers} editLabel='Edit Servers' showAddButton={listContext} />
                    </Td>
                    <Td>
                        {group.firewall_policy && firewallPolicies[group.firewall_policy.id] && firewallPolicies[group.firewall_policy.id].rules.length > 0
                            ? <RelatedIndicator id={group.id} related={firewallPolicies[group.firewall_policy.id].rules} />
                            : (
                                listContext
                                    ? <Link to={`${group.id}/firewall_rules/add/`}><Button kind='bare'>+ Add</Button></Link>
                                    : null
                            )
                        }
                    </Td>
                    <Td>{formatDate(group.created_at)}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={getRoute('server_group', group.id)}>
                                <RightArrow />
                            </Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonListRelated /></Td>
                        <Td><SkeletonListRelated /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={6} kind='server_group' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={6} context={context} kind='server_group' />
            </tbody>
        </Table>
    );
};

export const ServerGroupList = (): React$Node => {
    const { deleteDialog, hasAllocatedServers, ...selector } = useServerGroupListActions();

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Server Groups?`}
                confirmString='delete'
                render={() => (
                    <>
                        {hasAllocatedServers
                            ? <Notice type='warning'><b>Note</b> – one or more of the selected groups contain active Cloud Servers (the Cloud Servers themselves won't be deleted)</Notice>
                            : null
                        }
                        <div>Are you sure you want to delete these groups?</div>
                    </>
                )}
            />
            <ResourceListPage
                title='Server Groups'
                kind='server_group'
                context='list'
                render={(props) => (
                    <ServerGroupListTable {...props} disableDefaultGroup={DefaultGroupSelectWarning} />
                )}
                searchDef={serverGroupListSearchDef}
                searchComponent={ServerGroupSearch}
                sortFields={serverGroupSortFields}
                selector={selector}
            />
        </>
    );
}