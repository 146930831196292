// @flow
import { ResourceChipFetch, OrbitContainerChip, Chip, trimString } from '../../element/Chip';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../element/SvgIcon';
import { useOrbitContainers } from '../../hoc/Orbit';
import { useSelector } from 'react-redux';
import { RC_NOT_FOUND } from '../../../state/resource/type';

import type { SearchResultCategory, SearchResult } from '../../hoc/GlobalSearch';
import type { CloudGuiState } from '../../../state/cloudgui';

type SearchResultItemProps = {
    +item: SearchResult,
}

function SearchResultItem({ item, }: SearchResultItemProps): React$Node {
    const { containers, } = useOrbitContainers();

    switch(item.type) {
    case 'resource':
        return <ResourceChipFetch id={item.id} titleLines={1} />;
    case 'text':
        return item.text;
    case 'container':
        return <OrbitContainerChip name={item.id} bytes={containers[item.id]?.bytes} titleLines={1} />
    case 'na':
        return '';
    case 'docs':
        return (
            <Chip
                id={trimString(item.link.replace('https://www.brightbox.com/', ''), 38)}
                name={item.title}
                icon='world'
                titleLines={1}
            />
        );
    default:
        void (item.type: empty);
        return null;
    }
}

type SearchResultListProps = {
    +results: ?$ReadOnlyArray<?SearchResultCategory>,
    +addPreviousSearch: (value: string) => void,
    +onClick: () => void,
}

function linkFocusMouseOver(e: MouseEvent) {
    // $FlowFixMe incompatible-type focus() exists. But check anyway!
    if (e.currentTarget.focus) e.currentTarget.focus();
}

export function SearchResultList({ results, addPreviousSearch, onClick, }: SearchResultListProps): React$Node {
    let ti = 100;
    // $FlowFixMe incompatible-type this conversion works.
    const categories: $ReadOnlyArray<SearchResultCategory> = (results || []).filter(x => x != null);
    const skipResourceIds: Set<string> = useSelector((state: CloudGuiState) => {
        return new Set<string>(Object.keys(state.Resource).reduce((acc, key) => {
            state.Resource[key].cacheStatus.forEach((status, id) => {
                if (status === RC_NOT_FOUND) acc.push(id);
            });
            return acc;
        }, []));
    });

    return (
        <div className='c-search-result' onClick={() => onClick()}>
            <ul>
                {categories.map(({ title, items, ...category }) => [
                    <li key={title} className='c-search-result__header'>{title}</li>,
                    ...items.map((item, i) => (
                        <li key={i}>
                            {item.type === 'na'
                                ? <span className='c-search-result__item'>
                                    <span className='c-search-result__item-content'>{item.label || 'n/a'}</span>
                                </span>
                                : null
                            }
                            {item.type === 'docs'
                                ? <a
                                    href={item.link}
                                    className='c-search-result__item'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    tabIndex={ti++}
                                    onMouseOver={linkFocusMouseOver}
                                    onClick={category.addSearchHistory ? addPreviousSearch.bind(null, category.addSearchHistory) : null}
                                >
                                    <span className='c-search-result__item-content'>
                                        <SearchResultItem item={item}/>
                                    </span>
                                    <SvgIcon svg='new-window' className='c-search-result__item-action'/>
                                </a>
                                : null
                            }
                            {item.type !== 'na' && item.type !== 'docs' && !skipResourceIds.has(item.id || '')
                                ? <Link
                                    to={item.link || ''}
                                    className='c-search-result__item'
                                    target={item.type === 'docs' ? '_blank' : null}
                                    tabIndex={ti++}
                                    onMouseOver={linkFocusMouseOver}
                                    onClick={category.addSearchHistory ? addPreviousSearch.bind(null, category.addSearchHistory) : null}
                                >
                                    <span className='c-search-result__item-content'>
                                        <SearchResultItem item={item}/>
                                    </span>
                                    <SvgIcon svg='arrow-down-left' className='c-search-result__item-action'/>
                                </Link>
                                : null
                            }
                        </li>
                    ))
                ])}
            </ul>
        </div>
    );
}