// @flow
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar, PanelBar } from '../../element/Panel';
import { useOrbitObjectView } from '../../hoc/orbit/Object';
import { SkeletonBar } from '../../element/Skeleton';
import { formatDateTime, formatOrbitSize } from '../../element/Styled';
import { useOrbitAccessKeys, getObjectDownloadUrl } from '../../hoc/orbit/Container';
import { CreateKeysDialog } from './CreateKeysDialog';
import { PathBreadcrumb, usePathBreadcrumb } from './PathBreadcrumb';
import { useDialog } from '../../element/Dialog';
import { DeleteDialog } from '../../common/CommonDialogs';
import { RC_INITIAL, RC_CACHED, RC_NOT_FOUND, RC_FETCHING } from '../../../state/resource/type';
import { Notice } from '../../element/Notice';
import { ObjectTemporaryUrlCreateDialog, useObjectTemporaryUrl, ObjectTemporaryUrlViewDialog } from './ObjectTemporaryUrl';
import { useCurrentAccountId } from '../../hoc/lib';

import type { Match } from 'react-router';
import type { OrbitObject } from '../../../api/type.orbit';

type OrbitObjectViewProps = {
    container: string,
    name: string,
    match: Match,
};

export const OrbitObjectView = ({ container, name, match }: OrbitObjectViewProps): React$Node => {
    const { path, paths, marker, } = usePathBreadcrumb();
    const { object, deleteObject, basename, cacheStatus, } = useOrbitObjectView(container, name);
    const { confirmCreateKeys, confirmKeysDialog, messages, updateDelayed, } = useOrbitAccessKeys(container);
    const currAccountId = useCurrentAccountId() || '';
    const tempUrlHook = useObjectTemporaryUrl(container, object, confirmCreateKeys);
    const deleteObjectDialog = useDialog<OrbitObject>([
        {
            label: 'Delete',
            kind: 'primary',
            color: 'red',
            onSelect: () => {
                if (deleteObject) {
                    deleteObject();
                }
            }
        }
    ]);
    const download = async () => {
        if (object) {
            const key = await confirmCreateKeys('download', object);
            const link: ?[URL, Date] = key ? getObjectDownloadUrl(60, currAccountId, container, object, key, 'GET') : null;
            if (link != null) window.open(link[0]);
        }
    };

    return (
        <ResourceAddViewRoute
            match={match}
            dialog={<>
                <CreateKeysDialog dialog={confirmKeysDialog} messages={messages} updateDelayed={updateDelayed} />
                <DeleteDialog name={(data: ?OrbitObject) => data?.name || 'this object'} dialog={deleteObjectDialog} title="Delete Object?"/>
                <ObjectTemporaryUrlCreateDialog hook={tempUrlHook} />
                <ObjectTemporaryUrlViewDialog dialog={tempUrlHook.viewDialog} />
            </>}
            view={
                <Panel>
                    <PanelHeader
                        title={basename}
                        description={
                            <PathBreadcrumb
                                path={path}
                                paths={paths}
                                marker={marker}
                                objectName={basename}
                            />
                        }
                        actions={{
                            delete: () => deleteObjectDialog.show(),
                            download: () => {
                                // noinspection JSIgnoredPromiseFromCall
                                download();
                            },
                            share: () => {
                                tempUrlHook.show()
                            }
                        }}
                    />
                    {cacheStatus === RC_NOT_FOUND
                        ? <PanelBar border={false}>
                            <Notice type='warning' icon='warning-fill'>
                                We couldn't find the object <b className='mx-1'>{basename}</b> – if you've only just uploaded it please check back shortly
                            </Notice>
                        </PanelBar>
                        : null
                    }
                    <PanelMultiSettingsBar
                        settings={[
                            [
                                {
                                    name: 'Object Path',
                                    summary: name,
                                    copyValue: name,
                                },
                            ],
                            ...((cacheStatus === RC_INITIAL || cacheStatus === RC_CACHED || cacheStatus === RC_FETCHING)
                                ? [[
                                    {
                                        name: 'Content Type',
                                        summary: object ? object.content_type : <SkeletonBar/>,
                                    },
                                    {
                                        name: 'Last Modified',
                                        summary: object ? formatDateTime(object.last_modified) : <SkeletonBar/>,
                                    },
                                    {
                                        name: 'Size',
                                        summary: object ? formatOrbitSize(object.bytes) : <SkeletonBar/>,
                                    },
                                ]]
                                : []),
                        ]}
                    />


                </Panel>
            }
        />
    );
}
