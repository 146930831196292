// @flow
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useListResource, useResourcesById } from '../hoc/ListPage';
import { Avatar } from '../element/Avatar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getSupportedImages, filterSupportImageTypes } from '../../lib/SupportedImageFilter';
import { ValueSelect } from '../element/ValueSelect';
import { useGroupedItemState } from '../element/lib/groupedItemState';
import { Card } from '../element/Card';
import { CardGroup } from '../element/CardGroup';
import { Panel, PanelBar, PanelHeader, PanelButtonBar, } from '../element/Panel';
import { useSelectorListGrid } from '../element/UiStateHooks';
import { createImageSearch } from '../section/image/ImageSearch';
import { SkeletonCardSelector } from '../element/Skeleton';
import { ResourceSelector } from './ResourceSelector';
import { ImageListTable } from '../section/image/ImageList';
import { useCurrentAccount } from '../hoc/lib';
import { imageSortFields } from '../section/image/def';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../lib/history';
import { isDeleted } from '../../api/lib';

import type { BbCollectedImage } from '../../api/type';
import type { IdSelectEditor } from './ResourceSelector';
import type { ListSortDef } from '../hoc/ListPage';
import type { GroupedChoices } from '../element/lib/groupedItemState';
import type { ImageOrVolume } from '../hoc/CreateResource';


const { searchDef, ImageSearch } = createImageSearch('image:selector');

function isSnapshot(accountId: string, image: BbCollectedImage): boolean {
    return image.owner === accountId;
}

type CardSelectorProps = {
    +allItems: $ReadOnlyArray<BbCollectedImage>,
    +selected: ?BbCollectedImage,
    +onSelect: $PropertyType<IdSelectEditor, 'setValue'>,
}

function useSelectDefault(selected: $PropertyType<CardSelectorProps, 'selected'>, onSelect: $PropertyType<CardSelectorProps, 'onSelect'>, supported: GroupedChoices<BbCollectedImage>) {
    const [run, setRun] = useState(false);

    useEffect(() => {
        if (!run && selected === null && supported.ubuntu.default) {
            setRun(true);
            onSelect([supported.ubuntu.default.id]);
        }
    }, [run, setRun, selected, supported, onSelect])
}

const CardSelector = ({ allItems, selected, onSelect, }: CardSelectorProps): React$Node => {
    const supported = useMemo(() => getSupportedImages(allItems), [allItems]);
    const getRoute = useResourceRoutes();
    const account = useCurrentAccount();

    useSelectDefault(selected, onSelect, supported);

    const {
        selectGroup: selectDistro,
        selectItem: selectImage,
        internalSelected
    } = useGroupedItemState<BbCollectedImage>(selected, supported, (image: BbCollectedImage) => onSelect([image.id]));

    return (
        <PanelBar>
            <CardGroup>
            {Object.keys(supported).map((distro: string) => {
                if (distro === 'windows' && (account == null || account.windows_servers_limit === 0)) {
                    return null;
                }

                const details = supported[distro];

                return (
                    <Card
                        key={distro}
                        selectable={true}
                        selected={internalSelected.group === distro}
                        onClick={() => selectDistro(distro)}
                    >
                        <div className='c-distro-card'>
                            <h4 className='c-distro-card__title'>
                                <Avatar imageName={distro} className='c-distro-card__avatar' />{details.label}
                            </h4>
                            <ValueSelect
                                selected={internalSelected.selectedForGroup[distro] || details.default}
                                options={details.items}
                                size='xs'
                                onSelect={(image) => selectImage(image, distro)}
                                className='c-distro-card__version-select'
                            />
                        </div>
                    </Card>
                );
            })}
            </CardGroup>
            <p className='ml-2 text-sm text-gray-600'><Link to={getRoute('support')} className='ml-1'>Don't see an OS or version you need here?</Link></p>
        </PanelBar>
    );
};

type ImageSelectorProps = {
    +editor: IdSelectEditor,
    +hasDetachedVolume: boolean,
    +setImageOrVolume: (imageOrVolume: ImageOrVolume) => void,
};

export const ImageSelector = ({ editor, hasDetachedVolume, setImageOrVolume, }: ImageSelectorProps): React$Node => {
    const images = useListResource<BbCollectedImage>('image', sortDef);
    const { resources: imagesById } = useResourcesById<BbCollectedImage>('image');
    const listGrid = useSelectorListGrid('grid');
    const account = useCurrentAccount();
    const currAccountId = account ? account.id : '';
    const isMyImageFilter = useCallback((image: BbCollectedImage) => isSnapshot(currAccountId, image) && !isDeleted(image.status), [currAccountId]);

    const allowWindows = account && account.windows_servers_limit > 0;
    const allImagesFilter = useCallback(
        (image: BbCollectedImage) => !isDeleted(image.status) && (allowWindows || !(image.official && image.name.startsWith('Windows'))),
        [allowWindows]
    )

    const { value: selectedArray, setValue: onSelect } = editor;
    const selected: ?BbCollectedImage = selectedArray?.length ? imagesById?.[selectedArray[0]] : null;
    // If we have cached the images, we can choose between "My Images" / "All Images".
    // But if we can't, fallback to "All Images" if an image is pre-selected
    let defaultIndex = 0;
    if (selected != null && isSnapshot(currAccountId, selected)) defaultIndex = 1;
    else if (
        (selected != null && !filterSupportImageTypes(selected))
        || (selected == null && (editor.value?.length || 0) > 0)
    ) {
        defaultIndex = 2;
    }

    return (
        <Panel>
            <PanelHeader
                title='Choose an Operating System Image'
            />
            <Tabs defaultIndex={defaultIndex}>
                <TabList>
                    <Tab>Officially Supported Images</Tab>
                    <Tab>My Images</Tab>
                    <Tab>All Images</Tab>
                </TabList>

                <TabPanel>
                    {images.items.length > 0
                        ? <CardSelector allItems={images.items} selected={selected} onSelect={onSelect} />
                        : <SkeletonCardSelector />
                    }
                </TabPanel>

                <TabPanel>
                    <ResourceSelector
                        selector={{
                            editor,
                            selectSingle: true,
                        }}
                        kind='image'
                        sortFields={sortDef.fields}
                        searchDef={searchDef}
                        searchComponent={ImageSearch}
                        listGrid={listGrid}
                        table={ImageListTable}
                        itemsFilter={isMyImageFilter}
                    />
                </TabPanel>

                <TabPanel>
                    <ResourceSelector
                        selector={{
                            editor,
                            selectSingle: true,
                        }}
                        kind='image'
                        sortFields={sortDef.fields}
                        searchDef={searchDef}
                        searchComponent={ImageSearch}
                        searchIds={true}
                        listGrid={listGrid}
                        table={ImageListTable}
                        itemsFilter={allImagesFilter}
                    />
                </TabPanel>

            </Tabs>
            {hasDetachedVolume
                ? <PanelButtonBar
                    className='c-panel__section--force-border-top'
                    leftButton={{
                        kind: 'tertiary',
                        onClick: () => setImageOrVolume('volume'),
                        preIcon: 'swap',
                        children: 'Use an existing Volume instead?',
                    }}
                />
                : null
            }
        </Panel>
    );
};

const sortDef: ListSortDef<BbCollectedImage> = {
    name: 'image_selector',
    fields: imageSortFields
};
