// @flow
import { Link } from 'react-router-dom';
import { formatDate, RightArrow, formatMegabytesSize } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { ResourceChip, ResourceChipFetch } from '../../element/Chip';
import { volSortFields, volSearchDef, useVolumeListActions, } from './def';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, } from '../../element/Skeleton';
import { ItemSelector } from '../../element/ItemSelector';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';
import { TextOnlySearchEditor } from '../../common/TextOnlySearch';
import { Button } from '../../element/Button';
import { useResourceRoutes } from '../../../lib/history';

import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';
import type { BbVolume } from '../../../api/type.volume';


export const VolListTable = ({ Th: SortTh, items, search, itemSelect, status, totalCount, context, }: ResourceSelectorTableProps<BbVolume>): React$Node => {
    const listContext = context === 'list';
    const getRoute = useResourceRoutes();

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={5}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='name'>Name</SortTh>
                        <SortTh field='size'>Size</SortTh>
                        {listContext
                            ? <SortTh field='attached_to'>Attached To</SortTh>
                            : null
                        }
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((vol: BbVolume) =>
                <Tr
                    key={vol.id}
                    {...itemSelect?.rowProps(vol)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={vol} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}>
                        <ResourceChip concise={true} resource={vol} link={itemSelect?.selectedLength === 0 ? `${vol.id}/` : null}/>
                    </Td>
                    <Td>{formatMegabytesSize(vol.size)}</Td>
                    {listContext
                        ? <Td>
                            {vol.server
                                ? <ResourceChipFetch id={vol.server.id} link={getRoute('server', vol.server.id)}/>
                                : <Link to={`${vol.id}/attach/`}>
                                    <Button kind='bare'>
                                        + Attach
                                    </Button>
                                </Link>
                            }
                        </Td>
                        : null
                    }
                    <Td>{vol.created_at? formatDate(vol.created_at) : '-'}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={`${vol.id}/`}>
                                <RightArrow />
                            </Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonChip /></Td>
                        <Td><SkeletonBar size='md' /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={3} kind='volume' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={3} context={context} kind='volume' />
            </tbody>
        </Table>
    );
};

export const VolList = (): React$Node => {
    const { deleteDialog, detachDialog, lockDialog, unlockDialog, ...selector } = useVolumeListActions();

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Volumes?`}
                confirmString='delete'
                render={() => (
                    <div>Are you sure you want to delete these Volumes?</div>
                )
                }
            />
            <LockDialogs name={'these Volumes'} lockDialog={lockDialog} unlockDialog={unlockDialog} count={selector.editor?.value?.length}/>
            <Dialog
                dialog={detachDialog}
                title={() => `Detach ${selector.editor?.value?.length || 'these'} Volumes?`}
                confirmString='detach'
                render={() => (
                    <div>Are you sure you want to detach these Volumes?</div>
                )
                }
            />

            <ResourceListPage
                title='Volumes'
                kind='volume'
                context='list'
                table={VolListTable}
                searchDef={volSearchDef}
                searchComponent={TextOnlySearchEditor}
                sortFields={volSortFields}
                selector={selector}
                checkLimit='block_storage'
            />
        </>
    );
}