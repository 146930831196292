// @flow
import { useViewResource, } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { serversToIds } from '../../common/ServerSelector';
import { LbaNodeSelector, LbaNodeView } from './edit/LbaNodes';
import { ResourceCloudIpEdit, ResourceCloudIpView, useEditResourceCloudIps } from '../../common/ResourceCloudIps';
import { LbaListener } from './edit/LbaListener';
import { editHttps, LbaHttps, listenerIsHttps, listHasHttpsListener } from './edit/LbaHttps';
import { lbaNeedsHttps, nodesFromSelected, validateHealthcheck, validateHttps, validateListeners } from './edit/lib';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useResourceEditorModal } from '../../common/Editor';
import { LbaHttpsRedirectEdit, LbaPolicyEdit, LbaSslMinVerEdit, lbaAdvancedProps } from './edit/LbaAdvanced';
import { LockDialogs, useResourceLockDialogs, DeleteDialog, useDeleteDialog } from '../../common/CommonDialogs';
import { LbaHttpsEdit } from './edit/LbaHttpsEdit';
import { editListeners, LbaListenerEdit } from './edit/LbaListenerEdit';
import { emptyErrors } from '../../common/lib';
import { LbaHealthcheckEdit } from './edit/LbaHealthcheckEdit';
import { NameEditPanel } from '../../element/NameEditPanel';
import { useBuildingResource } from '../../hoc/Build';
import { getMonthlyPrice } from '../../../lib/pricing';
import { MonthlyPrice } from '../../element/MonthlyPrice';
import { LABELS } from '../../element/ResourceLabels';
import { LbaMetrics } from '../../common/Metrics/LbaMetrics';

import type { EditorDef } from '../../common/Editor';
import type { FormErrors } from '../../common/lib';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { BbLba, BbLbaParams, BbLbaPolicy, BbLbaSslMinVer } from '../../../api/type.lba';
import type { Match } from 'react-router-dom';
import type { EditHealthcheck, EditHttps, EditListener } from './edit/type';

type LbaViewer = ViewResourceProps<BbLba, BbLbaParams>;

const nameDef: EditorDef<BbLba, string, BbLbaParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbLba) => res.name || '',
    onValidate: null,
};
const nodesDef: EditorDef<BbLba, $ReadOnlyArray<string>, BbLbaParams, null> = {
    emptyErrors: null,
    editUri: 'servers',
    onEdit: (lba: BbLba) => serversToIds(lba.nodes),
    onValidate: (resource: LbaViewer, server_ids: $ReadOnlyArray<string>) => [null, nodesFromSelected(server_ids),],
};
const listenersDef: EditorDef<BbLba, $ReadOnlyArray<EditListener>, BbLbaParams, FormErrors> = {
    emptyErrors,
    editUri: 'listeners',
    onEdit: (lba: BbLba) => editListeners(lba.listeners),
    onValidate: (resource: LbaViewer, listeners: $ReadOnlyArray<EditListener>) => validateListeners(lbaNeedsHttps(resource.item), listeners),
};
const httpsDef: EditorDef<BbLba, EditHttps, BbLbaParams, FormErrors> = {
    emptyErrors,
    editUri: 'https',
    onEdit: (lba: BbLba) => editHttps(lba),
    onValidate: (resource: LbaViewer, editHttps: EditHttps) => {
        const { item } = resource;

        const hasHttpsListener = item ? listHasHttpsListener(item.listeners) : false;
        const [errors, commit] = validateHttps(editHttps);

        if (commit && item) {
            if ((editHttps.method !== 'none' || editHttps.https_redirect) && !hasHttpsListener) {
                commit.listeners = [].concat(item.listeners, {
                    in: 443,
                    out: 80,
                    timeout: 50000,
                    protocol: 'https',
                    proxy_protocol: null,
                });
            }
            if (editHttps.method === 'none' && hasHttpsListener) {
                commit.listeners = item.listeners.filter(l => !listenerIsHttps(l));

                if (commit.listeners.length === 0) {
                    return [
                        new Map([
                            ['none', 'HTTPS cannot be disabled until the Load Balancer has a non-HTTPS listener'],
                        ]), null
                    ]
                }
            }
        }
        return [errors, commit];
    },
};
const policyDef: EditorDef<BbLba, BbLbaPolicy, BbLbaParams, null> = {
    emptyErrors: null,
    editUri: 'policy',
    onEdit: (lba: BbLba) => lba.policy,
    onValidate: null,
};
const healthcheckDef: EditorDef<BbLba, EditHealthcheck, BbLbaParams, FormErrors> = {
    emptyErrors,
    editUri: 'healthcheck',
    onEdit: (lba: BbLba) => lba.healthcheck,
    onValidate: (resource: LbaViewer, editHealthcheck: EditHealthcheck) => validateHealthcheck(editHealthcheck),
};
const https_redirectDef: EditorDef<BbLba, boolean, BbLbaParams, null> = {
    emptyErrors: null,
    editUri: 'https_redirect',
    onEdit: (lba: BbLba) => lba.https_redirect,
    onValidate: null,
};
const ssl_minimum_versionDef: EditorDef<BbLba, BbLbaSslMinVer, BbLbaParams, null> = {
    emptyErrors: null,
    editUri: 'ssl_minimum_version',
    onEdit: (lba: BbLba) => lba.ssl_minimum_version,
    onValidate: null,
};

export const LbaView = ({ id, match, }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbLba, BbLbaParams>('load_balancer', id);
    const { item: lba, } = resource;
    const needsHttps = lbaNeedsHttps(lba);
    const progress = useBuildingResource(id);

    const cloudIp = useEditResourceCloudIps(id, match.path);
    const nodes = useResourceEditorModal(resource, nodesDef, match.path);
    const listeners = useResourceEditorModal(resource, listenersDef, match.path);
    const https = useResourceEditorModal(resource, httpsDef, match.path);
    const policy = useResourceEditorModal(resource, policyDef, match.path);
    const healthcheck = useResourceEditorModal(resource, healthcheckDef, match.path);
    const https_redirect = useResourceEditorModal(resource, https_redirectDef, match.path);
    const name = useResourceEditorModal(resource, nameDef, match.path);
    const ssl_minimum_version = useResourceEditorModal(resource, ssl_minimum_versionDef, match.path);
    const active = lba?.status === 'active';

    const { panelActions: lockActions, ...lockDialogs } = useResourceLockDialogs(active ? lba?.locked : null, resource.simpleAction);
    const [deletePanelAction, deleteDialog] = useDeleteDialog(active && !lba?.locked, resource.deleteAction);

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.load_balancer.listTitle}
            resourceName={lba?.name || lba?.id || ''}
            resource={resource}
            match={match}
            dialog={
                <>
                    <LockDialogs name={lba?.name || lba?.id || ''} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog}/>
                    <DeleteDialog name={lba?.name || lba?.id || ''} dialog={deleteDialog} />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={lba ? lba.name || lba.id : ''}
                            actions={{
                                ...deletePanelAction,
                                ...(active ? lockActions : null),
                            }}
                            editTitleLink={active ? name.editUri : null}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: lba?.id,
                                status: lba?.status,
                                progress,
                                kind: 'load_balancer',
                                created_at: lba?.created_at,
                            }}
                            settings={[
                                lbaAdvancedProps({
                                    cacheStatus: resource.status,
                                    https_redirect: [lba?.https_redirect, https_redirect],
                                    policy: [lba?.policy, policy],
                                    healthCheck: [lba?.healthcheck, healthcheck],
                                    ssl_minimum_version: [lba?.ssl_minimum_version, ssl_minimum_version],
                                    lock: [lba?.locked, lockDialogs],
                                    needsHttps: needsHttps,
                                    active,
                                }),
                                [
                                    {
                                        name: 'Cost',
                                        summary: <MonthlyPrice price={getMonthlyPrice('loadbalancer')} />,
                                    }
                                ]
                            ]}
                        />
                    </Panel>

                    <LbaMetrics id={id} />

                    <LbaNodeView
                        cacheStatus={resource.status}
                        selectedServers={lba?.nodes}
                        editUri={active ? nodes.editUri : null}
                    />

                    <ResourceCloudIpView
                        cacheStatus={resource.status}
                        resourceId={id}
                        resourceTitle={'Load Balancer'}
                        editUri={active || lba?.status === 'creating' ? cloudIp.editor.editUri : null}
                    />

                    <LbaHttps
                        cacheStatus={resource.status}
                        editUri={active ? https.editUri : null}
                        acme={lba?.acme}
                        certificate={lba?.certificate}
                        hasCloudIp={lba ? lba.cloud_ips.length > 0 : false}
                    />

                    <LbaListener
                        cacheStatus={resource.status}
                        editUri={active ? listeners.editUri : null}
                        listeners={lba?.listeners}
                        needsHttps={needsHttps}
                    />

                </>
            }
            editors={[
                { editor: nodes, component: LbaNodeSelector },
                {
                    editor: cloudIp.editor,
                    render: () =>
                        <ResourceCloudIpEdit
                            resourceId={id}
                            resourceTitle={'Load Balancer'}
                            edit={cloudIp}
                        />,
                },
                { editor: https, render: () => (
                    <LbaHttpsEdit
                        editor={https}
                        hasCloudIp={lba ? lba.cloud_ips.length > 0 : false}
                        acme={lba?.acme}
                    />
                    ) },
                { editor: listeners, render: () => (
                        <LbaListenerEdit
                            editor={listeners}
                            needsHttps={needsHttps}
                            cacheStatus={resource.status}
                        />
                    )},
                { editor: policy, component: LbaPolicyEdit, },
                { editor: healthcheck, component: LbaHealthcheckEdit, },
                { editor: https_redirect, component: LbaHttpsRedirectEdit, },
                { editor: ssl_minimum_version, component: LbaSslMinVerEdit, },
                { editor: name, render: () => <NameEditPanel editor={name}/> },
            ]}
        />
    );
};

