// @flow
import { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCloseOnFocusLoss } from '../common/lib';
import { request, addDevUrlOverride, } from '../../api/rest';
import { authUrl, usersUrl, volumesUrl, serversUrl, cloudIpsUrl, serverGroupsUrl, imagesUrl } from '../../api/url';
import to from 'await-to-js';
import { ResourceChip, initial } from '../element/Chip';
import { Avatar } from '../element/Avatar';
import { SvgIcon } from '../element/SvgIcon';
import { useResourceRoutes } from '../../lib/history';
import { Spinner } from '../element/Spinner';
import { RelatedPopover } from '../element/RelatedPopover';
import { useBuildingResources } from '../hoc/Build';
import { GlobalSearch } from '../common/GlobalSearch/GlobalSearch';
import { LABELS } from '../element/ResourceLabels';
import { devFeaturesDisable, toggleDevFeaturesOverride } from '../hoc/Metadata';

import type { AuthState } from '../../state/auth/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { Dispatch } from 'redux';

// catch this to silence the error.
const rejected = Promise.reject({ status: 403 }).catch((x) => x);

function makeResourcesFail(dispatch: Dispatch<any>) {
    addDevUrlOverride(volumesUrl, rejected);
    addDevUrlOverride(serversUrl, rejected);
    addDevUrlOverride(cloudIpsUrl, rejected);
    addDevUrlOverride(serverGroupsUrl, rejected);
    addDevUrlOverride(imagesUrl, rejected);
    dispatch({type: 'RESOURCE_FORCE_REFRESH'});
}

function DevUserNav(): React$Node {
    const dispatch = useDispatch();

    const triggerCookieTimeout = async () => {
        await request({
            url: authUrl,
            method: 'DELETE',
        });
        await to(request({
            url: usersUrl,
            method: 'GET',
        }));
    };

    const deleteCookies = async () => {
        await request({
            url: authUrl,
            method: 'DELETE',
        });
    }

    const fakeContainerNewApiClient = () => {
        dispatch({
            type: 'SECRET_SET',
            payload: {
                id: 'cli-12345',
                value: 'asdasd',
            },
        });
        dispatch({
            type: 'SECRET_SET_ID',
            payload: {
                key: 'somenewcomtnaienre_ctrimages',
                value: 'cli-12345',
            },
        });
    }

    const showJwsTimeout = async () => {
        await request({
            method: 'HEAD',
            url: usersUrl,
            headers: {
                'X-JWT-Extend': 'off',
            }
        });
    }

    function toggleDevFeatures()  {
        toggleDevFeaturesOverride();
        // we don't actually need to re-fetch the resources,
        // but since the dev override doesn't change a variable
        // in the store, this will do to force the gui to respect
        // the new value.
        dispatch({type: 'RESOURCE_FORCE_REFRESH'});
    }

    return (
        <>
            <li>
                <hr/>
            </li>
            <li className='text-gray-500 text-xs uppercase pt-3 pl-5'>
                Dev only
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={triggerCookieTimeout}>Fake Cookie Timeout</a>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={deleteCookies}>Delete Cookies Silently</a>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={fakeContainerNewApiClient}>Fake new API client</a>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={() => makeResourcesFail(dispatch)}>Break Resource Collections</a>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link className='c-user-nav__menu-link' to='/no-account'>The no-account page</Link>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={() => showJwsTimeout()}>Check the timeout</a>
            </li>
            <li className="c-user-nav__menu-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='c-user-nav__menu-link' href='#' onClick={() => toggleDevFeatures()}>
                    Toggle Features Disable
                    <br/>Current: {devFeaturesDisable ? 'Features globally disabled' : 'Features match API'}
                </a>
            </li>
        </>
    );
}

export const UserNav = (): React$Node => {
    const { currUser, eventsConnected } = useSelector<CloudGuiState, AuthState>((state: CloudGuiState) => state.Auth);
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
    const [showHelpMenu, setShowHelpMenu] = useState<boolean>(false);
    const getRoute = useResourceRoutes();
    const [building, orbitDeleting, pendingActions] = useBuildingResources();

    const userMenuRef = useCloseOnFocusLoss(showUserMenu, setShowUserMenu);
    const helpMenuRef = useCloseOnFocusLoss(showHelpMenu, setShowHelpMenu);

    return (
        <div className='c-user-nav'>

            {building.length || orbitDeleting.length || pendingActions.length
                ? <RelatedPopover
                    building={building}
                    orbitDeleting={orbitDeleting}
                    pendingActions={pendingActions}
                    placement='bottomRight'
                >
                    <div className='c-user-nav__item  c-user-nav__build-indicator'>
                        <Spinner size='sm' color='gray' /><span className='ml-2'>Tasks In Progress</span>
                    </div>
                </RelatedPopover>
                : null
            }
            {process.env.NODE_ENV === 'development'
                ? eventsConnected
                    ? <div className='c-user-nav__item' title='Connected to Brightbox API'>
                        <SvgIcon svg='pulse' className='text-green-500' />
                    </div>
                    : <div className='c-user-nav__item' title='Not connected to Brightbox API; data may not be up-to-date'>
                        <SvgIcon svg='pulse' className='text-red-500' />
                    </div>
                : null
            }

            <div className='c-user-nav__item'>
                <GlobalSearch />
            </div>

            <div className='c-user-nav__item' ref={helpMenuRef}  onClick={() => setShowHelpMenu(!showHelpMenu)}>
                <SvgIcon svg='help' className='c-user-nav__link' />
            </div>

            {currUser
                ? <div className='c-user-nav__item' ref={userMenuRef} onClick={() => setShowUserMenu(!showUserMenu)}>
                    <Avatar
                        initial={initial(currUser)}
                        colorId={currUser.id}
                        gravatar_url={currUser.gravatar_url}
                        className='c-user-nav__avatar'
                    />
                </div>
                : null
            }

            {showHelpMenu
                ? <ul className="c-user-nav__menu">
                    <li className="c-user-nav__menu-item">
                        <a href='https://www.brightbox.com/docs/' target='_blank' rel='noopener noreferrer' className='c-user-nav__menu-link'>Documentation</a>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <a href='https://status.brightbox.com/' target='_blank' rel='noopener noreferrer' className='c-user-nav__menu-link'>System Status</a>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <a href='https://www.brightbox.com/docs/changelog/' target='_blank' rel='noopener noreferrer' className='c-user-nav__menu-link'>Changelog</a>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <Link to={getRoute('support')} className='c-user-nav__menu-link'>Contact Support</Link>
                    </li>
                </ul>
                : null
            }

            {showUserMenu ?
                <ul className="c-user-nav__menu">
                    {currUser ?
                        <li>
                            <Link to='/user/#settings'>
                                <ResourceChip
                                    resource={currUser}
                                    className='c-user-nav__menu-chip'
                                />
                            </Link>
                        </li>
                        : null}
                    <hr/>
                    <li className="c-user-nav__menu-item">
                        <Link className='c-user-nav__menu-link' to='/user/#settings'>User Settings</Link>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <Link className='c-user-nav__menu-link' to='/user/#accounts'>{LABELS.account.listTitle}</Link>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <Link className='c-user-nav__menu-link' to='/user/#application'>OAuth Applications</Link>
                    </li>
                    <li>
                        <hr/>
                    </li>
                    <li className="c-user-nav__menu-item">
                        <Link className='c-user-nav__menu-link' to='/logout/'>Logout</Link>
                    </li>

                    {process.env.NODE_ENV === 'development'

                        ? <DevUserNav />
                        : null}

                </ul>
                : null}

        </div>
    );
};
