// @flow
import { Link } from 'react-router-dom';
import { LbaSearch, } from './LbaSearch';
import { formatDate, RightArrow } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { lbaSearchDef, lbaSortFields, loadBalancerSelectable } from './def';
import { ResourceChip } from '../../element/Chip';
import { ResourceServerTd } from '../../common/ServerSelector';
import { ResourceCloudIpTd } from '../../common/ResourceCloudIps';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, SkeletonListRelated } from '../../element/Skeleton';
import { ItemSelector } from '../../element/ItemSelector';
import { useLockDeleteListActions } from '../../hoc/ListActions';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';

import type { BbCollectedLba, BbLbaStatus } from '../../../api/type.lba';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';

const isDetailsStatus = (status: BbLbaStatus) => (status === 'active' || status === 'creating');

export const LbaListTable = ({ items, Th: SortTh, context, itemSelect, search, status, totalCount, } : ResourceSelectorTableProps<BbCollectedLba>): React$Node => {
    const listContext = context === 'list';

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={5}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='name'>Name</SortTh>
                        <SortTh field='cloud_ips'>Cloud IPs</SortTh>
                        <SortTh field='nodes'>Backends</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((lba: BbCollectedLba) =>
                <Tr
                    key={lba.id}
                    {...itemSelect?.rowProps(lba)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={lba} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}>
                        <ResourceChip resource={lba} link={itemSelect?.selectedLength === 0 ? `${lba.id}/` : null} />
                    </Td>
                    <Td>
                        {isDetailsStatus(lba.status)
                            ? <ResourceCloudIpTd
                                id={lba.id}
                                cloud_ips={lba.cloud_ips}
                                canAdd={listContext && (lba.status === 'creating' || lba.status === 'active')} />
                            :null}
                    </Td>
                    <Td>
                        {isDetailsStatus(lba.status)
                            ? <ResourceServerTd
                                id={lba.id}
                                servers={lba.nodes}
                                editLabel='Edit Nodes'
                                showAddButton={listContext}
                            />
                            : null}

                    </Td>
                    <Td>{formatDate(lba.created_at)}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={`${lba.id}/`}>
                                <RightArrow />
                            </Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonListRelated /></Td>
                        <Td><SkeletonListRelated /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={6} kind='load_balancer' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={6} context='list' kind='load_balancer' />
            </tbody>
        </Table>
    );
};

export const LbaList = (): React$Node => {
    const { deleteDialog, lockDialogs, ...selector } = useLockDeleteListActions('load_balancer', 'load balancers', loadBalancerSelectable);

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Load Balancers?`}
                confirmString='delete'
                render={() => (
                    <div>Are you sure you want to delete these Load Balancers?</div>
                )
                }
            />
            <LockDialogs name={'these Load Balancers'} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog} count={selector.editor?.value?.length}/>
            <ResourceListPage
                title='Load Balancers'
                kind='load_balancer'
                context='list'
                table={LbaListTable}
                searchDef={lbaSearchDef}
                searchComponent={LbaSearch}
                sortFields={lbaSortFields}
                checkLimit='load_balancers'
                selector={selector}
            />
        </>
    );
};