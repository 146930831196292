// @flow
import { createLogic } from 'redux-logic';
import { request, } from '../../api/rest';
import { to } from 'await-to-js';
import { adaptFirewallPolicy, adaptFirewallPolicyCollected, adaptServerGroup, resourceMetas } from '../../api/adapter';
import { userCollaborationsUrl, serverGroupsUrl, serversUrl, firewallPoliciesUrl } from '../../api/url';
import { handleApiError } from '../../lib/ErrorHandling';
import { doPatchResource, } from './ResourceLogic';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS, RC_FETCHING, RC_CACHED } from './type';

import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type {
    ResourceAction,
    ResourceAddFull,
    ResourceCreateFirewallPolicyAction,
    ResourceCreateKindAction,
    ResourceCreateServerGroupAction,
    ResourceDeleteCollaborationAction,
    ResourceServerConsoleAction,
    ResourceServerCreateAction,
    ResourceCollectedStatus,
    ResourceDeleteServerGroupAction, ResourceProcessing,
} from './type';
import type { BbServerGroup, BbCollaboration, } from '../../api/type';
import type { AuthAction } from '../auth/type';
import type { CloudIpMapAction, } from '../CloudIp/type';
import type { MessageAction } from '../Message/type';
import type { PatchDispatch } from './ResourceLogic';
import type { BbCollectedServer, BbServer } from '../../api/type.srv';
import type { ReduxLogic } from 'redux-logic';
import type { BbFirewallPolicy } from '../../api/type.fwp';

export const CREATE_GRP_MESSAGES_ID = 'create_grp';

/**
 * Creates a new server group, and then immediately adds the servers to it
 * that were selected.
 */
export const ResourceCreateServerGroupLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_CREATE_SERVER_GROUP'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceCreateServerGroupAction
        },
        dispatch: Dispatch<ResourceAction<BbServerGroup, any> | AuthAction | CloudIpMapAction | MessageAction>,
        done: () => void
    ) {
        const { server_ids, ...restParams } = deps.action.payload.params;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: CREATE_GRP_MESSAGES_ID,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        const [createErr, createResponse] = await to(request({
            method: 'POST',
            url: resourceMetas.server_group.url,
            data: restParams,
        }));

        if (!createErr && createResponse.status >= 200 && createResponse.status < 300) {
            let full = adaptServerGroup(createResponse.data);

            if (server_ids.length) {
                await to(request({
                    method: 'POST',
                    url: resourceMetas.server_group.url + '/' + createResponse.data.id + '/add_servers',
                    data: {
                        servers: server_ids.map(server => ({ server })),
                    },
                }));
            }

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: CREATE_GRP_MESSAGES_ID,
                    status: RC_SUCCESS,
                    resource: full,
                }
            });
        } else {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: CREATE_GRP_MESSAGES_ID,
                    status: RC_ERROR,
                    messages: createErr.response.data.errors,
                }
            });
        }

        done();
    },
});

export const CREATE_FWP_MESSAGES_ID = 'create_fwp';
export const CREATE_FWR_MESSAGES_ID = 'create_fwr';

export const ResourceCreateFirewallPolicyLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_CREATE_FIREWALL_POLICY'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceCreateFirewallPolicyAction
        },
        dispatch: Dispatch<ResourceAction<BbFirewallPolicy, any> | AuthAction | MessageAction>,
        done: () => void
    ) {
        const { rules, ...restParams } = deps.action.payload.params;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: CREATE_FWP_MESSAGES_ID,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        const [createErr, createResponse] = await to(request({
            method: 'POST',
            url: resourceMetas.firewall_policy.url,
            data: restParams,
        }));

        if (!createErr && createResponse.status >= 200 && createResponse.status < 300) {
            let full = adaptFirewallPolicy(createResponse.data);
            let collected = adaptFirewallPolicyCollected(createResponse.data);
            dispatch({
                type: 'RESOURCE_ADD_FULL',
                payload: {
                    kind: 'firewall_policy',
                    full,
                    collected
                },
            });

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: CREATE_FWP_MESSAGES_ID,
                    status: RC_SUCCESS,
                    resource: full,
                }
            });

            // After moving FWP to GRP page editing, we only expect to have a single one
            // of these - so just foreach it.  But see git history for use of RESOURCE_SET_FIREWALL_RULES
            // to edit an entire FWPs worth of rules.
            rules.forEach(rule => {
                dispatch({
                    type: 'RESOURCE_CREATE_KIND',
                    payload: {
                        kind: 'firewall_rule',
                        params: {
                            ...rule,
                            firewall_policy: createResponse.data.id,
                        },
                        cloudIp: null,
                        messagesId: CREATE_FWR_MESSAGES_ID,
                    }
                });
            });
        } else {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: CREATE_FWP_MESSAGES_ID,
                    status: RC_ERROR,
                    messages: createErr.response.data.errors,
                    resource: null,
                }
            });
        }

        done();
    },
});

export const ResourceServerConsoleLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_SERVER_CONSOLE'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceServerConsoleAction,
        },
        dispatch: Dispatch<ResourceCollectedStatus | ResourceAddFull<BbServer, BbCollectedServer> | ResourceProcessing>,
        done: () => void
    ) {
        const { id } = deps.action.payload;
        const state = deps.getState();

        const console_token = state.Resource.server.full?.[id]?.console_token;
        const token_expires = state.Resource.server.full?.[id]?.console_token_expires;

        if (console_token == null || token_expires == null || (new Date()) > token_expires) {
            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind: 'server', id, status: RC_FETCHING } });
            let [, response] = await to(request({
                method: 'POST',
                url: serversUrl + '/' + id + '/activate_console',
            }));

            if (response.status >= 200 && response.status < 300) {
                const full = resourceMetas.server.adapt.full(response.data);

                dispatch({
                    type: 'RESOURCE_ADD_FULL',
                    payload: {
                        kind: 'server',
                        full,
                    },
                });
            }

            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind: 'server', id, status: RC_CACHED } });
        }

        const nextServer: ?BbServer = deps.getState().Resource.server.full[id];
        const next_console_url = nextServer?.console_url;
        const next_console_token = nextServer?.console_token;
        const next_token_expires = nextServer?.console_token_expires;

        if (next_console_token != null && next_token_expires != null && next_token_expires > (new Date()) && next_console_url != null) {
            window.open(
                `${next_console_url}?password=${next_console_token}`,
                '_blank',
                'menubar=no,location=yes,resizable=yes,scrollbars=yes,status=no'
            );
        }

        done();
    },
});

export const DELETE_COLLAB_MESSAGES = 'delete_collab';

export const ResourceDeleteCollaborationLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_DELETE_COLLABORATION'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceDeleteCollaborationAction,
        },
        dispatch: Dispatch<ResourceAction<BbServer, any> | MessageAction>,
        done: () => void
    ) {
        const { accountId, userId } = deps.action.payload;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: DELETE_COLLAB_MESSAGES,
                status: RC_API_REQUEST,
                resource: null,
            },
        });

        let [collabErr, collabs] = await(to(request({
            url: userCollaborationsUrl,
            accountId: false,
        })));

        if (collabErr) {
            handleApiError(collabErr);
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: DELETE_COLLAB_MESSAGES,
                    status: RC_ERROR,
                    resource: null,
                },
            });
            done();
            return;
        }

        const collab = collabs.data.find((collab: BbCollaboration) =>
            (collab.user && collab.user.id === userId)
            && (collab.account && collab.account.id === accountId)
            && collab.finished_at == null
        );

        if (collab != null) {
            let [deleteErr, deleted] = await to(request({
                url: userCollaborationsUrl + '/' + collab.id,
                method: 'DELETE',
                accountId: false,
            }));

            if (deleteErr) {
                handleApiError(deleteErr);
            }

            if (deleted.status >= 200 && deleted.status < 300) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: DELETE_COLLAB_MESSAGES,
                        status: RC_SUCCESS,
                        messages: [ 'Deleted' ],
                        resource: null,
                    },
                });
            }
        }

        done();
    },
});

export const toServersObject = (server: string): ({ server: string }) => ({ server });

export const ResourceDeleteServerGroupLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_DELETE_SERVER_GROUP'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceDeleteServerGroupAction,
        },
        dispatch: Dispatch<ResourceAction<BbServer, any> | MessageAction>,
        done: () => void
    ) {
        const { id, autoDeleteFwpId } = deps.action.payload;
        const messagesId = 'server_group_' + id;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            },
        });

        const server_group: BbServerGroup = deps.getState().Resource.server_group.full[id];

        if (!server_group) {
            throw new Error("Did not find expected server_group");
        }

        const toRemove = server_group.servers.map(s => s.id);

        if (toRemove.length) {
            let [removeErr, ] = await to(request({
                url: serverGroupsUrl + '/' + id + '/remove_servers',
                method: 'POST',
                data: {
                    servers: toRemove.map(toServersObject),
                }
            }));

            if (removeErr) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_ERROR,
                        messages: removeErr.response.data.errors,
                        resource: null,
                    }
                });

                done();
                return;
            }
        }

        let [deleteErr, deleted] = await to(request({
            url: serverGroupsUrl + '/' + id,
            method: 'DELETE'
        }));

        if (deleteErr) {
            handleApiError(deleteErr);
        }

        if (autoDeleteFwpId) {
            let [deleteFwpErr, ] = await to(request({
                url: firewallPoliciesUrl + '/' + autoDeleteFwpId,
                method: 'DELETE'
            }));

            if (deleteFwpErr) {
                handleApiError(deleteFwpErr);
            }
        }

        if (deleted.status >= 200 && deleted.status < 300) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_SUCCESS,
                    messages: [ 'Deleted' ],
                    resource: null,
                },
            });
        }

        done();
    },
});

export const ResourceServerCreateLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_SERVER_CREATE'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceServerCreateAction,
        },
        dispatch: PatchDispatch,
        done: () => void
    ) {
        if (typeof deps.action.payload.userSshKey === 'string' && deps.action.payload.userSshKey !== '') {
            const currUser = deps.getState().Auth.currUser;
            if (currUser) {
                await doPatchResource(
                    dispatch,
                    currUser.id, 'user', { ssh_key: deps.action.payload.userSshKey }, null, 'server_user_ssh_key'
                );

                if (deps.getState().Message['server_user_ssh_key'].status !== 'success') {
                    done();
                    return;
                }
            }
        }

        let payload: $PropertyType<ResourceCreateKindAction, 'payload'> = {
            kind: 'server',
            params: deps.action.payload.params,
            cloudIp: {
                ids: deps.action.payload.cloudIp.ids,
            },
        };
        if (deps.action.payload.messagesId) {
            payload.messagesId = deps.action.payload.messagesId;
        }

        dispatch({ type: 'RESOURCE_CREATE_KIND', payload });

        done();
    },
});
