// @flow
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentAccountId } from '../hoc/lib';

import type { Dispatch } from 'redux';
import type { BbServerType, BbZone } from '../../api/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { FilterAction } from '../../state/Filter/type';
import type { ResourceFetchCollected } from '../../state/resource/type';

export type SearchFieldDefinition<T> = {
    +searchFieldName: string,
    +matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<T>) => boolean,
    +setValue?: (nextValue: string, dispatch: Dispatch<any>) => void,
    // If filtersOn is set, logic will ensure we have fetched
    // the relevant resource kind from the API, and passes the
    // list to the UI for use in eg a <select>.
    // If things are added that can be filtered on here,
    // extend SearchFieldState with the relevant kind?: { [id: string]: Bbxx }
    +filtersOn?: 'zone' | 'server_type',
    // If this is set, the filter will always at least try to filter with this value
    // - even if nothing is entered by the user
    +emptyOverride?: string,
    // Will be used if no value has been entered at all
    +initialValue?: string,
};

/**
 * Defines a single 'search' - if you reuse an instance of
 * SearchDefinition, then the multiple uses will always contain
 * the same search values in the given fields.
 *
 * If a redux-search field is present, then the "name"
 * must be a SearchIndexes value. But I didn't want to
 * necessarily restrict that here.
 */
export type SearchDefinition<T> = {
    +name: string,
    +fields: $ReadOnlyArray<SearchFieldDefinition<T>>,
};

type SearchFieldState = {
    +value: string,
    +zone?: { [id: string]: BbZone },
    +server_type?: { [id: string]: BbServerType },
};

type FieldsMap = {
    +[searchFieldName: string]: SearchFieldState,
};

export type SearchEditorHook = {
    +fields: FieldsMap,
    +setSearchValue: (field: string, value: string) => void,
};

export type ListSearchWrapChildProps<T> = {
    // The filtered list of items
    +items: Array<T>,
};

export type ListSearchWrapOwnProps<T> = {
    // the unfiltered list of items
    +allItems: Array<T>,
    +render: (props: ListSearchWrapChildProps<T>) => React$Node,
};

type SearchResult<T> = {
    items: $ReadOnlyArray<T>,
    filterRemovedAllItems: boolean,
    searchTerm: ?string,
}

export type ResourceSearchHook<T> = {
    editor: SearchEditorHook,
    ...SearchResult<T>,
}

const emptyFilter: $ReadOnlyMap<string, string> = new Map([]);

const useSearchEditor = <T>(def: SearchDefinition<T>): SearchEditorHook => {
    const { name } = def;
    const accountId = useCurrentAccountId();

    const fieldNameToIndex = useMemo(
        () => def.fields.reduce((acc, field, idx) => ({ ...acc, [field.searchFieldName]: idx }), ({}: $Shape<{}>)),
        [def]
    );

    const fields = useSelector((state: CloudGuiState) => {
        const filter = state.Filter.get(name) || emptyFilter;

        return def.fields.reduce((acc: ?FieldsMap, field: SearchFieldDefinition<T>) => {
            const { searchFieldName, filtersOn, initialValue } = field;
            let choices = {};

            switch(filtersOn) {
            case 'zone':
                choices = { zone: state.Resource[filtersOn].collected };
                break;
            case 'server_type':
                choices = { server_type: state.Resource[filtersOn].collected };
                break;
            default:
                void (filtersOn: empty | typeof undefined);
            }

            return {
                ...acc,
                [searchFieldName]: {
                    value: filter.has(searchFieldName) ? filter.get(searchFieldName) : (initialValue == null ? '' : initialValue),
                    ...choices,
                },
            };
        }, ({}: $Shape<{}>)
        );
    });

    const dispatch = useDispatch<Dispatch<ResourceFetchCollected | FilterAction>>();

    useEffect(() => {
        if (accountId !== '') {
            def.fields.forEach(field => {
                if (field.filtersOn) {
                    dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: field.filtersOn, } });
                }
            });
        }

    }, [accountId, def, dispatch]);

    return {
        fields,
        setSearchValue: (field: string, value: string) => {
            if (!(field in fieldNameToIndex)) {
                return;
            }

            const { searchFieldName, setValue } = def.fields[fieldNameToIndex[field]];

            if (setValue) {
                setValue(value, dispatch);
            }
            dispatch({
                type: 'FILTER_SET_FILTER_VALUE',
                payload: {
                    filterName: name,
                    field: searchFieldName,
                    value,
                }
            });
        },
    };
}

const useSearchWrap = <T>(def: SearchDefinition<T>, items: $ReadOnlyArray<T>): SearchResult<T> => {
    const { name } = def;

    return useSelector((state: CloudGuiState) => {
        const filter = state.Filter.get(name) || emptyFilter;
        const initialLength = items.length;
        let searchTerm: ?string = null;

        def.fields.forEach(field => {
            const { searchFieldName, emptyOverride, initialValue } = field;
            const filterValue = filter.get(searchFieldName);

            if (searchFieldName === 'searchText' && filterValue !== '') {
                searchTerm = filterValue;
            }

            if ((filterValue == null) && typeof initialValue === 'string') {
                items = items.filter(item => field.matches(state, initialValue, ((item: any): $ReadOnly<T>)));
            } else if (typeof filterValue === 'string' && filterValue !== '') {
                items = items.filter(item => field.matches(state, filterValue, ((item: any): $ReadOnly<T>)));
            } else if (typeof emptyOverride === 'string') {
                items = items.filter(item => field.matches(state, emptyOverride, ((item: any): $ReadOnly<T>)));
            }
        });

        return {
            items,
            filterRemovedAllItems: (items.length === 0 && initialLength > 0),
            searchTerm,
        };
    });
}

export const useResourceSearch = <T>(def: SearchDefinition<T>, items: $ReadOnlyArray<T>): ResourceSearchHook<T> => {
    return {
        editor: useSearchEditor(def),
        ...useSearchWrap<T>(def, items),
    }
}