// @flow
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { RadioGroup } from '../../../element/RadioGroup';

import type { EditorModal } from '../../../common/Editor';
import type { BbAccountParams, BbAccount } from '../../../../api/type.acc';

type AccountTfaRequirementEditProps = {
    +editor: EditorModal < BbAccountParams, null, BbAccount >
}

export const AccountTfaRequirementEdit = ({ editor }: AccountTfaRequirementEditProps): React$Node => {
    const { value: editAccount, setValue: setEditAccount } = editor;

    if (!editAccount) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Require Two-Factor Authentication'}
                description={'Require all team members to use Two-Factor Authentication'}
            />

            <PanelBar>
                <RadioGroup
                    label='Two-Factor Requirement'
                    selected={editAccount?.tfa_required || false}
                    onSelect={(value) => setEditAccount({ ...editAccount, tfa_required: value })}
                    options={[
                        { value: true, label: 'Enabled' },
                        { value: false, label: 'Disabled' },
                    ]}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{ onClick: editor.status === 'edit' ? editor.onSave : null }}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
}
