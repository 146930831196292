// @flow

import type { BbVolumePatchParams } from '../../../../api/type.volume';
import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { VolEditFilesystemType } from '../../../hoc/Volumes';

export const VOLUME_SERIAL_REGEX: RegExp = /^[A-Za-z0-9_.+-]{1,20}$/i;
export const VOLUME_LABEL_REGEX: RegExp = /^[A-Za-z0-9_.+-]{1,12}$/i;

export function validateSerialNumber(value: string): [?string, ?$Shape<BbVolumePatchParams>] {
    if (!value.match(VOLUME_SERIAL_REGEX)) {
        return [
            'Please enter a valid volume serial',
            null
        ];
    }

    return [
        null,
        { serial: value }
    ];
}

export const FILESYSTEM_TYPE_CHOICES: $ReadOnlyArray<ValueSelectChoice<VolEditFilesystemType>> = [
    {
        value: 'ext4',
        label: 'Ext4',
        description: 'Create volume with an ext4 filesystem',
        default: true,
    },
    {
        value: 'xfs',
        label: 'XFS',
        description: 'Create volume with an xfs filesystem',
    },
    {
        value: 'none',
        label: 'None',
        description: 'Don\'t auto-format this volume (manual formatting)',
    },
];