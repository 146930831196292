// @flow

import { Dialog } from '../../element/Dialog';

import type { DialogState } from '../../element/Dialog';

export const CreateAccountConfirmDialog = ({ dialog }: { dialog: DialogState<null> }): React$Node => (
    <Dialog
        dialog={dialog}
        title="Create New Account?"
        render={() =>
            <div>
                <p>Are you sure you'd like to create another separate Brightbox account?</p>
            </div>
        }
    />
);