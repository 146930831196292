// @flow
import { useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelButtonBar } from '../../../element/Panel';
import { VolAttachDialog } from '../../volume/VolDialogs';
import { ResourceSelector } from '../../../common/ResourceSelector';
import { volSortFields, volSearchDef } from '../../volume/def';
import { TextOnlySearchEditor } from '../../../common/TextOnlySearch';
import { VolListTable } from '../../volume/VolList';
import { useDialog, Dialog } from '../../../element/Dialog';
import { useCreateVolumeForm, useZoneSupportsBlockStorage } from '../../../hoc/Volumes';
import { TextInput } from '../../../element/Input';
import { PerGigPrice } from '../../../element/PerGigPrice';
import { RC_SUCCESS } from '../../../../state/resource/type';
import { useAtAccountLimit } from '../../../element/ResourceListPage';
import { RadioGroup } from '../../../element/RadioGroup';
import { FILESYSTEM_TYPE_CHOICES } from '../../volume/edit/lib';
import { Notice } from '../../../element/Notice';
import { HostVolumeConflictDialog } from './HostVolumeConflictDialog';
import { Button } from '../../../element/Button';
import { ZoneRestrictionTooltip } from '../../../element/LimitTooltip';

import type { BbVolume } from '../../../../api/type.volume';
import type { ImageOrVolume } from '../../../hoc/CreateResource';
import type { Editor } from '../../../common/Editor';
import type { DialogState } from '../../../element/Dialog';

function notDeletedAndNotAttached(serverZoneId: ?string, volume: BbVolume): boolean {
    return (
        volume.status === 'detached'
        && (serverZoneId == null || serverZoneId === volume.zone.id)
    );
}

type ServerAttachVolumeEditProps = {
    +title: string,
    +description: string,
    +serverId?: string,
    +serverZoneId?: string,
    +editor: Editor<$ReadOnlyArray<string>, ?React$Node, BbVolume>,
    +confirmDialog?: DialogState<null>,
    +setImageOrVolume?: (imageOrVolume: ImageOrVolume) => void,
}

export function ServerAttachVolumeEdit({ editor, confirmDialog, setImageOrVolume, title, description, serverId, serverZoneId, }: ServerAttachVolumeEditProps): React$Node {
    const createHook = useCreateVolumeForm(false);
    const createDialog = useDialog([{
        label: 'Create Volume',
        color: 'blue',
        kind: 'primary',
        onSelect: () => {
            createHook.validateAndCreate();
        }
    }], createHook.clear);

    const { value, setValue, errors } = editor;
    const { clear } = createHook;
    const { apiResult } = createHook.create;
    const { status: createVolumeStatus, resource: createdVolume } = apiResult;
    const [newVolumes, setNewVolumes] = useState<Set<string>>(new Set());
    useEffect(() => {
        if (createVolumeStatus === RC_SUCCESS && createdVolume != null) {
            setValue([createdVolume.id]);
            setNewVolumes((existing) => new Set([...existing, createdVolume.id]));
            clear();
        }
    }, [createVolumeStatus, createdVolume, setValue, clear, setNewVolumes]);

    const atLimitTooltip = useAtAccountLimit('block_storage', 'Volume');

    // with the addition of feature labels, this should always be 'true' these days -
    // because we disable the 'attach volume' button when it isn't.
    // but, keep this check here in case a server storage editor gets linked to
    // directly by someone.
    const zoneSupportsNbs = useZoneSupportsBlockStorage(serverZoneId);

    const showError = errors != null && value?.length === 0;

    return (
        <>
            {confirmDialog
                ? <VolAttachDialog confirmDialog={confirmDialog} editor={editor} />
                : null}
            <HostVolumeConflictDialog messages={editor.messages} serverId={serverId} volumeId={value?.length ? value[0] : null} />
            <Dialog
                dialog={createDialog}
                type='wide'
                messages={createHook.create.apiResult}
                footerLink={
                    <Button kind='bare' size='sm' preIcon='info-fill'>
                        <a href='https://www.brightbox.com/docs/guides/volumes/create-volume/' target='_blank' rel='noopener noreferrer'>Creating Volumes</a>
                    </Button>
                }
                title='Create Volume'
                render={() =>
                    <div className='flex flex-col pt-4 space-y-8'>
                        <TextInput
                            label='Name'
                            onChange={(name) => createHook.name.setValue(name)}
                            autoFocus={true}
                            value={createHook.name.value}
                            errorText={createHook.name.errors}
                            optional={true}
                        />
                        <TextInput
                            label='Size'
                            onChange={(size) => createHook.size.setValue(size)}
                            value={createHook.size.value}
                            errorText={createHook.size.errors}
                            postText='GB'
                            className='w-32'
                            focusSelectAll={true}
                            hint={`Volumes must be at least ${window.cgrConfig.VOLUME_LIMITS.min}GB and can be increased later using the Resize Volume option`}
                        />

                        <div className='bg-gray-100a rounded-lg px-8 py-6'>

                            <h4 className='mb-4'>Auto-format this volume?</h4>

                            <RadioGroup
                                label='Filesystem Type'
                                onSelect={(nextType) => createHook.filesystem_type.setValue(nextType)}
                                selected={createHook.filesystem_type.value}
                                options={FILESYSTEM_TYPE_CHOICES}
                                errorText={createHook.filesystem_type.errors}
                                className='w-32 mb-6'
                            />

                            <div hidden={createHook.filesystem_type.value === 'none'}>
                            <TextInput
                                label='Label'
                                onChange={(nextLabel) => createHook.filesystem_label.setValue(nextLabel)}
                                value={createHook.filesystem_label.value == null ? '' : createHook.filesystem_label.value}
                                errorText={createHook.filesystem_label.errors}
                                disabled={createHook.filesystem_type.value === 'none'}
                                className='w-48 mt-8'
                                hint='Provide an optional 12 character label which can be used to reference the newly created filesystem within the cloud server'
                                optional={true}
                                focusSelectAll={true}
                            />
                            </div>
                        </div>
                        <div>
                           <PerGigPrice id='blockstorage' label='Volume' />
                        </div>
                    </div>
                }
            />
            <Panel>
                <PanelHeader
                    title={title}
                    description={description}
                />
                <ResourceSelector
                    selector={{
                        editor,
                        selectSingle: true,
                        preventEmptySelection: true,
                    }}
                    kind='volume'
                    sortFields={volSortFields}
                    searchDef={volSearchDef}
                    searchComponent={TextOnlySearchEditor}
                    table={VolListTable}
                    itemsFilter={notDeletedAndNotAttached.bind(null, serverZoneId)}
                    confirmDialog={confirmDialog}
                    context='selector'
                    newSet={newVolumes}
                    leftButton={{
                        children: 'Add Volume',
                        preIcon: 'add-fill',
                        kind: 'bare',
                        disabled: atLimitTooltip != null || !zoneSupportsNbs,
                        onClick: () => createDialog.show(),
                    }}
                    leftButtonTooltip={atLimitTooltip ? { overlay: atLimitTooltip } : (zoneSupportsNbs ? null : { overlay: <ZoneRestrictionTooltip /> })}
                >
                    {setImageOrVolume && !showError
                        ? <Notice type='info' icon='info-fill'>
                            The selected Volume must contain a bootable filesystem
                        </Notice>
                        :null
                    }
                    {showError && errors != null
                        ? <Notice type='error'>
                            {errors}
                        </Notice>
                        :null
                    }
                </ResourceSelector>
                {setImageOrVolume
                    ? <PanelButtonBar
                        leftButton={{
                            kind: 'tertiary',
                            preIcon: 'swap',
                            onClick: () => setImageOrVolume('image'),
                            children: 'Use an Image instead?',
                        }}
                    />
                : null}
            </Panel>
        </>
    );
}
