// @flow

import { Panel, PanelHeader } from '../../../element/Panel';
import { history } from '../../../../lib/history';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { useAccessControlResourcesById } from "../../../hoc/AccessControl";
import { TargetDisplay } from '../../../element/TargetDisplay';
import { RC_FETCHING } from '../../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { ResourceCacheStatus } from '../../../../state/resource/type';
import type { BbDatabaseServer } from "../../../../api/type.dbs";

export type DbsAccessControlProps = {
    +cacheStatus: ResourceCacheStatus,
    +editUri: string,
    +access: ?$PropertyType<BbDatabaseServer, 'access_control'>,
};

export const DbsAccessControl = (props: DbsAccessControlProps): React$Node => {
    const accessResources = useAccessControlResourcesById();

    return (
        <Panel>
            <PanelHeader
                title='Access Control'
                actions={{ edit: () => history.push(props.editUri + '/'), }}
            />
            <Table>
                <thead>
                <Tr>
                    <Th>Source</Th>
                    <Th stretch={true}>Description</Th>
                </Tr>
                </thead>
                <tbody>

                {props.cacheStatus === RC_FETCHING
                    ? <SkeletonListLoadingEntries>
                        <Tr>
                            <Td resourceName={true}><SkeletonChip /></Td>
                            <Td><SkeletonBar size='md' /></Td>
                        </Tr>
                    </SkeletonListLoadingEntries>
                    : null
                }

                {props.access?.length === 0
                    ? <Tr>
                        <Td empty={true} colSpan='1'>No access is granted to this Cloud SQL.</Td>
                    </Tr>
                    : null
                }
                {props.access
                    ? props.access.map(({ source: access, description }, idx: number) =>
                        access
                            ? <Tr key={idx}>
                                <Td>
                                    <TargetDisplay target={access} accessResources={accessResources}/>
                                </Td>
                                <Td>{description || '-'}</Td>
                            </Tr>
                            : null
                    )
                    : null
                }
                </tbody>
            </Table>
        </Panel>
    );
};

