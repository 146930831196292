// @flow

import { Children } from 'react';

type CardGroupProps = {
    children: React$Node,
    className?: ?string,
}

export const CardGroup = ({ children, className: extra, ...rest }: CardGroupProps): React$Node => {
    let className = 'l-grid' + (extra ? ' ' + extra : '');

    return (
        Children.count(children) > 0
            ? <div className={className} {...rest}>
                {children}
            </div>
            : null
    );
};