// @flow

import type { ContainerImageTags, OrbitState } from "../Orbit/type";
import type { ImageTag } from "../../component/hoc/orbit/ContainerRegistry";

export type ImageTagSearchItem = {
    id: string;
    values: $ReadOnlyArray<string>,
}

type ImageTagSearchOutput = { [k: string]: ImageTagSearchItem };

type ImageTagSearchState = {
    +source: ContainerImageTags;
    +res: ImageTagSearchOutput,
}

let currentIndex: ImageTagSearchState|null = null;

const NO_RES: ImageTagSearchOutput = {};

export function buildImageTagSearches(resourceName: string, state: OrbitState): ImageTagSearchOutput {
    if (state.currentImageTags != null && (currentIndex == null || state.currentImageTags !== currentIndex.source)) {
        const { currentImageTags } = state;
        const res: ImageTagSearchOutput = {};

        (currentImageTags.imageTags || []).forEach((x: ImageTag) => {
            res[x.id] = {
                id: x.id,
                values: [
                    x.nameTag,
                    x.sha256,
                ]
            }
        });

        currentIndex = {
            source: currentImageTags,
            res,
        }
    }

    return currentIndex ? currentIndex.res : NO_RES;
}