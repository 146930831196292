// @flow
export const PAGING_MIN_SIZE = 10;
export const PAGING_MAX_SIZE = 100;
export const PAGING_PAGE_SIZE_CHOICES: $ReadOnlyArray<{ label: string, value: number }> = [
    { label: PAGING_MIN_SIZE + ' per page', value: PAGING_MIN_SIZE },
    { label: '20 per page', value: 20 },
    { label: '50 per page', value: 50 },
    { label: PAGING_MAX_SIZE + ' per page', value: PAGING_MAX_SIZE },
];

export type Paging = {
    permitNext: boolean;
    permitPrev: boolean;
    currentPage: number;
    totalPages: number;
    // array of tuples of [ pagenumber, offset ]
    pages: Array<[ number, number ]>;
}

export function getPaging(offset: number, itemsPerPage: number, totalItems: number): Paging {
    const currentPage = offset / itemsPerPage;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const permitPrev = offset > 0;
    const permitNext = offset < (totalItems - itemsPerPage);

    const pages = [];
    for(let i = 0; i < totalPages; i++) {
        pages.push([ i, i * itemsPerPage]);
    }

    return {
        permitNext, permitPrev, pages, currentPage, totalPages,
    }
}