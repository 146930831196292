// @flow

import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type TabProps = {
    selectedIndex: number,
    onSelect: (idx: number) => void,
}

export const useTabs = (hashMap: $ReadOnlyMap<string, number>): TabProps => {
    const [selectedIndex, setSelected] = useState<number>(0);
    const location = useLocation();

    const reverseMap = useMemo(() => {
        let reverse = [];
        hashMap.forEach((v, k) => reverse.push([v, k]));
        return new Map(reverse);
    }, [hashMap]);

    const onSelect = useMemo(() => (idx: number) => {
        if (reverseMap.has(idx)) {
            window.location.hash = reverseMap.get(idx);
        }
        setSelected(idx);
    }, [reverseMap]);

    useEffect(() => {
        const next = hashMap.get(location.hash);
        if (next != null) {
            onSelect(next);
        }
    }, [location, hashMap, onSelect]);

    return {
        selectedIndex,
        onSelect,
    };
};

/*
 * There's not much going on here - it's more of a semantic
 * hook than a complex one.
 */
type ListGrid = 'list' | 'grid';
export type ListGridHook = [ ListGrid, ((ListGrid => ListGrid) | ListGrid) => void ];

export const useSelectorListGrid = (def: ListGrid): ListGridHook => {
    return useState<ListGrid>(def);
}