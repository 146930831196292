// @flow

import { useMemo, Fragment, } from 'react';
import { Tooltip } from '../../element/Tooltip';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../../element/SvgIcon';
import { useRouteMatch } from 'react-router';
import { ORBIT_OBJECTS_PATH } from '../../../lib/history';

type PathBreadcrumbProps = {
    +path: string,
    +paths: $ReadOnlyArray<string>,
    +marker?: ?string,
    +objectName?: string,
}

type PathBreadcrumbHook = {
    +path: string,
    +paths: $ReadOnlyArray<string>,
    +marker: string,
}

export const PathBreadcrumb = ({ path, paths, marker, objectName }: PathBreadcrumbProps): React$Node => {
    const match = useRouteMatch(ORBIT_OBJECTS_PATH);
    let baseUrl = match && match.params.accountId && match.params.container && match.params.which
        ? `/accounts/${match.params.accountId}/orbit/container/${match.params.container}/${match.params.which}/`
        : '';

    return (
        path === '' && objectName == null
            ? null
            : <div className="c-breadcrumbs">
                <Tooltip overlay="Back to container root">
                    <Link to={baseUrl + '?path=&marker='} className="c-breadcrumbs__link flex items-center">
                        <SvgIcon svg="arrow-left-up" className="c-breadcrumbs__icon"/>
                        <span className="ml-2">{match.params.container}</span>
                    </Link>
                </Tooltip>
                {paths.map((e, i) =>
                    (e !== ''
                        ? <Fragment key={i}>
                            <SvgIcon svg="caret-right" className="c-breadcrumbs__icon"/>
                            <Link
                                className="c-breadcrumbs__link"

                                to={baseUrl
                                    + '?path=' + encodeURIComponent(paths.slice(0, 1 + i).join('/') + '/')
                                    + (i === paths.length - 1 && marker != null ? '&marker=' + encodeURIComponent(marker) : '')
                                }>{e}</Link>
                        </Fragment>
                        : null)
                )}
                {objectName != null
                    ? <>
                        <SvgIcon svg="caret-right" className="c-breadcrumbs__icon"/>
                        <div>{objectName}</div>
                    </>
                    : null
                }
            </div>

    );
}


export function usePathBreadcrumb(): PathBreadcrumbHook {
    const params = new URLSearchParams(window.location.search);
    const path = params.get('path') || '';
    const marker: string = params.get('marker') || '';
    const paths = useMemo(() => path === '' ? [] : path.slice(0, -1).split('/'), [path]);

    return {
        path, paths, marker
    }
}