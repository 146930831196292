// @flow

import { formatDateTime, Status, AcmeDomain, } from '../../../element/Styled';
import { Panel, PanelBar, PanelHeader, } from '../../../element/Panel';
import { Settings, SettingsItem, SettingsTable } from '../../../element/Setting/Settings';
import { history } from '../../../../lib/history';
import { HttpsAcmeCipWarning } from './LbaHttpsEdit';
import { Tooltip } from '../../../element/Tooltip';
import { useAcmeDomainUiState } from './lib';

import type { EditHttps, EditListener, } from './type';
import type { BbAcmeState, BbCertificateState, BbLba, BbLbaHttps, BbLbaListener, BbAcmeCert, } from '../../../../api/type.lba';
import type { ResourceCacheStatus } from '../../../../state/resource/type';
import type { GuiAcmeDomain } from './lib';

export type LbaHttpsProps = {
    +cacheStatus: ResourceCacheStatus,
    +editUri: ?string,
    +acme: ?BbAcmeState,
    +certificate: ?BbCertificateState,
    +hasCloudIp: boolean,
};

export const listenerIsHttps = ({ protocol }: BbLbaListener | EditListener): boolean => protocol === 'https' || protocol === 'https+wss';
export const listHasHttpsListener = (listeners: $ReadOnlyArray<BbLbaListener> | $ReadOnlyArray<EditListener>): boolean => (-1 !== listeners.findIndex(listenerIsHttps));

const emptyEdit: EditHttps = {
    currentState: {
        was_https: false,
        hasOnlyHttpsListeners: false,
        pem: null,
    },
    method: 'none',
    pem: ({}: any),
    domains: [],
    https_redirect: false,
};

export const editHttps = (lba: ?BbLba): EditHttps => {
    if (lba) {
        const { acme, certificate } = lba;

        let method: BbLbaHttps = 'none';
        if (acme && acme.domains.length) method = 'acme';
        if (certificate) method = 'pem';

        return {
            currentState: {
                was_https: method !== 'none',
                hasOnlyHttpsListeners: lba.listeners.findIndex(x => !listenerIsHttps(x)) === -1,
                pem: lba.certificate,
            },
            method,
            pem: ({}: any),
            domains: acme ? acme.domains.map(domain => domain.identifier) : [],
            https_redirect: lba.https_redirect,
        };
    }

    return emptyEdit;
};

export const LbaHttps = ({ editUri, acme, certificate, hasCloudIp, }: LbaHttpsProps): React$Node => {
    const noHttps = (!acme || acme.domains.length === 0) && !certificate;

    return (
        <Panel>
            <PanelHeader
                title='HTTPS'
                actions={editUri ? { edit: () => history.push(editUri + '/'), } : null}
            />
            {certificate
                ? <PanelBar>
                    <Settings>
                        <SettingsItem name={'HTTPS Mode'} summary={'Custom Certificate'}/>
                    </Settings>
                    <CertificateDetailsContent certificate={certificate}/>
                </PanelBar>
                : null}
            {acme && acme.domains.length > 0 ? <AcmeDetails acme={acme} hasCloudIp={hasCloudIp}/> : null}
            {noHttps
                ? <PanelBar>
                    <Settings>
                        <SettingsItem name={'HTTPS Mode'} summary={'No HTTPS'}/>
                    </Settings>
                </PanelBar>
                : null}
        </Panel>

    );
};

const AcmeDetails = ({ acme, hasCloudIp }: { acme: BbAcmeState, hasCloudIp: boolean, }) => {
    const { certificate, domains } = acme;
    const hasPending = !!domains.find(x => x.status === 'pending');

    const mergedDomains = useAcmeDomainUiState(acme, hasCloudIp);
    const domainsOnCert = certificate
        ? certificate.domains.map(x => [ <AcmeDomain key={x} domain={x} />, ', ']).flat().slice(0, certificate.domains.length * 2 - 1)
        : null;

    return (
        <>
            <PanelBar>
                <Settings>
                    <SettingsItem name={'HTTPS Mode'} summary={'Lets Encrypt'}/>
                </Settings>
                {certificate
                    ? <Settings>
                        <SettingsItem name={'Fingerprint'} summary={certificate.fingerprint}/>
                        <SettingsItem name={'Expires At'} summary={formatDateTime(certificate.expires_at)}/>
                        {domainsOnCert
                            ? <SettingsItem name='Domains' summary={domainsOnCert}/>
                            : null
                        }
                    </Settings>
                    : null
                }
                {hasPending && !hasCloudIp
                    ? <HttpsAcmeCipWarning subEdit={false} />
                    : null
                }
                <SettingsTable>
                    <thead>
                    <tr>
                        <th className='w-status'>status</th>
                        <th>Domain Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {mergedDomains.map((domain: GuiAcmeDomain) =>
                        <tr key={domain.identifier}>
                            <td className={domain.last_message || domain.uiMessage ? 'align-top' : null}>
                                <AcmeDomainStatus certificate={certificate} domain={domain} />
                            </td>
                            <td>
                                <AcmeDomain domain={domain.identifier} />
                                {domain.status !== 'valid' || domain.uiMessage != null
                                    ? <p className='text-gray-500 italic'>{domain.last_message || domain.uiMessage}</p>
                                    : null
                                }
                            </td>
                        </tr>
                    )}
                    </tbody>
                </SettingsTable>
            </PanelBar>
        </>
    );
};

export const CertificateDetailsContent = ({ certificate, children }: { certificate: BbCertificateState, children?: React$Node, }): React$Node => {
    return (
        <>
            <Settings>
                <SettingsItem name="Issuer" summary={certificate.issuer}/>
                <SettingsItem name="Subject" summary={certificate.subject}/>
                <SettingsItem name="Valid" summary={formatDateTime(certificate.valid_from) + ' – ' + formatDateTime(certificate.expires_at)}/>
                {children}
            </Settings>
        </>
    );
};

export const AcmeDomainStatus = ({ certificate, domain }: { certificate: ?BbAcmeCert, domain: GuiAcmeDomain, }): React$Node => (
    <Tooltip overlay={
        <div>
            On Certificate: {certificate && !!certificate.domains.find(x => x === domain.identifier) ? 'Yes' : 'No'}<br/>
            ACME status: {domain.status}
        </div>
    }>
        <Status status={domain.uiStatus}/>
    </Tooltip>
)