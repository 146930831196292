// @flow

type CounterProps = {
    className?: string,
    children: React$Node,
}

export const Counter = ({ className: extraClassName, children, ...props }: CounterProps): React$Node => {
    const className = 'c-counter ' + (extraClassName || '')

    return (
        <div className={className} {...props}>{children}</div>
    );
};