// @flow
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useViewResource, } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useResourceEditorModal } from '../../common/Editor';
import { LockDialogs, useResourceLockDialogs, DeleteDialog, useDeleteDialog } from '../../common/CommonDialogs';
import { NameEditPanel } from '../../element/NameEditPanel';
import { useCurrentAccountId } from '../../hoc/lib';
import { getLockSetting } from '../../element/Setting/Settings';
import { Chip, ResourceChipFetch } from '../../element/Chip';
import { useDatabaseEngineTypes } from '../../hoc/Database';
import { SkeletonChip } from '../../element/Skeleton';
import { wholeSize } from '../../element/Styled';
import { TextSetting } from '../../element/Setting/TextSetting';
import { useBuildingResource } from '../../hoc/Build';
import { isAllocated } from '../../../api/lib';
import { LABELS } from '../../element/ResourceLabels';

import type { EditorDef } from '../../common/Editor';
import type { BbDatabaseSnapshot, BbDatabaseSnapshotParams } from '../../../api/type.dbs';
import type { Match } from 'react-router-dom';

const nameDef: EditorDef<BbDatabaseSnapshot, string, BbDatabaseSnapshotParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbDatabaseSnapshot) => res.name || '',
    onValidate: null,
};
const descriptionDef: EditorDef<BbDatabaseSnapshot, string, BbDatabaseSnapshotParams, null> = {
    emptyErrors: null,
    editUri: 'description',
    onEdit: (res: BbDatabaseSnapshot) => res.description || '',
    onValidate: null,
};

export const DbiView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const currAccountId = useCurrentAccountId() || '';
    const resource = useViewResource<BbDatabaseSnapshot, BbDatabaseSnapshotParams>('database_snapshot', id);
    const { item: dbi, simpleAction, deleteAction, } = resource;
    const progress = useBuildingResource(id);
    const isAvailable = dbi?.status === 'available';
    const { engineLabel } = useDatabaseEngineTypes();

    const { panelActions: lockActions, ...lockDialogs } = useResourceLockDialogs(isAvailable ? dbi?.locked : null, simpleAction);
    const [deletePanelAction, deleteDialog] = useDeleteDialog(dbi?.status === 'available' && !dbi?.locked, deleteAction);

    const name = useResourceEditorModal(resource, nameDef, match.path);
    const description = useResourceEditorModal(resource, descriptionDef, match.path);
    const create_from_snapshot = dbi?.status === 'available' ? `/accounts/${currAccountId}/database_servers/add/?image=${dbi?.id}` : null;

    const settings = [
        [
            {
                name: 'Database Engine',
                summary: dbi
                    ? <Chip
                        engine={dbi.database_engine}
                        name={engineLabel(dbi.database_engine, dbi.database_version)}
                    />
                    : null,
                skeleton: <SkeletonChip nameOnly={true} />,
            },
            {
                name: 'Source',
                summary: dbi?.source ? <ResourceChipFetch id={dbi.source} /> : null,
                hint: 'The Cloud SQL instance from which this snapshot was created',
            },
            {
                name: 'Size',
                summary: dbi ? wholeSize(dbi.size) : null,
            },
            {
                name: 'Description',
                summary: dbi ? (dbi.description || '-') : null,
                route: isAvailable ? description.editUri : null,
            },
            getLockSetting(!!dbi && isAllocated(dbi.status), dbi?.locked, lockDialogs.unlockDialog.show, lockDialogs.lockDialog.show),
        ],
    ];

    return (
        <ResourceAddViewRoute
            resourceName={dbi?.name || dbi?.id || ''}
            listTitle={LABELS.database_snapshot.listTitle}
            resource={resource}
            match={match}
            dialog={
                <>
                    <LockDialogs
                        name={dbi?.name || id}
                        {...lockDialogs}
                    />
                    <DeleteDialog
                        name={dbi?.name || id}
                        dialog={deleteDialog}
                    />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={dbi?.name || dbi?.id}
                            actions={{
                                ...deletePanelAction,
                                ...(isAvailable ? lockActions : null),
                                create_from_snapshot
                            }}
                            editTitleLink={isAvailable ? name.editUri : null}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: dbi?.id,
                                created_at: dbi?.created_at,
                                status: dbi?.status,
                                progress,
                            }}
                            settings={settings}
                        />
                    </Panel>
                </>
            }
            editors={[
                { editor: name, render: () => <NameEditPanel editor={name} />},
                { editor: description, render: () => <TextSetting editor={description} title='Description' label='Snapshot Description' textarea={true} /> , },
            ]}

        />
    );
};

