// @flow
import { wholeSize, Bullet } from './Styled';

export type TypeSummaryProps = {
    ram: number,
    cpus?: number,
    disk: number,
}

export const TypeSummary = ({ ram, cpus, disk, }: TypeSummaryProps): React$Node => {
    return (
        <>
            {wholeSize(ram)} RAM
            {cpus ? <> <Bullet/> {cpus} VCPU </>: null}
            {disk ? <> <Bullet/> {wholeSize(disk)} SSD</>: null}
        </>
    );
};