// @flow

export const LABELS = {
    'account': {
        title: 'Account',
        singular: 'account',
        plural: 'accounts',
        listTitle: 'My Accounts'
    },
    'api_client': {
        title: 'API Client',
        singular: 'API client',
        plural: 'API clients',
        listTitle: 'All API Access',
    },
    'application': {
        title: 'OAuth Application',
        singular: 'OAuth application',
        plural: 'OAuth applications',
        listTitle: 'All OAuth Applications',
    },
    'cloud_ip': {
        title: 'Cloud IP',
        singular: 'cloud IP',
        plural: 'cloud IPs',
        listTitle: 'All Cloud IPs',
    },
    'container': {
        title: 'Orbit Container',
        singular: 'orbit container',
        plural: 'orbit containers',
        listTitle: 'All Orbit Containers',
    },
    'container_registry': {
        title: 'Repository',
        listTitle: 'All Repositories',
    },
    'collaboration': {
        title: 'Collaboration',
        singular: 'collaboration',
        plural: 'collaborations',
    },
    'database_server': {
        title: 'Cloud SQL Instance',
        singular: 'cloud SQL instance',
        plural: 'cloud SQL instances',
        listTitle: 'All Cloud SQL Instances',
    },
    'database_snapshot': {
        title: 'Database Snapshot',
        singular: 'snapshot',
        plural: 'snapshots',
        listTitle: 'All Database Snapshots',
    },
    'dbs_access': {
        title: 'Access',
        singular: 'access',
        plural: 'access rules',
    },
    'docker_image': {
        title: 'Docker Image',
        singular: 'docker image',
        plural: 'docker images',
        listTitle: 'All Docker Images',
    },
    'image': {
        title: 'Image',
        singular: 'image',
        plural: 'images',
        listTitle: 'Server Images',
    },
    'load_balancer': {
        title: 'Load Balancer',
        singular: 'load balancer',
        plural: 'load balancers',
        listTitle: 'All Load Balancers',
    },
    'repository': {
        title: 'Repository',
        single: 'repository',
        plural: 'repositories',
        listTitle: 'All Repositories',
    },
    'server': {
        title: 'Cloud Server',
        singular: 'cloud server',
        plural: 'cloud servers',
        listTitle: 'All Cloud Servers',
    },
    'server_group': {
        title: 'Server Group',
        singular: 'server group',
        plural: 'server groups',
        listTitle: 'All Server Groups',
    },
    'server_type': {
        title: 'Server Type',
        singular: 'server type',
        plural: 'server types',
    },
    'user': {
        title: 'Users',
        singular: 'User',
        plural: 'Users',
        listTitle: 'All Team Members',
    },
    'volume': {
        title: 'Volume',
        singular: 'volume',
        plural: 'volumes',
        listTitle: 'All Volumes',
    },
    'zone': {
        title: 'Zone',
        singular: 'zone',
        plural: 'zones',
    }
}

export type LabelIndex = $Keys<typeof LABELS>;
