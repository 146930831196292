// @flow

import { toLabelLookup, } from '../../../element/ValueSelect';
import { Panel, PanelHeader, PanelSettingsBar } from '../../../element/Panel';
import { RadioGroupSetting } from '../../../element/Setting/RadioGroupSetting';
import { Notice } from '../../../element/Notice';
import { getLockSetting } from '../../../element/Setting/Settings';
import { isShowDetailsResourceStatus } from '../../../element/Skeleton';

import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { BbLba, BbLbaHealthcheck, BbLbaPolicy, BbLbaSslMinVer } from '../../../../api/type.lba';
import type { EditorModal } from '../../../common/Editor';
import type { EditHealthcheck } from './type';
import type { FormErrors } from '../../../common/lib';
import type { ResourceCacheStatus } from '../../../../state/resource/type';
import type { SettingsItemProps } from '../../../element/Setting/Settings';
import type { LockDialogsHook } from '../../../common/CommonDialogs';

export type LbaAdvancedProps = {
    +cacheStatus?: ResourceCacheStatus,
    +active: boolean,
    +policy: [?BbLbaPolicy, EditorModal<BbLbaPolicy, null, BbLba>],
    +healthCheck: [?(BbLbaHealthcheck | EditHealthcheck), EditorModal<EditHealthcheck, FormErrors, BbLba>],
    +https_redirect: [?boolean, EditorModal<boolean, null, BbLba>],
    +ssl_minimum_version: [?BbLbaSslMinVer, EditorModal<BbLbaSslMinVer, null, BbLba>],
    +lock?: [?boolean, LockDialogsHook],
    +needsHttps: boolean,
};

export const policyChoices: Array<ValueSelectChoice<BbLbaPolicy>> = [
    {
        value: 'least-connections',
        label: 'Least Connections',
        description: 'Each request is routed to the backend with the fewest current open connections',
        default: true,
    },
    {
        value: 'round-robin',
        label: 'Round Robin',
        description: 'Requests are routed to each backend in turn',
    },
    {
        value: 'source-address',
        label: 'Source Address',
        description: 'Requests from the same source IP address are routed to the same backend',
    },
];

const policyChoiceLabels = policyChoices.reduce(toLabelLookup, {});

export const httpsRedirectChoices = [
    {
        value: false,
        label: 'Off',
        description: 'Do not redirect plaintext HTTP connections to HTTPS',
    },
    {
        value: true,
        label: 'On',
        description: 'Redirect plaintext HTTP connections to HTTPS',
    }
];

export const sslMinVerChoices = [
    {
        value: 'TLSv1.0',
        label: 'TLS v1.0',
    },
    {
        value: 'TLSv1.1',
        label: 'TLS v1.1',
    },
    {
        value: 'TLSv1.2',
        label: 'TLS v1.2',
        default: true,
    },
    {
        value: 'TLSv1.3',
        label: 'TLS v1.3',
    },
];

export function lbaAdvancedProps({ cacheStatus, healthCheck, policy,  https_redirect, needsHttps, active, ssl_minimum_version, lock, }: LbaAdvancedProps): $ReadOnlyArray<SettingsItemProps> {
    let res = [
        {
            name: 'Balancing Policy',
            summary: policy[0] ? policyChoiceLabels[policy[0]] : '',
            route: active ? policy[1].editUri : null,
            hint: 'The Balancing Policy defines how requests to this Load Balancer should be distributed across the backend pool',
        },
        {
            name: 'Health Check',
            summary: healthCheck[0]?.type?.toUpperCase(),
            route: active ? healthCheck[1]?.editUri : null,
            hint: 'How should this Load Balancer check backend nodes for availability?'
        },
        {
            name: 'HTTPS Redirects',
            summary: (needsHttps ? (https_redirect[0] ? 'On' : 'Off') : 'n/a'),
            route: needsHttps && active ? https_redirect[1]?.editUri : null,
            hint: 'Automatically redirect all non-HTTPS requests to HTTP and issue a HSTS header to browsers for future requests'
        },
        {
            name: 'Minimum SSL Version',
            summary: (needsHttps ? ssl_minimum_version[0] : 'n/a'),
            route: needsHttps && active ? ssl_minimum_version[1]?.editUri : null,
            hint: 'Specify a minimum version of TLS to be supported by this Load Balancer to prevent weaker encryption being used by older browsers',
        },
    ];

    if (lock) {
        res.push(
            getLockSetting(
                (cacheStatus == null || isShowDetailsResourceStatus(cacheStatus)) && active,
                lock[0],
                () => lock[1].unlockDialog.show(),
                () => lock[1].lockDialog.show()
            )
        );
    }

    return res;
}

export const LbaAdvanced = (props: LbaAdvancedProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title='Settings'
            />
            <PanelSettingsBar
                cacheStatus={props.cacheStatus}
                settings={lbaAdvancedProps(props)}
            />
        </Panel>
    );
}

export const LbaPolicyEdit = ({ editor }: { +editor: EditorModal<BbLbaPolicy, null, BbLba> }): React$Node => (
    <RadioGroupSetting
        title='Balancing Policy'
        description='How should requests to this Load Balancer be distributed across the backends?'
        choices={policyChoices}
        editor={editor}
    />
);

export const LbaHttpsRedirectEdit = ({ editor }: { +editor: EditorModal<boolean, null, BbLba> }): React$Node => (
    <RadioGroupSetting
        title='HTTPS Redirects'
        choices={httpsRedirectChoices}
        editor={editor}
    />
);

export const LbaSslMinVerEdit = ({ editor }: { +editor: EditorModal<BbLbaSslMinVer, null, BbLba> }): React$Node => (
    <RadioGroupSetting
        title={'Minimum SSL Version'}
        choices={sslMinVerChoices}
        editor={editor}
    >
        <Notice icon='info'>Minimum SSL Version enables you to specify a minimum
        version of TLS to be required by the load balancer to prevent weaker
        encryption being used by older browsers
        </Notice>
    </RadioGroupSetting>
);