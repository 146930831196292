/* @flow */
import type { MessageState, MessageStateAction } from './type';
import type { StoreClearAction } from '../type';
import type { AuthSetSelectedAccount } from '../auth/type';

const emptyState = ({}: any);

export const MessageReducer = (
    domain: MessageState = ({}: any),
    action: MessageStateAction | StoreClearAction | AuthSetSelectedAccount,
): MessageState => {
  switch(action.type) {
  case 'STORE_CLEAR':
    return emptyState;
  case 'AUTH_SELECT_ACCOUNT':
    return emptyState;
  case 'MESSAGE_MESSAGE':
    const { id, ...rest } = action.payload;
    return {
      ...domain,
      [id]: {
        id,
        messages: rest.messages || [],
        status: rest.status,
        resource: rest.resource,
        error_name: rest.error_name,
        objects: rest.objects,
      },
    };
  case 'MESSAGE_REMOVE':
    let next = { ...domain };
    if (next[action.payload.id]) {
      delete next[action.payload.id]
    }
    return next;

  default:
    // eslint-disable-next-line no-unused-expressions
    void (action: empty);
  }

  return domain;
};