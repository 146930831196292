// @flow
import { useMemo } from 'react';
import type { EditHealthcheck, EditHttps, EditListener } from './type';
import type { LbaCreateParams } from '../LbaCreate';
import type { BbLba, BbLbaHealthcheck, BbLbaListener, BbLbaListenerProtocol, BbLbaParams, BbAcmeDomain, BbAcmeState } from '../../../../api/type.lba';
import type { FormErrors } from '../../../common/lib';

export const validateDetails = (editDetails: LbaCreateParams): [$ReadOnlyArray<string>, ?$Shape<BbLbaParams> ] => {
    return [
        [],
        {
            name: editDetails.name,
            policy: editDetails.policy,
            ssl_minimum_version: editDetails.ssl_minimum_version,
        }
    ];
};

export const lbaNeedsHttps = (lba: ?BbLba): boolean => lba ? !!(lba.certificate || (lba.acme && lba.acme.domains.length)) : false;
const isHttpsProtocol = (protocol: BbLbaListenerProtocol) => protocol === 'https' || protocol === 'https+wss';

export const validateListeners = (needsHttps: boolean, editListeners: $ReadOnlyArray<EditListener>): [?FormErrors, ?$Shape<BbLbaParams>] => {
    let errors = new Map<string, string>();
    const inPorts: Array<string | number> = [];
    let commit = [];
    let hasHttpsListener = false;

    editListeners.forEach((listener, idx) => {
        if (!listener.in || isNaN(Number(listener.in)) || Number(listener.in) < 1 || Number(listener.in) > 65535) {
            errors.set(idx + '_in', 'Please enter port from 1 - 65535.');
        } else {
            if (inPorts.indexOf(Number(listener.in)) !== -1) {
                errors.set(idx + '_in', 'Please use unique "in" ports.');
            }
            inPorts.push(Number(listener.in));
        }
        if (!listener.out || isNaN(Number(listener.out)) || Number(listener.out) < 1 || Number(listener.out) > 65535) {
            errors.set(idx + '_out', 'Please enter port from 1 - 65535.');
        }
        if (!listener.timeout || isNaN(Number(listener.timeout)) || Number(listener.timeout) < 1 || Number(listener.timeout) > 86400) {
            errors.set(idx + '_timeout', 'Please enter timeout from 1 - 86400.');
        }
        if (!listener.protocol) {
            errors.set(idx + '_protocol', 'Please select protocol.');
        }
        hasHttpsListener = hasHttpsListener || isHttpsProtocol(listener.protocol);

        commit.push(({
            in: Number(listener.in),
            out: Number(listener.out),
            protocol: listener.protocol,
            timeout: Math.floor(Number(listener.timeout) * 1000),
            proxy_protocol: listener.proxy_protocol === '' ? null : listener.proxy_protocol,
        }: BbLbaListener));
    });

    if (needsHttps && !hasHttpsListener) {
        errors.set('https', 'Please create an HTTPS listener to support your HTTPS configuration');
    }

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? { listeners: commit } : null,
    ];
};

export const validateHttps = (editHttps: EditHttps): [?FormErrors, ?$Shape<BbLbaParams>,] => {
    let commit: ?$Shape<BbLbaParams> = null;
    let errors = new Map();

    switch(editHttps.method) {
    case 'none':
        commit = {
            certificate_pem: '',
            certificate_private_key: '',
            domains: [],
            https_redirect: false,
        };
        break;
    case 'pem':
        const { cert, key } = editHttps.pem;
        if (cert && key) {
            commit = {
                certificate_pem: cert.content,
                certificate_private_key: key.content,
                domains: [],
                https_redirect: editHttps.https_redirect,
            };
        } else {
            if (!cert) {
                errors.set('cert', 'Please upload a certificate PEM.');
            }
            if (!key) {
                errors.set('key', 'Please upload private key.');
            }
        }
        break;
    case 'acme':
        if (editHttps.domains.length === 0) {
            errors.set('acme_domains', 'Please enter at least one domain');
        }

        editHttps.domains.forEach((domain, idx) => {
            if (!domain) {
                errors.set(idx + '_domain', 'Please enter a domain name.');
            }
        });

        if (errors.size === 0) {
            commit = {
                domains: editHttps.domains,
                certificate_pem: '',
                certificate_private_key: '',
                https_redirect: editHttps.https_redirect,
            };
        }
        break;
    default:
        void (editHttps.method: empty);
    }

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? commit : null,
    ];
};

export const validateHealthcheck = (editHealthcheck: EditHealthcheck): [?FormErrors, ?{ healthcheck: BbLbaHealthcheck, ... }] => {
    let errors = new Map();

    if (!editHealthcheck.port || isNaN(editHealthcheck.port)) {
        errors.set('port', 'Please enter a valid port number');
    }
    if (!editHealthcheck.timeout || isNaN(editHealthcheck.timeout)) {
        errors.set('timeout', 'Please enter a valid timeout');
    }
    if (!editHealthcheck.interval || isNaN(editHealthcheck.interval)) {
        errors.set('interval', 'Please enter a valid interval');
    }
    if (!editHealthcheck.threshold_down || isNaN(editHealthcheck.threshold_down)) {
        errors.set('threshold_down', 'Please enter a valid "down" threshold');
    }
    if (!editHealthcheck.threshold_up || isNaN(editHealthcheck.threshold_up)) {
        errors.set('threshold_up', 'Please enter a valid "up" threshold');
    }

    switch(editHealthcheck.type) {
    case 'http':
        if (editHealthcheck.request === '') {
            errors.set('request', 'Please enter a valid request URL');
        }
        break;
    case 'tcp':
        // No need for a request?
        break;
    default:
        void (editHealthcheck.type: empty);
    }

    const commit: ?BbLbaHealthcheck = errors.size === 0 ? {
        type: editHealthcheck.type,
        request: editHealthcheck.request,
        port: Number(editHealthcheck.port),
        interval: Number(editHealthcheck.interval),
        timeout: Number(editHealthcheck.timeout),
        threshold_down: Number(editHealthcheck.threshold_down),
        threshold_up: Number(editHealthcheck.threshold_up),
    } : null;

    return [
        errors.size === 0 ? null : errors,
        commit ? { healthcheck: commit } : null,
    ];
};

export const nodesFromSelected = (serverIds: $ReadOnlyArray<string>): { nodes: Array<{ node: string }>, } => ({
    nodes: serverIds.map<{ node: string }, void>(id => ({ 'node': id }))
});

export type GuiAcmeDomain = {
    ...BbAcmeDomain,
    uiStatus: string,
    uiMessage: ?string,
}

export function useAcmeDomainUiState(acme: ?BbAcmeState, hasCloudIp: boolean): $ReadOnlyArray<GuiAcmeDomain> {

    return useMemo<Array<GuiAcmeDomain>>(() => {
        if (!acme) return [];

        const { certificate, domains } = acme;
        const isExpired = acme.certificate ? acme.certificate.expires_at <= new Date() : false;
        const certDomains = certificate ? certificate.domains : null;

        const invalidDomains = domains.filter(x => x.status === 'invalid');
        const hasInvalid = invalidDomains.length > 0;

        return domains.map(raw => {
            const onCert = certDomains?.find(domain => domain === raw.identifier);

            let uiStatus = '';
            let uiMessage: ?string = null;

            if (!onCert && raw.status === 'pending' && !hasCloudIp) uiStatus = 'unchecked';
            else if (onCert && raw.status === 'valid') uiStatus = 'valid';
            else if (onCert && isExpired) uiStatus = 'expired';
            else if (onCert && raw.status === 'invalid') uiStatus = 'failing';
            else if (!onCert && raw.status === 'invalid') uiStatus = 'invalid';
            else if (!onCert && (raw.status === 'valid' || raw.status === 'pending') && !hasInvalid) uiStatus = 'pending';
            else if (!onCert && (raw.status === 'valid' || raw.status === 'pending') && hasInvalid) {
                uiStatus = 'blocked';
                uiMessage = 'Cannot issue an ACME certificate because domain(s) ' + (invalidDomains.map(x => x.identifier).join(', ')) + ' are invalid';
            }

            return {
                ...raw,
                uiStatus,
                uiMessage,
            };
        });
    }, [acme, hasCloudIp,]);
}