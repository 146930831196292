// @flow
import { useMemo } from 'react';
import { PanelHeader, Panel, PanelButtonBar, PanelBar } from '../../../element/Panel';
import { ValueSelect } from '../../../element/ValueSelect';
import { TextInput } from '../../../element/Input';
import { ToggleGroup } from '../../../element/ToggleGroup';
import { useCurrentAccountId } from '../../../hoc/lib';
import { Notice } from '../../../element/Notice';
import { useOrbitContainers } from '../../../hoc/Orbit';
import { Link } from 'react-router-dom';
import { SkeletonBar } from '../../../element/Skeleton';
import { RC_CACHED } from '../../../../state/resource/type';
import { Button } from '../../../element/Button';

import type { OrbitEditMetaType, ContainerHistoryEdit } from '../def';
import type { EditorModal } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';

type ContainerHistoryFormProps = {
    +editor: EditorModal<OrbitEditMetaType<ContainerHistoryEdit>, FormErrors, OrbitContainerMeta>,
    +container: string,
}

const modeOptions = [
    { label: 'None', value: 'disable', },
    { label: 'Create New', value: 'create', },
    { label: 'Use Existing', value: 'existing', },
];


export const ContainerHistoryForm = ({ editor, container, }: ContainerHistoryFormProps): React$Node => {
    const accountId = useCurrentAccountId() || '';
    const { value, setValue, } = editor;
    const { containers, fetched } = useOrbitContainers();
    const containerChoices = useMemo(() => {
        let result = [
            { label: 'Select...', value: '' },
        ];
        if (containers) {
            Object.keys(containers).sort((a, b) => a.localeCompare(b)).forEach(key => {
                if (key !== container) result.push({ label: key, value: key });
            });
        }


        return result;
    }, [containers, container]);

    if (value == null) return null;
    const { edit } = value;

    const existingHasObjects = containers?.[value?.rawMeta.archive.history || '']?.count > 0;

    return (
        <Panel>
            <PanelHeader
                title='Object Archiving'
                description='Configure Object Archiving for this Orbit container'
            />
            <PanelBar>
                {value.rawMeta.archive.versions
                    ? <Notice>
                        This container is currently using "versions" object versioning.<br/>
                        "Versions" must be disabled before you can use container archive.
                    </Notice>
                    : <>

                        <Notice icon='speech'>
                            Object Archiving copies older versions of objects to
                            a separate Orbit Container whenever they are updated or deleted.
                        </Notice>

                        <ToggleGroup
                            options={modeOptions}
                            selected={edit.mode}
                            onSelect={(mode) => setValue({ ...value, edit: { ...edit, mode } })}
                        />

                        <div>
                            {edit.mode === 'create'
                                ? <TextInput
                                    label='New Container Name'
                                    hint='A new Orbit container will be created to be the Archive Container'
                                    value={edit.create_container}
                                    onChange={(create_container) => setValue({ ...value, edit: { ...edit, create_container, gen_create: false, } })}
                                    autoPopulated={edit.gen_create}
                                    errorText={editor.errors.get('create_container')}
                                />
                                : null
                            }
                            {edit.mode === 'create' && editor.errors.has('create_container_exists') && containers?.[edit.create_container] != null
                                ? <Button
                                    kind='tertiary'
                                    color='grey'
                                    onClick={() => setValue({...value, edit: {...edit, mode: 'existing',  existing_container: edit.create_container } })}
                                    >
                                        Select existing
                                </Button>
                                : null
                            }

                            {edit.mode === 'existing' && fetched === RC_CACHED
                                ? <ValueSelect
                                    label='Existing Container'
                                    selected={edit.existing_container}
                                    options={containerChoices}
                                    onSelect={(existing_container) => setValue({ ...value, edit: { ...edit, existing_container } })}
                                    errorText={editor.errors.get('existing_container')}
                                />
                                : null
                            }
                            {edit.mode === 'existing' && fetched !== RC_CACHED
                                ? <SkeletonBar size='lg'/>
                                : null
                            }

                            {edit.mode === 'disable' && existingHasObjects
                                ? <Notice type='warning' icon='info' className='mt-4'>
                                    <p>Disabling Object Archiving will not automatically remove the existing archive container (<Link to={`/accounts/${accountId}/orbit/container/${value.rawMeta.archive.history || ''}`}>{value.rawMeta.archive.history || ''}</Link>).</p>
                                   <p>If you want to delete the archive container you'll need to do that as a separate action.</p>
                                </Notice>
                                : null
                            }

                            {(edit.mode === 'create' && existingHasObjects) || (edit.mode === 'existing' && existingHasObjects && edit.existing_container !== value.rawMeta.archive.history)
                                ? <Notice type='warning' icon='info' className='mt-4'>
                                <p>Choosing a different container for Object Archiving will not automatically remove the existing archive container (<Link to={`/accounts/${accountId}/orbit/container/${value.rawMeta.archive.history || ''}`}>{value.rawMeta.archive.history || ''}</Link>).</p>
                               <p>If you want to delete the archive container you'll need to do that as a separate action.</p>
                            </Notice>
                                : null
                            }

                        </div>

                    </>
                }

            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};