// @flow

import { useState, useMemo } from 'react';
import { Panel, PanelHeader, PanelBar } from '../../element/Panel';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { navVariants, pageTransition } from '../../element/animation';
import { Table, Tr, Th as TableTh, Td, TrHeader, ForceSelectedTooltip } from '../../element/Table';
import { NoResourcesTr } from '../../element/NoResourceMessages';
import { useItemSelect } from '../../common/ResourceSelector';
import { useOrbitObjectListActions, getAllItemsCounts } from './def';
import { ItemSelector } from '../../element/ItemSelector';
import { PathBreadcrumb, usePathBreadcrumb } from './PathBreadcrumb';
import { Tooltip } from '../../element/Tooltip';
import { displayBytesSize, RightArrow, formatDateTime } from '../../element/Styled';
import { isShowDetailsResourceStatus, SkeletonListLoadingEntries, SkeletonBar, SkeletonChip } from '../../element/Skeleton';
import { FlipPagerBar, ManyObjectsWarning } from '../../element/FlipPagerBar';
import { useOrbitContainerObjects, useOrbitContainerAddDirectory } from '../../hoc/orbit/Container';
import { useFileUpload } from '../../hoc/orbit/Upload';
import { Chip } from '../../element/Chip';
import { useCurrentAccountId } from '../../hoc/lib';
import { Dialog } from '../../element/Dialog';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { OrbitUploadDialog } from './OrbitUploadDialog';
import { TextInput } from '../../element/Input';
import { DropFileOverlay } from '../../element/DropFileOverlay';
import { DeleteDialog } from '../../common/CommonDialogs';
import { PagerBar } from '../../element/PagerBar';
import { ENTER } from '../../hoc/GlobalSearch';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router';
import type { OrbitObject } from '../../../api/type.orbit';
import type { ThProps } from '../../element/Table';
import type { OrbitTreeNode } from '../../../state/Orbit/type';


const ImgDbiChip = ({ object }: { object: OrbitObject | OrbitTreeNode }): React$Node => {
    switch(object.content_type) {
    case 'application/x-brightbox-database-snapshot':
        return (
            <Chip
                id={object.id}
                icon="resource/cloud-sql"
            />
        );
    case 'application/x-brightbox-server-image':
        return (
            <Chip
                id={object.id}
                icon="resource/image"
            />
        );
    default:
        return object.id;
    }
};

function useNavAnimation(curr_path: ?$ReadOnlyArray<string>): [number, string | false] {
    const [stored, setStored] = useState<?[$ReadOnlyArray<string>, number, string | false]>(null);

    const dir = curr_path;

    let custom = 1, initial: false | string = false;

    if (dir) {
        if (stored == null) {
            setStored([dir, custom, initial]);
        } else if (stored[0] !== dir) {
            const [sp, ] = stored;

            if (sp.length < dir.length) {
                initial = 'initialRight';
                custom = -1;
            } else {
                initial = 'initialLeft';
                custom = 1;
            }

            setStored([dir, custom, initial]);
        }
    }

    return stored ? [stored[1], stored[2]] : [custom, initial];
}

type OrbitContainerObjectsProps = {
    +id: string,
    +match: Match,
    +isObjects: boolean,
}

const SimpleTh = ({ field, children, ...rest }: { ...ThProps, +children: React$Node, field?: string }) => (
    <TableTh {...rest}>
        <Tooltip overlay={ManyObjectsWarning}>
            <span className='cursor-not-allowed'>
                {children}
            </span>
        </Tooltip>
    </TableTh>
);

export const OrbitContainerObjects = ({ id, match, isObjects, }: OrbitContainerObjectsProps): React$Node => {
    const { path, paths, marker } = usePathBreadcrumb();

    const {
        cacheStatus,
        flip,
        full,
        lastSeenCount,
        pageSize,
        setPageSize,
        refresh,
        mode,
    } = useOrbitContainerObjects(id, path, marker);
    const { dialog: addDirectoryDialog, messages: addDirectoryMessages, error: addDirectoryError, onSave: onAddDirectory } = useOrbitContainerAddDirectory(id, path, refresh);

    const [custom, initial] = useNavAnimation(paths);
    const accountId = useCurrentAccountId() || '';

    const { confirmUploadDialog, setFileValue, uploadMessages, hoverClass, uploadProgress, uploadableFiles, } = useFileUpload(id, path, refresh);

    const objects: $ReadOnlyArray<OrbitTreeNode> | $ReadOnlyArray<OrbitObject> = isShowDetailsResourceStatus(cacheStatus)
        ? (flip?.objects || full?.objects || ([]: $ReadOnlyArray<OrbitTreeNode>))
        : ([]: $ReadOnlyArray<OrbitTreeNode>);

    const Th = full?.Th || SimpleTh;

    const { deleteDialog: deleteObjectsDialog, ...actions } = useOrbitObjectListActions(id, paths.length ? paths[paths.length - 1]: id, mode);

    const allPrefixObjects = full?.allPrefixObjects;
    const itemSelect = useItemSelect(allPrefixObjects || objects, objects, actions, 'list');

    const selectableFilter = itemSelect?.selectable;
    const isSelected = itemSelect?.isSelected;
    const [,childrenCount] = useMemo(() => getAllItemsCounts(allPrefixObjects || [], selectableFilter), [allPrefixObjects, selectableFilter]);
    const isFlipParentSelected = useMemo(() => {
        if (mode === 'flip' && isSelected != null) {
            let id: string = '';
            for(let el of paths) {
                id += el + '/';
                console.warn('checking parent', id);
                if (isSelected(({ id }: { id: string }))) return true;
            }
        }

        return false;
    }, [paths, isSelected, mode]);

    if (itemSelect == null) return null;

    const selectable = (id !== 'images');

    return (
        <ResourceAddViewRoute
            match={match}
            listTitle={
                path === ''
                    ? (isObjects ? LABELS.container.listTitle : id)
                    : null
            }
            dialog={
                <>
                    <OrbitUploadDialog
                        dialog={confirmUploadDialog}
                        messages={uploadMessages}
                        setFileValue={setFileValue}
                        uploadProgress={uploadProgress}
                        uploadableFiles={uploadableFiles}
                    />

                    <Dialog
                        dialog={addDirectoryDialog}
                        title="Create Directory"
                        messages={addDirectoryMessages}
                        render={(dir: ?string) => (
                            <TextInput
                                label="Directory Name"
                                value={dir || ''}
                                onChange={(dirName) => addDirectoryDialog.setData(dirName)}
                                onKeyDown={(e) => {
                                    if (e.keyCode === ENTER) {
                                        onAddDirectory();
                                        e.preventDefault();
                                    }
                                }}
                                autoFocus={true}
                                errorText={addDirectoryError}
                            />
                        )}
                    />
                    <DeleteDialog
                        title={() => `Delete ${actions.editor?.value?.length || 'these'} objects?`}
                        name={`these ${actions.editor.value?.length || ''} objects`}
                        dialog={deleteObjectsDialog}
                        confirmString='delete'

                    />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            title={path === ''
                                    ? id
                                    : path.substr(0, path.length - 1).split('/').pop()
                            }
                            description={
                                <PathBreadcrumb path={path} paths={paths} />
                            }
                            actions={{
                                container_settings: id,
                                add_directory: id === 'images' ? null : () => addDirectoryDialog.show(''),
                            }}
                        />
                        <div style={{ position: 'relative', minHeight: '5em' }}>
                            <AnimatePresence custom={custom}>
                                <motion.div
                                    style={{width:'100%'}}
                                    key={path}
                                    initial={initial}
                                    animate='in'
                                    exit='out'
                                    variants={navVariants}
                                    transition={pageTransition}
                                >
                                    <PanelBar padding={false} border={true}>
                                        <Table>
                                            <thead>
                                            <TrHeader
                                                itemSelect={selectable ? itemSelect : null}
                                                context={'list'}
                                                actionColSpan={4}
                                                hasItems={objects.length > 0}
                                                forceSelected={isFlipParentSelected}
                                                columns={
                                                    <>
                                                        <Th field='_default'>Name</Th>
                                                        <Th field='bytes'>Size</Th>
                                                        <Th field='last_modified'>Last Modified</Th>
                                                        <TableTh actions={true}>&nbsp;</TableTh>
                                                    </>
                                                }
                                            />
                                            </thead>
                                            <tbody>
                                            {(objects || []).map((e) =>
                                                <Tr
                                                    key={e.id}
                                                    {...(selectable
                                                        ? itemSelect?.rowProps(e, isFlipParentSelected ? { selected: true, } : {})
                                                        : null
                                                    )}
                                                >
                                                    {selectable
                                                        ? <Td selector={true}>
                                                            <Tooltip overlay={isFlipParentSelected ? <ForceSelectedTooltip /> : null}>
                                                                <ItemSelector
                                                                    item={e}
                                                                    itemSelect={itemSelect}
                                                                    listContext={true}
                                                                    forceSelected={isFlipParentSelected}
                                                                />
                                                            </Tooltip>
                                                        </Td>
                                                        : null
                                                    }
                                                    <Td resourceName={true}>
                                                        {e.is_resource
                                                            ? <ImgDbiChip object={e}/>
                                                            : <Chip
                                                                name={e.basename}
                                                                id={e.content_type}
                                                                contentType={e.content_type}
                                                                link={itemSelect?.selectedLength > 0
                                                                    ? null
                                                                    : (e.content_type === 'application/directory'
                                                                        ? '?path=' + encodeURIComponent(e.id) + '&marker='
                                                                        : e.id + '?path=' + encodeURIComponent(path) + '&marker=' + encodeURIComponent(marker)
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    </Td>
                                                    <Td>
                                                        {e.content_type === 'application/directory'
                                                            ? <span>–</span>
                                                            : (e.is_resource
                                                                    ? <>
                                                                        <Tooltip overlay={<>
                                                                            Please
                                                                            {e.content_type === 'application/x-brightbox-database-snapshot'
                                                                                ?
                                                                                <Link to={`/accounts/${accountId}/database_snapshots/${e.id}`}> view
                                                                                    snapshot </Link>
                                                                                : null
                                                                            }
                                                                            {e.content_type === 'application/x-brightbox-server-image'
                                                                                ? <Link to={`/accounts/${accountId}/images/${e.id}`}> view image </Link>
                                                                                : null
                                                                            }
                                                                            to see accurate size information.
                                                                        </>}>
                                                                            <span className="text-gray-500">n/a</span>
                                                                        </Tooltip>
                                                                    </>
                                                                    : displayBytesSize(e.bytes)
                                                            )
                                                        }
                                                    </Td>
                                                    <Td>{formatDateTime(e.last_modified)}</Td>
                                                    <Td actions={true} onClick={(e: MouseEvent) => { e.stopPropagation(); }}>
                                                        {e.content_type === 'application/directory'
                                                            ? <Link to={'?path=' + encodeURIComponent(e.id) + '&marker='}>
                                                                <RightArrow/>
                                                            </Link>
                                                            : <>
                                                                {e.content_type === 'application/x-brightbox-database-snapshot'
                                                                    ?
                                                                    <Link to={`/accounts/${accountId}/database_snapshots/${e.id}`}>
                                                                        <RightArrow/>
                                                                    </Link>
                                                                    : null
                                                                }
                                                                {e.content_type === 'application/x-brightbox-server-image'
                                                                    ? <Link to={`/accounts/${accountId}/images/${e.id}`}>
                                                                        <RightArrow/>
                                                                    </Link>
                                                                    : null
                                                                }

                                                                {e.content_type !== 'application/x-brightbox-database-snapshot'
                                                                    && e.content_type !== 'application/x-brightbox-server-image'
                                                                    && itemSelect?.selectedLength === 0

                                                                    ? <Link to={e.id + '?path=' + encodeURIComponent(path) + '&marker=' + encodeURIComponent(marker)}>
                                                                        <RightArrow/>
                                                                    </Link>
                                                                    :null
                                                                }
                                                            </>
                                                        }
                                                    </Td>
                                                </Tr>)
                                            }
                                            {!isShowDetailsResourceStatus(cacheStatus)
                                                ? <SkeletonListLoadingEntries count={lastSeenCount}>
                                                    <Tr>
                                                        {selectable
                                                            ? <Td selector={true}>&nbsp;</Td>
                                                            : null
                                                        }
                                                        <Td resourceName={true}><SkeletonChip /></Td>
                                                        <Td><SkeletonBar/></Td>
                                                        <Td><SkeletonBar/></Td>
                                                        <Td actions={true}><SkeletonBar size="sm"/></Td>
                                                    </Tr>
                                                </SkeletonListLoadingEntries>
                                                : null
                                            }
                                            <NoResourcesTr
                                                status={cacheStatus}
                                                colSpan={4}
                                                totalCount={(objects || []).length}
                                                context={'list'}
                                                title={<>objects in {path === '' ? 'this container' : path}</>}
                                            />
                                            </tbody>
                                        </Table>
                                    </PanelBar>
                                </motion.div>
                            </AnimatePresence>

                            {flip != null
                                ? <FlipPagerBar
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    nextLink={flip.next != null ? '?path=' + path + '&marker=' + flip.next : null}
                                    prevLink={flip.prev != null ? '?path=' + path + '&marker=' + flip.prev : null}
                                    jumpToFirst={flip.jumpToFirst != null ? '?path=' + path + '&marker=' + flip.jumpToFirst : null}
                                />
                                : null
                            }
                            {full != null
                                ? <PagerBar
                                    {...full.pager}
                                    childrenCount={childrenCount}
                                />
                                : null
                            }
                        </div>

                    </Panel>
                    {hoverClass
                        ? <DropFileOverlay destination={id + '/' + path} container={id} />
                        : null
                    }
                </>
            }
        />
    );
};
