// @flow
import { useEffect } from 'react';
import '../../assets/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Main } from '../Main';
import { Router, Link } from 'react-router-dom';
import { history, useEscapeBackNav } from '../../lib/history';
import { Layout } from './Layout';
import { UserNav } from './UserNav';
import { LoginOverlay } from '../common/LoginOverlay';
import { Button } from '../element/Button';
import { MainNav } from './MainNav';
import { EnsureTrailingSlash } from './EnsureTrailingSlash';
import { RC_CACHED } from '../../state/resource/type';
import { AccountStatusBanner } from '../section/account/AccountStatusBanner';
import { AccountSwitchDialog, useAccountSwitch } from './AccountSwitchDialog';
import { Dialog, useDialog } from '../element/Dialog';
import { useAccountBillingData, accountBillingContext } from '../hoc/Billing';
import { OlarkWrapper } from './OlarkWrapper';
import { useCurrentAccountId } from '../hoc/lib';
import { CreateAccountConfirmDialog } from '../section/account/DIalogs';
import { useIdleTimerApiCheck } from '../hoc/ControlPanel';

import type { CloudGuiState } from '../../state/cloudgui';
import type { BbCollectedAccount } from '../../api/type.acc';
import type { Dispatch } from 'redux';
import type { AuthAction } from '../../state/auth/type';
import type { DialogState } from '../element/Dialog';

type ManageStateProps = {
    userLoaded: boolean,
    hasScopes: boolean,
    currAccount: ?BbCollectedAccount,
    pendingDialog: DialogState<null>,
};

const useManage = (): ManageStateProps => {
    const pendingDialog = useDialog<null>([]);

    useEffect(() => {
        if (window.location.search.indexOf('pending') !== -1) pendingDialog.show();
        //  only run this on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...useSelector<CloudGuiState, $Diff<ManageStateProps, { pendingDialog: DialogState<null> }>>((state: CloudGuiState) => {
            const { account } = state.Resource;

            return {
                currAccount: state.Auth.currAccountId && account ? account.collected[state.Auth.currAccountId] : null,
                userLoaded: state.Auth.currUser != null && !state.Auth.processing && account && account.fetched === RC_CACHED,
                hasScopes: state.Auth.scopes.has('infrastructure'),
            };
        }),
        pendingDialog,
    };
};

const useUriControlsSelectedAccount = () => {
    const currAccountId: ?string = useCurrentAccountId();

    const dispatch = useDispatch<Dispatch<AuthAction>>();
    const urlLocation = history.location;
    const urlMatch = useRouteMatch('/accounts/:accountId');
    const urlAccountId = urlMatch?.params?.accountId || '';

    useEffect(() => {
        if (
            // ignore switches initiated by the user, from the switch dialog
            !(urlLocation.state && urlLocation.state.ignoreAccountRoute)
            // obviously we want to be switching to something.
            && (urlAccountId)
            // when there's no current accountId, the gui is still
            // loading, and GetAccountLogic is still running.
            // so don't force a switch through this way - it's
            // just a duplication of effort
            && (currAccountId && urlAccountId !== currAccountId)
        ) {
            dispatch({
                type: 'AUTH_USER_SWITCH_ACCOUNT',
                payload: {
                    accountId: urlAccountId,
                },
            });
        }
    }, [urlAccountId, urlLocation, currAccountId, dispatch,]);
};

export const ManageBase = (): React$Node => {
    const {
              currAccount, hasScopes, userLoaded, pendingDialog,
          } = useManage();

    useEscapeBackNav();
    useUriControlsSelectedAccount();
    useIdleTimerApiCheck();

    const billingResult = useAccountBillingData();
    const { Provider: BillingProvider } = accountBillingContext;

    const accountSwitch = useAccountSwitch();

    const baseUrl = currAccount ? '/accounts/' + currAccount.id : '/accounts';

    const createAccountConfirm = useDialog([{
        label: 'Create Account',
        kind: 'primary',
        onSelect: () => {
            history.push('/signup/account?additional');
        }
    }]);

    const showCreateAccountConfirm = () => {
        accountSwitch.dialog.hide();
        createAccountConfirm.show();
    }

    return (
        <Router history={history}>
            <OlarkWrapper />
            <CreateAccountConfirmDialog dialog={createAccountConfirm} />
            <Dialog
                dialog={pendingDialog}
                title={'Sorry about the delay!'}
                footerLink={
                    <Link to={`/accounts/${currAccount?.id || ''}/support/`} className='inline-block'>
                        <Button
                            size='sm'
                            onClick={() => pendingDialog.hide()}
                        >Contact Support</Button>
                    </Link>}
                render={() => (
                    <div>
                        <p>
                            There seems to be a bit of a delay in getting your account activated at the moment.
                            If your account doesn't get activated soon, please get in touch.
                        </p>
                    </div>
                )}
            />


            <BillingProvider value={[currAccount, billingResult]}>

                {!hasScopes
                    ? <LoginOverlay/>
                    : <Dialog
                        title='Switch Account'
                        dialog={accountSwitch.dialog}
                        footerLink={
                            <Button kind='bare' size='sm' preIcon='add-fill' onClick={showCreateAccountConfirm}>Create New Account</Button>
                        }
                        render={() => <AccountSwitchDialog accountSwitch={accountSwitch}/>}
                        type='wide'
                    />
                }

                <EnsureTrailingSlash/>

                <Layout
                    sidebar={
                        <MainNav
                            baseUrl={baseUrl}
                            currAccount={currAccount}
                            onShowSelectAccount={accountSwitch.onShowSelectAccount}
                        />
                    }
                    content={
                        <>
                            {currAccount ? <AccountStatusBanner /> : null}

                            <div className='l-app__top-bar'>
                                <UserNav/>
                            </div>

                            <Main baseUrl={baseUrl} userLoaded={userLoaded} currAccount={currAccount}/>
                        </>
                    }
                />
            </BillingProvider>
        </Router>
    );
};
