// @flow

import { useViewResource } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { ApiClientSecretDialog } from './ApiClientSecretDialog';
import { useSecretDisplayDialog } from '../../hoc/Secret';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { AppApiDialogs, useAppApiDialogs, } from '../../common/CommonDialogs';
import { HiddenPassword } from '../../element/HiddenPassword';
import { SkeletonBar } from '../../element/Skeleton';
import { useResourceEditorModal } from '../../common/Editor';
import { NameEditPanel } from '../../element/NameEditPanel';
import { RadioGroupSetting } from '../../element/Setting/RadioGroupSetting';
import { TextSetting } from '../../element/Setting/TextSetting';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router';
import type { BbApiClient, BbApiClientParams, BbApiPermissionsGroup, } from '../../../api/type.cli';
import type { EditorDef } from '../../common/Editor';

export const PERMISSIONS_GROUP_LABEL = {
    'full': 'All resources',
    'storage': 'Orbit only',
};

export const PERMISSIONS_GROUP_DESC = {
    'full': 'Read and write access to all account resources (including Orbit containers)',
    'storage': 'Acess to specific Orbit storage containers only via Orbit access control rules',
};

const nameDef: EditorDef<BbApiClient, string, BbApiClientParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbApiClient) => res.name || '',
    onValidate: null,
};
const descriptionDef: EditorDef<BbApiClient, string, BbApiClientParams, null> = {
    emptyErrors: null,
    editUri: 'description',
    onEdit: (res: BbApiClient) => res.description || '',
    onValidate: null,
};
const permissionsDef: EditorDef<BbApiClient, BbApiPermissionsGroup, BbApiClientParams, null> = {
    emptyErrors: null,
    editUri: 'permissions_group',
    onEdit: (res: BbApiClient) => res.permissions_group || '',
    onValidate: null,
};

export const permissionsChoices = [
    {
        value: 'full',
        label: PERMISSIONS_GROUP_LABEL['full'],
        description: PERMISSIONS_GROUP_DESC['full'],
    },
    {
        value: 'storage',
        label: PERMISSIONS_GROUP_LABEL['storage'],
        description: PERMISSIONS_GROUP_DESC['storage'],

    },
];

export const ApiClientView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbApiClient, BbApiClientParams>('api_client', id);
    const { item, simpleAction, deleteAction, } = resource;
    const newPassword = useSecretDisplayDialog(id);
    const name = useResourceEditorModal(resource, nameDef, match.path);
    const description = useResourceEditorModal(resource, descriptionDef, match.path);
    const permissions = useResourceEditorModal(resource, permissionsDef, match.path);

    const isRevoked = (item != null && item.revoked_at !== null);

    const dialogs = useAppApiDialogs(!isRevoked, simpleAction, deleteAction);

    return (
        <>
            <ResourceAddViewRoute
                listTitle={LABELS.api_client.listTitle}
                match={match}
                resource={resource}
                dialog={
                    <>
                        <AppApiDialogs name={item?.name || id} deleteDialog={dialogs.deleteDialog} resetPasswordDialog={dialogs.resetPasswordDialog} />

                        <ApiClientSecretDialog secret={newPassword} containerRegistry={false} />
                    </>
                }
                view={
                    <>
                        <Panel>
                            <PanelHeader
                                title={item?.name || item?.id}
                                editTitleLink={!isRevoked ? name.editUri : null}
                                actions={dialogs.actions}
                            />
                            <PanelMultiSettingsBar
                                cacheStatus={resource.status}
                                details={{
                                    id: item?.id,
                                    status: item ? (item.revoked_at ? 'revoked' : 'active') : null,
                                }}
                                settings={[
                                    [
                                        {
                                            name: 'Permissions',
                                            summary: item ? PERMISSIONS_GROUP_LABEL[item.permissions_group] : <SkeletonBar size='sm'/>,
                                            route: !isRevoked ? permissions.editUri : null,
                                        },
                                        {
                                            name: 'Secret',
                                            summary: <HiddenPassword/>,
                                            settingsBtn: !isRevoked
                                                ? {
                                                    children: 'Reset Secret',
                                                    kind: 'secondary',
                                                    onClick: () => dialogs.resetPasswordDialog.show()
                                                }
                                                : null,
                                        }
                                    ],
                                    [
                                        {
                                            name: 'Description',
                                            summary: item ? (item.description || '-'): null,
                                            route: description.editUri,
                                        },
                                    ]
                                ]}
                            />
                        </Panel>
                    </>
                }
                editors={[
                    { editor: name, render: () => <NameEditPanel editor={name}/> },
                    {
                        editor: permissions, render: () => (
                            <RadioGroupSetting
                                title='Permissions'
                                editor={permissions}
                                choices={permissionsChoices}
                            />
                        )
                    },
                    { editor: description, render: () => <TextSetting editor={description} title='Description' label='API Client Description' textarea={true} /> , },
                ]}
            />

        </>
    );
};
