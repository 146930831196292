// @flow

import { useRef } from 'react';
import { formatResourceCost } from './Styled';
import { getMonthlyPrice } from '../../lib/pricing';
import { Tooltip } from './Tooltip';
import { Button } from './Button';
import { Pill } from './Pill';
import { Notice } from './Notice';

type PerMonthPriceProps = {
    +label: string,
    +id: string,
    +inDialog: boolean,
};

export const PerMonthPrice = ({ label, id, inDialog, }: PerMonthPriceProps): React$Node => {
    const ref = useRef();

    return (
        <>
            <Notice icon='label' size='sm'>
                {label} cost £{formatResourceCost(getMonthlyPrice(id) || 0)} <span className='text-gray-600 text-xxs tracking-wider'>per month</span>
                <Tooltip
                    {...(inDialog ? { getTooltipContainer: () => ref.current } : null)}
                    overlay={
                        <div>
                            <p>{label} are billed by the hour. Monthly prices are shown for convenience, based on 730 hours of usage in an average month.</p>
                            <p className='mb-2'><a href='https://www.brightbox.com/pricing/' target='_blank' rel='noopener noreferrer'><Button size='xs' color='white' kind='tertiary' postIcon='new-window'>View Full Pricing Information</Button></a></p>
                        </div>}
                >
                    <Pill>?</Pill>
                </Tooltip>
            </Notice>
            {/*
              * focus-trap-react breaks tooltip links in modals since they normally get rendered outside the modal.
              * include a div to mount them here to fix it.
              */}
            <div ref={ref} style={{height: 0}}>&nbsp;</div>
        </>
    );
};