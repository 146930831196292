// @flow

import type { EditPortTranslator } from './type';
import type { BbCloudIpParams, BbPortTranslator } from '../../../../api/type.cip';
import type { FormErrors } from '../../../common/lib';

export function validatePortTranslators(translators: $ReadOnlyArray<EditPortTranslator>): [?FormErrors, ?$Shape<BbCloudIpParams>] {
    let errors = new Map<string, string>();
    const inPorts: Array<string | number> = [];
    let commit = [];

    translators.forEach((translator: EditPortTranslator, idx) => {
        if (!translator.incoming || isNaN(Number(translator.incoming))) {
            errors.set('incoming_' + idx, 'Please enter valid "in" port.');
        }
        if (!translator.outgoing || isNaN(Number(translator.outgoing))) {
            errors.set('outgoing_' + idx, 'Please enter valid "out" port.');
        }
        if (!translator.protocol) {
            errors.set('protocol_' + idx, 'Please select protocol port.');
        }
        if (inPorts.indexOf(translator.incoming) !== -1) {
            errors.set('incoming_' + idx, 'Please use unique "in" ports.');
        }
        inPorts.push(translator.incoming);

        commit.push(({
            incoming: Number(translator.incoming),
            outgoing: Number(translator.outgoing),
            protocol: translator.protocol,
        }: BbPortTranslator));
    });

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? { port_translators: commit } : null,
    ];
}
