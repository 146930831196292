// @flow

import { PAGING_MIN_SIZE } from '../../component/element/lib/paging';

import type { UiState, UiStateAction } from './type';
import type { StoreClearAction } from '../type';

const emptyState: UiState = {
    sort: ({}: any),// : any? https://github.com/facebook/flow/issues/2977,
    pageSize: PAGING_MIN_SIZE,
    offset: new Map<string, number>(),
};

export const UiReducer = (
    state: UiState = emptyState,
    action: UiStateAction | StoreClearAction
): UiState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return {
            ...emptyState,
            pageSize: state.pageSize,
        };
    case 'SORT_TOGGLE_FIELD':
        const { sortName, field } = action.payload;
        const order = (state.sort[sortName] && state.sort[sortName].field === field)
            ? state.sort[sortName].order
            : ''
        ;

        return {
            ...state,
            sort: {
                ...state.sort,
                [sortName]: {
                    field,
                    order: (order === 'desc') ? 'asc' : 'desc',
                },
            }
        };
    case 'UI_SET_PAGE_SIZE':
        return {
            ...state,
            pageSize: action.payload.pageSize
        }
    case 'UI_SET_OFFSET':
        return {
            ...state,
            offset: new Map<string, number>([...state.offset, [ action.payload.tableName, action.payload.offset ]])
        }
    default:
        void (action: empty);
        break;
    }

    return state;
};