// @flow

import { SvgIcon } from './SvgIcon';
import { StatusIcon } from './Styled';
import { Icons } from '../../assets/icons';
import { getBackgroundFromString, } from '../common/lib';
import { useGravatar } from './Gravatar';
import { RC_SUCCESS } from '../../state/resource/type';
import { getIconForContentType } from '../../assets/content-types';

import type { Icon } from '../../assets/icons';

export type AvatarProps = {
    id?: ?string,
    icon?: ?Icon,
    contentType?: ?string,
    imageName?: ?string,
    engine?: ?string,
    status?: ?string,
    title?: string,
    initial?: ?string,
    colorId?: ?string,
    className?: ?string,
    gravatar_url?: ?string,
    skeleton?: true,
    progress?: ?number,
};

export const distrosWithImages: Array<[string, Icon]> = Object.keys(Icons)
    .filter(s => s.startsWith('os/'))
    .map(s => [s.replace('os/', '').toLowerCase(), ((s: any): Icon)])


let imageIconCache = new Map<string, ?Icon>();

const engineIcons = new Map<string, Icon>([
    ['postgresql', 'db/postgresql',],
    ['mysql', 'db/mysql',],
]);

const SIZE = 64;
const VIEW = 48;
const VIEW_BOX = `-${VIEW} -${VIEW} ${VIEW * 2} ${VIEW * 2}`;
const SEGMENTS = 32;

export const Avatar = ({ id, icon, contentType, imageName, engine, initial, status, title, className: extraClassName, colorId, gravatar_url, skeleton, progress, ...rest }: AvatarProps): React$Node => {
    const gravatarStatus = useGravatar(gravatar_url);
    let className = 'c-avatar ' + (extraClassName || '');
    let svg: ?Icon = null;
    let span: ?React$Node = null;
    let checkedGravatarUrl: ?string = null;

    let color: ?{ background: string } = null;

    if (imageName) {
        if (!imageIconCache.has(imageName)) {
            const index = distrosWithImages.findIndex(
                ([search,]) => imageName.substr(0, search.length).toLowerCase() === search.toLowerCase()
            );
            imageIconCache.set(imageName, index === -1 ? null : distrosWithImages[index][1]);
        }

        svg = imageIconCache.get(imageName) || icon || 'resource/image';
    } else if (engine) {
        svg = engineIcons.get(engine) || 'resource/cloud-sql';
    } else if (icon) {
        svg = icon;
    } else if (contentType) {
        svg = getIconForContentType(contentType);
        className += ' c-avatar--object';
    } else if (gravatarStatus === RC_SUCCESS && typeof gravatar_url === 'string') {
        checkedGravatarUrl = gravatar_url;
    } else if (initial) {
        span = initial;
    } else if (skeleton) {
        className += ' o-skeleton';
    } else {
        svg = 'warning';
    }

    const res_type = (colorId || id)?.substr(0, 3) || '';
    if (res_type && (res_type === 'grp' || res_type === 'usr' || res_type === 'acc' || res_type === 'col')) {
        color = getBackgroundFromString(colorId || id || '');
    } else if (colorId && colorId.startsWith('dockerimage_')) {
        color = getBackgroundFromString(colorId.substring(12));
    }

    let progressSvg: ?React$Node = null;
    if (progress != null) {
        let path = 'M0 0 ';
        for (let i = 0; i < SEGMENTS + 1; i++) {
            let prop = i / SEGMENTS;
            if (prop >= progress) path += `L ${SIZE * Math.sin(prop * 2 * Math.PI)} ${SIZE * -1 * Math.cos(prop * 2 * Math.PI)} `;
        }
        if (progress === 0) path += `L 0 -${SIZE}`;
        path += 'Z';

        progressSvg = (
            <svg className='c-avatar__progress' xmlns="http://www.w3.org/svg/2000" viewBox={VIEW_BOX}>
                <path d={path} fillOpacity="0.6" fill="white"/>
            </svg>
        );
    }

    return (
        <div className={className} title={title || ''} style={color} {...rest}>
            {progressSvg}
            {svg ? <SvgIcon svg={svg} className='c-avatar__icon' /> : null}
            {checkedGravatarUrl ? <img alt='gravatar profile' src={'https://www.gravatar.com/avatar/' + checkedGravatarUrl + '?s=42&d=404'} className='c-avatar__gravatar' /> : null}
            {span ? <span className='c-avatar__letter'>{span}</span> : null}
            {status ? <StatusIcon status={status} /> : null}
        </div>
    )
}