// @flow
import { useViewResource, } from '../../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../../element/Panel';
import { Dialog, } from '../../../element/Dialog';
import { useSecretDisplayDialog } from '../../../hoc/Secret';
import { useResourceEditorModal } from '../../../common/Editor';
import { AppApiDialogs, useAppApiDialogs, } from '../../../common/CommonDialogs';
import { NameEditPanel } from '../../../element/NameEditPanel';
import { HiddenPassword } from '../../../element/HiddenPassword';
import { Notice } from '../../../element/Notice';
import { SecretDisplay } from '../../../element/SecretDisplay';
import { LABELS } from '../../../element/ResourceLabels';

import type { EditorDef } from '../../../common/Editor';
import type { Match } from 'react-router';
import type { BbApplication, BbApplicationParams, } from '../../../../api/type.app';

const nameDef: EditorDef<BbApplication, string, BbApplicationParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbApplication) => res.name || '',
    onValidate: null,
};

export const ApplicationView = ({ id, match }: { id: string, match: Match, }): React$Node => {
    const resource = useViewResource<BbApplication, BbApplicationParams>('application', id);
    const { item: application, simpleAction, deleteAction } = resource;
    const dialogs = useAppApiDialogs(application?.revoked_at == null, simpleAction, deleteAction);

    const newPassword = useSecretDisplayDialog(id);
    const name = useResourceEditorModal(resource, nameDef, match.path);

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.application.listTitle}
            match={match}
            resource={resource}
            dialog={
                <>
                    <Dialog dialog={newPassword.dialog} title='Secret Created' render={() =>
                        <div>
                            <p>The new secret is:</p>
                            <SecretDisplay secret={newPassword.secret} />
                            <Notice type='warning' icon='info'>Please store the new secret securely before closing this dialog as it won't be viewable afterwards.</Notice>
                        </div>
                    }/>

                    <AppApiDialogs name={application?.name || id} deleteDialog={dialogs.deleteDialog} resetPasswordDialog={dialogs.resetPasswordDialog} />
                </>
            }
            view={
                <Panel>
                    <PanelHeader
                        cacheStatus={resource.status}
                        title={application?.name || application?.id}
                        editTitleLink={!application?.revoked_at ? name.editUri : null}
                        actions={dialogs.actions}
                    />
                    <PanelMultiSettingsBar
                        cacheStatus={resource.status}
                        details={{
                            id: application?.id,
                            status: application?.status,
                            created_at: application?.created_at,
                            revoked_at: application?.revoked_at,
                        }}
                        settings={[
                            [
                                {
                                    name: 'Secret',
                                    summary: <HiddenPassword/>,
                                    settingsBtn: !application?.revoked_at
                                        ? {
                                            onClick: () => dialogs.resetPasswordDialog.show(),
                                            children: 'Reset Secret',
                                            kind: 'secondary',
                                        }
                                        : null,
                                },
                            ]
                        ]}
                    />
                </Panel>
            }
            editors={[
                { editor: name, render: () => <NameEditPanel editor={name}/> },
            ]}
        />
    );
};