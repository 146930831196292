// @flow

import { useCurrentAccount } from '../../hoc/lib';
import { useOrbitAccountMeta } from '../../hoc/Orbit';
import { formatOrbitSize, formatMegabytesSize } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../../lib/history';
import { SkeletonBar } from '../../element/Skeleton';

import type { BbAccount } from '../../../api/type.acc';

type ItemProps = {
    +label: string,
    +raw: ?number,
    +format?: ('number' | 'bytes' | 'mb'),
    +link: string,
    +onlyWithUsage: ?boolean
}

const Item = ({ format, raw, label, link, onlyWithUsage, }: ItemProps): React$Node => {
    if (onlyWithUsage && raw === 0) return null;

    let formatted = null;
    if (raw != null) {
        switch(format) {
        case 'bytes':
            formatted = formatOrbitSize(raw);
            break;
        case 'mb':
            formatted = formatMegabytesSize(raw);
            break;
        case 'number':
        case null:
        case undefined:
            formatted = raw;
            break;
        default:
            void (format: empty);
        }

    }

    return (
        <div className="c-summary__item">
            <div className="c-summary__item-label">{label}</div>
            {raw != null
                ? <Link to={link} className="c-summary__item-value">{formatted}</Link>
                : <SkeletonBar size="xs"/>
            }
        </div>
    );
};

type AccountResourceUsageProps = {
    onlyWithUsage?: boolean,
}

export const AccountResourceUsage = ({ onlyWithUsage, }: AccountResourceUsageProps): React$Node => {
    const getRoute = useResourceRoutes();
    const account: ?BbAccount = useCurrentAccount();
    const accountOrbitMeta = useOrbitAccountMeta();

    return (
        <div className="c-summary">
            <Item onlyWithUsage={onlyWithUsage} raw={account?.servers_used} label="Cloud Servers" link={getRoute('server')}/>
            <Item onlyWithUsage={onlyWithUsage} raw={account?.cloud_ips_used} label="Cloud IPs" link={getRoute('cloud_ip')}/>
            <Item onlyWithUsage={onlyWithUsage} raw={account?.load_balancers_used} label="Load Balancers" link={getRoute('load_balancer')}/>
            <Item onlyWithUsage={onlyWithUsage} raw={account?.dbs_instances_used} label="Cloud SQL Instances" link={getRoute('database_server')}/>
            <Item onlyWithUsage={onlyWithUsage} raw={accountOrbitMeta?.bytes} format='bytes' label="Orbit Storage" link={getRoute('container')}/>
            <Item onlyWithUsage={onlyWithUsage} raw={account?.block_storage_used} format='mb' label="Block Storage" link={getRoute('volume')}/>
        </div>
    );
};
