// @flow

import { createLogic } from 'redux-logic';
import { resourceMetas } from '../../api/adapter';
import { request, } from '../../api/rest';
import to from 'await-to-js';
import { cloudIpsUrl } from '../../api/url';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS } from '../resource/type';
import { getMappableResourceKind, getResourceForDest } from './lib';

import type { Dispatch } from 'redux';
import type { CloudIpAction, CloudIpMapAction, CloudIpMapResourceTypes } from './type';
import type { CloudGuiState } from '../cloudgui';
import type { ResourceAddFull } from '../resource/type';
import type { BbCloudIp } from '../../api/type.cip';
import type { ReduxLogic } from 'redux-logic';
import type { MessageAction } from '../Message/type';

/**
 * Waits until a cloud IP is ready to be mapped, then map it.
 *
 * Only used these days for 'remap', where the unmap can take a moment to happen.
 */
export const CloudIpAssignWhenAvailableLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_ADD_FULL'],
    async process(
        deps: { getState: () => CloudGuiState, action: ResourceAddFull<any, any>, },
        dispatch: Dispatch<MessageAction | CloudIpAction>, done: () => void
    ) {
        const { CloudIp, Resource } = deps.getState();

        await Promise.all(CloudIp.map(async ({ cloudIpId, resourceId, kind, messagesId }) => {
            const cloudIp = Resource.cloud_ip.full[cloudIpId] || Resource.cloud_ip.collected[cloudIpId];
            const resource: ?CloudIpMapResourceTypes = getResourceForDest(kind, resourceId, Resource);

            if (cloudIp?.status === 'unmapped' && resource) {
                dispatch({
                    type: 'CLOUD_IP_MAP',
                    payload: {
                        cloudIpId,
                        destination: resourceId,
                        messagesId,
                    }
                });
            }
        }));

        done();
    },
});

export const CloudIpMapLogic: ReduxLogic = createLogic({
    'type': ['CLOUD_IP_MAP'],
    async process(
        deps: { getState: () => CloudGuiState, action: CloudIpMapAction, },
        dispatch: Dispatch<MessageAction | CloudIpAction>, done: () => void
    ) {
        const state = deps.getState();
        const { cloudIpId, destination, messagesId } = deps.action.payload;

        const cloudIp: ?BbCloudIp = state.Resource.cloud_ip.full[cloudIpId] || state.Resource.cloud_ip.collected[cloudIpId]

        if (!cloudIp) {
            // shouldn't get here - the map can only happen from the view page
            done();
            return;
        }

        // clear out any pending mappings to other resources.
        if (state.CloudIp.find(x => x.cloudIpId === cloudIpId) != null) {
            dispatch({
                type: 'CLOUD_IP_ASSIGNED_RESOURCE',
                payload: { cloudIpId }
            });
        }

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        if (cloudIp.server || cloudIp.database_server || cloudIp.load_balancer || cloudIp.server_group) {
            // eslint-disable-next-line
            const [unmapErr, unmapResponse] = await to(request({
                url: cloudIpsUrl + '/' + cloudIpId + '/unmap',
                method: 'POST',
            }));

            if (unmapErr) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_ERROR,
                        messages: unmapErr.response.data.errors,
                        resource: null,
                    }
                });
            } else if (destination) {
                const kind = getMappableResourceKind(destination);

                // we need to wait for the actual unmapping to happen, so just dispatch
                // this and let the event that updates the CIP to unmapped trigger the mapping.
                dispatch({
                    type: 'CLOUD_IP_AUTO_ASSIGN_RESOURCE_ID',
                    payload: {
                        kind,
                        resourceId: destination,
                        cloudIpId: cloudIpId,
                        messagesId,
                    },
                });
            } else {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_SUCCESS,
                        resource: resourceMetas.cloud_ip.adapt.full(unmapResponse.data),
                    }
                });
            }
        } else if (destination) {
            const kind = getMappableResourceKind(destination);
            const resource: ?CloudIpMapResourceTypes = getResourceForDest(kind, destination, state.Resource);

            const mappingDest = resource?.resource_type === 'server'
                ? resource.interfaces[0].id
                : destination;

            // eslint-disable-next-line
            const [mapErr, mapResponse] = await to(request({
                url: cloudIpsUrl + '/' + cloudIpId + '/map',
                method: 'POST',
                data: {
                    destination: mappingDest,
                }
            }));
            if (mapErr) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_ERROR,
                        messages: mapErr.response.data.errors,
                        resource: null,
                    }
                });
                done();
                return;
            } else {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_SUCCESS,
                        resource: resourceMetas.cloud_ip.adapt.full(mapResponse.data),
                    }
                });
            }
        }

        done();
    },
});
