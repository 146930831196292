// @flow

import { useTriggeredMessages } from '../Messages';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { RC_SUCCESS } from '../../../state/resource/type';
import { toast } from 'react-toastify';
import { deleteAccess } from '../../section/orbit/def';
import { useResourcesById, useObjectToSortedList } from '../ListPage';
import { useCurrentAccountId } from '../lib';
import { isShowDetailsResourceStatus } from '../../element/Skeleton';
import { stringSort } from '../../element/Sort';

import type { ContainerAccess, OrbitContainerMeta } from '../../../api/type.orbit';
import type { MessageHook } from '../Messages';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { OrbitAction } from '../../../state/Orbit/type';
import type { SortedObjectList } from '../ListPage';
import type { BbApiClient } from '../../../api/type.cli';

export type OrbitDeleteAccess = {
    deleteAccessAction: (access: ?ContainerAccess) => void,
    messages: MessageHook<OrbitContainerMeta>,
}
type ContainerAccessMap = { [string]: ContainerAccess };

function accessTypeStr(a: ContainerAccess): string {
    switch(a.type) {
    case 'any-referrer':
        return (a.read ? '' : 'Deny ') + 'Public read access';
    case 'http':
        return (a.read ? 'Allow: ' : 'Deny: ') + a.referrer;
    case 'api':
        return (a.id.split(':')?.[1]) || '';
    case 'other':
        return a.id;
    case 'team':
        return 'Team';
    default:
        void (a.type: empty);
    }
    return '';
}

function accessAccessStr(a: ContainerAccess): string {
    switch(a.type) {
    case 'any-referrer':
        return a.read ? 'read' : 'none';
    case 'http':
        return a.read ? 'read' : 'none';
    case 'api':
        return a.read && a.write ? 'readwrite' : a.read ? 'read' : a.write ? 'write' : 'none';
    case 'other':
        return a.read && a.write ? 'readwrite' : a.read ? 'read' : a.write ? 'write' : 'none';
    case 'team':
        return 'full';
    default:
        void (a.type: empty);
    }
    return '';
}

const sortDef = {
    _default: (a: ContainerAccess, b: ContainerAccess) => accessTypeStr(a).localeCompare(accessTypeStr(b)),
    id: (a: ContainerAccess, b: ContainerAccess) => accessTypeStr(a).localeCompare(accessTypeStr(b)),
    type: stringSort('type'),
    access: (a: ContainerAccess, b: ContainerAccess) => accessAccessStr(a).localeCompare(accessAccessStr(b)),
};
const ENTRIES_LOADING = {};

export const useMergedContainerAccess = (meta: ?OrbitContainerMeta, allAccountApiClients: boolean): { ...SortedObjectList<ContainerAccess>, loading: boolean } => {
    const { cacheStatus: clientStatus, resources: applications, } = useResourcesById<BbApiClient>('api_client');
    const currAccountId = useCurrentAccountId() || '';

    const depsReady = isShowDetailsResourceStatus(clientStatus);

    const apiClients: ContainerAccessMap = useMemo(() => {
        let entries = {};
        let hadFullApiClient: boolean = false;

        if (depsReady && meta) {

            meta.access.forEach((apiAccess) => {
                if (apiAccess.type !== 'api') return;

                const pristine = applications.hasOwnProperty(apiAccess.cliId)
                    ? applications[apiAccess.cliId]
                    : null;

                const full = (pristine != null && pristine.permissions_group === 'full');

                if (hadFullApiClient && full) return;
                hadFullApiClient = hadFullApiClient || full;

                entries[apiAccess.id] = {
                    ...apiAccess,
                    type: 'api',
                    full,
                    read: !full && apiAccess.read,
                    write: !full && apiAccess.write,
                    client: pristine
                        ? pristine
                        : ({
                            id: apiAccess.cliId,
                            resource_type: 'api_client',
                            pristine: true,
                            name: apiAccess.cliId,
                            description: '',
                            permissions_group: 'storage',
                            // the api_client list won't have revoked ones,
                            // so if the account matches, then presumably it's deleted.
                            // so fake a revoked_at date.
                            revoked_at: currAccountId === apiAccess.accountId
                                ? (new Date())
                                : null,
                            secret: null,
                        }: BbApiClient),
                };
            });

            Object.keys(applications).filter((id: string) => allAccountApiClients ? true : applications[id].permissions_group === 'full').forEach((id: string) => {
                if (hadFullApiClient && applications[id].permissions_group === 'full') return;
                hadFullApiClient = hadFullApiClient || applications[id].permissions_group === 'full';

                const entryId = currAccountId + ':' + id;
                if (entries[entryId]) return;

                entries[entryId] = {
                    id: entryId,
                    type: 'api',
                    accountId: currAccountId,
                    cliId: id,
                    client: applications[id],
                    full: (applications[id].permissions_group === 'full'),
                    read: false,
                    write: false,
                };
            });

        }

        return entries;
    }, [depsReady, meta, allAccountApiClients, currAccountId, applications]);

    // Object.assign bypasses flow 'exponential spread' of lots of ...'s in this,
    // but it means the entries don't typecheck properly below. so take a bit of care.
    const accesses = useMemo((): ContainerAccessMap => depsReady && meta ? Object.assign(
        {},
        { team: { id: 'team', type: 'team', } },
        apiClients,
        meta.access.filter(t => t.type !== 'api').reduce((acc, e) => ({
            ...acc,
            [e.id]: e,
        }), {}),
    ) : ENTRIES_LOADING, [apiClients, depsReady, meta,]);

    return {
        ...useObjectToSortedList({ name: 'orbit_access', fields: sortDef }, accesses),
        loading: accesses === ENTRIES_LOADING,
    };
};

export const useOrbitDeleteAccess = (containerName: string): OrbitDeleteAccess => {
    const meta = useSelector((state: CloudGuiState) => state.Orbit.containers.meta.get(containerName));
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const { next, messages } = useTriggeredMessages<OrbitContainerMeta>();

    useEffect(() => {
        if (messages?.status === RC_SUCCESS) {
            toast('Access rule deleted successfully', { type: 'success' });
            messages.clear();
        }
    }, [messages]);

    return {
        deleteAccessAction: (entry: ?ContainerAccess) => {
            if (entry == null || meta == null) return;

            const headers = deleteAccess(meta, entry);

            dispatch({
                type: 'ORBIT_CONTAINER_UPDATE_META',
                payload: {
                    container: containerName,
                    headers,
                    messagesId: next(),
                },
            });
        },
        messages
    };
};