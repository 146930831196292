// @flow
import { useState, useEffect, useMemo } from 'react';
import { ImageSelector } from '../../common/ImageSelector';
import { ServerTypeSelector } from './server_type/ServerTypeSelector';
import { ServerLimitWrapper } from './server_type/ServerLimitWrapper';
import { Button } from '../../element/Button';
import { ServerGroupSelector } from '../../common/ServerGroupSelector';
import { useCurrentAccount, useCurrentUser, useUrlParamValue } from '../../hoc/lib';
import { ResourceCloudIpEdit, useCreateResourceCloudIps, } from '../../common/ResourceCloudIps';
import { useEditorDirect, useEditorModal } from '../../common/Editor';
import { NameEditPanel } from '../../element/NameEditPanel';
import { SrvAdvanced } from './SrvAdvanced';
import { RC_API_REQUEST, RC_NOT_FOUND, RC_CACHED, } from '../../../state/resource/type';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { ZoneSelector } from './ZoneSelector';
import { UserDataEditor } from './UserDataEditor';
import { UserSshKeyEditor } from '../user/edit/UserSshKeyEditor';
import { useResourcesById } from '../../hoc/ListPage';
import { DiskEncrypted } from './edit/DiskEncrypted';
import { useCreateServer } from '../../hoc/CreateResource';
import { validateUserData, validateVolume } from './edit/lib';
import { PanelResourceCreateButtons } from '../../element/Panel';
import { useResourceFetch } from '../../hoc/ViewResource';
import { GB_TO_MB } from '../../element/Styled';
import { ServerAttachVolumeEdit } from './edit/ServerAttachVolumeEdit';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router-dom';
import type { BbServerGroup, BbServerType, BbCollectedImage, } from '../../../api/type';
import type { BbServer, UserDataEdit } from '../../../api/type.srv';
import type { SelectedZone } from './ZoneSelector';
import type { ServerCreateParams, ImageOrVolume, ValidatedServerCreateParams } from '../../hoc/CreateResource';
import type { StateUpdateFn } from 'react-hooks';
import type { FormErrors } from '../../common/lib';
import type { Gigabytes } from '../../../api/type.units';
import type { EditorDirect } from '../../common/Editor';
import type { BbVolume } from '../../../api/type.volume';

// Use a constant here so we can later === compare it and determine if the selected server
// groups are in their initial state - and so we can auto select the default group.
const EMPTY_SERVER_GROUPS = []

// try to fetch the passed image. if it the api doesn't find it,
// then it's invalid, so clear it.
function useValidateImageUrlParam(value: ?string, setServer: StateUpdateFn<ServerCreateParams>) {
    const { status, fetch } = useResourceFetch('image', value);

    useEffect(() => {
        if (value != null) {
            fetch();
        }
    }, [value, fetch]);

    useEffect(() => {
        if (status === RC_NOT_FOUND) {
            setServer((server) => ({ ...server, 'image': null }));
        }
    }, [status, setServer]);
}

function useValidateDefaultTypeHandleParam(value: ?string, setServer: StateUpdateFn<ServerCreateParams>) {
    const { resources: all_server_types, cacheStatus } = useResourcesById<BbServerType>('server_type');

    useEffect(() => {
        if (cacheStatus === RC_CACHED && value != null) {
            const id = Object.keys(all_server_types).find(x => all_server_types[x].handle === value);
            if (id != null) setServer((server) => ({...server, server_type: all_server_types[id]}));
        }
    }, [cacheStatus, all_server_types, value, setServer])
}

// try to fetch the passed type. if it the api doesn't find it,
// then it's invalid, so clear it. if it's valid, select it
function useValidateDefaultTypeIdParam(value: ?string, setServer: StateUpdateFn<ServerCreateParams>) {
    const { item, status, fetch } = useResourceFetch('server_type', value);

    useEffect(() => {
        if (value != null) {
            fetch();
        }
    }, [value, fetch]);

    useEffect(() => {
        if (status === RC_NOT_FOUND) {
            setServer((server) => ({ ...server, 'server_type': null }));
        } else if (status === RC_CACHED) {
            setServer((server) => ({ ...server, 'server_type': item }));
        }
    }, [status, setServer, item]);
}

export type ImageOrVolumeSelectHook = {
    +image: EditorDirect<$ReadOnlyArray<string>, null>,
    +selectedImage: ?BbCollectedImage,

    +volume: EditorDirect<$ReadOnlyArray<string>, ?React$Node>,
    +selectedVolume: ?BbVolume,

    +hasDetachedVolume: boolean,
}

function volumeIsDetached(volume: BbVolume): boolean {
    return volume.status === 'detached';
}

function useImageOrVolumeSelect(server: ServerCreateParams, setServer: StateUpdateFn<ServerCreateParams>, volumeError: ?string): ImageOrVolumeSelectHook {
    const { resources: all_volumes } = useResourcesById<BbVolume>('volume');
    const { resources: all_images } = useResourcesById<BbCollectedImage>('image');
    const { resources: all_types } = useResourcesById<BbServerType>('server_type');
    const { imageOrVolume } = server;

    const image = useEditorDirect(
        server.image ? [server.image] : [],
        (selected: $ReadOnlyArray<string>) => {
            const image: ?BbCollectedImage = selected.length && all_images.hasOwnProperty(selected[0]) ? all_images[selected[0]] : null;

            setServer((prev: ServerCreateParams) => ({ ...prev, image: image?.id }));

            // always just set the nbs volume size so it fits the selected image.
            let nextRootVol = Math.max(Math.ceil((image?.virtual_size || 0) / GB_TO_MB), window.cgrConfig.VOLUME_LIMITS.min);
            if (nextRootVol > parseFloat(server.root_volume)) {
                setServer((prev: ServerCreateParams) => ({
                    ...prev,
                    root_volume: nextRootVol
                }));
            }
        },
        null
    );

    const selectedImage: ?BbCollectedImage = server.image ? all_images[server.image] : null;
    const selectedVolume: ?BbVolume = server.volume ? all_volumes[server.volume] : null;
    const serverType: ?BbServerType = server.server_type;

    const detachedVolumeIds = useMemo<$ReadOnlyArray<string>>(() => Object.keys(all_volumes).filter((id: string) => volumeIsDetached(all_volumes[id])), [all_volumes]);
    const hasDetachedVolume: boolean = detachedVolumeIds.length > 0;

    useEffect(() => {
        if (detachedVolumeIds.length === 1) {
            setServer((prev) => ({ ...prev, volume: detachedVolumeIds[0], }));
        }
    }, [detachedVolumeIds, setServer]);

    useEffect(() => {
        const needsNetworkType = imageOrVolume === 'volume';

        if (needsNetworkType
            && (
                serverType == null
                || serverType.storage_type !== 'network'
            )
        ) {
            const networkTypes = Object.keys(all_types)
                .filter(id => all_types[id].storage_type === 'network')
                .sort((a, b) => all_types[a].disk_size - all_types[b].disk_size);

            if (networkTypes.length) {
                setServer((prev) => ({ ...prev, server_type: all_types[networkTypes[0]] }));
            }
        }
    }, [imageOrVolume, serverType, all_types, setServer]);

    const volume = useEditorDirect(
        server.volume ? [server.volume] : [],
        (selected: $ReadOnlyArray<string>) => {
            const volume: ?BbVolume = selected.length && all_volumes.hasOwnProperty(selected[0]) ? all_volumes[selected[0]] : null;

            setServer((prev: ServerCreateParams) => ({ ...prev, volume: volume?.id }));
        },
        volumeError,
    );

    return {
        image,
        selectedImage: imageOrVolume === 'image' ? selectedImage : null,
        volume,
        selectedVolume: imageOrVolume === 'volume' ? selectedVolume : null,
        hasDetachedVolume,
    };
}

export const ServerCreate = ({ match }: { match: Match }): React$Node => {
    const defaultImage = useUrlParamValue(/image=(img-[a-z0-9]{5})/);
    const defaultTypeHandle = useUrlParamValue(/handle=([a-z0-9.-]+)/);
    const defaultTypeId = useUrlParamValue(/type=(typ-[a-z0-9]{5})/);
    const [errors, setErrors] = useState<FormErrors>(new Map());

    const [server, setServer] = useState<ServerCreateParams>({
        image: defaultImage,
        volume: null,
        name: '',
        server_type: null,
        zone: null,
        server_groups: EMPTY_SERVER_GROUPS,
        user_data: {
            value: '',
            isBase64Encoded: false,
        },
        user_ssh_key: '',
        disk_encrypted: false,
        root_volume: window.cgrConfig.VOLUME_LIMITS.min,
        imageOrVolume: 'image',
    });
    useValidateImageUrlParam(defaultImage, setServer);

    const cloudIp = useCreateResourceCloudIps();
    const { createServer, messages } = useCreateServer();
    const user = useCurrentUser();
    const account = useCurrentAccount();

    const { resources: all_server_groups  } = useResourcesById<BbServerGroup>('server_group');

    const { server_groups: selected_groups } = server;
    useEffect(() => {
        const default_group: ?string = Object.keys(all_server_groups).find(k => all_server_groups[k].default)
        if (selected_groups === EMPTY_SERVER_GROUPS && default_group) {
            setServer((v) => ({ ...v, server_groups: [default_group] }))
        }
    }, [all_server_groups, selected_groups, setServer]);

    useValidateDefaultTypeHandleParam(defaultTypeHandle, setServer);
    useValidateDefaultTypeIdParam(defaultTypeId, setServer);

    const name = useEditorDirect(
        server.name,
        (name: string) => setServer({...server, name}),
        null
    );
    const { image, selectedImage, volume, selectedVolume, hasDetachedVolume, } = useImageOrVolumeSelect(server, setServer, errors.get('volume'));

    const server_type = useEditorDirect(
        server.server_type,
        (server_type: ?BbServerType) => setServer((prev: ServerCreateParams) => ({ ...prev, server_type})),
        null
    );
    const server_groups = useEditorDirect(
        server.server_groups,
        (server_groups: $ReadOnlyArray<string>) => setServer((prev: ServerCreateParams) => ({ ...prev, server_groups})),
        null
    );
    const zone = useEditorModal<SelectedZone, null, SelectedZone, BbServer>(
        null,
        () => server.zone || 'auto',
        null,
        (zone: SelectedZone) => setServer({...server, zone: zone === 'auto' ? null : zone}),
        match.path,
        'zone'
    )
    const user_data = useEditorModal<UserDataEdit, ?string, UserDataEdit, BbServer>(
        null,
        () => server.user_data,
        (v: UserDataEdit) => {
            let [err, ] = validateUserData(v);
            if (err) return [err, null];
            return [null, v];
        },
        (user_data: UserDataEdit) => setServer({...server, user_data}),
        match.path,
        'user_data'
    );
    const ssh_key = useEditorModal<string, null, string, BbServer>(
        null,
        () => server.user_ssh_key,
        null,
        (user_ssh_key: string) => setServer({...server, user_ssh_key}),
        match.path,
        'user_ssh_key'
    );
    const disk_encrypted = useEditorModal<boolean, null, boolean, BbServer>(
        null,
        () => server.disk_encrypted,
        null,
        (disk_encrypted: boolean) => setServer({...server, disk_encrypted}),
        match.path,
        'disk_encrypted'
    );
    const root_volume = useEditorDirect<string | Gigabytes, ?string>(
        server.root_volume,
        (root_volume: string | Gigabytes) => setServer({ ...server, root_volume }),
        errors.get('root_volume')
    );

    const needsToAddSshKey = user && (!user.ssh_key || user.ssh_key === '') && (selectedImage?.name?.match(/Windows/));
    const availableMemory = account
        ? account.ram_limit - account.ram_used
        : -1; // we can handle 'null' until we get the account.
    const { imageOrVolume } = server;

    function validateAndCreateServer() {
        const nextErrors = new Map();

        if (server.server_type?.storage_type === 'network') {
            const [error, ] = validateVolume(server.root_volume, account, selectedImage, null);
            if (error != null) {
                nextErrors.set('root_volume', error);
            }
        }

        if (imageOrVolume === 'volume' && server.volume == null) {
            nextErrors.set('volume', 'Please select a volume');
        }

        if (nextErrors.size === 0) {
            createServer(((server: any): ValidatedServerCreateParams), cloudIp)
        }

        setErrors(nextErrors);
    }

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.server.listTitle}
            resourceName='Cloud Server'
            match={match}
            dialog={null}
            view={
                <>
                    {imageOrVolume === 'image'
                        ? <ImageSelector
                            editor={image}
                            hasDetachedVolume={hasDetachedVolume}
                            setImageOrVolume={(imageOrVolume: ImageOrVolume) => (setServer((params) => ({...params, imageOrVolume})))}
                        />
                        : <ServerAttachVolumeEdit
                            editor={volume}
                            setImageOrVolume={(imageOrVolume: ImageOrVolume) => (setServer((params) => ({...params, imageOrVolume})))}
                            title='Choose a volume'
                            description='Choose an existing volume to attach as the boot volume'
                        />
                    }
                    <ServerTypeSelector
                        editor={server_type}
                        root_volumeEditor={root_volume}
                        availableMemory={availableMemory}
                        selectedImage={selectedImage}
                        selectedVolume={selectedVolume}
                        defaultShowAll={defaultTypeHandle != null || defaultTypeId != null}
                        imageOrVolume={imageOrVolume}
                    />

                    <ResourceCloudIpEdit
                        edit={cloudIp}
                        resourceId={''}
                        resourceTitle={'Cloud Server'}
                    />

                    <ServerGroupSelector
                        editor={server_groups}
                    />

                    <SrvAdvanced
                        zone={[server.zone, zone]}
                        user_data={[server.user_data, user_data]}
                        user_ssh_key={needsToAddSshKey ? [server.user_ssh_key, ssh_key] : null}
                        disk_encrypted={[server.disk_encrypted, disk_encrypted]}
                        isBlockStorageServer={server.server_type?.storage_type === 'network'}
                    />

                    <NameEditPanel
                        editor={name}
                    >
                        <PanelResourceCreateButtons>
                            <ServerLimitWrapper min_ram={server.image != null ? selectedImage?.min_ram : null} ram={server.server_type ? server.server_type.ram : 0} availableMemory={availableMemory}>
                                <Button
                                    kind='primary'
                                    disabled={!server.image || !server.server_type || availableMemory < server.server_type.ram}
                                    onClick={validateAndCreateServer}
                                    state={messages.status === RC_API_REQUEST ? 'loading' : ''}
                                >
                                    Create Cloud Server
                                </Button>
                            </ServerLimitWrapper>
                        </PanelResourceCreateButtons>
                    </NameEditPanel>
                </>
            }
            editors={[
                { editor: zone, component: ZoneSelector },
                { editor: user_data, component: UserDataEditor },
                { editor: ssh_key, component: UserSshKeyEditor },
                { editor: disk_encrypted, component: DiskEncrypted },
            ]}
        />
    );
};
