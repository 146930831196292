// @flow

import React from "react";
import { ResourceSelector } from '../../../common/ResourceSelector';
import { dateSort, idNameSort, } from '../../../element/Sort';
import { ApplicationSearch, applicationSearchDef } from './ApplicationSearch';
import { formatDateTime, RightArrow} from '../../../element/Styled';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../../element/NoResourceMessages';
import { Link } from "react-router-dom";
import { ResourceChip } from '../../../element/Chip';
import { RC_CACHED } from '../../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, SkeletonListRelated } from '../../../element/Skeleton';

import type { ResourceSelectorTableProps } from '../../../common/ResourceSelector';
import type { BbCollectedApplication } from '../../../../api/type.app';
import type { SortFields } from '../../../element/Sort';

const applicationSortDef: SortFields<BbCollectedApplication> = {
    _default: idNameSort<BbCollectedApplication>(),
    name: idNameSort<BbCollectedApplication>(),
    created_at: dateSort('created_at'),
};

const ApplicationListTable = ({ items, Th: SortTh, search, totalCount, status }: ResourceSelectorTableProps<BbCollectedApplication>) => (
    <Table>
        <thead>
        <Tr>
            <SortTh field='name'>Name</SortTh>
            <SortTh field='created_at'>Created</SortTh>
            <Th actions={true}>&nbsp;</Th>
        </Tr>
        </thead>
        <tbody>
        {items.map(app =>
            <Tr key={app.id}>
                <Td resourceName={true}><ResourceChip resource={app} link={`application/${app.id}/`}/></Td>
                <Td>{formatDateTime(app.created_at)}</Td>
                <Td actions={true}>
                    <Link to={`application/${app.id}/`}><RightArrow /></Link>
                </Td>
            </Tr>
        )}
        {status !== RC_CACHED
            ? <SkeletonListLoadingEntries>
                <Tr>
                    <Td resourceName={true}><SkeletonChip /></Td>
                    <Td><SkeletonListRelated /></Td>
                    <Td actions={true}><SkeletonBar size='sm' /></Td>
                </Tr>
            </SkeletonListLoadingEntries>
            : null
        }
        <NoMatchingResourceTr search={search} colSpan={3} kind='application' />
        <NoResourcesTr status={status} totalCount={totalCount} colSpan={3} context='list' kind='application' />
        </tbody>
    </Table>
);

export const ApplicationList = (): React$Node => (
    <ResourceSelector
        kind='application'
        context='list'
        table={ApplicationListTable}
        searchDef={applicationSearchDef}
        searchComponent={ApplicationSearch}
        sortFields={applicationSortDef}
        addButton={{
            url: 'application/add/',
            resourceName: 'Application',
        }}
    />
);