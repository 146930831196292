// @flow

import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../element/Panel';
import { RadioGroup } from '../element/RadioGroup';
import { TextInput } from '../element/Input';
import { Notice } from '../element/Notice';

import type { EditorModal } from './Editor';
import type { BbServer } from '../../api/type.srv';

export const DEFAULT_RETENTIONS = '10';

export type SnapshotRetentionValues = {
    +automatic: boolean,
    +count: string,
}

type SnapshotRetentionEditProps = {
    +editor: EditorModal<SnapshotRetentionValues, string, BbServer>,
}

export const SnapshotRetentionEdit = ({ editor }: SnapshotRetentionEditProps): React$Node => {
    const { value, setValue } = editor;

    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader title="Snapshot Retention"/>
            <PanelBar>
                <RadioGroup
                    selected={value.automatic}
                    onSelect={(automatic) => setValue({ ...value, automatic })}
                    options={[
                        {
                            label: 'Keep all',
                            value: false,
                        },
                        {
                            label: (
                                <>
                                    Keep only the newest
                                <TextInput
                                    errorText={editor.errors}
                                    value={value.count}
                                    onChange={(count) => setValue({ automatic: true, count })}
                                    size='sm'
                                    className='w-20 mx-2'
                                    autoFocus={true}
                                    disabled={!value.automatic}
                                />
                                automated snapshots
                                </>
                            ),
                            value: true,
                        },
                    ]}
                />
               {value.automatic
                    ? <Notice type='warning' icon='info'>Once the number of automated snapshots exceeds {value.count} – the oldest snapshots will be deleted to maintain the retention count</Notice>
                    : null
               } 
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};