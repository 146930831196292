// @flow

import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';

import type { EditorModal } from '../../../common/Editor';
import type { OrbitEditMetaType, ContainerQuotaEdit } from '../def';
import type { FormErrors } from '../../../common/lib';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';

type OrbitTempUrlKeysEditProps = {
    +editor: EditorModal<OrbitEditMetaType<ContainerQuotaEdit>, FormErrors, OrbitContainerMeta>
};

export const ContainerQuotaForm = ({ editor }: OrbitTempUrlKeysEditProps): React$Node => {
    const { value, setValue, } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Quotas'}
            />
            <PanelBar>
                <Notice icon='pin'>
                    Quotas allow you to set to limits on the total size and/or number of objects in an Orbit Container
                </Notice>

                <div>
                    <TextInput
                        label='Size'
                        value={value.edit.bytes}
                        onChange={(bytes) => setValue({ ...value, edit: { ...value.edit, bytes, } })}
                        errorText={editor.errors.get('bytes')}
                        postText='GB'
                        className='w-32'
                    />
                </div>
                <div>
                    <TextInput
                        label='Count'
                        value={value.edit.count}
                        onChange={(count) => setValue({ ...value, edit: { ...value.edit, count, } })}
                        errorText={editor.errors.get('count')}
                        postText='objects'
                        className='w-40'
                    />
                </div>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};