// @flow
// eslint no-unused-vars: [2, { "argsIgnorePattern": "state" }]

import { SERVER_NAME_EMPTY, ZONE_NOT_AVAILABLE } from '../../api/type.srv';
import { displaySize, formatDate, formatDateTime, mapSourceType, wholeSize, displayBytesSize } from '../../component/element/Styled';
import { getFakeOwnerCollab } from '../../component/hoc/lib';

import type {
    BbAllResourceTypes,
    BbCollaboration,
    BbCollectedImage,
    BbImage,
    BbNestedServerGroup,
    BbServerGroup,
    BbZone,
    BbNestedImage,
    BbServerType,
} from '../../api/type';
import { buildGlobalSearchResources } from './GlobalSearch';
import {buildImageTagSearches} from "./ImageTagSearch";

import type { CloudGuiState } from '../cloudgui';
import type { BbCloudIp, BbNestedCloudIp } from '../../api/type.cip';
import type { BbCollectedServer, BbNestedServer, BbServer } from '../../api/type.srv';
import type { BbLba, BbNestedLba } from '../../api/type.lba';
import type { BbDatabaseServer, BbDatabaseSnapshot, BbNestedDatabaseServer } from '../../api/type.dbs';
import type { BbAccount, BbCollectedAccount, BbNestedAccount } from '../../api/type.acc';
import type { SearchParams, AddToIndexFn, ReduxSearchOptions } from 'redux-search';
import type { BbOrbitContainer } from '../../api/type.orbit';
import type { ResourceState, } from '../resource/type';
import type { GlobalSearchItem } from './GlobalSearch';
import type { BbVolume } from '../../api/type.volume';
import type { ImageTagSearchItem } from "./ImageTagSearch";

type CgrSearchParams<T> = SearchParams<T, CloudGuiState>;

// we have to memoize the combined collected and full lists.
// if we don't, and return {...collected, ...full}, each return
// value resourceSelector is a 'new' object and so the searcher
// infinitely loops on all the 'new' entries.

type SearchableResources<T> = {
    combined: { [id: string]: T },
    deps: $ReadOnlyArray<any>,
}

// these don't have deps, so keep them in a simple object map
const memoContainers = {};
// these have deps and some other fiddly lookups, so
// keep them in a nicer type Map.
const memoResources = new Map<string, SearchableResources<any>>();

const dependencies = new Map<$Keys<ResourceState>, $ReadOnlyArray<$Keys<ResourceState>>>([
    ['server', ['image', 'server_group']],
    ['cloud_ip', ['server', 'server_group', 'load_balancer', 'database_server']],
    ['load_balancer', ['server', 'cloud_ip']],
    ['database_server', ['cloud_ip']],
]);

type ResourceCollections = $PropertyType<$Values<ResourceState>, 'collected'> | $PropertyType<$Values<ResourceState>, 'full'>;

function getDependencies(kind: $Keys<ResourceState>, state: CloudGuiState): $ReadOnlyArray<ResourceCollections> {
    let res: Array<ResourceCollections> = [
        state.Resource[kind].collected,
        state.Resource[kind].full,
    ];

    const otherDeps = dependencies.get(kind);
    if (otherDeps != null) {
        otherDeps.forEach(key => {
            res.push(state.Resource[key].collected);
            res.push(state.Resource[key].full);
        });
    }

    return res;
}

function dependenciesMatch(a: $ReadOnlyArray<ResourceCollections>, b: $ReadOnlyArray<ResourceCollections>) {
    return a.length === b.length && (
        a.reduce((acc, x, i) => acc && x === b[i], true)
    );
}

export const resourceSelector = (resourceName: string, state: CloudGuiState): Object => {
    if (resourceName === 'global') return buildGlobalSearchResources(resourceName, state);
    if (resourceName === 'crImageTags') return buildImageTagSearches(resourceName, state.Orbit);

    // split off the sub-index section
    const [ raw, ] = resourceName.split(':');


    if (raw === 'container') {
        if (!(raw in memoContainers) || memoContainers[raw].containers !== state.Orbit.containers) {
            let combined = {};
            for (const kv of state.Orbit.containers.details.entries()) {
                const [name, details] = kv;
                combined[name] = details;
            }
            memoContainers[raw] = {
                containers: state.Orbit.containers,
                combined
            }
        }

        return memoContainers[raw].combined;
    } else {
        let res: $Keys<ResourceState> = (raw: any);
        const existing = memoResources.get(res);
        const deps = getDependencies(res, state);

        if (deps.length > 1 && (existing == null || !dependenciesMatch(deps, existing.deps))) {
            let combined: any = {
                // $FlowFixMe recursive type expansion
                ...deps[0],
                // $FlowFixMe recursive type expansion
                ...deps[1],
            };

            if (resourceName === 'collaboration') {
                const fakeOwnerCol = getFakeOwnerCollab(state.Resource.account.full[state.Auth.currAccountId]);
                if (fakeOwnerCol) {
                    combined['col-owner'] = fakeOwnerCol;
                }
            }

            memoResources.set(res, {
                deps,
                combined,
            });
        }

        // $FlowFixMe we've definitely set this by now
        return memoResources.get(res).combined;
    }
};

type ResourceIndexerFn<T> = (addToIndex: AddToIndexFn, id: string, resource: T, state: CloudGuiState) => void;


function indexResources<T: BbAllResourceTypes | BbOrbitContainer>({ resources, indexDocument, state, }: CgrSearchParams<T>, indexer: ResourceIndexerFn<T>) {
    Object.keys(resources).forEach((k: string) => {
        indexer(
            (id: string, value: ?string) => {
                if (typeof value !== 'string' && process.env.NODE_ENV === 'development') {
                    throw Error('for ' + id + ' must be string');
                }
                indexDocument(id, value || '')
            },
            k,
            resources[k],
            state
        );
    });
}

function indexCollaboration(addToIndex, id: string, collaboration: BbCollaboration, state: CloudGuiState) {
    addToIndex(id, collaboration.id);
    addToIndex(id, collaboration.email);
    if (collaboration.user) {
        addToIndex(id, collaboration.user.name);
    }
    addToIndex(id, formatDate(collaboration.created_at))
    if (collaboration.started_at) {
        addToIndex(id, formatDate(collaboration.started_at))
    }
    if (collaboration.finished_at) {
        addToIndex(id, formatDate(collaboration.finished_at))
    }
    if (collaboration.user) {
        const { user } = collaboration;
        addToIndex(id, user.id);
        addToIndex(id, user.name);
        addToIndex(id, user.email_address);
        addToIndex(id, user['2fa'] && user['2fa'].enabled ? 'Yes' : 'No');
    }
}

function indexZone(addToIndex, id, zone: BbZone, state: CloudGuiState) {
    addToIndex(id, zone.id);
    addToIndex(id, zone.handle);
    addToIndex(id, zone.name || '');
}

function indexServer(addToIndex, id: string, server: BbNestedServer | BbCollectedServer | BbServer, state: CloudGuiState) {
    addToIndex(id, server.id);
    addToIndex(id, server.name || '');
    if (server.name === '') {
        addToIndex(id, SERVER_NAME_EMPTY);
    }
    addToIndex(id, server.status);
    addToIndex(id, server.hostname);
    addToIndex(id, server.fqdn);
    if (id === server.id) {
        if (server.image) {
            const image = state.Resource.image.full[server.image.id] || state.Resource.server_group.collected[server.image.id] || server.image;
            indexImage(addToIndex, id, image, state);
        }
        if (server.server_type) {
            addToIndex(id, server.server_type.id);
            addToIndex(id, server.server_type.name);
        }
        if (server.zone) {
            indexZone(addToIndex, id, server.zone, state);
        } else {
            addToIndex(id, ZONE_NOT_AVAILABLE);
        }
        (server.server_groups || []).forEach((nested: BbNestedServerGroup) => {
            const group = state.Resource.server_group.full[nested.id] || state.Resource.server_group.collected[nested.id] || nested;
            addToIndex(id, group.name || '');
            addToIndex(id, group.id);
        });
    }
}

function indexCloudIp(addToIndex, id: string, cloudIp: BbCloudIp | BbNestedCloudIp, state: CloudGuiState) {
    addToIndex(id, cloudIp.id);
    addToIndex(id, cloudIp.name || '');
    addToIndex(id, cloudIp.public_ipv4);
    addToIndex(id, cloudIp.public_ipv6);
    addToIndex(id, cloudIp.reverse_dns);
    addToIndex(id, cloudIp.status);
    if (id === cloudIp.id) {
        if (cloudIp.server) {
            const server = state.Resource.server.full[cloudIp.server.id] || state.Resource.server.collected[cloudIp.server.id] || cloudIp.server;
            indexServer(addToIndex, id, server, state);
        }
        if (cloudIp.load_balancer) {
            const load_balancer = state.Resource.load_balancer.full[cloudIp.load_balancer.id] || state.Resource.load_balancer.collected[cloudIp.load_balancer.id] || cloudIp.load_balancer;
            indexLoadBalancer(addToIndex, id, load_balancer, state);
        }
        if (cloudIp.database_server) {
            const database_server = state.Resource.database_server.full[cloudIp.database_server.id] || state.Resource.load_balancer.collected[cloudIp.database_server.id] || cloudIp.database_server;
            indexDatabaseServer(addToIndex, id, database_server, state);
        }
    }
}

function indexLoadBalancer(addToIndex, id: string, lba: BbNestedLba | BbLba, state: CloudGuiState) {
    addToIndex(id, lba.id);
    addToIndex(id, lba.name || '');
    addToIndex(id, lba.status);
    if (lba.policy) {
        addToIndex(id, lba.policy);
    }
    if (id === lba.id) {
        if (lba.nodes) {
            lba.nodes.forEach(s => {
                const server = state.Resource.server.full[s.id] || state.Resource.server.collected[s.id] || s;
                indexServer(addToIndex, id, server, state)
            });
        }
        if (lba.cloud_ips) {
            lba.cloud_ips.forEach(ip => {
                const cloud_ip = state.Resource.cloud_ip.full[ip.id] || state.Resource.cloud_ip.collected[ip.id] || ip;
                indexCloudIp(addToIndex, id, cloud_ip, state)
            });
        }
    }
}

function indexServerGroup(addToIndex, id: string, serverGroup: BbServerGroup | BbNestedServerGroup, state: CloudGuiState) {
    addToIndex(id, serverGroup.id);
    addToIndex(id, serverGroup.name || '');
    addToIndex(id, serverGroup.description || '');
    if (serverGroup.firewall_policy) {
        const { firewall_policy } = serverGroup;
        addToIndex(id, firewall_policy.id);
        addToIndex(id, firewall_policy.name || '');
        if (firewall_policy.description) {
            addToIndex(id, firewall_policy.description);
        }
    }
}

function indexDatabaseServer(addToIndex, id: string, dbs: BbDatabaseServer | BbNestedDatabaseServer, state: CloudGuiState) {
    addToIndex(id, dbs.id);
    addToIndex(id, dbs.name);
    addToIndex(id, dbs.description);
    addToIndex(id, dbs.database_engine);
    addToIndex(id, dbs?.maintenance_weekday?.toString() || '');
    addToIndex(id, dbs.database_version);
    addToIndex(id, dbs.status);
    if (dbs.database_server_type) {
        const { database_server_type} = dbs;
        addToIndex(id, database_server_type.id);
        addToIndex(id, database_server_type.name);
        addToIndex(id, database_server_type.description);
    }
    if (id === dbs.id) {
        if (dbs.zone) {
            indexZone(addToIndex, id, dbs.zone, state);
        }
        if (dbs.cloud_ips) {
            dbs.cloud_ips.forEach(ip => {
                const cloud_ip = state.Resource.cloud_ip.full[ip.id] || state.Resource.cloud_ip.collected[ip.id] || ip;
                indexCloudIp(addToIndex, id, cloud_ip, state)
            });
        }
    }
}

function indexDatabaseSnapshot(addToIndex, id: string, dbi: BbDatabaseSnapshot, state: CloudGuiState) {
    addToIndex(id, dbi.id);
    addToIndex(id, dbi.name);
    addToIndex(id, dbi.description);
    addToIndex(id, dbi.database_engine);
    addToIndex(id, dbi.database_version);
    addToIndex(id, dbi.status);
    addToIndex(id, formatDateTime(dbi.created_at));
    addToIndex(id, displaySize(dbi.size));
}

function indexImage(addToIndex, id: string, image: BbImage | BbCollectedImage | BbNestedImage, state: CloudGuiState) {
    addToIndex(id, image.id);
    addToIndex(id, image.name);
    addToIndex(id, image.description);
    addToIndex(id, image.public ? 'public' : 'private');
    addToIndex(id, image.owner);
    addToIndex(id, image.status);
    if (image.disk_size) addToIndex(id, wholeSize(image.disk_size));
    if (image.source_type) addToIndex(id, mapSourceType(image.source_type, image.source_trigger));
}

function indexAccount(addToIndex, id: string, acc: BbAccount | BbNestedAccount | BbCollectedAccount, state: CloudGuiState) {
    addToIndex(id, acc.id);
    addToIndex(id, acc.name);
    addToIndex(id, acc.status);
}

function indexOrbitContainer(addToIndex, id: string, details: BbOrbitContainer, state: CloudGuiState) {
    addToIndex(id, details.name);
    addToIndex(id, displayBytesSize(details.bytes));
    addToIndex(id, '' + details.bytes);
    addToIndex(id, '' + details.count);
    addToIndex(id, '' + formatDateTime(details.last_modified));
}

function indexServerType(addToIndex, id: string, details: BbServerType, state: CloudGuiState) {
    addToIndex(id, details.id);
    addToIndex(id, details.name);
    if (details.handle != null) addToIndex(id, details.handle);
}

function indexVolume(addToIndex, id: string, details: BbVolume, state: CloudGuiState) {
    addToIndex(id, details.id);
    if (details.name) addToIndex(id, details.name);
    if (details.description) addToIndex(id, details.description);
    addToIndex(id, details.status);
}

const resourceIndexes = {
    api_client: ['id', 'name', 'description', 'permissions_group'],
    'cloud_ip:list': (p: CgrSearchParams<BbCloudIp>): void => indexResources(p, indexCloudIp),
    'cloud_ip:selector': (p: CgrSearchParams<BbCloudIp>): void => indexResources(p, indexCloudIp),
    load_balancer: (p: CgrSearchParams<BbLba>): void => indexResources(p, indexLoadBalancer),
    'server:list': (p: CgrSearchParams<BbNestedServer>): void => indexResources(p, indexServer),
    'server:selector': (p: CgrSearchParams<BbNestedServer>): void => indexResources(p, indexServer),
    'server_group:list': (p: CgrSearchParams<BbServerGroup>): void => indexResources(p, indexServerGroup),
    'server_group:selector': (p: CgrSearchParams<BbServerGroup>): void => indexResources(p, indexServerGroup),
    'image:list': (p: CgrSearchParams<BbImage | BbCollectedImage>): void => indexResources(p, indexImage),
    'image:selector': (p: CgrSearchParams<BbImage | BbCollectedImage>): void => indexResources(p, indexImage),
    database_server: (p: CgrSearchParams<BbDatabaseServer>): void => indexResources(p, indexDatabaseServer),
    account: (p: CgrSearchParams<BbAccount | BbNestedAccount>): void => indexResources(p, indexAccount),
    application: ['id', 'name', 'status',],
    collaboration: (p: CgrSearchParams<BbCollaboration>): void => indexResources(p, indexCollaboration),
    'database_snapshot': (p: CgrSearchParams<BbDatabaseSnapshot>): void => indexResources(p, indexDatabaseSnapshot),
    container: (p: CgrSearchParams<BbOrbitContainer>): void => indexResources(p, indexOrbitContainer),
    'server_type': (p: CgrSearchParams<BbServerType>): void => indexResources(p, indexServerType),
    'volume': (p: CgrSearchParams<BbVolume>): void => indexResources(p, indexVolume),
    'global': ({ resources, indexDocument, } : SearchParams<GlobalSearchItem, CloudGuiState>) => {
        Object.keys(resources).forEach(id => {
            const res = resources[id];
            res.values.forEach(val => indexDocument(id, val));
        });
    },
    'crImageTags': ({ resources, indexDocument, } : SearchParams<ImageTagSearchItem, CloudGuiState>) => {
        Object.keys(resources).forEach(id => {
            const res = resources[id];
            res.values.forEach(val => indexDocument(id, val));
        });
    }
};

export const reduxSearchConfig: ReduxSearchOptions<CloudGuiState, any, any> = {
    resourceIndexes,
    resourceSelector
};

export type SearchIndexes = $Keys<typeof resourceIndexes>;