import { createLogic } from 'redux-logic';

import type { CloudGuiState } from '../cloudgui';
import type { Store, Dispatch } from 'redux';
import type { UiSetPageSize } from './type';

export const KEY_PAGE_SIZE = 'bb:page_size';

export const StoreUiStateLogic: ReduxLogic = createLogic({
    type: [ 'UI_SET_PAGE_SIZE' ],
    process: async (deps: { getState: () => CloudGuiState, action: UiSetPageSize }, dispatch: Dispatch<any>, done: () => void) => {
        if (localStorage) {
            localStorage.setItem(KEY_PAGE_SIZE, deps.action.payload.pageSize);
        }

        done();
    }
})

export function loadUiDefaults(store: Store<CloudGuiState, any, any>) {
    if (localStorage) {
        let size: string = localStorage.getItem(KEY_PAGE_SIZE);
        if (size != null) {
            store.dispatch({
                type: 'UI_SET_PAGE_SIZE',
                payload: {
                    pageSize: parseInt(size)
                }
            });
        }
    }
}