// @flow

import type { Feature } from '../../api/feature_labels';
import type { BbMetadata } from '../../api/type.metadata';

export let devFeaturesDisable: boolean = false;

export function toggleDevFeaturesOverride(): void {
    if (process.env.NODE_ENV === 'development') {
        devFeaturesDisable = !devFeaturesDisable;
    }
}

// labels have string values
// so the features, being booleans, all use "string true"
// as their value.
const STR_TRUE = 'true';

export function useFeatureLabel(feature: Feature, resource: ?{ metadata?: ?BbMetadata, ... }): boolean {
    if (process.env.NODE_ENV === 'development' && devFeaturesDisable) return false;

    return (resource?.metadata?.labels?.[feature] === STR_TRUE);
}