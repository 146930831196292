// @flow
import { GB_TO_MB } from '../../../element/Styled';

import type { BbServerParams, UserDataEdit } from '../../../../api/type.srv';
import type { EditGigabytes } from '../../../hoc/Volumes';
import type { BbCollectedImage } from '../../../../api/type';
import type { BbAccount } from '../../../../api/type.acc';
import type { Megabytes } from '../../../../api/type.units';

const MAX_USER_DATA_SIZE = (16 * 1024) * 3 / 4;

export function validateUserData(data: UserDataEdit): [?string, ?$Shape<BbServerParams>] {
    if (data.value.length > MAX_USER_DATA_SIZE) return ['Data size limit exceeded', null];
    return [
        null,
        { user_data: data.isBase64Encoded ? data.value : btoa(data.value) }
    ];
}

export function validateVolume(root_volume: EditGigabytes, account: ?BbAccount, image: ?BbCollectedImage, existingSize: ?Megabytes): [?string, ?number] {
    const parsed = parseFloat(root_volume);
    const availableBlockStorage = account
        ? ((account.block_storage_limit - account.block_storage_used + (existingSize || 0)) / GB_TO_MB)
        : window.cgrConfig.VOLUME_LIMITS.max;
    const maxVolumeSize = Math.min(availableBlockStorage, window.cgrConfig.VOLUME_LIMITS.max);

    if (image && image.virtual_size > (parsed * GB_TO_MB)) {
        return [ `The selected image needs a volume of at least ${image.virtual_size / GB_TO_MB}GB`, null];
    }

    if (existingSize && existingSize > (parsed * GB_TO_MB)) {
        return [ `Volumes can only be increased in size; volume must be at least ${existingSize / GB_TO_MB}GB`, null];
    }

    if (existingSize && existingSize === (parsed * GB_TO_MB)) {
        return [ `The volume is already ${existingSize / GB_TO_MB}GB`, null];
    }

    if (isNaN(parsed) || parsed > maxVolumeSize || parsed < window.cgrConfig.VOLUME_LIMITS.min) {
        return [ `Please enter a valid size between ${existingSize ? (existingSize / GB_TO_MB) : window.cgrConfig.VOLUME_LIMITS.min}GB and ${maxVolumeSize}GB`, null ];
    }

    return [null, parsed];
}