// @flow

import { Link } from 'react-router-dom';
import { Button, CopyButton, } from '../Button';
import { Tooltip } from '../Tooltip';
import { Pill } from '../Pill';
import { SkeletonBar, isShowDetailsResourceStatus } from '../Skeleton';
import type { ButtonProps } from '../Button';
import type { ResourceCacheStatus } from '../../../state/resource/type';

type SettingsProps = {
    children: React$Node,
}

type OldButton = {
    +caption: string,
    +onClick: () => void,
};

export type SettingsItemProps = {
    +name: string,
    +summary?: ?React$Node,
    +route?: ?string,
    +notice?: ?React$Node,
    +hint?: React$Node,
    +button?: OldButton,
    +settingsBtn?: ?ButtonProps,
    +copyValue?: ?string,
    routeDisabledTooltip?: ?React$Node,
    +skeleton?: ?React$Node,
}

export const Settings = ({ children }: SettingsProps): React$Node => {
    return (
        <div className='c-settings'>{children}</div>
    );
};

export const SettingsItem = ({ name, summary, route, button: b, notice, hint, routeDisabledTooltip, copyValue, settingsBtn: sb, }: SettingsItemProps): React$Node => {
    let settingsBtn: ?ButtonProps = sb;
    let button: ?OldButton = b;
    if (!sb && b) {
        settingsBtn = {
            children: b.caption,
            onClick: b.onClick,
        }
    }

    return (
        <div id={'settings-item-' + name.toLowerCase().replace(/ /g, '-')}
            className='c-settings-item'>
            <div className='c-settings-item__name'>
                {name}
                {hint
                    ? <Tooltip overlay={hint}><Pill>?</Pill></Tooltip>
                    : null
                }
            </div>
            <div className='c-settings-item__value'>{summary}</div>
            <div className='c-settings-item__edit-link'>
                {settingsBtn
                    ? <> {/* keep this */}
                        {route && routeDisabledTooltip == null
                            ? <Link to={route}>
                                <Button
                                    size='sm'
                                    kind='tertiary'
                                    {...settingsBtn}
                                >
                                    {settingsBtn.children || 'Edit'}
                                </Button>
                            </Link>
                            : null}
                        {route && routeDisabledTooltip != null
                            ? <Tooltip placement='left' overlay={routeDisabledTooltip}>
                                <Button
                                    size='sm'
                                    kind='tertiary'
                                    {...settingsBtn}
                                    disabled={true}
                                >
                                    {settingsBtn.children || 'Edit'}
                                </Button>

                            </Tooltip>
                            : null}
                        {route == null && settingsBtn
                            ? <Tooltip placement='left' overlay={routeDisabledTooltip}>
                                <Button
                                    size='sm'
                                    kind='tertiary'
                                    disabled={routeDisabledTooltip != null}
                                    {...settingsBtn}
                                >
                                    {settingsBtn.children || 'Edit'}
                                </Button>
                            </Tooltip>
                            : null
                        }
                    </>
                    : <> {/* eventually delete this */}
                        {route && routeDisabledTooltip == null
                            ? <Link to={route}>
                                <Button size='sm' kind='tertiary'>Edit</Button>
                            </Link>
                            : null}

                        {route && routeDisabledTooltip != null
                            ? <Tooltip placement='left' overlay={routeDisabledTooltip}>
                                <Button size='sm' kind='tertiary' disabled={true}>Edit</Button>
                            </Tooltip>
                            : null}
                        {button
                            ? <Button size='sm' kind='tertiary' onClick={button.onClick}>{button.caption}</Button>
                            : null
                        }
                    </>
                }
                {copyValue
                    ? <CopyButton value={copyValue} />
                    : null
                }
            </div>
            {notice
                ? <div className='c-settings-item__notice'>
                    {notice}
                </div>
                : null
            }
        </div>
    );
};

export const SettingsTable = ({ children }: { children: React$Node }): React$Node => {
    return (
        <Settings>
            <table className={'c-settings-table'}>
                {children}
            </table>
        </Settings>
    );
};

type SettingsArrayProps = {
    +cacheStatus?: ResourceCacheStatus,
    +settings: $ReadOnlyArray<SettingsItemProps>,
}

export const SettingsArray = ({ settings, cacheStatus }: SettingsArrayProps): React$Node => {
    const show = (cacheStatus == null || isShowDetailsResourceStatus(cacheStatus));

    return settings.map(({ summary, route, settingsBtn, skeleton, ...s }) =>
        <SettingsItem
            key={s.name}
            {...s}
            route={show ? route : null}
            settingsBtn={show ? settingsBtn : null}
            summary={show
                ? summary
                : (skeleton || <SkeletonBar />)
            }
        />
    );
}

export function getLockSetting(loaded: boolean, locked: ?boolean, unlock: (...any: any) => any, lock: (...any: any) => any): SettingsItemProps {
    return {
        name: 'Lock',
        summary: loaded
            ? (locked ? 'Locked' : 'Unlocked')
            : null,
        skeleton: <SkeletonBar size='xs'/>,
        hint: 'Resource locking helps prevent accidental deletion by requiring locked resources to be unlocked before they can be deleted',
        settingsBtn: loaded ? {
            children: (locked ? 'Unlock' : 'Lock'),
            onClick: (locked ? () => unlock() : () => lock()),
            kind: 'secondary',
        } : null
    };
}