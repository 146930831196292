// @flow
import { useMemo } from 'react';
import { ResourceLink } from './ResourceLink';
import { actionMessages } from './lib/eventActionMessages';
import { RelatedPopover } from './RelatedPopover';

import type { BbEvent, BbNestedUser } from '../../api/type';
import type { BbApiClient } from '../../api/type.cli';

export type EventActionerType = 'user_app' | 'api_client' | 'background';

export const eventActionerKey = (event: BbEvent): EventActionerType => {
    return event.user ? 'user_app' : (event.client ? 'api_client' : 'background');
}

export const eventActioner = (event: BbEvent): BbNestedUser | BbApiClient | 'background' => {
    return event.user || event.client || 'background';
}

export const eventWillShow = (event: BbEvent): boolean => {
    return actionMessages[event.resource.resource_type]?.[eventActionerKey(event)]?.[event.action] != null;
}

export const useEventMessage = (event: BbEvent): React$Node => {
    return useMemo(() => {
        const actioner = eventActionerKey(event);
        const message = actionMessages[event.resource.resource_type]?.[actioner]?.[event.action];

        if (message == null) return null;

        const affected: string = [...new Set(event.affects.filter(x => x.id !== event.resource.id).map(x => x.id))].join(', ')

        let out: Array<React$Node> = [
            message
                .replace('%{user_name}', event.user?.name || '')
                .replace('%{resource_link}', event.resource.id)
                .replace('%{resource_name}', event.resource.name || '')
                .replace('%{affected_resources}', affected)
        ];

        let affects = [].concat(event.resource, event.account, event.affects);
        let idx = 0;

        affects.forEach(res => {
            out = out.reduce<Array<React$Node>, Array<React$Node>>((acc, msg) => {
                if (typeof msg === 'string') {
                    return [].concat(
                        acc,
                        msg
                            .split(res.id)
                            .reduce((a, s, i) => [].concat(a, s,
                                <RelatedPopover affected={[res]} key={idx++}>
                                    <ResourceLink id={res.id} className='c-event__resource-link' />
                                </RelatedPopover>
                            ), [])
                            .slice(0, -1) // drop the trailing link, making this an "array join"...
                    );
                } else {
                    return [].concat(acc, msg);
                }
            }, []);
        });

        return out;
    }, [event]);

};