// @flow

import { RC_INITIAL } from './type';
import type { ResourceStateAction, ResourceState, ResourceCollection, ResourceCacheStatus, ResourceForceRefresh } from './type';
import type {
    BbFullResourceTypes,
    BbCollaboration,
    BbEvent,
    BbImage,
    BbInterface,
    BbServerGroup,
    BbServerType,
    BbTest,
    BbUser,
    BbZone,
    BbCollectedResourceTypes
} from '../../api/type';
import type { AuthSetSelectedAccount } from '../auth/type';
import type { StoreClearAction } from '../type';
import type { BbCollectedDatabaseServer, BbDatabaseServer, BbDatabaseSnapshot, BbDatabaseType } from '../../api/type.dbs';
import type { BbCollectedLba, BbLba } from '../../api/type.lba';
import type { BbCloudIp } from '../../api/type.cip';
import type { BbFirewallPolicy, BbFirewallRule } from '../../api/type.fwp';
import type { BbApplication, BbCollectedApplication } from '../../api/type.app';
import type { BbAccount, BbCollectedAccount } from '../../api/type.acc';
import type { BbApiClient } from '../../api/type.cli';
import type { BbCollectedServer, BbServer } from '../../api/type.srv';
import type { BbVolume } from '../../api/type.volume';

const emptyEntry = <F, C>(): ResourceCollection<F, C> => ({
    collected: {},
    full: {},
    cacheStatus: new Map<string, ResourceCacheStatus>(),
    fetchIds: new Map<string, number>(),
    fetched: RC_INITIAL,
    generation: 0,
    notFound: new Set<string>(),
});

const emptyState = ({
    application: emptyEntry<BbApplication, BbCollectedApplication>(),
    account: emptyEntry<BbAccount, BbCollectedAccount>(),
    api_client: emptyEntry<BbApiClient, BbApiClient>(),
    cloud_ip: emptyEntry<BbCloudIp, BbCloudIp>(),
    database_server: emptyEntry<BbDatabaseServer, BbCollectedDatabaseServer>(),
    database_snapshot: emptyEntry<BbDatabaseSnapshot, BbDatabaseSnapshot>(),
    database_type: emptyEntry<BbDatabaseType, BbDatabaseType>(),
    event: emptyEntry<BbEvent, BbEvent>(),
    firewall_policy: emptyEntry<BbFirewallPolicy, BbFirewallPolicy>(),
    firewall_rule: emptyEntry<BbFirewallRule, BbFirewallRule>(),
    image: emptyEntry<BbImage, BbImage>(),
    load_balancer: emptyEntry<BbLba, BbCollectedLba>(),
    interface: emptyEntry<BbInterface, BbInterface>(),
    test: emptyEntry<BbTest, BbTest>(),
    test_unmapped: emptyEntry<BbTest, BbTest>(),
    server: emptyEntry<BbServer, BbCollectedServer>(),
    server_group: emptyEntry<BbServerGroup, BbServerGroup>(),
    server_type: emptyEntry<BbServerType, BbServerType>(),
    user: emptyEntry<BbUser, BbUser>(),
    collaboration: emptyEntry<BbCollaboration, BbCollaboration>(),
    zone: emptyEntry<BbZone, BbZone>(),
    volume: emptyEntry<BbVolume, BbVolume>(),
}: ResourceState);

function reduceIdToObjectKey<T: { id: string, ... }>(acc: Object, val: T) {
    acc[val.id] = val;
    return acc;
}

function process<F: BbFullResourceTypes, C: BbCollectedResourceTypes>(b: ResourceCollection<F, C>, action: ResourceStateAction<F, C>): ResourceCollection<F, C> {
    switch(action.type) {
    case 'RESOURCE_COLLECTED_STATUS':
        return {
            ...b,
            fetched: action.payload.fetched,
        };
    case 'RESOURCE_ADD_COLLECTED':
        return {
            ...b,
            generation: b.generation + (action.payload.resources.length > 0 ? 1 : 0),
            collected: {
                ...b.collected,
                ...(action.payload.resources.reduce(reduceIdToObjectKey, {})),
            }
        };
    case 'RESOURCE_SET_COLLECTED':
        return {
            ...b,
            generation: b.generation + (action.payload.resources.length > 0 ? 1 : 0),
            collected: action.payload.resources.reduce(reduceIdToObjectKey, {}),
        };
    case 'RESOURCE_ADD_FULL':
        const { full, collected } = action.payload;
        return {
            ...b,
            generation: b.generation + 1,
            collected: {
                ...b.collected,
                ...(collected ? { [collected.id]: collected } : null ),
            },
            full: {
                ...b.full,
                [full.id]: full,
            }
        };
    case 'RESOURCE_DELETE_RESOURCE':
        const { id: idToDelete } = action.payload;

        const nextItems = {
            collected: { ...b.collected },
            full: { ...b.full },
        };
        delete nextItems.collected[idToDelete];
        delete nextItems.full[idToDelete];

        return {
            ...b,
            generation: (b.generation) + 1,
            ...nextItems,
        };
    case 'RESOURCE_SET_NOT_FOUND':
        const { id: idToSet } = action.payload;
        return {
            ...b,
            notFound: new Set([ ...b.notFound, idToSet ]),
        }
    case 'RESOURCE_CACHE_STATUS':
        const { id: idToStatus, status, fetchId, } = action.payload;
        return {
            ...b,
            cacheStatus: new Map([...b.cacheStatus, [idToStatus, status],]),
            fetchIds: fetchId ? new Map([...b.fetchIds, [idToStatus, fetchId]]) : b.fetchIds,
        }
    default:
        void (action: empty);
        break;
    }

    return b;
}

export const ResourceReducer = <F: BbFullResourceTypes, C: BbCollectedResourceTypes>(
    state: ResourceState = emptyState,
    action: ResourceStateAction<F, C> | AuthSetSelectedAccount | StoreClearAction | ResourceForceRefresh
): ResourceState => {

    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'AUTH_SELECT_ACCOUNT':
    case 'RESOURCE_FORCE_REFRESH': // same action as AUTH_SELECT_ACCOUNT, but only in this reducer.
        return {
            ...emptyState,
            account: state.account,
        };
    case 'RESOURCE_COLLECTED_STATUS':
    case 'RESOURCE_CACHE_STATUS':
    case 'RESOURCE_ADD_COLLECTED':
    case 'RESOURCE_SET_COLLECTED':
    case 'RESOURCE_ADD_FULL':
    case 'RESOURCE_DELETE_RESOURCE':
    case 'RESOURCE_SET_NOT_FOUND':
        return {
            ...state,
            // flow bug #8177 affects this, and the need to do a runtime type
            // check on the ResourceCollection and action.payload.kind.
            // If ResourceStateActions are made where kind != the actual kind,
            // this will cause a problem.
            // $FlowFixMe
            [action.payload.kind]: process(state[action.payload.kind], (action: ResourceStateAction<any, any>)),
        };
    default:
        void (action: empty);
        break;
    }

    return state;
};