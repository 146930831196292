// @flow

import { useCallback } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ValueSelect, } from '../../../element/ValueSelect';
import { useGroupedItemState } from '../../../element/lib/groupedItemState';
import { Avatar } from '../../../element/Avatar';
import { Panel, PanelBar, PanelHeader } from '../../../element/Panel';
import { Card } from '../../../element/Card';
import { CardGroup } from '../../../element/CardGroup';
import { useDatabaseEngineSelector, useDatabaseEngineTypes } from '../../../hoc/Database';
import { dbiSearchDef, dbiSortFields } from '../def';
import { TextOnlySearchEditor } from '../../../common/TextOnlySearch';
import { DbsSnapshotTable } from '../DbiList';
import { Notice } from '../../../element/Notice';
import { ResourceSelector } from '../../../common/ResourceSelector';
import { useEditorDirect } from '../../../common/Editor';
import { isAllocated } from '../../../../api/lib';
import { RC_CACHED } from '../../../../state/resource/type';
import { SkeletonCardSelector } from '../../../element/Skeleton';

import type { DbsEngineVersion, BbDatabaseSnapshot } from '../../../../api/type.dbs';
import type { DbsCreateParams } from '../type';

export type DatabaseEngineSelectProps = {
    error: ?string,
    selected: ?DbsEngineVersion,
    snapshot: ?string,
    onSetSourceType: (sourceType: $PropertyType<DbsCreateParams, 'source_type'>) => void,
    onSelect: (engine: ?DbsEngineVersion, snapshot: ?string) => void,
}

export const DatabaseEngineSelect = ({ selected, snapshot, onSelect, onSetSourceType, error, }: DatabaseEngineSelectProps): React$Node => {
    const onGroupedItemSelect = useCallback((engine) => onSelect(engine, null), [onSelect]);
    const { engineTypes, cacheStatus } = useDatabaseEngineTypes();

    const {
        selectGroup: selectEngine,
        selectItem: selectVersion,
        internalSelected
    } = useGroupedItemState<DbsEngineVersion>(selected, engineTypes, onGroupedItemSelect);

    useDatabaseEngineSelector(selected, snapshot, selectVersion);

    const editor = useEditorDirect(
        snapshot == null ? [] : [snapshot],
        (next: $ReadOnlyArray<string>) => next.length
            ? onSelect(null, next[0])
            : onSelect(null, null),
        null
    );

    return (
        <Panel>
            <PanelHeader
                title={'Choose a database engine'}
                description={'What should be the starting point for this new Cloud SQL instance?'}
            />
            <Tabs defaultIndex={snapshot != null ? 1 : 0} onSelect={(idx) => onSetSourceType(idx === 1 ? 'snapshot' : 'engine')}>
                <TabList>
                    <Tab>New instance</Tab>
                    <Tab>Restore from a Snapshot</Tab>
                </TabList>
                <TabPanel>
                    {cacheStatus !== RC_CACHED
                        ? <SkeletonCardSelector count={2} />
                        : <PanelBar>
                            {error ? <Notice type='error'>{error}</Notice> : null}

                            <CardGroup>
                                {Object.keys(engineTypes).map((engine: string) => {
                                    const details = engineTypes[engine];

                                    return (
                                        <Card
                                            selectable={true}
                                            key={engine}
                                            selected={selected != null && internalSelected.group === engine}
                                            onClick={() => selectEngine(engine)}
                                        >
                                            <div className='c-distro-card'>
                                                <h4 className='c-distro-card__title'>
                                                    <Avatar engine={engine} className='c-distro-card__avatar'/>{details.label}
                                                </h4>
                                                <ValueSelect
                                                    className='c-distro-card__version-select'
                                                    size='xs'
                                                    selected={internalSelected.selectedForGroup[engine] || details.default}
                                                    options={details.items}
                                                    onSelect={(image) => selectVersion(image, engine)}
                                                />
                                            </div>
                                        </Card>
                                    );
                                })}
                            </CardGroup>
                            {selected?.isDeprecated
                                ? <Notice type='warning' icon='warning-fill'>
                                    <b>Please note</b> - the database version you have selected is no
                                    longer supported by the database vendor or Brightbox
                                </Notice>
                                : null
                            }
                        </PanelBar>
                    }
                </TabPanel>
                <TabPanel>
                    <ResourceSelector
                        selector={{
                            editor,
                            selectSingle: true,
                        }}
                        kind='database_snapshot'
                        sortFields={dbiSortFields}
                        searchDef={dbiSearchDef}
                        searchComponent={TextOnlySearchEditor}
                        table={DbsSnapshotTable}
                        itemsFilter={(dbi: BbDatabaseSnapshot) => isAllocated(dbi.status)}
                    />
                </TabPanel>
            </Tabs>
        </Panel>
    );
};