// @flow
import { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { Button, } from '../../element/Button';
import { ServerGroupServerSelector } from './ServerGroupServerSelector';
import { ErrorList } from '../../common/ErrorList';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useEditorDirect } from '../../common/Editor';
import { NameDescriptionEditPanel } from '../../element/NameDescriptionEditPanel';
import { RC_API_REQUEST, RC_SUCCESS } from '../../../state/resource/type';
import { useMessages } from '../../hoc/Messages';
import { CREATE_GRP_MESSAGES_ID } from '../../../state/resource/DependentResourceLogic';
import { historyNavigateOnCreate } from '../../../lib/history';
import { useCurrentAccount } from '../../hoc/lib';
import { PanelResourceCreateButtons } from '../../element/Panel';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from "react-router-dom";
import type { BbServerGroupCreateParams, } from '../../../api/type';
import type { Dispatch } from "redux";
import type { ResourceCreateServerGroupAction } from '../../../state/resource/type';

const useCreateServerGroup = () => {
    const dispatch = useDispatch<Dispatch<ResourceCreateServerGroupAction>>();
    const messages = useMessages(CREATE_GRP_MESSAGES_ID);
    const account = useCurrentAccount();
    const { status, resource } = messages;
    const [state, setState] = useState('pre');

    useEffect(() => {
        setState('edit');
        messages.clear();
    // we only do this on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state !== 'pre' && status === RC_SUCCESS && account && resource) {
            historyNavigateOnCreate(account, resource.id, 'server_group');
        }
    }, [status, resource, account, state]);

    return {
        createServerGroup: (params: BbServerGroupCreateParams) => {
            dispatch({ type: 'RESOURCE_CREATE_SERVER_GROUP', payload: { params, } });
        },
        messages,
    }
}

export const ServerGroupCreate = ({ match }: { match: Match }): React$Node => {
    const { createServerGroup, messages } = useCreateServerGroup();
    const [createParams, setCreateParams] = useState<BbServerGroupCreateParams>({
        name: '',
        description: '',
        server_ids: [],
    });
    const [ errors, setErrors ] = useState<Array<string>>([]);
    const nodes = useEditorDirect(
        createParams.server_ids,
        (server_ids: $ReadOnlyArray<string>) => setCreateParams({...createParams, server_ids}),
        null
    );
    const name = useEditorDirect(
        { name: createParams.name, description: createParams.description },
        (next: { name: string, description: string }) => setCreateParams({...createParams, ...next }),
        null
    );


    const validateAndCreate = () => {
        let errors: Array<string> = [];

        if (createParams.name === '') {
            errors.push('Please enter a group name');
        }

        setErrors(errors);
        if (errors.length === 0) {
            createServerGroup(createParams);
        }
    };

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.server_group.listTitle}
            resourceName='Server Group'
            match={match}
            dialog={null}
            view={
                <>
                    <ServerGroupServerSelector editor={nodes} />
                    <ErrorList errors={[].concat(errors, messages.messages || [])}/>
                    <NameDescriptionEditPanel resourceTitle="Server Group" nameRequired={true} editor={name}>
                        <PanelResourceCreateButtons>
                            <Button
                                kind="primary"
                                onClick={validateAndCreate}
                                state={messages.status === RC_API_REQUEST ? 'loading' : ''}
                            >Create Server Group</Button>
                        </PanelResourceCreateButtons>
                    </NameDescriptionEditPanel>
                </>}
            editors={[]}
        />
    );
};

