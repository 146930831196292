// @flow

import { createLogic } from 'redux-logic';
import { RC_INITIAL, RC_ERROR } from '../resource/type';
import { accountsUrl } from '../../api/url';
import to from 'await-to-js';
import { request } from '../../api/rest';

import type { ReduxLogic } from 'redux-logic';
import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type { GlobalSearchAction, GlobalSearchFetchAction } from './type';

export const GlobalSearchFetchFullAccountLogic: ReduxLogic = createLogic({
    type: 'GLOBAL_SEARCH_FETCH',
    async process(
        deps: { getState: () => CloudGuiState, action: GlobalSearchFetchAction },
        dispatch: Dispatch<GlobalSearchAction>,
        done: () => void
    ) {
        const state = deps.getState();

        if (state.GlobalSearch.cacheStatus === RC_INITIAL && state.Auth.currAccountId) {
            let url = [accountsUrl, state.Auth.currAccountId].join('/');

            const [err, res] = await to(request({ url, accountId: false, }));
            if (err) {
                dispatch({
                    type: 'GLOBAL_SEARCH_CACHE_STATUS',
                    payload: {
                        status: RC_ERROR,
                    }
                });
            } else if (res) {
                dispatch({
                    type: 'GLOBAL_SEARCH_ACCOUNT',
                    payload: {
                        account: res.data,
                    }
                });
            }
        }

        done();
    }
});