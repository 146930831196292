// @flow

import { dateSort, numberSort, stringSort } from '../../element/Sort';
import { textOnlySearch } from '../../common/TextOnlySearch';
import { orbitContainerMetaToHeaders, cloneContainerMeta } from '../../../api/adapter.orbit';
import { genTempUrlKey } from '../../hoc/orbit/lib';
import { store } from '../../../state/cloudgui';
import { BYTES_TO_GB } from '../../element/Styled';
import { useDeleteDialog } from '../../common/CommonDialogs';
import { useState } from 'react';
import { useEditorDirect } from '../../common/Editor';
import { useDispatch, useSelector } from 'react-redux';
import { useTriggeredMessages } from '../../hoc/Messages';
import { amendSelection } from '../../common/ResourceSelector';

import type { FormErrors } from '../../common/lib';
import type { BbOrbitContainer, OrbitContainerMeta, OrbitContainerHeaders, ContainerAccess, OrbitAccountMeta, OrbitObject } from '../../../api/type.orbit';
import type { SortFields } from '../../element/Sort';
import type { SearchDefinition } from '../../element/Search';
import type { BbCollaboration } from '../../../api/type';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { OrbitBulkDeleteSelectionAction, OrbitTreeNode } from '../../../state/Orbit/type';
import type { ItemSelectOptions } from '../../common/ResourceSelector';
import type { DialogState } from '../../element/Dialog';
import type { ObjectsBrowserMode } from '../../hoc/orbit/Container';
import type { MultiSelectChoice } from '../../element/Table';
import type { ItemSelectTypes, SetOrFn } from '../../common/ResourceSelector';

export const orbitContainerSearchDef: SearchDefinition<BbOrbitContainer> = textOnlySearch<BbOrbitContainer>('container');

export const orbitSortFields: SortFields<BbOrbitContainer> = {
    _default: stringSort<BbOrbitContainer>('name'),
    name: stringSort<BbOrbitContainer>('name'),
    last_modified: dateSort<BbOrbitContainer>('last_modified'),
    bytes: numberSort<BbOrbitContainer>('bytes'),
    count: numberSort<BbOrbitContainer>('count'),
};

export const collabsSortFields: SortFields<BbCollaboration> = {
    _default: stringSort<BbCollaboration>('id')
};

export type ReadWriteFlag = 'read' | 'write' | 'readwrite' | 'none';
export type ContainerPublicListingEdit = {
    listings: boolean,
    css: string,
}
export type ContainerStaticSiteEdit = {
    enabled: boolean,
    index: string,
    error: string,
    genIndex: boolean,
    genError: boolean,
}

export type ContainerAccessEntryType = '' | 'other' | 'http' | 'any-referrer' | 'api' | 'new-api';
export type AllowDenyFlag = 'allow' | 'deny';

export type ContainerAccessEdit = {
    type: ContainerAccessEntryType,
    initial: ?string,
    access: ReadWriteFlag | '',
    apiClient: string,
    referrer: string,
    allowDomain: AllowDenyFlag,
    other: string,
    newApiClientName: string,
}

export type TempUrlKeys = {
    tempUrlKey1: string,
    tempUrlKey2: string,
    gen1: boolean,
    gen2: boolean,
}

export type ContainerHistoryEdit = {
    mode: 'disable' | 'create' | 'existing',
    create_container: string,
    existing_container: string,
    gen_create: boolean,
}

export type OrbitEditMetaType<V> = {
    rawMeta: OrbitContainerMeta,
    edit: V,
}

export type ContainerQuotaEdit = {
    bytes: string,
    count: string,
}

export function validatePublicListingsType(meta: OrbitContainerMeta, value: ContainerPublicListingEdit): [?FormErrors, ?OrbitContainerHeaders] {
    let nextMeta: OrbitContainerMeta = cloneContainerMeta(meta);

    if (value.listings) {
        const idx = meta.access.findIndex(x => x.type === 'any-referrer');
        if (idx === -1) {
            nextMeta.access.push({
                id: 'any-referrer',
                type: 'any-referrer',
                read: true,
            });
        }
        if (value.css !== '') {
            nextMeta.web.css = value.css;
        } else {
            nextMeta.web.css = null;
        }
        nextMeta.rlistings.read = true;
        nextMeta.web.listings = 'true';
    } else {
        nextMeta.web.listings = null;
        nextMeta.rlistings.read = false;
        if (typeof meta.web.index === 'string') {
            nextMeta.web.listings = 'false';
            nextMeta.rlistings.read = true;
        }
    }

    let headers = orbitContainerMetaToHeaders(nextMeta, [
        'x-container-read',
        'x-container-meta-web-listings', 'x-remove-container-meta-web-listings',
        'x-container-meta-web-listings-css', 'x-remove-container-meta-web-listings-css'
    ]);

    return [
        null,
        headers,
    ];
}

export function validateStaticSite(meta: OrbitContainerMeta, value: ContainerStaticSiteEdit): [?FormErrors, ?OrbitContainerHeaders] {
    if (value.enabled && value.index === '') {
        return [
            new Map<string, string>([['index', 'Please enter a filename to use as the site index page.'],]),
            null
        ];
    }

    let nextMeta: OrbitContainerMeta = cloneContainerMeta(meta);

    if (value.enabled) {
        const idx = meta.access.findIndex(x => x.type === 'any-referrer');
        if (idx === -1) {
            nextMeta.access.push({
                id: 'any-referrer',
                type: 'any-referrer',
                read: true,
            });
        }
        nextMeta.rlistings.read = true;
        if (nextMeta.web.listings == null) nextMeta.web.listings = 'false';
        nextMeta.web.index = value.index;
        if (value.error !== '') nextMeta.web.error = value.error;
        else nextMeta.web.error = null;
    } else {
        nextMeta.rlistings.read = false;
        nextMeta.web.index = null;
        nextMeta.web.error = null;
        nextMeta.web.listings = null;
        // preserve 'public site listings' if set
        if (meta.web.listings === 'true') {
            nextMeta.web.listings = 'true';
            nextMeta.rlistings.read = true;
        }
    }

    let headers = orbitContainerMetaToHeaders(nextMeta, [
        'x-container-read',
        'x-container-meta-web-listings', 'x-remove-container-meta-web-listings',
        'x-container-meta-web-index', 'x-remove-container-meta-web-index',
        'x-container-meta-web-error', 'x-remove-container-meta-web-error',
    ]);

    return [
        null, headers
    ]
}

export function validateContainerAccessEntry(meta: OrbitContainerMeta, value: ContainerAccessEdit): [?FormErrors, ?OrbitContainerHeaders] {
    let errors = new Map<string, string>();

    const source: ?ContainerAccessEdit = value.initial ? UriFragmentContainerAccessEdit(value.initial) : null;

    switch(value.type) {
    case 'http':
        if (value.referrer === '') {
            errors.set('referrer', 'Please enter a domain name.');
        }
        if (
            (source == null || source.referrer !== value.referrer)
            && meta.access.findIndex((e) => e.type === 'http' && e.referrer === value.referrer) !== -1
        ) {
            errors.set('referrer', 'An access rule already exists for this domain.');
        }
        break;
    case 'any-referrer':
        if (meta.access.findIndex(t => t.type === 'any-referrer') !== -1) {
            errors.set('type', 'An access rule for any referrer already exists on this container.');
        }
        break;
    case 'api':
        if (value.apiClient === '') {
            errors.set('apiClient', 'Please select an API client.');
        }
        if (
            (source == null || source.apiClient !== value.apiClient)
            && meta.access.findIndex(x => x.type === 'api' && x.id === value.apiClient) !== -1
        ) {
            errors.set('apiClient', 'An access rule for this API client already exists on this container.');
        }
        break;
    case 'other':
        if (value.other === '') {
            errors.set('other', 'Please enter an access string.');
        }
        if (
            (source == null || source.other !== value.other)
            && meta.access.findIndex(x => x.type === 'other' && x.id === value.other) !== -1
        ) {
            errors.set('other', 'An access rule for this access string already exists on this container.');
        }
        break;
    case '':
        errors.set('type', 'Please select an access type.');
        break;
    case 'new-api':
        // no validation here - empty api client name is fine.
        break;
    default:
        void (value.type: empty);
    }

    if (errors.size > 0) {
        return [errors, null];
    }

    let nextMeta: OrbitContainerMeta = cloneContainerMeta(meta);

    switch(value.type) {
    case 'http':
        nextMeta.access = [].concat(
            meta.access.filter(x => source == null || x.type !== 'http' || source.referrer !== x.referrer),
            {
                id: value.referrer,
                type: 'http',
                referrer: value.referrer,
                read: value.allowDomain === 'allow',
            }
        );
        break;
    case 'any-referrer':
        nextMeta.access = [].concat(
            meta.access,
            {
                id: 'any-referrer',
                type: 'any-referrer',
                read: true,
            });
        break;
    case 'api':
        const [accId, cliId] = value.apiClient.split(':');
        nextMeta.access = [].concat(
            meta.access.filter(x => source == null || x.type !== 'api' || source.apiClient !== x.id),
            {
                id: value.apiClient,
                type: 'api',
                accountId: accId,
                cliId: cliId,
                read: value.access === 'readwrite' || value.access === 'read',
                write: value.access === 'readwrite' || value.access === 'write',
            }
        );
        break;
    case 'other':
        nextMeta.access = [].concat(
            meta.access.filter(x => source == null || x.type !== 'other' || source.other !== x.id),
            {
                id: value.other,
                type: 'other',
                read: value.access === 'readwrite' || value.access === 'read',
                write: value.access === 'readwrite' || value.access === 'write',
            }
        );
        break;
    case 'new-api':
        break;
    case '':
        // we shouldn't get here.
        break;
    default:
        void (value.type: empty);
    }

    let headers = orbitContainerMetaToHeaders(nextMeta, ['x-container-read', 'x-container-write']);

    return [
        null,
        headers,
    ];
}

export function validateHistory(meta: OrbitContainerMeta, value: ContainerHistoryEdit): [?FormErrors, ?OrbitContainerHeaders] {
    if (value.mode === 'create' && value.create_container === '') {
        return [
            new Map([['create_container', 'Please enter a new container name.']]),
            null
        ];
    }

    if (value.mode === 'create') {
        const state: CloudGuiState = store.getState();
        if (state.Orbit.containers.details.has(value.create_container)) {
            return [
                new Map([
                    ['create_container', 'A container name with this name already exists; "Use Existing Container" to select it.'],
                    ['create_container_exists', ''],
                ]),
                null
            ];
        }
    }

    if (value.mode === 'existing' && value.existing_container === '') {
        return [
            new Map([['existing_container', 'Please enter an existing container.']]),
            null
        ];
    }

    let nextMeta: OrbitContainerMeta = cloneContainerMeta(meta);
    switch(value.mode) {
    case 'disable':
        nextMeta.archive.history = null;
        break;
    case 'create':
        nextMeta.archive.history = value.create_container;
        nextMeta.archive.versions = null;
        break;
    case 'existing':
        nextMeta.archive.history = value.existing_container;
        nextMeta.archive.versions = null;
        break;
    default:
        void (value.mode: empty);
    }

    return [
        null,
        orbitContainerMetaToHeaders(nextMeta, [
            'x-history-location', 'x-versions-location',
            'x-remove-history-location', 'x-remove-versions-location',
        ]),
    ];
}

export function validateQuota(meta: OrbitContainerMeta, value: ContainerQuotaEdit): [?FormErrors, ?OrbitContainerHeaders] {
    let bytes: ?number = null;
    let count: ?number = null;

    if (value.bytes !== '' && isNaN(value.bytes)) {
        return [
            new Map([['bytes', 'Please enter a valid bytes quota.']]),
            null
        ];
    } else {
        bytes = Math.ceil((parseFloat(value.bytes) * BYTES_TO_GB));
    }

    if (value.count !== '' && isNaN(value.count)) {
        return [
            new Map([['count', 'Please enter a valid object count quota.']]),
            null
        ];
    } else {
        count = parseFloat(value.count);
    }

    let nextMeta: OrbitContainerMeta = cloneContainerMeta(meta);
    nextMeta.quota = {
        bytes, count,
    };

    return [
        null,
        orbitContainerMetaToHeaders(nextMeta, [
            'x-container-meta-quota-bytes', 'x-container-meta-quota-count',
            'x-remove-container-meta-quota-bytes', 'x-remove-container-meta-quota-count',
        ]),
    ];
}

export const orbitMetaToTempKeyEditor = (meta: OrbitContainerMeta | OrbitAccountMeta): TempUrlKeys => {
    let edit: TempUrlKeys = {
        tempUrlKey1: '',
        tempUrlKey2: '',
        gen1: false,
        gen2: false,
    }

    if (typeof meta.tempUrlKey1 === 'string') {
        edit.tempUrlKey1 = meta.tempUrlKey1;
        edit.gen1 = false;
    } else {
        edit.tempUrlKey1 = genTempUrlKey();
        edit.gen1 = true;
    }
    if (typeof meta.tempUrlKey2 === 'string') {
        edit.tempUrlKey2 = meta.tempUrlKey2;
        edit.gen2 = false;
    } else {
        edit.tempUrlKey2 = genTempUrlKey();
        edit.gen2 = true;
    }

    return edit;
}

export function validateTempUrlKeys(meta: OrbitContainerMeta, value: TempUrlKeys): [?FormErrors, ?OrbitContainerHeaders] {
    let headers: OrbitContainerHeaders = ({}: any);

    if (value.tempUrlKey1 !== '') headers['x-container-meta-temp-url-key'] = value.tempUrlKey1;
    else headers['x-remove-container-meta-temp-url-key'] = '1';

    if (value.tempUrlKey2 !== '') headers['x-container-meta-temp-url-key-2'] = value.tempUrlKey2;
    else headers['x-remove-container-meta-temp-url-key-2'] = '1';

    return [
        null,
        headers
    ];
}

export function deleteAccess(meta: OrbitContainerMeta, value: ContainerAccess): OrbitContainerHeaders {
    const nextMeta: OrbitContainerMeta = {
        ...cloneContainerMeta(meta),
        access: [].concat(meta.access).filter(x => x.type !== value.type || x.id !== value.id),
    };

    return orbitContainerMetaToHeaders(nextMeta, ['x-container-read', 'x-container-write']);
}

function ReadWriteToFlag(read: boolean, write: boolean): ReadWriteFlag {
    if (read && write) return 'readwrite';
    if (read) return 'read';
    if (write) return 'write';
    return 'none';
}

export function ContainerAccessToUriFragment(a: ContainerAccess): string {
    switch(a.type) {
    case 'api':
        // can't edit these ones.
        if (a.full) return '';
        else return [a.type, ReadWriteToFlag(a.read, a.write), a.id].join(':');
    case 'team':
        return '';
    case 'http':
        return [a.type, ReadWriteToFlag(a.read, false), a.referrer].join(':');
    case 'other':
        return [a.type, ReadWriteToFlag(a.read, a.write), a.id].join(':');
    case 'any-referrer':
        return [a.type, ReadWriteToFlag(a.read, false), '*'].join(':');
    default:
        void (a.type: empty);
    }

    return '';
}

export const emptyEdit: ContainerAccessEdit = {
    type: '',
    initial: '',
    access: 'readwrite',
    apiClient: '',
    referrer: '',
    allowDomain: 'allow',
    other: '',
    newApiClientName: '',
};

export function UriFragmentContainerAccessEdit(fragment: string): ContainerAccessEdit {
    const exp = fragment.split(':');
    if (exp.length >= 3 && (exp[1] === 'readwrite' || exp[1] === 'read' || exp[1] === 'write' || exp[1] === 'none')) {
        const editType = exp[0];
        const access: ReadWriteFlag = (exp[1]: any);
        const value = exp.slice(2).join(':');

        switch(editType) {
        case 'api':
            return {
                ...emptyEdit,
                initial: fragment,
                type: 'api',
                apiClient: value,
                access,
            };
        case 'http':
            return {
                ...emptyEdit,
                initial: fragment,
                type: 'http',
                referrer: value,
                allowDomain: access === 'read' ? 'allow' : 'deny',
                access,
            };
        case 'other':
            return {
                ...emptyEdit,
                initial: fragment,
                type: 'other',
                other: value,
                access,
            };
        default:
            break;
        }
    }

    return {
        ...emptyEdit,
        type: 'other',
        other: fragment,
    };
}

type OrbitObjectListActions = {
    ...ItemSelectOptions<OrbitObject | OrbitTreeNode>,
    +deleteDialog: DialogState<null>,
}

export function getAllItemsCounts<C: ItemSelectTypes>(allItems: $ReadOnlyArray<C>, filter: ?(C) => boolean): [number, ?number] {
    const toProcess = allItems.filter(x => !filter || filter(x));
    const allSelectableCount = toProcess.length;

    let childrenCount = 0;
    while(toProcess.length) {
        const item = toProcess.pop();
        if (Array.isArray(item.children)) {
            childrenCount += item.children.length;
            // $FlowFixMe C will only ever be OrbitTreeNode in this case, so the filter would work...
            toProcess.push(...item.children.filter(x => !filter || filter(x)));
        }
    }

    return [
        allSelectableCount,
        childrenCount > 0 ? childrenCount : null,
    ];
}

function buildSelectionShortcuts<C: ItemSelectTypes>(
    allItems: $ReadOnlyArray<C>,
    items: $ReadOnlyArray<C>,
    filter: ?(C) => boolean,
    setSelected: (SetOrFn) => void,
    pathBasename: string,
    mode: ObjectsBrowserMode
): $ReadOnlyArray<MultiSelectChoice> {
    const paged = mode === 'flip' || (mode === 'full' && items.length < allItems.length);

    const [allSelectableLength, childrenCount] = getAllItemsCounts(allItems);
    const pageSelectableLength = items.reduce((acc, x) => !filter || filter(x) ? acc + 1 : acc, 0);

    let res: Array<MultiSelectChoice> = [];

    if (mode === 'full') {
        res.unshift(            {
            children: `Select all in "${pathBasename}" (${allSelectableLength + (childrenCount || 0)} items)`,
            onClick: () => {
                setSelected((selected) => {
                    let nextSelected = new Set(selected);
                    amendSelection(nextSelected, allItems, filter, true);
                    return nextSelected;
                });
            },
        });
    }

    if (paged && pageSelectableLength > 0) {
        res.unshift(
            {
                children: `Select all on page (${pageSelectableLength} items)`,
                onClick: () => {
                    setSelected(() => {
                        let nextSelected = new Set();
                        amendSelection(nextSelected, items, filter, true);
                        return nextSelected;
                    });
                },
            },
        );
    }

    res.push({
        separator: true,
        children: 'Select none',
        onClick: () => setSelected(new Set())
    });

    return res;
}


export const useOrbitObjectListActions = (container: string, pathBasename: string, mode: ObjectsBrowserMode): OrbitObjectListActions => {
    const { next } = useTriggeredMessages();
    const [selected, setSelected] = useState<$ReadOnlyArray<string>>([]);
    const editor = useEditorDirect(
        selected,
        setSelected,
        null
    );
    const pending = useSelector<CloudGuiState, ?Set<string>>(state => state.ActionQueue.orbitObjectActions.get(container));

    const dispatch = useDispatch<Dispatch<OrbitBulkDeleteSelectionAction>>();

    const [, deleteDialog] = useDeleteDialog(
        true,
        () => {
            dispatch({
                type: 'ORBIT_BULK_DELETE_SELECTION',
                payload: {
                    container,
                    objects: editor.value || [],
                    messagesId: next(),
                }
            });
            editor.setValue([]);
        },
        'Delete',
    );

    return {
        editor,
        isPending: (c: OrbitObject | OrbitTreeNode) => {
            return pending != null && pending.has(c.id);
        },
        shortcutBuilder: (...params) => buildSelectionShortcuts(...params, pathBasename, mode),
        multiItemActions: [
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: () => deleteDialog.show(),
                },
                disabledTooltip: null,
            },
        ],
        deleteDialog,
    };
};