// @flow

import { useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useMutation, } from '@apollo/react-hooks';
import { apiClient, } from '../../api/graphql';
import { FETCH_DELAY } from '../../state/resource/ResourceLogic';

import type { QueryTuple, LazyQueryHookOptions, ApolloError, } from '@apollo/react-hooks';
import type { BbResourceKind } from '../../api/type';
import type { Dispatch } from 'redux';
import type { MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import type { FetchQueuePushAction } from '../../state/FetchQueue/type';
import type { AuthLogoutAction } from '../../state/auth/type';

type DocumentNode = any;

if (process.env.NODE_ENV === 'development') {
    const ver = require('@apollo/client/package.json');
    if (ver.version !== '3.7.10') throw new Error("Make sure isNetworkRequestInFlight is still right after apollo client upgrade:" + ver.version);
}

export function isNetworkRequestInFlight(networkStatus: number): boolean {
    return networkStatus ? networkStatus < 7 : false;
}

export function isNetworkRequestError(networkStatus: number): boolean {
    return networkStatus === 8;
}

function hasUnauthenticatedError(error: ApolloError) {
    return -1 !== error.graphQLErrors.findIndex(
        e => e?.extensions?.code === 'UNAUTHENTICATED'
    );
}

export const useResourceQuery = <TData, TVariables>(
    query: DocumentNode,
    options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {

    const lazyQuery = useLazyQuery(query, {
        client: apiClient,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        ...options,
    });
    const [, usedQuery] = lazyQuery;
    const { called, error, networkStatus, } = usedQuery;
    const dispatch = useDispatch<Dispatch<AuthLogoutAction>>();

    useEffect(() => {
        if (
            called && !isNetworkRequestInFlight(networkStatus) && error
            && hasUnauthenticatedError(error)
        ) {
            dispatch({ type: 'AUTH_LOGOUT', payload: { clearState: false } });
        }
    }, [called, networkStatus, dispatch, error,]);

    return lazyQuery;
};

/**
 * Use to run a graphql mutation where no event is produced as a result
 * of it being run.
 *
 * Initially used for 2fa enable / disable on user.
 */
export const useResourceFetchMutation = <TData, TVariables>(
    kind: BbResourceKind,
    id: string,
    query: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
    const usedMutation = useAuthedMutation<TData, TVariables>(query, options);
    const [, { called, loading }] = usedMutation;

    const dispatch = useDispatch<Dispatch<FetchQueuePushAction>>();

    useEffect(() => {
        if (called && !loading) {
            dispatch({
                type: 'FETCH_QUEUE_PUSH',
                payload: {
                    kind, id, delay: FETCH_DELAY
                }
            });
        }
    }, [called, loading, dispatch, kind, id]);

    return usedMutation;
};

export const useAuthedMutation = <TData, TVariables>(
    query: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
    const usedMutation = useMutation<TData, TVariables>(query, options);
    const dispatch = useDispatch<Dispatch<AuthLogoutAction>>();

    const [, { called, loading, error }] = usedMutation;

    useEffect(() => {
        if (
            called && !loading && error
            && hasUnauthenticatedError(error)
        ) {
            dispatch({ type: 'AUTH_LOGOUT', payload: { clearState: false } });
        }
    }, [called, loading, dispatch, error,]);

    return usedMutation;
};