// @flow

import { useEffect, useCallback, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { apiClient } from '../../api/graphql';
import gql from 'graphql-tag';
import { RC_FETCHING, RC_INITIAL, RC_CACHED } from '../../state/resource/type';

import type { Dispatch } from 'redux';
import type { CloudGuiState } from '../../state/cloudgui';
import type { GroupedChoices } from '../element/lib/groupedItemState';
import type { DbsEngineVersion, BbDatabaseEngineType } from '../../api/type.dbs';
import type { ValueSelectChoice } from '../element/ValueSelect';
import type { GuiDatabaseEngineState, GuiDatabaseEngineAction } from '../../state/Gui/type';
import type { ResourceFetched } from '../../state/resource/type';

const UNCACHED_ENGINES = {};

const QUERY = gql`
query {
  meta {
    databaseEngines {
      id
      label
      isDefault
      versions {
        id
        label
        isAvailable
        isDefault
        isDeprecated
      }
    }
  }
}
`;

type DatabaseVersionRaw = {
    id: string,
    label: string,
    isAvailable: boolean,
    isDefault: boolean,
    isDeprecated: boolean,
}

type DatabaseEngineRaw = {
    id: string,
    label: string,
    isDefault: boolean,
    versions: $ReadOnlyArray<DatabaseVersionRaw>
}

type DatabaseEnginesRaw = {
    meta: {
        databaseEngines: $ReadOnlyArray<DatabaseEngineRaw>,
    },
}

type DatabaseEngineTypesHook = {
    defaultVersion: ?DbsEngineVersion,
    engineTypes: GroupedChoices<DbsEngineVersion>,
    engineLabel: (engine: BbDatabaseEngineType, version: string) => string,
    cacheStatus: ResourceFetched
}

export function adaptGqlData(data: DatabaseEnginesRaw): [?DbsEngineVersion, GroupedChoices<DbsEngineVersion>] {
    let defaultVersion: ?DbsEngineVersion = null;
    const engineTypes = data.meta.databaseEngines.reduce((acc, engineRaw) => {
        const items: $ReadOnlyArray<ValueSelectChoice<DbsEngineVersion>> = engineRaw.versions
            .filter(x => x.isAvailable)
            .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
            .map((x) => ({
                label: x.label + (x.isDeprecated ? ' (deprecated)' : ''),
                value: { engine: engineRaw.id, version: x.id, isDeprecated: x.isDeprecated, },
                default: x.isDefault,
            }: ValueSelectChoice<DbsEngineVersion>));

        if (items.length) {
            const def: DbsEngineVersion = (items.find(x => x.default) || items[0]).value;

            if (engineRaw.isDefault) defaultVersion = def;

            return {
                ...acc,
                [engineRaw.id]: {
                    label: engineRaw.label,
                    items,
                    default: def,
                },
            }
        }

        return acc;
    }, ({}: GroupedChoices<DbsEngineVersion>));

    return [
        defaultVersion,
        engineTypes,
    ];
}

export function useDatabaseEngineTypes(): DatabaseEngineTypesHook {
    const { cacheStatus, engineTypes, defaultVersion } = useSelector<CloudGuiState, GuiDatabaseEngineState>(state => state.Gui.databaseEngines);
    const dispatch = useDispatch<Dispatch<GuiDatabaseEngineAction>>();

    const [fetch, { loading, called, data, }] = useLazyQuery<DatabaseEnginesRaw, {}>(QUERY, {
        client: apiClient,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (cacheStatus === RC_INITIAL) {
            dispatch({
                type: 'GUI_DATABASE_ENGINE',
                payload: {
                    cacheStatus: RC_FETCHING,
                    engineTypes: null,
                    defaultVersion: null,
                },
            });
            fetch();
        }
    }, [cacheStatus, dispatch, fetch]);

    useEffect(() => {
        if (called && !loading && data) {
            const [defaultVersion, engineTypes] = adaptGqlData(data);

            dispatch({
                type: 'GUI_DATABASE_ENGINE',
                payload: {
                    cacheStatus: RC_CACHED,
                    engineTypes,
                    defaultVersion,
                },
            })
        }
    }, [called, loading, data, dispatch]);

    const engineLabel = useCallback((engine: BbDatabaseEngineType, version: string): string => {
        return `${engineTypes?.[engine]?.label || engine} ${version}`;
    }, [engineTypes]);

    return {
        defaultVersion,
        engineTypes: engineTypes || UNCACHED_ENGINES,
        engineLabel,
        cacheStatus,
    };
}

export function useDatabaseEngineSelector(selected: ?DbsEngineVersion, snapshot: ?string, selectVersion: (next: DbsEngineVersion, group: ?string) => void): DatabaseEngineTypesHook {
    const types = useDatabaseEngineTypes();
    const { defaultVersion, engineTypes }  = types;

    useEffect(() => {
        if (selected == null && snapshot == null && engineTypes !== UNCACHED_ENGINES && defaultVersion != null) {
            selectVersion(defaultVersion, defaultVersion.engine);
        }
    }, [engineTypes, defaultVersion, selected, snapshot, selectVersion]);

    return types;
}