// @flow

import type { SecretState, SecretAction, SecretValue } from './type';
import type { StoreClearAction } from '../type';

const emptyState: SecretState = {
    secretIds: new Map<string, string>(),
    secrets: new Map<string, SecretValue>(),
};

export const SecretReducer = (
    state: SecretState = emptyState,
    action: SecretAction | StoreClearAction
): SecretState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'SECRET_SET':
        return {
            ...state,
            secrets: new Map<string, SecretValue>(state.secrets).set(
                action.payload.id, action.payload.value
            )
        };
    case 'SECRET_REMOVE':
        let next = new Map<string, SecretValue>(state.secrets);
        next.delete(action.payload.id);
        return {
            ...state,
            secrets: next
        };
    case 'SECRET_SET_ID':
        const nextSecretIds = new Map(state.secretIds);
        if (action.payload.value == null) nextSecretIds.delete(action.payload.key);
        else nextSecretIds.set(action.payload.key, action.payload.value)
        return {
            ...state,
            secretIds: nextSecretIds,
        }
    default:
        void (action: empty);
        break;
    }

    return state;
};