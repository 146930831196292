// @flow
import { StandardResourceSection } from '../StandardResourceSection';
import { VolList } from './VolList';
import { VolCreate } from './VolCreate';
import { VolView } from './VolView';

import type { Location, Match } from 'react-router-dom';

export const VolSection = ({ match, }: { location: Location, match: Match }): React$Node => (
    <StandardResourceSection
        match={match}
        list={VolList}
        create={VolCreate}
        view={VolView}
    />
);