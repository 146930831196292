// @flow
import type { ReduxLogic } from 'redux-logic';
import { createLogic } from 'redux-logic';
import { to } from 'await-to-js';
import { request } from '../../api/rest';
import { resourceMetas } from '../../api/adapter';
import { RC_CACHED } from '../resource/type';
import { handleApiError } from '../../lib/ErrorHandling';

import type { CloudGuiState } from '../cloudgui';
import type { ActionQueueAddAction, ActionQueueAction } from './type';
import type { Dispatch } from 'redux';
import type { ResourceAction } from '../resource/type';

/**
 * Fetches all containers from the api for current account
 */
export const ActionQueueActionsLogic: ReduxLogic = createLogic({
    'type': ['ACTION_QUEUE_ADD', 'ACTION_QUEUE_MULTIPLE'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ActionQueueAddAction
        },
        dispatch: Dispatch<ActionQueueAction | ResourceAction<any, any>>,
        done: () => void
    ) {
        const { Auth, ActionQueue } = deps.getState();

        if (ActionQueue.running) {
            done();
            return;
        }

        dispatch({
            type: 'ACTION_QUEUE_RUNNING',
            payload: {
                running: true,
            }
        });

        if (Auth.currAccountId) {
            let queue = deps.getState().ActionQueue.actions;

            while(queue.length) {
                let next = queue[0];
                dispatch({ type: 'ACTION_QUEUE_SHIFT' });

                const { kind, id, action, method } = next;

                const meta = resourceMetas[kind];
                if (meta) {
                    let urlParts = [meta.url, id];
                    if (action) urlParts.push(action);
                    let data = {};
                    if (next.params) {
                        data = { data: next.params };
                    }

                    const [err, actionResponse] = await to(request({
                        method,
                        url: urlParts.join('/'),
                        ...data,
                    }));

                    if (!err && actionResponse.status >= 200 && actionResponse.status < 300) {
                        let full: any = null;

                        if (actionResponse.data) {
                            // eugh another cast through :any. Perhaps this will disappear when
                            // resourceMetas has all types and matches BbAllResourceKinds?
                            full = meta.adapt.full(actionResponse.data);
                            let collected = meta.adapt.full(actionResponse.data);
                            dispatch({
                                type: 'RESOURCE_ADD_FULL',
                                payload: {
                                    kind,
                                    full,
                                    collected
                                },
                            });
                        }

                        if (actionResponse.data) {
                            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id: actionResponse.data.id, status: RC_CACHED } });
                        }
                    }

                    if (err) {
                        if (id) {
                            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_CACHED } });
                        }
                        handleApiError(err);
                    }
                }

                queue = deps.getState().ActionQueue.actions;
            }
        }

        dispatch({
            type: 'ACTION_QUEUE_RUNNING',
            payload: {
                running: false,
            }
        });

        done();
    },
});

