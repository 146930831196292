// @flow
import { createLogic } from 'redux-logic';
import { orbit, request, } from '../../api/rest';
import { to } from 'await-to-js';
import { orbitUrl, clientsUrl } from '../../api/url';
import { fetchOrbitAuth, FLIP_MODE_THRESHOLD, } from './lib';
import { clearTokens } from '../../api/kiosk';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS, RC_CACHED, RC_FETCHING, RC_INITIAL, RC_NOT_FOUND } from '../resource/type';
import { handleApiError } from '../../lib/ErrorHandling';
import { adaptOrbitObject, adaptOrbitContainerMeta, adaptOrbitContainer, adaptOrbitAccountMeta, adaptOrbitObjectMeta } from '../../api/adapter.orbit';
import { resourceMetas } from '../../api/adapter';
import { isDirId, getObjectName, } from '../../api/type.orbit';
import { mapOrbitToOrbitTree, } from './OrbitTree';

import type { ReduxLogic } from 'redux-logic';
import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type {
    OrbitAction,
    OrbitCreateContainerAction,
    OrbitDeleteContainerAction,
    OrbitFetchContainersAction,
    OrbitEntriesSetPrefixAction,
    OrbitContainerMetaUpdateAction,
    OrbitRefreshContainerAction,
    OrbitFetchAccountSettingsAction,
    OrbitAccountMetaUpdateAction,
    OrbitContainerSetHistoryAction,
    OrbitDeleteObjectAction,
    OrbitContainerNewApiClientAction,
    OrbitBulkDeleteObjectsAction,
    ContainerObjectPage,
    OrbitFetchObjectMetaAction,
    OrbitContainerFetchAllObjects,
    OrbitBulkDeleteSelectionAction,
    OrbitBulkDeleteDirsAction,
    OrbitSetImageTags,
    OrbitManifestSize,
} from './type';
import type { MessageAction } from '../Message/type';
import type { BbOrbitContainer, OrbitObject, OrbitContainerHeaders, } from '../../api/type.orbit';
import type { BbApiClientParams, BbApiClient } from '../../api/type.cli';
import type { ResourceAddFull, ResourceAddCollected } from '../resource/type';
import type { SecretSetId } from '../Secret/type';
import type { ActionQueueAction } from '../ActionQueue/type';

/**
 * Fetches all containers from the api for current account
 */
export const OrbitFetchContainersLogic: ReduxLogic = createLogic({
    'type': ['ORBIT_FETCH_CONTAINERS'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitFetchContainersAction }, dispatch: Dispatch<OrbitAction>, done: () => void) {
        const { Auth } = deps.getState();

        dispatch({ type: 'ORBIT_CLEAR', });

        await fetchOrbitAuth();

        if (Auth.currAccountId) {
            const [err, containersResponse] = await to(orbit({
                url: orbitUrl + '/' + Auth.currAccountId,
            }));

            if (!err && containersResponse.status >= 200 && containersResponse.status < 300) {
                dispatch({
                    type: 'ORBIT_CONTAINERS',
                    payload: {
                        containers: containersResponse.data.map(x => [x.name, adaptOrbitContainer({
                            ...x,
                            last_modified: x.last_modified + 'Z'
                        })]),
                    },
                });
                dispatch({
                    type: 'ORBIT_FETCHED',
                });
            }

            if (err && err.response?.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
                // but we probably still want to fetch the containers, so just do a single-hit retry.
                if (!deps.action.payload?.isRetry) {
                    dispatch({
                        type: 'ORBIT_FETCH_CONTAINERS',
                        payload: {
                            isRetry: true,
                        }
                    });
                }

            }
        }

        done();
    },
});

function orbitContainerFromHeaders(name: string, headers: Object) {
    return adaptOrbitContainer({
        name,
        count: Number(headers['x-container-object-count']) || 0,
        bytes: Number(headers['x-container-bytes-used']) || 0,
        last_modified: headers['last-modified'],
    });
}

function adaptRawObject(container: string, url: string, acc: Array<OrbitObject>, raw: Object): Array<OrbitObject> {
    if (container === 'images') {
        let object = adaptOrbitObject(raw, url);
        let namePrefix = object.name.substr(0, 4);
        if (object.basename === object.name) {
            if (namePrefix === 'dbi-') object.content_type = 'application/x-brightbox-database-snapshot';
            else if (namePrefix === 'img-') object.content_type = 'application/x-brightbox-server-image';
            object.is_resource = true;

            acc.push(object);
        }

    } else {
        if (
            raw.subdir &&
            acc.length &&
            acc[acc.length - 1].id === raw.subdir &&
            acc[acc.length - 1].content_type === 'application/directory'
        ) {
            // skip it.
            // i wrote this this way-round originally and it's a bit mind bending
            // to flip it the other way. so let's keep this "noop / else"....
        } else {
            acc.push(adaptOrbitObject(raw, url));
        }

    }

    return acc;
}

function calculateObjectsPrev(action: OrbitEntriesSetPrefixAction, currObjects: ?ContainerObjectPage, objects: ContainerObjectPage): ?string {
    let retryMarker: ?string = action.payload.prefix === action.payload.marker ? null : action.payload.prefix;

    if (action.payload.marker === action.payload.prefix) {
        // actually, nothing to do - we explicitly want the
        // "prev" array to be empty here, so that 'Jump to first page'
        // is hidden and "Prev" button is disabled.
    } else if (currObjects?.marker === action.payload.marker && Array.isArray(currObjects?.prev)) {
        objects.prev = [].concat(currObjects.prev);
        retryMarker = currObjects.prev.length ? currObjects.prev[currObjects.prev.length - 1] : objects.prefix;
    } else if (currObjects && currObjects.prev.length && currObjects.prev[currObjects.prev.length - 1] === action.payload.marker) {
        objects.prev = currObjects.prev.slice(0, -1);
        retryMarker = objects.prev.length ? objects.prev[objects.prev.length - 1] : objects.prefix;
    } else if (currObjects) {
        objects.prev = (currObjects.prev || []).concat(currObjects.marker);
        retryMarker = objects.prev.length > 1 ? objects.prev[objects.prev.length - 2] : objects.prefix;
    }

    return retryMarker;
}

function dispatchContainerObjectsState(objects: ContainerObjectPage, containerRes, pageSize: number, container: string, url: string, currOrbit: ?BbOrbitContainer, dispatch: Dispatch<OrbitAction>) {
    objects.nextMarker = null;

    // collect all the entries together and extract the meta
    objects.objects = containerRes.data.reduce((acc, raw) => {
        if (acc.length === pageSize) {
            objects.nextMarker = acc[acc.length - 1].name;
            return acc;
        }

        return adaptRawObject(container, url, acc, raw);
    }, ([]: Array<OrbitObject>));

    const meta = adaptOrbitContainerMeta(containerRes.headers);

    if (!currOrbit) {
        // all the details are in headers, so let's grab them.
        dispatch({
            type: 'ORBIT_DETAILS',
            payload: {
                details: orbitContainerFromHeaders(container, containerRes.headers),
            }
        });
        dispatch({
            type: 'ORBIT_META',
            payload: { container, meta },
        });
    }

    dispatch({
        type: 'ORBIT_OBJECTS',
        payload: { container, objects },
    });
}

async function doOrbitBulkDelete(
    dispatch: Dispatch<OrbitAction | ActionQueueAction | MessageAction>,
    container: string,
    objects: $ReadOnlyArray<string>,
    currAccountId: string
) {
    await fetchOrbitAuth();

    dispatch({
        type: 'ACTION_QUEUE_ADD_ORBIT_OBJECTS',
        payload: {
            container,
            objects,
        }
    });

    let toDelete = objects.map(x => container + '/' + encodeURI(x)).join('\n');

    const url = [orbitUrl, currAccountId].join('/') + '?bulk-delete';

    const [err,] = await to(orbit({
        url,
        method: 'POST',
        headers: {
            'content-type': 'text/plain',
        },
        data: toDelete,
    }));

    if (err) {
        const message = 'Error deleting objects';

        handleApiError({ response: { data: { errors: [message] } } });
    }

    dispatch({
        type: 'ACTION_QUEUE_REMOVE_ORBIT_OBJECTS',
        payload: {
            container,
            objects,
        }
    });
}

function refreshOrbitContainer(dispatch: Dispatch<OrbitAction | ActionQueueAction | MessageAction>, container: string, containerPage: ContainerObjectPage) {
    if (containerPage.tree) {
        dispatch({
            type: 'ORBIT_CONTAINER_FETCH_ALL_OBJECTS',
            payload: {
                container,
                useNewestFlag: true,
            }
        });
    } else {
        const { prefix, marker, pageSize, } = containerPage;
        dispatch({
            type: 'ORBIT_CONTAINER_SET_PREFIX',
            payload: {
                container,
                prefix,
                marker,
                pageSize,
                useNewestFlag: true,
                useDelimiter: true,
            }
        });
    }
}

/**
 * Fetches a page of container objects. This is the logic that drives the 'flip' ObjectsBrowserMode
 */
export const OrbitContainerFetchPageLogic: ReduxLogic = createLogic({
    type: 'ORBIT_CONTAINER_SET_PREFIX',
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: OrbitEntriesSetPrefixAction
        },
        dispatch: Dispatch<OrbitAction>,
        done: () => void
    ) {
        const { Auth, Orbit } = deps.getState();
        const { container, pageSize, useNewestFlag, useDelimiter, } = deps.action.payload;

        const currOrbit: ?BbOrbitContainer = Orbit.containers.details.get(container);
        const currObjects: ?ContainerObjectPage = Orbit.containers.objects.get(container);

        if (Auth.currAccountId) {
            await fetchOrbitAuth();

            const queryLimit = (pageSize === FLIP_MODE_THRESHOLD)
                // if we're asked for the full threshold count, then this is
                // container reg image parsing, so just give them all
                ? FLIP_MODE_THRESHOLD
                // otherwise, fetch twice as many as asked for, to ensure we don't
                // end up with a page full of 'subdir' entries, which we skip in `adaptRawObject`
                : 1 + (pageSize * 2)

            let params: Object = {
                limit: queryLimit,
            };
            if (useDelimiter) params.delimiter = '/';

            let objects = {
                prefix: deps.action.payload.prefix,
                marker: deps.action.payload.marker,
                prev: [],
                nextMarker: null,
                objects: null,
                pageSize,
                tree: null,
            };

            dispatch({
                type: 'ORBIT_OBJECTS',
                payload: {
                    container,
                    objects,
                }
            });

            objects = { ...objects };

            params['marker'] = deps.action.payload.marker;
            const retryMarker = calculateObjectsPrev(deps.action, currObjects, objects);

            let url = orbitUrl + '/' + Auth.currAccountId + '/' + container + '/';
            params['prefix'] = objects.prefix;
            const headers = (useNewestFlag) ? { 'x-newest': 'true' } : null;
            const [err, containerRes] = await to(orbit({ url, params, headers, }));

            if (!err && containerRes.status >= 200 && containerRes.status < 300) {
                if (Array.isArray(containerRes.data) && containerRes.data.length === 0 && retryMarker != null) {
                    dispatch({
                        type: 'ORBIT_CONTAINER_SET_PREFIX',
                        payload: {
                            container,
                            pageSize,
                            prefix: deps.action.payload.prefix,
                            marker: retryMarker,
                            useNewestFlag,
                            useDelimiter,
                        }
                    });
                } else {
                    dispatchContainerObjectsState(objects, containerRes, pageSize, container, url, currOrbit, dispatch);
                }

            } else if (err && err.response.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
            }
        }

        done();
    },
});

/**
 * Fetches all objects in a container. This is the logic that drives the 'full' ObjectsBrowserMode
 *
 * - well, up to the max orbit "page" size of 10k objects
 * - but our gui limit is below this...
 */
export const OrbitContainerFetchAllObjectsLogic: ReduxLogic = createLogic({
    type: 'ORBIT_CONTAINER_FETCH_ALL_OBJECTS',
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: OrbitContainerFetchAllObjects
        },
        dispatch: Dispatch<OrbitAction>,
        done: () => void
    ) {
        const { Auth, } = deps.getState();
        const { container, } = deps.action.payload;

        if (Auth.currAccountId) {
            await fetchOrbitAuth();

            dispatch({
                type: 'ORBIT_OBJECTS',
                payload: {
                    container,
                    objects: {
                        prefix: '',
                        marker: '',
                        prev: [],
                        nextMarker: null,
                        pageSize: 1,
                        objects: null,
                        tree: null,
                    }
                },
            });

            let url = orbitUrl + '/' + Auth.currAccountId + '/' + container + '/';
            const headers = (deps.action.payload.useNewestFlag) ? { 'x-newest': 'true' } : null;

            const [err, containerRes] = await to(orbit({ url, headers, }));

            if (!err && containerRes.status >= 200 && containerRes.status < 300) {

                const meta = adaptOrbitContainerMeta(containerRes.headers);
                const details = orbitContainerFromHeaders(container, containerRes.headers);

                // always update the details; this could be a refresh from a bulk delete
                dispatch({
                    type: 'ORBIT_DETAILS',
                    payload: {
                        details,
                    }
                });
                dispatch({
                    type: 'ORBIT_META',
                    payload: { container, meta },
                });

                const objects = containerRes.data.reduce((acc, raw) => adaptRawObject(container, url, acc, raw), ([]: Array<OrbitObject>));
                const tree = mapOrbitToOrbitTree(Auth.currAccountId, container, details, objects);

                dispatch({
                    type: 'ORBIT_OBJECTS',
                    payload: {
                        container,
                        objects: {
                            prefix: '',
                            marker: '',
                            prev: [],
                            nextMarker: null,
                            pageSize: 1,
                            objects,
                            tree,
                        }
                    },
                });

            } else if (err && err.response.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
            }
        }

        done();
    },
});

/**
 * Creates a new container.
 */
export const OrbitCreateContainerLogic: ReduxLogic = createLogic({
    type: ['ORBIT_CREATE_CONTAINER'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitCreateContainerAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { params: allParams, messagesId } = deps.action.payload;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        await fetchOrbitAuth();

        const { name, ...params } = allParams;

        const url = [orbitUrl, Auth.currAccountId, name].join('/');

        const [err,] = await to(orbit({
            url,
            method: 'PUT',
            data: params,
        }));

        if (err) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    messages: err.response.data.errors,
                    status: RC_ERROR,
                    resource: null,
                }
            });
        } else {
            // this is only used to redirect within the ui, so
            // these defaults will be fine.
            const resource: BbOrbitContainer = {
                id: name,
                name,
                count: 0,
                bytes: 0,
                last_modified: new Date(),
            };

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_SUCCESS,
                    resource,
                }
            });
            dispatch({
                type: 'ORBIT_FETCH_CONTAINERS',
            });
        }


        done();
    },
});

/**
 * Creates a new container.
 */
export const OrbitDeleteContainerLogic: ReduxLogic = createLogic({
    type: ['ORBIT_DELETE_CONTAINER'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitDeleteContainerAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { container, messagesId } = deps.action.payload;

        await fetchOrbitAuth();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        const url = [orbitUrl, Auth.currAccountId, container].join('/');

        const [err,] = await to(orbit({
            url,
            method: 'DELETE',
        }));

        if (err) {
            let message = (err.response.status === 409)
                ? 'Cannot delete ' + container + ' while it has objects.'
                : 'Error deleting ' + container;

            handleApiError({ response: { data: { errors: [message] } } });

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    messages: [message],
                    status: RC_ERROR,
                    resource: null,
                }
            });
        } else {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_SUCCESS,
                    resource: null,
                }
            });
            dispatch({
                type: 'ORBIT_FETCH_CONTAINERS',
            });
        }


        done();
    },
});

/**
 * Deletes a single object
 */
export const OrbitDeleteObjectLogic: ReduxLogic = createLogic({
    type: ['ORBIT_OBJECT_DELETE'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitDeleteObjectAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { container, object, messagesId } = deps.action.payload;

        await fetchOrbitAuth();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        const url = [orbitUrl, Auth.currAccountId, container, object.name].join('/');

        const [err,] = await to(orbit({
            url,
            method: 'DELETE',
        }));

        if (err) {
            const message = 'Error deleting ' + object.name;

            handleApiError({ response: { data: { errors: [message] } } });

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    messages: [message],
                    status: RC_ERROR,
                    resource: null,
                }
            });
        } else {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_SUCCESS,
                    resource: null,
                }
            });
        }

        done();
    },
});

/**
 * Separates the provided objects into dirs and non-dirs, and triggers the
 * logic for each subset.
 */
export const OrbitBulkDeleteSelectionLogic: ReduxLogic = createLogic({
    type: 'ORBIT_BULK_DELETE_SELECTION',
    async process(
        deps: { getState: () => CloudGuiState, action: OrbitBulkDeleteSelectionAction },
        dispatch: Dispatch<OrbitAction>,
        done: () => void
    ) {
        const { objects: allObjects, container, ...params } = deps.action.payload;
        const page = deps.getState().Orbit.containers.objects.get(container);
        
        if (page && page.tree != null) {
            // the ResourceSelector should have included all sub dir items
            // in its selection, so no need to go through that again.

            const objects = allObjects.map(x => getObjectName(x)).sort((a: string, b: string): number => {
                return b.length - a.length;
            });

            dispatch({
                type: 'ORBIT_BULK_DELETE_OBJECTS',
                payload: {
                    ...params,
                    container,
                    objects,
                    refreshObjects: true,
                }
            });
        } else {
            const objects = allObjects.filter(x => !isDirId(x));
            const dirs = allObjects.filter(x => isDirId(x));

            if (objects.length) {
                dispatch({
                    type: 'ORBIT_BULK_DELETE_OBJECTS',
                    payload: {
                        ...params,
                        container,
                        objects,
                        refreshObjects: true,
                    }
                });
            }
            if (dirs.length) {
                dispatch({
                    type: 'ORBIT_BULK_DELETE_DIRS',
                    payload: {
                        ...params,
                        container,
                        dirs,
                    }
                });
            }
        }

        done();
    },
});

/**
 * Uses bulk delete to delete many objects
 *
 * Doesn't remove application/directory entries; a separate logic for dirs dispatches
 * multiple ORBIT_BULK_DELETE_OBJECTS instead.
 */
export const OrbitBulkDeleteObjectLogic: ReduxLogic = createLogic({
    type: 'ORBIT_BULK_DELETE_OBJECTS',
    async process(deps: { getState: () => CloudGuiState, action: OrbitBulkDeleteObjectsAction }, dispatch: Dispatch<OrbitAction | ActionQueueAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { container, objects, refreshObjects, messagesId } = deps.action.payload;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
            }
        });

        await doOrbitBulkDelete(dispatch, container, objects, Auth.currAccountId);

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_SUCCESS,
                objects,
            }
        });

        const containerPage = deps.getState().Orbit.containers.objects.get(container);
        if (refreshObjects && containerPage != null) {
            refreshOrbitContainer(dispatch, container, containerPage);
        }

        done();
    },
});

export const OrbitRemoveCachedObjects: ReduxLogic = createLogic({
    type: 'ORBIT_REMOVE_CACHED_OBJECTS',
    process(deps: { getState: () => CloudGuiState, action: OrbitBulkDeleteObjectsAction }, dispatch: Dispatch<OrbitAction | ActionQueueAction | MessageAction>, done: () => void) {
        const { Orbit, } = deps.getState();
        const { container, objects: deletedObjects } = deps.action.payload;

        const currentPage = Orbit.containers.objects.get(container);

        if (currentPage && currentPage.objects) {
            const { objects: currentObjects, ...rest } = currentPage;
            const deleted = new Set<string>(deletedObjects);

            dispatch({
                type: 'ORBIT_OBJECTS',
                payload: {
                    container,
                    objects: {
                        ...rest,
                        objects: currentObjects.filter(x => !deleted.has(x.id))
                    }
                },
            });
        }

        done();
    },
})

export const OrbitBulkDeleteDirsLogic: ReduxLogic = createLogic({
    type: 'ORBIT_BULK_DELETE_DIRS',
    async process(
        deps: { getState: () => CloudGuiState, action: OrbitBulkDeleteDirsAction, },
        dispatch: Dispatch<OrbitAction | ActionQueueAction | MessageAction>,
        done: () => void
    ) {
        const { Auth, } = deps.getState();
        const { container, dirs, } = deps.action.payload;

        dispatch({
            type: 'ACTION_QUEUE_ADD_ORBIT_OBJECTS',
            payload: {
                container,
                objects: dirs,
            }
        });

        let queue: Array<{ id: string, marker: string }> = dirs.map(id => ({ id, marker: '' }));

        while(queue.length > 0) {
            await fetchOrbitAuth();

            const { id, marker, } = queue.pop();

            const url = [orbitUrl, Auth.currAccountId, container].join('/');
            const params = {
                prefix: id,
                separator: '',
                marker,
            }

            const [err, containerRes] = await to(orbit({ url, params, }));
            if (!err && containerRes.status >= 200 && containerRes.status < 300) {
                if (containerRes.status === 204 || !Array.isArray(containerRes.data) || containerRes.data.length === 0) {
                    // when paging runs out for this dir, delete it too.
                    await doOrbitBulkDelete(dispatch, container, [id.substr(0, id.length - 1)], Auth.currAccountId);
                } else {
                    const nextMarker = containerRes.data[containerRes.data.length - 1].name;
                    queue.push({ id, marker: nextMarker, });

                    let objects: Array<string> = containerRes.data.map(x => x.name);

                    await doOrbitBulkDelete(dispatch, container, objects, Auth.currAccountId);
                }
            }
        }

        dispatch({
            type: 'ACTION_QUEUE_REMOVE_ORBIT_OBJECTS',
            payload: {
                container,
                objects: dirs,
            }
        });

        const containerPage = deps.getState().Orbit.containers.objects.get(container);
        if (containerPage) {
            refreshOrbitContainer(dispatch, container, containerPage);
        }

        done();
    }
})

/**
 * Updates meta properties on containers
 */
export const OrbitUpdateContainerHeadersLogic: ReduxLogic = createLogic({
    type: ['ORBIT_CONTAINER_UPDATE_META'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitContainerMetaUpdateAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { container, messagesId, headers } = deps.action.payload;

        await fetchOrbitAuth();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        const url = [orbitUrl, Auth.currAccountId, container].join('/');

        const [updateErr,] = await to(orbit({
            url,
            method: 'PUT',
            headers,
        }));

        if (updateErr) {
            let message = 'Error updating ' + container;

            handleApiError({ response: { data: { errors: [message] } } });

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    messages: [message],
                    status: RC_ERROR,
                    resource: null,
                }
            });
        } else {

            const [getErr, getRes] = await to(orbit({
                url,
                method: 'HEAD',
                headers: {
                    'X-Newest': 'true',
                }
            }));

            if (getErr) {
                let message = 'Error fetching ' + container + ' after update';

                handleApiError({ response: { data: { errors: [message] } } });

                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        messages: [message],
                        status: RC_ERROR,
                        resource: null,
                    }
                });
            } else {
                const meta = adaptOrbitContainerMeta(getRes.headers);

                dispatch({
                    type: 'ORBIT_META',
                    payload: { container, meta },
                });

                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_SUCCESS,
                        resource: null,
                    }
                });
            }
        }

        done();
    },
});

/**
 * Updates container history, creating the dest container if necessary.
 */
export const OrbitUpdateContainerSetHistoryLogic: ReduxLogic = createLogic({
    type: ['ORBIT_CONTAINER_SET_HISTORY'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitContainerSetHistoryAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { messagesId, headers } = deps.action.payload;

        await fetchOrbitAuth();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        if (typeof headers['x-history-location'] === 'string') {
            const destContainerName = headers['x-history-location'];

            const destUrl = [orbitUrl, Auth.currAccountId, destContainerName].join('/');
            const [destErr, ] = await to(orbit({
                url: destUrl,
                method: 'HEAD',
            }));

            if (destErr && destErr.response.status === 404) {
                const [createErr, ] = await to(orbit({
                    url: destUrl,
                    method: 'PUT',
                }));

                if (createErr) {
                    dispatch({
                        type: 'MESSAGE_MESSAGE',
                        payload: {
                            id: messagesId,
                            status: RC_ERROR,
                            resource: null,
                            messages: [ 'An error occurred creating the destination container.' ]
                        }
                    });

                    done();
                    return;
                } else {
                    const [, headRes] = await to(orbit({
                        url: destUrl,
                        method: 'HEAD',
                    }));

                    dispatch({
                        type: 'ORBIT_DETAILS',
                        payload: {
                            details: orbitContainerFromHeaders(destContainerName, headRes.headers)

                        }
                    })
                }
            }
        }

        dispatch({
            type: 'ORBIT_CONTAINER_UPDATE_META',
            payload: deps.action.payload,
        })

        done();
    },
});

/**
 * Creates a new API client and grants access to the container from it.
 */
export const OrbitNewApiClientAccessLogic: ReduxLogic = createLogic({
    type: ['ORBIT_CONTAINER_NEW_API_CLIENT'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: OrbitContainerNewApiClientAction
        },
        dispatch: Dispatch<OrbitAction | MessageAction | ResourceAddFull<BbApiClient, BbApiClient> | SecretSetId>,
        done: () => void
    ) {
        const { Auth, } = deps.getState();
        const { container, messagesId, headers, access, apiClientName } = deps.action.payload;

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        // make an API client first
        const [err, res] = await to(request({
            url: clientsUrl,
            method: 'POST',
            data: ({
                name: apiClientName,
                description: '',
                permissions_group: 'storage',
            }: BbApiClientParams)
        }));

        if (err) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_ERROR,
                    messages: err.response?.data?.errors
                }
            });
            return;
        }

        dispatch({
            type: 'RESOURCE_ADD_FULL',
            payload: {
                kind: 'api_client',
                full: resourceMetas.api_client.adapt.full(res.data),
                collected: resourceMetas.api_client.adapt.collected(res.data),
            }
        });
        dispatch({
            type: 'SECRET_SET_ID',
            payload: {
                key: container,
                value: res.data.id,
            }
        })

        await fetchOrbitAuth();

        const ref = Auth.currAccountId +':' + res.data.id;
        let nextHeaders: OrbitContainerHeaders = ({}: any);
        if (access === 'readwrite' || access === 'read') {
            nextHeaders['x-container-read'] = headers['x-container-read'] != null && headers['x-container-read'] !== ''
                ? headers['x-container-read'] + ',' +  ref
                : ref
        }
        if (access === 'readwrite' || access === 'write') {
            nextHeaders['x-container-write'] = headers['x-container-write'] != null && headers['x-container-write'] !== ''
                ? headers['x-container-write'] + ',' +  ref
                : ref
        }

        dispatch({
            type: 'ORBIT_CONTAINER_UPDATE_META',
            payload: {
                container,
                headers: nextHeaders,
                messagesId,
            }
        });

        done();
    },
});

/**
 * Updates meta properties on account
 */
export const OrbitUpdateAccountHeadersLogic: ReduxLogic = createLogic({
    type: ['ORBIT_ACCOUNT_UPDATE_META'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitAccountMetaUpdateAction }, dispatch: Dispatch<OrbitAction | MessageAction>, done: () => void) {
        const { Auth, } = deps.getState();
        const { messagesId, headers } = deps.action.payload;

        await fetchOrbitAuth();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_API_REQUEST,
                resource: null,
            }
        });

        let submitHeaders: Object = {};
        if (typeof headers['x-account-meta-temp-url-key'] === 'string') {
            if (headers['x-account-meta-temp-url-key'] === '') {
                submitHeaders['x-remove-account-meta-temp-url-key'] = '1';
            } else {
                submitHeaders['x-account-meta-temp-url-key'] = headers['x-account-meta-temp-url-key'];
            }
        }
        if (typeof headers['x-account-meta-temp-url-key-2'] === 'string') {
            if (headers['x-account-meta-temp-url-key-2'] === '') {
                submitHeaders['x-remove-account-meta-temp-url-key-2'] = '1';
            } else {
                submitHeaders['x-account-meta-temp-url-key-2'] = headers['x-account-meta-temp-url-key-2'];
            }
        }

        const url = [orbitUrl, Auth.currAccountId, ].join('/');

        const [updateErr,] = await to(orbit({
            url,
            method: 'PUT',
            headers: submitHeaders,
        }));

        if (updateErr) {
            let message = 'Error updating account settings';

            handleApiError({ response: { data: { errors: [message] } } });

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    messages: [message],
                    status: RC_ERROR,
                    resource: null,
                }
            });
        } else {
            dispatch({
                type: 'ORBIT_FETCH_ACCOUNT_META',
                payload: {
                    isRetry: false,
                    messagesId,
                }
            })
        }

        done();
    },
});

/**
 * Refreshes a specific container, using X-Newest to ensure the
 * X- headers are up to date.
 */
export const OrbitRefreshContainerLogic: ReduxLogic = createLogic({
    'type': ['ORBIT_REFRESH_CONTAINER'],
    async process(deps: { getState: () => CloudGuiState, action: OrbitRefreshContainerAction }, dispatch: Dispatch<OrbitAction>, done: () => void) {
        const { Auth } = deps.getState();

        await fetchOrbitAuth();

        const { container } = deps.action.payload;

        if (Auth.currAccountId) {
            const [err, getRes] = await to(orbit({
                url: orbitUrl + '/' + Auth.currAccountId + '/' + container + '/',
                headers: {
                    'X-Newest': 'true',
                },
                method: 'HEAD',
            }));

            if (!err && getRes.status >= 200 && getRes.status < 300) {
                const meta = adaptOrbitContainerMeta(getRes.headers);

                // all the details are in headers
                dispatch({
                    type: 'ORBIT_DETAILS',
                    payload: {
                        details: orbitContainerFromHeaders(container, getRes.headers)
                    }
                });

                dispatch({
                    type: 'ORBIT_META',
                    payload: { container, meta },
                });

            }

            if (err && err.response?.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
                // but we probably still want to fetch the containers, so just do a single-hit retry.
                if (!deps.action.payload?.isRetry) {
                    dispatch({
                        type: 'ORBIT_REFRESH_CONTAINER',
                        payload: {
                            container,
                            isRetry: true,
                        }
                    });
                }

            }
        }

        done();
    },
});


/**
 * Refreshes account meta, using X-Newest to ensure the
 * X- headers are up to date.
 */
export const OrbitAccountSettingsLogic: ReduxLogic = createLogic({
    'type': ['ORBIT_FETCH_ACCOUNT_META'],
    async process(
        deps: { getState: () => CloudGuiState, action: OrbitFetchAccountSettingsAction },
        dispatch: Dispatch<OrbitAction | MessageAction>,
        done: () => void
    ) {
        const { Auth } = deps.getState();

        await fetchOrbitAuth();

        const { messagesId, isRetry } = deps.action.payload;

        if (Auth.currAccountId) {

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_API_REQUEST,
                    resource: null,
                }
            });

            const [err, getRes] = await to(orbit({
                url: orbitUrl + '/' + Auth.currAccountId + '/',
                headers: {
                    'X-Newest': 'true',
                },
                method: 'HEAD',
            }));

            if (!err && getRes.status >= 200 && getRes.status < 300) {
                const meta = adaptOrbitAccountMeta(getRes.headers);

                dispatch({
                    type: 'ORBIT_ACCOUNT_META',
                    payload: { meta },
                });

                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_SUCCESS,
                        resource: null,
                    }
                });
            }

            if (err && err.response?.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
                // but we probably still want to fetch the settings, so just do a single-hit retry.
                if (!isRetry) {
                    dispatch({
                        type: 'ORBIT_FETCH_ACCOUNT_META',
                        payload: {
                            messagesId,
                            isRetry: true,
                        }
                    });
                }
            }
        }

        done();
    },
});

export const OrbitFetchObjectLogic: ReduxLogic = createLogic({
    type: ['ORBIT_FETCH_OBJECT_META'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: OrbitFetchObjectMetaAction,
        },
        dispatch: Dispatch<OrbitAction | MessageAction | ResourceAddFull<BbApiClient, BbApiClient> | SecretSetId>,
        done: () => void
    ) {
        const { Auth } = deps.getState();

        const { container, name, } = deps.action.payload;

        dispatch({
            type: 'ORBIT_VIEW_OBJECT',
            payload: {
                container,
                name,
                object: null,
                cacheStatus: RC_INITIAL,
            }
        });

        await fetchOrbitAuth();

        if (Auth.currAccountId) {
            dispatch({
                type: 'ORBIT_VIEW_OBJECT',
                payload: {
                    container,
                    name,
                    object: null,
                    cacheStatus: RC_FETCHING,
                }
            });

            const containerUrl = orbitUrl + '/' + Auth.currAccountId + '/' + container + '/';

            const [err, getRes] = await to(orbit({
                url: containerUrl + name,
                headers: {
                    'X-Newest': 'true',
                },
                method: 'HEAD',
            }));

            if (!err && getRes.status >= 200 && getRes.status < 300) {
                const object = adaptOrbitObjectMeta(containerUrl, name, getRes.headers);

                dispatch({
                    type: 'ORBIT_VIEW_OBJECT',
                    payload: {
                        container,
                        name,
                        object,
                        cacheStatus: RC_CACHED,
                    }
                });
            }

            if (err && err.response?.status === 401) {
                // sometimes we can't rely on our token.exp < now() check.
                clearTokens();
                // but we probably still want to fetch the containers, so just do a single-hit retry.

                if (!deps.action.payload?.isRetry) {
                    dispatch({
                        type: 'ORBIT_FETCH_OBJECT_META',
                        payload: {
                            isRetry: true,
                            container,
                            name,
                        }
                    });
                }

            }

            if (err && err.response?.status === 404) {
                dispatch({
                    type: 'ORBIT_VIEW_OBJECT',
                    payload: {
                        container,
                        name,
                        object: null,
                        cacheStatus: RC_NOT_FOUND,
                    }
                });
            }

        }

        done();

    }
})

export const OrbitRefreshImagesContainerLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_ADD_COLLECTED'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceAddCollected<any>,
        },
        dispatch: Dispatch<OrbitRefreshContainerAction>,
        done: () => void
    ) {
        if (deps.action.payload.kind === 'event') {
            for (let event of deps.action.payload.resources) {
                if (
                    event.action === 'deleted'
                    && event.resource
                    && (
                        event.resource.resource_type === 'image'
                        || event.resource.resource_type === 'database_snapshot'
                    )
                ) {
                    dispatch({
                        type: 'ORBIT_REFRESH_CONTAINER',
                        payload: {
                            container: 'images',
                            isRetry: false,
                        }
                    });
                    break;
                }
            }
        }
        done();
    }
});

const LAYER_MEDIA_TYPE = "application/vnd.docker.image.rootfs.diff.tar.gzip";

export const OrbitFetchManifestSizesLogic: ReduxLogic = createLogic({
    type: 'ORBIT_SET_IMAGE_TAGS',
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: OrbitSetImageTags,
        },
        dispatch: Dispatch<OrbitManifestSize>,
        done: () => void
    ) {
        const { Auth, Orbit } = deps.getState();

        const { imageTags, container } = deps.action.payload;

        const fetches = [];

        imageTags.forEach((x) => {
            const { sha256 } = x;
            if (!Orbit.manifestSizes.has(sha256)) {
                dispatch({
                    type: 'ORBIT_MANIFEST_SIZE',
                    payload: {
                        sha256: x.sha256,
                        size: null,
                    }
                });

                let url = orbitUrl + '/' + Auth.currAccountId + '/' + container + `/files/docker/registry/v2/blobs/sha256/${sha256.substring(0, 2)}/${sha256}/data` ;
                fetches.push(orbit({ url, })
                    .then((res) => {
                        const { data } = res;
                        const size = (typeof data === 'object' && Array.isArray(data.layers))
                            ? data.layers.reduce((acc, x) => acc + (x.mediaType === LAYER_MEDIA_TYPE ? x.size  : 0), 0)
                            : null;

                        dispatch({
                            type: 'ORBIT_MANIFEST_SIZE',
                            payload: {
                                sha256,
                                size,
                            }
                        });
                    })
                );
            }
        });

        await Promise.all(fetches);

        done();
    }   ,
});


