// @flow

import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import copy from 'copy-to-clipboard';

import type { EditorModal } from '../../../common/Editor';
import type { OrbitEditMetaType, TempUrlKeys } from '../def';
import type { FormErrors } from '../../../common/lib';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';

type OrbitTempUrlKeysEditProps = {
    +editor: EditorModal<OrbitEditMetaType<TempUrlKeys>, FormErrors, OrbitContainerMeta>
};

export const ContainerTempUrlKeysEdit = ({ editor }: OrbitTempUrlKeysEditProps): React$Node => {
    const { value, setValue, } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Temporary URL Keys'}
                description='Temporary URL Keys are used to generate secure shareable links to individual objects'
            />
            <PanelBar>
                <TextInput
                    label='Key 1'
                    value={value.edit.tempUrlKey1}
                    focusSelectAll={true}
                    autoPopulated={value.edit.gen1}
                    helperText={value.edit.gen1 ? 'Auto-generated key – click "Save" to enable' : ''}
                    onChange={(tempUrlKey1) => setValue({...value, edit: { ...value.edit, tempUrlKey1, gen1: false, } })}
                    postText={value.edit.tempUrlKey1 ? 'Copy' : null}
                    onPostInlayClick={() => copy(value.edit.tempUrlKey1)}
                />
                <hr/>
                <TextInput
                    label='Key 2'
                    value={value.edit.tempUrlKey2}
                    focusSelectAll={true}
                    autoPopulated={value.edit.gen2}
                    helperText={value.edit.gen2 ? 'Auto-generated key – click "Save" to enable' : ''}
                    onChange={(tempUrlKey2) => setValue({...value, edit: { ...value.edit, tempUrlKey2, gen2: false, } })}
                    postText={value.edit.tempUrlKey2 ? 'Copy' : null}
                    onPostInlayClick={() => copy(value.edit.tempUrlKey2)}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};