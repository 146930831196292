// @flow
import { Notice } from '../element/Notice';

export type ErrorListProps = {
    errors: ?$ReadOnlyArray<string>,
}

export const ErrorList = (props: ErrorListProps): React$Node => {
    if (props.errors && props.errors.length) {
        return (
            <Notice type='error'>
                <ul>
                    {props.errors.map((error, i) => <li key={i}>{error}</li>)}
                </ul>
            </Notice>
        );
    }

    return null;
};
