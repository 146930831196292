// @flow
import { createTextZoneSearch } from '../../common/TextAndZoneSearch';
import { cloudIpsSort, dateSort, idNameSort, } from '../../element/Sort';
import { textOnlySearch } from '../../common/TextOnlySearch';

import type {
    BbCollectedDatabaseServer as BbCollDbs,
    BbDatabaseSnapshot,
    BbCollectedDatabaseServer,
    BbDatabaseServerParams,
    BbDatabaseServer,
} from '../../../api/type.dbs';
import type { SortFields } from '../../element/Sort';
import type { SearchDefinition } from '../../element/Search';
import type { ResourceActionMethod } from '../../../state/resource/type';

export const dbsSearchDef: SearchDefinition<BbCollDbs> = createTextZoneSearch<BbCollDbs>('database_server');

export const dbsSortFields: SortFields<BbCollDbs> = {
    _default: idNameSort<BbCollDbs>(),
    name: idNameSort<BbCollDbs>(),
    created_at: dateSort<BbCollDbs>('created_at'),
    database_engine: (a: BbCollDbs, b: BbCollDbs) => {
        let res = a.database_engine.localeCompare(b.database_engine);
        if (res === 0) {
            res = parseFloat(a.database_version) - parseFloat(b.database_version);
        }
        return res;
    },
    disk_size: (a: BbCollDbs, b: BbCollDbs) => a.database_server_type.disk_size - b.database_server_type.disk_size,
    cloud_ips: cloudIpsSort<BbCollDbs>('cloud_ips'),
};

export const dbiSortFields: SortFields<BbDatabaseSnapshot> = {
    _default: idNameSort<BbDatabaseSnapshot>(),
    name: idNameSort<BbDatabaseSnapshot>(),
    created_at: dateSort<BbDatabaseSnapshot>('created_at'),
    database_engine: (a: BbDatabaseSnapshot, b: BbDatabaseSnapshot) => {
        let res = a.database_engine.localeCompare(b.database_engine);
        if (res === 0) {
            res = parseFloat(a.database_version) - parseFloat(b.database_version);
        }
        return res;
    },
    size: (a: BbDatabaseSnapshot, b: BbDatabaseSnapshot) => a.size - b.size,
};

export const dbiSearchDef: SearchDefinition<BbDatabaseSnapshot> = textOnlySearch<BbDatabaseSnapshot>('database_snapshot');

export function snapshotSelectableFilter(snapshot: BbDatabaseSnapshot): boolean {
    return snapshot.status === 'available';
}

export function databaseServerSelectable(dbs: BbCollectedDatabaseServer): boolean {
    return dbs.status !== 'deleting' && dbs.status !== 'deleted' && dbs.status !== 'failing' && dbs.status !== 'failed';
}

export type DbsSimpleAction = (action: string, params?: ?$Shape<BbDatabaseServerParams>, nonPristine: ?BbDatabaseServer, method?: ?ResourceActionMethod) => void;