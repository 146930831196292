// @flow
import { Switch, Route, } from 'react-router-dom';
import { animationRouteContext, useCloudGuiRouteAnimation } from './element/animation';
import { NotFound } from './section/home/NotFound';
import { Home } from './section/home/Home';
import { ServerSection } from './section/server/ServerSection';
import { ServerGroupSection } from './section/server_group/ServerGroupSection';
import { LbaSection } from './section/lba/LbaSection';
import { CipSection } from './section/cloud_ip/CipSection';
import { ImageSection } from './section/image/ImageSection';
import { DbsSection } from './section/database_server/DbsSection';
import { OrbitSection } from './section/orbit/OrbitSection';
import { UserSection } from './section/user/UserSection';
import { BillingSection } from './section/account/BillingSection';
import { Settings } from './section/account/Settings';
import { TeamSection } from './section/team/TeamSection';
import { useScrollPositionState } from './base/ScrollPositionState';
import { DbiSection } from './section/database_server/DbiSection';
import { SupportQuery, CloseAccountRequest } from './section/account/SupportQuery';
import { ContainerRegistrySection } from './section/container_registry/ContainerRegistrySection';
import { VolSection } from './section/volume/VolSection';
import { NoAccounts } from './section/home/NoAccounts';

import type { BbCollectedAccount } from '../api/type.acc';

type MainProps = {
    baseUrl: string,
    userLoaded: boolean,
    currAccount: ?BbCollectedAccount,
}

const Playground = process.env.NODE_ENV === 'development'
    ? require('./section/playground/Playground').Playground
    : null

export const Main = ({ baseUrl, currAccount, userLoaded }: MainProps): React$Node => {
    const { ref } = useScrollPositionState();
    const { sectionAnim, subEditAnim, custom, section, } = useCloudGuiRouteAnimation();
    const { Provider } = animationRouteContext;

    return (
        <main className='l-app__main bg-gray-100' role='main' ref={ref}>
            {/*<AnimationMatchDebug />*/}
                <Provider value={[section, custom, sectionAnim, subEditAnim ]}>
                    <Switch>
                        {(!currAccount && userLoaded)
                            ? <Route path={`${baseUrl}`} component={NotFound}/>
                            : <Route exact path={`${baseUrl}/:dashboard(dashboard)?`} component={Home}/>
                        }

                        <Route path={`${baseUrl}/servers/`} component={ServerSection}/>
                        <Route path={`${baseUrl}/server_groups/`} component={ServerGroupSection}/>
                        <Route path={`${baseUrl}/load_balancers/`} component={LbaSection}/>
                        <Route path={`${baseUrl}/cloud_ips/`} component={CipSection}/>
                        <Route path={`${baseUrl}/images/`} component={ImageSection}/>
                        <Route path={`${baseUrl}/database_servers/`} component={DbsSection}/>
                        <Route path={`${baseUrl}/database_snapshots/`} component={DbiSection}/>
                        <Route path={`${baseUrl}/orbit/`} component={OrbitSection}/>
                        <Route path='/user/' component={UserSection}/>
                        <Route path={`${baseUrl}/(billing|invoices)/`} component={BillingSection}/>
                        <Route path={`${baseUrl}/settings/close/`} component={CloseAccountRequest}/>
                        <Route path={`${baseUrl}/settings/`} component={Settings}/>
                        <Route path={`${baseUrl}/support/`} component={SupportQuery}/>
                        <Route path={`${baseUrl}/team/`} component={TeamSection}/>
                        <Route path={`${baseUrl}/container_registry/`} component={ContainerRegistrySection}/>
                        <Route path={`${baseUrl}/volumes/`} component={VolSection}/>
                        {Playground != null
                            ? <Route path='/playground/' component={Playground}/>
                            : null}
                        {/* Only show this not found once the user is loaded, else it flashes up briefly before the account is fetched */}
                        {userLoaded ? <Route component={NoAccounts} /> : null}

                    </Switch>
                </Provider>
        </main>
    );
};
