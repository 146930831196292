// @flow
import { SvgIcon } from './SvgIcon';

type SelectorSummaryProps = {
    +selected: number,
    +kind?: 'plain' | 'dropdown',
}

export const SelectorSummary = ({ selected, kind, ...rest }: SelectorSummaryProps): React$Node => (
    <div className={'c-selector-summary'
            + (selected > 0 ? ' c-selector-summary--active' : '')
            + (kind === 'dropdown' ? ' c-selector-summary--dropdown' : '')
        } {...rest}>
        <div className="c-selector-summary__content">
            <div className="c-selector-summary__text">{selected > 0 ? selected : 'None'} selected</div>
            {kind === 'dropdown'
                ? <SvgIcon svg={'caret-down'} className="c-selector-summary__icon" />
                : null
            }
        </div>
    </div>
);

