// @flow

import { useCurrentAccountId } from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { FLIP_MODE_THRESHOLD } from "../../../state/Orbit/lib";
import { useEffect, useMemo } from "react";
import { RC_CACHED, RC_FETCHING } from "../../../state/resource/type";
import { containerRegistryNoScheme } from "../../../api/url";

import type { CloudGuiState } from "../../../state/cloudgui";
import type { OrbitObject } from "../../../api/type.orbit";
import type { ValueSelectChoice } from '../../element/ValueSelect';
import type { Bytes } from "../../../api/type.units";

export type ImageTag = {
    // "/files/docker/registry/v2/repositories/acc-x/orbitcontainer/object/name"
    +id: string;
    // "image:tag"
    +nameTag: string;
    // "image"
    +name: string;
    // "tag"
    +tag: string;
    // "6b3b6c113f98e901a8b1473dee4c268cf37e93d72bc0a01e57c65b4ab99e58ee"
    +sha256: string;
    // array<orbit object path of current/.../link>
    +indexes: $ReadOnlyArray<string>;
    // self-explanatory
    +last_modified: ?Date;
    // "cr.gb1s.brightbox.com/acc-x/orbitcontainer/image:tag"
    +tagUrl: string;
    // "cr.gb1s.brightbox.com/acc-x/orbitcontainer/image:abc123..."
    +hashUrl: string;
    // parsed from the manifests
    +size: null|Bytes,
}

type TagIndex = {
    latest: string,
    last_modified: Date,
    indexObjects: Array<string>
};

// convert the paths from the distribution project, detailed URL below, into something usable for control panel.
// https://github.com/distribution/distribution/blob/52d948a9f51c953b646f02cfaa9b9d95e1ad5f3c/registry/storage/paths.go
export function pathsToImageTagList(accountId: string, containerRepoName: string, paths: $ReadOnlyArray<OrbitObject>, manifestSizes: Map<string, null|Bytes>): $ReadOnlyArray<ImageTag> {
    const fullMatch = new RegExp(`^files/docker/registry/v2/repositories/${accountId}/${containerRepoName}(/.*)?/_manifests/tags/(.+)/current/link$`)
    const tagContentMap = new RegExp(`^files/docker/registry/v2/repositories/${accountId}/${containerRepoName}(/.*)?/_manifests/tags/(.+)/index/sha256/([a-f0-9]{64})/link`)

    // pull out all the tagContentMap values, usually probably only one for
    // a given tag, and store them. avoids having to fetch every tag/x/current/link file, if
    // there is only one found. least likely to help with ':latest' tag.
    const tagHashes = new Map<string, TagIndex>();

    const result: Array<ImageTag> = [];

    // build the list of all values in this tag's "current/" subdir,
    // used to show the images sha256, and to delete the image later.
    paths.forEach((x: OrbitObject) => {
        const match = typeof x.name === 'string' ? x.name.match(tagContentMap) : null;
        const { last_modified } = x;

        if (match && last_modified) {
            const nameTag = containerRepoName + (match[1] ?? '') + ':' + match[2];
            const hash = match[3];

            const forTag: TagIndex = tagHashes.get(nameTag) || { latest: hash, last_modified: last_modified, indexObjects: [] };
            forTag.indexObjects.push(x.name);
            if (forTag.latest !== hash && forTag.last_modified < last_modified) {
                forTag.latest = hash;
                forTag.last_modified = last_modified;
            }
            tagHashes.set(nameTag, forTag);
        }
    });

    paths.forEach(x => {
        const tagMatch = typeof x.name === 'string' ? x.name.match(fullMatch) : null;
        if (tagMatch != null) {
            const name = containerRepoName + (tagMatch[1] ?? '');
            const tag = tagMatch[2];
            const nameTag = containerRepoName + (tagMatch[1] ?? '') + ':' + tag;

            let hashes = tagHashes.get(nameTag);

            if (hashes) {
                result.push({
                    id: x.name,
                    nameTag,
                    name,
                    tag,
                    sha256: hashes.latest,
                    indexes: hashes.indexObjects,
                    last_modified: x.last_modified,
                    tagUrl: [containerRegistryNoScheme, accountId, nameTag].join('/'),
                    hashUrl: [containerRegistryNoScheme, accountId, name + '@sha256:' + hashes.latest].join('/'),
                    size: manifestSizes.get(hashes.latest) || null,
                });
            }
        }
    })

    return result;
}

export type ContainerImageTags = {
    +imageNameChoices: $ReadOnlyArray<ValueSelectChoice<string>>,
    +items: $ReadOnlyArray<ImageTag>,
    +status: typeof RC_FETCHING | typeof RC_CACHED,
}

const NO_IMAGES: $ReadOnlyArray<ImageTag> = [];

export function useContainerImageTags(container: string): ContainerImageTags {
    const accountId = useCurrentAccountId();
    const dispatch = useDispatch();
    // strip the '_ctrimages'
    const containerRepoName = container.substring(0, container.length - 10)

    // load the all the objects in .../manifests/revisions
    useEffect(() => {
        if (accountId == null) return;

        dispatch({
            type: 'ORBIT_CONTAINER_SET_PREFIX',
            payload: {
                container,
                pageSize: FLIP_MODE_THRESHOLD,
                prefix: `files/docker/registry/v2/repositories/${accountId}/${containerRepoName}/`,
                marker: '',
                useNewestFlag: false,
                useDelimiter: false,
            }
        });
    }, [accountId, container, containerRepoName, dispatch]);

    const [objects, manifestSizes] = useSelector((state: CloudGuiState) => [
        state.Orbit.containers.objects.get(container),
        state.Orbit.manifestSizes,
    ]);

    const imageTags = useMemo(
        ()=> accountId && Array.isArray(objects?.objects) ? pathsToImageTagList(accountId, containerRepoName, objects.objects, manifestSizes) : NO_IMAGES,
        [accountId, containerRepoName, objects, manifestSizes]
    );

    useEffect(() => {
        dispatch({
            type: 'ORBIT_SET_IMAGE_TAGS',
            payload: {
                container,
                imageTags,
            }
        });
    }, [container, imageTags, dispatch]);

    const imageNameChoices = useMemo(() => {
        return [
            {
                label: 'All Images',
                value: '',
            },
            // eugh... use a Set to just get a single entry per name, then map and sort them.
            ...[...new Set(imageTags.map(x => x.name))]
                .map(x => ({
                    label: x,
                    value: x,
                }))
                .sort(
                    ((a, b) => a.label.localeCompare(b.label))
                )
        ]
    }, [imageTags]);

    return {
        imageNameChoices,
        items: imageTags,
        status: objects == null || imageTags === NO_IMAGES ? RC_FETCHING : RC_CACHED,
    }
}