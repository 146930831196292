// @flow
import { Link } from 'react-router-dom';
import { displaySize, formatDate, RightArrow} from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { TextOnlySearchEditor } from '../../common/TextOnlySearch';
import { ResourceChip } from '../../element/Chip';
import { dbiSearchDef, dbiSortFields, snapshotSelectableFilter, } from './def';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, } from '../../element/Skeleton';
import { ItemSelector } from '../../element/ItemSelector';
import { useLockDeleteListActions } from '../../hoc/ListActions';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';
import { useDatabaseEngineTypes } from '../../hoc/Database';

import type { BbDatabaseSnapshot } from '../../../api/type.dbs';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';

export const DbsSnapshotTable = ({ Th: SortTh, items, search, itemSelect, status, totalCount, context }: ResourceSelectorTableProps<BbDatabaseSnapshot>): React$Node => {
    const listContext = context === 'list';
    const { engineLabel } = useDatabaseEngineTypes();

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={5}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='name'>Name</SortTh>
                        <SortTh field='database_engine'>Engine</SortTh>
                        <SortTh field='size'>Size</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((dbi: BbDatabaseSnapshot) =>
                <Tr
                    key={dbi.id}
                    {...itemSelect?.rowProps(dbi)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={dbi} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}>
                        <ResourceChip concise={true} resource={dbi} link={itemSelect?.selectedLength === 0 ? `${dbi.id}/` : null} />
                    </Td>
                    <Td>{engineLabel(dbi.database_engine, dbi.database_version)}</Td>
                    <Td>{displaySize(dbi.size)}</Td>
                    <Td>{formatDate(dbi.created_at)}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={`${dbi.id}/`}>
                                <RightArrow />
                            </Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={6} kind='database_snapshot' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={6} context={context} kind='database_snapshot' />
            </tbody>
        </Table>
    );
};

export const DbiList = (): React$Node => {
    const { deleteDialog, lockDialogs, ...selector } = useLockDeleteListActions('database_snapshot', 'snapshots', snapshotSelectableFilter);

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} snapshots?`}
                confirmString="delete"
                render={() => (
                    <div>Are you sure you want to delete these snapshots?</div>
                )
                }
            />
            <LockDialogs name={'these snapshots'} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog} count={selector.editor?.value?.length} />
            <ResourceListPage
                title="Cloud SQL Snapshots"
                kind="database_snapshot"
                context="list"
                add={false}
                table={DbsSnapshotTable}
                searchDef={dbiSearchDef}
                searchComponent={TextOnlySearchEditor}
                sortFields={dbiSortFields}
                selector={selector}
            />
        </>
    );
};
