// @flow

import type { FetchQueueAction, FetchQueueState } from './type';

const emptyState: FetchQueueState = {
    queue: {},
};

export const FetchQueueReducer = (
    state: FetchQueueState = emptyState,
    action: FetchQueueAction,
): FetchQueueState => {
    switch(action.type) {
    case 'FETCH_QUEUE_PUSH':
        return {
            ...state,
            queue: {
                ...state.queue,
                [action.payload.id]: {
                    kind: action.payload.kind,
                    id: action.payload.id,
                    timeout: (state.queue[action.payload.id] || { timeout: null }).timeout,
                }
            }
        };
    case 'FETCH_QUEUE_REMOVE':
        const nextQueue = { ...state.queue };
        delete nextQueue[action.payload.id];
        return {
            ...state,
            queue: nextQueue,
        };
    case 'FETCH_QUEUE_SET_TIMEOUT':
        if (action.payload.id in state.queue) {
            return {
                ...state,
                queue: {
                    ...state.queue,
                    [action.payload.id]: {
                        ...state.queue[action.payload.id],
                        timeout: action.payload.timeout,
                    }
                }
            };
        }
        break;
    default:
        void (action: empty);
        break;
    }

    return state;
};