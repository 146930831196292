// @flow
import { useState, useEffect } from 'react';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { historyBack } from '../../../lib/history';
import { Notice } from '../../element/Notice';
import { TextInput } from '../../element/Input';
import { ValueSelect } from '../../element/ValueSelect';
import { Textarea } from '../../element/Textarea';
import { useAuthedMutation } from '../../hoc/graphql';
import { useViewResource } from '../../hoc/ViewResource';
import gql from 'graphql-tag';
import { apiClient } from '../../../api/graphql';
import { RC_API_REQUEST, RC_CACHED } from '../../../state/resource/type';
import { toast } from 'react-toastify';
import { GB_TO_MB } from '../../element/Styled';

import type { BbAccount, BbAccountParams } from '../../../api/type.acc';
import type { FormErrors } from '../../common/lib';
import type { StateUpdateFn } from 'react-hooks';
import type { ValueSelectOptions } from  '../../element/ValueSelect';

export type LimitedResourceType = 'CIP_COUNT' | 'LBA_COUNT' | 'SQL_COUNT' | 'SRV_RAM' | 'BLK_STORAGE';

export type LimitRequestProps = {
    +kind: LimitedResourceType,
    +kindLabel: string,
    +accountId: string,
    +currentLimit: number,
    +currentLimitUnit: string,
    +submitFactor?: number,
};

type RequestParams = {
    limit: string,
    orgType: string,
    comment: string,
};

const orgTypes: ValueSelectOptions<string> = [
    { label: 'Please select...', value: '', },
    { label: 'Registered charity', value: 'CHARITY', },
    { label: 'Creative agency', value: 'CREATIVE_AGENCY', },
    { label: 'Education (e.g. school, college, university)', value: 'EDUCATION', },
    { label: 'Enterprise', value: 'ENTERPRISE', },
    { label: 'Government, NGO or Non-profit', value: 'GOV_NGO_NPO', },
    { label: 'Social Media & Gaming', value: 'SOCIAL_GAMING', },
    { label: 'Startup', value: 'STARTUP', },
    { label: 'Web development', value: 'WEB', },
    { label: 'Other', value: 'OTHER', },
];

const SEND_REQUEST_INCREASE_MUTATION = gql`
mutation rlc($accountId: ID!, $newLimit: Int!, $organisationType: OrganisationType!, $resourceType: LimitedResourceType!, $message: String!) {
    requestLimitChange(input:{
        accountId: $accountId,
        newLimit: $newLimit,
        organisationType: $organisationType,
        resourceType: $resourceType,
        message: $message,
    }) {
        message
    }
}`;

type LimitRequestFormProps = {
    +params: RequestParams,
    +setParams: StateUpdateFn<RequestParams>,
    +errors: FormErrors,
    +kindLabel: string,
    +currentLimit: number,
    +currentLimitUnit: string,
}

const LimitRequestForm = ({ params, setParams, currentLimit, currentLimitUnit, kindLabel, errors }: LimitRequestFormProps): React$Node => (
    <>
        <Notice icon='info'>
            Please complete the form below to request an increase to the current {kindLabel} limit of <b>{currentLimit} {currentLimitUnit}</b>
        </Notice>

        <TextInput
            label={`New Limit`}
            value={params.limit}
            onChange={(limit: string) => setParams({ ...params, limit })}
            postText={currentLimitUnit}
            errorText={errors.get('limit')}
            autoFocus={true}
            className='w-36'
        />

        <br/>

        <ValueSelect
            label='Organisation Type'
            selected={params.orgType}
            options={orgTypes}
            errorText={errors.get('orgType')}
            onSelect={(orgType: string) => setParams({ ...params, orgType: orgType })}
        />

        <Textarea
            label='Reason for increase'
            value={params.comment}
            errorText={errors.get('comment')}
            onChange={(comment: string) => setParams({ ...params, comment })}
            helperText='Please provide as much information as possible'
        />
    </>
);

export const LimitRequest = ({ kindLabel, kind, accountId, currentLimit, currentLimitUnit, submitFactor }: LimitRequestProps): React$Node => {
    const [params, setParams] = useState<RequestParams>({ limit: '', orgType: '', comment: '', });
    const [errors, setErrors] = useState<FormErrors>(new Map());
    const [sendBillingMessage, { loading, called, error: mutError }] = useAuthedMutation(SEND_REQUEST_INCREASE_MUTATION, { client: apiClient });

    const requestChange = () => {
        let nextErrors = new Map<string, string>();

        if (isNaN(parseFloat(params.limit))) {
            nextErrors.set('limit', 'Please enter valid numeric limit');
        } else if (parseFloat(params.limit) < currentLimit) {
            nextErrors.set('limit', 'Requested limit is lower than current limit.');
        }

        if (params.orgType === '') {
            nextErrors.set('orgType', 'Please select organisation type');
        }

        if (params.comment.trim().length < 10) {
            nextErrors.set('comment', 'Please provide a reason for the increase request');
        }

        if (nextErrors.size) {
            setErrors(nextErrors);
        } else {
            setErrors(new Map());

            sendBillingMessage({
                variables: {
                    accountId,
                    newLimit: parseFloat(params.limit) * (submitFactor || 1),
                    organisationType: params.orgType,
                    resourceType: kind,
                    message: params.comment,
                }
            });
        }
    };

    useEffect(() => {
        if (!loading && called && !mutError) {
            toast('Limit request submitted.', { type: 'success' });
            historyBack();
        }
    }, [loading, called, mutError]);

    useEffect(() => {
        if (errors || mutError) {
            const message = (errors.size ? '' : (mutError != null ? String(mutError) : null)) || '';
            if (message !== '') toast(message, { type: 'error' });
        }
    }, [errors, mutError]);

    return (
        <Panel>
            <PanelHeader title={`${kindLabel} Limit Increase`}/>
            <PanelBar>
                {!called && !loading
                    ? <LimitRequestForm
                        params={params}
                        setParams={setParams}
                        currentLimit={currentLimit}
                        currentLimitUnit={currentLimitUnit}
                        errors={errors}
                        kindLabel={kindLabel}
                    />
                    : null
                }
            </PanelBar>
            <PanelButtonBar
                cacheStatus={loading ? RC_API_REQUEST : RC_CACHED}
                primaryButton={{
                    onClick: requestChange,
                    children: 'Request Change',
                }}
                cancel={historyBack}
            />
        </Panel>
    );
};

export const RequestLimitIncrease = ({ accountId, kind }: { accountId: string, kind: string, }): React$Node => {
    const { item: account } = useViewResource<BbAccount, BbAccountParams>('account', accountId);

    if (account == null) {
        return <div>Loading</div>;
    }

    switch(kind) {
    case 'server': return (
        <LimitRequest
            accountId={accountId}
            kind='SRV_RAM'
            kindLabel='Cloud Server'
            currentLimit={account.ram_limit / GB_TO_MB}
            currentLimitUnit='GB'
            submitFactor={GB_TO_MB}
        />
    );
    case 'database_server': return (
        <LimitRequest
            accountId={accountId}
            kind='SQL_COUNT'
            kindLabel='Cloud SQL'
            currentLimit={account.dbs_ram_limit / GB_TO_MB}
            currentLimitUnit='GB'
            submitFactor={GB_TO_MB}
        />
    );
    case 'cloud_ip': return <LimitRequest accountId={accountId} kind={'CIP_COUNT'} kindLabel={'Cloud IP'} currentLimit={account.cloud_ips_limit} currentLimitUnit={''} />;
    case 'load_balancer': return <LimitRequest accountId={accountId} kind={'LBA_COUNT'} kindLabel={'Load Balancer'} currentLimit={account.load_balancers_limit} currentLimitUnit={''} />;
    case 'block_storage': return (
        <LimitRequest
            accountId={accountId}
            kind='BLK_STORAGE'
            kindLabel='Block Storage'
            currentLimit={account.block_storage_limit / GB_TO_MB}
            currentLimitUnit='GB'
            submitFactor={GB_TO_MB}
        />
    );
    case '':
    default:
        return <div>Unknown resource kind</div>;
    }
};