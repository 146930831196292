// @flow
import gql from 'graphql-tag';
import { cpuPercentConfig, interfaceRatesConfig, } from './lib';
import { mapSeries, ticksFromRange, RANGE_QUERY } from '../../hoc/Metrics';
import { MetricsPanel } from './MetricsPanel';
import numeral from "numeral";

import type { ChartInputs, DbsVolIoMetrics, DiskPercentMetrics, MemoryPercentMetrics } from './types';
import type { ResourceAreaChartConfig } from "./ResourceAreaChart";

type DbsMetricsProps = {
    id: string,
}

const CPU_PERCENT = cpuPercentConfig('databaseServer');
const NETWORK = interfaceRatesConfig('databaseServer', 'Cloud SQL Instance');

const DISK_IO: ResourceAreaChartConfig<DbsVolIoMetrics> = {
    title: 'Disk IO',
    measurement: 'IOPS',
    tooltip: 'Total aggregated read and write operations expressed in IOPS (input/output operations per second)',
    colorOffset: 3,

    yAxisFormatter: (v) => numeral(v).format('0.[00]'),

    query: {
        query: gql`
            query dbsIopsStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    databaseServer(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        volumes {
                            readIoRate {
                                timestamps
                                values
                            }
                            writeIoRate {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: DbsVolIoMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.databaseServer.range,
                ['s0', raw.metrics.databaseServer.volumes.readIoRate],
                ['s1', raw.metrics.databaseServer.volumes.writeIoRate],
            );

            return {
                data: [
                    [`Read`, `Write`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
};

const MEMORY_PERCENT: ResourceAreaChartConfig<MemoryPercentMetrics> = {
    title: 'RAM Usage',
    tooltip: 'Utilisation of total available RAM expressed as a percentage',
    colorOffset: 1,
    yAxisFormatter: (v) => numeral(v).format('0.[00]') + '%',
    query: {
        query: gql`
            query dbsMemoryPercentStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    databaseServer(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        memoryUsage {
                            percent {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: MemoryPercentMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.databaseServer.range,
                ['s0', raw.metrics.databaseServer.memoryUsage.percent]
            );

            return {
                data: [
                    [`RAM Usage (%)`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
};

const DISK_USAGE_PERCENT: ResourceAreaChartConfig<DiskPercentMetrics> = {
    title: 'Disk Usage',
    tooltip: 'Utilisation of total available disk space expressed as a percentage',
    colorOffset: 3,
    yAxisFormatter: (v) => numeral(v).format('0.[00]') + '%',
    query: {
        query: gql`
            query dbsDiskPercentStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    databaseServer(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        diskUsage {
                            percent {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: DiskPercentMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.databaseServer.range,
                ['s0', raw.metrics.databaseServer.diskUsage.percent]
            );

            return {
                data: [
                    [`Disk Space Usage (%)`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
};

const CHARTS = [CPU_PERCENT, MEMORY_PERCENT, NETWORK, DISK_USAGE_PERCENT, DISK_IO];

export const DbsMetrics = ({ id }: DbsMetricsProps): React$Node => {
    return (
        <MetricsPanel id={id} charts={CHARTS} />
    );
};