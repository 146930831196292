// @flow
import { PanelResourceCreateButtons, PanelBar, Panel, PanelHeader, } from '../../element/Panel';
import { Button, } from '../../element/Button';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { RC_API_REQUEST, } from '../../../state/resource/type';
import { BlockStorageLimitWrapper } from './BlockStorageLimitWrapper';
import { TextInput } from '../../element/Input';
import { GB_TO_MB, formatMegabytesSize } from '../../element/Styled';
import { PerGigPrice } from '../../element/PerGigPrice';
import { useResourceRoutes } from '../../../lib/history';
import { Notice } from '../../element/Notice';
import { Link } from 'react-router-dom';
import { RadioGroup } from '../../element/RadioGroup';
import { FILESYSTEM_TYPE_CHOICES } from './edit/lib';
import { useCreateVolumeForm } from '../../hoc/Volumes';

import type { Match } from 'react-router-dom';
import { LABELS } from '../../element/ResourceLabels';

export const VolCreate = ({ match }: { match: Match }): React$Node => {
    const {
              create,
              name,
              size,
              filesystem_type,
              filesystem_label,
              validateAndCreate,
              availableBelowMin,
              availableBlockStorage,
              parsedSize,
              createDisabled,

          } = useCreateVolumeForm(true);
    const getRoute = useResourceRoutes();

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.volume.listTitle}
            match={match}
            dialog={null}
            view={
                <>
                    <Panel>
                        <PanelHeader title='New Volume' />
                        <PanelBar>
                            <div className='flex flex-col space-y-8'>

                            <TextInput
                                label='Name'
                                autoFocus={true}
                                optional={true}
                                onChange={(nextName) => name.setValue(nextName)}
                                value={name.value}
                                errorText={name.errors}
                                focusSelectAll={true}
                            />

                            <TextInput
                                label='Size'
                                onChange={(nextSize) => size.setValue(nextSize)}
                                value={size.value}
                                errorText={size.errors}
                                postText='GB'
                                className='w-32'
                                focusSelectAll={true}
                                hint={`Volumes must be at least ${window.cgrConfig.VOLUME_LIMITS.min}GB and can be increased later using the Resize Volume option`}
                            />

                            {availableBelowMin
                                ? <Notice type='warning' icon='warning-fill'>
                                    The minimum Volume size is <b>{window.cgrConfig.VOLUME_LIMITS.min}GB</b> but you only
                                    have <b>{formatMegabytesSize(availableBlockStorage)}</b> remaining of your current Block Storage account limit<br/>
                                    <Link to={getRoute('limit', 'block_storage')}>Request an increase here</Link>
                                </Notice>
                                : null
                            }

                            <div className='bg-gray-100a rounded-lg px-8 py-6'>

                                <h4 className='mb-4'>Auto-format this volume?</h4>

                                <RadioGroup
                                    onSelect={(nextType) => filesystem_type.setValue(nextType)}
                                    selected={filesystem_type.value}
                                    options={FILESYSTEM_TYPE_CHOICES}
                                    errorText={filesystem_type.errors}
                                />

                                <div hidden={filesystem_type.value === 'none'}>
                                    <TextInput
                                        label='Label'
                                        disabled={filesystem_type.value === 'none'}
                                        onChange={(nextLabel) => filesystem_label.setValue(nextLabel)}
                                        value={filesystem_label.value == null ? '' : filesystem_label.value}
                                        errorText={filesystem_label.errors}
                                        optional={true}
                                        focusSelectAll={true}
                                        hint='Provide an optional 12 character label which can be used to reference the newly created filesystem within the cloud server'
                                        className='w-48 mt-8'
                                    />
                                </div>
                            </div>
                            <PerGigPrice id='blockstorage' label='Volume'/>
                            </div>


                        </PanelBar>
                        <PanelResourceCreateButtons
                            leftContent={
                                <Button
                                    kind='bare'
                                    preIcon='info-fill'
                                >
                                    <a href='https://www.brightbox.com/docs/guides/volumes/create-volume/' target='_blank' rel='noopener noreferrer'>Creating Volumes</a>
                                </Button>
                            }
                        >
                            <BlockStorageLimitWrapper availableBlockStorage={availableBlockStorage / GB_TO_MB} size={parsedSize}>
                                <Button
                                    kind="primary"
                                    onClick={validateAndCreate}
                                    state={create.status === RC_API_REQUEST ? 'loading' : ''}
                                    disabled={createDisabled}
                                >
                                    Create Volume
                                </Button>
                            </BlockStorageLimitWrapper>
                        </PanelResourceCreateButtons>
                    </Panel>
                </>
            }
            editors={[]}
        />
    );
};


