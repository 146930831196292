// @flow


import type { BbApiClient } from './type.cli';
import type { Bytes } from './type.units';

export type BbOrbitContainerParams = {
    +name: string,
};

export type BbRawApiOrbitContainer = {
    name: string,
    count: number,
    bytes: Bytes,
    last_modified: string,
};

export type BbOrbitContainer = {
    id: string,
    name: string,
    count: number,
    bytes: Bytes,
    last_modified: Date,
};

export type BbRawApiOrbitSubdir = { subdir: string };

export type BbRawApiOrbitObject = {
    name: string,
    content_type: string,
    hash: string,
    bytes: Bytes,
    last_modified: string,
};

export type BbRawApiOrbitEntry = BbRawApiOrbitSubdir | BbRawApiOrbitObject;

export type ContainerAccess =
    {
        id: string,
        type: 'api',
        accountId: string,
        cliId: string,
        read: boolean,
        write: boolean,
        client?: BbApiClient,
        full?: boolean,
    }
    | {
        id: string,
        type: 'team',
    }
    | {
        id: string,
        type: 'http',
        referrer: string,
        read: boolean,
    }
    | {
        id: string,
        type: 'any-referrer',
        read: boolean,
    }
    | {
        id: string,
        type: 'other',
        read: boolean,
        write: boolean,
    }
;

export type ContainerAccessType = $PropertyType<ContainerAccess, 'type'>

export type OrbitContainerMeta = {
    access: Array<ContainerAccess>,
    rlistings: {
        read: boolean,
        write: boolean,
    },
    web: {
        listings: ?string,
        css: ?string,
        index: ?string,
        error: ?string,
    },
    tempUrlKey1: ?string,
    tempUrlKey2: ?string,
    archive: {
        history: ?string,
        versions: ?string,
    },
    quota: {
        bytes: ?Bytes,
        count: ?number,
    },
}

export opaque type ObjectId: string = string;

export function getObjectId(name: string, content_type: string): ObjectId {
    if (content_type === 'application/directory') return name + '/';

    return name;
}

// hack here - 'subdir' prop always has the trailing "/", eg subdir: "a dir/"
// so just pass an empty content type...
export function getSubdirObjectId(subdir: string): ObjectId {
    return subdir;
}

export function isDirId(id: ObjectId | string): boolean {
    return id.substr(-1) === '/';
}

export function getObjectName(id: ObjectId|string): string {
    return isDirId(id) ? id.substr(0, id.length -1) : id;
}

/**
 * @desc adapted result from a raw object.
 *       We manipulate the raw name value from orbit into a few differently
 *       formatted versions for different uses - so we have to be careful
 *       to use the right one at the right time.
 *
 * @property string name     is the raw value we received from orbit
 * @property string id       is the raw name for non-dir objects, or name + "/" for dirs
 * @property string basename basename("a/file/thing") = "thing"
 * @property string path     is "/" + name
 */
export type OrbitObject = {
    ...BbRawApiOrbitObject,
    id: ObjectId,           // the full object name
    basename: string,     // "an/orbit/object" => "object"
    absolute_url: string, // 'https://..orbit../containername/an/orbit/object"
    last_modified: ?Date,
    is_resource: boolean,
}

export type OrbitContainerHeaders = {
    'x-container-read'?: string,
    'x-container-write'?: string,

    'x-container-meta-web-error'?: string,
    'x-container-meta-web-index'?: string,
    'x-container-meta-web-listings'?: string,
    'x-container-meta-web-listings-css'?: string,
    'x-container-meta-temp-url-key'?: string,
    'x-container-meta-temp-url-key-2'?: string,
    'x-versions-location'?: string,
    'x-history-location'?: string,
    'x-container-meta-quota-bytes'?: string,
    'x-container-meta-quota-count'?: string,

    'x-remove-container-meta-web-error'?: string,
    'x-remove-container-meta-web-index'?: string,
    'x-remove-container-meta-web-listings'?: string,
    'x-remove-container-meta-web-listings-css'?: string,
    'x-remove-container-meta-temp-url-key'?: string,
    'x-remove-container-meta-temp-url-key-2'?: string,
    'x-remove-versions-location'?: string,
    'x-remove-history-location'?: string,
    'x-remove-container-meta-quota-bytes'?: string,
    'x-remove-container-meta-quota-count'?: string,
}

export type OrbitAccountHeaders = {
    'x-account-meta-temp-url-key'?: string,
    'x-account-meta-temp-url-key-2'?: string,
}

export type OrbitAccountMeta = {
    tempUrlKey1: ?string,
    tempUrlKey2: ?string,
    bytes: ?number,
}