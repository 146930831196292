// @flow
import { useContext, } from 'react';
import { AccountLimits } from './AccountLimits';
import { AccountDetails } from './AccountDetails';
import { Route, } from 'react-router-dom';
import { useViewResource } from '../../hoc/ViewResource';
import { ApiClientList } from '../api_client/ApiClientList';
import { Switch } from 'react-router-dom';
import { ApiClientCreate } from '../api_client/ApiClientCreate';
import { ApiClientView } from '../api_client/ApiClientView';
import { RequestLimitIncrease } from './LimitRequest';
import { animationRouteContext } from '../../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useCurrentAccountId } from '../../hoc/lib';
import { Panel, } from '../../element/Panel';
import { useTabs } from '../../element/UiStateHooks';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { NameEditPanel } from '../../element/NameEditPanel';
import { useResourceEditorModal } from '../../common/Editor';
import { AccountAddressEdit } from './edit/AccountAddressEdit';
import { AccountTfaRequirementEdit } from './edit/AccountTfaRequirementEdit';
import { TextSetting } from '../../element/Setting/TextSetting';
import { useOrbitAccountMeta, useOrbitAccountMetaEditor } from '../../hoc/Orbit';
import { AccountTempUrlKeysEdit } from './edit/AccountTempUrlKeysEdit';

import type { Match } from 'react-router';
import type { BbAccount, BbAccountParams } from '../../../api/type.acc';
import type { EditorDef } from '../../common/Editor';

export const Settings = ({ match: accountMatch }: { match: Match }): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route
                    path={`${accountMatch.path}cli/add`}
                    component={ApiClientCreate}
                />
                <Route
                    path={`${accountMatch.path}cli/:id/`}
                    render={({ match }) => <ApiClientView id={match.params.id || ''} match={match}/>}
                />
                <Route
                    path={accountMatch.path}
                    render={({ match }) => <AccountSettings match={match}/>}
                />
            </Switch>
        </AnimatePresence>
    );
};

const hashMap = new Map<string, number>([
    ['#settings', 0],
    ['#increase', 1],
    ['#cli', 2],
]);

const nameDef: EditorDef<BbAccount, string, BbAccountParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbAccount) => res.name,
    onValidate: null,
};

const tfaDef: EditorDef<BbAccount, $Shape<BbAccountParams>, BbAccountParams, null> = {
    emptyErrors: null,
    editUri: 'tfa_requirement',
    onEdit: (account: BbAccount) => ({
        tfa_required: account.tfa_required,
    }),
    onValidate: (resource, data: $Shape<BbAccountParams>) => [null, data],
};

const vatDef: EditorDef<BbAccount, string, BbAccountParams, null> = {
    emptyErrors: null,
    editUri: 'vat_registration_number',
    onEdit: (res: BbAccount) => res.vat_registration_number,
    onValidate: null,
};

const addressDef: EditorDef<BbAccount, $Shape<BbAccountParams>, BbAccountParams, null> = {
    emptyErrors: null,
    editUri: 'address',
    onEdit: (account: BbAccount) => ({
        address_1: account.address_1,
        address_2: account.address_2,
        city: account.city,
        county: account.county,
        postcode: account.postcode,
        country_name: account.country_name,
        country_code: account.country_code,
    }),
    onValidate: (resource, data: $Shape<BbAccountParams>) => [null, data],
};

const AccountSettings = ({ match }: { match: Match }): React$Node => {
    const id = useCurrentAccountId() || '';
    const resource = useViewResource<BbAccount, BbAccountParams>('account', id || '');
    const accountMeta = useOrbitAccountMeta();
    const { item } = resource;
    const tabs = useTabs(hashMap);

    const name = useResourceEditorModal(resource, nameDef, match.path);
    const address = useResourceEditorModal(resource, addressDef, match.path);
    const tfa_required = useResourceEditorModal(resource, tfaDef, match.path);
    const vat = useResourceEditorModal(resource, vatDef, match.path);
    const tempKeyEditor = useOrbitAccountMetaEditor(accountMeta, match.path);

    return (
        <ResourceAddViewRoute
            match={match}
            view={
                <Panel>
                    <Tabs {...tabs}>
                        <TabList>
                            <Tab>Account Settings</Tab>
                            <Tab>Account Limits</Tab>
                            <Tab>API Access</Tab>
                        </TabList>
                        <TabPanel>
                            <AccountDetails
                                account={item}
                                accountMeta={accountMeta}
                                tempKeyEditUri={tempKeyEditor.editUri}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AccountLimits
                                account={item}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ApiClientList/>
                        </TabPanel>
                    </Tabs>
                </Panel>
            }
            editors={[
                { editor: name, render: () => <NameEditPanel editor={name}/> },
                { editor: address, component: AccountAddressEdit },
                { editor: { editUri: 'increase/cloud_ip', onSave: () => void 0, }, render: () => <RequestLimitIncrease accountId={id} kind={'cloud_ip'}/> },
                { editor: { editUri: 'increase/server', onSave: () => void 0, }, render: () => <RequestLimitIncrease accountId={id} kind={'server'}/> },
                { editor: { editUri: 'increase/database_server', onSave: () => void 0, }, render: () => <RequestLimitIncrease accountId={id} kind={'database_server'}/> },
                { editor: { editUri: 'increase/load_balancer', onSave: () => void 0, }, render: () => <RequestLimitIncrease accountId={id} kind={'load_balancer'}/> },
                { editor: { editUri: 'increase/block_storage', onSave: () => void 0, }, render: () => <RequestLimitIncrease accountId={id} kind={'block_storage'}/> },
                { editor: vat, render: () => <TextSetting title='EU VAT Number' label='VAT Registration Number' editor={vat} /> },
                { editor: tfa_required, component: AccountTfaRequirementEdit, },
                { editor: tempKeyEditor, component: AccountTempUrlKeysEdit, },
            ]}
        />
    );
};
