// @flow

import axios from 'axios';
import { authUrl, twoFaUrl } from './url';

import type { Dispatch } from 'redux';

type ConnectionValues = {
    orbitToken: string,
    accountId: string,
};

const devUrlOverrides = new Map<string, Object>();

export function addDevUrlOverride(url: string, res: Object) {
    devUrlOverrides.set(url, res);
}

const emptyConnection: ConnectionValues = Object.freeze({
    orbitToken: '',
    accountId: '',
});
let interceptor = null;
let connection: ConnectionValues = emptyConnection;

export function setAccountId(accountId: string) {
    connection = { ...connection, accountId };
}

export function setOrbitToken(orbitToken: string) {
    connection = { ...connection, orbitToken };
}

export async function clearConnection(): Promise<void> {
    connection = emptyConnection;
    await request({
        url: authUrl,
        method: 'DELETE',
    });
}

export function setDispatch(dispatch: Dispatch<any>) {
    if (interceptor) {
        axios.interceptors.response.eject(interceptor);
        interceptor = null;
    }
    const handler = (response, error) => {
        if (
            error
            && error.response
            && error.response.status === 401
            // $FlowFixMe this property is in one of these return types
            && error.request.responseURL !== authUrl
            // $FlowFixMe this property is in one of these return types
            && error.request.responseURL !== twoFaUrl
        ) {
            dispatch({ type: 'AUTH_LOGOUT', payload: { clearState: false } });
        }

        if (!error && response && response.headers?.['x-jwt-expires-in']) {
            // the JWT expiry is always amended by API requests, so always just
            // bump the logout timer
            dispatch({ type: 'AUTH_COOKIE_RESET_TIMEOUT', payload: { delay: 1000 * parseInt(response.headers['x-jwt-expires-in']) } });
            // dispatch({ type: 'AUTH_COOKIE_RESET_TIMEOUT', payload: { delay: 2000 } });
        }

        return error ? Promise.reject(error) : Promise.resolve(response);
    };
    interceptor = axios.interceptors.response.use(
        (response) => handler(response, null),
        (error) => handler(null, error)
    );
}

export function request(config: Object = {}): * {
    if (process.env.NODE_ENV === 'development' && devUrlOverrides.has(config.url)) {
        return Promise.resolve(devUrlOverrides.get(config.url));
    }

    let urlParams = [];
    if (config.accountId === false) {
        // we want to explicitly exclude account id - so noop.
    } else if (config.accountId) {
        urlParams.push('account_id=' + config.accountId);
    } else if (connection && connection.accountId) {
        urlParams.push('account_id=' + connection.accountId);
    }
    // this is a case of amazing javascript comparison -
    // true and false are both '!=' null, even while null alone is 'false'
    if (config.nested != null) {
        urlParams.push('nested=' + config.nested);
    }

    if (config.metadata) {
        urlParams.push('metadata=true');
    }

    return axios({
        ...config,
        url: config.url + '?' + urlParams.join('&'),
        headers: {
            'X-Requested-With': 'Gui',
            ...(config.headers || {})
        },
        withCredentials: true,
    });
}

export function orbit(config: Object = {}): * {
    return axios({
        ...config,
        headers: {
            ...(connection.orbitToken ? { 'X-Auth-Token': connection.orbitToken } : {}),
            'cache-control': 'no-cache',
            ...(config.headers || {})
        },
    });
}

export function login(config: Object): * {
    return axios({
        ...config,
        withCredentials: true,
    });
}
