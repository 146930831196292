// @flow
import { Panel, PanelBar, PanelHeader, PanelSearchBar } from "../../element/Panel";
import { useContainerImageTags } from "../../hoc/orbit/ContainerRegistry";
import { Table, Td, Th, Tr, TrHeader } from "../../element/Table";
import { DockerImageChip, } from "../../element/Chip";
import {formatDateTime, formatOrbitSize,} from "../../element/Styled";
import { PagerBar } from "../../element/PagerBar";
import { DropdownButton } from "../../element/DropdownButton";
import { Button, CopyButton, CopyLink, } from "../../element/Button";
import { Dialog, useDialog } from "../../element/Dialog";
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from "../../element/Skeleton";
import { RC_CACHED } from "../../../state/resource/type";
import { NoResourcesTr } from "../../element/NoResourceMessages";
import { ContainerRegistrySearchEditor } from "./ContainerRegistrySearchEditor";
import { ItemSelector } from "../../element/ItemSelector";
import { DeleteDialog, useDeleteDialog } from '../../common/CommonDialogs';
import { Notice } from '../../element/Notice';
import { useImageTagList } from './def';

import type { ImageTag } from "../../hoc/orbit/ContainerRegistry";

type ContainerRegistryImageListProps = {
    container: string;
}

function GarbageCollectionNotice() {
    return (
        <Notice type='warning' icon='info-fill'>
            <p>Deleting a tag will remove it from the registry, but won't
                delete the underlying layer data.</p>
        </Notice>
    );
}

export const ContainerRegistryImageList = ({container}: ContainerRegistryImageListProps): React$Node => {
    const imageTags = useContainerImageTags(container);
    const { status, imageNameChoices, } = imageTags;
    const {
        selectOptions,
        multiDeleteDialog,

        items,
        Th: SortTh,
        pager,
        searchEditor,

        itemSelect,
        
        deleteTag
    } = useImageTagList(container, imageTags);
    const { editor: selectionEditor } = selectOptions;

    const imagePullDialog = useDialog<ImageTag>([]);
    const [, deleteDialog] = useDeleteDialog(
        true,
        (tag: ?ImageTag) => {
            if (tag) deleteTag(tag);
        },
    )

    return (
        <Panel>
            <Dialog
                title='Docker pull command for image'
                type='wide'
                dialog={imagePullDialog}
                render={
                    (image) => {
                        if (!image) return null;

                        const pullCmd = `docker pull ${image.tagUrl}`;

                        return (
                            <p>
                                <code className='bg-gray-200 block text-xs p-4 rounded-md leading-relaxed break-words'>
                                    <span className='text-gray-600'>$</span> <span
                                    className='select-all mr-1'>{pullCmd}</span> <CopyButton size='xs' kind='bare' value={pullCmd}/>
                                    <br/><br/>
                                </code>
                            </p>
                        );
                    }
                }
            />
            <DeleteDialog
                title={() => `Delete ${selectionEditor?.value?.length || 'these'} image tags?`}
                name=''
                dialog={multiDeleteDialog}
                confirmString='delete'
                render={() =>
                    <>
                        <p>Are you sure you want to delete these {selectionEditor.value?.length || ''} image tags?</p>
                        <GarbageCollectionNotice />
                    </>
                }
            />
            <DeleteDialog
                title='Delete this image tag?'
                name={(tag) => tag?.name || ''}
                dialog={deleteDialog}
                render={() =>
                    <>
                        <p>Are you sure you want to delete this image tag?</p>
                        <GarbageCollectionNotice />
                    </>
                }
            />

            <PanelHeader title={'Image tags'}/>
            <PanelSearchBar search={<ContainerRegistrySearchEditor editor={searchEditor} imageNameChoices={imageNameChoices} />} />
            <PanelBar padding={false}>
                <Table>
                    <thead>
                    <TrHeader
                        context='list'
                        actionColSpan={5}
                        itemSelect={itemSelect}
                        hasItems={items.length > 0}
                        columns={
                            <>
                                <SortTh field='id'>Name</SortTh>
                                <SortTh field='sha256'>Digest</SortTh>
                                <SortTh field='size'>Size</SortTh>
                                <SortTh field='last_modified'>Pushed at</SortTh>
                                <Th actions={true}>&nbsp;</Th>
                            </>
                        }
                    />
                    </thead>
                    <tbody>
                    {items.map((image: ImageTag) =>
                        <Tr
                            key={image.id}
                            {...itemSelect?.rowProps(image)}
                        >
                             <Td selector={true}>
                                <ItemSelector item={image} itemSelect={itemSelect} listContext={true} />
                            </Td>
                            <Td resourceName={true}>
                                <DockerImageChip imageTag={image} />
                            </Td>
                            <Td>
                                <span className='u-data--sha256 text-sm'>sha256:{image.sha256.substring(0, 12)}</span> <b className="text-gray-500 tracking-wide">&#8230;</b><CopyLink value={image.hashUrl} />
                            </Td>
                            <Td>
                                {image.size == null ? <SkeletonBar size='sm' /> : formatOrbitSize(image.size)}
                            </Td>
                            <Td>{formatDateTime(image.last_modified)}</Td>
                            <Td actions={true}>
                                <DropdownButton
                                    postIcon={'ellipsis-horizontal'}
                                    kind="tertiary"
                                    color="grey"
                                    dropdown={
                                        <>
                                            <Button
                                                size="sm" color="blue" kind="tertiary"
                                                onClick={() => imagePullDialog.show(image)}
                                            >
                                                Show Pull Command
                                            </Button>
                                            <Button
                                                size="sm" color="red" kind="tertiary"
                                                onClick={() => deleteDialog.show(image)}
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    }
                                />
                            </Td>
                        </Tr>
                    )}
                    {status !== RC_CACHED
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td selector={true}>&nbsp;</Td>
                                <Td resourceName={true}><SkeletonChip /></Td>
                                <Td><SkeletonBar size='xl' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='md' /></Td>
                                <Td actions={true}>&nbsp;</Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : null
                    }
                    {/*<NoMatchingResourceTr search={search} colSpan={6} kind='database_snapshot' />*/}
                    <NoResourcesTr status={status} totalCount={pager.unfilteredCount} colSpan={5} context='' kind='docker_image' />
                    </tbody>
                </Table>
            </PanelBar>
            <PagerBar {...pager} />
        </Panel>
    );
}