// @flow
import { createSelector } from 'reselect';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../state/Search/search';
import { TextInput } from '../element/Input';

import type { Dispatch } from 'redux';
import type { CloudGuiState } from '../../state/cloudgui';
import type { SearchIndexes } from '../../state/Search/search';
import type { SearchDefinition, SearchEditorHook, } from '../element/Search';

export const textOnlySearch = <T: { id: string, ... }>(kind: SearchIndexes): SearchDefinition<T> => {
    const { result } = getSearchSelectors({ resourceName: kind, resourceSelector });

    const getSearchTextKind = createSelector(
        [result,],
        (result) => ({
            searchMatchIds: result,
        }));

    const serverSearchText = createSearchAction(kind);

    return {
        name: kind,
        fields: [
            {
                searchFieldName: 'searchText',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<T>) => {
                    const { searchMatchIds, } = (getSearchTextKind(state): { searchMatchIds: Array<string> });
                    return searchMatchIds.findIndex(x => x === item.id) !== -1;
                },
                setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                    dispatch(serverSearchText(nextValue));
                },
            },
        ],
    };
};

type TextOnlySearchEditorProps = {
   +editor: SearchEditorHook,
};

export const TextOnlySearchEditor = ({ editor }: TextOnlySearchEditorProps): React$Node => {
    const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <TextInput
            size='sm'
            label='Filter by keyword'
            value={editor.fields.searchText.value}
            onChange={(value) => editor.setSearchValue('searchText', value)}
            preIcon={'search'}
            {...post}
        />
    );
}