// @flow

import { Dialog } from '../../element/Dialog';
import { Notice } from '../../element/Notice';

import type { OrbitAccessKeysHook } from '../../hoc/orbit/Container';
import type { MessageHook } from '../../hoc/Messages';
import type { BbOrbitContainer } from '../../../api/type.orbit';

type CreateKeysDialogProps = {
    +dialog: $PropertyType<OrbitAccessKeysHook, 'confirmKeysDialog'>,
    +messages: ?MessageHook<BbOrbitContainer>,
    +updateDelayed: boolean,
}

export const CreateKeysDialog = ({ dialog, messages, updateDelayed, }: CreateKeysDialogProps): React$Node => {
    const verbing = dialog?.data?.[0] === 'share' ? 'Sharing' : 'Downloading';

    return (
        <Dialog
            dialog={dialog}
            messages={messages}
            title='Set Temporary URL Keys?'
            forceCancelEnabled={updateDelayed}
            render={() => (
                <>
                    <p>{verbing} Orbit files via the control panel requires Temporary URL keys to be created.</p>
                    {updateDelayed
                        ? <Notice type='info' icon='info'>
                            <p>Changes to Orbit sometimes take a few seconds to occur, and we're still waiting.</p>
                        </Notice>
                        : null
                    }
                </>
            )}
        />
    );
}