// @flow
/* global analytics */

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUser, useCurrentAccount } from './component/hoc/lib';
import type { BbUser } from './api/type';
import type { BbAccount } from './api/type.acc';

// triggers the various tracking events in segment.
// it's disabled in dev by the param in config.js being named SEGMENT_KEY_DISABLED.
// rename it to SEGMENT_KEY to re-enable in dev.
export function useSegment() {
    const location = useLocation();
    const { pathname } = location;
    const user: ?BbUser = useCurrentUser();
    const account: ?BbAccount = useCurrentAccount();

    const accountId = account?.id || '';

    useEffect(() => {
        switch(pathname) {
        case '/signup': analytics.track('Viewed Signup Page'); break;
        case `/accounts/${accountId}/settings/close/`: analytics.track('Viewed Close Account Page'); break;

        default:
            // no analytics
            break;
        }
    }, [pathname, accountId]);

    useEffect(() => {
        if (user != null && user.created_at) {
                // user.created_at check skips the 'collected' repr we fetch first..
                analytics.identify(user.id, {
                    'name': user.name,
                    'email': user.email_address,
                    'created_at': user.created_at,
                    'has_ssh_key': user.ssh_key !== null && user.ssh_key !== '',
                    'accounts_owned': user.accounts.length,
                });
        }
    }, [user]);

    useEffect(() => {
        if (account != null) {
            analytics.group(account.id, {
                name: account.name,
                created_at: account.created_at,
                current_state: account.status,
            });
        }
    }, [account]);
}