// @flow

import { createContext, useEffect, useState, useMemo } from 'react';
import gql from 'graphql-tag';
import { processStripe } from '../section/account/lib';
import { to } from 'await-to-js';
import { GENERIC_ERROR_MESSAGE } from '../section/account/edit/StripeAddCard';
import { useAuthedMutation, useResourceQuery, isNetworkRequestInFlight, isNetworkRequestError } from './graphql';
import { apiClient } from '../../api/graphql';
import { useCurrentAccountId, useCurrentAccount } from './lib';
import { useMessages } from './Messages';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS } from '../../state/resource/type';
import { useDispatch } from 'react-redux';
import { useElements, CardElement } from '@stripe/react-stripe-js';
import numeral from 'numeral';
import set from 'date-fns/set';
import add from 'date-fns/add';
import format from 'date-fns/format';
import { parseIsoDate } from '../../lib/date';
import { formatDate } from '../element/Styled';

import type { QueryResult } from '@apollo/react-hooks';
import type { Dispatch } from 'redux';
import type { MessageAction } from '../../state/Message/type';
import type { BbAccount, BbCollectedAccount } from '../../api/type.acc';
import type { MessageHook } from './Messages';
import type { NetworkStatus } from '@apollo/react-hooks';

export type PaymentCardDetails = {
    +name: string,
    +type: string,
    +number: string,
    +expiresOn: string,
}

export type BbInvoice = {
    +id: string,
    +number: string,
    +createdOn: string,
    +dueOn: string,
    +downloadUrl: string,
    +status: string,
    +balance: string,
    +total: string,
};

export type BillingItem = {
    +amount: string,
    +name: string,
    +label: string,
    +price: string,
}

export type BillingCategory = {
    +name: string,
    +items: $ReadOnlyArray<BillingItem>,
}

export type BillingActivity = {
    +totalAmount: number,
    +categories: $ReadOnlyArray<BillingCategory>,
};

export type AccountBillingData = {
    +account: {
        +currentBalance: string,
        +invoices: ?$ReadOnlyArray<BbInvoice>,
        +paymentCard: ?PaymentCardDetails,
        +billingActivity: ?BillingActivity,
    },
}

export type AccountBillingDataVars = {
    +accountId: ?string,
    +startDate: string,
    +endDate: string,
}

export const SIGNUP_CREDIT_LABEL = 'signup-credit';

export const UPDATE_BILLING_METHOD_MUTATION: any = gql`
mutation ubm($accountId: ID!, $token: BillingToken!) {
    updateBillingMethod(input:{
        accountId: $accountId,
        token: $token
    }) {
        clientSecret
        isComplete
    }
}`;

export const ACCOUNT_BILLING_DATA_QUERY: any = gql`
query AccountBillingData ($accountId: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
  account(accountId: $accountId){
    currentBalance
    invoices(startDate: $startDate, endDate: $endDate) {
      id
      createdOn
      dueOn
      downloadUrl
      status
      balance
      total
      number
    }
    paymentCard {
      id
      name 
      number
      type
      expiresOn
    }
    billingActivity {
      totalAmount
      categories {
        items {
          amount
          name
          label
          price
        }
        name
      }
    }
  }
}`;


export type CardSaveStatus = typeof RC_SUCCESS | typeof RC_ERROR;
type UpdateBillingMethodHook = {
    handleSubmit: (accountId: string, event: ?SyntheticEvent<any>) => Promise<CardSaveStatus>,
    name: string,
    setName: ((string => string) | string) => void,
    messages: MessageHook<BbAccount>,
}

export const MESSAGES_ID = 'update_billing_method';

export const useUpdateBillingMethod = (stripe: any): UpdateBillingMethodHook => {
    const [updateBillingMethod] = useAuthedMutation(UPDATE_BILLING_METHOD_MUTATION, {
        client: apiClient,
        refetchQueries: ['AccountBillingData', ],
    });
    const [name, setName] = useState<string>('');
    const messages = useMessages<BbAccount>(MESSAGES_ID);
    const dispatch = useDispatch<Dispatch<MessageAction>>();
    const elements = useElements();

    const handleSubmit = async (accountId: string): Promise<CardSaveStatus> => {

        if (name === '') {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_ERROR,
                    messages: ['Please enter the cardholder name'],
                }
            });
            return RC_ERROR;
        }
        const paymentMeta = { billing_details: { name } };

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: MESSAGES_ID,
                status: RC_API_REQUEST,
            }
        });

        let [err, createResult] = await to(stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            ...paymentMeta,
        }));

        if (err) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_ERROR,
                    messages: [err.message],
                }
            });
            return RC_ERROR;
        }
        if (createResult && createResult.error) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_ERROR,
                    messages: [createResult.error.message],
                }
            });
            return RC_ERROR;
        }
        if (!createResult || !createResult.paymentMethod || !createResult.paymentMethod.id) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_ERROR,
                    messages: [GENERIC_ERROR_MESSAGE],
                }
            });
            return RC_ERROR;
        }

        const variables = {
            token: createResult.paymentMethod.id,
            accountId
        };

        const result = await processStripe(updateBillingMethod, 'updateBillingMethod', variables, stripe);

        if (result.success) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_SUCCESS,
                }
            });
            return RC_SUCCESS;
        }
        if (result.error) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: MESSAGES_ID,
                    status: RC_ERROR,
                    messages: [result.error],
                }
            });
        }
        return RC_ERROR;
    };

    return {
        handleSubmit,
        name, setName,
        messages,
    };
};

export const CHARGE_BILLING_METHOD_MUTATION: any = gql`
mutation cbm($accountId: ID!, $amount: String!, $token: BillingToken) {
    chargeBillingMethod(input:{
        accountId: $accountId,
        amount: $amount,
        token: $token
    }) {
        isComplete
        clientSecret
    }
}`;

export type BillingQueryResult = QueryResult<AccountBillingData, AccountBillingDataVars>;

export const accountBillingContext: React$Context<[?BbCollectedAccount, ?BillingQueryResult]> = createContext([null, null]);
accountBillingContext.displayName = 'AccountBilling';

export function useAccountBillingData(): BillingQueryResult  {
    const accountId = useCurrentAccountId();

    const [fetch, hook] = useResourceQuery<AccountBillingData, AccountBillingDataVars>(
        ACCOUNT_BILLING_DATA_QUERY, {}
    );

    useEffect(() => {
        const endDate = format(add(new Date(), { months: 2 }), 'yyyy-MM-dd');

        if (accountId != null) {
            fetch({
                variables: {
                    accountId,
                    startDate: '2019-01-01',
                    endDate,
                }
            });
        }
    }, [fetch, accountId]);

    return hook;
}

export function useTrialCreditSummary(categories: ?$ReadOnlyArray<BillingCategory>): ?[number, number, number] {
    const summary = useMemo((): ?[typeof numeral, typeof numeral] => {
        const discounts = categories?.find(category => category.name === 'Discounts');

        if (discounts) {
            return discounts.items.filter(x => x.label === SIGNUP_CREDIT_LABEL).reduce(([amount, price], i) =>
                    [amount.add(i.amount), price.add(i.price),],
                [numeral(0), numeral(0)]
            );
        }

        return null;
    }, [categories]);

    if (summary == null) return null;

    const amount = Math.abs(summary[0].value());
    const price = Math.abs(summary[1].value());

    const remainingAmount = price - amount;

    return [amount, price, remainingAmount];
}

export function useAccountNextBillingDate(data: ?AccountBillingData, networkStatus: NetworkStatus | number): ?string {
    const account = useCurrentAccount();
    const latestInvoice: ?BbInvoice = data?.account?.invoices?.[0];

    return useMemo(() => {
        const firstInvoiceDate: ?Date = account ? account.created_at : null;
        const latestDate: ?Date = latestInvoice ? parseIsoDate(latestInvoice.createdOn) : null;
        const isFirst = latestDate == null;

        if (isNetworkRequestInFlight(networkStatus) || isNetworkRequestError(networkStatus)) {
            return null;
        }

        const nextBillingDate= set(add(latestDate || firstInvoiceDate || new Date(), { months: 1 }), { hours: 0, minutes: 0, seconds: 0 });

        return `${isFirst ? "First" : "Next"} billing date ${formatDate(nextBillingDate)}`
    }, [account, latestInvoice, networkStatus]);
}