// @flow
import { Spinner } from './Spinner';
import { Checkbox } from './Checkbox';

import type { ItemSelectHook, ItemSelectTypes } from '../common/ResourceSelector';

type ItemSelectorProps<C: ItemSelectTypes> = {
    +item: C,
    +itemSelect: ?ItemSelectHook<C>,
    +listContext: boolean,
    +forceSelected?: ?boolean,
}

export function ItemSelector<C: ItemSelectTypes>({ item, itemSelect, listContext, forceSelected, ...rest }: ItemSelectorProps<C>): React$Node {
    return (
        itemSelect && itemSelect.selectable(item)
            ? (itemSelect.multi?.isPending(item)
                ? <Spinner size="sm"/>
                : <Checkbox
                    color="blue"
                    checked={itemSelect.isSelected(item) || (forceSelected === true)}
                    hoverVisible={listContext && itemSelect.selectedLength === 0}
                    onChange={
                        forceSelected === true
                            ? null
                            : (value, event) => itemSelect.setSelected(item, value, event)
                    }
                    {...rest}
                />
            ) : null
    );
}