// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { RemoveButton } from '../../../element/Button';
import { SvgIcon } from '../../../element/SvgIcon';
import { ErrorList } from '../../../common/ErrorList';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr } from '../../../element/NoResourceMessages';
import { ValueSelect } from '../../../element/ValueSelect';
import { TextInput } from '../../../element/Input';
import { Tooltip } from '../../../element/Tooltip';
import { isShowDetailsResourceStatus, SkeletonBar, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { EditListener } from './type';
import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { BbLba, BbLbaListener, BbLbaListenerProtocol, BbLbaListenerProxyProtocol } from '../../../../api/type.lba';
import type { Editor } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { ResourceCacheStatus } from '../../../../state/resource/type';

export type LbaListenerListProps = {
    +editor: Editor<$ReadOnlyArray<EditListener>, FormErrors, BbLba>,
    +needsHttps: boolean,
    +cacheStatus: ResourceCacheStatus,
};

export const emptyListener: $ReadOnly<EditListener> = Object.freeze({
    in: '',
    out: '',
    timeout: 30,
    protocol: 'http',
    proxy_protocol: '',
});

const protocols: Array<ValueSelectChoice<BbLbaListenerProtocol>> = [
    { value: 'http', label: 'HTTP' },
    { value: 'tcp', label: 'TCP' },
    { value: 'https', label: 'HTTPS' },
];

const proxyProtocols: Array<ValueSelectChoice<BbLbaListenerProxyProtocol>> = [
    { value: '', label: 'None' },
    { value: 'v1', label: 'v1' },
    { value: 'v2', label: 'v2' },
    { value: 'v2-ssl', label: 'v2-ssl' },
    { value: 'v2-ssl-cn', label: 'v2-ssl-cn' },
];

const isHttpsProtocol = (protocol: BbLbaListenerProtocol) => protocol === 'https' || protocol === 'https+wss';

const listenerRequired = 'This rule is needed to support the HTTPS settings you have currently selected'

export const editListeners = (listeners: $ReadOnlyArray<BbLbaListener>): $ReadOnlyArray<EditListener> => listeners.map(listener => ({
    ...listener,
    timeout: listener.timeout / 1000,
    proxy_protocol: listener.proxy_protocol || '',
}));


export const LbaListenerEdit = ({ editor, needsHttps, cacheStatus, }: LbaListenerListProps): React$Node => {
    const editListeners = editor.value || [];

    const setListenerValue = (idx: number, value: $Shape<EditListener>) => {
        if (idx >= 0 && idx < editListeners.length) {
            const next: Array<EditListener> = [].concat(editListeners);
            next[idx] = { ...next[idx], ...value };
            editor.setValue(next);
        }
    };

    const addListener = () => {
        editor.setValue([].concat(editListeners, { ...emptyListener }));
    };

    const removeListener = (idx: number) => {
        editor.setValue([].concat(
            idx ? editListeners.slice(0, idx) : [],
            editListeners.slice(idx + 1),
        ));
    };

    let httpsListenerCount = editListeners.reduce((count, listener) => isHttpsProtocol(listener.protocol) ? count + 1 : count, 0);

    return (
        <Panel>
            <PanelHeader
                title='Listeners'
                description='How should this Load Balancer handle incoming traffic?'
            />
            {editor.messages?.messages?.length
                ? <PanelBar>
                    <ErrorList errors={editor.messages?.messages} />
                </PanelBar>
                : null}
            <PanelBar padding={false}>
            <Table editor={true}>
                <thead>
                    <Tr>
                        <Th min={true} hint='On which port should the Load Balancer listen for connections?'>From</Th>
                        <Th min={true} hint='Which port should the Load Balancer connect to on the backend Cloud Servers?'>To</Th>
                        <Th min={true} hint='After how many seconds should inactive connections be closed?'>Timeout</Th>
                        <Th min={true}>Protocol</Th>
                        <Th min={true}>
                            <Tooltip overlay={<span>Only specify this setting if you know it is required. In most cases, "None" is the best setting. <a href='https://www.brightbox.com/docs/reference/load-balancers/#proxy-protocol-support' target='blank'>Learn more</a></span>}>
                                <span className='flex items-center'>
                                Proxy Protocol <SvgIcon className='text-gray-400 ml-1 self-center' svg='warning-fill' />
                                </span>
                            </Tooltip>
                        </Th>
                        <Th stretch={true}>&nbsp;</Th>
                    </Tr>
                </thead>
                <tbody>
                {!isShowDetailsResourceStatus(cacheStatus)
                    ? <SkeletonListLoadingEntries>
                        <Tr>
                            <Td><SkeletonBar size='xs'/></Td>
                            <Td><SkeletonBar size='xs'/></Td>
                            <Td><SkeletonBar size='xs'/></Td>
                            <Td><SkeletonBar size='xs'/></Td>
                            <Td><SkeletonBar size='xs'/></Td>
                            <Td><SkeletonBar size='xs'/></Td>
                        </Tr>
                    </SkeletonListLoadingEntries>
                    : null
                }
                {editListeners.map((listener, idx: number) =>
                    <Tr key={idx}>
                        <Td min={true}>
                            <TextInput
                                size='xs'
                                autoFocus={true}
                                inputMode='numeric' pattern='[0-9 ,.]*'
                                value={listener.in}
                                onChange={(value) => setListenerValue(idx, { 'in': value })}
                                errorText={editor.errors.get(idx + '_in')}
                            />
                        </Td>
                        <Td min={true}>
                            <TextInput
                                size='xs'
                                inputMode='numeric' pattern='[0-9 ,.]*'
                                value={listener.out}
                                onChange={(out) => setListenerValue(idx, { out })}
                                errorText={editor.errors.get(idx + '_out')}
                            />
                        </Td>
                        <Td min={true}>
                            <TextInput
                                size='xs'
                                inputMode='numeric' pattern='[0-9 ,.]*'
                                value={listener.timeout}
                                onChange={(timeout) => setListenerValue(idx, { timeout })}
                                postText='sec'
                                errorText={editor.errors.get(idx + '_timeout')}
                            />
                        </Td>
                        <Td min={true}>
                            {needsHttps && httpsListenerCount === 1 && isHttpsProtocol(listener.protocol)
                                ? <Tooltip placement="bottom" overlay={listenerRequired}>
                                    <ValueSelect
                                        size='xs'
                                        selected={listener.protocol}
                                        options={protocols}
                                        readOnly={true}
                                        onSelect={(value: BbLbaListenerProtocol) => void 0}
                                        errorText={editor.errors.get(idx + '_protocol')}
                                    />
                                </Tooltip>
                                : <ValueSelect
                                    size='xs'
                                    selected={listener.protocol}
                                    options={protocols}
                                    onSelect={(value: BbLbaListenerProtocol) => setListenerValue(idx, { protocol: value })}
                                    errorText={editor.errors.get(idx + '_protocol')}
                                />
                            }
                        </Td>
                        <Td min={true}>
                            <ValueSelect
                                size='xs'
                                selected={listener.proxy_protocol || ''}
                                options={proxyProtocols}
                                onSelect={(value: BbLbaListenerProxyProtocol) => setListenerValue(idx, { proxy_protocol: value })}
                                errorText={editor.errors.get(idx + '_proxy_protocol')}
                            />
                        </Td>
                        <Td>
                            {needsHttps && httpsListenerCount === 1 && isHttpsProtocol(listener.protocol)
                                ? <Tooltip overlay={listenerRequired}>
                                    <span>
                                        <RemoveButton
                                            disabled={true}
                                            onClick={() => void 0}
                                            size='sm'
                                        />
                                    </span>
                                </Tooltip>
                                : <Tooltip
                                    placement="bottom"
                                    overlay={editListeners.length === 1
                                        ? <div>Load Balancers must have at least one listener</div>
                                        : <div>Remove listener</div>
                                    }>
                                    <RemoveButton
                                        disabled={editListeners.length === 1}
                                        size='sm'
                                        onClick={() => removeListener(idx)}
                                    />
                                </Tooltip>
                            }

                        </Td>
                    </Tr>)}
                <NoResourcesTr
                    status={cacheStatus}
                    colSpan={5}
                    title='Listeners'
                    totalCount={editListeners.length}
                    context=''
                />
                </tbody>
            </Table>
            </PanelBar>
            {editor.status === 'add'
                ? <PanelButtonBar
                    leftButton={{ children: 'Add Listener', kind: 'bare', preIcon: 'add-fill', onClick: addListener }}
                />
                : <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                    leftButton={{ children: 'Add Listener', kind: 'bare', preIcon: 'add-fill', onClick: addListener }}
                />
            }
        </Panel>
    );
};

