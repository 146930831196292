// @flow

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as searchReducer, reduxSearch, } from 'redux-search';
import { createLogicMiddleware } from 'redux-logic';

import { setStore } from '../api/events';

import { AuthReducer } from './auth/AuthReducer';
import {
    AuthLogoutLogic,
    AuthWaitForCookieTimeout,
    AuthWatchUserChangesLogic,
    GetAccountLogic,
    GetUserLogic,
    SwitchAccountLogic,
    TryLoginLogic
} from './auth/AuthLogic';

import { ResourceReducer } from './resource/ResourceReducer';
import {
    ResourceFetchCollectedLogic,
    ResourceFetchFullLogic,
    ResourcePatchActionLogic,
    ResourceProcessEventRawLogic,
    ResourceSimpleActionLogic
} from './resource/ResourceLogic';
import {
    ResourceCreateFirewallPolicyLogic,
    ResourceCreateServerGroupLogic,
    ResourceDeleteCollaborationLogic,
    ResourceDeleteServerGroupLogic,
    ResourceServerConsoleLogic,
    ResourceServerCreateLogic
} from './resource/DependentResourceLogic';

import { reduxSearchConfig, } from './Search/search';

import { FilterReducer } from './Filter/FilterReducer';
import { UiReducer } from './Sort/UiReducer';
import { StoreUiStateLogic } from './Sort/UiLogic';

import { CloudIpReducer } from './CloudIp/CloudIpReducer';
import { CloudIpAssignWhenAvailableLogic, CloudIpMapLogic } from './CloudIp/CloudIpLogic';

import { OrbitReducer } from './Orbit/OrbitReducer';
import {
    OrbitCreateContainerLogic,
    OrbitDeleteContainerLogic,
    OrbitContainerFetchPageLogic,
    OrbitFetchContainersLogic,
    OrbitUpdateContainerHeadersLogic,
    OrbitRefreshContainerLogic,
    OrbitAccountSettingsLogic,
    OrbitUpdateAccountHeadersLogic,
    OrbitUpdateContainerSetHistoryLogic,
    OrbitDeleteObjectLogic,
    OrbitNewApiClientAccessLogic,
    OrbitFetchObjectLogic,
    OrbitBulkDeleteObjectLogic,
    OrbitContainerFetchAllObjectsLogic,
    OrbitBulkDeleteSelectionLogic,
    OrbitBulkDeleteDirsLogic,
    OrbitRefreshImagesContainerLogic,
    OrbitRemoveCachedObjects,
    OrbitFetchManifestSizesLogic,
} from './Orbit/OrbitLogic';

import { MessageReducer } from './Message/MessageReducer';

import { FetchQueueLogic } from './FetchQueue/FetchQueueLogic';
import { FetchQueueReducer } from './FetchQueue/FetchQueueReducer';

import { SecretReducer } from './Secret/SecretReducer';
import { SecretRegisterLogic } from './Secret/SecretLogic';

import { GuiReducer } from './Gui/GuiReducer';

import { ActionQueueReducer } from './ActionQueue/ActionQueueReducer';
import { ActionQueueActionsLogic } from './ActionQueue/ActionQueueLogic';

import { BuildReducer } from './Build/BuildReducer';

import { GlobalSearchReducer } from './GlobalSearch/GlobalSearchReducer';
import { GlobalSearchFetchFullAccountLogic } from './GlobalSearch/GlobalSearchLogic';

import type { Store } from 'redux';
import type { AuthState } from './auth/type';
import type { ResourceState } from './resource/type';
import type { FilterState } from './Filter/type';
import type { CloudIpState } from './CloudIp/type';
import type { UiState } from './Sort/type';
import type { OrbitState } from './Orbit/type';
import type { MessageState } from './Message/type';
import type { FetchQueueState } from './FetchQueue/type';
import type { SecretState } from './Secret/type';
import type { GuiState } from './Gui/type';
import type { ActionQueueState } from './ActionQueue/type';
import type { BuildState } from './Build/type';
import type { GlobalSearchState } from './GlobalSearch/type';

let logic = [
    TryLoginLogic,
    GetAccountLogic,
    GetUserLogic,
    SwitchAccountLogic,
    AuthWatchUserChangesLogic,
    AuthLogoutLogic,
    AuthWaitForCookieTimeout,

    ResourceFetchCollectedLogic,
    ResourceFetchFullLogic,
    ResourceSimpleActionLogic,
    ResourcePatchActionLogic,
    ResourceProcessEventRawLogic,
    ResourceCreateServerGroupLogic,
    ResourceCreateFirewallPolicyLogic,
    ResourceServerConsoleLogic,
    ResourceDeleteCollaborationLogic,
    ResourceServerCreateLogic,
    ResourceDeleteServerGroupLogic,

    CloudIpAssignWhenAvailableLogic,
    CloudIpMapLogic,

    OrbitFetchContainersLogic,
    OrbitContainerFetchPageLogic,
    OrbitCreateContainerLogic,
    OrbitDeleteContainerLogic,
    OrbitUpdateContainerHeadersLogic,
    OrbitUpdateContainerSetHistoryLogic,
    OrbitRefreshContainerLogic,
    OrbitAccountSettingsLogic,
    OrbitUpdateAccountHeadersLogic,
    OrbitDeleteObjectLogic,
    OrbitNewApiClientAccessLogic,
    OrbitFetchObjectLogic,
    OrbitBulkDeleteSelectionLogic,
    OrbitBulkDeleteObjectLogic,
    OrbitBulkDeleteDirsLogic,
    OrbitContainerFetchAllObjectsLogic,
    OrbitRefreshImagesContainerLogic,
    OrbitRemoveCachedObjects,
    OrbitFetchManifestSizesLogic,

    FetchQueueLogic,

    SecretRegisterLogic,

    StoreUiStateLogic,

    ActionQueueActionsLogic,

    GlobalSearchFetchFullAccountLogic
];

let reducers = combineReducers({
    Auth: AuthReducer,
    Resource: ResourceReducer,
    Filter: FilterReducer,
    CloudIp: CloudIpReducer,
    Ui: UiReducer,
    Orbit: OrbitReducer,
    Message: MessageReducer,
    FetchQueue: FetchQueueReducer,
    Secret: SecretReducer,
    Gui: GuiReducer,
    ActionQueue: ActionQueueReducer,
    Build: BuildReducer,
    GlobalSearch: GlobalSearchReducer,
    search: searchReducer,
});

export type CloudGuiState = {
    Auth: AuthState,
    Resource: ResourceState,
    Filter: FilterState,
    Ui: UiState,
    CloudIp: CloudIpState,
    Orbit: OrbitState,
    Message: MessageState,
    FetchQueue: FetchQueueState,
    Secret: SecretState,
    Gui: GuiState,
    ActionQueue: ActionQueueState,
    Build: BuildState,
    GlobalSearch: GlobalSearchState,
    search: any,
};

let middle = [
    createLogicMiddleware(logic),
];

if (process.env.NODE_ENV !== 'production') {
    const reduxLogger = require('redux-logger');
    middle.push(reduxLogger.createLogger({
        // predicate: (getState, action) => action.type.substring(0, 5) === 'ORBIT',
    }));
}

if (process.env.NODE_ENV === 'development') {
    window.dumpStore = () => { console.log(store.getState()); return store.getState(); }
}

const enhancers = compose(
    applyMiddleware(...middle),
    reduxSearch(reduxSearchConfig)
);

export const store: Store<CloudGuiState, any, any> = createStore<CloudGuiState, any, any>(reducers, enhancers);

setStore(store);
