// @flow
import { PanelHeader, Panel, PanelButtonBar } from '../../../element/Panel';
import { useDialog, Dialog } from '../../../element/Dialog';
import { Notice } from '../../../element/Notice';
import { Button } from '../../../element/Button';
import { Pill } from '../../../element/Pill';
import { useSupportDialog } from '../../../hoc/SupportDialog';
import { DatabaseTypeSelector } from './DatabaseTypeSelector';
import { useEditorDirect } from '../../../common/Editor';

import type { EditorModal } from '../../../common/Editor';
import type { BbDatabaseType, BbDatabaseServer, } from '../../../../api/type.dbs';
import type { ServerTypeValue } from '../../../hoc/ResizeResource';

export type DatabaseTypeEditProps = {
    +editor: EditorModal<ServerTypeValue<BbDatabaseType>, ?string, BbDatabaseServer>,
    +availableMemory: number,
    +id: string,
    +name: string,
    +onValidate: () => ?string,
}

function getPresetMessage(id: string, type: BbDatabaseType): string {
    return `I'm having trouble resizing ${id} to ${type.name || ''} (${type.id}) and need some assistance.`;
}

const INSUFFICIENT_INSTANCE_CAPACITY = 'insufficient_instance_capacity';
// simpler for SD to test with this...
// const INSUFFICIENT_INSTANCE_CAPACITY = 'account_limit_reached';

export const DatabaseTypeEdit = ({ editor, availableMemory, id, name, onValidate, }: DatabaseTypeEditProps): React$Node => {
    const { value, errors } = editor;

    const confirmDialog = useDialog([
        {
            label: 'Resize',
            kind: 'primary',
            onSelect: editor.status === 'edit' && errors == null ? editor.onSave : null
        }
    ]);

    const supportDialog = useSupportDialog(
        editor.messages,
        INSUFFICIENT_INSTANCE_CAPACITY,
        (): ?string => value?.selected ? getPresetMessage(id, value.selected) : null
    );

    const editorDirect = useEditorDirect(
        editor.value?.selected,
        (selected) => editor.value ?  editor.setValue({ ...editor.value, selected }) : void 0,
        null
    );

    if (value == null) return null;

    return (
        <>
            <Dialog
                dialog={confirmDialog}
                title='Resize Cloud SQL Instance?'
                footerLink={<a href='https://www.brightbox.com/docs/guides/control-panel/resize-cloud-sql/' rel='noreferrer noopener' target='blank'>
                <Button size='sm' kind='bare' preIcon='info-fill'>Resizing Cloud SQL Instances</Button></a>}
                render={() => (
                    <div>
                        {errors
                            ? <Notice type='error'>{errors}</Notice>
                            : <>
                                <p>Please confirm you want to resize <b>{name}</b></p>
                                <Notice type='warning' icon='info-fill'>
                                    <b>Note:</b> resizing a Cloud SQL instance can't be undone or resized to a smaller type later.
                                </Notice>
                            </>
                        }
                    </div>
                )}
            />
            <Dialog
                title='Automatic Resize Failed'
                dialog={supportDialog}
                render={() => (
                    <div className='space-y-4'>
                        <Notice type='warning' icon='info-fill'>
                            We're unable to complete the resize automatically right now
                        </Notice>
                        <p>Please click below to request assistance to complete the resize.</p>
                    </div>
                )}
            />
            <Panel>
                <PanelHeader
                    title={<>Resize Cloud SQL Instance <Pill className='align-middle'>Beta</Pill></>}
                    description='Choose a new size for this Cloud SQL Instance'
                />

                <DatabaseTypeSelector
                    editor={editorDirect}
                    availableMemory={availableMemory}
                    current={value.current}
                />

                <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    leftButton={{
                        kind: 'bare',
                        preIcon: 'info-fill',
                        children: <a href='https://www.brightbox.com/docs/guides/control-panel/resize-cloud-sql/' rel='noopener noreferrer' target='_blank'>Resizing Cloud SQL Instances</a>
                    }}
                    primaryButton={{
                        onClick: editor.status === 'edit'
                            ? () => {
                                onValidate();
                                confirmDialog.show();
                            }
                            : null,
                        disabled: value.selected == null,
                    }}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                />
            </Panel>
        </>
    );
};