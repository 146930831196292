// @flow
import { StandardResourceSection } from '../StandardResourceSection';
import { ServerList } from './ServerList';
import { ServerView } from './ServerView';
import { ServerCreate } from './ServerCreate';

import type { Match } from 'react-router-dom';

export const ServerSection = ({ match }: { match: Match }): React$Node => (
    <StandardResourceSection match={match} list={ServerList} view={ServerView} create={ServerCreate} />
);
