// @flow
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { historyNavigateOnCreate } from '../../lib/history';
import { useMessages } from './Messages';
import { useCurrentAccount } from './lib';
import { RC_SUCCESS } from '../../state/resource/type';
import { CREATE_FWR_MESSAGES_ID } from '../../state/resource/DependentResourceLogic';
import { validateUserData } from '../section/server/edit/lib';
import { GB_TO_MB } from '../element/Styled';

import type { Dispatch } from 'redux';
import type { BbAllResourceTypes, BbResourceKind, BbServerType, BbZone } from '../../api/type';
import type { CipAssignParams } from '../../api/type.cip';
import type { ResourceCreateKindAction, ResourceServerCreateAction } from '../../state/resource/type';
import type { MessageRemoveAction, MessageStatus } from '../../state/Message/type';
import type { BbAccount } from '../../api/type.acc';
import type { BbFirewallRule, BbFirewallRuleParams } from '../../api/type.fwp';
import type { ResourceCipCreate } from '../common/ResourceCloudIps';
import type { BbServer, BbServerCreateParams, UserDataEdit } from '../../api/type.srv';
import type { MessageHook } from './Messages';
import type { Gigabytes } from '../../api/type.units';
import type { BbVolume, BbVolumeParams } from '../../api/type.volume';

export type CreateResource<Res, Params> = {
    +createResource: (params: Params, cloudIp?: CipAssignParams) => void,
    +messages: Array<string>,
    +status: MessageStatus,
    +resource: ?Res,
    +apiResult: MessageHook<Res>,
};

type CreateResourceActions = ResourceCreateKindAction | MessageRemoveAction;

export function useRawCreateResource<Res: BbAllResourceTypes, Params>(
    messagesId: string,
    kind: BbResourceKind,
    onComplete: (account: BbAccount, resource: Res) => void,
): CreateResource<Res, Params> {
    const dispatch = useDispatch<Dispatch<CreateResourceActions>>();
    const apiResult = useMessages<Res>(messagesId);
    const { messages, status, resource } = apiResult;
    const account = useCurrentAccount();

    useEffect(() => {
        if (status === RC_SUCCESS) {
            dispatch({ type: 'MESSAGE_REMOVE', payload: { id: messagesId } });
            if (resource && account) {
                onComplete(account, resource);
            }
        }
    }, [account, dispatch, onComplete, messagesId, resource, status])

    return {
        messages,
        status,
        resource,
        apiResult,
        createResource: (params: Params, cloudIp?: CipAssignParams) => {
            dispatch({
                type: 'RESOURCE_CREATE_KIND',
                payload: { kind, params, cloudIp, messagesId }
            });
        },
    };
}

function navigateAfterResourceCreate<Res: BbAllResourceTypes>(account: BbAccount, resource: Res) {
    historyNavigateOnCreate(account, resource.id, resource.resource_type);
}

export const useCreateResource = <Res: BbAllResourceTypes, Params>(
    messagesId: string, kind: BbResourceKind
): CreateResource<Res, Params> => {
    return useRawCreateResource(
        messagesId, kind,
        navigateAfterResourceCreate
    )
};


/**
 * We need to navigate differently when adding a FWR - back to the server group
 * that contains the firewall policy that contains the rule...
 *
 * So this just changes the redirect.
 */
export const useCreateFirewallRule = (server_group_id: string): CreateResource<BbFirewallRule, BbFirewallRuleParams> => {
    const navigate = useCallback(
        (account: BbAccount, resource: BbFirewallRule) => historyNavigateOnCreate(account, server_group_id, 'server_group'),
        [server_group_id]
    );

    return useRawCreateResource(
        CREATE_FWR_MESSAGES_ID, 'firewall_rule',
        navigate
    );
};

/**
 * Volumes might be created from Server > Attach Volume, which we track with a 'srv' query parameter.
 *
 * So that might change the redirect.
 */
export const useCreateVolume = (navigate: boolean): CreateResource<BbVolume, BbVolumeParams> => {
    const navigateAfter = useCallback(
        (account: BbAccount, volume: BbVolume) => {
            if (navigate) {
                historyNavigateOnCreate(account, volume.id, 'volume');
            }
        },
        [navigate]
    )

    return useRawCreateResource(
        'volume_create', 'volume',
        navigateAfter
    );
};

export type ImageOrVolume = 'image' | 'volume';

export type ServerCreateParams = {
    +image: ?string,
    +volume: ?string,
    +name: string,
    +server_type: ?BbServerType,
    +zone: ?BbZone,
    +server_groups: $ReadOnlyArray<string>,
    +user_data: UserDataEdit,
    +user_ssh_key: string,
    +disk_encrypted: boolean,
    +root_volume: string | Gigabytes,
    +imageOrVolume: ImageOrVolume,
}

export type ValidatedServerCreateParams = {
    ...ServerCreateParams,
    +imageOrVolume: 'image',
    +image: string,
} | {
    ...ServerCreateParams,
    +imageOrVolume: 'volume',
    +volume: string,
}

export type CreateServerHook = {
    createServer: (p: ValidatedServerCreateParams, c: ResourceCipCreate) => void,
    messages: MessageHook<BbServer>,
}

export const useCreateServer = (): CreateServerHook => {
    const dispatch = useDispatch<Dispatch<ResourceServerCreateAction>>();
    const messages = useMessages<BbServer>('server_create');
    const account = useCurrentAccount();

    useEffect(() => {
        if (messages.status === RC_SUCCESS && account && messages.resource != null) {
            historyNavigateOnCreate(account, messages.resource.id, 'server');
            messages.clear();
        }
    }, [messages, account]);

    return {
        createServer: (rawParams: ValidatedServerCreateParams, cloudIp: ResourceCipCreate) => {
            const { server_type, image, user_ssh_key, user_data, root_volume, disk_encrypted, zone, volume, imageOrVolume, ...directParams } = rawParams;

            if (server_type != null && image != null) {

                const [, converted] = validateUserData(user_data);

                const params: BbServerCreateParams = {
                    ...directParams,
                    ...converted,
                    server_type: server_type.id,
                };

                if (rawParams.imageOrVolume === 'volume') {
                    params.volumes = [{ volume: rawParams.volume }];
                } else {
                    if (server_type.storage_type === 'network') {
                        params.volumes = [
                            {
                                size: parseFloat(root_volume) * GB_TO_MB,
                                image,
                            }
                        ];
                    } else {
                        params.image = image;
                        params.disk_encrypted = disk_encrypted;
                        params.zone = rawParams.zone ? rawParams.zone.id : null;
                    }
                }

                let payload: $PropertyType<ResourceServerCreateAction, 'payload'> = {
                    messagesId: 'server_create',
                    params,
                    cloudIp: cloudIp.editor.value,
                };
                if (user_ssh_key !== '') {
                    payload.userSshKey = user_ssh_key;
                }

                dispatch({ type: 'RESOURCE_SERVER_CREATE', payload, });
            }
        },
        messages,
    }
}
