// @flow
import { cn } from './lib/classNames';


type SeparatorProps = {
    className?: string,
    children: React$Node,
    solid?: boolean,
}

export const Separator = ({ className: extraClassName, children, solid, ...props }: SeparatorProps): React$Node => {

    let classes = {
        'c-separator': true,
        'c-separator--solid': (solid === true),
    }

    const className = cn(classes) + ' ' + (extraClassName || '');

    return (
        <div className={className} {...props}>
            {children
            ? <span className='c-separator__text'>
                {children}
            </span>
            : null
            }
        </div>
    );
};