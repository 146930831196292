// @flow
import { useState, useContext } from 'react';
import { PaymentCard } from './PaymentCard';
import { isNetworkRequestInFlight, isNetworkRequestError } from '../../hoc/graphql';
import { InvoiceTable } from './InvoiceTable';
import { TakePayment } from './TakePayment';
import { Elements, } from '@stripe/react-stripe-js';
import { stripePromise } from './lib';
import { useCurrentAccount } from '../../hoc/lib';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { StripeAddCard } from './edit/StripeAddCard';
import { accountBillingContext } from '../../hoc/Billing';
import { PanelHeader, PanelBar, Panel } from '../../element/Panel';
import { Link } from 'react-router-dom';

import type { Match } from 'react-router';
import type { BbAccount } from '../../../api/type.acc';

// these are just a workarounds for creating an 'EditorModal'
// which would be very fiddly with gql and stripe involved.
const CARD_EDITOR = { editUri: 'card', onSave: () => null };
const PAYMENT_EDITOR = { editUri: 'payment', onSave: () => null };

export const BillingPage = ({ match }: { match: Match }): React$Node => {
    const account: ?BbAccount = useCurrentAccount();
    const [currentBalance, setCurrentBalance] = useState<number>(0);

    const [, billingResult] = useContext(accountBillingContext);

    let { error, data, networkStatus } = billingResult || { error: null, data: null, networkStatus: 1, };

    if (!isNetworkRequestInFlight(networkStatus) && data && currentBalance !== parseFloat(data.account.currentBalance)) {
        setCurrentBalance(parseFloat(data.account.currentBalance));
    }

    if (account == null) {
        return <div>Loading</div>;
    }

    const isAddCard = account.status === 'pending' && data?.account?.paymentCard == null;

    const loading = isNetworkRequestInFlight(networkStatus)

    return (
        <ResourceAddViewRoute
            match={match}
            dialog={null}
            view={
                isNetworkRequestError(networkStatus)
                    ? <Panel>
                        <PanelHeader
                            title='Billing'
                        />
                        <PanelBar padding={false}>
                            <div className='m-6 text-gray-600 text-sm text-center'>
                                <i>There was a problem accessing the billing data, please check back later.
                                    If this problem persists, please
                                    <Link to={`/accounts/${account.id}/support/`} className='inline-block ml-3'>Contact Support</Link>
                                </i>
                            </div>
                        </PanelBar>
                    </Panel>
                    : <>
                        <PaymentCard
                            paymentCard={(!data || isNetworkRequestInFlight(networkStatus)) ? null : data.account.paymentCard}
                            loading={loading}
                        />
                        <InvoiceTable
                            loading={loading}
                            error={error}
                            data={data}
                        />
                    </>
            }
            editors={[
                { editor: CARD_EDITOR, render: () => <StripeAddCard isAddCard={isAddCard} /> },
                {
                    editor: PAYMENT_EDITOR,
                    render: () => (
                        <Elements stripe={stripePromise}>
                            <TakePayment
                                accountId={account.id}
                                currentBalance={currentBalance}
                                billingData={data}
                                billingDataStatus={networkStatus}
                            />
                        </Elements>
                    )
                },
            ]}
        />
    );
};
