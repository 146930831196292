// @flow

import { useEffect } from 'react';
import Olark from 'react-olark';

const OLARK_KEY = '8293-987-10-7294';
const OLARK_GROUP = '2d4eb5435fc12410797e81d5afc94608';

function showOlark() {
    window.olark('api.box.show');
}

export const OlarkWrapper = (): React$Node => {
    useEffect(() => {
        window.olark
            ? window.olark('api.chat.onOperatorsAvailable', showOlark)
            : void 0;
    }, []);

    // un-comment the "&& false" if you want to test this in dev
    return (
        process.env.NODE_ENV === 'development' // && false
            ? null
            : <Olark
                siteId={OLARK_KEY}
                systemConfig={{
                    is_single_page_application: true,
                    group: OLARK_GROUP,
                    hb_show_button_text: false,
                }}
                localeConfig={{
                    welcome_title: 'Need help?',
                    unavailable_title: 'Send a message',
                    away_message: 'If you\'ve got any questions or feedback, we\'d love to hear it...',
                }}
                boxConfig={{
                    start_hidden: true
                }}
            />
    );
};