// @flow
import { useState } from 'react';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { NameEditPanel } from '../../element/NameEditPanel';
import { useEditorDirect, useEditorErrors } from '../../common/Editor';
import { useOrbitContainerCreate, useContainerNames } from '../../hoc/Orbit';
import { getOrbitContainerUrl } from '../../../api/adapter.orbit';
import { useCurrentAccountId } from '../../hoc/lib';
import { Notice } from '../../element/Notice';
import { PanelButtonBar } from '../../element/Panel';
import { historyBack } from '../../../lib/history';
import { PerGigPrice } from '../../element/PerGigPrice';

import type { Match } from 'react-router';
import type { BbOrbitContainerParams } from '../../../api/type.orbit';
import { LABELS } from '../../element/ResourceLabels';

export const OrbitContainerCreate = ({ match }: { match: Match }): React$Node => {
    const { create, messages } = useOrbitContainerCreate();
    const errors = useEditorErrors<?string>(null);
    const accountId = useCurrentAccountId() || '';
    const currContainers = useContainerNames();

    const [createParams, setCreateParams] = useState<BbOrbitContainerParams>({
        name: '',
    });

    const name = useEditorDirect<string, ?string>(
        createParams.name,
        (name: string) => setCreateParams({...createParams, name}),
        errors.errors
    );

    const validateAndCreate = () => {
        let valid = true;

        if (createParams.name === '' || createParams.name.match(/\//)) {
            errors.setErrors('Please enter a valid name');
            valid = false;
        }

        if (currContainers.indexOf(createParams.name) !== -1) {
            errors.setErrors('An Orbit container with this name already exists');
            valid = false;
        }

        if (valid) {
            create(createParams);
        }
    };

    const containerUrl = getOrbitContainerUrl(accountId, (name.value !== '' ? name.value : '<container name>'));

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.container.listTitle}
            match={match}
            dialog={null}
            view={
                <>
                    <NameEditPanel
                        panelTitle='New Orbit Container'
                        editor={name}
                        nameRequired={true}
                        forceNameAutoFocus={true}
                        nameNotice={
                            <Notice>
                                Orbit container URL: <br/>
                                <pre><a target="_blank" rel='noopener noreferrer' href={containerUrl}>{containerUrl}</a></pre>
                            </Notice>
                        }
                        resourceTitle='Orbit Container'
                    >
                        <PanelButtonBar
                            cacheStatus={messages.status}
                            primaryButton={{
                                kind: 'primary',
                                onClick: validateAndCreate,
                                children: 'Create Orbit Container',
                            }}
                            cancel={() => historyBack()}
                        >
                          <PerGigPrice id='orbit' label='Orbit' />
                        </PanelButtonBar>
                    </NameEditPanel>
                </>
            }
        />
    );
};