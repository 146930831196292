// @flow

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTriggeredMessages } from '../Messages';
import { RC_INITIAL, RC_SUCCESS, RC_ERROR } from '../../../state/resource/type';
import { historyNavigateOnDeleteOrbitObject } from '../../../lib/history';
import { orbitUrl } from '../../../api/url';
import { to } from 'await-to-js';
import { orbit } from '../../../api/rest';
import { fetchOrbitAuth } from '../../../state/Orbit/lib';

import type { CloudGuiState } from '../../../state/cloudgui';
import type { OrbitObject } from '../../../api/type.orbit';
import type { ResourceCacheStatus } from '../../../state/resource/type';
import type { Dispatch } from 'redux';
import type { OrbitAction, OrbitViewObject } from '../../../state/Orbit/type';

export type OrbitObjectViewHook = {
    +object: ?OrbitObject,
    +cacheStatus: ResourceCacheStatus,
    +basename: string,
    +deleteObject: ?() => void,
}

export const useOrbitObjectView = (container: string, name: string): OrbitObjectViewHook => {
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const [cacheBreaker, setCacheBreaker] = useState<number>(0);
    const { next, messages: deleteObjectMessages } = useTriggeredMessages();

    const viewObject = useSelector<CloudGuiState, ?OrbitViewObject>((state) =>
        state.Orbit.viewObject && state.Orbit.viewObject.container === container && state.Orbit.viewObject.name === name
            ? state.Orbit.viewObject
            : null
    );

    useEffect(() => {
        if (viewObject === null) {
            dispatch({
                type: 'ORBIT_FETCH_OBJECT_META',
                payload: {
                    isRetry: false,
                    container,
                    name,
                }
            });
        }
    }, [container, name, dispatch, cacheBreaker, viewObject, ]);

    const { status: deleteStatus, clear } = (deleteObjectMessages || { status: RC_INITIAL, clear: null });

    useEffect(() => {
        if ((deleteStatus === RC_SUCCESS || deleteStatus === RC_ERROR) && typeof clear === 'function') clear();
        if (deleteStatus === RC_SUCCESS) {
            setCacheBreaker((cb) => cb + 1);
        }
    }, [deleteStatus, clear, setCacheBreaker]);

    const object = viewObject?.object;

    return {
        object,
        cacheStatus: viewObject?.cacheStatus || RC_INITIAL,
        basename: name.split('/').pop(),
        deleteObject: deleteObjectMessages.status === RC_INITIAL && object ?
            () => {
                dispatch({
                    type: 'ORBIT_OBJECT_DELETE',
                    payload: {
                        container,
                        object,
                        messagesId: next(),
                    }
                });

                historyNavigateOnDeleteOrbitObject();
            }
            : null,
    };
};

export async function orbitObjectExists(accountId: string, container: string, name: string): Promise<boolean> {
    await fetchOrbitAuth();

    const url = [orbitUrl, accountId, container, name].join('/');
    const [, res] = await to(orbit({
        url,
        method: 'HEAD',
        headers: {
            'X-Newest': true,
        }
    }));

    return (res && res.status >= 200 && res.status < 300);
}