// @flow

import to from 'await-to-js';
import { GENERIC_ERROR_MESSAGE } from './edit/StripeAddCard';
import { loadStripe } from '@stripe/stripe-js';
import type { Stripe } from '@stripe/stripe-js';

const STRIPE_PUBLISHABLE_KEY = window?.cgrConfig?.STRIPE_PUBLISHABLE_KEY || 'pk_test_Z3HycVAUp5O4E94kAwkjYERQ'; // brightbox dev stripe key
// const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_WiSQu4HZA7Z67InyWo2NPKXj'; // SD dev strip key

export const EU_COUNTRIES_LIST = ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE'];

export const stripePromise: Promise<Stripe> = loadStripe(STRIPE_PUBLISHABLE_KEY);

export type StripeResult = {
    success: boolean,
    error?: string,
};

export const processStripe = async (
    mutation: any,
    mutName: string,
    variables: Object,
    stripe: any
): Promise<StripeResult> => {
    let [ mutErr, mutResult ] = await to(mutation({
        variables
    }));

    if (mutErr) {
        return { success: false, error: mutErr.message || GENERIC_ERROR_MESSAGE };
    }

    if (mutResult && mutResult.data && mutResult.data[mutName].isComplete) {
        return { success: true };
    }

    if (mutResult && mutResult.data && mutResult.data[mutName].clientSecret) {
        const { clientSecret } = mutResult.data[mutName];
        let [handleCardErr, handleCardResult] = await to(stripe.handleCardAction(clientSecret));
        if (handleCardResult.error) {
            return { success: false, error: handleCardResult.error.message };
        }
        if (handleCardErr) {
            return { success: false, error: handleCardErr.message };
        }

        if (handleCardResult.paymentIntent) {
            let [confirmErr, confirmResult] = await to(mutation({
                variables: {
                    ...variables,
                    token: handleCardResult.paymentIntent.id,
                }
            }));

            if (confirmErr) {
                return { success: false, error: confirmErr.message || GENERIC_ERROR_MESSAGE };
            }

            if (confirmResult && confirmResult.data && confirmResult.data[mutName].isComplete) {
                return { success: true,  };
            }
        }
    }

    return { success: false, error: GENERIC_ERROR_MESSAGE };
};

