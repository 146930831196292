//@flow

import { BbResourceKinds } from './type';

import type { BbResourceKind, BbImageStatus } from './type';
import type { BbServerStatus } from './type.srv';
import type { BbDatabaseSnapshotStatus, BbDatabaseServerStatus } from './type.dbs';
import type { BbCloudIpStatus } from './type.cip';
import type { BbLbaStatus } from './type.lba';
import type { BbVolumeStatus } from './type.volume';

const reverseMap = Object.keys(BbResourceKinds).reduce((acc, key) => {
    acc[BbResourceKinds[key]] = key;
    return acc;
}, {});

export const getResourceKind = (id: string): BbResourceKind => {
    const kindPrefix = id.substr(0, 3);
    if (!(kindPrefix in reverseMap)) {
        throw new Error('Unknown resource type: ' + id);
    }

    return reverseMap[kindPrefix];
};

type ResourceStatuses = ?BbServerStatus | ?BbDatabaseSnapshotStatus | ?BbCloudIpStatus | ?BbLbaStatus | ?BbImageStatus | ?BbDatabaseServerStatus | ?BbVolumeStatus;

export function isAllocated(status: ResourceStatuses): boolean {
    return status === 'active' || status === 'inactive' || status === 'available' || status === 'unmapped' || status === 'mapped';
}

export function isCreating(status: ResourceStatuses): boolean {
    return status === 'creating';
}

export function isDeleted(status: ResourceStatuses): boolean {
    return status === 'deleting' || status === 'deleted' || status === 'failing' || status === 'failed' || status === 'unavailable';
}