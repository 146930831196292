// @flow
import { PanelBar } from '../../../element/Panel';
import { Notice } from '../../../element/Notice';
import { CardGroup } from '../../../element/CardGroup';
import { Card } from '../../../element/Card';
import { TypeCardContent } from '../../../element/TypeCardContent';
import { getMonthlyPrice } from '../../../../lib/pricing';
import { SkeletonTypeCardPanel } from '../../../element/Skeleton';
import { Tooltip } from '../../../element/Tooltip';
import { Pill } from '../../../element/Pill';
import { formatMegabytesSize, GB_TO_MB } from '../../../element/Styled';
import { TextInput } from '../../../element/Input';
import { serverTypeIsDisabled } from './lib';
import { ServerLimitWrapper } from './ServerLimitWrapper';

import type { BbServerType, BbCollectedImage, BbImage } from '../../../../api/type';
import type { EditorDirect } from '../../../common/Editor';
import type { Gigabytes } from '../../../../api/type.units';
import type { BbVolume } from '../../../../api/type.volume';

type ServerTypeTabPanelProps = {
    +choices: $ReadOnlyArray<BbServerType>,
    +selected: ?BbServerType,
    +onSelect: (type: BbServerType) => void,
    +availableMemory: number,
    +root_volumeEditor?: EditorDirect<string | Gigabytes, ?string>,
    +selectedImage: ?BbCollectedImage | ?BbImage,
    +selectedVolume: ?BbVolume,
    +current?: BbServerType,
    +isInactive?: boolean,
    +volumeEditor?: EditorDirect<$ReadOnlyArray<string>, null>,
}

export const ServerTypeTabPanel = ({
    choices, selected, onSelect, availableMemory, root_volumeEditor, volumeEditor, selectedImage, selectedVolume, current, isInactive,
}: ServerTypeTabPanelProps): React$Node => {
    const hasSelectedNbsServer = (selected?.storage_type === 'network');

    return (
        <PanelBar>
            {volumeEditor
                ? <Notice type="warning" icon="flag">
                    <b>Please Note</b> – Block Storage is currently in beta and isn't
                    recommended for production systems</Notice>
                : null
            }
            <CardGroup>
                {choices.map(server_type => {
                    const [needsToBeInactive, diskDoesntFit, disabled] = serverTypeIsDisabled(server_type, availableMemory, selectedImage, current, isInactive == null ? false : isInactive);

                    return (
                        <ServerLimitWrapper
                            ram={server_type.ram}
                            availableMemory={availableMemory}
                            min_ram={selectedImage?.min_ram}
                            key={server_type.id}
                            needsToBeInactive={needsToBeInactive}
                            diskDoesntFit={diskDoesntFit
                                ? {
                                    disk: server_type.disk_size,
                                    min_disk: current?.disk_size || 0,
                                }
                                : null
                            }
                        >
                            <Card
                                selectable={current == null || current.id !== server_type.id}
                                selected={selected === server_type}
                                onClick={disabled ? null : () => onSelect(server_type)}
                                disabled={disabled}
                                current={current?.id === server_type.id}
                            >
                                <TypeCardContent
                                    id={server_type.id}
                                    ram={server_type.ram}
                                    cpus={server_type.cores}
                                    disk={server_type.disk_size}
                                    cost={getMonthlyPrice(server_type.id)}
                                    handle={server_type.handle}
                                    resourceTitle='Cloud Servers'
                                />
                            </Card>
                        </ServerLimitWrapper>
                    );
                })}
                {choices.length === 0
                    ? <SkeletonTypeCardPanel />
                    : null
                }
            </CardGroup>
            {root_volumeEditor
                ? <div className="py-5 px-8 bg-gray-200 rounded-lg">
                    <h4 className="mb-4">Initial Boot Volume Size <Tooltip
                        overlay="Choose the initial size for the boot volume – you can always increase this later if you need to"><Pill>?</Pill></Tooltip></h4>
                    <Tooltip
                        overlay={
                            selectedVolume != null
                                ?
                                <div>You've selected an existing volume {selectedVolume.id} which is currently {formatMegabytesSize(selectedVolume.size)} – you
                                    can increase this later if you need to</div>
                                : !hasSelectedNbsServer
                                    ? <div>Please select a Block Storage server type before editing Volume Size</div>
                                    : null
                        }>
                        <TextInput
                            label="Volume Size"
                            value={selectedVolume ? selectedVolume.size / GB_TO_MB : root_volumeEditor.value}
                            onChange={(val) => root_volumeEditor.setValue(val)}
                            postText="GB"
                            className="w-32 mb-3"
                            disabled={!hasSelectedNbsServer || selectedVolume != null}
                            focusSelectAll={true}
                            errorText={root_volumeEditor.errors}
                            readOnly={!selected?.storage_type === 'network'}
                        />
                    </Tooltip>
                    {selectedVolume == null
                        ? <p className="italic text-sm text-gray-600">Specify the initial size for the root volume (it can always be <a href='https://www.brightbox.com/docs/guides/volumes/resize-volume/' rel='noreferrer noopener' target='_blank'>increased</a> later if required)</p>
                        : null
                    }
                </div>
                : null}
        </PanelBar>
    );
};