import { createHttpLink } from 'apollo-link-http';
import { graphqlUrl, kioskUrl } from './url';
import { ApolloLink } from 'apollo-link';
import { ApolloClient, InMemoryCache } from '@apollo/client';

function createApolloClient(uri: string, cacheOptions: ?Object) {
    const httpLink = createHttpLink({
        uri,
        credentials: 'include',
        headers: { 'x-requested-with': 'apollo' }
    });
    const link = ApolloLink.from([httpLink]);
    return new ApolloClient({
        link,
        cache: new InMemoryCache(cacheOptions)
    });
}

const apiCacheOptions = {
    typePolicies: {
        Metric: {
            keyFields: false,
            merge: function() {
                // the Metric type doesn't have an id, so we need
                // to tell apollo how to merge them into the object graph.
                // But, we don't need to have these results cached by apollo;
                // as long as the hook sees the raw data once, it builds it
                // into some graph data and that it caches locally then
                // doesn't reference again, so just return undefined
                return void 0;
            }
        }
    },
};

export const apiClient = createApolloClient(graphqlUrl, apiCacheOptions);
export const kioskClient = createApolloClient(kioskUrl);
