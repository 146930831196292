// @flow
import { createSelector } from "reselect";
import { createSearchAction, getSearchSelectors } from "redux-search";
import { resourceSelector } from '../../state/Search/search';

import type { Dispatch } from "redux";
import type { CloudGuiState } from '../../state/cloudgui';
import type { BbAccountStatus, BbCollectedAccount } from '../../api/type.acc';
import type { SearchDefinition } from '../element/Search';

const { result } = getSearchSelectors({ resourceName: 'account', resourceSelector });

const getSearchTextAccounts = createSelector(
    [result,],
    (result) => ({
        searchMatchIds: result,
    }));

const accountSearchText = createSearchAction('account');

export const statusToActive = (status: BbAccountStatus): boolean => {
    switch(status) {
    case 'pending':
    case 'active':
    case 'overdue':
    case 'warning':
    case 'suspended':
        return true;
    case 'terminated':
    case 'closed':
    case 'deleted':
        return false;
    default:
        void (status: empty);
    }
    return true;
};

export const accountSearch: SearchDefinition<BbCollectedAccount> = {
    name: 'account',
    fields: [
        {
            searchFieldName: 'searchText',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedAccount>) => {
                const { searchMatchIds, } = (getSearchTextAccounts(state): { searchMatchIds: Array<string> });
                return searchMatchIds.findIndex(x => x === item.id) !== -1;
            },
            setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                dispatch(accountSearchText(nextValue));
            },
        },
    ],
};
