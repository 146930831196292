// @flow

import { useState } from 'react';
import { history } from './lib/history';

const BETA_FLAG = 'bb-beta';

function checkBeta(): boolean {
    let beta = localStorage.getItem(BETA_FLAG) != null;

    if (window.location.search.match(/beta/)) {
        beta = true;
        localStorage.setItem(BETA_FLAG, '1');
        history.replace(window.location.pathname);
    }

    return beta;
}

export function useCheckBeta(): boolean {
    const [show,] = useState<boolean>(checkBeta);

    return show;
}

