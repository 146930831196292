// @flow
import { createRoot } from 'react-dom/client';
import { App } from './component/base/App';
import { Provider } from 'react-redux';
import { store } from './state/cloudgui';
import { setDispatch } from './api/rest';
import { loadUiDefaults } from './state/Sort/UiLogic';
import { AppToastContainer } from './component/element/AppToastContainer';

setDispatch(store.dispatch);

loadUiDefaults(store);

const rootEl = document.getElementById('root');
if (rootEl) {
    const root = createRoot(rootEl);
    root.render(
        <>
            <AppToastContainer/>
            <Provider store={store}>
                <App/>
            </Provider>
        </>,
    );
} else {
    throw Error('No root div found for React');
}
