// @flow
import { NavLink, useRouteMatch, Link} from 'react-router-dom';
import { ResourceChip } from '../element/Chip';
import { SvgIcon } from '../element/SvgIcon';
import Logo from './../../assets/Logo/Reversednav-logo.svg';
import Tooltip from 'rc-tooltip';
import { Pill } from '../element/Pill';

import type { BbCollectedAccount } from '../../api/type.acc';
import type { Icon } from '../../assets/icons';

type MainNavProps = {
    +baseUrl: string,
    +currAccount: ?BbCollectedAccount,
    +onShowSelectAccount: ?() => void,
};
type EntryProps = { icon?: Icon, path: ?string, label: React$Node, exact?: true };

const NO_ACCOUNT_PATH = '/no-account/';

const Entry = ({ icon, path, label, exact }: EntryProps) => {
    const routeMatch = useRouteMatch({ path: path || '', exact });
    const match = routeMatch && path != null ? 'c-nav__link--current' : '';

    return (
        <li className={'c-nav__item'}>
            <NavLink exact={exact || false} to={path || NO_ACCOUNT_PATH} className={`c-nav__link ${match}`}>
                {icon ? <SvgIcon svg={icon} className='c-nav__icon' /> : null}
                {label}
            </NavLink>
        </li>
    );
};


export const MainNav = ({ baseUrl, currAccount, onShowSelectAccount, }: MainNavProps): React$Node => {
    return (
        <nav className='c-nav'>
            <div className='c-nav__logo'>
                <Link to={baseUrl + '/'}><img src={Logo} alt='Brightbox Systems Ltd' /></Link>
            </div>
            <ul className='c-nav__list'>
                <Entry path={currAccount ? `${baseUrl}/` : null} label={'Dashboard'} exact={true} />
                <li className='c-nav__sub-title'>Compute</li>
                <Entry path={currAccount ? `${baseUrl}/servers/` : null} label={'Cloud Servers'} />
                <Entry path={currAccount ? `${baseUrl}/server_groups/` : null} label={'Server Groups'} />
                <Entry path={currAccount ? `${baseUrl}/images/` : null} label={'Server Images'} />
                <li className='c-nav__sub-title'>Network</li>
                <Entry path={currAccount ? `${baseUrl}/cloud_ips/` : null} label={'Cloud IPs'} />
                <Entry path={currAccount ? `${baseUrl}/load_balancers/` : null} label={'Load Balancers'} />
                <li className='c-nav__sub-title'>Database</li>
                <Entry path={currAccount ? `${baseUrl}/database_servers/` : null} label={'Cloud SQL'} />
                <Entry path={currAccount ? `${baseUrl}/database_snapshots/` : null} label={'Snapshots'} />
                <li className='c-nav__sub-title'>Storage</li>
                <Entry path={currAccount ? `${baseUrl}/volumes/` : null} label={<span>Volumes <Pill color='dark'>New</Pill></span>} />
                <Entry path={currAccount ? `${baseUrl}/orbit/` : null} label={'Orbit'} />
                <Entry path={currAccount ? `${baseUrl}/container_registry/` : null} label={'Docker Registry'} />
            </ul>
            <div className='flex-auto'>{/* Empty div that just takes up the space to the lower menu entries */}</div>
            {currAccount != null
                ? <>
                    <ul className='c-nav__list'>
                        <li className='c-nav__sub-title'>Account</li>
                        <li className='c-nav__account'>
                            {onShowSelectAccount
                                    ? <Tooltip placement={'topLeft'} overlay={'Switch account'} mouseEnterDelay='0.2'>
                                        <div className='c-nav__account-switch-link' onClick={onShowSelectAccount}>
                                            <SvgIcon svg={'swap'} className='c-nav__account-switch-icon'/>
                                        </div>
                                    </Tooltip>
                                    : null
                            }
                            <ResourceChip
                                resource={currAccount}
                                className='c-nav__account-chip'
                                dark={true}
                                hideAccountStatus={true}
                            />
                        </li>
                        <Entry path={`${baseUrl}/billing/`} label={'Billing'}/>
                        <Entry path={`${baseUrl}/team/`} label={'Team'}/>
                        <Entry path={`${baseUrl}/settings/`} label={'Settings'}/>
                        {process.env.NODE_ENV === 'development'
                            ? <Entry path='/playground/' label='Playground' />
                            : null}
                    </ul>
                </>
                : null
                }
        </nav>
    );
};