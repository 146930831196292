// @flow
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from './Panel';
import { TextInput } from './Input';

import type { Editor } from '../common/Editor';

export type NamePanelProps = {
    +resourceTitle?: string,
    +panelTitle?: string,
    +editor: Editor<string, null, any> | Editor<string, ?string, any>,
    +nameRequired?: boolean,
    +nameNotice?: React$Node,
    +children?: ?React$Node,
    +forceNameAutoFocus?: boolean,
    +hideNameBorder?: boolean,
}


export const NameEditPanel = ({ resourceTitle, editor, nameRequired, nameNotice, panelTitle, children, forceNameAutoFocus, hideNameBorder }: NamePanelProps): React$Node => {
    const { value, setValue } = editor;
    return (
        <Panel>
            <PanelHeader title={panelTitle ? panelTitle : 'Name'} description={resourceTitle ? `What shall we call your new ${resourceTitle}?` : null}/>
            <PanelBar border={!hideNameBorder}>
                <TextInput
                    label='Name'
                    optional={!nameRequired}
                    value={value ||  ''}
                    onChange={setValue}
                    autoFocus={editor.status !== 'add' || forceNameAutoFocus}
                    errorText={editor.errors}
                />
                {nameNotice}
            </PanelBar>
            {editor.status !== 'add'
                ? <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                />
                : null
            }
            {children}
        </Panel>
    );
};
