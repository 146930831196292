// @flow
import { useMemo } from 'react';
import { TextInput } from '../element/Input';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../state/Search/search';
import { createSelector } from 'reselect';
import { ValueSelect } from '../element/ValueSelect';

import type { BbCollectedResourceTypes } from '../../api/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { SearchDefinition, SearchEditorHook } from '../element/Search';
import type { ValueSelectChoice } from '../element/ValueSelect';
import type { SearchIndexes } from '../../state/Search/search';

const NO_ZONES = {};

export const TextAndZoneSearchEditor = ({ editor }: { +editor: SearchEditorHook }): React$Node => {
    const zones = editor.fields.zoneId.zone || NO_ZONES;
    const zoneChoices: Array<ValueSelectChoice<string>> = useMemo(
        () => [{ value: '', label: 'All zones', }].concat(Object.keys(zones).map((key) => ({ value: key, label: zones[key].handle, }))),
        [zones],
    );
    const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <>
            <TextInput
                label='Filter by keyword'
                size='sm'
                preIcon='search'
                value={editor.fields.searchText.value}
                onChange={(v: string) => editor.setSearchValue('searchText', v)}
                {...post}
            />
            <ValueSelect
                selected={editor.fields.zoneId.value}
                onSelect={(value) => editor.setSearchValue('zoneId', value)}
                options={zoneChoices}
                size='sm'
            />
        </>
    );
};

// We have separate searches for the main server list, and the one used in 'server selectors'.
// To be exact - we have two, one for the list, and one in selectors.
// This function creates them. It's supported in gui/src/state/search.js by indexing the servers twice...
export const createServerSearch = <C: BbCollectedResourceTypes>(name: SearchIndexes): SearchDefinition<C> => {
    return createTextZoneSearch(name);
}

export const createTextZoneSearch = <C: BbCollectedResourceTypes>(name: SearchIndexes): SearchDefinition<C> => {
    const { result } = getSearchSelectors({ resourceName: name, resourceSelector });

    const getSearchTextServers = createSelector(
        [result,],
        (result) => ({
            searchMatchIds: result,
        }));

    const serverSearchText = createSearchAction(name);

    return {
        name,
        fields: [
            {
                searchFieldName: 'searchText',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<C>) => {
                    const { searchMatchIds, } = (getSearchTextServers(state): { searchMatchIds: Array<string> });
                    return searchMatchIds.findIndex(x => x === item.id) !== -1;
                },
                setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                    dispatch(serverSearchText(nextValue));
                },
            },
            {
                searchFieldName: 'zoneId',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<C>) => {
                    return item.zone ? item.zone.id === fieldValue : false;
                },
                filtersOn: 'zone',
            },
        ],
    };
};

