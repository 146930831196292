// @flow
import { Tooltip } from '../../element/Tooltip';
import { BlockStorageLimit } from '../../element/LimitTooltip';

import type { Gigabytes } from '../../../api/type.units';

type BlockStorageLimitWrapperProps = {
    +size: Gigabytes,
    +availableBlockStorage: Gigabytes,
    +children: React$Node,
}

export const BlockStorageLimitWrapper = ({ size, availableBlockStorage, children }: BlockStorageLimitWrapperProps): React$Node => {
    return (
        <Tooltip overlay={availableBlockStorage < size ? <BlockStorageLimit /> : null}>
            {children}
        </Tooltip>
    );
}