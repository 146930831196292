// @flow

import { Button } from '../../element/Button';
import { Dialog } from '../../element/Dialog';
import { Notice } from '../../element/Notice';
import { Textarea } from '../../element/Textarea';
import { Separator } from '../../element/Separator';
import { containerRegistryNoScheme } from '../../../api/url';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { SecretDisplay } from '../../element/SecretDisplay';
import copy from 'copy-to-clipboard';
import { TextInput } from '../../element/Input';

import type { SecretFromIdDialog, SecretDisplayDialog } from '../../hoc/Secret';
import type { ContainerAccess } from '../../../api/type.orbit';

type ApiClientSecretDialogProps = {
    +secret: SecretDisplayDialog<any>,
    +containerRegistry: false,
} |  {
    +secret: SecretFromIdDialog<any>,
    +containerRegistry: true,
    +name: string,
    +access: ?ContainerAccess
};

function flags(read: boolean, write: boolean): string {
    if (read && write) return 'push-pull';
    if (read) return 'pull';
    if (write) return 'push';
    return 'none';
}

export const ApiClientSecretDialog = (props: ApiClientSecretDialogProps): React$Node => {
    const secret = typeof props.secret.secret === 'string' ? props.secret.secret : '';

    const dockerCreds: string = props.containerRegistry && typeof props.secret.secret === 'string'
        ? `{\n  "auths":{\n    "${containerRegistryNoScheme}":{"auth":"${btoa(props.secret.secretId + ':' + secret)}"}\n  }\n}`
        : '';
    const kubernetesConfig: string = props.containerRegistry && typeof secret === 'string' && props.access && props.access.type === 'api'
        ? `apiVersion: v1
kind: Secret
metadata:
  name: ${props.access.cliId}-${props.name}-${flags(props.access.read, props.access.write)}
data:
  .dockerconfigjson: ${btoa(dockerCreds)} 
type: kubernetes.io/dockerconfigjson
`
        : '';

    const secretId: ?string = typeof props.secret.secretId === 'string' ? props.secret.secretId : null;

    return (
        <Dialog
            title='New Secret Created'
            type={props.containerRegistry ? 'wide' : 'default'}
            dialog={props.secret.dialog}
            render={() =>
            <>
                {typeof secretId === 'string'
                    ? <TextInput
                        label='API Client ID'
                        value={secretId || ''}
                        readOnly={true}
                        onChange={() => void 0}
                        autoPopulated={true}
                        focusSelectAll={true}
                        postText='Copy'
                        onPostInlayClick={() => copy(secretId)}
                        className='w-full mb-5 mr-3'
                    />
                    : null}
                <SecretDisplay label={secretId ? "API Client Secret" : null} secret={secret} />

                <Notice type='warning' icon='info-fill'>
                    Please store the new secret securely – it won't be viewable again after this dialog is closed.
                </Notice>

                {props.containerRegistry
                    ? <>
                        <Separator solid={true}>Configs</Separator>
                        <Tabs className='-mt-4'>
                            <TabList>
                                <Tab>Docker</Tab>
                                <Tab>Kubernetes</Tab>
                            </TabList>
                            <TabPanel>
                                <div className='pt-4 px-4 space-y-4'>
                                    <Textarea
                                        spellCheck={false}
                                        focusSelectAll={true}
                                        rows={5}
                                        className='font-mono text-xs w-full'
                                        value={dockerCreds}
                                        readOnly={true}
                                        onChange={() => void 0}
                                    />
                                    <Button size='sm' preIcon='download'><a download="docker.json" target="_blank" rel="noopener noreferrer" href={`data:,${dockerCreds}`}>Download Docker Config</a></Button>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='pt-4 px-4 space-y-4'>
                                <Textarea
                                        spellCheck={false}
                                        focusSelectAll={true}
                                        rows={5}
                                        className='font-mono text-xs w-full'
                                        value={kubernetesConfig}
                                        readOnly={true}
                                        onChange={() => void 0}
                                    />
                                    <Button size='sm' preIcon='download'><a download="kubernetes.json" target="_blank" rel="noopener noreferrer" href={`data:,${kubernetesConfig}`}>Download Kubernetes Config</a></Button>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </>
                    : null
                }

            </>
        }/>
    )
}