// @flow
import { Elements, } from '@stripe/react-stripe-js';
import { stripePromise } from '../lib';

export const StripeWrapper = ({ children }: { children: React$Node }): React$Node => (
    <Elements
        stripe={stripePromise}
        options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Lato&display=swap' }] }}
    >
        {children}
    </Elements>
);
