// @flow

import { useMemo } from 'react';
import { RadioGroupSetting } from '../../element/Setting/RadioGroupSetting';
import { useListResource } from '../../hoc/ListPage';
import { Notice } from '../../element/Notice';
import { Pill } from '../../element/Pill';

import type { BbZone } from '../../../api/type';
import type { ValueSelectChoice } from '../../element/ValueSelect';
import type { EditorModal } from '../../common/Editor';
import type { BbServer } from '../../../api/type.srv';
import type { ListSortDef } from '../../hoc/ListPage';

export type SelectedZone = BbZone | 'auto';

type ZoneSelectorProps = {
    +editor: EditorModal<SelectedZone, null, BbServer>
}

const zoneSort: ListSortDef<BbZone> = {
    name: 'zone_select',
    fields: {
        _default: (a: BbZone, b: BbZone) => a.name.localeCompare(b.name),
    }
};

export const ZoneSelector = ({ editor }: ZoneSelectorProps): React$Node => {
    const zones = useListResource('zone', zoneSort);
    const choices = useMemo(
        (): Array<ValueSelectChoice<SelectedZone>> => ([{
            label: <div>Auto Allocate <Pill>Recommended</Pill></div>,
            value: 'auto',
        }].concat(zones.items.map(zone => ({ label: zone.name, value: zone, })))),
        [zones.items]
    );

    return (
        <RadioGroupSetting
            title={'Zone Placement'}
            description={'Which hardware zone should this Cloud Server be created in?'}
            choices={choices}
            editor={editor}
        >
            <Notice type='info' icon='hardware'>
                Zones facilitate high availability by ensuring that your Cloud Servers are 
                located on separate host hardware
            </Notice>
        </RadioGroupSetting>
    );
};