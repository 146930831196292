// @flow
import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserProfile } from './UserProfile';
import { ApplicationCreate } from './edit/ApplicationCreate';
import { ApplicationView } from './edit/ApplicationView';
import { useCurrentUser } from '../../hoc/lib';
import { animationRouteContext } from '../../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';


export const UserSection = (): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();
    const userId = useCurrentUser()?.id;

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route exact path='/user/application/add/' component={ApplicationCreate}/>
                <Route path='/user/application/:id/' render={({ match }) =>
                    <ApplicationView id={match.params.id || ''} match={match}/>
                } />
                <Route path='/user/' render={({ match }) => userId
                    ? <UserProfile id={userId} match={match}/>
                    : <div>Loading</div>}/>
            </Switch>
        </AnimatePresence>
    );
};
