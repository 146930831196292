// @flow

import React from "react";
import { ResourceSelector } from '../../common/ResourceSelector';
import { stringSort } from '../../element/Sort';
import { ClientSearch, clientSearchDef } from '../account/edit/ClientSearch';
import { RightArrow} from '../../element/Styled';
import { Table, Td, Th, Tr } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { Link } from 'react-router-dom';
import { ResourceChip } from '../../element/Chip';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, } from '../../element/Skeleton';
import { PERMISSIONS_GROUP_LABEL, PERMISSIONS_GROUP_DESC } from './ApiClientView';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';

import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';
import type { BbApiClient } from '../../../api/type.cli';
import type { SortFields } from '../../element/Sort';

const clientSortFields: SortFields<BbApiClient> = {
    _default: stringSort<BbApiClient>('id'),
    id: stringSort<BbApiClient>('id'),
    permissions_group: stringSort<BbApiClient>('permissions_group'),
};

const ClientListTable = ({ items, Th: SortTh, search, totalCount, status, context }: ResourceSelectorTableProps<BbApiClient>) => (
    <Table>
        <thead>
        <Tr>
            <SortTh field='id'>Name</SortTh>
            <SortTh field='permissions_group'>Permissions</SortTh>
            <Th actions={true}>&nbsp;</Th>
        </Tr>
        </thead>
        <tbody>
        {items.map(app =>
            <Tr key={app.id}>
                <Td resourceName={true}>
                    <ResourceChip resource={app} link={`cli/${app.id}/`}/>
                </Td>
                <Td>
                    {PERMISSIONS_GROUP_LABEL[app.permissions_group]}
                    <Tooltip overlay={PERMISSIONS_GROUP_DESC[app.permissions_group]}>
                        <Pill>?</Pill>
                    </Tooltip>
                </Td>
                <Td actions={true}>
                    <Link to={`cli/${app.id}/`}><RightArrow/></Link>
                </Td>
            </Tr>
        )}
        <NoMatchingResourceTr search={search} colSpan={3} kind='api_client' />
        <NoResourcesTr status={status} totalCount={totalCount} colSpan={3} context={context} kind='api_client' />
        {status !== RC_CACHED
            ? <SkeletonListLoadingEntries>
                <Tr>
                    <Td resourceName={true}><SkeletonChip /></Td>
                    <Td><SkeletonBar size='sm' /></Td>
                    <Td actions={true}><SkeletonBar size='md' /></Td>
                </Tr>
            </SkeletonListLoadingEntries>
            : null
        }

        </tbody>
    </Table>
);


export const ApiClientList = (props: {}): React$Node => (
    <ResourceSelector
        kind='api_client'
        context='list'
        addButton={{
            url: 'cli/add',
            resourceName: 'API client',
        }}
        table={ClientListTable}
        searchDef={clientSearchDef}
        searchComponent={ClientSearch}
        sortFields={clientSortFields}
    />
);