// @flow

import Logo from '../../assets/Logo/Reversednav-logo.svg';


export const LoginSidebar = (): React$Node => {
    return (
        <div className='c-sidebar  c-sidebar--auth  c-sidebar--login'>
            <div className='c-sidebar__content'>
                <img src={Logo} className='c-sidebar__logo' alt='Brightbox Logo' />
            </div>
        </div>
    );
};

export const SignupSidebar = (): React$Node => {
    return (
        <div className='c-sidebar  c-sidebar--auth  c-sidebar--signup'>
            <div className='c-sidebar__content'>
                <img src={Logo} className='c-sidebar__logo' alt='Brightbox Logo' />

                    <ul className='c-sidebar__fancy-list'>
                        <li className='c-sidebar__fancy-list-item'>Get started in seconds</li>
                        <li className='c-sidebar__fancy-list-item'>Simple, fast and flexible cloud infrastructure</li>
                        <li className='c-sidebar__fancy-list-item'>Clear and simple pricing</li>
                    </ul>
            </div>              
        </div>

    );
};