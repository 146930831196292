// @flow
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ResourceChip } from '../../element/Chip';
import { createServerSearch, TextAndZoneSearchEditor } from '../../common/TextAndZoneSearch';
import { formatDate, RightArrow } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { serverSortFields, serverSelectableFilter } from './def';
import { useLockDeleteListActions } from '../../hoc/ListActions';
import { ResourceCloudIpTd } from '../../common/ResourceCloudIps';
import { Pill } from '../../element/Pill';
import { Tooltip } from '../../element/Tooltip';
import { Card } from '../../element/Card';
import { TypeCardContent } from '../../element/TypeCardContent';
import { getMonthlyPrice } from '../../../lib/pricing';
import { RelatedIndicator } from '../../element/RelatedIndicator';
import { Button } from '../../element/Button';
import { RC_CACHED, } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, SkeletonListRelated } from '../../element/Skeleton';
import { ItemSelector } from '../../element/ItemSelector';
import { useResourceRoutes } from '../../../lib/history';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';
import { useResourcesById } from '../../hoc/ListPage';
import { Notice } from '../../element/Notice';
import { ResourceLink } from '../../element/ResourceLink';

import type { BbCollectedServer } from '../../../api/type.srv';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';
import type { BbVolume } from '../../../api/type.volume';

const searchDef = createServerSearch('server:list');

const groupsEditLink = (id: string) => ({kind: 'server_groups', id, label: 'Edit Groups'});

export const ServerListTable = ({ items, Th: SortTh, itemSelect, search, status, totalCount, context, }: ResourceSelectorTableProps<BbCollectedServer>): React$Node => {
    const getRoute = useResourceRoutes();

    const listContext = context === 'list';

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={6}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='id'>Name</SortTh>
                        <SortTh field='cloud_ips'>Cloud IPs</SortTh>
                        <SortTh field='server_groups'>Groups</SortTh>
                        <SortTh field='server_type'>Type</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((server: BbCollectedServer) =>
                <Tr
                    key={server.id}
                    {...itemSelect?.rowProps(server)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={server} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}

                    <Td resourceName={true}>
                        <ResourceChip resource={server} link={itemSelect?.selectedLength === 0 ? getRoute('server', server.id) : null} />
                    </Td>
                    <Td>
                        <ResourceCloudIpTd
                            id={server.id}
                            cloud_ips={server.cloud_ips}
                            canAdd={listContext && (server.status === 'creating' || server.status === 'active' || server.status === 'inactive')}
                        />
                    </Td>
                    <Td>
                        {(server.status === 'active' || server.status === 'inactive') && server.server_groups.length === 0
                            ? <Link to={`${server.id}/server_groups/`}>
                                <Button kind='bare'>+ Add</Button>
                            </Link>
                            : <RelatedIndicator id={server.id} related={server.server_groups} editLink={groupsEditLink(server.id)} />
                        }
                    </Td>
                    <Td>{server.server_type.handle
                            ? <span className='font-mono text-sm'>{server.server_type.handle}</span>
                            : <span>{server.server_type.name}</span>
                        }
                        <Tooltip background='light' overlay={
                            <Card>
                                <TypeCardContent
                                    id={server.server_type.id}
                                    ram={server.server_type.ram}
                                    cpus={server.server_type.cores}
                                    disk={server.server_type.disk_size}
                                    cost={getMonthlyPrice(server.server_type.id)}
                                    handle={server.server_type.handle}
                                    resourceTitle='Cloud Servers'
                                />
                            </Card>
                        }>
                            <Pill>?</Pill>
                        </Tooltip>
                    </Td>
                    <Td>{formatDate(server.created_at)}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={getRoute('server', server.id)}><RightArrow /></Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonListRelated /></Td>
                        <Td><SkeletonListRelated /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={7} kind='server' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={7} context={context} kind='server' />
            </tbody>
        </Table>
    );
};

export const ServerList = (): React$Node => {
    const { deleteDialog, lockDialogs, ...selector } = useLockDeleteListActions('server', 'servers', serverSelectableFilter);
    const { resources: servers } = useResourcesById<BbCollectedServer>('server');
    const { resources: volumes } = useResourcesById<BbVolume>('volume');
    const selection = selector.editor?.value;

    const deleteWithServerServerIds = useMemo(
        () => (selection || []).filter(serverId => (null != servers[serverId]?.volumes.find(nested => volumes[nested.id]?.storage_type === 'network' && volumes[nested.id]?.delete_with_server)))
    , [servers, selection, volumes]);

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Cloud Servers?`}
                confirmString='delete'
                render={() => (
                    <>
                        <p>Are you sure you want to delete these Cloud Servers?</p>
                        {deleteWithServerServerIds.length
                            ? <Notice type='warning' icon='warning-fill'>
                                <p>Cloud Servers ({deleteWithServerServerIds.map((id, index) => <>{index ? ', ' : ''}<ResourceLink id={id} /></>)})
                                    have volumes currently configured as <b className='mx-1'><code>delete_with_server</code></b> which means
                                    they will also be permanently deleted along with the servers.</p>
                            </Notice>
                            : null
                        }
                    </>
                )
                }
            />
            <LockDialogs name={'these Cloud Servers'} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog} count={selector.editor?.value?.length} />
            <ResourceListPage
                title="Cloud Servers"
                context="list"
                kind="server"
                table={ServerListTable}
                searchDef={searchDef}
                searchComponent={TextAndZoneSearchEditor}
                sortFields={serverSortFields}
                checkLimit={'ram'}
                selector={selector}
            />
        </>
    );
}