// @flow
import { useState, useEffect, useCallback } from 'react';
import { useDialog } from '../element/Dialog';
import { RC_SUCCESS } from '../../state/resource/type';
import { useTriggeredMessages } from './Messages';
import { useDispatch } from 'react-redux';

import type { Dispatch } from 'redux';
import type { BbServer } from '../../api/type.srv';
import type { ResourceCreateKindAction } from '../../state/resource/type';
import type { MessageHook } from './Messages';
import type { PanelHeaderActions } from '../element/Panel';
import type { DialogState } from '../element/Dialog';
import type { BbImage } from '../../api/type';

export type SnapshotHook<Res: BbServer | BbImage> = {
    +actions: PanelHeaderActions,
    +snapshotDialog: DialogState<null>,
    +snapshotDetailsDialog: DialogState<BbImage>,
    +messages: ?MessageHook<Res>
}

export function useSnapshot<Res: BbServer | BbImage>(
    performSnapshot: () => void,
    apiResult: ?MessageHook<Res>,
    hasCreatingSnapshot: boolean,
    isAllocated: boolean,
): SnapshotHook<Res> {
    const actions: PanelHeaderActions = {
        snapshot: null,
    };

    // the 'apiResult' could be from a request after the initial snapshot one.
    // so use this flag + presence of a 'creating' one to trigger the details dialog.
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const snapshotDialog = useDialog([{
        label: 'Create Snapshot',
        kind: 'primary',
        onSelect: () => {
            performSnapshot();
            setShowDetails(true);
        },
    }]);
    const snapshotDetailsDialog = useDialog([]);
    const { show } = snapshotDetailsDialog;

    useEffect(() => {
        const resource = apiResult?.resource;
        if (resource?.resource_type === 'server') {
            const creatingImage = resource.snapshots?.find(x => x.status === 'creating');
            if (
                showDetails
                && apiResult?.status === RC_SUCCESS
                && creatingImage
            ) {
                setShowDetails(false);
                show(creatingImage);
            }
        }
        if (resource?.resource_type === 'image') {
            if (
                showDetails
                && apiResult?.status === RC_SUCCESS
            ) {
                setShowDetails(false);
                show(resource);
            }
        }

    }, [showDetails, apiResult, setShowDetails, show]);

    if (!hasCreatingSnapshot && isAllocated) {
        actions['snapshot'] = () => {
            snapshotDialog.show();
        };
    }

    return {
        snapshotDialog,
        snapshotDetailsDialog,
        actions,
        messages: apiResult,
    };
}


type ImageFromVolumeHook = {
    create: () => void,
    messages: ?MessageHook<BbImage>,
}

export function useImageFromVolume(volId: string): ImageFromVolumeHook {
    const { next, messages } = useTriggeredMessages();
    const dispatch = useDispatch<Dispatch<ResourceCreateKindAction>>();

    const create = useCallback(() => {
        const messagesId = next();

        dispatch({
            type: 'RESOURCE_CREATE_KIND',
            payload: {
                kind: 'image',
                params: {
                    "arch": "x86_64",
                    "volume": volId,
                },
                cloudIp: false,
                messagesId,
            }
        });
    }, [dispatch, next, volId]);

    return {
        create, messages
    }
}