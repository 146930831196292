// @flow
import { StandardResourceSection } from '../StandardResourceSection';
import { CipList } from './CipList';
import { CipView } from './CipView';
import { Redirect } from 'react-router';

import type { Location, Match } from 'react-router-dom';

export const CipSection = ({ match }: { location: Location, match: Match }): React$Node => (
    <StandardResourceSection match={match} list={CipList} create={() => <Redirect to='../?add' />} view={CipView} />
);