// @flow
import { useMemo } from 'react';
import { ipv4Sort, stringSort } from '../../element/Sort';
import numeral from 'numeral';
import { useResourceIdSelector } from '../../hoc/ListSelection';
import { useResourcesById } from '../../hoc/ListPage';
import { useDispatch } from 'react-redux';
import { useDeleteDialog } from '../../common/CommonDialogs';
import { Button } from '../../element/Button';
import { useDialog } from '../../element/Dialog';

import type { BbCloudIp } from '../../../api/type.cip';
import type { SortFields } from '../../element/Sort';
import type { Dispatch } from 'redux';
import type { ActionQueueAction } from '../../../state/ActionQueue/type';
import type { ItemSelectOptions } from '../../common/ResourceSelector';
import type { DialogState } from '../../element/Dialog';

const ipv6ToComparable = (ipv6: string) => ipv6.split(':')
    .map(s => numeral(Number('0x' + s)).format('000000'))
    .join(':');

export const ipDestStr = (ip: BbCloudIp): string => (
    ip.server?.name || ip.server?.id ||
    ip.load_balancer?.name || ip.load_balancer?.id ||
    ip.database_server?.name || ip.database_server?.id ||
    ip.server_group?.name || ip.server_group?.id ||
    ''
)

export const cloudIpSortFields: SortFields<BbCloudIp> = {
    _default: stringSort<BbCloudIp>('id'),
    id: stringSort<BbCloudIp>('id'),
    name: stringSort<BbCloudIp>('name'),
    public_ipv4: ipv4Sort<BbCloudIp>('public_ipv4'),
    public_ipv6: (a: BbCloudIp, b: BbCloudIp) => ipv6ToComparable(a.public_ipv6).localeCompare(ipv6ToComparable(b.public_ipv6)),
    destination: (a: BbCloudIp, b: BbCloudIp) => {
        return ipDestStr(a).localeCompare(ipDestStr(b));
    },
};


type CloudIpListActions = {
    ...ItemSelectOptions<BbCloudIp>,
    +deleteDialog: DialogState<null>,
    +unmapDialog: DialogState<null>,
}

function allIpsSelectable(ip: BbCloudIp) {
    return true;
}

export const useCloudIpListActions = (): CloudIpListActions => {
    const { selected, editor } = useResourceIdSelector('cloud_ip');
    const { resources: cloud_ips } = useResourcesById<BbCloudIp>('cloud_ip');
    const dispatch = useDispatch<Dispatch<ActionQueueAction>>();

    const [, deleteDialog] = useDeleteDialog(
        true,
        () => {
            selected.forEach(id => {
                if (cloud_ips[id]?.status === 'mapped') {
                    dispatch({
                        type: 'ACTION_QUEUE_ADD',
                        payload: {
                            action: {
                                kind: 'cloud_ip',
                                id,
                                method: 'POST',
                                action: 'unmap',
                            }
                        }
                    });
                }

                dispatch({
                    type: 'ACTION_QUEUE_ADD',
                    payload: {
                        action: {
                            kind: 'cloud_ip',
                            id,
                            method: 'DELETE',
                            action: null,
                        }
                    }
                });

            });
            editor.setValue([]);
        },
        'Delete',
    );

    const unmapDialog = useDialog([{
        label: 'Unmap',
        kind: 'primary',
        color: 'red',
        onSelect: () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected.filter(x => cloud_ips[x]?.status === 'mapped'),
                    kind: 'cloud_ip',
                    method: 'POST',
                    action: 'unmap',
                }
            });
        }
    }])

    const hasMappedAddresses: boolean = useMemo(
        () => selected.findIndex(
            id => cloud_ips[id]?.status === 'mapped'
        ) !== -1,
        [selected, cloud_ips]
    );

    return {
        editor,
        multiItemActions: [
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: hasMappedAddresses ? null : () => deleteDialog.show(),
                },
                disabledTooltip: hasMappedAddresses
                    ? <div>
                        Your selection includes mapped Cloud IPs which must be unmapped before they can be deleted<br/>
                        <Button className='my-2' color='white' size='xs' onClick={() => unmapDialog.show()}>Unmap Now?</Button>
                    </div>
                    : null
                ,
            },
            {
                label: 'Unmap',
                buttonProps: {
                    color: 'red',
                    onClick: hasMappedAddresses ? () => unmapDialog.show() : null,
                },
                disabledTooltip: hasMappedAddresses
                    ? null
                    : <div>
                        There are no mapped Cloud IPs in your selection
                    </div>
                ,
            },
        ],
        selectableFilter: allIpsSelectable,
        deleteDialog,
        unmapDialog,
    };
};