// @flow

import { useState } from 'react';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { Textarea } from '../../element/Textarea';
import { FileInput } from '../../element/FileInput';
import { Notice } from '../../element/Notice';
import { Button } from '../../element/Button';
import { getTrimmedName } from '../../element/Chip';
import { Checkbox } from '../../element/Checkbox';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';

import type { EditorModal } from '../../common/Editor';
import type { BbServer, UserDataEdit } from '../../../api/type.srv';

type UserDataEditorProps = {
    +editor: EditorModal<UserDataEdit, null, BbServer>,
}

// any control chars to test for non-plain-text-i-ness...
// eslint-disable-next-line no-control-regex
const NON_PLAIN_TEXT = new RegExp('[\x00-\x08\x0b\x0c\x0e-\x1f]');

export const UserDataEditor = ({ editor }: UserDataEditorProps): React$Node => {
    const { value, setValue, errors, setErrors } = editor;
    const [forcePlainText, setForcePlainText] = useState<boolean>(false);

    if (value == null) return null;

    const isPlainText = !NON_PLAIN_TEXT.exec(value?.value);

    const onClear = () => {
        setValue({ value: '', isBase64Encoded: false, });
        setForcePlainText(false);
        setErrors(null);
    };

    const onPaste = (pasted: string, e: ?ClipboardEvent) => {
        if (
            value.value === ''
            || (
                e && e.currentTarget instanceof HTMLTextAreaElement
                && e.currentTarget.selectionStart === 0
                && e.currentTarget.selectionEnd === e.currentTarget.value.length
        )
        ) {
            setForcePlainText(false);
            setValue({
                ...value,
                value: pasted,
            });
            setErrors(null);

            return true;
        }
    };

    const onFileSelect = (fileContent: string) => {
        setForcePlainText(false);
        setValue({ ...value, value: fileContent });
        setErrors(null);
    }

    return (
        <>
            <Panel>
                <PanelHeader title="User Data"/>
                <PanelBar>
                <div className='max-w-2xl'>
                    {isPlainText || forcePlainText
                        ? <Textarea
                            label="User Data"
                            value={value.value}
                            onChange={(data) => setValue({ value: data, isBase64Encoded: value.isBase64Encoded })}
                            onPaste={onPaste}
                            autoFocus={true}
                            errorText={errors}
                            rows={7}
                            className='w-full font-mono text-sm'
                        />
                        : null
                    }
                    {!isPlainText && !forcePlainText
                        ? <>
                            <Notice type='warning' icon='info'>
                                The user data appears to contain binary data 
                                <Button className='ml-2' size='sm' onClick={() => setForcePlainText(true)}>Force Plain Text?</Button>
                            </Notice>

                        </>
                        : null
                    }
                    <Checkbox
                        checked={value.isBase64Encoded}
                        onChange={(isBase64Encoded) => setValue({ ...value, isBase64Encoded })}
                        className='ml-4 mb-5 text-sm leading-6'
                        label={
                            <span>
                                This user data is already base64 encoded
                                <Tooltip
                                    overlay={
                                        <div>
                                            <p className='mb-2'>The API requires User Data to be base64 encoded, so by default we do this automatically.</p>
                                            <p>If the user data you've entered is already base64 encoded, check this box to prevent double-encoding.</p>
                                        </div>
                                    }>
                                    <Pill>?</Pill>
                                </Tooltip>
                            </span>
                        }
                    />
                    <FileInput
                        label='Upload User Data File?'
                        onChange={(filename, content) => {
                            onFileSelect(content);
                        }}
                        onClear={() => {
                            onClear();
                        }}
                        errorText={!isPlainText && !forcePlainText ? errors : null}
                        value={null}
                    />
                </div>
                </PanelBar>
                <PanelButtonBar
                    primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                    leftButton={{ children: 'Clear', preIcon: 'arrow-left-up', onClick: onClear }}
                />
            </Panel>
        </>
    );
};

export const UserDataSummary = (props: { +encoded?: ?string, +edit?: UserDataEdit, }): React$Node => {
    let decoded: ?string;
    if (props.hasOwnProperty('encoded')) {
        const encoded = props.encoded || '';
        try {
            decoded = atob(encoded);
        } catch (err) {
            return <pre className='font-mono text-xs'>base64 decode error; {getTrimmedName(encoded)}</pre>
        }
        if (encoded === '' || decoded == null) return 'None';
    }

    if (props.edit != null) {
        const { edit } = props;
        if (edit.isBase64Encoded) {
            try {
                decoded = atob(edit.value);
            } catch (err) {
            }
        } else {
            decoded = edit.value;
        }
    }

    if (decoded == null) return 'None';

    const isPlainText = !NON_PLAIN_TEXT.exec(decoded);

    if (isPlainText) {
        return <pre className='font-mono text-xs'>{getTrimmedName(decoded)}</pre>;
    }

    return <>Binary: (not shown)</>;
};