// @flow
import { Redirect, Route, } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

import type { ContextRouter, } from 'react-router-dom';

const OBJECT_VIEW = '/accounts/:accountId/orbit/container/:name/:browsing(objects|browse)/:path/';

export const EnsureTrailingSlash = (): React$Node => {
    const match = useRouteMatch(OBJECT_VIEW);
    if (match) return null;

    return (
        <Route path="/:url*" exact strict render={(props: ContextRouter) => <Redirect to={`${props.location.pathname}/`}/>}/>
    );
};
