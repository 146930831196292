// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { Textarea } from '../../../element/Textarea';

import type { BbServer } from '../../../../api/type.srv';
import type { EditorModal } from '../../../common/Editor';

type UserSshKeyEditorProps = {
    +editor: EditorModal<string, null, BbServer>,
}

export const UserSshKeyEditor = ({ editor }: UserSshKeyEditorProps): React$Node => {
    const { value, setValue } = editor;
    return (
        <Panel>
            <PanelHeader title='SSH Key'/>
            <PanelBar>
                <Textarea
                    label='SSH Public Key'
                    rows={5}
                    spellCheck={false}
                    className='break-all w-full max-w-3xl'
                    value={value || ''}
                    helperText='Paste your public key(s) e.g. ssh-rsa abcdef123123...'
                    onChange={setValue}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>

    );
};