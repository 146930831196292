// @flow
import { useState, useMemo, useEffect } from 'react';
import { serverTypeToTypeType } from '../../../../lib/ServerTypeGroup';
import { PanelBar } from '../../../element/Panel';
import { ToggleGroup } from '../../../element/ToggleGroup';
import { ServerTypeTabPanel } from './ServerTypeTabPanel';

import type { BbServerType, BbCollectedImage, BbImage } from '../../../../api/type';
import type { ServerTypeGroups } from '../../../../lib/ServerTypeGroup';
import type { BbVolume } from '../../../../api/type.volume';

type LocalStorageTypeSelectorProps = {
    +selected: ?BbServerType,
    +onSelect: (type: BbServerType) => void,
    +allChoices: ServerTypeGroups,
    +availableMemory: number,
    +selectedImage: ?BbCollectedImage | ?BbImage,
    +selectedVolume: ?BbVolume,
    +current?: BbServerType,
    +isInactive?: boolean,
}

export const LocalStorageTypeSelector = ({ selected, onSelect, allChoices, availableMemory, selectedImage, selectedVolume, current, isInactive, }: LocalStorageTypeSelectorProps): React$Node => {

    const [ssdTypeFilter, setSsdTypeFilter] = useState('ssd');
    const ssdTypeGroups = useMemo(() => [
        { value: 'ssd', label: allChoices.ssd.label, },
        { value: 'ssd-ram-opt', label: allChoices['ssd-ram-opt'].label, },
        { value: 'ssd-stg-opt', label: allChoices['ssd-stg-opt'].label, },
    ], [allChoices]);

    useEffect(() => {
        if (selected != null) {
            const typeType = serverTypeToTypeType(selected.storage_type, selected.handle);
            if (typeType.startsWith('ssd')) {
                setSsdTypeFilter(typeType);
            }
        } else if (current != null) {
            const typeType = serverTypeToTypeType(current.storage_type, current.handle);
            if (typeType.startsWith('ssd')) {
                setSsdTypeFilter(typeType);
            }
        }
        
    }, [current, selected, setSsdTypeFilter]);

    return (
        <>
            <PanelBar border={false} className="c-panel__section--toggle-group">
                <ToggleGroup
                    options={ssdTypeGroups}
                    selected={ssdTypeFilter}
                    onSelect={setSsdTypeFilter}
                />
            </PanelBar>

            <ServerTypeTabPanel
                choices={allChoices[ssdTypeFilter].types}
                selected={selected}
                onSelect={onSelect}
                availableMemory={availableMemory}
                selectedImage={selectedImage}
                selectedVolume={selectedVolume}
                current={current}
                isInactive={isInactive}
            />
        </>
    );
};