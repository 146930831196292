// @flow

import { useMemo } from 'react';
import { PAGING_PAGE_SIZE_CHOICES } from './lib/paging';
import { ValueSelect } from './ValueSelect';
import { PanelBar } from './Panel';
import { Button } from './Button';

export type PagerBarProps = {
    showPagerBar: boolean,
    unfilteredCount: number,
    childrenCount?: ?number,

    setPage: (pageNo: number) => void,
    setPageSize: (pageSize: number) => void,

    nextPage: ?() => void,
    prevPage: ?() => void,
    pageSize: number,

    current: {
        currentPage: number,
        itemCount: number,
        pageCount: number,
        pageChoices: Array<[number, number]>;
    },
};

const MAX_PAGE_BUTTONS = 5;

export const PagerBar = ({ setPage, setPageSize, pageSize, nextPage, prevPage, current, unfilteredCount, childrenCount, showPagerBar }: PagerBarProps): React$Node => {
    const pageOptions = useMemo(
        () => current.pageChoices.map(([pageNo,]) => ({ label: ('Page ' + (pageNo + 1).toString() + ' of ' + current.pageCount), value: pageNo })),
        [current.pageChoices, current.pageCount],
    );

    if (!showPagerBar) {
        return null;
    }

    return (
        <PanelBar className='c-panel__section--pager'>
            <div className='c-pager'>
                <div className='c-pager__options'>
                    <div className='c-pager__summary'>
                        {(unfilteredCount !== current.itemCount)
                            ? <span>Showing {current.itemCount} of {unfilteredCount} items</span>
                            : <span>{current.itemCount} items {childrenCount ? ` (plus ${childrenCount} children)` : null}</span>
                        }
                    </div>
                    <div className='c-pager__page-size'>
                        <ValueSelect
                            size='xs'
                            selected={pageSize}
                            onSelect={(v) => setPageSize(Number(v))}
                            options={PAGING_PAGE_SIZE_CHOICES}
                        />
                    </div>
                </div>

                {(current.pageCount > 1 && current.pageCount <= MAX_PAGE_BUTTONS)
                    ? <div className='c-pager__nav'>
                        {current.pageChoices.map(([pageNo,]) =>
                            <Button size='sm' color='dark-grey' key={pageNo} className={'c-pager__button' + (pageNo === current.currentPage ? ' c-pager__button--active' : '')}
                                    onClick={() => setPage(pageNo)}
                            >
                                {pageNo + 1}
                            </Button>
                        )}
                    </div>
                    : null}
                {current.pageCount > MAX_PAGE_BUTTONS
                    ? <div className='c-pager__nav'>
                        <ValueSelect
                            size='xs'
                            selected={current.currentPage}
                            onSelect={(value) => setPage(Number(value))}
                            options={pageOptions}
                        />
                        <Button size='sm' color='dark-grey' preIcon='caret-left' onClick={prevPage} disabled={!prevPage} className='c-pager__button'>Prev</Button>
                        <Button size='sm' color='dark-grey' postIcon='caret-right' onClick={nextPage} disabled={!nextPage} className='c-pager__button'>Next</Button>
                    </div>
                    : null}

            </div>
        </PanelBar>
    );
};