// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr } from '../../../element/NoResourceMessages';
import { RemoveButton } from '../../../element/Button';
import { ValueSelect } from '../../../element/ValueSelect';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';
import { isShowDetailsResourceStatus, SkeletonBar, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { EditPortTranslator } from './type';
import type { BbCloudIp, BbPortTranslatorProtocol } from '../../../../api/type.cip';
import type { FormErrors } from '../../../common/lib';
import type { Editor } from '../../../common/Editor';
import type { ResourceCacheStatus } from '../../../../state/resource/type';

export type PortTranslatorListProps = {
    +editor: Editor<$ReadOnlyArray<EditPortTranslator>, FormErrors, BbCloudIp>,
    +cacheStatus: ResourceCacheStatus,
};

const protocols: Array<ValueSelectChoice<BbPortTranslatorProtocol>> = [
    { value: 'tcp', label: 'TCP' },
    { value: 'udp', label: 'UDP' },
];

export const emptyPortTranslator = { incoming: '', outgoing: '', protocol: 'tcp', };

export const CipPortTranslatorsEdit = ({ editor, cacheStatus, }: PortTranslatorListProps): React$Node => {
    // if (!editor.value) return null;

    const { setValue: setEditPortTranslators } = editor;
    const editPortTranslators = editor.value || [];

    const setPortTranslatorValue = (idx: number, value: $Shape<EditPortTranslator>) => {
        if (idx >= 0 && idx < editPortTranslators.length) {
            const next: Array<EditPortTranslator> = [].concat(editPortTranslators);
            next[idx] = { ...next[idx], ...value };
            setEditPortTranslators(next);
        }
    };

    const addPortTranslator = () => {
        setEditPortTranslators([].concat(editPortTranslators, { ...emptyPortTranslator }));
    };

    const removePortTranslator = (idx: number) => {
        setEditPortTranslators([].concat(
            idx ? editPortTranslators.slice(0, idx) : [],
            editPortTranslators.slice(idx + 1),
        ));
    };

    return (
        <>
        <Notice type='info' icon='pin' className='mb-8'>Cloud IP port translation enables you to change the destination port of an incoming TCP or UDP connection. <a href='https://www.brightbox.com/docs/reference/cloud-ips/#port-translation' target='blank'>Learn more</a></Notice>
        <Panel>
            <PanelHeader
                title='Port Translators'
                description='How should this Cloud IP translate ports?'
            />
            <PanelBar padding={false}>
                <Table editor={true}>
                    <thead>
                    <Tr>
                        <Th min={true} hint='Which port is traffic arriving on?'>From</Th>
                        <Th min={true} hint='Which port on the target resource should the Cloud IP translate traffic to?'>To</Th>
                        <Th min={true}>Protocol</Th>
                        <Th stretch={true}>&nbsp;</Th>
                    </Tr>
                    </thead>
                    <tbody>
                    {!isShowDetailsResourceStatus(cacheStatus) || !editor.value
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='xs' /></Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : null
                    }
                    {editPortTranslators.map((portTranslator, idx: number) =>
                        <Tr key={idx}>
                            <Td min={true}>
                                <TextInput
                                    size='xs'
                                    autoFocus={true}
                                    inputMode='numeric' pattern='[0-9 ,.]*'
                                    value={portTranslator.incoming}
                                    onChange={(incoming) => setPortTranslatorValue(idx, { incoming })}
                                    errorText={editor.errors.get('incoming_' + idx)}
                                />
                            </Td>
                            <Td min={true}>
                                <TextInput
                                    size='xs'
                                    inputMode='numeric' pattern='[0-9 ,.]*'
                                    value={portTranslator.outgoing}
                                    onChange={(outgoing: string) => setPortTranslatorValue(idx, { outgoing })}
                                    errorText={editor.errors.get('outgoing_' + idx)}
                                />
                            </Td>
                            <Td>
                                <ValueSelect
                                    size="xs"
                                    selected={portTranslator.protocol}
                                    options={protocols}
                                    onSelect={(value: BbPortTranslatorProtocol) => setPortTranslatorValue(idx, { protocol: value })}
                                    errorText={editor.errors.get('protocol_' + idx)}
                                />
                            </Td>
                            <Td>
                                <RemoveButton size='sm' onClick={() => removePortTranslator(idx)} />
                            </Td>
                        </Tr>)}
                    </tbody>
                    <NoResourcesTr
                        status={cacheStatus}
                        colSpan={4}
                        title='port translators'
                        totalCount={editPortTranslators.length}
                        context=''
                    />
                </Table>
            </PanelBar>
            {editor.status === 'add'
                ? <PanelButtonBar
                    leftButton={{ children: 'Add Port Translator', kind: 'bare', preIcon: 'add-fill', onClick: addPortTranslator }}
                />
                : <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: editor.status ? editor.onSave : null}}
                    cancel={editor.status ? editor.onCancel : null}
                    leftButton={{ children: 'Add Port Translator', kind: 'bare', preIcon: 'add-fill', onClick: editor.status ? addPortTranslator : null }}
                />
            }
        </Panel>
        </>
    );
};

