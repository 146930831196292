// @flow

import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../lib/history';
import { formatMegabytesSize } from './Styled';
import { TooltipButton, Button } from './Button';

export const AccountPending = ({ resourceName }: { resourceName: string }): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>
            <p>Activate your account to create your first {resourceName}</p>

            <Link to={getRoute('card')}>
                <Button kind='primary'>Activate My Account</Button>
            </Link>
        </div>
    );
};

export const AccountClosed = (): React$Node => (<div>This account is closed</div>);

export type LimitProps = {
    +selectedType?: boolean,
    +children?: ?React$Node,
};

export const CloudIpLimit = (): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>
            You have reached your current Cloud IP limit<br/>
            <Link to={getRoute('limit', 'cloud_ip')}><TooltipButton>Request Increase</TooltipButton></Link>
        </div>
    );
};

export const DatabaseLimit = ({ selectedType }: LimitProps): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>
            {selectedType
                ? <>This size exceeds your current Cloud SQL account limit<br/></>
                : <>You have reached your current Cloud SQL account limit<br/></>
            }
            <Link to={getRoute('limit', 'database_server')}><TooltipButton>Request Increase</TooltipButton></Link>
        </div>
    );
};

export const LoadBalancerLimit = (): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>You have reached your Load Balancer account limit<br/>
            <Link to={getRoute('limit', 'load_balancer')}>Request an increase</Link>
        </div>
    );
};

export const ServerLimit = ({ selectedType }: LimitProps): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>
            {selectedType
                ? <>This server type exceeds your current Cloud Server account limit<br/></>
                : <>You have reached your current Cloud Server account limit<br/></>
            }
            <Link to={getRoute('limit', 'server')}><TooltipButton>Request Increase</TooltipButton></Link>
        </div>
    );
};

export const ServerDiskDoesntFit = ({ min_disk, disk }: { min_disk: number, disk: number }): React$Node => {
    return (
        <div>
            Servers can't be resized to a server type with a smaller disk –
            the server <b>currently has {formatMegabytesSize(min_disk)} SSD</b> but this
            server type only includes {formatMegabytesSize(disk)}
        </div>
    );
}

export const BlockStorageLimit = ({ isCopy }: { isCopy?: boolean }): React$Node => {
    const getRoute = useResourceRoutes();

    return (
        <div>
            Your account does not have enough Block Storage allocation remaining to {isCopy ? 'copy this volume' : 'create a new Volume'}<br/>
            <Link to={getRoute('limit', 'block_storage')}><TooltipButton>Request Increase</TooltipButton></Link>
        </div>
    );
};

export const ImageMinimumRam = ({ min_ram, ram }: { min_ram: number, ram: number }): React$Node => (
    <div>The Image you have selected requires at least {formatMegabytesSize(min_ram)} RAM but this server type only has {formatMegabytesSize(ram)}</div>
);

export const ServerShrinkHint = (): React$Node => (
    <div>To resize to a smaller RAM/CPU size the server must first be shutdown (i.e. have a status of <b><code>inactive</code></b>)</div>
);

export const ZoneRestrictionTooltip = (): React$Node => (
    <div>Volumes can't currently be created in the same zone as this Server</div>
);

export const ZoneNbsRestrictionTooltip = ({ zoneHandle, serverId }: { zoneHandle: ?string, serverId: ?string }): React$Node => {
    return (
        <p>
            Volumes can't currently be attached to {serverId} because the zone in
            which it's located ({zoneHandle}) doesn't yet support Block Storage.
        </p>
    )
};
