// @flow

import { cssTransition, ToastContainer } from 'react-toastify';
import { SvgIcon } from './SvgIcon';

const animateToast = cssTransition({
    enter: 'u-animate--slide-in-left',
    exit: 'u-animate--fade-out',
    duration: [200],
});

export const AppToastContainer = (): React$Node =>  (
    <ToastContainer
        position='top-right'
        transition={animateToast}
        limit={5}
        hideProgressBar={false}
        closeButton={<SvgIcon svg='cross' className='Toastify__close-button'/>}
    />
);