// @flow

import type {
    BbAllResourceTypes,
    BbCollaboration, BbEvent, BbImage,
    BbInterface,
    BbResourceKind,
    BbServerGroup,
    BbServerType,
    BbTest,
    BbUser,
    BbZone, BbServerGroupCreateParams, BbCollectedResourceTypes,
} from '../../api/type';
import type { BbCollectedDatabaseServer, BbDatabaseServer, BbDatabaseSnapshot, BbDatabaseType } from '../../api/type.dbs';
import type { BbCollectedLba, BbLba } from '../../api/type.lba';
import type { BbCloudIp, CipAssignParams } from '../../api/type.cip';
import type { BbFirewallPolicy, BbFirewallPolicyCreateParams, BbFirewallRule, } from '../../api/type.fwp';
import type { BbApplication, BbCollectedApplication } from '../../api/type.app';
import type { BbAccount, BbCollectedAccount } from '../../api/type.acc';
import type { BbApiClient } from '../../api/type.cli';
import type { BbCollectedServer, BbServer } from '../../api/type.srv';
import type { BbVolume } from '../../api/type.volume';

// we haven't hit the API for this
export const RC_INITIAL: 0 = 0;
// we're fetching it, maybe it will be there
export const RC_FETCHING: 10 = 10;
// we have a locally cached copy
export const RC_CACHED: 20 = 20;
// orbit container details only - name, total size, etc
export const RC_ORBIT_CONTAINER: 21 = 21;
// orbit container with items too...
export const RC_ORBIT_OBJECTS: 22 = 22;
// we have a locally cached copy, and we're fetching it again - probably because of an event
export const RC_REFRESHING: 30 = 30;
// we have a locally cached copy, and we're in the middle of a POST/PUT/etc to it to request a change.
export const RC_API_REQUEST: 31 = 31;
// we made a request, and it wasn't found.
export const RC_NOT_FOUND: 40 = 40;
// something went wrong - perhaps loss of network connection?
export const RC_ERROR: 50 = 50;
// api call success
export const RC_SUCCESS: 60 = 60;

export type ResourceFetched = typeof RC_INITIAL | typeof RC_FETCHING | typeof RC_CACHED | typeof RC_ERROR;
export type ResourceCacheStatus = ResourceFetched | typeof RC_REFRESHING | typeof RC_API_REQUEST | typeof RC_NOT_FOUND | typeof RC_SUCCESS;

export type ResourceCollection<Full, Collected> = {
    generation: number,
    collected: { [id: string]: Collected, ... },
    full: { [id: string]: Full, ... },
    // fetched should really be "Collected Cache Status"
    fetched: ResourceFetched,
    notFound: Set<string>,
    cacheStatus: Map<string, ResourceCacheStatus>,
    fetchIds: Map<string, number>,
};

export type ResourceState = {
    'application': ResourceCollection<BbApplication, BbCollectedApplication>,
    'account': ResourceCollection<BbAccount, BbCollectedAccount>,
    'api_client': ResourceCollection<BbApiClient, BbApiClient>,
    'cloud_ip': ResourceCollection<BbCloudIp, BbCloudIp>,
    'database_server': ResourceCollection<BbDatabaseServer, BbCollectedDatabaseServer>,
    'database_snapshot': ResourceCollection<BbDatabaseSnapshot, BbDatabaseSnapshot>,
    'database_type': ResourceCollection<BbDatabaseType, BbDatabaseType>,
    'event': ResourceCollection<BbEvent, BbEvent>,
    'firewall_policy': ResourceCollection<BbFirewallPolicy, BbFirewallPolicy>,
    'firewall_rule': ResourceCollection<BbFirewallRule, BbFirewallRule>,
    'image': ResourceCollection<BbImage, BbImage>,
    'load_balancer': ResourceCollection<BbLba, BbCollectedLba>,
    'interface': ResourceCollection<BbInterface, BbInterface>,
    'test': ResourceCollection<BbTest, BbTest>,
    'test_unmapped': ResourceCollection<BbTest, BbTest>,
    'server': ResourceCollection<BbServer, BbCollectedServer>,
    'server_group': ResourceCollection<BbServerGroup, BbServerGroup>,
    'server_type': ResourceCollection<BbServerType, BbServerType>,
    'user': ResourceCollection<BbUser, BbUser>,
    'collaboration': ResourceCollection<BbCollaboration, BbCollaboration>,
    'zone': ResourceCollection<BbZone, BbZone>,
    'volume': ResourceCollection<BbVolume, BbVolume>,
};

export type ResourceAddCollected<T: BbAllResourceTypes> = {
    type: 'RESOURCE_ADD_COLLECTED',
    +payload: {
        +kind: BbResourceKind,
        resources: Array<T>,
    },
};

export type ResourceSetCollected<T: BbCollectedResourceTypes> = {
    type: 'RESOURCE_SET_COLLECTED',
    +payload: {
        +kind: BbResourceKind,
        +resources: $ReadOnlyArray<T>,
    },
};

export type ResourceAddFull<F: BbAllResourceTypes, C: BbCollectedResourceTypes> = {
    type: 'RESOURCE_ADD_FULL',
    +payload: {
        +kind: BbResourceKind,
        +full: F,
        +collected?: C
    },
};

export type ResourceDeleteResource = {
    type: 'RESOURCE_DELETE_RESOURCE',
    +payload: {
        +kind: BbResourceKind,
        +id: string,
    },
}

export type ResourceCollectedStatus = {
    type: 'RESOURCE_COLLECTED_STATUS',
    +payload: {
        +kind: BbResourceKind,
        +fetched: ResourceFetched,
    }
};

export type ResourceFetchCollected = {
    type: 'RESOURCE_FETCH_COLLECTED',
    +payload: {
        +kind: BbResourceKind,
    },
};

export type ResourceFetchFull = {
    type: 'RESOURCE_FETCH_FULL',
    +payload: {
        +kind: BbResourceKind,
        +id: string,
    },
};

export type ResourceProcessing = {
    type: 'RESOURCE_CACHE_STATUS',
    +payload: {
        +kind: BbResourceKind,
        +id: string,
        +status: ResourceCacheStatus,
        +fetchId?: number,
    },
};

export type ResourceActionMethod = 'POST' | 'PUT';

export type ResourceSimpleAction<F: BbAllResourceTypes> = {
    type: 'RESOURCE_SIMPLE_ACTION',
    +payload: {
        +id: string,
        +action: string,
        +method: ?ResourceActionMethod,
        +kind: BbResourceKind,
        +params: ?Object,
        +messagesId: ?string,
        +nonPristine: ?F,
        +suppressErrorToast?: boolean,
    },
}

export type ResourceDeleteAction = {
    type: 'RESOURCE_DELETE_ACTION',
    +payload: {
        +id: string,
        +kind: BbResourceKind,
    },
}

export type ResourcePatchAction<F: BbAllResourceTypes> = {
    type: 'RESOURCE_PATCH',
    +payload: {
        +id: string,
        +kind: BbResourceKind,
        +params: Object,
        +nonPristine: ?F,
        +messagesId?: string,
    },
}

export type ResourceCreateKindAction = {
    type: 'RESOURCE_CREATE_KIND',
    +payload: {
        kind: BbResourceKind,
        params: Object,
        cloudIp?: ?CipAssignParams,
        messagesId?: string,
    },
}

export type ResourceCreateServerGroupAction = {
    type: 'RESOURCE_CREATE_SERVER_GROUP',
    +payload: {
        +params: BbServerGroupCreateParams,
    },
}

export type ResourceCreateFirewallPolicyAction = {
    type: 'RESOURCE_CREATE_FIREWALL_POLICY',
    +payload: {
        +params: BbFirewallPolicyCreateParams,
    },
}

export type ResourceEventRawAction = {
    type: 'RESOURCE_EVENT_RAW';
    +payload: {
        +message: Object;
    },
}

export type ResourceServerConsoleAction = {
    type: 'RESOURCE_SERVER_CONSOLE',
    +payload: {
        +id: string,
    },
}

export type ResourceServerCreateAction = {
    type: 'RESOURCE_SERVER_CREATE',
    +payload: {
        params: Object,
        cloudIp: CipAssignParams,
        messagesId?: string,
        userSshKey?: string,
    },
}

export type ResourceDeleteCollaborationAction = {
    type: 'RESOURCE_DELETE_COLLABORATION',
    +payload: {
        +accountId: string,
        +userId: string,
    },
};

export type ResourceDeleteServerGroupAction = {
    type: 'RESOURCE_DELETE_SERVER_GROUP',
    +payload: {
        +id: string,
        +autoDeleteFwpId: ?string,
    },
};

export type ResourceSetNotFound = {
    type: 'RESOURCE_SET_NOT_FOUND',
    payload: {
        kind: BbResourceKind,
        id: string,
    }
}

export type ResourceForceRefresh = {
    type: 'RESOURCE_FORCE_REFRESH',
}

export type ResourceStateAction<F, C> =
    ResourceAddCollected<C> | ResourceCollectedStatus | ResourceSetCollected<C> |
    ResourceAddFull<F, C> | ResourceSetNotFound | ResourceProcessing | ResourceDeleteResource;

export type ResourceLogicAction<F> =
    ResourceFetchCollected | ResourceFetchFull |
    ResourceSimpleAction<F> | ResourceDeleteAction | ResourceCreateKindAction | ResourcePatchAction<F> |
    ResourceCreateServerGroupAction | ResourceCreateFirewallPolicyAction | ResourceServerCreateAction |
    ResourceEventRawAction | ResourceServerConsoleAction | ResourceDeleteCollaborationAction | ResourceDeleteServerGroupAction
    ;

export type ResourceAction<F, C> = ResourceStateAction<F, C> | ResourceLogicAction<F> | ResourceForceRefresh;
