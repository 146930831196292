// @flow
import { toast } from 'react-toastify';

export const handleApiError = (err: Object) => {
    if (err.response && err.response.data && Array.isArray(err.response.data.errors)) {
        toast(['API error'].concat(err.response.data.errors).join(". "), { type: 'error'});
    } else {
        toast('API error', { type: 'error'});
    }

};