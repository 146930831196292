// @flow
import { useViewResource, useResourceFetch, } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useResourceEditorModal } from '../../common/Editor';
import { NameEditPanel } from '../../element/NameEditPanel';
import { isDeleted } from '../../../api/lib';
import { useBuildingResource } from '../../hoc/Build';
import { TextSetting } from '../../element/Setting/TextSetting';
import { formatMegabytesSize, GB_TO_MB } from '../../element/Styled';
import { ResourceChipFetch } from '../../element/Chip';
import { SkeletonBar, SkeletonChip } from '../../element/Skeleton';
import { getLockSetting } from '../../element/Setting/Settings';
import { useResourceLockDialogs, useDeleteDialog, LockDialogs, DeleteDialog, CreateSnapshotDialog, SnapshotCreatingDialog } from '../../common/CommonDialogs';
import { validateSerialNumber } from './edit/lib';
import { RadioGroupSetting } from '../../element/Setting/RadioGroupSetting';
import { useVolumeResizeEditor, useVolumeDetach, useVolumeToServerAttachEditor, useVolumeCopy } from '../../hoc/Volumes';
import { VolumeResizeEdit } from '../server/edit/VolumeResizeEdit';
import { MonthlyPrice } from '../../element/MonthlyPrice';
import { getPerGigPrice } from '../../../lib/pricing';
import { VolAttachToServerEditor} from './edit/VolAttachToServerEditor';
import { useResourceRoutes } from '../../../lib/history';
import { VolDetachDialog, VolAttachedDialog } from './VolDialogs';
import { Dialog } from '../../element/Dialog';
import { BlockStorageLimit } from '../../element/LimitTooltip';
import { useSnapshot, useImageFromVolume } from '../../hoc/Snapshot';
import { VolMetrics } from '../../common/Metrics/VolMetrics';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router-dom';
import type { EditorDef } from '../../common/Editor';
import type { BbVolume, BbVolumePatchParams } from '../../../api/type.volume';
import type { LockDialogsHook } from '../../common/CommonDialogs';
import type { DialogState } from '../../element/Dialog';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { ValueSelectChoice } from '../../element/ValueSelect';
import type { VolumeDetachHook } from '../../hoc/Volumes';
import type { MessageHook } from '../../hoc/Messages';
import type { SnapshotHook } from '../../hoc/Snapshot';
import type { BbImage } from '../../../api/type';

const nameDef: EditorDef<BbVolume, string, BbVolumePatchParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbVolume) => res.name || '',
    onValidate: null,
};
const descriptionDef: EditorDef<BbVolume, string, BbVolumePatchParams, null> = {
    emptyErrors: null,
    editUri: 'description',
    onEdit: (res: BbVolume) => res.description || '',
    onValidate: null,
};
const serialDef: EditorDef<BbVolume, string, BbVolumePatchParams, ?string> = {
    emptyErrors: null,
    editUri: 'serial',
    onEdit: (res: BbVolume) => res.serial,
    onValidate: (resource: ViewResourceProps<BbVolume, BbVolumePatchParams>, value: string) => validateSerialNumber(value),
};
const delete_with_serverDef: EditorDef<BbVolume, boolean, BbVolumePatchParams, ?string> = {
    emptyErrors: null,
    editUri: 'delete_with_server',
    onEdit: (res: BbVolume) => !!res.delete_with_server,
    onValidate: null,
};

const deleteWithServerChoices: $ReadOnlyArray<ValueSelectChoice<boolean>> = [
    {
        value: false,
        label: 'No',
        description: 'Don\'t delete this Volume automatically',
    },
    {
        value: true,
        label: 'Yes',
        description: 'Delete this volume automatically when the Cloud Server it is attached to is deleted',
    }
];

type VolDialogsProps = {
    +volume: ?BbVolume,
    +name: string,
    ...LockDialogsHook,
    +deleteDialog: DialogState<null>,
    +detach: VolumeDetachHook,
    +attachedDialog: DialogState<BbVolume>,
    +copyDialog: DialogState<null>,
    +copyMessages: MessageHook<BbVolume>,
    +snapshot: SnapshotHook<BbImage>,
}

const VolDialogs = ({ volume, name, deleteDialog, detach, attachedDialog, copyDialog, copyMessages, snapshot, ...dialogs }: VolDialogsProps): React$Node => {
    return (
        <>
            <LockDialogs name={name} {...dialogs} />
            <DeleteDialog dialog={deleteDialog} name={name} />
            <VolDetachDialog detach={detach} />
            <VolAttachedDialog attachedDialog={attachedDialog} />
            <Dialog
                dialog={copyDialog}
                messages={copyMessages}
                title='Copy Volume?'
                render={() => <div>Are you sure you want to create a copy of {name}?</div>}
            />
            <CreateSnapshotDialog
                name={name}
                dialog={snapshot.snapshotDialog}
                messages={snapshot.messages}
            />
            <SnapshotCreatingDialog
                name={name}
                dialog={snapshot.snapshotDetailsDialog}
            />
        </>
    )
}


export const VolView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbVolume, BbVolumePatchParams>('volume', id);
    const { item: volume,  } = resource;
    const progress = useBuildingResource(id);
    const getRoute = useResourceRoutes();
    const { panelActions: lockActions, ...lockDialogs } = useResourceLockDialogs(volume?.locked, resource.simpleAction);
    const [deleteActions, deleteDialog] = useDeleteDialog(
        volume != null && volume.status !== 'attached' && volume.status !== 'deleted' && volume.status !== 'creating' && !volume.locked ,
        resource.deleteAction
    );
    const { item: server } = useResourceFetch('server', volume?.server?.id);
    const { actions: copyActions, dialog: copyDialog, messages: copyMessages, accountHasSpace } = useVolumeCopy(resource);

    const isNotDeleted = !isDeleted(volume?.status);

    const { create: createSnapshot, messages: snapshotMessages, } = useImageFromVolume(id);
    const snapshot = useSnapshot<BbImage>(createSnapshot, snapshotMessages, false, isNotDeleted); //hasCreatingSnapshot, allocated);
    const name = useResourceEditorModal(resource, nameDef, match.path);
    const description = useResourceEditorModal(resource, descriptionDef, match.path);
    const serial = useResourceEditorModal(resource, serialDef, match.path);
    const delete_with_server = useResourceEditorModal(resource, delete_with_serverDef, match.path);
    const [size, originalVolumeSize, disableEditorTooltip] = useVolumeResizeEditor(match.path, volume);
    const attach = useVolumeToServerAttachEditor(volume, match.path);
    const detach = useVolumeDetach();
    const { dialog: detachDialog, } = detach;
    const { attachedDialog } = attach;
    const isNbsVolume = (volume?.storage_type === 'network');

    const deleteWithServerHint = 'Will this Volume be automatically deleted when the Cloud Server to which it\'s attached is deleted?';
    const volumeSerialDocsUrl = 'https://www.brightbox.com/docs/reference/volumes/#persistent-block-device-naming'

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.volume.listTitle}
            resourceName={volume?.name || volume?.id || ''}
            resource={resource}
            match={match}
            dialog={
                <VolDialogs
                    volume={volume}
                    name={volume?.name || id}
                    deleteDialog={deleteDialog}
                    {...lockDialogs}
                    detach={detach}
                    attachedDialog={attachedDialog}
                    copyDialog={copyDialog}
                    copyMessages={copyMessages}
                    snapshot={snapshot}
                />}
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={volume?.name || volume?.id}
                            actions={{
                                ...lockActions,
                                // $FlowFixMe exponential spread
                                ...(isNbsVolume ? deleteActions : null),
                                // $FlowFixMe exponential spread
                                ...(isNbsVolume ? copyActions : null),
                                // $FlowFixMe exponential spread
                                ...(isNbsVolume ? snapshot.actions : null),
                            }}
                            editTitleLink={isNotDeleted ? name.editUri : null}
                            disableCopyHint={accountHasSpace ? null : <BlockStorageLimit isCopy={true} />}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: volume?.id,
                                status: volume?.status,
                                progress,
                                kind: 'volume',
                                created_at: volume?.created_at,
                            }}
                            settings={[
                                [
                                    {
                                        name: 'Description',
                                        summary: volume ? (volume.description || '-'): null,
                                        route: description.editUri,
                                    },
                                    {
                                        name: 'Zone',
                                        summary: volume ? volume.zone.handle : null,
                                    }
                                ],
                                [
                                    {
                                        name: 'Size',
                                        summary: volume ? formatMegabytesSize(volume.size) : null,
                                        route: size.editUri,
                                        settingsBtn: {
                                            kind: 'secondary',
                                            children: 'Resize',
                                        },
                                        routeDisabledTooltip: !isNbsVolume
                                            ? <div>Local storage volumes can't be resized</div>
                                            : disableEditorTooltip
                                    },
                                    {
                                        name: 'Storage Type',
                                        summary: volume ? (volume.storage_type || '–') : <SkeletonBar size='sm'/>,
                                    },
                                    {
                                        name: 'Attached To',
                                        summary: volume?.server
                                            ? <ResourceChipFetch id={volume?.server?.id} link={getRoute('server',volume?.server?.id)} />
                                            : '-',
                                        route: volume?.server ? null : attach.editor.editUri,
                                        settingsBtn: {
                                            kind: 'secondary',
                                            children: volume?.server ? 'Detach' : 'Attach',
                                            onClick: volume?.server ? () => detachDialog.show(volume) : null,
                                        },
                                        routeDisabledTooltip: !isNbsVolume
                                            ? <div>Local storage volumes can't be detached</div>
                                            : volume?.boot && server?.status === 'active'
                                                ? <div>You cannot detach the boot volume from an active Cloud Server</div>
                                                : null
                                    }
                                ],
                                [
                                    {
                                        name: 'Filesystem Type',
                                        summary: volume ? (volume.filesystem_type || '–') : <SkeletonBar size='sm'/>,
                                        hint: 'The filesystem type (ext4 of xfs) specified when the volume was created'
                                    },
                                    {
                                        name: 'Filesystem Label',
                                        hint: <>The Filesystem Label, if specified, allows referencing a volume consistently inside a cloud server {volume?.filesystem_label ? <>e.g.<br /><code className='select-all'>/dev/disk/by-label/<b>{volume?.filesystem_label}</b></code></> : null}</>,
                                        summary: volume
                                            ? (volume?.filesystem_label
                                                ? <span className='select-all font-mono'>{volume.filesystem_label}</span>
                                                : '–'
                                            )
                                            : <SkeletonBar size='sm'/>,
                                    },
                                ],
                                [
                                    {
                                        name: 'Delete with Server',
                                        summary: volume ? (volume.delete_with_server ? 'Yes' : 'No') : <SkeletonBar size='sm'/>,
                                        route: delete_with_server.editUri,
                                        hint: deleteWithServerHint,
                                        routeDisabledTooltip: !isNbsVolume
                                            ? <div>This setting can't be changed for local storage volumes</div>
                                            : null
                                    },
                                    {
                                        name: 'Encryption',
                                        summary: volume ? (volume.encrypted ? 'Yes' : 'No') : <SkeletonBar size='sm'/>,
                                        hint: 'Is full disk encryption enabled on this volume?'
                                    },
                                    {
                                        name: 'Serial',
                                        hint: <>The Serial allows referencing a volume consistently inside a cloud server e.g.<br /><code className='select-all'>/dev/disk/by-id/virtio-<b>{volume?.serial}</b></code></>,
                                        summary: volume ? <span className='select-all font-mono'>{volume.serial}</span> : <SkeletonBar size='sm'/>,
                                        route: serial.editUri,
                                        routeDisabledTooltip: volume?.status === 'attached'
                                            ? <div>Serial can't be edited while volumes are attached</div>
                                            : null
                                    },
                                    {
                                        name: 'Source Volume',
                                        hint: 'The Volume from which this volume was initially created as a copy',
                                        summary: volume
                                            ? (volume?.source && volume?.source_type === 'volume'
                                                ? <ResourceChipFetch id={volume?.source} link={getRoute('volume',volume?.source)} />
                                                : '-'
                                            )
                                            : <SkeletonChip />,
                                    },
                                    {
                                        name: 'Image',
                                        hint: 'The Server Image from which this volume was initially created',
                                        summary: volume
                                            ? (volume?.image
                                                ? <ResourceChipFetch
                                                    id={volume?.image.id}
                                                    link={isDeleted(volume?.image?.status) ? null : getRoute('image',volume?.image?.id)}
                                                    fallback={volume?.image}
                                                />
                                                : '-'
                                            )
                                            : <SkeletonChip />,
                                    },
                                    getLockSetting(volume != null, volume?.locked, lockDialogs.unlockDialog.show, lockDialogs.lockDialog.show),
                                    {
                                        name: 'Cost',
                                        summary: isNbsVolume ? <span><MonthlyPrice price={getPerGigPrice('blockstorage', (volume?.size || 0) / GB_TO_MB)} /></span> : '£0 (included in cloud server cost)'
                                    }
                                ],
                            ]}
                        />
                    </Panel>
                    <VolMetrics id={id} />
                </>
            }
            editors={[
                { editor: name, render: () => <NameEditPanel editor={name} />},
                { editor: description, render: () => <TextSetting editor={description} title='Description' label='Volume Description' textarea={true} /> , },
                { editor: delete_with_server, render: () => <RadioGroupSetting title='Delete with Server' description={deleteWithServerHint} editor={delete_with_server} choices={deleteWithServerChoices} /> , },
                { editor: size, render: () => <VolumeResizeEdit editor={size} originalSize={originalVolumeSize} />, },
                { editor: attach.editor, render: () => <VolAttachToServerEditor volumeId={id} volumeZoneId={volume?.zone?.id || ''} {...attach} />, },
                { editor: serial, render: () => <TextSetting editor={serial} title='Volume Serial' label='Volume Serial' helperText='Max 20 characters' leftButton={{kind: 'bare', preIcon: 'info-fill', children: <a href={volumeSerialDocsUrl} target='_blank' rel='noopener noreferrer'>Persistent Block Device Naming</a>}}  /> , },
            ]}
        />
    );
};
