// @flow

import { Fragment } from 'react';
import { ResourceChipFetch, OrbitContainerChip } from './Chip';
import { Tooltip } from './Tooltip';
import { Card } from './Card';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../lib/history';

import type { RelatedArrayTypes, RelatedEditLink, } from './RelatedIndicator';
import type { BbShortResourceRef } from '../../api/type';
import type { BuildWithEstimate, OrbitBulkDeletion } from '../../state/Build/type';
import type { TooltipPlacement } from './Tooltip';
import type { QueueAction } from '../../state/ActionQueue/type';

type RelatedPopoverProps = {
    +related?: $ReadOnlyArray<RelatedArrayTypes>,
    +affected?: $ReadOnlyArray<BbShortResourceRef>,
    +building?: $ReadOnlyArray<BuildWithEstimate>,
    +orbitDeleting?: $ReadOnlyArray<OrbitBulkDeletion>,
    +editLink?: ?RelatedEditLink,
    +children: React$Node,
    +parentId?: ?string,
    +placement?: ?TooltipPlacement,
    +pendingActions?: $ReadOnlyArray<QueueAction>,
}


export const RelatedPopover = ({ related, affected, building, orbitDeleting, editLink, children, parentId, placement, pendingActions }: RelatedPopoverProps): React$Node => {
    const getRoute = useResourceRoutes();

    if (!related?.length && !affected?.length && !building?.length && !orbitDeleting?.length && !pendingActions?.length) {
        return null;
    }

    return (
        <Tooltip placement={placement || 'topRight'} background="light" overlay={
            <div className="c-related-popover">
                <div className="c-related-popover__items">
                    {related?.map(s => {
                        let link = getRoute(s.resource_type, s.id, parentId);
                        let pending: ?{ isPending: boolean } = null;

                        switch(s.resource_type) {
                        case 'cloud_ip':
                            if (s.status === 'unmapped') pending = { isPending: true };
                            break;
                        default:
                            // just show the card.
                            break;
                        }

                        return (
                            <Fragment key={s.id}>
                                <Card link={link} key={s.id} popover={true}>
                                    <ResourceChipFetch
                                        link={link}
                                        id={s.id}
                                        fallback={s}
                                        {...pending}
                                    />
                                </Card>
                                <hr/>
                            </Fragment>
                        );
                    })}
                    {affected?.map(s => {
                        let link = getRoute(s.resource_type, s.id);

                        return (
                            <Fragment key={s.id}>
                                <Card link={link} key={s.id} popover={true}>
                                    <ResourceChipFetch id={s.id} kind={s.resource_type}/>
                                </Card>
                                <hr/>
                            </Fragment>
                        );
                    })}
                    {building?.map(s => {
                        let link = getRoute(s.resource_type, s.id);
                        return (
                            <Fragment key={s.id}>
                                <Card link={link} key={s.id} popover={true}>
                                    <ResourceChipFetch id={s.id} kind={s.resource_type}/>
                                </Card>
                                <hr/>
                            </Fragment>
                        );
                    })}
                    {orbitDeleting?.map(s => {
                        return (
                            <Fragment key={s.id}>
                                <Card popover={true}>
                                    <OrbitContainerChip name={s.id} deleteProgress={s} />
                                </Card>
                                <hr/>
                            </Fragment>
                        );
                    })}
                    {pendingActions?.map(s =>
                        <Fragment key={s.id}>
                            <Card key={s.id} popover={true}>
                                <ResourceChipFetch id={s.id} />
                            </Card>
                        </Fragment>
                    )}
                </div>
                {editLink
                    ? <div className="c-related-popover__footer">
                        <div className="c-related-popover__edit-link">
                            <Link className="c-related-popover__edit-link" to={`${editLink.id}/${editLink.kind}/`}>{editLink.label}</Link>
                        </div>
                    </div>
                    : null
                }
            </div>
        }>
            {children}
        </Tooltip>
    );
};