// @flow
import { useMemo } from 'react';
import { PanelHeader, Panel, PanelButtonBar } from '../../../element/Panel';
import { ServerTypeTabPanel } from '../server_type/ServerTypeTabPanel';
import { useListResource } from '../../../hoc/ListPage';
import { getMonthlyPrice } from '../../../../lib/pricing';
import { useDialog, Dialog } from '../../../element/Dialog';
import { Notice } from '../../../element/Notice';
import { Button } from '../../../element/Button';
import { useSupportDialog } from '../../../hoc/SupportDialog';
import { getServerTypeGroups } from '../../../../lib/ServerTypeGroup';
import { LocalStorageTypeSelector } from '../server_type/LocalStorageTypeSelector';

import type { ListSortDef } from '../../../hoc/ListPage';
import type { EditorModal } from '../../../common/Editor';
import type { BbServer } from '../../../../api/type.srv';
import type { BbServerType, BbCollectedImage, BbImage } from '../../../../api/type';
import type { ServerTypeValue } from '../../../hoc/ResizeResource';
import type { BbStorageType } from '../../../../api/type.volume';

export type ServerTypeEditProps = {
    +editor: EditorModal<ServerTypeValue<BbServerType>, ?string, BbServer>,
    +availableMemory: number,
    +selectedImage: ?BbCollectedImage | ?BbImage,
    +id: string,
    +name: string,
    +onValidate: () => ?string,
    +isInactive: boolean,
    +storageTypeFilter: BbStorageType,
}

function getPresetMessage(id: string, type: BbServerType): string {
    return `I'm having trouble resizing ${id} to ${type.handle || ''} (${type.id}) and need some assistance.`;
}

const priceSort: ListSortDef<BbServerType> = {
    name: 'price_sort',
    fields: {
        _default: (a: BbServerType, b: BbServerType): number => (getMonthlyPrice(a.id) || 0) - (getMonthlyPrice(b.id) || 0),
    }
};

const INSUFFICIENT_INSTANCE_CAPACITY = 'insufficient_instance_capacity';
// simpler for SD to test with this...
// const INSUFFICIENT_INSTANCE_CAPACITY = 'account_limit_reached';

export const ServerTypeEdit = ({ editor, availableMemory, selectedImage, id, name, onValidate, isInactive, storageTypeFilter }: ServerTypeEditProps): React$Node => {
    const { value, errors } = editor;

    const confirmDialog = useDialog([
        {
            label: 'Resize',
            kind: 'primary',
            onSelect: editor.status === 'edit' && errors == null ? editor.onSave : null
        }
    ]);

    const supportDialog = useSupportDialog(
        editor.messages,
        INSUFFICIENT_INSTANCE_CAPACITY,
        (): ?string => value?.selected ? getPresetMessage(id, value.selected) : null
    );

    const serverTypes = useListResource<BbServerType>('server_type', priceSort);
    const isNbs = storageTypeFilter === 'network';

    // only build the groups once all the account/server/type info is pulled from the api.
    // availableMemory should never be 0 once they're all present, because this control is
    // only to resize existing servers, and they necessarily have some ram themselves, which
    // is always 'added into' availableMemory - see useAvailableMemoryForServerResize
    const allChoices = useMemo(
        () => getServerTypeGroups(availableMemory !== 0 ? serverTypes.items : []),
        [serverTypes.items, availableMemory]
    );

    if (value == null) return null;

    return (
        <>
            <Dialog
                dialog={confirmDialog}
                title='Resize Cloud Server?'
                footerLink={<a href='https://www.brightbox.com/docs/guides/control-panel/resize-cloud-server/' rel='noreferrer noopener' target='blank'>
                <Button size='sm' kind='bare' preIcon='info-fill'>Resizing Cloud Servers</Button></a>}
                render={() => (
                    <div>
                        {errors
                            ? <Notice type='error'>{errors}</Notice>
                            : <>
                                <p>Please confirm you want to resize <b>{name}</b></p>

                                {!isNbs
                                    ? <Notice type='warning' icon='info-fill'>
                                        <b>Note:</b> to avoid data loss, increases in disk size can't be undone or decreased later.
                                        We recommend taking a snapshot before resizing.
                                    </Notice>
                                    : null
                                }
                            </>
                        }
                    </div>
                )}
            />
            <Dialog
                title='Automatic Resize Failed'
                dialog={supportDialog}
                render={() => (
                    <div className='space-y-4'>
                        <Notice type='warning' icon='info-fill'>
                            We're unable to complete the resize automatically right now
                        </Notice>
                        <p>Please click below to request assistance to complete the resize.</p>
                    </div>
                )}
            />
            <Panel>
                <PanelHeader
                    title="Resize Cloud Server"
                    description='Choose a new Server Type to resize this Cloud Server'
                />
                {isNbs
                    ? <ServerTypeTabPanel
                        choices={allChoices['nbs'].types}
                        current={value.current}
                        selected={value.selected}
                        onSelect={(selected) => editor.setValue({ ...value, selected })}
                        availableMemory={availableMemory}
                        selectedImage={selectedImage}
                        selectedVolume={null}
                        isInactive={isInactive}
                    />
                    : <LocalStorageTypeSelector
                        allChoices={allChoices}
                        current={value.current}
                        selected={value.selected}
                        onSelect={(selected) => editor.setValue({ ...value, selected })}
                        availableMemory={availableMemory}
                        selectedImage={selectedImage}
                        selectedVolume={null}
                        isInactive={isInactive}
                    />
                }

                <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    leftButton={{
                        kind: 'bare',
                        preIcon: 'info-fill',
                        children: <a href='https://www.brightbox.com/docs/guides/control-panel/resize-cloud-server/' rel='noopener noreferrer' target='_blank'>Resizing Cloud Servers</a>
                    }}
                    primaryButton={{
                        onClick: editor.status === 'edit'
                            ? () => {
                                onValidate();
                                confirmDialog.show();
                            }
                            : null,
                        disabled: value.selected == null,
                    }}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                />
            </Panel>
        </>
    );
};