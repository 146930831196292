// @flow

import { useCurrentAccountId } from '../../hoc/lib';
import { useContainerMetaEditor, useOrbitContainerView } from '../../hoc/orbit/Container';
import { useOrbitDeleteAccess } from '../../hoc/orbit/Access';
import { useDeleteDialog, DeleteDialog, RemoveDialog } from '../../common/CommonDialogs';
import {
    validateContainerAccessEntry,
    UriFragmentContainerAccessEdit,
    emptyEdit,
} from '../orbit/def';
import { formatOrbitSize, formatDateTime } from '../../element/Styled';
import { RC_NOT_FOUND } from '../../../state/resource/type';
import { NotFound } from '../home/NotFound';
import { getContainerRegistryUrl } from '../../../api/adapter.orbit';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { OrbitContainerAccess } from '../orbit/OrbitContainerAccess';
import { ContainerAccessForm } from '../orbit/edit/ContainerAccessForm';
import { containerRegistryNoScheme } from '../../../api/url';
import { ContainerAccessDeleteName } from '../orbit/OrbitContainerView';
import { useSecretFromIdDisplayDialog } from '../../hoc/Secret';
import { ApiClientSecretDialog } from '../api_client/ApiClientSecretDialog';
import { LABELS } from '../../element/ResourceLabels';
import { ContainerRegistryImageList } from "./ContainerRegistryImageList";

import type { Match } from 'react-router';
import type { ContainerAccess, OrbitContainerMeta, OrbitContainerHeaders, } from '../../../api/type.orbit';
import type {
    OrbitEditMetaType,
    ContainerAccessEdit,
} from '../orbit/def';

export function ContainerRegistryView({ id, match, }: { id: string, match: Match }): React$Node {
    const name = id.substr(0, id.length - 10);
    const accountId = useCurrentAccountId() || '';
    const containerView = useOrbitContainerView(id);
    const { container, deleteAction, cacheStatus, } = containerView;
    const { deleteAccessAction, messages: deleteMessages, } = useOrbitDeleteAccess(id);

    const containerDeletable: boolean = (container?.details?.count || 0) === 0;
    const [actions, deleteDialog] = useDeleteDialog(containerDeletable, deleteAction);
    const [, deleteAccessDialog] = useDeleteDialog<ContainerAccess>(true, deleteAccessAction, 'Remove');

    const editAccessEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerAccessEdit>>(
        id, containerView, 'access/:edit([a-z]+:.+)/',
        (meta: OrbitContainerMeta, match: Match) => {
            const edit = UriFragmentContainerAccessEdit(match.params.edit || '');

            return ({
                rawMeta: meta,
                edit: edit || emptyEdit,
            });
        },
        (value: OrbitEditMetaType<ContainerAccessEdit>) => validateContainerAccessEntry(value.rawMeta, value.edit),
        match.path, 'Access rule edited successfully'
    );

    const addAccessEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerAccessEdit>>(
        id, containerView, 'access/add',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: {
                type: 'new-api',
                initial: null,
                access: 'read',
                apiClient: '',
                referrer: '',
                allowDomain: 'allow',
                other: '',
                newApiClientName: 'Client for ' + name,
            },
        }),
        (value: OrbitEditMetaType<ContainerAccessEdit>) => validateContainerAccessEntry(value.rawMeta, value.edit),
        match.path, 'Access rule added successfully',
        (value: OrbitEditMetaType<ContainerAccessEdit>, headers: OrbitContainerHeaders, messagesId: string) => {
            if (value.edit.type === 'new-api' && value.edit.access !== '') {
                containerView.createAndGrantApiClient(value.edit.newApiClientName, value.edit.access, headers, messagesId);
            } else {
                containerView.setHistory(headers, messagesId);
            }
        }
    );
    const secretDialog = useSecretFromIdDisplayDialog(id, addAccessEditor.messages);


    if (cacheStatus === RC_NOT_FOUND) {
        return <NotFound/>;
    }

    const [containerUrl, noSchemeUrl] = getContainerRegistryUrl(accountId, id);

    const login = `docker login ${containerRegistryNoScheme}`;
    const tag = `docker tag ${name}:latest ${noSchemeUrl}/${name}:latest`;
    const push = `docker push ${noSchemeUrl}/${name}:latest`;

    let settings = [
        [
            {
                name: 'Repository URL',
                summary: containerUrl,
                copyValue: containerUrl,

            },
            {
                name: 'Total Size',
                summary: container?.details != null
                    ? formatOrbitSize(container.details.bytes)
                    : null,
            },
            {
                name: 'Last Modified',
                summary: container?.details != null
                    ? formatDateTime(container.details.last_modified)
                    : null
            },
        ],
        [
            {
                name: 'Login',
                summary: <span className='text-xs font-mono'>{login}</span>,
                copyValue: login,
            },
            {
                name: 'Tag',
                summary: <span className='text-xs font-mono'>{tag}</span>,
                copyValue: tag
            },
            {
                name: 'Push',
                summary:<span className='text-xs font-mono'>{push}</span>,
                copyValue: push,
            },
        ]
    ];

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.container_registry.listTitle}
            match={match}
            dialog={
                <>
                    <DeleteDialog name={`repository "${name}"`} title='Delete Repository?' dialog={deleteDialog}/>
                    <RemoveDialog name={ContainerAccessDeleteName} dialog={deleteAccessDialog} title='Remove Access?' messages={deleteMessages} />
                    <ApiClientSecretDialog
                        secret={secretDialog}
                        containerRegistry={true}
                        access={container?.meta?.access?.find(x => x.type === 'api' && x.cliId === secretDialog.secretId)}
                        name={name}
                    />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            title={name}
                            actions={actions}
                            disableDeleteHint={
                                containerDeletable
                                    ? null
                                    : 'Container Registries with objects cannot be deleted'
                            }
                        />
                        <PanelMultiSettingsBar
                            settings={settings}
                        />

                    </Panel>

                    {container?.details
                        ? <OrbitContainerAccess
                            editDisabled={id === 'images'}
                            meta={container.meta}
                            onDeleteEntry={(entry: ContainerAccess) => deleteAccessDialog.show(entry)}
                            containerRegistry={true}
                        />
                        : null
                    }

                    <ContainerRegistryImageList container={id} />
                </>
            }
            editors={[
                { editor: editAccessEditor, render: () => <ContainerAccessForm containerRegistry={true} editor={editAccessEditor} addEdit='edit' /> , },
                { editor: addAccessEditor,  render: () => <ContainerAccessForm containerRegistry={true} editor={addAccessEditor} addEdit='add' /> , },
            ]}
        />
    );
}
