// @flow
import { useEffect, useRef, useState } from 'react';
import { useLocation, } from 'react-router-dom';

type UrlScrollPositionState = { [url: string]: number, ... }

/*
 * Remember scroll positions for given page URLs and restore the
 * position whenever we go back to a remembered page.
 *
 * Primarily useful for the 'advanced settings' that show an extra
 * page of editor, and where going back to the main resource page
 * would be jarring if the scroll position wasn't restored.
 *
 * This now scrolls to 0 position when going to a longer path.
 * This helps when going from eg a scrollable list resource page,
 * to viewing a long resource view page; the view page now scrolls
 * to the top, where before it showed up 'half way'. Seems nicer.
 *
 */

export const useScrollPositionState = (): { ref: { current: ?HTMLElement } } => {
    const ref = useRef<?HTMLElement>(null);
    const [ urlState, setUrlState ] = useState<UrlScrollPositionState>({});
    const { pathname } = useLocation();
    const [ trackingUrl, setTrackingUrl ] = useState<string>(pathname);

    useEffect(() => {
        if (
            pathname !== trackingUrl
            && pathname.match(/[/]$/)
            && ref.current
        ) {
            const { current } = ref;
            // It looks like Chrome needs React to have completed its render before it can
            // scroll successfully, so put it into an instant-run timeout.
            if (pathname.length < trackingUrl.length) {
                setTimeout(() => current.scroll(current.scrollLeft, urlState[pathname] || 0), 0);
            } else {
                setTimeout(() => current.scroll(current.scrollLeft, 0), 0);
            }
            setTrackingUrl(pathname);
        }
    }, [pathname, urlState, trackingUrl]);

    const { current } = ref;
    useEffect(() => {
        if (current) {
            const listener = (e: Event) => {
                setUrlState((s) => ({ ...s, [window.location.pathname]: current.scrollTop }));
            };
            current.addEventListener('scroll', listener);

            return () => current.removeEventListener('scroll', listener);
        }
    }, [current]);


    return { ref };
};
