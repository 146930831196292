// @flow
import { useContext } from 'react';
import { animationRouteContext, pageTransition, pageVariants } from '../../element/animation';
import { motion } from 'framer-motion';
import { Page } from '../../element/Page';
import { ResourceListTitle } from '../../element/ResourceListPage';
import { Panel, PanelSearchBar, PanelBar } from '../../element/Panel';
import { formatDateTime, RightArrow, formatOrbitSize } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { OrbitContainerChip } from '../../element/Chip';
import { useObjectToSortedList } from '../../hoc/ListPage';
import { orbitSortFields, orbitContainerSearchDef } from './def';
import { usePager } from '../../element/ArrayPager';
import { PagerBar } from '../../element/PagerBar';
import { Table, Td, Th, Tr } from '../../element/Table';
import { NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { useResourceSearch } from '../../element/Search';
import { TextOnlySearchEditor } from '../../common/TextOnlySearch';
import { SkeletonListLoadingEntries, SkeletonChip, SkeletonBar, isShowDetailsResourceStatus } from '../../element/Skeleton';
import { useOrbitContainers } from '../../hoc/Orbit';
import { Button } from '../../element/Button';
import { Tooltip } from '../../element/Tooltip';
import { useAccountStatusCreateOverlays } from '../../hoc/orbit/lib';

import type { BbOrbitContainer, } from '../../../api/type.orbit';

export const OrbitContainerList = (): React$Node => {
    const [, , sectionAnim,] = useContext(animationRouteContext);
    const { containers, fetched } = useOrbitContainers();
    const { items: sortedItems, Th: SortTh } = useObjectToSortedList<BbOrbitContainer>({ name: 'OrbitContainersList', fields: orbitSortFields }, containers);
    const resourceSearch = useResourceSearch(orbitContainerSearchDef, sortedItems);
    const { items } = resourceSearch;
    const pager = usePager('OrbitContainersList', items, items.length);
    const limitOverlay = useAccountStatusCreateOverlays('Orbit Container');

    return (
        <motion.div
            style={{ width: '100%' }}
            {...sectionAnim}
            variants={pageVariants}
            transition={pageTransition}
        >
            <Page>
                <ResourceListTitle
                    resourceName={'Orbit Container'}
                    title={'Orbit Containers'}
                    limitOverlay={limitOverlay}
                />

                <Panel>
                    <PanelSearchBar
                        search={<TextOnlySearchEditor editor={resourceSearch.editor}/>}
                    />
                    <PanelBar padding={false}>
                    <Table>
                        <thead>
                        <Tr>
                            <SortTh field='name'>Name</SortTh>
                            <SortTh field='bytes'>Size</SortTh>
                            <SortTh field='count'>Objects</SortTh>
                            <SortTh field='last_modified'>Last Modified</SortTh>
                            <Th actions={true}>&nbsp;</Th>
                        </Tr>
                        </thead>

                        <tbody>
                        {pager.items.map((container, name) =>
                            container
                                ? <Tr key={name}>
                                    <Td resourceName={true}>
                                        <OrbitContainerChip name={container.name} bytes={container.bytes} />
                                    </Td>
                                    <Td>{formatOrbitSize(container.bytes)}</Td>
                                    <Td>{container.count
                                        ? <Tooltip overlay={'View container objects'}>
                                            <Link to={`container/${container.name}/objects/`}>
                                                <Button kind='bare'>{container.count} items</Button>
                                            </Link>
                                        </Tooltip>
                                        : '-'}
                                    </Td>
                                    <Td>{formatDateTime(container.last_modified)}</Td>
                                    <Td actions={true}>
                                        <Link to={`container/${container.name}/`}>
                                            <RightArrow />
                                        </Link>
                                    </Td>
                                </Tr>
                                : <Tr key={name}>
                                    <Td>{name}</Td>
                                    <Td>&nbsp;</Td>
                                    <Td>&nbsp;</Td>
                                    <Td actions={true}>
                                        <Link to={`container/${name}/`}>
                                            <RightArrow />
                                        </Link>
                                    </Td>
                                </Tr>
                        )}
                        <NoMatchingResourceTr search={resourceSearch} colSpan={5} kind='container' />
                        {!isShowDetailsResourceStatus(fetched)
                            ? <SkeletonListLoadingEntries>
                                <Tr>
                                    <Td resourceName={true}><SkeletonChip /></Td>
                                    <Td><SkeletonBar /></Td>
                                    <Td><SkeletonBar /></Td>
                                    <Td><SkeletonBar /></Td>
                                    <Td actions={true}>&nbsp;</Td>
                                </Tr>
                            </SkeletonListLoadingEntries>
                            : null
                        }
                        </tbody>
                    </Table>
                    </PanelBar>
                    <PagerBar {...pager.pager} />
                </Panel>
            </Page>
        </motion.div>

    );
};