// @flow
import { Td, Tr } from "../../../element/Table";
import { ValueSelect } from "../../../element/ValueSelect";
import { TextInput } from "../../../element/Input";
import { RemoveButton } from "../../../element/Button";
import { CIDR_PROMPT } from "./lib";

import { type AccessResourcesHook } from "../../../hoc/AccessControl";
import type { AccessControlTarget, BbTargetKinds } from "../../../../api/type";

export type DbsAccessControlRuleEdit = {
    source: AccessControlTarget;
    description: string;
}

export type DbAccessControlTableRowProps = {
    +item: DbsAccessControlRuleEdit,
    +onChange: (rule: DbsAccessControlRuleEdit) => void,
    +errorText?: ?string,
    +onRemove: () => void,
    +accessControlResources: AccessResourcesHook,
}

export const SET_TO_DEFAULT_SERVER_GROUP = 'DEFAULT';

export const DbsAccessControlTableRow = (
    { item, onChange, errorText, onRemove, accessControlResources, }: DbAccessControlTableRowProps
): React$Node => {
    const { source, description } = item;
    const { options, typeOptions, clientIp } = accessControlResources;

    return (
        <Tr>
            <Td>
                <ValueSelect
                    size='xs'
                    selected={source.kind}
                    options={typeOptions}
                    onSelect={(nextType: BbTargetKinds) => {
                        onChange({ ...item, source: { kind: nextType, value: nextType === 'clientIp' ? (clientIp.clientIp || '') : '' }});
                    }}
                />
            </Td>
            <Td>
                {source.kind === 'addr'
                    ? <TextInput
                        label='IP Subnet'
                        size='xs'
                        helperText={CIDR_PROMPT}
                        errorText={errorText}
                        value={source.value}
                        onChange={(value: string) => onChange({ ...item, source: { kind: 'addr', value }})}
                    />
                    : null}
                {source.kind === 'other'
                    ? <TextInput
                        label='Other'
                        size='xs'
                        errorText={errorText}
                        value={source.value}
                        onChange={(value: string) => onChange({ ...item, source: { kind: 'other', value }})}
                    />
                    : null}
                {source.kind in options
                    ? <ValueSelect
                        size='xs'
                        errorText={errorText}
                        options={options[source.kind]}
                        selected={source.value}
                        onSelect={(value: string) => onChange({ ...item, source: { kind: source.kind, value }})}
                    />
                    : null}
            </Td>
            <Td>
                <TextInput
                    value={description}
                    onChange={(description: string) => onChange({ ...item, description })}
                    size='xs'
                    className='w-full'
                    placeholder='Description (optional)'
                />
            </Td>
            <Td>
                <RemoveButton size='sm' onClick={onRemove}/>
            </Td>
        </Tr>
    );
};