// @flow
import { cn } from './lib/classNames';
import { SvgIcon } from './SvgIcon';
import type { Icon } from '../../assets/icons';

export type CheckboxProps = {
    disabled?: boolean,
    checked?: boolean,
    inline?: boolean,
    label?: React$Node,
    color?: ?string,
    onChange?: ?(value: boolean, MouseEvent) => void,
    className?: string,
    errorText?: ?string,
    size?: ?string,
    icon?: ?Icon,
    hoverVisible?: boolean,
}

export const Checkbox = ({disabled, checked, inline, label, color, size, className: extraClassName, onChange, errorText, hoverVisible, icon, ...rest}: CheckboxProps): React$Node => {

    let classes = {
        'c-checkbox': true,
        'c-checkbox--inline': inline,
        'c-checkbox--blue': (color === 'blue'),
        'c-checkbox--disabled': disabled,
        'c-checkbox--checked': checked,
        'c-checkbox--with-label': !!label,
        'c-checkbox--has-error': !!errorText,
        'c-checkbox--sm': (size === 'sm'),
        'c-checkbox--hover-visible': hoverVisible === true,
    }
    
    const className = cn(classes) + ' ' + (extraClassName || '');

    const handleClick = (e: MouseEvent) => {
        // $FlowFixMe bad flow type defintion.
        if (e.target.tagName !== 'A') {
            // default handling changes the underlying 'checked'ness of the control,
            // so just disable that every time.
            e.preventDefault();

            if (disabled || !onChange) return;

            onChange(!checked, e);
        } else {
            e.stopPropagation();
        }
    }

    return (
        <>
        <label className={className} onClick={handleClick} {...rest}>
            <input type='checkbox' checked={checked} onClick={handleClick} onChange={() => null} className='c-checkbox__control' />
            <span className='c-checkbox__box'>
                <SvgIcon className='c-checkbox__check' svg={icon || 'tick'} />
            </span>
            {label
                ? <span className='c-checkbox__description'>{label}</span>
                : null
            }
        </label>
        {errorText
            ? <span className='text-xs italic text-red-500 ml-3'>{errorText}</span>
            : null}
        </>
    );
};
