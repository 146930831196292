// @flow
import { ResourceChipFetch } from '../../element/Chip';
import { Link } from 'react-router-dom';
import { Button } from '../../element/Button';
import { SkeletonChip } from '../../element/Skeleton';
import { useResourceRoutes } from '../../../lib/history';

import type { BbCloudIp } from '../../../api/type.cip';

type CloudIpMappedResourceProps = {
    +ip: ?BbCloudIp,
    +showAddButton?: boolean,
}

export const CloudIpMappedResource = ({ ip, showAddButton, }: CloudIpMappedResourceProps): React$Node => {
    const getRoute = useResourceRoutes();

    if (ip == null) {
        return <SkeletonChip />
    }
    const resource = ip.server || ip.server_group || ip.database_server || ip.load_balancer;

    if (resource) {
        return <ResourceChipFetch id={resource.id} fallback={resource} link={getRoute(resource.resource_type, resource.id)}/>;
    } else {
        return showAddButton
            ? <Link to={`${ip.id}/map/`}>
                <Button kind='bare'>
                    + Map
                </Button>
            </Link>
            : <div>Unmapped</div>;
    }
};