// @flow

import { useMemo } from 'react';
import formatDistance from 'date-fns/formatDistance';
import { formatDateTime } from '../../element/Styled';
import { useListResource } from '../../hoc/ListPage';
import { Avatar } from '../../element/Avatar';
import { Tooltip } from '../../element/Tooltip';
import { useEventMessage, eventWillShow, eventActioner } from '../../element/EventMessage';
import { cn } from '../../element/lib/classNames';
import { Counter } from '../../element/Counter';
import { Separator } from '../../element/Separator';
import { RelatedPopover } from '../../element/RelatedPopover';
import set from 'date-fns/set';
import sub from 'date-fns/sub';
import getDay from 'date-fns/getDay'
import getDate from 'date-fns/getDate'
import format from 'date-fns/format';
import { initial } from '../../element/Chip';
import { useRefreshComponent } from '../../common/lib';

import type { BbEvent, BbNestedUser } from '../../../api/type';
import type { BbApiClient } from '../../../api/type.cli';
import type { ListSortDef } from '../../hoc/ListPage';

export const eventSortDef: ListSortDef<BbEvent> = {
    name: 'event',
    fields: {
        // we rely here on the fact that array sorting in JS is a stable sort algo...
        _default: (b: BbEvent, a: BbEvent): number => a.created_at.getTime() - b.created_at.getTime(),
    }
};

// 15s
const REFRESH_INTERVAL_MS = 15000;

type EventStreamEntry = {
    +event: BbEvent,
    +includeActioner: boolean,
    separator: ?string,
}

export function addDateSeparators(entries: Array<EventStreamEntry>): Array<EventStreamEntry> {
    if (!entries.length) return [];

    const today0 = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
    const yesterday0 = sub(today0, { days: 1 });
    const lastWeek0 = sub(today0, { days: getDay(today0), weeks: 1 });
    const lastMonth0 = sub(today0, { days: getDate(today0) - 1, months: 1 });

    let separators = [
        ['Today', today0, false],
        ['Yesterday', yesterday0, false],
        ['Last Week', lastWeek0, false],
        ['Last Month', lastMonth0, false],
    ];

    let lastSeparatorDate: ?string = null;

    entries.forEach((entry) => {
        const { event } = entry;
        const eventDate = format(event.created_at, 'yyyy-MM-dd');

        if (lastSeparatorDate !== eventDate) {
            let lastSepIdx = separators.findIndex(([, sepDate, used]) => !used && event.created_at > sepDate);
            if (lastSepIdx !== -1) {
                entry.separator = separators[lastSepIdx][0];
                separators[lastSepIdx][2] = true;
            }
            lastSeparatorDate = eventDate;
        }
    });

    if (entries[0].event.created_at < lastMonth0) {
        entries[0].separator = 'Older';
    }

    return entries;
}

export const useEventsWithSeparators = (events: $ReadOnlyArray<BbEvent>): Array<EventStreamEntry> => {
    return useMemo(() => {
        const shownEvents = events.filter(eventWillShow).reverse();

        if (shownEvents.length === 0) return [];

        let previousActioner: ?(BbNestedUser | BbApiClient | 'background') = null;

        return addDateSeparators(shownEvents.map(event => {
            const thisActioner = eventActioner(event);
            let includeActioner = false;

            if (
                previousActioner == null
                || ((previousActioner === 'background') !== (thisActioner === 'background'))
                || (typeof previousActioner === 'object' && typeof thisActioner === 'object' && previousActioner.id !== thisActioner.id)
            ) {
                includeActioner = true;
                previousActioner = thisActioner;
            }

            return ({
                event,
                includeActioner,
                separator: null,
            }: EventStreamEntry);
        }).reverse());
    }, [events]);
};

const EventItem = ({ entry }: { entry: EventStreamEntry }): React$Node => {
    const { event, includeActioner, separator } = entry;
    const className = cn({
        'c-event': true,
        'c-event--user': !!event.user,
        'c-event--api': !!event.client,
        'c-event--backend': !event.client && !event.user,
        'c-event--successive': !includeActioner,
    });
    const message = useEventMessage(event);

    const user = event.user;

    if (message == null) return null;

    return (
        <>
            {separator
                ? <li className='c-event__separator'>
                    <Separator solid={true}>{separator}</Separator>
                </li>
                : null
            }
        <li key={event.id} className={className}>
            <div className='c-event__body'>
                <div className='c-event__contents'>
                {message}

                {/*id: {event.id}<br/>*/}
                {/*action: {event.action}<br/>*/}
                {/*short_action: {event.short_action}<br/>*/}
                {/*message: {event.message}<br/>*/}
                {/*event.resource.resource_type: {event.resource.resource_type}<br/>*/}
                {/*short_message: {event.short_message}<br/>*/}

                <div className='text-gray-500'>{event.resource.resource_type}.{event.action}</div>

                {event.affects.length
                    ? <div className='c-event__affected'>
                        <RelatedPopover affected={event.affects}>
                            <Counter className='c-event__counter'>{event.affects.length}</Counter>
                        </RelatedPopover>
                        Affected resources
                    </div>
                    : null
                }
                </div>
               
                <Tooltip overlay={<div>{formatDateTime(event.created_at)}</div>}>
                    <div className='c-event__timestamp'> {formatDistance(event.created_at, Date.now())} ago</div>
                </Tooltip>

            </div>

            <div className='c-event__byline'>
                {user
                    ? <>
                        <Avatar
                            initial={initial(user)}
                            colorId={user.id}
                            gravatar_url={user.gravatar_url}
                            className={'c-event__avatar'}
                        />
                        <div className='c-event__actioner'>
                            {user.name}
                        </div>
                    </>
                    : null}
                {event.client && !event.user
                    ? <div>
                        <Avatar
                            icon='code'
                            className={'c-event__avatar'}
                        />
                        <div className='c-event__actioner'>
                        API client: {event.client.id}
                    </div>
                    </div>
                    : null}

            </div>
        </li>
    </>);
};

export const EventStream = (): React$Node => {
    const rawEvents = useListResource<BbEvent>('event', eventSortDef);
    useRefreshComponent(REFRESH_INTERVAL_MS);
    const events = useEventsWithSeparators(rawEvents.items);

    return (
        <ul>
            {events.map((entry) => (<EventItem entry={entry} key={entry.event.id} />))}
        </ul>
    );
};

