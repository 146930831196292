// @flow
import { useStripe, } from '@stripe/react-stripe-js';
import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../../../element/Panel';
import { useUpdateBillingMethod } from '../../../hoc/Billing';
import { historyBack } from '../../../../lib/history';
import { RC_API_REQUEST, RC_SUCCESS } from '../../../../state/resource/type';
import { AddCardForm, } from './AddCardForm';
import { StripeWrapper } from './StripeWrapper';
import { useCurrentAccountId } from '../../../hoc/lib';
import { toast } from 'react-toastify';
import { AddCardText } from '../../../element/Styled';

export const GENERIC_ERROR_MESSAGE = 'There was a problem processing your card. Please try again later.';

type StripeAddCardProps = { isAddCard: boolean }

const PaymentCardComponent = ({ isAddCard }: StripeAddCardProps) => {
    const accountId = useCurrentAccountId() || '';
    const stripe = useStripe();
    const { name, setName, handleSubmit, messages } = useUpdateBillingMethod(stripe);

    const updateCard = async (event: ?SyntheticEvent<any>) => {
        if (event) {
            event.preventDefault();
        }

        let result = await handleSubmit(accountId);

        if (result === RC_SUCCESS) {
            toast('Payment card updated', { type: 'success' });
            historyBack();
        }
    }

    return (
        <>
            <Panel>
                <PanelHeader
                    title={isAddCard ? 'Add Card Details' : 'Update Card'}
                    description={isAddCard ? 'Activate your account by adding card details' : null}
                />
                <PanelBar>
                    {isAddCard
                        ? <AddCardText />
                        : null
                    }
                    <form onSubmit={handleSubmit} className='max-w-lg'>
                        <AddCardForm
                            name={name}
                            setName={setName}
                            messages={messages}
                        />
                    </form>
                </PanelBar>

                <PanelButtonBar
                    cacheStatus={messages.status}
                    primaryButton={{
                        children: isAddCard ? 'Save Card' : 'Update Card',
                        onClick: messages.status === RC_API_REQUEST ? null : () => {
                            // noinspection JSIgnoredPromiseFromCall
                            updateCard();
                        }
                    }}
                    cancel={messages.status === RC_API_REQUEST ? null : () => historyBack()}
                />

            </Panel>
        </>
    );
};

export const StripeAddCard = ({ isAddCard }: StripeAddCardProps): React$Node => (
    <StripeWrapper>
        <PaymentCardComponent isAddCard={isAddCard}/>
    </StripeWrapper>
);
