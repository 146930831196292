// @flow
import { cn } from './lib/classNames';

import type { HtmlProps } from '../type';

export type SpinnerProps = {
    show?: boolean,
    size?: string,
    color?: string,
    className?: string,
    inline?: ?boolean,
    ...HtmlProps,
};

export const Spinner = ({ className: extraClassName, size, color, show, inline, ...rest }: SpinnerProps): React$Node => {

    let classes = {
        'o-spinner': true,
        'o-spinner--xs': (size === 'xs'),
        'o-spinner--sm': (size === 'sm'),
        'o-spinner--lg': (size === 'lg'),
        'o-spinner--green': (color === 'green'),
        'o-spinner--inline': inline,
    }

    return (
    show == null || show
        ? <span className={cn(classes) + ' ' + (extraClassName || '')} {...rest} />
        : null
    );
};
