// @flow

import { useEffect, useState, useMemo, useCallback } from 'react';
import { TextInput } from '../../element/Input';
import { SearchResultList } from './SearchResultList';
import {
    useGlobalSearchKeyboardInput,
    useRunGlobalSearch,
    usePreSearchResult,
    DOWN_ARROW,
    useGlobalSearchFromQueryParameter,
    useGlobalSearchTextInput,
    useSearchTimeout,
    useTimeout,
    ENTER,
    TAB
} from '../../hoc/GlobalSearch';
import { ESCAPE } from '../../../lib/history';
import { SvgIcon } from '../../element/SvgIcon';
import { Dialog, useDialog } from '../../element/Dialog';

import type { GlobalSearchHook, SearchResultCategory } from '../../hoc/GlobalSearch';
import type { Icon } from '../../../assets/icons';

const SPINNER_DELAY = 50;

function usePostIcon(status: $PropertyType<GlobalSearchHook, 'status'>, input: string): ?Icon {
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const show: ?Function = useMemo(() => status === 'searching' ? () => setShowSpin(true) : null, [setShowSpin, status]);
    useTimeout(show, SPINNER_DELAY);
    useEffect(() => {
        if (status !== 'searching') setShowSpin(false);
    }, [setShowSpin, status]);

    return status === 'searching' && showSpin
        ? 'spinner'
        : null
}

export function GlobalSearch(): React$Node {
    const {
        input, setInput,
        onInputFocussed,
    } = useGlobalSearchTextInput();

    const { status, startSearch, resourceResult, websiteResult, clearSearch, noResourcesMatch, } = useRunGlobalSearch();
    useGlobalSearchFromQueryParameter(setInput, startSearch);
    const postIcon = usePostIcon(status, input);

    const fullyClearSearch = useCallback(() => {
        clearSearch();
        setInput('');
    }, [clearSearch, setInput]);

    const dialog = useDialog([], () => fullyClearSearch());

    const { focusFirst } = useGlobalSearchKeyboardInput(dialog.show);

    const { recent, history, addPreviousSearch } = usePreSearchResult();
    const { hide } = dialog;

    const onSearchResultClick = useCallback(() => {
        fullyClearSearch();
        hide();
    }, [fullyClearSearch, hide])

    useSearchTimeout(input, startSearch);

    let currResults: $ReadOnlyArray<?SearchResultCategory> = [recent, history];

    if (status !== 'initial' && input !== '') {
        if (resourceResult != null || websiteResult != null) {
            currResults = [];
            if (resourceResult != null) currResults.push(resourceResult);
            if (websiteResult != null) currResults.push(websiteResult);
            if (noResourcesMatch && websiteResult == null) currResults.push(history);
        }
    }

    return (
        <div>
            <SvgIcon onClick={() => dialog.show()} svg='search' className='c-user-nav__link' />

            <Dialog
                /*className='c-search'  // Dialog doesn't take a class, but this is useful to reference the stylesheet. */
                dialog={dialog}
                type='wide'
                title={
                        <TextInput
                            value={input}
                            onChange={setInput}
                            autoFocus={true}
                            preIcon="search"
                            className={'c-search__input'}
                            placeholder={'Start typing to search...'}
                            postText={postIcon == null && input !== '' ? 'Clear' : ''}
                            postIcon={postIcon}
                            onPostInlayClick={fullyClearSearch}
                            onFocus={() => onInputFocussed(true)}
                            onBlur={() => onInputFocussed(false)}
                            onKeyDown={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                                if (e.keyCode === TAB || e.keyCode === DOWN_ARROW) {
                                    focusFirst();
                                    e.preventDefault();
                                    e.stopPropagation();
                                } else if (e.keyCode === ENTER) {
                                    startSearch(input);
                                    e.preventDefault();
                                } else if (e.keyCode === ESCAPE) {
                                    dialog.hide();
                                    // $FlowFixMe
                                    if (e.target.blur) e.target.blur();
                                }
                            }}
                            tabIndex="99"
                        />
                }
                render={() => (
                    <div className="c-search">


                        <SearchResultList
                            results={currResults}
                            addPreviousSearch={addPreviousSearch}
                            onClick={onSearchResultClick}
                        />

                    </div>
                )}
            />

        </div>
    );
}