// @flow

import React, { Fragment } from "react";

export type LayoutProps = {
    sidebar: React$Node,
    content: React$Node,
    kind?: string,
}

export const Layout = ({ sidebar, content, kind }: LayoutProps): React$Node => (

    <div className='l-app'>
    {kind === 'auth'
        ? <>
            <div className='l-app__sidebar l-app__sidebar--wide'>
                {sidebar}
            </div>
            <div className='l-app__content justify-center'>
                {content}
            </div>
        </>
        : <>
            <div className='l-app__sidebar'>
                <div className='c-sidebar'>
                    {sidebar}
                </div>
            </div>
            <div className='l-app__content'>
                {content}
            </div>
        </>
    }
    </div>
)