// @flow

import { useEffect } from 'react';
import { Modal } from '../element/Dialog';
import { NavLink } from 'react-router-dom';
import { useLogin } from '../hoc/Login';
import { history } from '../../lib/history';

import { TwoFactorForm, UsernamePasswordForm } from '../base/Login';
import { Button } from '../element/Button';

// hide the gui completely after 5 mins.
const OVERLAY_TIMEOUT = 1000 * 60 * 5;

const forceLogout = () => {
    history.push('/logout/');
}

export const LoginOverlay = (): React$Node => {
    const login = useLogin();
    useEffect(() => {
        const delay = setTimeout(() => {
            history.push('/logout/');
        }, OVERLAY_TIMEOUT);

        return () => clearTimeout(delay);
    }, []);

    return (
        <Modal
            visible={true}
            hide={forceLogout}
            render={() => (
                <form onSubmit={login.tryLogin}>
                    <div className='c-modal__popup'>
                        <div className='c-modal__popup-header'>
                            <h3>Stay logged in?</h3>
                            <Button
                                className='c-modal__close'
                                size='sm'
                                kind='tertiary'
                                color='grey'
                                onClick={forceLogout}
                                preIcon='cross'
                            />
                        </div>

                        <div className='c-modal__popup-body'>
                            <div>
                                <p className='mb-4'>Since you've been inactive for a while, please enter your password to continue.</p>
                                {login.twoFactor
                                    ? <TwoFactorForm {...login} />
                                    : <UsernamePasswordForm login={login} disableUsername={true}/>
                                }
                            </div>
                        </div>

                        <div className='c-modal__popup-footer'>
                            <div className='c-modal__popup-info'>&nbsp;</div>

                            <div className='c-modal__popup-buttons'>
                                <NavLink className='nav-link' to='/logout/'>
                                    <Button
                                        size='sm'
                                        kind={'tertiary'}
                                        color='grey'
                                    >
                                        Logout
                                    </Button>
                                </NavLink>
                                <Button
                                    size='sm'
                                    kind={'primary'}
                                    type='submit'
                                    onClick={login.tryLogin}
                                    state={login.processing ? 'loading' : ''}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        />
    );

};