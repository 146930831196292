// @flow
import { cn } from './lib/classNames';
import { Tooltip } from './Tooltip';

import type { ValueSelectProps } from './ValueSelect';

export type ToggleValueSelectChoice<V> = {
    +label: React$Node,
    +value: V,
    +description?: React$Node,
    +default?: boolean,
    +disabledTooltip?: ?React$Node,
};

type ToggleGroupProps<V> = {
    ...ValueSelectProps<V>,
    +options: $ReadOnlyArray<ToggleValueSelectChoice<V>>,
    +selected: ?V,
    +addOther?: V,
    +fullWidth?: boolean,
};


export function ToggleGroup<V>({ options, selected, onSelect, addOther, fullWidth, ...rest }: ToggleGroupProps<V>): React$Node {
    const selectedIndex = options.findIndex(option => option.value === selected);

    const select = (index: number) => {
        const idx = Number(index);
        if (idx >= 0 && idx < options.length) {
            onSelect(options[idx].value);
        }
    };

    return (
        <div className={'c-toggle-group' + (fullWidth ? ' c-toggle-group--full-width' : '')}>
            {options.map((item, key) =>
                <Tooltip overlay={item.disabledTooltip}>
                    <div
                        key={key}
                        className={cn({
                            'c-toggle-group__option': true,
                            'c-toggle-group__option--is-selected': selectedIndex === key,
                            'c-toggle-group__option--is-disabled': item.disabledTooltip != null,
                        })}
                        onClick={item.disabledTooltip == null
                            ? (e: SyntheticInputEvent<HTMLDivElement>) => select(key)
                            : null
                        }
                    >
                        {item.label}
                    </div>
                </Tooltip>
            )}
            {addOther != null
                ? <div
                    className={cn({'c-toggle-group__option': true, 'c-toggle-group__option--is-selected': selectedIndex === -1})}
                    onClick={(e: SyntheticInputEvent<HTMLDivElement>) => onSelect(addOther)}
                >
                    Other
                </div>
                : null
            }
            {process.env.NODE_ENV === 'development' && selected != null && selectedIndex === -1 && addOther == null
                ? <option value={-1}>Unknown value in ValueSelect</option>
                : null}
        </div>
    );
}
