// @flow

import { RC_INITIAL, RC_CACHED, RC_FETCHING } from '../resource/type';
import type { StoreClearAction } from '../type';
import type { OrbitStateAction, OrbitState, } from './type';
import type { AuthSetSelectedAccount } from '../auth/type';

const emptyState: OrbitState = {
    containers: {
        details: new Map(),
        meta: new Map(),
        objects: new Map(),
    },

    fetched: RC_INITIAL,
    account: null,
    viewObject: null,
    currentImageTags: null,
    manifestSizes: new Map(),
};

export const OrbitReducer = (
    state: OrbitState = emptyState,
    action: OrbitStateAction | AuthSetSelectedAccount | StoreClearAction
): OrbitState => {

    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'AUTH_SELECT_ACCOUNT':
        return {
            ...emptyState,
        };
    case 'ORBIT_DETAILS':
        return {
            ...state,
            containers: {
                ...state.containers,
                details: (new Map(state.containers.details)).set(
                    action.payload.details.name,
                    action.payload.details
                ),
            },
        };
    case 'ORBIT_CONTAINERS':
        return {
            ...state,
            containers: {
                ...state.containers,
                details: new Map(action.payload.containers),
            },
        };
    case 'ORBIT_FETCHED':
        return {
            ...state,
            fetched: RC_CACHED,
        };
    case 'ORBIT_OBJECTS':
        return {
            ...state,
            containers: {
                ...state.containers,
                objects: (new Map(state.containers.objects)).set(
                    action.payload.container,
                    action.payload.objects,
                )
            },
        };
    case 'ORBIT_CLEAR':
        return {
            ...state,
            containers: { ...emptyState.containers, },
            fetched: RC_FETCHING,
        };
    case 'ORBIT_META':
        return {
            ...state,
            containers: {
                ...state.containers,
                meta: (new Map(state.containers.meta)).set(
                    action.payload.container,
                    action.payload.meta
                )
            },
        };
    case 'ORBIT_ACCOUNT_META':
        return {
            ...state,
            account: action.payload.meta,
        }
    case 'ORBIT_VIEW_OBJECT':
        return {
            ...state,
            viewObject: {
                ...action.payload,
            },
        }
    case 'ORBIT_SET_IMAGE_TAGS':
        return {
            ...state,
            currentImageTags: {
                container: action.payload.container,
                imageTags: action.payload.imageTags,
            }
        }
    case 'ORBIT_MANIFEST_SIZE':
        return {
            ...state,
            manifestSizes: (new Map(state.manifestSizes))
                .set(action.payload.sha256, action.payload.size),
        }
    default:
        void (action: empty);
        break;
    }

    return state;
};