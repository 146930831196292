// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { Notice } from '../../../element/Notice';
import { Checkbox } from '../../../element/Checkbox';

import type { EditorModal } from '../../../common/Editor';
import type { BbServer } from '../../../../api/type.srv';

type DiskEncryptedProps = {
    +editor: EditorModal<boolean, null, BbServer>,
}

export const DiskEncrypted = ({ editor }: DiskEncryptedProps): React$Node => {
    if (editor.value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title='Disk Encryption'
                description='Enable full disk encryption for the local root volume'
            />
            <PanelBar>
                <Checkbox
                    checked={editor.value}
                    onChange={editor.setValue}
                    label='Enable Disk Encryption'
                />

                <Notice type='info' icon='info-fill'>
                    Encryption can affect disk read/write performance so we normally suggest only enabling this option where
                    regulatory or compliance obligations require Encryption at Rest.
                </Notice>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );

}