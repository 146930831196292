// @flow

import { useEffect, useState, } from 'react';
import { Layout } from './Layout';
import { LoginSidebar } from './Sidebar';
import { Notice } from '../element/Notice';
import { PasswordInput, TextInput, } from '../element/Input';
import { Button } from '../element/Button';
import to from 'await-to-js';
import { request } from '../../api/rest';
import { usersUrl } from '../../api/url';
import { Link } from 'react-router-dom';

const PASSWORD_RESET_HOST = window?.cgrConfig?.PASSWORD_RESET_HOST || process.env.TEST_PASSWORD_RESET_HOST || 'manage.brightbox.com';

const useResetPassword = () => {
    const [pass0, setPass0] = useState<string>('');
    const [pass1, setPass1] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [badToken, setBadToken] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        const tokenMatch = window.location.search.match(/token=([a-z0-9]+)/i);
        if (!tokenMatch) {
            setError('Missing token');
        } else {
            setToken(tokenMatch[1]);
        }
    }, []);

    return {
        pass0, setPass0,
        pass1, setPass1,

        error,
        processing,
        badToken,
        submitted,

        tryReset: async (e: SyntheticUIEvent<any>) => {
            e.preventDefault();

            if (token === '') {
                setError('Missing token');
            } else if (pass0 !== pass1) {
                setError('Passwords do not match');
            } else {
                setError('');
                setProcessing(true);

                let [err, ] = await to(request({
                    url: usersUrl + '/reset_password',
                    method: 'PUT',
                    data: {
                        reset_token: token,
                        new_password: pass0,
                    }
                }));

                setProcessing(false);

                if (err) {
                    if (err.response && err.response.data && Array.isArray(err.response.data.errors)) {
                        setError(err.response.data.errors.join('. '));
                    } else {
                        setBadToken(true);
                        setError('The password reset token you entered is invalid or has expired. ' +
                            'Please enter you email address below and you\'ll receive an email containing a new ' +
                            'link to reset your password.'
                        );
                    }
                } else {
                    setSubmitted(true);
                }
            }
            return false;
        }
    };

};

const ResetPasswordForm = () => {
    const { badToken, error, pass0, pass1, processing, setPass0, setPass1, submitted, tryReset } = useResetPassword();

    if (badToken) {
        return <ForgottenPasswordForm badToken={true}/>;
    }

    return (
        <div className='c-auth'>
            <form className='c-auth__form' onSubmit={tryReset}>
                <h2 className='c-auth__heading'>Create New Password</h2>

                {error !== ''
                    ? <Notice type='error'>{error}</Notice>
                    : null}

                {submitted
                    ? <>
                         <Notice type='info' icon='thumb'>Password reset completed</Notice>
                        <p><Link to='/'><Button className='w-full'>Return to Login</Button></Link></p>
                    </>
                    : <>

                        <div className='c-auth__input'>
                            <PasswordInput
                                label='New Password'
                                id='new-password'
                                autoComplete='new-password'
                                autoFocus={true}
                                value={pass0}
                                onChange={setPass0}
                                helperText='Minimum of 8 characters'
                            />
                        </div>

                        <div className='c-auth__input'>
                            <PasswordInput
                                label='Confirm Password'
                                id='confirm_password'
                                autoComplete='new-password'
                                value={pass1}
                                onChange={setPass1}
                            />
                        </div>

                            <Button
                                kind='primary'
                                type="submit"
                                className='c-auth__button'
                                onClick={(e) => { tryReset(e); }}
                                state={processing ? 'loading' : ''}
                            >
                                Reset Password
                            </Button>

                        <Link to='/'>
                            <Button
                            kind='tertiary'
                            color='grey'
                            className='c-auth__button'
                            >Cancel</Button>
                        </Link>

                    </>
                }
            </form>
        </div>
    );
};

export const ResetPassword = (): React$Node => {
    return (
        <Layout
            kind='auth'
            sidebar={<LoginSidebar/>}
            content={<ResetPasswordForm/>}
        />
    );
};

const useForgottenPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');


    return {
        email, setEmail,
        processing,
        submitted,
        error,

        tryRequest: async (e: SyntheticUIEvent<any>) => {
            e.preventDefault();

            setError('');
            setProcessing(true);

            let [err, ] = await to(request({
                url: usersUrl + '/generate_password_reset',
                method: 'POST',
                data: {
                    "email_address": email,
                    "reset_host": PASSWORD_RESET_HOST,
                }
            }));

            setProcessing(false);

            if (err) {
                if (err.response && err.response.data && Array.isArray(err.response.data.errors)) {
                    setError(err.response.data.errors.join('. '));
                } else {
                    setError('Error requesting reset email.');
                }
            } else {
                setSubmitted(true);
            }
        }
    };
};

const ForgottenPasswordForm = ({ badToken }: { badToken?: boolean }): React$Node => {
    const { email, processing, setEmail, submitted, tryRequest } = useForgottenPassword();

    return (
        <div className='c-auth'>
            <form className='c-auth__form' onSubmit={null}>
                <h2 className='c-auth__heading'>Forgot your password?</h2>
                {badToken && !submitted
                    ? <Notice type='error'>
                        The password reset link has expired or is invalid. Please
                        enter your email address again to receive a new password reset email.
                    </Notice>
                    : null
                }
                {submitted
                    ? <>
                        <Notice type='info' icon='thumb'>
                            Please check your email for your password reset link
                        </Notice>

                        <Link to='/'>
                            <Button
                                kind='primary'
                                className='c-auth__button'
                            >Return to Sign In</Button>
                        </Link>
                    </>
                    : <>
                        <p className='mb-6 text-gray-700'>If you've forgotten
                        your password, we can send you a password reset email.</p>
                        <TextInput
                            label='Email Address'
                            value={email}
                            onChange={setEmail}
                            autoFocus={true}
                            className='c-auth__input'
                        />
                        <Button
                            kind='primary'
                            type="submit"
                            className='c-auth__button'
                            onClick={(e) => {
                                tryRequest(e);
                            }}
                            state={processing ? 'loading' : ''}
                        >
                            Send Reset Email
                        </Button>

                        <Link to='/'>
                            <Button
                                kind='tertiary'
                                color='grey'
                                className='c-auth__button'
                            >Cancel</Button>
                        </Link>
                    </>
                }
            </form>
        </div>
    );

};

export const ForgottenPassword = (): React$Node => {
    return (
        <Layout
            kind='auth'
            sidebar={<LoginSidebar/>}
            content={<ForgottenPasswordForm/>}
        />
    );
};