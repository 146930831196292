// @flow
import { useState } from 'react';
import { Status } from './Styled.js';
import { ProgressBar } from './ProgressBar';

export type BuildProgressKind = 'server' | 'database_server' | 'load_balancer' | 'volume';

type BuildProgressBarProps = {
    +status: ?string,
    +progress: ?number,
}

function isResourceFinishedBuildingStatus(status: ?string): boolean {
    return -1 !== [
        'active',       // most resources
        'detached',     // volumes
        'attached',     // volumes
    ].indexOf(status);
}

export const BuildProgressBar = ({ status, progress, }: BuildProgressBarProps): React$Node => {
    const [cacheStatus, setCacheStatus] = useState(status);

    if (status !== cacheStatus) {
        if (isResourceFinishedBuildingStatus(status) && cacheStatus === 'creating') {
            setTimeout(() => setCacheStatus('active'), 4000);
        } else if (cacheStatus === 'active') {
            setCacheStatus(status);
        }
    }

    return (
        <div className='flex flex-row items-center'>
            <Status status={status}/>
            {cacheStatus === 'creating'
                ? <ProgressBar
                    max={1}
                    value={status === 'creating' ? progress || 0 : 1}
                    className='flex-grow inline-block ml-2 overflow-hidden'
                    color='green'
                    explanation={null}
                />
                : null
            }
        </div>

    );
}
