// @flow

import { Notice } from '../../element/Notice';
import { Dialog } from '../../element/Dialog';
import { Button, CopyButton } from '../../element/Button';
import { Spinner } from '../../element/Spinner';

import type { BbVolume } from '../../../api/type.volume';
import type { VolumeDetachHook, VolumeBootHook } from '../../hoc/Volumes';
import type { Editor } from '../../common/Editor';
import type { DialogState } from '../../element/Dialog';

type VolDetachDialogProps = {
    +detach: VolumeDetachHook
}

export function VolDetachDialog({ detach }: VolDetachDialogProps): React$Node {
    return (
        <Dialog
            dialog={detach.dialog}
            messages={detach.messages}
            title="Detach Volume?"
            render={(volume: ?BbVolume) =>
                <>
                    <p>Please confirm you want to detach this Volume.</p>
                    <p>{volume?.server?.status === 'active'
                        ? <Notice type="warning" icon="warning-fill"><b>Warning</b> – this Volume is attached to an <code>active</code> server. To avoid data
                            loss, either the server should be shutdown or the Volume should be unmounted before detaching.</Notice>
                        : null
                    }</p>
                </>
            }
        />
    );
}

export function VolBootDialog({ boot }: { boot: VolumeBootHook }): React$Node {
    return (
        <Dialog
            dialog={boot.dialog}
            messages={boot.messages}
            title="Change Boot Volume?"
            render={(volume: ?BbVolume) =>
                <>
                    <p>Are sure you want to change the boot volume of <b>{volume?.server?.name || volume?.server?.id}</b> to {volume?.id}?</p>
                    <Notice type="warning" icon="warning-fill">The Volume must contain a bootable filesystem</Notice>
                </>
            }
        />
    );
}

type VolAttachDialogProps = {
    +editor: Editor<$ReadOnlyArray<string>, ?React$Node, BbVolume>,
    +confirmDialog: DialogState<null>,
}

export function VolAttachDialog({ editor, confirmDialog }: VolAttachDialogProps): React$Node {
    return (
        <Dialog
            title={editor.errors == null ? 'Attach Volume to Cloud Server?' : 'Volume Attach Error'}
            dialog={confirmDialog}
            messages={editor.messages}
            footerLink={<a href='https://www.brightbox.com/docs/guides/volumes/attach-detach-volume/' target='blank'>
            <Button size='sm' kind='bare' preIcon='info-fill'>Attaching Volumes</Button></a>}
            render={() => (
                <div>
                    {editor.errors
                        ? <Notice type='warning' icon='warning-fill'>{editor.errors}</Notice>
                        : <>
                            <p className='mb-4'>Are you sure you want to attach this volume?</p>
                            <Notice icon='info-fill' type='info'>You will need
                            to <a href='https://www.brightbox.com/docs/guides/volumes/attach-detach-volume/' target='blank'>mount the volume</a> inside
                            the Cloud Server before it can be used</Notice>
                        </>
                    }
                </div>
            )}
        />
    );
}

type VolAttachedDialogProps = {
    +attachedDialog: DialogState<BbVolume>,
}

export function VolAttachedDialog({ attachedDialog }: VolAttachedDialogProps): React$Node {
    return (
        <Dialog
            dialog={attachedDialog}
            title='Volume Attached'
            type='wide'
            footerLink={<a href='https://www.brightbox.com/docs/guides/volumes/attach-detach-volume/' target='blank'>
            <Button size='sm' kind='bare' preIcon='info-fill'>Attaching and Detaching Volumes</Button></a>}
            render={
                (volume: ?BbVolume) => {
                    if (!volume) return <Spinner />
                    
                    const volumeRef = !!volume.filesystem_label ? volume.filesystem_label : volume.serial
                    const volumeRefPath = !!volume.filesystem_label ? `/dev/disk/by-label/${volume.filesystem_label}` : `/dev/disk/by-id/virtio-${volume.serial}`
                    const mountPoint = `/mnt/${volumeRef}`
                    const mkdirCmd = `sudo mkdir -p ${mountPoint}`
                    const mountCmd = `sudo mount -o discard,defaults,noatime ${volumeRefPath} ${mountPoint}`
                    // this "|| 'ext4'" is a hack; if we have a null filesystem_type we shouldn't really be offering
                    // any suggested fstab entry. but anyone that's using it that way will probably understand
                    // the fstab line...
                    const fstabCmd = `echo '${volumeRefPath} ${mountPoint} ${volume.filesystem_type||'ext4'} defaults,nofail,discard 0 0' | sudo tee -a /etc/fstab`

                    return (
                        <div className='space-y-4'>
                            {volume.filesystem_type
                                ? <>
                                    <Notice type='info' icon='info-fill'>
                                        <b>Next steps</b> – The Volume must now be mounted before it can be accessed.
                                        Below are some pre-filled instructions for mounting the volume, but please also see the <a href='https://www.brightbox.com/docs/guides/volumes/attach-detach-volume/' target='blank'>documentation</a> for full details.
                                    </Notice>

                                    <code className='bg-gray-200 block text-xs p-4 rounded-md leading-relaxed break-words'>
                                        <span className='text-gray-600'># create the mount point</span><br/>
                                        <span className='text-gray-600'>$</span> <span className='select-all mr-1'>{mkdirCmd}</span> <CopyButton size='xs' kind='bare' value={mkdirCmd}/><br/><br/>
                                        <span className='text-gray-600'># mount the volume to above</span><br/>
                                        <span className='text-gray-600'>$</span> <span className='select-all mr-1'>{mountCmd}</span> <CopyButton size='xs' kind='bare' value={mountCmd}/><br/><br/>
                                        <span className='text-gray-600'># auto-mount volume after reboots</span><br/>
                                        <span className='text-gray-600'>$</span> <span className='select-all mr-1'>{fstabCmd}</span> <CopyButton size='xs' kind='bare' value={fstabCmd}/><br/>
                                    </code>
                                </>
                                : <Notice type='info' icon='info-fill'>
                                    <b>Next steps</b> – The Volume is now attached but you need to <a href='https://www.brightbox.com/docs/guides/volumes/manual-formatting/' target='blank'>manually format</a> and mount
                                    it before it can be accessed by the Cloud Server.
                                </Notice>
                            }
                        </div>
                    );
                }
        }
        />
    );
}

