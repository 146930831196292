// @flow
import { useEffect, useMemo, useState, useCallback, } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useListResource, useSearchOnResourceId } from '../../../hoc/ListPage';
import { usePager } from '../../../element/ArrayPager';
import { PagerBar } from '../../../element/PagerBar';
import { getServerTypeGroups, } from '../../../../lib/ServerTypeGroup';
import { getMonthlyPrice } from '../../../../lib/pricing';
import { Chip } from '../../../element/Chip';
import { Checkbox } from '../../../element/Checkbox';
import { isShowDetailsResourceStatus } from '../../../element/Skeleton';
import { Panel, PanelBar, PanelHeader, PanelSearchBar } from '../../../element/Panel';
import { Table, Tr, Th, Td } from '../../../element/Table';
import { NoMatchingResourceTr } from '../../../element/NoResourceMessages';
import { ServerLimit } from '../../../element/LimitTooltip';
import { Notice } from '../../../element/Notice';
import { wholeSize, formatResourceCost } from '../../../element/Styled';
import { useResourceSearch } from '../../../element/Search';
import { TextOnlySearchEditor, textOnlySearch } from '../../../common/TextOnlySearch';
import { Pill } from '../../../element/Pill';
import { WINDOWS_LICENSE_ID, isWindowsImage } from '../../../../api/type.srv';
import { LocalStorageTypeSelector } from './LocalStorageTypeSelector';
import { ServerTypeTabPanel } from './ServerTypeTabPanel';
import { serverTypeIsDisabled } from './lib';
import { ServerLimitWrapper } from './ServerLimitWrapper';
import { Tooltip } from '../../../element/Tooltip';

import type { BbServerType, BbCollectedImage, BbImage } from '../../../../api/type';
import type { EditorDirect } from '../../../common/Editor';
import type { ListSortDef } from '../../../hoc/ListPage';
import type { Gigabytes } from '../../../../api/type.units';
import type { BbVolume } from '../../../../api/type.volume';
import type { TabProps } from '../../../element/UiStateHooks';
import type { ImageOrVolume } from '../../../hoc/CreateResource';

type ServerTypeSelectorProps = {
    +editor: EditorDirect<?BbServerType, null>,
    +root_volumeEditor: EditorDirect<string | Gigabytes, ?string>,
    +availableMemory: number,
    +selectedImage: ?BbCollectedImage | ?BbImage,
    +selectedVolume: ?BbVolume,
    +defaultShowAll: boolean,
    +imageOrVolume: ImageOrVolume,
};

export const DEFAULT_SERVER_TYPE_HANDLE: string = window?.cgrConfig?.BRIGHTBOX_DEFAULT_SERVER_TYPE_HANDLE || '1gb.ssd';

const searchDef = textOnlySearch<BbServerType>('server_type');

function useTypeSelectorTabs(defaultShowAll: boolean, imageOrVolume: ImageOrVolume): TabProps {
    const [selectedIndex, setSelected] = useState<number>(defaultShowAll ? 3 : 0);

    const onSelect = useCallback((idx: number) => {
        setSelected(idx);
    }, []);

    useEffect(() => {
        if (imageOrVolume === 'volume') {
            setSelected(1);
        }
    }, [imageOrVolume]);

    return {
        selectedIndex,
        onSelect,
    };
}

export function useAutoSelectServerType(
    allItems: ReadonlyArray<BbServerType>,
    availableMemory: number,
    selected: ?BbServerType,
    image: ?BbCollectedImage | ?BbImage,
    onSelect,
) {
    useEffect(() => {
        if (
            // wait for the list of images, and the ram restriction from the account.
            Array.isArray(allItems) && allItems.length > 0 && availableMemory > -1 &&

            // and only try to select if there's no selection, or if the selected type doesn't
            // meet the images memory requirements
            (
                selected === null ||
                (selected && image && image.min_ram != null && selected.ram < image.min_ram)
            )
        ) {
            // Put all the images into descending RAM size order.
            // Rely on that order to select an image with the 'minimum acceptable' ram,
            // by just letting the reduce()s always overwrite a found image
            const sorted = allItems.sort((a, b) => {
                let res = (b.ram - a.ram);
                if (res === 0 && b.handle && a.handle) {
                    res = b.handle.length - a.handle.length;
                }
                return res;
            });
            // we try to keep the same 'category' if one is selected - eg ssd, ssg-std-opt, nbs, etc.
            let selectedCategory: ?string = null;
            if (selected != null && selected.handle != null) {
                [, selectedCategory] = selected.handle.split(".", 2);
            }

            const [defaultType, matchingCategory, fallback] = sorted.reduce((acc, serverType) => {
                const [, thisCategory] = serverType.handle && serverType.handle.indexOf(".") !== -1
                    ? serverType.handle.split('.', 2)
                    : [null, null];

                if (
                    serverType.handle === DEFAULT_SERVER_TYPE_HANDLE
                    && serverType.ram <= availableMemory
                    && (image?.min_ram == null || image.min_ram <= serverType.ram)
                ) {
                    acc[0] = serverType;
                }
                if (
                    serverType.ram <= availableMemory
                    && (image?.min_ram == null || image.min_ram <= serverType.ram)
                ) {
                    if (selectedCategory != null && thisCategory === selectedCategory) acc[1] = serverType;
                    else acc[2] = serverType;
                }

                return acc;
            }, [null, null, null]);

            if (matchingCategory) {
                onSelect(matchingCategory);
            } else if (defaultType) {
                onSelect(defaultType);
            } else if (fallback) {
                onSelect(fallback);
            }
        }

    }, [selected, allItems, onSelect, availableMemory, image]);
}

export const ServerTypeSelector = ({ editor, availableMemory, selectedImage, selectedVolume, defaultShowAll, root_volumeEditor, imageOrVolume, }: ServerTypeSelectorProps): React$Node => {
    const serverTypes = useListResource<BbServerType>('server_type', typeSort);
    const allChoices = useMemo(
        () => getServerTypeGroups(availableMemory > -1 ? serverTypes.items : []),
        [serverTypes.items, availableMemory]
    );
    const searchHook = useResourceSearch(searchDef, serverTypes.items);
    const { editor: searchEditor, items: searchedItems, searchTerm } = searchHook;
    useSearchOnResourceId('server_type', searchTerm);
    const needsNetworkType = imageOrVolume === 'volume';
    const tabs = useTypeSelectorTabs(defaultShowAll, imageOrVolume);

    const pager = usePager('ServerTypeSelector', searchedItems, searchedItems.length);

    const { items: allItems, status } = serverTypes;
    const { items, } = pager;
    const { value: selected, setValue: onSelect } = editor;

    useAutoSelectServerType(allItems, availableMemory, selected, selectedImage, onSelect);

    const atAccountLimit = useMemo(() => {
        return isShowDetailsResourceStatus(status) && availableMemory !== -1 && allItems.findIndex(x => x.ram <= availableMemory) === -1;
    }, [availableMemory, status, allItems]);

    return (
        <Panel>
            <PanelHeader
                title="Choose Server Type"
            />
            <Tabs {...tabs}>
                <TabList>
                    <Tab disabled={needsNetworkType}>
                        Local SSD
                        <Tooltip
                            overlay='Local SSD server types have the root disk located
                            on high performance local SSD disk storage.'
                        >
                            <Pill>?</Pill>
                        </Tooltip>
                    </Tab>
                    <Tab>
                        Block Storage
                        <Tooltip
                        overlay='Block Storage server types have the root disk located
                        on flexible network block storage volumes.'
                        >
                            <Pill>?</Pill>
                        </Tooltip>
                    </Tab>
                    <Tab disabled={needsNetworkType}>
                        All Types
                    </Tab>
                </TabList>

                {atAccountLimit
                    ? <PanelBar border={false}>
                        <Notice type="warning">
                            <ServerLimit />
                        </Notice>
                    </PanelBar>
                    : null
                }
                <TabPanel>
                    <LocalStorageTypeSelector
                        selected={selected}
                        onSelect={onSelect}
                        allChoices={allChoices}
                        availableMemory={availableMemory}
                        selectedImage={selectedImage}
                        selectedVolume={selectedVolume}
                    />
                </TabPanel>
                <TabPanel>
                    <ServerTypeTabPanel
                        choices={allChoices['nbs'].types}
                        selected={selected}
                        onSelect={onSelect}
                        availableMemory={availableMemory}
                        root_volumeEditor={root_volumeEditor}
                        selectedImage={selectedImage}
                        selectedVolume={selectedVolume}
                    />
                </TabPanel>
                <TabPanel>
                    <PanelSearchBar search={<TextOnlySearchEditor editor={searchEditor} />} />
                    <PanelBar padding={false}>
                        <Table>
                            <thead>
                            <Tr>
                                <Th selector={true}>&nbsp;</Th>
                                <Th>Name</Th>
                                <Th>Handle</Th>
                                <Th>Price per month</Th>
                                <Th>RAM</Th>
                                <Th>CPU</Th>
                                <Th>Local SSD</Th>
                            </Tr>
                            </thead>
                            <tbody>
                            {items.map((server_type: BbServerType) => {
                                const [, diskDoesntFit, disabled] = serverTypeIsDisabled(server_type, availableMemory, selectedImage, null, true);

                                return (
                                    <Tr
                                        key={server_type.id}
                                        selected={selected?.id === server_type.id}
                                        selectable={true}
                                        disabled={disabled}
                                        onClick={disabled ? null : () => onSelect(server_type)}
                                    >
                                        <Td selector={true}>
                                            {disabled
                                                ? null
                                                : <Checkbox
                                                    color={'blue'}
                                                    checked={selected?.id === server_type.id}
                                                    onChange={(value: boolean) => value ? onSelect(server_type) : void 0}
                                                />
                                            }
                                        </Td>
                                        <Td>
                                            <ServerLimitWrapper
                                                ram={server_type.ram}
                                                availableMemory={availableMemory}
                                                min_ram={selectedImage?.min_ram}
                                                key={server_type.id}
                                                needsToBeInactive={false}
                                                diskDoesntFit={diskDoesntFit
                                                    ? {
                                                        disk: server_type.disk_size,
                                                        min_disk: selectedImage?.disk_size || 0,
                                                    }
                                                    : null
                                                }
                                            >
                                                <Chip
                                                    id={server_type.id}
                                                    name={server_type.name}
                                                />
                                            </ServerLimitWrapper>
                                        </Td>
                                        <Td><span className="font-mono text-sm">{server_type.handle || '–'}</span></Td>
                                        <Td>£{getMonthlyPrice(server_type.id)}</Td>
                                        <Td>{wholeSize(server_type.ram)}</Td>
                                        <Td>{server_type.cores} VCPU</Td>
                                        <Td>{server_type.disk_size > 0 ? wholeSize(server_type.disk_size) : '–'}</Td>
                                    </Tr>
                                );
                            })}
                            <NoMatchingResourceTr
                                search={searchHook}
                                colSpan={5}
                                kind={'server_type'}
                            />
                            </tbody>
                        </Table>
                        {selectedImage && isWindowsImage(selectedImage)
                            ? <hr className="mb-3" />
                            : null
                        }
                    </PanelBar>
                    <PagerBar {...pager.pager} />
                </TabPanel>
            </Tabs>
            {selectedImage && isWindowsImage(selectedImage) && getMonthlyPrice(WINDOWS_LICENSE_ID) > 0
                ? <PanelBar padding={false}>
                    <Notice icon="info-fill" type="warning" className="mx-8 mb-8">
                        <b>Note:</b> Windows Cloud Servers have an additional licence fee of
                        £{formatResourceCost(getMonthlyPrice(WINDOWS_LICENSE_ID))} per month
                    </Notice>
                </PanelBar>
                : null
            }
        </Panel>
    );
};

export const typeSort: ListSortDef<BbServerType> = {
    name: 'database_type_select',
    fields: {
        _default: (a: BbServerType, b: BbServerType): number => a.id.localeCompare(b.id),
    }
};
