// @flow

import { RC_CACHED, RC_INITIAL, RC_FETCHING } from '../resource/type';

import type { CloudGuiState } from '../cloudgui';
import type { BbApiClient } from '../../api/type.cli';
import type { BbNestedCloudIp, BbCloudIp } from '../../api/type.cip';
import type { BbNestedDatabaseServer, BbDatabaseServer, BbDatabaseSnapshot } from '../../api/type.dbs';
import type { BbNestedImage, BbImage, BbNestedServerGroup, BbServerGroup, BbNestedUser, BbUser, BbCollaboration } from '../../api/type';
import type { BbNestedLba, BbLba } from '../../api/type.lba';
import type { BbNestedServer, BbServer } from '../../api/type.srv';
import type { OrbitContainersDetailCollection } from '../Orbit/type';
import type { GlobalSearchState } from '../GlobalSearch/type';
import type { BbVolume } from '../../api/type.volume';

export type GlobalSearchItem = {
    id: string,
    resource_type: string,
    values: $ReadOnlyArray<string>,
}

const indexers = {
    api_client: (client: BbApiClient): $ReadOnlyArray<string> => [client.id, client.name, client.description],
    cloud_ip: (ip: BbNestedCloudIp | BbCloudIp): $ReadOnlyArray<string> => [ip.id, ip.name,],
    collaboration: (col: BbCollaboration): $ReadOnlyArray<string> => {
        if (col.user) return [col.id, col.user.email_address, col.user.name,];

        return [col.id, col.email];
    },
    database_server: (x: BbNestedDatabaseServer | BbDatabaseServer): $ReadOnlyArray<string> => [x.id, x.name, x.database_engine],
    database_snapshot: (x: BbDatabaseSnapshot): $ReadOnlyArray<string> => [x.id, x.name],
    image: (x: BbNestedImage | BbImage): $ReadOnlyArray<string> => [x.id, x.name],
    load_balancer: (x: BbNestedLba | BbLba): $ReadOnlyArray<string> => [x.id, x.name],
    server_group: (x: BbNestedServerGroup | BbServerGroup): $ReadOnlyArray<string> => [x.id, ...(x.name != null ? [x.name] : [])],
    server: (x: BbNestedServer | BbServer): $ReadOnlyArray<string> => [x.id, x.name],
    user: (x: BbNestedUser | BbUser): $ReadOnlyArray<string> => [x.id, x.name],
    volume: (x: BbVolume): $ReadOnlyArray<string> => [x.id, x.name || '', x.description || ''].filter(Boolean),
};

type GlobalSearchIndexCacheStatus = [
    number,
    OrbitContainersDetailCollection,
    ?{ [id: string]: BbCollaboration, ... },
    $PropertyType<GlobalSearchState, 'fullResources'>,
    { [k: string]: GlobalSearchItem },
]

let cachedAccount: GlobalSearchIndexCacheStatus = [-1, new Map(), null, new Map(), {}];
const NOT_CACHED = {};

export function isFullyIndexed(state: CloudGuiState): boolean {
    const [currGen, currOrbit, currCollabs, currFullResources, ] = cachedAccount;

    return (
        state.GlobalSearch.generation === currGen
        && state.GlobalSearch.cacheStatus === RC_CACHED
        && state.Orbit.containers.details === currOrbit
        && state.GlobalSearch.fullResources === currFullResources
        && state.Resource.collaboration.collected === currCollabs
    );
}

function isGlobalSearchVolume(vol: BbVolume): boolean {
    return vol.storage_type === 'network';
}

export function buildGlobalSearchResources(resourceName: string, state: CloudGuiState): { [k: string]: GlobalSearchItem } {
    const [currGen, , , , res] = cachedAccount;

    if (isFullyIndexed(state)) return res;

    if (
        state.GlobalSearch.cacheStatus === RC_INITIAL
        || state.Orbit.fetched !== RC_CACHED
        || state.Resource.collaboration.fetched <= RC_FETCHING
    ) {
        return currGen !== -1 && state.GlobalSearch.generation > -1 ? res : NOT_CACHED;
    }

    // return a big old array of everything that's searchable
    let searchable: { [k: string]: GlobalSearchItem } = {};

    if (state.Orbit.fetched) {
        [...state.Orbit.containers.details.values()].forEach(({ id, ...rest }) => searchable['orb-' + id] = {
            id: 'orb-' + id,
            resource_type: 'orbit_container',
            values: [
                id,
            ],
        });
    }

    if (state.GlobalSearch.cacheStatus === RC_CACHED && state.GlobalSearch.account != null) {
        const { account } = state.GlobalSearch;

        account.clients.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.api_client(state.Resource.api_client.full?.[x.id] || x),
            };
        });
        account.cloud_ips.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.cloud_ip(state.Resource.cloud_ip.full?.[x.id] || x),
            };
        });
        account.database_servers.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.database_server(state.Resource.database_server.full?.[x.id] || x),
            };
        });
        account.database_snapshots.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.database_snapshot(state.Resource.database_snapshot.full?.[x.id] || x),
            };
        });
        account.images.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.image(state.Resource.image.full?.[x.id] || x),
            };
        });
        account.load_balancers.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.load_balancer(state.Resource.load_balancer.full?.[x.id] || x),
            };
        });
        account.server_groups.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.server_group(state.Resource.server_group.full?.[x.id] || x),
            };
        });
        account.servers.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.server(state.Resource.server.full?.[x.id] || x),
            };
        });
        account.users.forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.user(state.Resource.user.full?.[x.id] || x),
            };
        });
        account.volumes.filter(x => isGlobalSearchVolume(x)).forEach((x) => {
            searchable[x.id] = {
                id: x.id,
                resource_type: x.resource_type,
                values: indexers.volume(state.Resource.volume?.[x.id] || x),
            };
        });
    }

    Object.keys(state.Resource.collaboration.collected).forEach(id => {
        searchable[id] = {
            id,
            resource_type: state.Resource.collaboration.collected[id].resource_type,
            values: indexers.collaboration(state.Resource.collaboration.collected[id]),
        };
    });

    state.GlobalSearch.fullResources.forEach(({ id, kind }) => {
        if (kind in indexers && state.Resource[kind]?.full?.[id]) {
            // $FlowFixMe incompatible-type
            const values = indexers[kind](state.Resource[kind].full[id]);
            searchable[id] = {
                id,
                resource_type: kind,
                values
            };
        }
    });

    cachedAccount = [
        state.GlobalSearch.generation,
        state.Orbit.containers.details,
        state.Resource.collaboration.collected,
        state.GlobalSearch.fullResources,
        searchable,
    ];

    return searchable;
}
