// @flow

import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';
import copy from 'copy-to-clipboard';

import type { EditorModal } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { OrbitAccountMeta } from '../../../../api/type.orbit';
import type { TempUrlKeys } from '../../orbit/def';

type OrbitTempUrlKeysEditProps = {
    +editor: EditorModal<TempUrlKeys, FormErrors, OrbitAccountMeta>
};

export const AccountTempUrlKeysEdit = ({ editor }: OrbitTempUrlKeysEditProps): React$Node => {
    const { value, setValue, } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Orbit Temporary URL Keys'}
            />
            <PanelBar>
                <Notice type='info' icon='info-fill'>
                    <p>Setting account keys here at the Account-level enables
                    generating temporary URLs for all Orbit containers within your account.<br/>
                    You can also (or instead) create keys at the individual Orbit container level.</p>
                </Notice>
                <TextInput
                    label='Key 1'
                    value={value.tempUrlKey1}
                    focusSelectAll={true}
                    autoPopulated={value.gen1}
                    helperText={value.gen1 ? 'Auto-generated key – click "Save" to enable' : ''}
                    onChange={(tempUrlKey1) => setValue({...value, tempUrlKey1, gen1: false, })}
                    postText={value.tempUrlKey1 ? 'Copy' : null}
                    onPostInlayClick={() => copy(value.tempUrlKey1)}
                />
                <hr/>
                <TextInput
                    label='Key 2'
                    value={value.tempUrlKey2}
                    focusSelectAll={true}
                    autoPopulated={value.gen2}
                    helperText={value.gen2 ? 'Auto-generated key – click "Save" to enable' : ''}
                    onChange={(tempUrlKey2) => setValue({ ...value, tempUrlKey2, gen2: false, })}
                    postText={value.tempUrlKey2 ? 'Copy' : null}
                    onPostInlayClick={() => copy(value.tempUrlKey2)}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};