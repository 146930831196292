// @flow
import {useEffect, useReducer, useState} from 'react';
import type {StateUpdateFn} from "react-hooks";

//let debug = 0;

export const useCloseOnFocusLoss = (open: boolean, setOpen: (open: boolean) => void): StateUpdateFn<HTMLElement|null> => {
    const [current, setCurrent] = useState<HTMLElement|null>(null);

    useEffect(() => {
        //const run = debug++;
        //console.log('effect', run, open, current);
        if (document && open && current) {
            //console.log('binding', run);
            const listener = (e: MouseEvent) => {
                //console.log('captured', run, current, current.contains(e.target), e.target, e.target.isConnected);
                if (e.target instanceof Node && e.target.isConnected && !current.contains(e.target)) {
                    setTimeout(() => setOpen(false), 0);
                }
            };
            document.addEventListener('click', listener, { capture: true });
            return () => {
                //console.log('unbinding', run);
                document.removeEventListener('click', listener, { capture: true });
            }
        }
    }, [ current, open, setOpen ]);

    return setCurrent;
};

export function useRefreshComponent(interval: number) {
    // refresh this page when it's mounted, just so the
    // "X minutes ago" are right when no events are generated
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    useEffect(() => {
        const refresh = setInterval(forceUpdate, interval);
        return () => clearInterval(refresh);
    }, [interval]);
}

// <fieldname: string, error: string>
export type FormErrors = Map<string, string>;
export const emptyErrors: FormErrors = Object.freeze(new Map<string, string>());

const lookup = 'abcdefghijklmnopqrstuvwxyz0123456789/_';
function hashCode(s: string) {
    var hash = 0, i;
    if (s.length === 0) return hash;
    for (i = 5; i < s.length; i++) {
        hash += (lookup.indexOf(s[i]) << (6 * (i - 5)));
    }
    return hash;
}

const HUES = 22;
const HUEM = 360 / HUES;

export function getColorFromString(s: string): string {
    const num = hashCode(s);
    // console.log('hash', s, num);
    const hue = (num % HUES) * HUEM;
    //const sat = '64%, 69%';
    const sat = (num % 3) ? '60%, 62%' : '76%, 68%';
    return `hsl(${hue}, ${sat})`
}

export function getBackgroundFromString(s: string): { background: string } {
    return { background: getColorFromString(s) };
}