// @flow
import { ResourceChip } from './Chip';

import type { TargetsById } from "../hoc/AccessControl";

export const TargetDisplay = ({ target, accessResources }: { +target: ?string, +accessResources: ?TargetsById }): React$Node => {
    if (target === 'any') return <span className='c-firewall-rule__wildcard'>Any</span>;
    if (target === undefined) return null;
    const resource = accessResources?.get(target || '');
    if (resource) {
        return <ResourceChip resource={resource}/>;
    }
    return target;
};
