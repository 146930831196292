// @flow
import { formatResourceCost } from './Styled';
import { getMonthlyPrice } from '../../lib/pricing';
import { WINDOWS_LICENSE_ID } from '../../api/type.srv';

type MonthlyPriceProps = {
    price: ?number,
    plusWindows?: boolean,
};

export function MonthlyPrice({ price, plusWindows }: MonthlyPriceProps): React$Node {
    if (price != null) {
        return (
            <span>
                &pound;{formatResourceCost(price)} per month
                {plusWindows && getMonthlyPrice(WINDOWS_LICENSE_ID) > 0
                    ? <span className='ml-1'> (+&pound;{formatResourceCost(getMonthlyPrice(WINDOWS_LICENSE_ID))} Windows Licence)</span>
                    : null}
            </span>);
    } else {
        return <div>n/a</div>;
    }
}