// @flow

import gql from 'graphql-tag';
import { RANGE_QUERY, mapSeries, ticksFromRange } from '../../hoc/Metrics';
import numeral from 'numeral';

import type { CpuPercentMetric, ChartInputs, InterfaceMetrics } from './types';
import type { ResourceAreaChartConfig } from './ResourceAreaChart';

export const RANGES = [
    {
        label: 'Last Hour',
        value: 'HOURS_1',
    },
    {
        label: 'Last Day',
        value: 'HOURS_24',
    },
    {
        label: 'Last Week',
        value: 'HOURS_168',
    },
    {
        label: 'Last 2 Weeks',
        value: 'HOURS_336',
    },
];

export function cpuPercentConfig(resourceType: string): ResourceAreaChartConfig<CpuPercentMetric> {
    return  {
        title: 'CPU Utilisation',
        tooltip: 'Utilisation of total available CPU expressed as a percentage',
        yAxisFormatter: (v) => numeral(v).format('0.[00]') + '%',
        query: {
            query: gql`
                query ${resourceType}CpuPercentStats($resourceId: ID!, $range: MetricRangeType) {
                    metrics {
                        cpuPercent: ${resourceType}(id: $resourceId, range: $range) {
                            ${RANGE_QUERY}
                            cpu {
                                percent {
                                    timestamps
                                    values
                                }
                            }
                        }
                    }
                }
            `,
            adapter: (id: string, raw: CpuPercentMetric, rangeFilter: string): ChartInputs => {
                const [chartData, range] = mapSeries(
                    raw.metrics.cpuPercent.range,
                    ['s0', raw.metrics.cpuPercent.cpu.percent]
                );

                return {
                    data: [
                        [`CPU Utilisation (%)`],
                        chartData,
                    ],
                    range,
                    ticks: ticksFromRange(rangeFilter, range),
                };
            },
        }
    }
}

export function interfaceRatesConfig(resourceType: string, tooltipResName: string): ResourceAreaChartConfig<InterfaceMetrics> {
    return {
        title: "Network Data",
        tooltip: `Total aggregated network data transferred in and out of the ${tooltipResName} expressed in bytes per second`,
        colorOffset: 1,

        yAxisFormatter: (v) => numeral(v).format('0.[00] b'),

        query: {
            query: gql`
                query ${resourceType}Interface($resourceId: ID!, $range: MetricRangeType) {
                    metrics {
                        res: ${resourceType}(id: $resourceId, range: $range) {
                            ${RANGE_QUERY}
                            interface {
                                receiveBytesRate {
                                    timestamps
                                    values
                                }
                                transmitBytesRate {
                                    timestamps
                                    values
                                }
                            }
                        }
                    }
                }
            `,
            adapter: (id: string, raw: InterfaceMetrics, rangeFilter: string): ChartInputs => {
                const [chartData, range] = mapSeries(
                    raw.metrics.res.range,
                    ['s0', raw.metrics.res.interface.receiveBytesRate],
                    ['s1', raw.metrics.res.interface.transmitBytesRate],
                );

                return {
                    data: [
                        [`Inbound`, `Outbound`],
                        chartData,
                    ],
                    range,
                    ticks: ticksFromRange(rangeFilter, range),
                };
            },
        }
    }
}
