// @flow
import { useMemo, useState } from 'react';
import { getPaging, PAGING_MIN_SIZE, } from './lib/paging';
import { useDispatch, useSelector } from 'react-redux';

import type { Dispatch } from 'redux';
import type { PagerBarProps } from './PagerBar';
import type { CloudGuiState } from '../../state/cloudgui';
import type { UiAction } from '../../state/Sort/type';
import type { Paging } from './lib/paging';

type PagerHook<T> = {
    items: Array<T>,
    pager: PagerBarProps,
};

export const usePager = <T>(name: string, items: ?$ReadOnlyArray<T>, unfilteredCount: ?number): PagerHook<T>  => {
    const [paging, setPaging] = useState<[number, number, number, Paging]>([0, 0, 0, { permitNext: false, permitPrev: false, currentPage: 0, totalPages: 0, pages: [] }]);

    let [offset, pageSize] = useSelector((state: CloudGuiState) => {
        return [
            state.Ui.offset.get(name) || 0,
            state.Ui.pageSize,
        ];
    });
    const dispatch = useDispatch<Dispatch<UiAction>>();
    const storeOffset = (offset: number) => {
        dispatch({ type: 'UI_SET_OFFSET', payload: { tableName: name, offset } });
    };
    const setPageSize = (pageSize: number) => {
        dispatch({ type: 'UI_SET_PAGE_SIZE', payload: { pageSize } });
    };

    if (offset % pageSize !== 0) {
        offset = pageSize * Math.floor(offset / pageSize);
        storeOffset(offset);
    } else if (items != null && offset >= items.length && offset > 0) {
        offset = Math.floor((items.length - 1) / pageSize) * pageSize;
        storeOffset(offset);
    }

    let currPaging: Paging;
    const [eo, ep, ol, ] = paging;
    if (items == null || (eo === offset && ep === pageSize && ol === items.length)) {
        currPaging = paging[3];
    } else {
        currPaging = getPaging(offset, pageSize, items.length);
        setPaging([
            offset, pageSize, items.length, currPaging,
        ]);
    }

    const { permitNext, permitPrev, pages: pageChoices, currentPage, totalPages } = currPaging;
    const subItems = useMemo(() => (items || []).slice(offset, offset + pageSize), [items, offset, pageSize]);
    const nextPage = permitNext ? () => storeOffset(offset + pageSize) : null;
    const prevPage = permitPrev ? () => storeOffset(offset - pageSize) : null;

    return {
        items: subItems,
        pager: ({
            showPagerBar: (unfilteredCount || 0 )> PAGING_MIN_SIZE,
            unfilteredCount: unfilteredCount || 0,
            setPage: (pageNo: number) => storeOffset(pageNo * pageSize),
            setPageSize,

            nextPage,
            prevPage,
            pageSize,

            current: {
                currentPage,
                itemCount: items?.length || 0,
                pageCount: totalPages,
                pageChoices,
            },
        }: PagerBarProps),
    };
};