// @flow strict

import { Link } from 'react-router-dom';
import { protocolLabel } from '../../../element/Styled';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr } from '../../../element/NoResourceMessages';
import { Panel, PanelHeader } from '../../../element/Panel';
import { Button } from '../../../element/Button';
import { Pill } from '../../../element/Pill';
import { Tooltip } from '../../../element/Tooltip';
import { TargetDisplay } from '../../../element/TargetDisplay';
import { RC_FETCHING } from '../../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { BbFirewallRule } from '../../../../api/type.fwp';
import type { ResourceCacheStatus } from '../../../../state/resource/type';
import type { TargetsById } from "../../../hoc/AccessControl";

export type FirewallRuleDirection = 'in' | 'out'

export type FirewallRuleListProps = {
    +groupName: string,
    +cacheStatus: ResourceCacheStatus,
    +direction: FirewallRuleDirection,
    +firewallRules: $ReadOnlyArray<BbFirewallRule>,
    +accessResources?: TargetsById,
    +onDeleteFirewallRule?: (id: string) => void,
};

export const FirewallPolicyRules = ({ groupName, cacheStatus, direction, accessResources, onDeleteFirewallRule, ...props }: FirewallRuleListProps): React$Node => {
    const inOut = (direction === 'in' ? 'Inbound' : 'Outbound');
    const filteredRules = props.firewallRules.filter(r => (direction === 'in') ? r.source : r.destination);

    return (
        <Panel>
            <PanelHeader
                title={inOut + ' Firewall Rules'}
                actions={{
                    add_rule: 'firewall_rules/add/?direction=' + direction,
                }}
                description={'Rules to apply where Cloud Servers belonging to this group are the ' + (direction === 'in' ? 'destination' : 'source') + ' of the traffic'}
            />
            <Table className='c-resource-list'>
                <thead>
                <Tr>
                    <Th>Protocol</Th>
                    <Th>
                        <span>
                            Destination
                            <Tooltip
                                overlay={direction === 'in'
                                    ? <span>For inbound traffic the destination is always the Cloud Servers in this Group</span>
                                    : <span>The destination of traffic originating <b>from</b> Cloud Servers in this Server Group i.e. where is the traffic going <b>to</b>?</span>
                                }>
                                <Pill>?</Pill>
                            </Tooltip>
                        </span>
                    </Th>
                    <Th>
                        <span>
                            Source
                            <Tooltip
                                overlay={direction === 'in'
                                    ? <span>The source of inbound traffic to Cloud Servers in this Group i.e. where is the traffic coming <b>from</b>?</span>
                                    :  <span>For outgoing traffic the source is always the Cloud Servers in this Server Group</span>
                                }>
                                <Pill>?</Pill>
                            </Tooltip>
                        </span>
                    </Th>
                    <Th>Description</Th>
                    <Th actions={true}>&nbsp;</Th>
                </Tr>
                </thead>
                <tbody>
                {filteredRules.map((firewallRule: BbFirewallRule, idx: number) =>
                    <Tr key={idx}>
                        <Td>{
                            firewallRule.protocol && !isNaN(firewallRule.protocol) ? firewallRule.protocol : null}
                            {firewallRule.protocol || 'any' ? protocolLabel[firewallRule.protocol || 'any'] : null}
                        </Td>
                        <Td>
                            <div className='c-firewall-rule'>
                                {direction === 'out'
                                    ? <TargetDisplay target={firewallRule.destination} accessResources={accessResources} />
                                    : <span className='c-firewall-rule__wildcard'>&lt;This Group&gt;</span>
                                }

                                {direction === 'out' && firewallRule.protocol === 'icmp' && firewallRule.icmp_type_name
                                    ? <span className='c-firewall-rule__port'>{firewallRule.icmp_type_name}</span>
                                    : null
                                }

                                {firewallRule.protocol !== 'icmp' && firewallRule.destination_port
                                    ? <span className='c-firewall-rule__port'>:{firewallRule.destination_port}</span>
                                    : null
                                }
                            </div>
                        </Td>
                        <Td>
                            <div className='c-firewall-rule'>
                                {direction === 'in'
                                    ? <TargetDisplay target={firewallRule.source} accessResources={accessResources} />
                                    : <span className='c-firewall-rule__wildcard'>&lt;This Group&gt;</span>
                                }

                                {direction === 'in' && firewallRule.protocol === 'icmp' && firewallRule.icmp_type_name
                                    ? <span className='c-firewall-rule__port'>{firewallRule.icmp_type_name}</span>
                                    : null
                                }

                                {firewallRule.protocol !== 'icmp' && firewallRule.source_port
                                    ? <span className='c-firewall-rule__port'>:{firewallRule.source_port}</span>
                                    : null
                                }
                            </div>
                        </Td>
                        <Td className='text-sm'>
                            {firewallRule.description || '-'}
                        </Td>
                        <Td actions={true}>
                            <Button kind='tertiary' size='sm' color='red' onClick={onDeleteFirewallRule ? () => onDeleteFirewallRule(firewallRule.id) : null}>Delete</Button>
                            <Link to={`firewall_rules/${firewallRule.id}/`}>
                                <Button kind='tertiary' size='sm'>
                                    Edit
                                </Button>
                            </Link>
                        </Td>
                    </Tr>
                )}
                {cacheStatus === RC_FETCHING
                    ? <SkeletonListLoadingEntries>
                        <Tr>
                            <Td><SkeletonBar size='sm' /></Td>
                            <Td>
                                {direction === 'in'
                                    ? <SkeletonChip/>
                                    : <SkeletonBar size='md'/>
                                }
                            </Td>
                            <Td><SkeletonBar size='sm' /></Td>
                            <Td>
                                {direction === 'out'
                                    ? <SkeletonChip/>
                                    : <SkeletonBar size='md'/>
                                }
                            </Td>
                            <Td><SkeletonBar size='sm' /></Td>
                        </Tr>
                    </SkeletonListLoadingEntries>
                    : null}
                <NoResourcesTr status={cacheStatus} colSpan={6} totalCount={filteredRules.length} context='list' title={inOut.toLowerCase() + ' rules'} />
                </tbody>
            </Table>
        </Panel>
    );
};

