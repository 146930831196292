// @flow
import { Dialog, useDialog } from '../../element/Dialog';
import { useRawCreateResource } from '../../hoc/CreateResource';
import { historyNavigateOnCreate } from '../../../lib/history';
import { PerMonthPrice } from '../../element/PerMonthPrice';

import type { DialogState } from '../../element/Dialog';
import type { BbCloudIp, BbCloudIpParams } from '../../../api/type.cip';
import type { MessageHook } from '../../hoc/Messages';
import type { BbAccount } from '../../../api/type.acc';

type CipCreateConfirmDialogProps = {
    dialog: DialogState<null>,
    apiResult: MessageHook<BbCloudIp>,
}

type CipCreateDialogHook = {
    dialog: DialogState<null>,
    apiResult: MessageHook<BbCloudIp>,
}

type CipCreateDialogParams = {
    navigate?: boolean,
    onCreate?: (account: BbAccount, resource: BbCloudIp) => void,
}

export function useCipCreateDialog({ navigate, onCreate }: CipCreateDialogParams): CipCreateDialogHook {
    const dialog = useDialog([
        {
            label: 'Create Cloud IP',
            kind: 'primary',
            onSelect: () => {
                create.createResource({
                    name: '',
                    port_translators: [],
                    reverse_dns: '',
                });
            }
        }
    ]);
    const create = useRawCreateResource<BbCloudIp, BbCloudIpParams>(
        'cip_create', 'cloud_ip',
        (account: BbAccount, resource: BbCloudIp) => {
            if (navigate) historyNavigateOnCreate(account, resource.id, resource.resource_type);
            if (onCreate) onCreate(account, resource);
            // Dialog never sees the RC_SUCCESS message
            // because useRawCreateResource clears it out immediately.
            // rather than tweak useRawCreateResource for this one case,
            // just hide the dialog here.
            dialog.hide();
        }
    );

    return {
        dialog,
        apiResult: create.apiResult
    }
}

export const CipCreateConfirmDialog = ({ apiResult, dialog }: CipCreateConfirmDialogProps): React$Node => (
    <Dialog
        title="Create Cloud IP?"
        messages={apiResult}
        dialog={dialog}
        render={() => (
            <>
                <p>Please confirm that you want to allocate a new Cloud IP to your account.</p>
                <PerMonthPrice label='Cloud IPs' id='cloudip' inDialog={true} />
            </>
        )}
    />
);