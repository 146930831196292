// @flow
import { Pill } from './Pill';

import type { ValueSelectChoice, ValueSelectProps } from './ValueSelect';
import type { BbZone } from '../../api/type';

export type RadioGroupProps<V: string | number | boolean | BbZone> = {
    ...ValueSelectProps<V>,
    +options: $ReadOnlyArray<ValueSelectChoice<V>>,
    +size?: 'sm' | 'lg',
}

export function RadioGroup<V: string | number | boolean | BbZone>({ options, selected, onSelect, size }: RadioGroupProps<V>): React$Node {

    const select = (index: number) => {
        const idx = Number(index);
        if (idx >= 0 && idx < options.length) {
            onSelect(options[idx].value);
        }
    };

    return (
        <div className={'c-radio' + (size === 'lg' ? ' c-radio--lg' : '')}>
            {options.map((item, key) => {
                const isSelected = selected === item.value;
                return (
                    <label
                        key={key}
                        className={'c-radio__item' + (isSelected ? ' c-radio__item--selected' : '') + (item.description ? ' c-radio__item--has-description' : '')}
                        onClick={() => select(key)}
                    >
                        <div className='c-radio__item-indicator' />
                        <div className='c-radio__item-content'>
                            <div className='c-radio__item-name'>
                                {item.label}
                                {item.default
                                ? <Pill>Default</Pill>
                                : null
                            }
                            </div>
                            {item.description
                                ? <div className='c-radio__item-description'>{item.description}</div>
                                : null
                            }
                        </div>
                    </label>
                    );
                }
            )}
        </div>
    );
}
