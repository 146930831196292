// @flow

import { Button } from '../../element/Button';
import { ValueSelect } from '../../element/ValueSelect';
import { useAccountSignUp } from '../../hoc/SignUp';
import { useCountryListChoices } from '../../hoc/lib';
import { AddCardForm } from '../account/edit/AddCardForm';
import { useStripe } from '@stripe/react-stripe-js';
import { useUpdateBillingMethod } from '../../hoc/Billing';
import { RC_API_REQUEST, RC_ERROR } from '../../../state/resource/type';
import { Notice } from '../../element/Notice';
import { AddCardText } from '../../element/Styled';

import type { Match } from 'react-router';

export const AccountSignUp = ({ match }: { match: Match }): React$Node => {
    const stripe = useStripe();
    const { name, setName, handleSubmit, messages } = useUpdateBillingMethod(stripe);
    const { errors, account, setAccount, onCreateAccount, status, geocodeStatus, } = useAccountSignUp(match.params.id, handleSubmit);

    const submitError = errors.get('submit');
    const countryChoices = useCountryListChoices();
    const isNewSignUp = null == window.location.search.match(/additional/);

    return (
        <div className='c-auth'>
            <form className='c-auth__form' onSubmit={null}>
                {isNewSignUp
                    ? <h1  className='c-auth__heading'>Almost done!</h1>
                    : <h1  className='c-auth__heading'>Setup Billing</h1>
                }
                {submitError
                    ? <Notice type='error'>{submitError}</Notice>
                    : null
                }

                {isNewSignUp
                    ? <AddCardText />
                    : <p className='mb-6'>Please add card details for your new account so that we can
                    setup billing for future payments.</p>
                }

                <AddCardForm
                    name={name}
                    setName={setName}
                    messages={messages}
                />

                {geocodeStatus === RC_ERROR
                    ? <>
                        <ValueSelect
                            label='Country'
                            className='c-auth__input'
                            options={countryChoices}
                            selected={account.country_code}
                            onSelect={(country_code) => setAccount({ ...account, country_code })}
                            errorText={errors.get('account_country_code')}
                        />
                    </>
                    : null
                }

                <Button
                    kind='primary'
                    onClick={() => { onCreateAccount(); }}
                    state={status === RC_API_REQUEST ? 'loading' : ''}
                    className='c-auth__button'
                >
                    Activate My Account
                </Button>
            </form>
        </div>
    );
};
