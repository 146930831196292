// @flow strict
import { useEffect, useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { pathToRegexp } from 'path-to-regexp';
import { useCurrentAccountId } from '../component/hoc/lib';
import { matchPath } from 'react-router';

import type { BbResourceKind } from '../api/type';
import type { BbAccount } from '../api/type.acc';
import type { RouterHistory } from 'react-router-dom';

export const history: RouterHistory = (createBrowserHistory(): any);

let hadInAppNav = false;

history.listen(() => {
    hadInAppNav = true;
});

const OAuth: RegExp = pathToRegexp('/user/:section/:id?/');
const Support: RegExp = pathToRegexp('/accounts/:accountId/support/');
const NestedEditor: RegExp = pathToRegexp('/accounts/:accountId/:section(server_groups|orbit/container|container_registry/container)/:id/:sub(firewall_rules|access)/:addEdit');
const OrbitPath: RegExp = pathToRegexp('/accounts/:accountId/:section(orbit|container_registry)/container/:id/:subedit?/');
const ImageView: RegExp = pathToRegexp('/accounts/:accountId/images/:id');
const AccountSettings: RegExp = pathToRegexp('/accounts/:accountId/settings/:setting/:kind/:something?/');
const SectionResource: RegExp = pathToRegexp('/accounts/:accountId/:section/:id?/:subedit?/');

export const RecentlyViewedResource: RegExp = pathToRegexp('/accounts/:accountId/:section(servers|server_groups|images|cloud_ips|load_balancers|database_servers|database_snapshots)/:id([a-z]{3}-[a-z0-9]{5})/');
export const RecentlyViewedOrbit: RegExp = pathToRegexp('/accounts/:accountId/:section(orbit|container_registry)/container/:id/');
export const RecentlyViewedApiClient: RegExp = pathToRegexp('/accounts/:accountId/settings/cli/:id([a-z]{3}-[a-z0-9]{5})/');
export const RecentlyViewedUser: RegExp = pathToRegexp('/accounts/:accountId/team/:id([a-z]{3}-[a-z0-9]{5})/');


export const historyBack = () => {
    const support = Support.exec(history.location.pathname);
    if (support) {
        if (hadInAppNav) {
            history.goBack();
        } else {
            history.push(`/accounts/${support[1]}/`);
        }
        return;
    }

    const settings = AccountSettings.exec(history.location.pathname);
    if (settings) {
        if (settings[4]) {
            history.push(`/accounts/${settings[1]}/settings/${settings[2]}/${settings[3]}/`);
        } else {
            history.push(`/accounts/${settings[1]}/settings/#${settings[2]}`);
        }
        return;
    }

    const firewallRule = NestedEditor.exec(history.location.pathname);
    if (firewallRule) {
        history.push('/accounts/' + firewallRule.slice(1).filter(x => !!x).slice(0, -2).join('/') + '/');
        return;
    }

    const orbit = OrbitPath.exec(history.location.pathname);
    if (orbit) {
        if (orbit[4] === 'objects') {
            history.push(`/accounts/${orbit[1]}/${orbit[2]}/`);
        } else if (orbit[4]) {
            history.push(`/accounts/${orbit[1]}/${orbit[2]}/container/${orbit[3]}/`);
        } else {
            history.push(`/accounts/${orbit[1]}/${orbit[2]}/`);
        }
        return;
    }

    const image =  ImageView.exec(history.location.pathname);
    if (image) {
        const anchor = history.location.state?.tab || 'all'
        history.push(`/accounts/${image[1]}/images/#${anchor}`);
        return;
    }

    const section = SectionResource.exec(history.location.pathname);

    if (section) {
        history.push('/accounts/' + section.slice(1).filter(x => !!x).slice(0, -1).join('/') + '/');
        return;
    }

    const profile = OAuth.exec(history.location.pathname);
    if (profile) {
        // eg, "application/app-12345"
        if (profile[2]) {
            history.push(`/user/#${profile[1]}`);
        } else {
            history.push('/user/');
        }
        return;
    }

    if (hadInAppNav) {
        history.goBack();
    } else {
        history.push('../');
    }
}

type HistoryNavType = BbResourceKind | 'container';

export const historyNavigateOnCreate = (account: BbAccount, id: string, kind: HistoryNavType) => {
    // use `history.replace` so that browser nav doesn't push us
    // back onto the add page
    switch(kind) {
    case 'account':
    case 'database_snapshot':
    case 'database_type':
    case 'event':
    case 'firewall_rule':
    case 'image':
    case 'interface':
    case 'server_type':
    case 'user':
    case 'zone':
        // we won't be creating these in a way that needs us to navigate the UI
        break;

    case 'application':
        history.replace(`/user/application/${id}`);
        break;

    case 'collaboration':
        history.replace(`/accounts/${account.id}/team/`);
        break;

    case 'api_client':
        history.replace(`/accounts/${account.id}/settings/cli/${id}`);
        break;

    // with the change to a dialog, this isn't replacing a '/add' route,
    // so push rather than replace.
    case 'cloud_ip':
        history.push(`/accounts/${account.id}/${kind}s/${id}`);
        break;

    case 'database_server':
    case 'firewall_policy':
    case 'load_balancer':
    case 'server':
    case 'server_group':
    case 'test':
    case 'test_unmapped':
    case 'volume':
        history.replace(`/accounts/${account.id}/${kind}s/${id}`);
        break;

    case 'container':
        if (id.endsWith('_ctrimages')) {
            history.replace(`/accounts/${account.id}/container_registry/container/${id}/`);
        } else {
            history.replace(`/accounts/${account.id}/orbit/container/${id}/`);
        }
        break;

    default:
        void (kind: empty);
    }
}


export const historyNavigateOnDelete = (accountId: string, kind: HistoryNavType) => {
    switch(kind) {
    case 'account':
    case 'database_type':
    case 'event':
    case 'firewall_rule':
    case 'image':
    case 'interface':
    case 'server_type':
    case 'user':
    case 'zone':
        // we won't be creating these in a way that needs us to navigate the UI
        break;

    case 'application':
        history.push('/user/#application');
        break;
    case 'collaboration':
        // this is handled in useDeleteCurrUserCollabRedirect directly.
        break;

    case 'api_client':
        history.push(`/accounts/${accountId}/settings/#cli`);
        break;

    case 'cloud_ip':
    case 'database_server':
    case 'database_snapshot':
    case 'firewall_policy':
    case 'load_balancer':
    case 'server':
    case 'server_group':
    case 'test':
    case 'test_unmapped':
    case 'volume':
        history.push(`/accounts/${accountId}/${kind}s/`);
        break;

    case 'container':
        history.replace(`/accounts/${accountId}/orbit/`);
        break;

    default:
        void (kind: empty);
    }
}

export const ORBIT_OBJECTS_PATH = '/accounts/:accountId/orbit/container/:container/:which/:name*';

export function historyNavigateOnDeleteOrbitObject() {
    const match = matchPath(window.location.pathname, {
        path: ORBIT_OBJECTS_PATH
    });

    if (match && match.params.accountId && match.params.container && match.params.which) {
        let baseUrl = `/accounts/${match.params.accountId}/orbit/container/${match.params.container}/${match.params.which}/?`;
        const params = new URLSearchParams(window.location.search);
        const path = params.get('path') || '';
        const marker: string = params.get('marker') || '';

        if (path) baseUrl += 'path=' + encodeURIComponent(path) + '&';
        if (marker) baseUrl += 'marker=' + encodeURIComponent(marker) + '&';

        history.push(baseUrl);
    }
}

type EscHandler = () => any;

export const ESCAPE: number = 27;
let escHandler: Array<EscHandler> = [];

export const pushEscHandler = (h: EscHandler) => {
    escHandler.push(h);
}

// there's no strict guarantee that these all get pushed
// and popped in the same order - so just 'remove' from the
// stack.
export const removeEscHandler = (h: EscHandler) => {
    const idx = escHandler.findIndex(item => item === h);
    if (idx !== -1) {
        escHandler = [].concat(escHandler.slice(0, idx), escHandler.slice(idx + 1))
    }
}

export const useEscapeBackNav = (): void => {
    const { body } = document;
    useEffect(() => {
        if (body == null) {
            return;
        }

        const listener = (event: KeyboardEvent) => {
            if (event.keyCode === ESCAPE) {
                if (escHandler.length) {
                    escHandler[escHandler.length - 1]();
                }
            }
        }
        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [body]);
}

type RouteKind = BbResourceKind | 'container' | 'card' | 'limit' | 'support' | 'dashboard' | 'container_registry' | 'team';
export type RouteFn = (kind: RouteKind, id?: string, parentId?: ?string) => string;

export const useResourceRoutes = (): RouteFn => {
    const currAccountId = useCurrentAccountId() || '';

    return useMemo(() => (kind: RouteKind, id?: string, parentId?: ?string) => {
        const idExtra = id ? `${id}/` : '';

        switch(kind) {
        case 'container':
            if (idExtra === '') {
                return `/accounts/${currAccountId}/orbit/`;
            }
            return `/accounts/${currAccountId}/orbit/container/${idExtra}`;
        case 'container_registry':
            if (idExtra === '') {
                return `/accounts/${currAccountId}/container_registry/`;
            }
            return `/accounts/${currAccountId}/container_registry/container/${idExtra}`;
        case 'firewall_rule':
            if (parentId && id) {
                return `/accounts/${currAccountId}/server_groups/${parentId}/firewall_rules/${id}/`;
            }
            // fallback to no link - since there's no obvious page.
            return '';
        case 'team':
            return `/accounts/${currAccountId}/team/`;
        case 'card':
            return `/accounts/${currAccountId}/billing/card/`;
        case 'limit':
            if (id) return `/accounts/${currAccountId}/settings/increase/${id}/`;
            return '';
        case 'user':
            return `/user/${idExtra}`;
        case 'support':
            return `/accounts/${currAccountId}/support/`;
        case 'collaboration':
            return `/accounts/${currAccountId}/team/${idExtra}`;
        case 'dashboard':
            if (id) return `/accounts/${id}/`;
            return '';
        case 'account':
            return `/accounts/${currAccountId}/${idExtra}`;
        case 'api_client':
            if (id) return `/accounts/${currAccountId}/settings/cli/${idExtra}`;
            return `/accounts/${currAccountId}/settings/#cli`;
        default:
            return `/accounts/${currAccountId}/${kind}s/${idExtra}`;
        }
    }, [currAccountId]);
}