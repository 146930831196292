// @flow
import { useContext } from 'react';
import { ContainerRegistryList } from './ContainerRegistryList';
import { ContainerRegistryView } from './ContainerRegistryView';
import { ContainerRegistryCreate } from './ContainerRegistryCreate';
import { animationRouteContext } from '../../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route } from 'react-router-dom';
import { AnimNotFound } from '../home/NotFound';

import type { Match } from 'react-router-dom';

export const ContainerRegistrySection = ({ match: accountMatch }: { match: Match }): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route exact path={`${accountMatch.path}`} component={ContainerRegistryList}/>
                <Route exact path={`${accountMatch.path}add/`} component={ContainerRegistryCreate}/>
                <Route path={`${accountMatch.path}container/:id/`} render={({ match: idMatch, }) => idMatch.params.id
                    ? <ContainerRegistryView id={idMatch.params.id} match={idMatch}/>
                    : <div>Error in react-router?</div>
                }/>
                <Route><AnimNotFound/></Route>
            </Switch>
        </AnimatePresence>
    );
}