// @flow
import { useState, } from 'react';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { useCreateResource } from '../../hoc/CreateResource';
import { RadioGroup } from '../../element/RadioGroup';
import { permissionsChoices } from './ApiClientView';
import { TextInput } from '../../element/Input';
import { Textarea } from '../../element/Textarea';
import { historyBack } from '../../../lib/history';
import { Notice } from '../../element/Notice';
import { LABELS } from '../../element/ResourceLabels';

import type { Match } from 'react-router';
import type { BbApiClient, BbApiClientParams, BbApiPermissionsGroup } from '../../../api/type.cli';


export const ApiClientCreate = ({ match }: { match: Match }): React$Node => {
    const { messages, createResource, status } = useCreateResource<BbApiClient, BbApiClientParams>('api_client_create', 'api_client');

    const [createParams, setCreateParams] = useState<BbApiClientParams>({
        name: '',
        description: '',
        permissions_group: 'full',
    });
    const [errors, setErrors] = useState<Array<string>>([]);

    const validateAndCreate = () => {
        const errors = [];

        if (createParams.name === '') {
            errors.push('Please enter a valid name');
        }

        setErrors(errors);

        if (errors.length === 0) {
            createResource(createParams);
        }
    };

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.api_client.listTitle}
            match={match}
            dialog={null}
            view={
                <Panel>
                    <PanelHeader title='Create API Client'/>
                    <PanelBar>
                        <TextInput
                            label='Name'
                            value={createParams.name}
                            onChange={(name: string) => setCreateParams({ ...createParams, name })}
                        />
                        {errors.length || messages.length
                            ? <div className='alert alert-danger mt-2'>
                                {errors ? errors.map(e => <Notice type='error' key={e}>{e}</Notice>) : null}
                                {messages ? messages.map(e => <Notice type='error' key={e}>{e}</Notice>) : null}
                            </div>
                            : null}
                
                        <Textarea
                            label='Description'
                            optional={true}
                            value={createParams.description}
                            onChange={(description: string) => setCreateParams({ ...createParams, description })}
                        />

                        <h3>Permissions</h3>
                   
                        <RadioGroup
                            selected={createParams.permissions_group}
                            options={permissionsChoices}
                            onSelect={(permissions_group: BbApiPermissionsGroup) => setCreateParams({ ...createParams, permissions_group })}
                        />

                    </PanelBar>
                    <PanelButtonBar
                        cacheStatus={status}
                        primaryButton={{children: 'Create API Client', onClick: validateAndCreate}}
                        cancel={historyBack}
                    />
                </Panel>
            }
        />
    );
};
