// @flow

import { useState } from 'react';
import { TextInput } from '../../../element/Input';
import { CardElement } from '@stripe/react-stripe-js';
import { cn } from '../../../element/lib/classNames';
import { Notice } from '../../../element/Notice';

import type { StateUpdateFn } from 'react-hooks';
import type { MessageHook } from '../../../hoc/Messages';
import type { BbAccount } from '../../../../api/type.acc';

type AddCardFormProps = {
    name: string,
    setName: StateUpdateFn<string>,
    messages: MessageHook<BbAccount>
}

const useStripeElementFocus = () => {
    const [states, setStates] = useState({ focus: false, completed: false });

    const onBlur = () => {
        setStates((p) => ({ ...p, focus: false, }));
    };
    const onFocus = () => {
        setStates((p) => ({ ...p, focus: true, }));
    };
    const onChange = (event: { elementType: string, empty: boolean, ... }) => {
        if (event.elementType === 'card') {
            setStates((p => ({ ...p, completed: !event.empty })));
        }
    };

    const classNames = {
        'c-stripe-card--has-focus': states.focus,
        'c-stripe-card--completed': states.completed,
    };

    return {
        classNames,
        onBlur,
        onFocus,
        onChange,
    };

};

export const AddCardForm = ({ name, setName, messages }: AddCardFormProps): React$Node => {
    const { classNames, ...events } = useStripeElementFocus();

    return (
        <>
            {messages.messages.length
                ? <Notice type='error' className='mb-4'>{messages.messages[0]}</Notice>
                : null
            }
            <TextInput
                label='Name on Card'
                value={name}
                onChange={(next: string) => setName(next)}
                autoComplete='cc-name'
                className='w-full c-auth__input'
            />

            <CardElement
                className={cn({
                    'c-stripe-card': true,
                    ...classNames,
                })}
                {...events}
                options={{
                    style: {
                        base: {
                            color: '#3D4357',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            fontFamily: 'Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                            '::placeholder': {
                                color: '#7C8494',
                            }
                        }
                    }
                }}
            />

            <Notice icon='info-fill'>To validate your card we'll try an
            authorisation only for £1 – no payment will be taken</Notice>

            {process.env.NODE_ENV === 'development'
                ? <Notice className='bg-gray-100 mb-4 border border-dashed border-gray-400'>
                    <h4>Dev only</h4>
                    Simple success: 4242424242424242<br/>
                    Need 3DS: 4000000000003220<br/>
                    Generic decline: 4000000000000002<br/>
                    Insufficient funds: 4000000000009995<br/>
                    Expired card: 4000000000000069<br/>
                    More <a href='https://stripe.com/docs/testing' target='_blank' rel="noopener noreferrer">here</a>
                </Notice> : null}
        </>
    )
}
