// @flow

import axios from 'axios';
import to from 'await-to-js';
import type { Gigabytes } from '../api/type.units';

const PRICING_URL = window?.cgrConfig?.BRIGHTBOX_PRICING_JSON_URL || 'https://www.brightbox.com/pricing.json';

export type Price = {
    id?: string,
    server_type?: string,
    type?: string,
    for?: string,

    hourly?: number,
    monthly?: number,
    per_gig?: number,
}

type PricingMap = {
    [id: string]: Price,
}

let prices: ?PricingMap = null;

async function fetch_pricing() {
    let [err,response] = await to(axios({
        url: PRICING_URL,
    }));

    if (!err && response.status === 200) {
        prices = response.data.reduce((acc, value) => {
            acc[value.id || value.service] = value;
            return acc;
        }, {});
    }
}

fetch_pricing();

export function getMonthlyPrice (id: string): ?number {
    return prices?.[id]?.monthly || null;
}

export function getHourlyPrice(id: string): ?number {
    return prices?.[id]?.hourly || null;
}

export function getPerGigPrice(id: string, size?: ?Gigabytes): ?number {
    const base: ?number = prices?.[id]?.per_gig || null;

    if (base != null) {
        if (size != null) return size * base;

        return base;
    }

    return null;
}

export function getPrice(id: string): ?Price {
    return prices?.[id];
}