// @flow

import { createElement, useContext } from 'react';
import { animationRouteContext } from '../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route } from 'react-router-dom';

import type { Match } from 'react-router';

type StandardResourceSectionProps = {
    match: Match,
    list: React$ComponentType<any>,
    create?: React$ComponentType<any>,
    view: React$ComponentType<{ id: string, match: Match }>,
}

export const StandardResourceSection = ({ match: accountMatch, list, create, view }: StandardResourceSectionProps): React$Node => {
    const [ section, custom, ] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route exact path={`${accountMatch.path}`} component={list}/>
                {create ? <Route path={`${accountMatch.path}add/`} component={create}/> : null}
                <Route path={`${accountMatch.path}:id([a-z]{3}-.{5})/`} render={({ match: idMatch, }) => idMatch.params.id
                    ? createElement(view, { id: idMatch.params.id, match: idMatch })
                    : <div>Error in react-router?</div>
                }/>
            </Switch>
        </AnimatePresence>
    );
};
