// @flow

import { formatResourceCost } from './Styled';
import { getPerGigPrice } from '../../lib/pricing';
import { Tooltip } from './Tooltip';
import { Button } from './Button';
import { Pill } from './Pill';
import { Notice } from './Notice';

type PerGigPriceProps = {
    +label: string,
    +id: string,
};

export const PerGigPrice = ({ label, id }: PerGigPriceProps): React$Node => (
    <Notice icon='label' size='sm'>
        {label} storage costs £{formatResourceCost(getPerGigPrice(id) || 0)} <span className='text-gray-600 text-xxs tracking-wider'>per GB/mo</span>
        <Tooltip
            overlay={<div>
                <p><a href='https://www.brightbox.com/pricing/' target='_blank' rel='noopener noreferrer'><Button size='xs' color='white' kind='tertiary' postIcon='new-window'>View Full Pricing Information</Button></a></p>
            </div>}>
            <Pill>?</Pill>
        </Tooltip>
    </Notice>
)