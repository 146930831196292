// @flow

import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { EventStream } from './EventStream';
import { Page } from '../../element/Page';
import { Panel, PanelBar, PanelHeader } from '../../element/Panel';
import { VERSION } from '../../../lib/version';
import { AccountResourceUsage } from './AccountResourceUsage';
import { BillingActivityDisplay } from '../account/BillingActivityDisplay';
import { accountBillingContext, useAccountNextBillingDate } from '../../hoc/Billing';
import { useOnboarding, F_WELCOME } from '../../hoc/Onboarding';
import { OnboardCheckList } from '../onboarding/OnboardCheckList';
import { TrialCreditDisplay } from '../account/TrialCreditDisplay';
import { isNetworkRequestInFlight, isNetworkRequestError } from '../../hoc/graphql';
import { SkeletonBar } from '../../element/Skeleton';
import { useCurrentAccount } from '../../hoc/lib';
import { Button } from '../../element/Button';
import { Tooltip } from '../../element/Tooltip';

import type { CloudGuiState } from '../../../state/cloudgui';
import type { AuthState } from '../../../state/auth/type';
import type { BbAccount } from '../../../api/type.acc';

export const Home = (props: {}): React$Node => {
    const { processing, currAccountId }  = useSelector<CloudGuiState, AuthState>((state: CloudGuiState) => state.Auth);
    const [, billingResult] = useContext(accountBillingContext);
    const networkStatus = billingResult?.networkStatus || 0;
    const data = billingResult?.data;
    const onboarding = useOnboarding(data);
    const { showWelcome, showChecklist, showTrial, onHideFlag, } = onboarding;
    const account: ?BbAccount = useCurrentAccount();
    const billingDataLoading = isNetworkRequestInFlight(networkStatus) || account == null;
    const billingDataError = isNetworkRequestError(networkStatus);
    const nextBillingDateDesc = useAccountNextBillingDate(data, networkStatus);

    const hasUsedResources = account
        ? account.servers_used > 0
            || account.cloud_ips_used > 0
            || account.load_balancers_used > 0
            || account.dbs_instances_used > 0
        : null;

    return (
        <Page>
            <div className='flex flex-row flex-wrap space-x-16'>
                <div className='flex-col flex-grow flex-shrink'>
                    {showWelcome
                        ? <div className='c-welcome'>
                            <h2 className='c-welcome__heading'><span aria-label='hand waving' role='img'>👋 </span>&nbsp;Welcome to your new Brightbox account</h2>
                            <h2 className='c-welcome__subheading'>Thanks for signing up – we're glad to have you on board!</h2>
                            <Tooltip overlay='Hide'>
                            <Button
                                size='sm'
                                kind='tertiary'
                                color='grey'
                                onClick={() => onHideFlag(F_WELCOME)}
                                preIcon='cross'
                                className='c-welcome__dismiss'
                            />
                            </Tooltip>
                        </div>
                        : null
                    }
                    <h2>Latest Activity</h2>
                    {!processing && currAccountId
                        ? <EventStream/>
                    : null
                    }
                </div>  
                <div className='space-y-8 w-72'>
                    {showChecklist
                        ? <Panel className='c-panel--compact'>
                            <PanelHeader
                                title='Getting Started'
                            />
                            <PanelBar>
                                <OnboardCheckList onboarding={onboarding} />
                            </PanelBar>
                        </Panel>
                        : null
                    }
                    {billingDataLoading
                        ? <Panel className='c-panel--compact'>
                            <PanelHeader
                                title={<SkeletonBar size='sm'/>}
                            />
                            <PanelBar>
                                <SkeletonBar size='md'/><br/>
                                <SkeletonBar size='md'/>
                            </PanelBar>
                        </Panel>
                        : null
                    }

                    {!billingDataLoading && !billingDataError
                        ? <Panel className='c-panel--compact'>
                            <PanelHeader
                                title={showTrial ? 'Free Trial Usage' : 'Current Billing Period'}
                                description={nextBillingDateDesc}
                            />
                            <PanelBar padding={false}>
                                {showTrial
                                    ? <TrialCreditDisplay
                                        activity={data?.account?.billingActivity}
                                        trialDaysRemaining={onboarding.trialDaysRemaining}
                                    />
                                    : null
                                }

                                <BillingActivityDisplay
                                    showingTrial={showTrial}
                                    activity={data?.account?.billingActivity}
                                />
                            </PanelBar>
                        </Panel>
                        : null
                    }
                    {!billingDataLoading && billingDataError
                        ? <Panel className='c-panel--compact'>
                            <PanelHeader
                                title={showTrial ? 'Free Trial Usage' : 'Current Billing Period'}
                            />
                            <PanelBar padding={false}>
                                <div className='m-6 text-gray-600 text-sm text-center'>
                                    <i>There was a problem accessing the billing data, please check back later.</i>
                                </div>
                            </PanelBar>
                        </Panel>
                        : null
                    }
                    {hasUsedResources
                        ? <Panel className="c-panel--compact">
                            <PanelHeader title="Current Resource Usage"/>
                            <PanelBar>
                                {!processing && currAccountId ?

                                    <AccountResourceUsage/>
                                    : null}
                            </PanelBar>
                        </Panel>
                        : null}
                </div>

            </div>


        <p className='text-sm text-gray-500 text-right'>{VERSION}</p>

        </Page>
    );
};