// @flow
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
    RecentlyViewedResource,
    RecentlyViewedOrbit,
    RecentlyViewedApiClient,
    RecentlyViewedUser,
    useResourceRoutes,
    history,
} from '../../lib/history';
import { getResourceKind } from '../../api/lib';
import { useLocation } from 'react-router';
import { useCurrentAccountId } from './lib';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { createSelector } from 'reselect';
import { resourceSelector } from '../../state/Search/search';
import { RC_INITIAL } from '../../state/resource/type';
import { isFullyIndexed } from '../../state/Search/GlobalSearch';

import type { StateUpdateFn } from 'react-hooks';
import type { Location } from 'react-router';
import type { Dispatch } from 'redux';
import type { GlobalSearchFetchAction } from '../../state/GlobalSearch/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { BbFullAccount } from '../../api/type.acc';
import type { RouteFn } from '../../lib/history';
import type { ResourceFetched, ResourceFetchCollected } from '../../state/resource/type';
import type { OrbitFetchContainersAction } from '../../state/Orbit/type';
import type { DialogState } from '../element/Dialog';

type ResourceResult = {
    +type: 'resource',
    +id: string,
    +link: string,
}
type OrbitContainerResult = {
    +type: 'container',
    +id: string,
    +link: string,
}
type TextResult = {
    +type: 'text',
    +text: string,
    +link: string,
}
type BrightboxComResult = {
    +type: 'docs',
    +id: number,
    +title: string,
    +snippet: string,
    +body: string,
    +link: string,
    +headers: string,
    +tags: $ReadOnlyArray<string>,
};
type NaResult = {
    +type: 'na',
    +label?: string,
}

export type SearchResult = ResourceResult | TextResult | NaResult | OrbitContainerResult | BrightboxComResult;
export type SearchResultCategory = {
    +title: string,
    +addSearchHistory?: string,
    +items: $ReadOnlyArray<SearchResult>,
}

type GlobalSearchTextInputHook = {
    input: string,
    setInput: StateUpdateFn<string>,

    focus: boolean,
    onInputFocussed: StateUpdateFn<boolean>,
}

export function useGlobalSearchTextInput(): GlobalSearchTextInputHook {
    const [input, setInput] = useState<string>('');
    const [focus, setFocus] = useState<boolean>(false);

    return {
        input, setInput,
        focus, onInputFocussed: setFocus,
    };
}

type GlobalSearchKeyboardInput = {
    focusFirst: () => void,
}

export const DOWN_ARROW: number = 40;
const UP_ARROW = 38;
const SLASH = 191;
const KEY_K = 75;

const KEY_0 = 48
const KEY_Z = 90;
const KEY_SEMI = 186;
const KEY_GRAVE = 223;
const KEY_SPACE = 32;

export const TAB = 9;
export const ENTER = 13;
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

const EXTRA_FOCUS_KEYS = [
    KEY_SPACE,
    KEY_BACKSPACE,
    KEY_DELETE,
]

function shouldMoveFocus(): boolean {
    const { activeElement } = document;
    return (
        activeElement == null
        || (
            activeElement.tagName !== 'INPUT'
            && activeElement.tagName !== 'TEXTAREA'
            && activeElement.tagName !== 'SELECT '
        )
    );
}

function focusInput(): boolean {
    const el = document.querySelector('.c-search input');
    if (el != null && shouldMoveFocus()) {
        el.focus();
        return true;
    }

    return false;
}

function isMoveFocusToInputKeycode(keyCode: number): boolean {
    return (
        (keyCode >= KEY_0 && keyCode <= KEY_Z)
        || (keyCode >= KEY_SEMI && keyCode <= KEY_GRAVE)
        || (EXTRA_FOCUS_KEYS.indexOf(keyCode) !== -1)
    );
}

/*
 * registers the "/" shortcut, and performs the up/down arrow movement
 * within search results if present.
 */
export function useGlobalSearchKeyboardInput(showDialog: $PropertyType<DialogState<any>, 'show'>): GlobalSearchKeyboardInput {
    const { body } = document;
    useEffect(() => {
        if (!body) return;

        function keyDown(e: KeyboardEvent) {
            if (
                e.keyCode === SLASH
                || (e.metaKey && e.keyCode === KEY_K)
            ) {
                if (shouldMoveFocus()) {
                    showDialog();
                    // if the dialog is already showing, force the focus too.
                    focusInput();
                    e.preventDefault();
                }
            } else if (e.keyCode === DOWN_ARROW || e.keyCode === UP_ARROW) {
                //add all elements we want to include in our selection
                const focussableElements = '.c-search-result__item';
                if (document.activeElement) {
                    const focussable: Array<HTMLElement> = [...document.querySelectorAll(focussableElements)].sort((a, b) => a.tabIndex - b.tabIndex);
                    // (element) => {
                    //     //check for visibility while always include the current activeElement
                    //     return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
                    // });
                    const index = focussable.indexOf(document.activeElement);
                    if (index > -1) {
                        e.preventDefault();

                        const nextIdx = (e.keyCode === DOWN_ARROW)
                            ? ((index + 1) % focussable.length)
                            : (index === 0 ? focussable.length - 1 : index - 1);

                        const nextElement = focussable[nextIdx];
                        nextElement.focus();
                    }
                }
            } else if (
                isMoveFocusToInputKeycode(e.keyCode)
                && document.activeElement
                && document.activeElement.classList.contains('c-search-result__item')
            ) {
                focusInput();
            }
        }

        body.addEventListener('keydown', keyDown);

        return () => {
            body.removeEventListener('keydown', keyDown);
        };

    }, [body, showDialog]);

    return {
        focusFirst: () => {
            const el = document.querySelector('.c-search-result__item[tabindex="100"]');
            if (el) el.focus();
        },
    };
}

function idToSearchResult(id: string, getRoute: RouteFn): SearchResult {
    if (id.substr(0, 4) === 'orb-') {
        const isContainerRegistry = id.endsWith('_ctrimages');
        const name = id.substr(4);

        return {
            type: 'container',
            id: name,
            link: getRoute(isContainerRegistry ? 'container_registry' : 'container', name),
        };
    }

    return {
        type: 'resource',
        id,
        link: getRoute(getResourceKind(id), id),
    };
}

type BrightboxComSearchHook = {
    +search: (value: ?string) => void,
    +result: ?[string, ?SearchResultCategory],
}

const MAX_DOCS = 3;

function useRunBrightboxComSearch(): BrightboxComSearchHook {
    const [userSearchText, setUserSearchText] = useState<?string>(null);
    const [worker, setWorker] = useState<?Worker>(null);
    const [result, setResult] = useState<?[string, ?SearchResultCategory]>(null);

    const parseResult = useCallback((event: MessageEvent) => {
        if (!Array.isArray(event.data) || event.data.length !== 2) throw new Error('unexpected message from lunr result');

        const [searchedTerm, searchResult] = event.data;
        if (Array.isArray(searchResult) && typeof searchedTerm === 'string') {
            setResult([
                searchedTerm,
                searchResult.length > 0
                    ? {
                        title: 'Related Pages',
                        addSearchHistory: searchedTerm,
                        items: (searchResult.slice(0, MAX_DOCS): any),
                    }
                    : null
            ]);
        } else {
            setResult(null);
        }
    }, [setResult]);

    const search = useCallback((value: ?string) => {
        setUserSearchText(value);
    }, [setUserSearchText]);

    useEffect(() => {
        if (userSearchText !== '' && worker == null) {
            const searchWorker = new Worker('/site_search_worker.js');
            // trigger an index asap
            searchWorker.onmessage = parseResult;
            searchWorker.postMessage(userSearchText);
            setWorker(searchWorker);
        } else if (userSearchText !== '' && worker != null) {
            worker.postMessage(userSearchText);
        }
    }, [setWorker, worker, userSearchText, parseResult]);


    return {
        search,
        result,
    };
}

type ResourceSearchHook = {
    search: (value: ?string) => void,
    result: ?[string, SearchResultCategory],
    noMatches: boolean,
}

const { result: globalSearchResult, text: globalSearchedText } = getSearchSelectors({ resourceName: 'global', resourceSelector });

const getGlobalSearchResults = createSelector(
    [globalSearchResult,],
    (globalSearchResult) => globalSearchResult,
);
const getGlobalSearchedText = createSelector(
    [globalSearchedText,],
    (globalSearchedText) => globalSearchedText,
);

const MAX_SIMPLE_ITEMS = 5;

function useRunResourcesSearch(): ResourceSearchHook {
    const dispatch = useDispatch<Dispatch<GlobalSearchFetchAction | ResourceFetchCollected | OrbitFetchContainersAction>>();
    const [userSearchText, setUserSearchText] = useState<?string>(null);
    const getRoute = useResourceRoutes();
    const [result, setResult] = useState<?[string, SearchResultCategory]>(null);
    const [noMatches, setNoMatches] = useState<boolean>(false);

    const [matchedIds, matchedSearchText, fullAccount, isSearching, orbitFetched, accountId, indexCreated] = useSelector<
        CloudGuiState,
        [$ReadOnlyArray<string>, string, ?BbFullAccount, boolean, ResourceFetched, string, boolean]
    >(state => [
        (getGlobalSearchResults(state): $ReadOnlyArray<string>),
        getGlobalSearchedText(state),
        state.GlobalSearch.account,
        state.search.global.isSearching,
        state.Orbit.fetched,
        state.Auth.currAccountId,
        isFullyIndexed(state),
    ]);

    useEffect(() => {
        if (userSearchText != null) {
            dispatch({ type: 'GLOBAL_SEARCH_FETCH', });
            dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind: 'collaboration', } });

            dispatch(createSearchAction('global')(userSearchText));
        }
    }, [userSearchText, dispatch]);

    useEffect(() => {
        if (orbitFetched === RC_INITIAL && userSearchText != null) dispatch({ type: 'ORBIT_FETCH_CONTAINERS', });
    }, [orbitFetched, userSearchText, dispatch]);

    useEffect(() => {
        setResult(null);
    }, [accountId]);

    const hasSearchResult = matchedSearchText === userSearchText && matchedSearchText != null && !isSearching && fullAccount != null;
    useEffect(() => {
        if (indexCreated && hasSearchResult && matchedIds && matchedIds.length) {
            setResult([
                matchedSearchText,
                {
                    title: 'Results',
                    addSearchHistory: matchedSearchText,
                    items: matchedIds.slice(0, MAX_SIMPLE_ITEMS).map(id => idToSearchResult(id, getRoute)),
                }
            ]);
            setNoMatches(false);
        } else if (indexCreated && hasSearchResult && matchedIds && !matchedIds.length) {
            setResult([
                matchedSearchText,
                {
                    title: 'Results',
                    items: [{
                        type: 'na',
                        label: `No results for "${matchedSearchText}"`,
                    }],
                }]);
            setNoMatches(true);
        }

    }, [indexCreated, hasSearchResult, setResult, setNoMatches, matchedIds, getRoute, matchedSearchText]);

    return {
        search: setUserSearchText,
        result,
        noMatches,
    };
}


export type GlobalSearchHook = {
    +status: 'initial' | 'searching' | 'results';
    +startSearch: (value: string) => void,
    +resourceResult: ?SearchResultCategory,
    +websiteResult: ?SearchResultCategory,
    +searchedTerm: ?string,
    +clearSearch: () => void,
    +noResourcesMatch: boolean,
}

export function useRunGlobalSearch(): GlobalSearchHook {
    const { search: websiteSearch, result: websiteResult } = useRunBrightboxComSearch();
    const { search: resourceSearch, result: resourceResult, noMatches: noResourcesMatch } = useRunResourcesSearch();

   const [userSearchText, setUserSearchText] = useState<?string>(null);

    const startSearch = useCallback((value: string) => {
        setUserSearchText(value);
        websiteSearch(value);
        resourceSearch(value);
    }, [setUserSearchText, websiteSearch, resourceSearch]);

    const clearSearch = useCallback(() => {
        setUserSearchText(null);
        websiteSearch(null);
        resourceSearch(null);
    }, [websiteSearch, resourceSearch]);

    const status = useMemo(() => {
        if (userSearchText == null) return 'initial';

        if (
            websiteResult?.[0] === userSearchText
            && resourceResult?.[0] === userSearchText
        ) {
            return 'results';
        }

        return resourceResult == null && websiteResult == null
            ? 'initial'
            : 'searching'
        ;
    }, [userSearchText, websiteResult, resourceResult]);

    return {
        status,
        startSearch,
        resourceResult: resourceResult?.[1],
        websiteResult: websiteResult?.[1],
        searchedTerm: userSearchText,
        clearSearch,
        noResourcesMatch,
    };
}

type SearchHistoryHook = {
    values: $ReadOnlyArray<string>,
    add: (value: string) => void,
}

const MAX_RECENT = 5;

function useSearchHistory(keyPrefix: string, limit: number = MAX_RECENT): SearchHistoryHook {
    const accountId = useCurrentAccountId() || '';
    const localStorageKey = keyPrefix + accountId;
    const [values, setValues] = useState<$ReadOnlyArray<string>>([]);

    useEffect(() => {
        let next = [];
        if (localStorage && !localStorageKey.endsWith(':')) {
            const saved = localStorage.getItem(localStorageKey);
            if (typeof saved === 'string') {
                let decoded = JSON.parse(saved);
                if (Array.isArray(decoded)) next = decoded;
            }
        }
        setValues(next);
    }, [localStorageKey, setValues]);

    const add = useCallback((value: string) => {
        setValues((current) => {
            let next = current.filter(x => x !== value).slice(0, limit - 1);
            next.unshift(value);
            if (localStorage  && !localStorageKey.endsWith(':')) localStorage.setItem(localStorageKey, JSON.stringify(next));
            return next;
        });
    }, [localStorageKey, limit, setValues]);

    return {
        add,
        values,
    };
}

const KEY_RECENTLY_VIEWED = 'bb:recently-viewed:';
const KEY_PREVIOUS_SEARCHES = 'bb:previous-searches:';

function useRecentlyViewed(): SearchResultCategory {
    const { add, values: recentIds } = useSearchHistory(KEY_RECENTLY_VIEWED);

    const location: Location = useLocation();
    const getRoute = useResourceRoutes();

    const { pathname } = location;

    useEffect(() => {
        const match = RecentlyViewedResource.exec(pathname);
        if (match) {
            add(match[3]);
        } else {
            const orbitMatch = RecentlyViewedOrbit.exec(pathname);
            if (orbitMatch) {
                add('orb-' + orbitMatch[3]);
            } else {
                const apiMatch = RecentlyViewedApiClient.exec(pathname);
                if (apiMatch) {
                    add(apiMatch[2]);
                } else {
                    const userMatch = RecentlyViewedUser.exec(pathname);
                    if (userMatch) {
                        add(userMatch[2]);
                    }
                }
            }
        }
    }, [pathname, add]);

    const recent = useMemo(() => {
        if (recentIds.length === 0) {
            return [
                {
                    type: 'na',
                    label: 'No recently viewed resources',
                }
            ];
        }
        return recentIds.map(id => idToSearchResult(id, getRoute));
    }, [getRoute, recentIds]);

    return {
        title: 'Recently Viewed',
        items: recent
    };
}

type PreviousSearchesHook = {
    add: (value: string) => void,
    history: ?SearchResultCategory,
}

function usePreviousSearches(): PreviousSearchesHook {
    const { add, values } = useSearchHistory(KEY_PREVIOUS_SEARCHES);

    const history = useMemo(() => {
        if (values.length) {
            return {
                title: 'Previous Searches',
                items: values.map(text => ({
                    type: 'text',
                    text,
                    link: '?search=' + text
                })),
            };
        } else {
            return null;
        }
    }, [values]);

    return {
        add,
        history,
    };
}

type PreSearchResults = {
    recent: ?SearchResultCategory,
    history: ?SearchResultCategory,
    addPreviousSearch: (value: string) => void,
}

export function usePreSearchResult(): PreSearchResults {
    const recent = useRecentlyViewed();
    const { add: addPreviousSearch, history } = usePreviousSearches();

    return {
        recent,
        history,
        addPreviousSearch
    };
}

export function useGlobalSearchFromQueryParameter(setInput: $PropertyType<GlobalSearchTextInputHook, 'setInput'>, startSearch: $PropertyType<GlobalSearchHook, 'startSearch'>) {
    const location = useLocation();
    const { search } = location;

    useEffect(() => {
        const params = new URLSearchParams(search);
        const userSearch = params.get('search');
        if (userSearch != null && userSearch !== '') {
            setInput(userSearch);
            startSearch(userSearch);
            history.replace(history.location.pathname);
        }
    }, [search, startSearch, setInput]);

}

const TYPING_DELAY = 300;

export function useTimeout(start: ?Function, delay: number = TYPING_DELAY) {
    const [, setCurr] = useState<?TimeoutID>(null);

    useEffect(() => {
        setCurr((curr) => {
            if (curr != null) clearTimeout(curr);

            if (start != null) {
                return setTimeout(start, delay);
            }
        });
    }, [start, delay]);
}

export function useSearchTimeout(searchTerm: string, startSearch: $PropertyType<GlobalSearchHook, 'startSearch'>) {
    const start = useMemo(() => searchTerm !== '' ? () => startSearch(searchTerm) : null, [searchTerm, startSearch]);
    useTimeout(start);
}