// @flow

import type { BbNestedCloudIp } from './type.cip';
import type {
    BbImage,
    BbNestedImage,
    BbInterface,
    BbNestedServerGroup,
    BbResource,
    BbServerType,
    BbZone,
    BbCollectedImage,
    BbLockable
} from './type';
import type { BbNestedVolume } from './type.volume';

export const ZONE_NOT_AVAILABLE = 'n/a';
export const SERVER_NAME_EMPTY = 'No Name';
// license cost is version-independent, so always use this license.
export const WINDOWS_LICENSE_ID = 'lcn-8aua2'

// BbImage has licence_name, but it's not there in nested. so just use name.
export function isWindowsImage(image: BbImage | BbNestedImage | BbCollectedImage): boolean {
    return !!image.name.match(/Windows/);
}

export type BbServerStatus =
    'creating' |
    'active' |
    'inactive' |
    'deleting' |
    'deleted' |
    'failed' |
    'unavailable';

export type BbNestedServer = {
    ...BbResource<'server'>,
    ...BbLockable,
    name: string,
    status: BbServerStatus,
    hostname: string,
    fqdn: string,
    created_at: Date,
    deleted_at: ?Date,
    started_at: ?Date,
};

export type BbCollectedServer = {
    ...BbNestedServer,
    ...BbLockable,
    compatibility_mode: boolean,
    image: BbNestedImage,
    server_type: BbServerType,
    zone: ?BbZone,
    cloud_ips: Array<BbNestedCloudIp>,
    server_groups: Array<BbNestedServerGroup>,
    interfaces: Array<BbInterface>,
    volumes: $ReadOnlyArray<BbNestedVolume>
};

export type BbServer = {
    ...BbCollectedServer,
    user_data: ?string,
    compatibility_mode: boolean,
    console_url: ?string,
    console_token: ?string,
    console_token_expires: ?Date,
    disk_encrypted: boolean,
    snapshots: $ReadOnlyArray<BbImage>,
    snapshots_schedule: ?string,
    snapshots_schedule_next_at: ?Date,
    snapshots_retention: ?string,
};

export type BbServerParams = {
    +name?: string,
    +server_groups?: $ReadOnlyArray<string>,
    +user_data?: string,
    +snapshots_schedule?: string,
    +snapshots_retention?: string,
    +server_type?: ?string,
};

type BbVolumeParams = {
    +size: number,
    +image: string,
} | {
    +volume: string,
};

export type BbServerCreateParams = {
    ...$Shape<BbServerParams>,
    +disk_encrypted?: boolean,
    +image?: string,
    +server_type: string,
    +zone?: ?string,
    +volumes?: $ReadOnlyArray<BbVolumeParams>,
};

export type UserDataEdit = {
    +value: string,
    +isBase64Encoded: boolean,
};