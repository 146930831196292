// @flow
import numeral from 'numeral';
import gql from 'graphql-tag';
import { MetricsPanel } from './MetricsPanel';
import { RANGE_QUERY, mapSeries, ticksFromRange } from '../../hoc/Metrics';

import type { ResourceAreaChartConfig } from './ResourceAreaChart';
import type { VolByteMetrics, ChartInputs, VolIoMetrics } from './types';

type ServerMetricsProps = {
    id: string,
}

const BYTES: ResourceAreaChartConfig<VolByteMetrics> = {
    title: 'Disk IO',
    measurement: 'Bytes',
    tooltip: 'Total read and write throughput of this Volume, expressed in bytes per second',
    colorOffset: 0,

    yAxisFormatter: (v) => numeral(v).format('0.[00] b'),

    query: {
        query: gql`
            query volIoBytesStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    volume(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        readBytesRate {
                            timestamps
                            values
                        }
                        writeBytesRate {
                            timestamps
                            values
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: VolByteMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.volume.range,
                ['s0', raw.metrics.volume.readBytesRate],
                ['s1', raw.metrics.volume.writeBytesRate],
            );

            return {
                data: [
                    [`Read`, `Write`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
}
const IO: ResourceAreaChartConfig<VolIoMetrics> = {
    title: 'Disk IO',
    measurement: 'IOPS',
    tooltip: 'Total number of read and write operations performed on this Volume, expressed in IOPS (input/output operations per second)',
    colorOffset: 2,

    yAxisFormatter: (v) => numeral(v).format('0.[00]'),

    query: {
        query: gql`
            query volIoIopsStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    volume(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        readIoRate {
                            timestamps
                            values
                        }
                        writeIoRate {
                            timestamps
                            values
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: VolIoMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.volume.range,
                ['s0', raw.metrics.volume.readIoRate],
                ['s1', raw.metrics.volume.writeIoRate],
            );

            return {
                data: [
                    [`Read`, `Write`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
}

const CHARTS = [BYTES, IO];

export const VolMetrics = ({ id }: ServerMetricsProps): React$Node => {
    return (
        <MetricsPanel
            id={id}
            charts={CHARTS}
        />
    );
};