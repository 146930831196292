// @flow

import { orbitUrl } from '../../api/url';
import { getObjectId } from '../../api/type.orbit';

import type { OrbitTreeNode } from './type';
import type { OrbitObject, BbOrbitContainer } from '../../api/type.orbit';

const IMAGES_CONTAINER = 'images';

export function createEmptyNode(accountId: string, container: string, path: string): OrbitTreeNode {
    let absolute_url = [orbitUrl, accountId, container].join('/');
    const id = getObjectId(path.substr(1), 'application/directory');
    if (path !== '') {
        absolute_url += path;
    }

    const basename: string = path.split('/').pop();

    return ({
        // BbRawApiOrbitEntry properties
        // name: path,    // removed as it was getting confusing
        content_type: 'application/directory',
        hash: '',
        bytes: 0,
        // last_modified: // overridden in OrbitObject

        // OrbitObject properties
        id,
        basename,
        absolute_url,
        last_modified: null,
        is_resource: false,

        // OrbitTreeNode
        path,
        children: [],
    }: OrbitTreeNode);
}

export const ensurePath = (root: OrbitTreeNode, accountId: string, container: string, fullPath: $ReadOnlyArray<string>): $ReadOnlyArray<OrbitTreeNode> => {
    let currRoot = root;
    let nodePath = [currRoot];

    fullPath.forEach((path: string) => {
        let thisPathIndex = currRoot.children.findIndex(x => x.basename === path && x.content_type === 'application/directory');
        if (thisPathIndex === -1) {
            thisPathIndex += currRoot.children.push(createEmptyNode(accountId, container, currRoot.path + '/' + path));
        }

        currRoot = currRoot.children[thisPathIndex];
        nodePath.push(currRoot);
    });

    return nodePath;
};

export function getPath(root: OrbitTreeNode, desiredPath: string): ?OrbitTreeNode {
    if (desiredPath === '') return root;

    let currRoot: ?OrbitTreeNode = root;
    // desiredPath will have a terminating "/", so trim that out
    const fullPath: $ReadOnlyArray<string> = desiredPath.substr(0, desiredPath.length - 1).split('/');

    fullPath.forEach((path: string) => {
        if (currRoot == null) return;

        const thisPathIndex = currRoot.children.findIndex(x => x.basename === path && x.content_type === 'application/directory');
        if (currRoot && thisPathIndex !== -1) currRoot = currRoot.children[thisPathIndex];
        else currRoot = null;
    });
    return currRoot;
}

/**
 * @desc maps a flat list of objects into the containerRoot OrbitTree param
 */
function mapDirStructureNode(containerRoot: OrbitTreeNode, accountId: string, containerName: string, item: OrbitObject) {
    const path = item.name.split('/');

    let thisPathNode = ensurePath(containerRoot, accountId, containerName, path);

    thisPathNode[thisPathNode.length - 1].id = item.id;
    thisPathNode[thisPathNode.length - 1].bytes = item.bytes;
    thisPathNode[thisPathNode.length - 1].last_modified = item.last_modified;
    thisPathNode[thisPathNode.length - 1].content_type = item.content_type;
}

/**
 * @desc Aggregates the many dynamic large objects into a single image entry
 */
function mapImagesContainerNode(containerRoot: OrbitTreeNode, accountId: string, containerName: string, item: OrbitObject) {
    const path = item.name.split('/');
    let image: ?OrbitTreeNode = containerRoot.children.find(x => x.basename === path[0]);
    if (image == null) {
        image = createEmptyNode(accountId, containerName, '/' + path[0]);
        containerRoot.children.push(image);
    }

    if (path.length > 1) {
        // nothing to do; the bytes value could be incomplete
    } else {
        image.last_modified = item.last_modified;
        let prefix = path[0].substr(0, 4);
        if (prefix ===  'dbi-') image.content_type = 'application/x-brightbox-database-snapshot';
        else if (prefix ===  'img-') image.content_type = 'application/x-brightbox-server-image';
        else image.content_type = item.content_type;

        image.id = getObjectId(item.name, image.content_type);
        image.is_resource = true;
    }
}

export function mapOrbitToOrbitTree(accountId: string, containerName: string, details: ?BbOrbitContainer, objects: ?$ReadOnlyArray<OrbitObject>): OrbitTreeNode {
    let containerRoot = createEmptyNode(accountId, containerName, '/');

    if (details && objects) {
        containerRoot.path = '';
        containerRoot.bytes = details.bytes;

        const mapper = containerName === IMAGES_CONTAINER
            ? mapImagesContainerNode.bind(null, containerRoot, accountId, containerName)
            : mapDirStructureNode.bind(null, containerRoot, accountId, containerName);

        objects.forEach(mapper);

        // set the '/' path here because we use simple string concat for all the children,
        // which would end up double-/'ing the first child, and which then would get passed
        // along and along. But, it's nicer from the outside to have consistent slashing -
        // so just override after the parsing.
        containerRoot.path = '/';
    }

    return containerRoot;
}

