// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { PasswordInput, } from '../../../element/Input';
import { Notice } from '../../../element/Notice';

import type { BbUser } from '../../../../api/type';
import type { EditorModal } from '../../../common/Editor';

type UserPasswordEditorProps = {
    +editor: EditorModal<[string, string], ?string, BbUser>,
    +email_address: ?string,
}

export const UserPasswordEditor = ({ editor, email_address, }: UserPasswordEditorProps): React$Node => {
    const { value, setValue } = editor;

    return (
        <Panel>
            <PanelHeader
                title='Change Password'
                description='Choose a new password to use to sign in as your Brightbox user profile'
            />
            <PanelBar>
                {editor.errors
                    ? <Notice type='error'>{editor.errors}</Notice>
                    : null
                }
                <div className='max-w-md'>
                    <PasswordInput
                        label='New Password'
                        id='new_password'
                        autoComplete='new-password'
                        value={value?.[0] || ''}
                        onChange={(p0) => setValue([p0, value?.[1] || ''])}
                        helperText='At least 8 characters'
                    />
                </div>
                <div className='max-w-md'>
                    <PasswordInput
                        label='Confirm Password'
                        id='confirm_password'
                        autoComplete='new-password'
                        value={value?.[1] || ''}
                        onChange={(p1) => setValue([value?.[0] || '', p1])}
                    />
                </div>
                {email_address
                    ? <input type='hidden' id='email' name='email' value={email_address} />
                    : null
                }
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};