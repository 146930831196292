// @flow
import { createLogic } from 'redux-logic';
import { request, } from '../../api/rest';
import { to } from 'await-to-js';
import { adaptEvent, resourceMetas } from '../../api/adapter';
import { checkResourceAccess, nextFetchId, } from './lib';
import { getResourceKind } from '../../api/lib';
import { handleApiError } from '../../lib/ErrorHandling';
import { RC_CACHED, RC_ERROR, RC_FETCHING, RC_INITIAL, RC_NOT_FOUND, RC_SUCCESS, RC_REFRESHING, RC_API_REQUEST } from './type';
import { toast } from "react-toastify";

import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type {
    ResourceAction,
    ResourceCreateKindAction,
    ResourceDeleteAction,
    ResourceEventRawAction,
    ResourceFetchCollected, ResourceFetchFull,
    ResourcePatchAction,
    ResourceSimpleAction
} from './type';
import type { BbAllResourceTypes, BbCollectedResourceTypes, BbResourceKind, } from '../../api/type';
import type { AuthAction } from '../auth/type';
import type { CloudIpMapAction } from '../CloudIp/type';
import type { MessageAction, MessageStateAction } from '../Message/type';
import type { FetchQueueAction } from '../FetchQueue/type';
import type { ReduxLogic } from 'redux-logic';

/**
 * Ensures the collected representations of the given resource kind is downloaded
 * and cached in our state.
 */
export const ResourceFetchCollectedLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_FETCH_COLLECTED'],
    async process<C: BbCollectedResourceTypes>(deps: { getState: () => CloudGuiState, action: ResourceFetchCollected }, dispatch: Dispatch<ResourceAction<any, C> | AuthAction>, done: () => void) {
        const { kind } = deps.action.payload;
        if (resourceMetas[kind]) {
            const { url, adapt } = resourceMetas[kind];
            const state = deps.getState();
            const resources = state.Resource[kind];

            if (resources.fetched === RC_INITIAL || resources.fetched === RC_ERROR) {

                dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind, fetched: RC_FETCHING } });

                const [err, fetchResponse] = await to(request({
                    method: 'GET',
                    url,
                    ...(kind === 'account' ? { nested: false, accountId: false, } : {}),
                }));

                if (!err && fetchResponse.status >= 200 && fetchResponse.status < 300) {
                    dispatch({
                        type: 'RESOURCE_SET_COLLECTED',
                        payload: {
                            kind,
                            resources: fetchResponse.data
                                .filter(x => !state.Auth.currAccountId || checkResourceAccess(state.Auth.currAccountId, kind, x))
                                .map(adapt.collected)
                        }
                    });
                    dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind, fetched: RC_CACHED } });
                } else {
                    dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind, fetched: RC_ERROR } });
                }
            }
        }
        done();
    },
});

/**
 * Fetches the full representation for the specified resource and caches it.
 */
export const ResourceFetchFullLogic: ReduxLogic = createLogic({
    type: ['RESOURCE_FETCH_FULL'],
    async process({getState, action}: { getState: () => CloudGuiState, action: ResourceFetchFull }, dispatch: Dispatch<ResourceAction<any, any> | AuthAction>, done: () => void) {
        const { kind } = action.payload;
        if (resourceMetas[kind]) {
            const { url, adapt } = resourceMetas[kind];
            const { id } = action.payload;
            const thisFetchId = nextFetchId();

            const cachedResource = getState().Resource[kind].full[id];

            if (cachedResource == null) {
                dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_FETCHING, fetchId: thisFetchId } });
            } else {
                dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_REFRESHING, fetchId: thisFetchId } });
            }

            const [err, fetchResponse] = await to(request({
                method: 'GET',
                nested: (kind === 'account' ? false : null),
                ...(kind === 'account' ? { accountId: false } : null),
                url: url + '/' + id,
                metadata: true,
            }));

            const { Auth, Resource } = getState();
            if (!err && fetchResponse.status >= 200 && fetchResponse.status < 300 && Resource[kind].fetchIds.get(id) === thisFetchId) {
                let allow = Auth.currAccountId ? checkResourceAccess(Auth.currAccountId, kind, fetchResponse.data) : true;
                if (allow) {
                    dispatch({
                        type: 'RESOURCE_ADD_FULL',
                        payload: {
                            kind,
                            full: adapt.full(fetchResponse.data),
                            collected: adapt.collected(fetchResponse.data),
                        }
                    });
                    dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_CACHED } });
                } else {
                    dispatch({
                        type: 'RESOURCE_SET_NOT_FOUND',
                        payload: {
                            kind,
                            id,
                        }
                    });
                    dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_NOT_FOUND } });
                }
            } else if (err && err.response?.status === 404) {
                dispatch({
                    type: 'RESOURCE_DELETE_RESOURCE',
                    payload: {
                        kind,
                        id,
                    }
                });
                dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_NOT_FOUND } });
            } else if (err) {
                handleApiError(err);
                dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_ERROR } });
            }

        }
        done();
    },
});

/**
 * Posts the params in the action to create a new resource, to appropriate API URL.
 *
 * Relies on an event coming in to update our ResourceState cache.
 *
 */
export const ResourceSimpleActionLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_SIMPLE_ACTION', 'RESOURCE_DELETE_ACTION', 'RESOURCE_CREATE_KIND'],
    async process(
        {getState, action}: {
            getState: () => CloudGuiState,
            action: ResourceSimpleAction<any> | ResourceDeleteAction | ResourceCreateKindAction
        },
        dispatch: Dispatch<ResourceAction<any, any> | AuthAction | CloudIpMapAction | MessageAction>,
        done: () => void
    ) {
        const { kind, } = action.payload;
        const meta = resourceMetas[kind];
        if (meta) {
            let urlParts = [meta.url];
            let method = (action.type === 'RESOURCE_DELETE_ACTION' ? 'DELETE' : 'POST');

            if (action.type !== 'RESOURCE_CREATE_KIND') {
                urlParts.push(action.payload.id);
                if (action.payload.action) {
                    urlParts.push(action.payload.action);
                }
            }

            if (action.type === 'RESOURCE_SIMPLE_ACTION') {
                if (action.payload.method != null) {
                    method = action.payload.method;
                }
                if (action.payload.nonPristine != null) {
                    dispatch({
                        type: 'RESOURCE_ADD_FULL',
                        payload: {
                            kind,
                            full: { ...action.payload.nonPristine, pristine: false },
                            collected: { ...action.payload.nonPristine, pristine: false },
                        }
                    });
                }
            }

            const url = urlParts.join('/');

            let data = {};
            if (action.payload.params) {
                data = { data: action.payload.params };
            }


            if (action.payload.messagesId) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: action.payload.messagesId,
                        status: RC_API_REQUEST,
                        resource: null,
                    }
                });
            }

            if (action.payload.id) {
                const { id } = action.payload;
                dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_API_REQUEST } });
            }

            const [err, fetchResponse] = await to(request({
                method,
                url,
                ...data,
            }));

            if (!err && fetchResponse.status >= 200 && fetchResponse.status < 300) {
                let full: any = null;

                if (fetchResponse.data && !action.payload.nonPristine) {
                    // eugh another cast through :any. Perhaps this will disappear when
                    // resourceMetas has all types and matches BbAllResourceKinds?
                    full = meta.adapt.full(fetchResponse.data);
                    let collected = meta.adapt.full(fetchResponse.data);
                    dispatch({
                        type: 'RESOURCE_ADD_FULL',
                        payload: {
                            kind,
                            full,
                            collected
                        },
                    });
                }

                if (fetchResponse.data) {
                    dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id: fetchResponse.data.id, status: RC_CACHED } });
                }

                if (action.payload.messagesId) {
                    dispatch({
                        type: 'MESSAGE_MESSAGE',
                        payload: {
                            id: action.payload.messagesId,
                            status: RC_SUCCESS,
                            resource: full,
                        }
                    });
                }

                // If we had IP(s) to assign, ask the cloud ip logic to map it
                // when possible
                if (action.type === 'RESOURCE_CREATE_KIND' && action.payload.cloudIp) {
                    const { cloudIp: cloudIpParams } = action.payload;

                    cloudIpParams.ids.forEach((cloudIpId: string) => {
                        dispatch({
                            type: 'CLOUD_IP_MAP',
                            payload: {
                                cloudIpId,
                                destination: fetchResponse.data.id,
                                messagesId: cloudIpId + '_' + fetchResponse.data.id,
                            }
                        });
                    });
                }

                // Flash message for successful request?
                if (action.type === 'RESOURCE_CREATE_KIND' && action.payload.kind === 'collaboration') {
                    toast('User invited', { type: 'success' });
                }
            }

            if (err) {
                if (action.payload.messagesId) {
                    dispatch({
                        type: 'MESSAGE_MESSAGE',
                        payload: {
                            id: action.payload.messagesId,
                            messages: Array.isArray(err.response.data.errors)
                                ? err.response.data.errors
                                : (typeof err.response.data.errors === 'string'
                                        ? [err.response.data.errors]
                                        : null
                                ),
                            ...(err.response.data.error_name ? { error_name: err.response.data.error_name } : null),
                            status: RC_ERROR,
                            resource: null,
                        }
                    });
                }
                if (action.payload.id) {
                    const { id } = action.payload;
                    dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_CACHED } });
                }
                if (!action.payload.suppressErrorToast) handleApiError(err);
            }
        }


        done();
    },
});

export type PatchDispatch = Dispatch<ResourceAction<any, any> | AuthAction | MessageStateAction>;

export const doPatchResource = async <F>(
    dispatch: PatchDispatch,
    id: string, kind: BbResourceKind, params: Object, nonPristine: ?F, messagesId: ?string
) => {
    const meta = resourceMetas[kind];

    if (meta) {
        const url = meta.url + '/' + id;

        if (messagesId) {
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messagesId,
                    status: RC_API_REQUEST,
                    messages: [],
                    resource: null,
                }
            });
        }

        dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_API_REQUEST } });
        if (nonPristine) {
            dispatch({ type: 'RESOURCE_ADD_FULL', payload: { kind, full: nonPristine, } });
        }

        const [err, fetchResponse] = await to(request({
            method: 'PUT',
            url,
            data: params,
        }));


        if (!err && fetchResponse.status >= 200 && fetchResponse.status < 300) {
            // eugh another cast through :any. Perhaps this will disappear when
            // resourceMetas has all types and matches BbAllResourceKinds?
            const full = (meta.adapt.full(fetchResponse.data): any);

            if (fetchResponse.data && !nonPristine) {
                dispatch({
                    type: 'RESOURCE_ADD_FULL',
                    payload: {
                        full,
                        kind,
                    },
                });
            }
            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_CACHED } });

            if (messagesId) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        status: RC_SUCCESS,
                        resource: full,
                    }
                });
            }

        }

        if (err) {
            if (
                messagesId
                && err.response
                && err.response.data
                && Array.isArray(err.response.data.errors)
            ) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messagesId,
                        messages: err.response.data.errors,
                        status: RC_ERROR,
                        resource: null,
                    }
                });
            }
            dispatch({ type: 'RESOURCE_CACHE_STATUS', payload: { kind, id, status: RC_CACHED } });
            handleApiError(err);
        }

    }
};

/**
 * PUTs the params in the action to the relevant URL in the API.
 *
 * Relies on an event coming in to update our ResourceState cache.
 *
 */
export const ResourcePatchActionLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_PATCH'],
    async process(
        deps: { getState: () => CloudGuiState, action: ResourcePatchAction<any> },
        dispatch: PatchDispatch,
        done: () => void
    ) {
        const { id, kind, params, nonPristine, } = deps.action.payload;
        await doPatchResource(dispatch, id, kind, params, nonPristine, deps.action.payload.messagesId);
        done();
    },
});

export const FETCH_DELAY = 250;

/**
 * Listens out for raw events dispatched straight out of Faye.
 *
 * Pushes them into the general event ResourceCollection, and then
 * triggers API requests for the resource the affected resources.
 *
 */
export const ResourceProcessEventRawLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_EVENT_RAW'],
    async process(
        deps: { getState: () => CloudGuiState, action: ResourceEventRawAction },
        dispatch: Dispatch<ResourceAction<BbAllResourceTypes, BbCollectedResourceTypes> | FetchQueueAction>, done: () => void
    ) {
        const { message } = deps.action.payload;

        dispatch({
            type: 'RESOURCE_ADD_COLLECTED',
            payload: {
                resources: [adaptEvent({ ...message, resource_type: 'event' })],
                kind: 'event',
            }
        });

        // definitely fetch all the affected resources.
        let toProcess = new Set(message.affects.map(a => a.id));
        toProcess.add(message.resource.id);
        // eg creating a server affects the accounts available server RAM.
        toProcess.add(message.account.id);
        toProcess.forEach(id => {
            const kind = getResourceKind(id);
            dispatch({
                type: 'FETCH_QUEUE_PUSH',
                payload: {
                    kind, id, delay: FETCH_DELAY,
                }
            });
        });

        done();
    },
});