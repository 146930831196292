// @flow
import { useState, } from 'react';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { historyBack } from '../../../../lib/history';
import { useCreateResource } from '../../../hoc/CreateResource';
import { ResourceAddViewRoute } from '../../../element/ResourceAddViewRoute';
import { TextInput } from '../../../element/Input';
import { LABELS } from '../../../element/ResourceLabels';

import type { BbApplication, BbApplicationParams } from '../../../../api/type.app';
import type { Match } from 'react-router';

export const ApplicationCreate = ({ match }: { match: Match }): React$Node => {
    const create = useCreateResource<BbApplication, BbApplicationParams>('application_create', 'application');

    const [createParams, setCreateParams] = useState<BbApplicationParams>({
        name: '',
    });

    const validateAndCreate = () => {
        create.createResource(createParams);
    };

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.application.listTitle}
            match={match}
            dialog={null}
            view={
                <Panel>
                    <PanelHeader title={'Create Oauth Application'} />
                    <PanelBar>
                        <TextInput
                            label='Application Name'
                            value={createParams.name}
                            onChange={(name: string) => setCreateParams({ ...createParams, name })}
                            autoFocus={true}
                        />
                    </PanelBar>
                    <PanelButtonBar
                        cacheStatus={create.status}
                        primaryButton={{children: 'Create Oauth Application', onClick: validateAndCreate}}
                        cancel={historyBack}
                    />
                </Panel>
            }
        />
    );
};

