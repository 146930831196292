// @flow
import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { animationRouteContext } from '../../element/animation';
import { useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { ServerGroupList } from './ServerGroupList';
import { ServerGroupCreate } from './ServerGroupCreate';
import { ServerGroupView } from './ServerGroupView';
import { FirewallRuleEdit } from './FirewallRuleEdit';
import { FirewallRuleAdd } from './FirewallRuleAdd';

import type { Match } from 'react-router-dom';

export const ServerGroupSection = ({ match: accountMatch, }: { match: Match }): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route exact path={`${accountMatch.path}`} component={ServerGroupList}/>
                <Route exact path={`${accountMatch.path}add/`} component={ServerGroupCreate}/>
                <Route path={`${accountMatch.path}:id/firewall_rules/add/`} render={({ match: groupIdMatch, }) => groupIdMatch.params.id
                    ? <FirewallRuleAdd id={groupIdMatch.params.id} match={groupIdMatch}/>
                    : <div>Error in react-router?</div>
                }/>

                <Route path={`${accountMatch.path}:server_group_id/firewall_rules/:id/`} render={({ match: fwrIdMatch, }) => fwrIdMatch.params.id
                    ? <FirewallRuleEdit id={fwrIdMatch.params.id} match={fwrIdMatch}/>
                    : <div>Error in react-router?</div>
                }/>

                <Route path={`${accountMatch.path}:id/`} render={({ match: groupIdMatch, }) => groupIdMatch.params.id
                    ? <ServerGroupView id={groupIdMatch.params.id} match={groupIdMatch}/>
                    : <div>Error in react-router?</div>
                }/>
            </Switch>
        </AnimatePresence>
    );
};