// @flow
import { StandardResourceSection } from '../StandardResourceSection';
import { DbsList } from './DbsList';
import { DbsView } from './DbsView';
import { DbsCreate } from './DbsCreate';

import type { Location, Match } from 'react-router-dom';

export const DbsSection = ({ match, }: { location: Location, match: Match }): React$Node => (
    <StandardResourceSection match={match} list={DbsList} create={DbsCreate} view={DbsView} />
);