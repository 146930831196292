// @flow
import { cn } from './lib/classNames';
import { Avatar } from './Avatar';
import { Card } from './Card';
import { PanelBar } from './Panel';
import { RC_API_REQUEST, RC_CACHED, RC_REFRESHING, RC_SUCCESS, RC_ORBIT_OBJECTS } from '../../state/resource/type';
import { CardGroup } from './CardGroup';

export type SkeletonBarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full';

type SkeletonBarProps = {
    +size?: ?SkeletonBarSize,
    +noAnimation?: boolean,
}

export const SkeletonBar = ({ size, noAnimation }: SkeletonBarProps): React$Node => {
    const sizeClass = size ? 'o-skeleton--' + size : 'o-skeleton--md';

    const classNames = cn({
        'o-skeleton': true,
        'o-skeleton--no-animation': !!noAnimation,
        // $FlowFixMe
        [sizeClass]: true,
    });

    return (
        <div className={classNames}>&nbsp;</div>
    )
}

export const SkeletonChip = ({ nameOnly, noAnimation }: { nameOnly?: boolean, noAnimation?: boolean, }): React$Node => {
    return (
        <div className='c-chip'>
            <Avatar skeleton={true} className={noAnimation ? 'o-skeleton--no-animation' : null} />
            <div className='c-chip__content'>
                <div className='c-chip__name'><SkeletonBar size='lg' noAnimation={noAnimation} /></div>
                {nameOnly ? null : <span className='c-chip__secondary'><SkeletonBar size='sm' noAnimation={noAnimation} /></span>}
            </div>
        </div>
    );
};

export const SkeletonCard = (): React$Node => {
    return (
        <Card><SkeletonChip /></Card>
    )
}

export const SkeletonCardPanel = (): React$Node => {
    return (
        <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
        </>
    )
}

export const SkeletonTypeCardPanel = ({ count }: { count?: ?number }): React$Node => (
        typeof count === 'number'
        ? <>{new Array(count).fill(<SkeletonTypeCard />)}</>
        : <>
            <SkeletonTypeCard/>
            <SkeletonTypeCard/>
            <SkeletonTypeCard/>
            <SkeletonTypeCard/>
            <SkeletonTypeCard/>
            <SkeletonTypeCard/>
        </>
)

export const SkeletonDistroCard = (): React$Node => (
    <Card>
        <div className='c-distro-card'>
            <h4 className='c-distro-card__title'>
                <Avatar className='c-distro-card__avatar' skeleton={true} />
                <SkeletonBar size='sm' />
            </h4>
            <div className='c-distro-card__version-select'>
                <SkeletonBar size='full' />
            </div>
        </div>
    </Card>
);

export const SkeletonTypeCard = (): React$Node => (
    <Card>
        <div className='c-type-card'>
            <h4 className='c-type-card__title'><SkeletonBar size='md' /></h4>
            <h5 className='c-type-card__handle'><SkeletonBar size='sm' /></h5>

            <hr/>
            <div className='c-type-card__details'>
                <div className='c-type-card__detail-item'>
                    <div className='c-type-card__value'><SkeletonBar size='xs' /></div>
                    <div className='c-type-card__label'><SkeletonBar size='xs' /></div>
                </div>
                <div className='c-type-card__detail-item'>
                    <div className='c-type-card__value'><SkeletonBar size='xs' /></div>
                    <div className='c-type-card__label'><SkeletonBar size='xs' /></div>
                </div>
                <div className='c-type-card__detail-item'>
                    <div className='c-type-card__value'><SkeletonBar size='xs' /></div>
                    <div className='c-type-card__label'><SkeletonBar size='xs' /></div>
                </div>
            </div>
        </div>
    </Card>
);

export const SkeletonCardSelector = ({ count }: { count?: ?number }): React$Node => {
    return (
        <PanelBar>
        <CardGroup>
        {typeof count === 'number'
        ? <>{new Array(count).fill(<SkeletonDistroCard />)}</>
        : <>
            <SkeletonDistroCard />
            <SkeletonDistroCard />
            <SkeletonDistroCard />
            <SkeletonDistroCard />
            <SkeletonDistroCard />
            <SkeletonDistroCard />
        </>
        }
        </CardGroup>
        </PanelBar>
    )
}

export const SkeletonListLoadingEntries = ({ children, count }: { children: React$Node, count?: ?number }): React$Node => (
    typeof count === 'number'
        ? <>{new Array(count).fill(children)}</>
        : <>
            {children}
            {children}
            {children}
        </>
);

export const SkeletonListRelated = (): React$Node => <SkeletonBar size='md' />;

export const SkeletonChart = (): React$Node => <SkeletonBar size="lg" />

export const isShowDetailsResourceStatus = (status: number | Array<number>): boolean => (
    (Array.isArray(status) ? status : [status]).findIndex(s =>
        s !== RC_CACHED &&
        s !== RC_API_REQUEST &&
        s !== RC_REFRESHING &&
        s !== RC_ORBIT_OBJECTS &&
        s !== RC_SUCCESS
    ) === -1
);