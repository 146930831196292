// @flow
import { cn } from './lib/classNames';
import { Checkbox } from './Checkbox';
import { Dropdown } from './DropdownButton';
import { Button } from './Button';
import { SelectorSummary } from './SelectorSummary';
import { Tooltip } from './Tooltip';
import { Pill } from './Pill';

import type { ThOwnProps } from '../hoc/ListPage';
import type { BbCollectedResourceTypes } from '../../api/type';
import type { ItemSelectHook } from '../common/ResourceSelector';
import type { ButtonProps } from './Button';
import { SvgIcon } from './SvgIcon';

type TableProps = {
    +children: React$Node,
    +className?: ?string,
    +editor?: boolean,
}

export type TrProps = {
    +children: React$Node,
    +className?: ?string,
    +selected?: ?boolean,
    +selectable?: ?boolean,
    +highlighted?: ?boolean,
    +disabled?: ?boolean,
    +empty?: ?boolean,
    +onClick?: ?(e: MouseEvent) => void,
}

type TdProps = {
    +children: React$Node,
    +className?: ?string,
    +actions?: ?boolean,
    +empty?: ?boolean,
    +resourceName?: ?boolean,
    +colSpan?: number | string,
    +min?: boolean,
    +selector?: boolean,
    +onClick?: ?(e: MouseEvent) => void,
}

export type ThProps = {
    +children?: ?React$Node,
    +className?: ?string,
    +actions?: ?boolean,
    +stretch?: ?boolean,
    +selector?: ?boolean,
    +selectable?: ?boolean,
    +colSpan?: number | string,
    +min?: boolean,
    +hint?: ?React$Node,
}

export type TableContext = 'list' | '' | string;

export const Table = ({ editor, children, className, ...rest }: TableProps): React$Node => (
    <table
        className={'c-resource-list' +
        (className ? ' ' +
            className : '') +
        (editor ? ' c-resource-list--editor' : '')}>
        {children}
    </table>
);


export const Tr = ({ children, selected, selectable, highlighted, disabled, className, empty, ...rest }: TrProps): React$Node => {
    const classNames = cn({
        'c-resource-list__row': true,
        'c-resource-list__row--selected': selected,
        'c-resource-list__row--selectable': selectable,
        'c-resource-list__row--highlighted': highlighted,
        'c-resource-list__row--disabled': disabled,
        'c-resource-list__row--empty': empty,
    }) + (className ? ' ' + className : '');

    return (
        <tr
            className={classNames}
            {...rest}
        >
            {children}
        </tr>
    );
};

export const Td = ({ children, className, actions, resourceName, empty, min, selector, ...rest }: TdProps): React$Node => (
    <td
        className={'c-resource-list__item' +
        (min ? ' c-resource-list__item--min' : '') +
        (selector ? ' c-resource-list__item--selector' : '') +
        (actions ? ' c-resource-list__item--actions' : '') +
        (resourceName ? ' c-resource-list__item--resource-name' : '') +
        (empty ? ' c-resource-list__item--empty' : '') +
        (className ? ' ' + className : '')}
        {...rest}
    >
        {children}
    </td>
);

export const Th = ({ children, className, stretch, actions, min, selector, hint, ...rest }: ThProps): React$Node => (
    <th
        className={'c-resource-list__header' +
        (min ? ' c-resource-list__header--min' : '') +
        (selector ? ' c-resource-list__header--selector' : '') +
        (stretch ? ' c-resource-list__header--stretch' : '') +
        (actions ? ' c-resource-list__header--actions' : '') +
        (className ? ' ' + className : '')}
        {...rest}
    >
        {children}
        {hint
            ? <Tooltip
                placement='top'
                overlay={hint}>
                <Pill>?</Pill>
            </Tooltip>
            : null
        }

    </th>
);


export const BlankSortTh = ({ field, children, ...props }: ThOwnProps): React$Node => (
    <th className={'c-resource-list__header'} {...props}>{children}</th>
);

export type MultiSelectChoice = {
    ...ButtonProps,
    separator?: boolean
}

type SelectionDropdownProps = {
    selectionShortcuts: ?$ReadOnlyArray<MultiSelectChoice>,
    selected: number,
}

export const SelectionDropdown = ({ selectionShortcuts, selected }: SelectionDropdownProps): React$Node => (
    selectionShortcuts == null
        ? <SelectorSummary
            selected={selected}
        />
        : <Dropdown
            dropdown={
                <ul className="p-1">
                    {selectionShortcuts.map(({ separator, ...x }, i) =>
                        <li key={i}>
                            {separator ? <hr/> : null}
                            <Button
                                className="w-full"
                                kind="tertiary"
                                size="sm"
                                {...x}
                            />
                        </li>
                    )}
                </ul>
            }
        >
            <SelectorSummary
                selected={selected}
                kind="dropdown"
            />
        </Dropdown>
);

export const ForceSelectedTooltip = (): React$Node => (
    <div>Selected because a parent directory is selected</div>
);

type ThSelectorProps<C: BbCollectedResourceTypes> = {
    +itemSelect: ?ItemSelectHook<C>,
    +context: TableContext,
    // number of rows to span with buttons when in 'multi select' mode
    +actionColSpan: number,
    +columns: React$Node,
    +hasItems: boolean,
    +forceSelected?: boolean,
}

export const TrHeader = <C: BbCollectedResourceTypes>({ itemSelect, forceSelected, context, actionColSpan, columns, hasItems }: ThSelectorProps<C>): React$Node => (
    <Tr>
        {itemSelect
            ? <>
                <Th selector={true}>
                    {itemSelect.multi && hasItems && !forceSelected
                        ? <Checkbox {...itemSelect.multi.headerCheckbox} />
                        : null
                    }
                    {forceSelected
                        ? <Tooltip overlay={<ForceSelectedTooltip />}>
                            <Checkbox
                                color='blue'
                                checked={true}
                                onChange={(next) => void 0}
                                icon='tick'
                            />
                        </Tooltip>
                        : null
                    }
                </Th>
                {itemSelect.multi && itemSelect.multi.itemActions && itemSelect.selectedLength > 0
                    ? <Th colSpan={context === 'list' ? actionColSpan : actionColSpan - 1}>
                        <div className="flex space-x-4 items-center">
                            <SelectionDropdown selectionShortcuts={forceSelected ? null : itemSelect.multi.selectionShortcuts} selected={itemSelect.selectedLength} />
                            <SvgIcon svg='arrow-right' className='text-gray-400' />
                            {itemSelect.multi?.itemActions?.map((x, i) => (
                                <Tooltip overlay={x.disabledTooltip} key={i}>
                                    <Button
                                        size="xs"
                                        disabled={!!x.disabledTooltip}
                                        {...x.buttonProps}
                                    >
                                        {x.label}
                                    </Button>
                                </Tooltip>
                            ))}
                        </div>
                    </Th>
                    : columns
                }
            </>
            : columns
        }
    </Tr>
);