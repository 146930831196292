// @flow

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Panel, PanelHeader } from '../../../element/Panel';
import { ResourceSelector } from '../../../common/ResourceSelector';
import { lbaSearchDef, lbaSortFields } from '../../lba/def';
import { LbaListTable } from '../../lba/LbaList';
import { dbsSearchDef, dbsSortFields } from '../../database_server/def';
import { DatabaseServerListTable } from '../../database_server/DbsList';
import { ServerSelector } from '../../../common/ServerSelector';
import { serverGroupSelectorSearchDef, serverGroupSortFields } from '../../server_group/def';
import { ServerGroupListTable } from '../../server_group/ServerGroupList';
import { TextOnlySearchEditor } from '../../../common/TextOnlySearch';
import { TextAndZoneSearchEditor } from '../../../common/TextAndZoneSearch';
import { isAllocated, isCreating } from '../../../../api/lib';
import { useFeatureLabel } from '../../../hoc/Metadata';
import { CLUSTER_IPS } from '../../../../api/feature_labels';
import { useCurrentAccount } from '../../../hoc/lib';

import type { IdSelectEditor, ResourceSelectorTableProps } from '../../../common/ResourceSelector';
import type { BbServerGroup } from '../../../../api/type';

export type CipMapResourceProps = {
    +editor: IdSelectEditor,
};

export const DefaultGroupCipMapWarning: React$Node = <div>Cloud IPs can't be mapped to the default Server Group</div>;

const ServerGroupTable = (props: ResourceSelectorTableProps<BbServerGroup>) => {
    return (
        <ServerGroupListTable {...props} disableDefaultGroup={DefaultGroupCipMapWarning} />
    )
}

export const CipMapResource = ({ editor }: CipMapResourceProps): React$Node => {
    const account = useCurrentAccount();
    const showGroupMap = useFeatureLabel(CLUSTER_IPS, account);

    if (!editor.value) {
        return null;
    }

    let defaultIndex: number = 0;
    if (editor.value.length > 0) {
        const kind = editor.value[0].substr(0, 3);
        switch(kind) {
        case 'srv':
        default:
            defaultIndex = 0;
            break;
        case 'lba':
            defaultIndex = 1;
            break;
        case 'dbs':
            defaultIndex = 2;
            break;
        case 'grp':
            defaultIndex = 3;
            break;
        }
    }

    return (
        <Panel>
            <PanelHeader
                title="Map Cloud IP"
                description={'To which resource should this Cloud IP be mapped?'}
            />
            <Tabs defaultIndex={defaultIndex}>
                <TabList>
                    <Tab>Cloud Servers</Tab>
                    <Tab>Load Balancers</Tab>
                    <Tab>Cloud SQL</Tab>
                    {showGroupMap ? <Tab>Server Group</Tab> : null}
                </TabList>
                <TabPanel>
                    <ServerSelector editor={editor} selectSingle={true} />
                </TabPanel>
                <TabPanel>
                    <ResourceSelector
                        selector={{
                            editor,
                            selectSingle: true,
                        }}
                        kind='load_balancer'
                        sortFields={lbaSortFields}
                        searchDef={lbaSearchDef}
                        searchComponent={TextOnlySearchEditor}
                        table={LbaListTable}
                        context='selector'
                        itemsFilter={(lba) => isAllocated(lba.status) || isCreating(lba.status)}
                    />
                </TabPanel>
                <TabPanel>
                    <ResourceSelector
                        selector={{
                            editor,
                            selectSingle: true,
                        }}
                        kind='database_server'
                        sortFields={dbsSortFields}
                        searchDef={dbsSearchDef}
                        searchComponent={TextAndZoneSearchEditor}
                        table={DatabaseServerListTable}
                        context='selector'
                        itemsFilter={(dbs) => isAllocated(dbs.status) || isCreating(dbs.status)}
                    />
                </TabPanel>
                {showGroupMap ?
                    <TabPanel>
                        <ResourceSelector
                            selector={{
                                editor,
                                selectSingle: true,
                                selectableFilter: (g => !g.default),
                            }}
                            kind='server_group'
                            sortFields={serverGroupSortFields}
                            searchDef={serverGroupSelectorSearchDef}
                            searchComponent={TextOnlySearchEditor}
                            table={ServerGroupTable}
                            disableDefaultGroup={DefaultGroupCipMapWarning}
                            context='selector'
                            />
                    </TabPanel>
                    : null}
            </Tabs>
        </Panel>
    );
};