// @flow

import { cn } from './lib/classNames';
import { SvgIcon } from './SvgIcon';
import { Pill } from './Pill';
import { Link } from 'react-router-dom';

type CardProps = {
    children: React$Node,
    selected?: boolean,
    disabled?: boolean,
    current?: boolean,
    popover?: boolean,
    selectable?: boolean,
    isNew?: boolean,
    hasError?: boolean,
    link?: string,
    linkStyle?: ?boolean,
    onClick?: ?(event: MouseEvent) => void,
    className?: string
}

export const Card = ({ children, selected, disabled, current, popover, selectable, link, linkStyle, isNew, hasError, className: extraClassName, ...rest }: CardProps): React$Node => {
    const classes = {
        'c-card': true,
        'c-card--selected': !!selected,
        'c-card--selectable': selectable,
        'c-card--new': isNew,
        'c-card--disabled': disabled,
        'c-card--current': current,
        'c-card--error': hasError,
        'c-card--popover': popover,
        'c-card--link': !!link || linkStyle,
    };

    const className = cn(classes) + ' ' + (extraClassName || '');
    return (
        <div className={className} {...rest}>
            {current
                ? <div className='c-card__current-indicator'>
                    <Pill color='med'>Current</Pill>
                </div>
                : null
            }
            {!!selected ? <SvgIcon className='c-card__selected-indicator' svg={'tick'}/> : null}
            {children}
            {link
                ? <Link to={link} className='c-card__arrow'>
                    <SvgIcon svg={'arrow-up-right'} className='block'  />
                </Link>
                : null
            }
            {linkStyle && !link
                ? <div className='c-card__arrow'>
                    <SvgIcon svg={'arrow-up-right'} className='block'  />
                </div>
                : null
            }
        </div>
    );
};