// @flow

import { ValueSelect } from '../../../element/ValueSelect';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';

import type { EditHealthcheck } from './type';
import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { BbLba, BbLbaHealthcheckType } from '../../../../api/type.lba';
import type { FormErrors } from '../../../common/lib';
import type { EditorModal } from '../../../common/Editor';

type LbaHealthCheckEditProps = {
    +editor: EditorModal<EditHealthcheck, FormErrors, BbLba>
};

const healthcheckChoices: Array<ValueSelectChoice<BbLbaHealthcheckType>> = [
    { value: 'http', label: 'HTTP' },
    { value: 'tcp', label: 'TCP' },
];

export const LbaHealthcheckEdit = ({ editor, }: LbaHealthCheckEditProps): React$Node => {
    const { value, setValue, errors } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Healthcheck'}
                description={'How should this Load Balancer check backend nodes for availability?'}
            />
            <PanelBar>
                <Notice type='info' icon='pin'>
                    Healthcheck settings define how a Load Balancer should determine that
                    each backend node is "healthy" and available to receive requests. <a href='https://www.brightbox.com/docs/reference/load-balancers/#health-checks' target='blank'>Learn more</a>
                </Notice>
                <div className='space-y-6'>

                        <ValueSelect
                            id='https_type'
                            label='Protocol'
                            selected={value.type}
                            options={healthcheckChoices}
                            onSelect={(checkType: BbLbaHealthcheckType) => setValue({ ...value, type: checkType })}
                        />

                        <TextInput
                            label='Port'
                            value={value.port || ''}
                            onChange={(port) => setValue({ ...value, port })}
                            errorText={errors.get('port')}
                            hint='The TCP port to be used by the Load Balancer to
                            attempt a healthcheck request'
                            className='w-32'
                        />

                        <TextInput
                            value={value.request || ''}
                            onChange={(request) => setValue({ ...value, request })}
                            errorText={errors.get('request')}
                            label='HTTP Path'
                            hint={
                                value.type === 'tcp'
                                    ? 'Not applicable to a TCP healthcheck'
                                    : 'The URL path to be used for HTTP healthcheck requests'
                            }
                            disabled={value.type === 'tcp'}
                        />
                </div>

                <hr />

                <div className='space-y-6'>
                    <div>
                        <h3>Timings</h3>
                    </div>
  
                    <TextInput
                        value={value.timeout}
                        onChange={(timeout) => setValue({ ...value, timeout })}
                        errorText={errors.get('timeout')}
                        postText='ms'
                        label='Timeout'
                        hint='The length of time in milliseconds the Load Balancer
                        should wait before deciding that a health check request failed [default = 5000]'
                        className='w-32'
                    />
        
                    <TextInput
                        label='Interval'
                        value={value.interval}
                        onChange={(interval) => setValue({ ...value, interval })}
                        errorText={errors.get('interval')}
                        postText='ms'
                        hint='How long in milliseconds between each health check? [default = 5000]'
                        className='w-32'
                    />
                 
                </div>

                <hr />

                <div className='space-y-6'>
                <h3>Thresholds</h3>

                <TextInput
                    label='Up'
                    value={value.threshold_up}
                    onChange={(threshold_up) => setValue({ ...value, threshold_up })}
                    errorText={errors.get('threshold_up')}
                    postText='checks'
                    hint='How many consecutive health checks must fail before a
                    backend is considered down and stops receiving requests? [default = 3]'
                    className='w-32'
                />

                <TextInput
                    label='Down'
                    value={value.threshold_down}
                    onChange={(threshold_down) => setValue({ ...value, threshold_down })}
                    errorText={errors.get('threshold_down')}
                    postText='checks'
                    hint='How many consecutive health checks must succeed
                    before a backend is considered healthy again and ready for new requests? [default = 3]'
                    className='w-32'
                />
          
                </div>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};

