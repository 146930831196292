// @flow
import { useState } from 'react';
import { Panel, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { useEditorDirect, useEditorErrors } from '../../common/Editor';
import { emptyErrors } from '../../common/lib';
import { FirewallRuleForm } from './FirewallRuleForm';
import { useDispatch } from 'react-redux';
import { useViewResource } from '../../hoc/ViewResource';
import { useCreateFirewallRule, } from '../../hoc/CreateResource';
import { historyBack } from '../../../lib/history';
import { validateFirewallRule } from '../firewall_policies/edit/lib';
import { useMessages } from '../../hoc/Messages';
import { CREATE_FWP_MESSAGES_ID } from '../../../state/resource/DependentResourceLogic';
import { RC_API_REQUEST } from '../../../state/resource/type';

import type { Match } from 'react-router';
import type { EditFirewallRule } from '../../../api/type.fwp';
import type { FormErrors } from '../../common/lib';
import type { BbServerGroup, BbServerGroupPatchParams } from '../../../api/type';
import type { ResourceCreateFirewallPolicyAction } from '../../../state/resource/type';
import type { Dispatch } from 'redux';
import type { FirewallRuleDirection } from '../firewall_policies/edit/FirewallPolicyRules';

type FirewallRuleAddProps = {
    id: string,
    match: Match,
}

export function fwpDescriptionForServerGroup(server_group_id: string): string {
    return `**autogenerated for ${server_group_id}**`;
}

const useFirewallRuleAdd = (server_group_id: string, direction: FirewallRuleDirection) => {
    const [value, setValue] = useState<EditFirewallRule>({
        id: '',
        templateIdx: -1,
        description: '',
        protocol: 'any',
        protocolNumber: '',
        source: direction === 'in' ? { kind: 'any', value: '' } : null,
        source_port: '',
        destination: direction === 'out' ? { kind: 'any', value: '' } : null,
        destination_port: '',
        icmp_type_name: '',
    });
    const errors = useEditorErrors<FormErrors>(emptyErrors);
    const editor = useEditorDirect<EditFirewallRule, FormErrors>(value, setValue, errors.errors);

    const resource = useViewResource<BbServerGroup, BbServerGroupPatchParams>('server_group', server_group_id);
    const create = useCreateFirewallRule(server_group_id);
    const dispatch = useDispatch<Dispatch<ResourceCreateFirewallPolicyAction>>();
    const createFwpMessages = useMessages(CREATE_FWP_MESSAGES_ID);

    const cacheStatus = createFwpMessages.status === RC_API_REQUEST
        ? createFwpMessages.status
        : create.status;

    return {
        editor,
        onSave: () => {
            const { item } = resource;
            if (!item) return;

            const { firewall_policy } = item;

            const [validateErrors, apiRule] = validateFirewallRule(value);
            if (validateErrors) {
                errors.setErrors(validateErrors);
                return;
            }
            if (!apiRule) return;

            if (firewall_policy) {
                create.createResource({
                    ...apiRule,
                    firewall_policy: firewall_policy.id,
                });
            } else {
                dispatch({
                    type: 'RESOURCE_CREATE_FIREWALL_POLICY',
                    payload: {
                        params: {
                            server_group: item.id,
                            description: fwpDescriptionForServerGroup(item.id),
                            rules: [apiRule,]
                        }
                    }
                });
            }
        },
        cacheStatus,
        group: resource.item,
    };
};

export const FirewallRuleAdd = ({ id, match, }: FirewallRuleAddProps): React$Node => {
    const direction = window.location.search.indexOf('direction=out') !== -1 ? 'out' : 'in';
    const { editor, onSave, cacheStatus, group, } = useFirewallRuleAdd(id, direction);

    return (
        <ResourceAddViewRoute
            listTitle={group?.name || group?.id || ''}
            match={match}
            dialog={null}
            view={
                <Panel>
                    <PanelHeader
                        title={`Add Firewall Rule`}
                    />
                    <FirewallRuleForm editor={editor}/>

                    <PanelButtonBar
                        cacheStatus={cacheStatus}
                        primaryButton={{onClick: editor.status === 'add' ? onSave : null}}
                        cancel={editor.status === 'add' ? historyBack : null}
                    />
                </Panel>}
        />
    );
};