// @flow

import { toLabelLookup, ValueSelect } from '../../../element/ValueSelect';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { Notice } from '../../../element/Notice';
import { SkeletonBar } from '../../../element/Skeleton';

import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { DbsMaintenanceParams } from '../type';
import type { EditorModal } from '../../../common/Editor';
import type { BbDatabaseServer } from '../../../../api/type.dbs';

export type DbsMaintenanceEditProps = {
    +editor: EditorModal<DbsMaintenanceParams, null, BbDatabaseServer>,
};

export const weekdayChoices: Array<ValueSelectChoice<number>> = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 0, label: 'Sunday' },
];

const hourChoices: Array<ValueSelectChoice<number>> = [
    { value: 0, label: '00:00 - 01:00' },
    { value: 1, label: '01:00 - 02:00' },
    { value: 2, label: '02:00 - 03:00' },
    { value: 3, label: '03:00 - 04:00' },
    { value: 4, label: '04:00 - 05:00' },
    { value: 5, label: '05:00 - 06:00' },
    { value: 6, label: '06:00 - 07:00' },
    { value: 7, label: '07:00 - 08:00' },
    { value: 8, label: '08:00 - 09:00' },
    { value: 9, label: '09:00 - 10:00' },
    { value: 10, label: '10:00 - 11:00' },
    { value: 11, label: '11:00 - 12:00' },
    { value: 12, label: '12:00 - 13:00' },
    { value: 13, label: '13:00 - 14:00' },
    { value: 14, label: '14:00 - 15:00' },
    { value: 15, label: '15:00 - 16:00' },
    { value: 16, label: '16:00 - 17:00' },
    { value: 17, label: '17:00 - 18:00' },
    { value: 18, label: '18:00 - 19:00' },
    { value: 19, label: '19:00 - 20:00' },
    { value: 20, label: '20:00 - 21:00' },
    { value: 21, label: '21:00 - 22:00' },
    { value: 22, label: '22:00 - 23:00' },
    { value: 23, label: '23:00 - 00:00' },
];

export const hourToLabel: Object = hourChoices.reduce(toLabelLookup, {});
export const weekdayToLabel: Object = weekdayChoices.reduce(toLabelLookup, {});

export const MaintenanceHint = (): React$Node => <>Minor version updates to the database engine are automatically applied during a weekly one hour maintenance window. <a href='https://www.brightbox.com/docs/reference/cloud-sql/#weekly-maintenance-window' target='blank'>Learn more</a></>;

export const DbsMaintenanceEdit = ({ editor }: DbsMaintenanceEditProps): React$Node => {
    const { value, setValue } = editor;

    return (
        <Panel>
            <PanelHeader
                title={'Maintenance window'}
                description='When should automated updates take place?' />
            <PanelBar>
                <Notice type='info'><MaintenanceHint /></Notice>
                {value ?
                    <>
                        <ValueSelect
                            label='Day of the week'
                            selected={value.weekday}
                            options={weekdayChoices}
                            onSelect={(v) => setValue({ ...value, weekday: v || 0 })}
                            autoFocus={true}
                        />
                        <br/>
                        <ValueSelect
                            label='Hour of the day'
                            selected={value.hour}
                            options={hourChoices}
                            onSelect={(v) => setValue({ ...value, hour: v || 0 })}
                        />
                    </>
                    : <>
                        <div><SkeletonBar size='lg' /></div>
                        <SkeletonBar size='lg' />
                    </>}
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};
