// @flow

import { RC_INITIAL, RC_CACHED } from '../resource/type';

import type { GlobalSearchState, GlobalSearchStateAction } from './type';
import type { StoreClearAction } from '../type';
import type { ResourceAddFull } from '../resource/type';
import type { AuthSetSelectedAccount } from '../auth/type';

const emptyState: GlobalSearchState = {
    cacheStatus: RC_INITIAL,
    account: null,
    generation: -1,
    fullResources: new Map(),
}

let nextGeneration = 0;

export const GlobalSearchReducer = (
    state: GlobalSearchState = emptyState,
    action: GlobalSearchStateAction | StoreClearAction | ResourceAddFull<any, any> | AuthSetSelectedAccount,
): GlobalSearchState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'AUTH_SELECT_ACCOUNT':
        return emptyState;
    case 'GLOBAL_SEARCH_ACCOUNT':
        return {
            cacheStatus: RC_CACHED,
            account: action.payload.account,
            generation: nextGeneration++,
            fullResources: state.fullResources,
        };
    case 'RESOURCE_ADD_FULL':
        return {
            ...state,
            generation: nextGeneration++,
            fullResources: new Map([
                ...state.fullResources,
                [ action.payload.full.id, { kind: action.payload.kind, id: action.payload.full.id, } ]
            ]),
        }
    case 'GLOBAL_SEARCH_CACHE_STATUS':
        return {
            ...state,
            cacheStatus: action.payload.status,
        }
    default:
        void (action.type: empty);
    }

    return state;
}