// @flow

import { setOrbitToken } from '../../api/rest';
import { fetchKioskToken } from '../../api/kiosk';

export const orbitTokenQuery = `mutation { issueJsonWebToken(input: { scopes:[ORBIT_READ,ORBIT_WRITE,ORBIT_MANAGE,ORBIT_FULL], duration: 10 }) { token { token } } }`;

export async function fetchOrbitAuth() {
    let token = await fetchKioskToken(orbitTokenQuery);
    setOrbitToken(token);
}

// This is the threshold between "full" and "flip" mode - over this number of objects, we use "flip".
// Can't be over 10,000 - Orbit only returns up to 10k objects in a single response and must be paged above that.
export const FLIP_MODE_THRESHOLD = 10000;