// @flow
import { TextInput } from '../../element/Input';
import { ValueSelect } from '../../element/ValueSelect';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../../state/Search/search';
import { createSelector } from 'reselect';
import { statusToActive } from '../../base/def';

import type { BbCollectedAccount } from '../../../api/type.acc';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { SearchEditorHook, SearchDefinition } from '../../element/Search';

const { result } = getSearchSelectors({ resourceName: 'account', resourceSelector });

const getSearchText = createSelector(
    [result,],
    (result) => ({
        searchMatchIds: result,
    }));

const accountSearchText = createSearchAction('account');

export const accountSearchDef: SearchDefinition<BbCollectedAccount> = {
    name: 'account',
    fields: [
        {
            searchFieldName: 'searchText',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedAccount>) => {
                const { searchMatchIds, } = (getSearchText(state): { searchMatchIds: Array<string> });
                return searchMatchIds.findIndex(x => x === item.id) !== -1;
            },
            setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                dispatch(accountSearchText(nextValue));
            },
        },
        {
            searchFieldName: 'accountType',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedAccount>) => {
                return fieldValue !== 'active' || statusToActive(item.status);

            },
            initialValue: 'active',
        },
    ],
};

const accountTypeChoices = [
    { label: 'Show current only', value: 'active', },
    { label: 'Show all accounts', value: 'all', },
];


export const AccountSearch = ({ editor }: { +editor: SearchEditorHook }): React$Node => {
    const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <>
            <TextInput
                label='Filter by keyword'
                size='sm'
                preIcon='search'
                value={editor.fields.searchText.value}
                onChange={(v: string) => editor.setSearchValue('searchText', v)}
                {...post}
            />
            <ValueSelect
                selected={editor.fields.accountType.value}
                onSelect={(value) => editor.setSearchValue('accountType', value)}
                options={accountTypeChoices}
                size='sm'
            />
        </>
    );
};