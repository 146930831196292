// @flow

import type { DialogState } from '../element/Dialog';
import { useDialog } from '../element/Dialog';
import { history, useResourceRoutes } from '../../lib/history';
import { useEffect } from 'react';
import { RC_ERROR } from '../../state/resource/type';
import { toast } from 'react-toastify';

import type { MessageHook } from './Messages';

export function useSupportDialog(messages: ?MessageHook<any>, expectedErrorName: string, getMessage: () => ?string): DialogState<null> {
    const getRoute = useResourceRoutes();

    const supportDialog = useDialog([
        {
            label: 'Contact Support',
            kind: 'primary',
            onSelect: () => {
                const message = getMessage();
                const messageUrl = message != null
                    ? '?p=' + encodeURI(message)
                    : ''
                ;
                history.push(getRoute('support') + messageUrl);
            }
        }
    ]);

    const supportDialogShow = supportDialog.show;
    useEffect(() => {
        if (messages?.status === RC_ERROR) {
            if (messages?.error_name === expectedErrorName) {
                supportDialogShow();
            } else {
                toast(['API error'].concat(messages?.messages || []).join(". "), { type: 'error'});
            }
        }
    }, [messages, supportDialogShow, expectedErrorName]);

    return supportDialog;
}

