// @flow

import { useEffect } from "react";
import { SkeletonBar } from "../../../element/Skeleton";
import { ValueSelect } from "../../../element/ValueSelect";
import { TextInput } from "../../../element/Input";
import { useClientIpAddress } from "../../../../lib/ClientIp";
import { useAccessControlOptions } from "../../../hoc/AccessControl";
import { resourceTypeMatch } from "../../../common/AccessControl";

import type { AccessResourcesHook } from "../../../hoc/AccessControl";
import type { AccessControlTarget, BbTargetKinds } from "../../../../api/type";

type AccessControlOnChange = (selectedType: BbTargetKinds, value: string) => void;

export type AccessControlSelectProps = {
    +selected: AccessControlTarget,
    +onChange: AccessControlOnChange,
    +label: string,
    +errorText?: ?string,
};

export const portCriteriaHint: React$Node =
    <span>
        Enter either: a single port, a comma separated list of ports or a range of
        ports (<a href='https://www.brightbox.com/docs/reference/firewall/#port-criteria' target='blank'>learn more</a>)
    </span>
;

export const sourceOrDestPortOnly: React$Node = (
    <span>
        Firewall Rules can only specify <b>either</b> a source or destination port
    </span>
);

const FWR_INCLUDE = {
    any: true,
    server: true,
    lba: true,
    serverGroup: true,
    address: true,
    clientIp: true,
    unknown: true,
};

function useFwrAccessRulePatch(
    acr: AccessResourcesHook,
    selected: AccessControlTarget,
    onChange: AccessControlOnChange
): void  {
    const { processing: loading , options } = acr;

    // some grp- rules could be cross-account groups. change them to 'other' so they are a text input rather
    // than the current-account-groups-dropdown, which won't have the group in it.
    useEffect(
        () => {
            if (!loading && selected.kind !== 'other') {
                const matches = typeof selected.value === 'string' ? selected.value.match(resourceTypeMatch) : false;
                if (typeof matches === 'object' && matches && (!options[matches[1]] || !options[matches[1]].find(x => x.value === selected.value))) {
                    onChange('other', selected.value);
                }
            }
        },
        // this runs a bit more that i'd like it to really; but the nested conditionals should keep it snappy.
        [loading, options, selected, onChange]
    );
}

export const FwrAccessControl = ({ selected, onChange, label, errorText, }: AccessControlSelectProps): React$Node => {
    const acr = useAccessControlOptions(FWR_INCLUDE);
    useFwrAccessRulePatch(acr, selected, onChange);
    const { processing, options, typeOptions } = acr;

    const { clientIp } = useClientIpAddress();

    if (processing) {
        return <SkeletonBar size='lg' />
    }

    return (
        <>
            <ValueSelect
                label={label + ' Type'}
                selected={selected.kind}
                options={typeOptions}
                onSelect={(nextType: BbTargetKinds) => {
                    onChange(nextType, nextType === 'clientIp' ? (clientIp || '') : '');
                }}
                className='my-3'
            />

            {selected.kind === 'addr'
                ? <TextInput
                    label='IPv4/IPv6 Subnet'
                    errorText={errorText}
                    value={selected.value}
                    onChange={(value: string) => onChange('addr', value)}
                    hint='Enter a valid subnet in CIDR notation'
                    className='my-3'
                />
                : null}
            {selected.kind === 'other'
                ? <TextInput
                    label='Other'
                    errorText={errorText}
                    value={selected.value}
                    onChange={(value: string) => onChange('other', value)}
                    className='my-3'
                />
                : null}
            {selected.kind in options
                ? <ValueSelect
                    label={label}
                    errorText={errorText}
                    options={options[selected.kind]}
                    selected={selected.value}
                    onSelect={(value: string) => onChange(selected.kind, value)}
                    className='my-3'
                />
                : null}

        </>
    );
};

