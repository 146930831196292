// @flow

import { useState } from 'react';
import { RC_CACHED, RC_ERROR, RC_FETCHING } from '../../../state/resource/type';
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip as ChartTooltip, Legend, } from 'recharts';
import { Spinner } from '../../element/Spinner';
import { formatTime, formatDate, formatDateTime } from '../../element/Styled';
import { useGraphqlMetric } from '../../hoc/Metrics';
import { Button } from '../../element/Button';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../../lib/history';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';

import type { ResourceChartQuery } from './types';

const COLORS = [
    '#9358B0',
    '#DE701D',
    '#02A191',
    '#AB4160',
    '#273A8C',
    '#DD5C6E',
    '#F4BB0B',
    '#59A145',
    '#425AC8',
    '#E76C5A',
    '#CA5FB9',
];

export type ResourceAreaChartConfig<Metric> = {
    +query: ResourceChartQuery<Metric>,

    +title: string,
    +measurement?: string,
    +tooltip: string,

    +colorOffset?: number,

    +yAxisFormatter: (v: number) => string,
}

export type ResourceAreaChartProps<Metric> = {
    +id: string,
    +range: string,
    ...ResourceAreaChartConfig<Metric>
}

function getFormatter(range: string): Function {
    switch(range) {
    default:
    case 'HOURS_1':
    case 'HOURS_24':
        // time is good
        return (v) => formatTime(new Date(v));
    case 'HOURS_168':
    case 'HOURS_336':
        return (v) => formatDate(new Date(v));
    }
}

export function ResourceAreaChart<Metric>({ id: resourceId, range, title, measurement, tooltip, query, yAxisFormatter, colorOffset, }: ResourceAreaChartProps<Metric>): React$Node {
    const { chartInput, cacheStatus, refresh, } = useGraphqlMetric<Metric>(query, resourceId, range);
    const formatter = getFormatter(range);
    const getRoute = useResourceRoutes();
    const [activeSeries, setActiveSeries] = useState<Array<string>>([]);

    const handleLegendClick = (dataKey: string) => {
        if (activeSeries.includes(dataKey)) {
          setActiveSeries(activeSeries.filter(el => el !== dataKey));
        } else {
          setActiveSeries(prev => [...prev, dataKey]);
        }
      };

    return (
        <div className="c-chart">
            <h3 className="c-chart__title">
                {title}
                {measurement
                    ? <i className='c-chart__title-measurement'>({measurement})</i>
                    : null
                }
                {tooltip
                    ? <Tooltip overlay={tooltip}>
                        <Pill className='c-chart__tooltip'>?</Pill>
                    </Tooltip>
                    : null
                }
                </h3>
            <div className='c-chart__container'>
                {cacheStatus === RC_ERROR
                    ? <div className='c-chart__placeholder'>
                        <div className='c-chart__error'>
                            <p>We couldn't retrieve the data to display this chart. <br/>If the problem persists, please <Link to={getRoute('support')}>contact support</Link></p>
                            <p><Button size='sm' kind='secondary' onClick={() => refresh()}>Reload chart</Button></p>
                        </div>
                    </div>
                    : null
                }
                {cacheStatus === RC_FETCHING
                    ? <Spinner size='md' className='c-chart__spinner' />
                    : null
                }
                {cacheStatus === RC_CACHED && chartInput != null
                    ? <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                data={chartInput.data[1]}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    domain={[chartInput.range.start, chartInput.range.end]}
                                    dataKey="time"
                                    scale='time'
                                    type='number'
                                    tickFormatter={formatter}
                                    ticks={chartInput.ticks}
                                />
                                <YAxis
                                    tickFormatter={yAxisFormatter}
                                />
                                <ChartTooltip
                                    formatter={yAxisFormatter}
                                    labelFormatter={(v) => formatDateTime(v)}
                                />
                                {chartInput.data[0].map((name, i) =>
                                    <Area
                                        hide={activeSeries.includes('s'+i)}
                                        key={i}
                                        type="monotone"
                                        dataKey={'s' + i}
                                        name={name}
                                        strokeWidth={1.25}
                                        fillOpacity={0.08}
                                        fill={COLORS[(colorOffset || 0) + i % COLORS.length]}
                                        stroke={COLORS[(colorOffset || 0) + i % COLORS.length]}
                                        dot={false}
                                        animationDuration={1000}
                                    />
                                )}
                                {chartInput.data[0].length > 1
                                    ? <Legend
                                        verticalAlign='bottom'
                                        formatter={(v, e, i) => <span className="c-chart__legend-item">{v}</span>}
                                        onClick={props => handleLegendClick(props.dataKey)}
                                    />
                                    : null
                                }
                            </AreaChart>
                        </ResponsiveContainer>
                    : null
                }


                </div>
        </div>
    );
}
