// @flow
import { StandardResourceSection } from '../StandardResourceSection';
import { CollaborationList } from './CollaborationList';
import { CollaborationCreate } from './CollaborationCreate';
import { CollaborationView } from './CollaborationView';

import type { Match } from 'react-router-dom';

export const TeamSection = ({ match }: { match: Match }): React$Node => (
    <StandardResourceSection match={match} list={CollaborationList} view={CollaborationView} create={CollaborationCreate} />
);
