// @flow
import { createElement, useContext, useEffect } from 'react';
import { Page, PageHeader, PageTitle } from './Page';
import { matchPath } from 'react-router';
import { BackLink } from './BackLink';
import { animationRouteContext, pageTransition, pageVariants } from './animation';
import { AnimatePresence, motion } from 'framer-motion';
import { historyBack, pushEscHandler, removeEscHandler, } from '../../lib/history';
import { RC_NOT_FOUND } from '../../state/resource/type';
import { NotFound } from '../section/home/NotFound';

import type { EditorModal, } from '../common/Editor';
import type { ViewResourceProps } from '../hoc/ViewResource';
import type { Match } from "react-router";

type ResourceEditor = {
    +editor: EditorModal<any, any, any>,
    +component?: React$StatelessFunctionalComponent<{ +editor: EditorModal<any, any, any>, }>,
} | {
    +editor: { editUri: string, onSave: () => any, ... },
    +render?: () => React$Node,
}

type ResourceAddViewProps = {
    listTitle?: ?string,
    resourceName?: string,
    match: Match,
    view: React$Node,
    dialog?: ?React$Node,
    editors?: $ReadOnlyArray<ResourceEditor>,
    resource?: ViewResourceProps<any, any>,
};

const FormWrapper = ({ children, onSave }: { children: React$Node, onSave: () => void }) => {
    useEffect(() => {
        const handler = () => historyBack();
        pushEscHandler(handler);
        return () => removeEscHandler(handler);
    }, []);

    const onSubmitHandler = (e: SyntheticUIEvent<any>) => {
        e.preventDefault();
        onSave();
        return false;
    }

    return (
        <form onSubmit={onSubmitHandler}>
            {children}
        </form>
    );
}

export const ResourceAddViewRoute = ({ listTitle, resourceName, match, view, dialog, editors, resource, }: ResourceAddViewProps): React$Node => {
    const [ , custom, sectionAnim, subEditAnim, ] = useContext(animationRouteContext);

    let editor = null;
    const { path } = match;
    (editors || []).forEach((re) => {
        if (editor) return;
        const matchedEditor = matchPath(window.location.pathname, {
            path: path + re.editor.editUri
        });
        if (matchedEditor) {
            editor = re;
        }
    });

    let child: React$Node;

    if (!match.isExact && editor == null) {
        child = <NotFound extra={` - unknown path (...${window.location.pathname.replace(match.url, '')})`}/>;
    } else if (resource?.status === RC_NOT_FOUND) {
        child = <NotFound/>;
    } else {
        child = editor == null
            ? <>
                {!resource && resourceName
                    ? <PageHeader>
                        <PageTitle>New {resourceName}</PageTitle>
                    </PageHeader>
                    : null
                }
                {view}
            </>
            : editor.render
                ? <FormWrapper onSave={editor.editor.onSave}>{editor.render()}</FormWrapper>
                : editor.component
                    ? <FormWrapper onSave={editor.editor.onSave}>{createElement(editor.component, { editor: editor.editor })}</FormWrapper>
                    : null
        ;
    }

    return (
        <>
            {dialog}
            <motion.div
                style={{width:'100%'}}
                {...sectionAnim}
                variants={pageVariants}
                transition={pageTransition}
            >
                <AnimatePresence custom={custom}>
                    <motion.div
                        style={{width:'100%'}}
                        key={editor ? editor.editor.editUri : 'view'}
                        {...subEditAnim}
                        variants={pageVariants}
                        transition={pageTransition}
                    >
                        <Page>
                            {editor && resourceName ? <BackLink>{resourceName}</BackLink> : null}
                            {!editor && listTitle ? <BackLink>{listTitle}</BackLink> : null}
                            {child}
                        </Page>
                    </motion.div>
                </AnimatePresence>
            </motion.div>
        </>

    );
};