// @flow
import { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RC_INITIAL, RC_ERROR, RC_SUCCESS, RC_API_REQUEST } from '../../state/resource/type';

import type { Message, MessageRemoveAction, MessageStatus } from '../../state/Message/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { BbCloudIp } from '../../api/type.cip';

export type MessageHook<Resource: Object> = {
    ...Message<Resource>,
    clear: () => void,
}

const defaultMessage: Message<any> = {
    id: '',
    messages: [],
    status: (RC_INITIAL: MessageStatus),
    resource: null,
};

export const useMessages = <Resource: Object>(id: ?string): MessageHook<Resource> => {
    const stateMessage = useSelector(
        (state: CloudGuiState) => id == null ? null : (((state.Message[id] || defaultMessage): any): Message<Resource>)
    );
    const dispatch = useDispatch<Dispatch<MessageRemoveAction>>();

    return useMemo(() => ({
        ...(stateMessage || defaultMessage),
        clear: () => {
            if (id) dispatch({ type: 'MESSAGE_REMOVE', payload: { id } });
        },
    }), [id, stateMessage, dispatch]);
};

export type CloudIpMessages = {
    messages: { [cipId: string]: MessageHook<BbCloudIp> },
    summary: MessageHook<BbCloudIp>,
};

export const useCloudIpMessages = (prefix: string): CloudIpMessages => {
    const dispatch = useDispatch<Dispatch<MessageRemoveAction>>();

    const [messages, hasError, hasUpdating, allSuccess] = useSelector((state: CloudGuiState) => {
        let messages = {};
        let hasError = false;
        let hasUpdating = false;
        let allSuccess = null;

        Object.keys(state.Message)
            .filter((key: string) => key.startsWith(prefix))
            .forEach(id => {
                const msg = state.Message[id];
                const thisSuccess = (msg.status === RC_SUCCESS);
                messages[id.replace(prefix, '')] = {
                    ...msg,
                    clear: () => {
                        dispatch({ type: 'MESSAGE_REMOVE', payload: { id } });
                    }
                };

                hasUpdating = hasUpdating || (msg.status === RC_API_REQUEST);
                allSuccess = (allSuccess === null) ? thisSuccess : allSuccess && thisSuccess;
                hasError = hasError || (msg.status === RC_ERROR);
            });

        return [messages, hasError, hasUpdating, allSuccess];
    });

    const summary: MessageHook<BbCloudIp> = useMemo(() => ({
        ...defaultMessage,
        status: (hasError ? RC_ERROR : allSuccess ? RC_SUCCESS : hasUpdating ? RC_API_REQUEST : RC_INITIAL),
        clear: () => {
            Object.keys(messages).forEach((cipId) => {
                dispatch({ type: 'MESSAGE_REMOVE', payload: { id: messages[cipId].id } });
            });
        }
    }), [messages, allSuccess, hasError, hasUpdating, dispatch]);

    return {
        messages,
        summary,
    };
};

export type TriggeredMessagesHook<Resource> = {
    next: () => string,
    messages: MessageHook<Resource>,
}

let nextMsgId = 1;

export const useTriggeredMessages = <Resource: Object>(): TriggeredMessagesHook<Resource> => {
    const [id, setId] = useState<?string>(null);

    const messages = useMessages(id);

    const next = useCallback(() => {
        nextMsgId++;
        const id = 'msg' + nextMsgId;
        setId(id);
        return id;
    }, [setId]);

    return {
        next,
        messages
    };
}