// @flow

import { archOptions as allArchOptions } from './edit/ImageAdvanced';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../../state/Search/search';
import { createSelector } from 'reselect';
import { TextInput } from '../../element/Input';
import { ValueSelect } from '../../element/ValueSelect';

import type { CloudGuiState } from '../../../state/cloudgui';
import type { BbCollectedImage } from '../../../api/type';
import type { Dispatch } from 'redux';
import type { SearchEditorHook, SearchDefinition } from '../../element/Search';
import type { SearchIndexes } from '../../../state/Search/search';

const sourceOptions = [
    { label: 'Any type', value: '' },
    { label: 'Image', value: 'upload', },
    { label: 'Snapshot', value: 'snapshot', },
];

const archOptions = [{ label: 'Any arch', value: '' },].concat(allArchOptions);

type ImageSearch = {
    ImageSearch: React$ComponentType<{ +editor: SearchEditorHook }>,
    searchDef: SearchDefinition<BbCollectedImage>
}

export const createImageSearch = (name: SearchIndexes): ImageSearch => {
    const { result } = getSearchSelectors({ resourceName: name, resourceSelector });

    const getSearchTextImages = createSelector(
        [result,],
        (result) => ({
            searchMatchIds: result,
        }));

    const imageSearchText = createSearchAction(name);

    const searchDef = {
        name,
        fields: [
            {
                searchFieldName: 'searchText',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedImage>) => {
                    const { searchMatchIds, } = (getSearchTextImages(state): { searchMatchIds: Array<string> });
                    return searchMatchIds.findIndex(x => x === item.id) !== -1;
                },
                setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                    dispatch(imageSearchText(nextValue));
                },
            },
            {
                searchFieldName: 'sourceType',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedImage>) => {
                    return item.source_type === fieldValue;
                },
            },
            {
                searchFieldName: 'arch',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCollectedImage>) => {
                    return item.arch === fieldValue;
                },
            },
        ],
    };

    const ImageSearch = ({ editor }: { editor: SearchEditorHook }) => {
        const { fields, setSearchValue } = editor;
        const post = editor.fields.searchText.value
            ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
            : { postIcon: 'spacer', };

        return (
            <>
                <TextInput
                    size='sm'
                    label='Filter by keyword'
                    value={fields.searchText.value}
                    onChange={(value) => setSearchValue('searchText', value)}
                    preIcon={'search'}
                    {...post}
                />
                <ValueSelect
                    label='Type'
                    size='sm'
                    selected={fields.sourceType.value}
                    options={sourceOptions}
                    onSelect={(v) => setSearchValue('sourceType', v)}
                />
                <ValueSelect
                    label='Arch'
                    size='sm'
                    selected={fields.arch.value}
                    options={archOptions}
                    onSelect={(v) => setSearchValue('arch', v)}
                />
            </>
        );
    };

    return {
        ImageSearch,
        searchDef
    };
};