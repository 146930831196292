// @flow
import { cn } from './lib/classNames';
import { useFocusHoverStyle } from './Input';
import { useAnimAutoFocus } from './lib/autofocus';


type TextareaProps = {
    value: string | number,
    onChange: ?(value: string) => void,
    // if onPaste returns 'true', prevent the default paste action.
    onPaste?: (value: string, e: ClipboardEvent) => ?boolean,
    rows?: number,
    size?: 'default' | 'sm';
    label?: ?string,
    helperText?: ?string,
    errorText?: ?string,
    optional?: true,
    disabled?: boolean,
    autoFocus?: boolean,
    className?: ?string,
    spellCheck?: ?boolean,
    focusSelectAll?: ?boolean,
    readOnly?: ?boolean,
}

export const Textarea = (props: TextareaProps): React$Node => {
    
    const { ref: inputRef, classNames: hoverClasses } = useFocusHoverStyle('c-textarea', props.focusSelectAll);

    const {
        value, label, rows,
        size, optional, disabled,
        errorText, helperText,
        onChange, onPaste, className,
        autoFocus,
        ...rest
    } = props;

    let classes = {
        'c-textarea': true,
        'c-textarea--has-error': !!errorText,
        'c-textarea--completed': !!value,
        'c-textarea--optional': !!optional,
        'c-textarea--disabled': (disabled === true),
        'c-textarea--sm': (size === 'sm'),
        ...hoverClasses,
    }

    useAnimAutoFocus(autoFocus, disabled, inputRef);

    return (
        <div className={cn(classes) + ' ' + (className || '')}>
            {props.label
                ? <label className='c-textarea__label'>
                    {label}
                    {optional ? <span> (optional)</span> : null}
                </label>
                : null
            }
            <textarea 
                className={'c-textarea__control'}
                rows={rows ? rows : 3}
                value={value}
                disabled={disabled}
                spellCheck={false}
                ref={inputRef}
                onChange={onChange ? (e) => onChange(e.target.value) : null}
                onPaste={(e) => {
                    if (onPaste) {
                        const data = e.clipboardData.getData('text/plain');
                        if (data) {
                            const res = onPaste(data, e);
                            if (res) e.preventDefault();
                        }
                    }
                }}
                {...rest}
            />
            {helperText && !errorText
                ? <div className='c-textarea__helper-text'>{helperText}</div>
                : null
            }
            {errorText
                ? <div className='c-textarea__helper-text'>{errorText}</div>
                : null}
        </div>
    );

};
