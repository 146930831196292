// @flow

import { ReactSVG } from 'react-svg';
import { Icons } from '../../assets/icons';
import type { Icon } from '../../assets/icons';

export type SvgIconProps = {
    svg: Icon,
    color?: string,
    size?: number | false,
    className?: string,
    onClick?: ?() => any,
}

const svgAttributes = (err, svg) => {
    if (!err && svg) {
        svg.classList.add(
            'o-icon__svg'
        );
        svg.removeAttribute('width');
        svg.removeAttribute('height');
    }
}

export const SvgIcon = ({ svg, className: extraClassName, onClick, ...rest }: SvgIconProps): React$Node => {

    return (
        <ReactSVG
            src={Icons[svg]}
            className={'o-icon ' + (extraClassName || '')}
            afterInjection={svgAttributes}
            onClick={onClick}
            {...rest} 
        />
    );
};