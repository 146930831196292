// @flow

import { textOnlySearch } from '../../common/TextOnlySearch';
import { arrayLengthSort, cloudIpsSort, dateSort, idNameSort, stringSort } from '../../element/Sort';
import { isSelectable } from '../../hoc/ListSelection';

import type { BbCollectedLba } from '../../../api/type.lba';
import type { SortFields } from '../../element/Sort';
import type { SearchDefinition } from '../../element/Search';

export const lbaSearchDef: SearchDefinition<BbCollectedLba> = textOnlySearch<BbCollectedLba>('load_balancer');

export const lbaSortFields: SortFields<BbCollectedLba> = {
    _default: idNameSort<BbCollectedLba>(),
    id: idNameSort<BbCollectedLba>(),
    name: stringSort<BbCollectedLba>('name'),
    created_at: dateSort<BbCollectedLba>('created_at'),
    cloud_ips: cloudIpsSort<BbCollectedLba>('cloud_ips'),
    nodes: arrayLengthSort<BbCollectedLba>('nodes'),
};

export function loadBalancerSelectable(lba: BbCollectedLba): boolean {
    return isSelectable(lba.status);
}