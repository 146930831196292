// @flow

import type { BbServerType } from '../api/type';
import type { BbStorageType } from '../api/type.volume';

export type ServerTypeGroup = {
    +label: string,
    +types: Array<BbServerType>,
};

export type ServerTypeGroups = {
    'ssd': ServerTypeGroup,
    'ssd-stg-opt': ServerTypeGroup,
    'ssd-ram-opt': ServerTypeGroup,
    'nbs': ServerTypeGroup,
};

export function serverTypeToTypeType(storage_type: BbStorageType, handle: ?string): string {
    if (storage_type === 'network') return 'nbs';

    if ((handle || '').indexOf('.') === -1) return handle || '';

    const [ , typeType ] = (handle || '.').split('.');
    return typeType;
}

export const getServerTypeGroups = (allTypes: Array<BbServerType>): ServerTypeGroups => {
    const initial: ServerTypeGroups = {
        'ssd':         { label: 'Standard', types: [] },
        'ssd-stg-opt': { label: 'Storage Optimised', types: [] },
        'ssd-ram-opt': { label: 'RAM Optimised', types: [] },
        'nbs':         { label: 'Block Storage', types: [] },
    };

    allTypes.reduce((acc: ServerTypeGroups, server_type: BbServerType) => {
        const typeType = serverTypeToTypeType(server_type.storage_type, server_type.handle);

        if (typeType in acc) {
            acc[typeType].types.push(server_type);
        }

        return acc;
    }, initial);

    initial.ssd.types.sort((a, b) => (a.ram - b.ram));
    initial['ssd-ram-opt'].types.sort((a, b) => (a.ram - b.ram));
    initial['ssd-stg-opt'].types.sort((a, b) => (a.ram - b.ram));
    initial['nbs'].types.sort((a, b) => (a.ram - b.ram));

    return initial;
};