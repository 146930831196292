// @flow

import { formatToTimeZone } from 'date-fns-timezone';
import type { BbCollectedAccount } from '../../api/type.acc';

type Cache = { [string]: string };

let historyCache: ?Cache = null;

export function testClearCache(): void {
    historyCache = null;
}

function getCache(): $ReadOnly<Cache> {
    if (localStorage && historyCache == null) {
        let history = localStorage.getItem('bb-account-history');
        if (typeof history === 'string') historyCache = JSON.parse(history);
    }
    if (typeof historyCache !== 'object') historyCache = {};

    // $FlowFixMe it must be at least {} by now.
    return historyCache;
}

export function getLastAccountId(): ?string {
    const a = getCache();
    if (a != null) {
        const latest = Object.keys(a).reduce((acc, id) => {
            if (acc === null || a[id] > a[acc]) {
                return id;
            }
            return acc;
        }, null);

        if (latest) return latest;
    }

    if (localStorage) return localStorage.getItem('bb-account');

    return null;
}

export function setLastAccountId(id: string) {
    // there's no guarantee that getLastAccountId was
    // called before this. so always try to get the cache.
    const existingCache = getCache();

    historyCache = {
        ...existingCache,
        [id]: formatToTimeZone(Date.now(), 'YYYYMMDDHHmmss', { timeZone: 'UTC' })
    };

    if (localStorage) {
        localStorage.setItem('bb-account-history', JSON.stringify(historyCache));
    }
}

export function sortAccountsByHistory(a: BbCollectedAccount, b: BbCollectedAccount): number {
    const cache = getCache();

    return (cache[b.id] || '19700101010001').localeCompare(cache[a.id] || '19700101010001');
}