// @flow
import { useDispatch } from 'react-redux';
import { useViewResource, } from '../../hoc/ViewResource';
import { CipPortTranslators } from './edit/CipPortTranslators';
import { useResourceEditorModal } from '../../common/Editor';
import { emptyErrors } from '../../common/lib';
import { validatePortTranslators } from './edit/lib';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useDeleteDialog, DeleteDialog, } from '../../common/CommonDialogs';
import { CipReverseDnsEdit } from './edit/CipAdvanced';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { CipPortTranslatorsEdit, emptyPortTranslator, } from './edit/CipPortTranslatorsEdit';
import { NameEditPanel } from '../../element/NameEditPanel';
import { CipMapResource } from './edit/CipMapResource';
import { CloudIpMappedResource } from './CipMappedResource';
import { useDialog, Dialog } from '../../element/Dialog';
import { history } from '../../../lib/history';
import { SkeletonBar, SkeletonChip } from '../../element/Skeleton';
import { MonthlyPrice } from '../../element/MonthlyPrice';
import { getMonthlyPrice } from '../../../lib/pricing';

import type { Dispatch } from 'redux';
import type { Match } from 'react-router-dom';
import type { BbCloudIp, BbCloudIpParams, } from '../../../api/type.cip';
import type { EditorDef } from '../../common/Editor';
import type { EditPortTranslator } from './edit/type';
import type { FormErrors } from '../../common/lib';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { CloudIpMapAction } from '../../../state/CloudIp/type';
import type { ResourceActionMethod } from '../../../state/resource/type';
import type { DialogState } from '../../element/Dialog';
import type { DeleteDialogHook } from '../../common/CommonDialogs';
import type { PanelHeaderActions } from '../../element/Panel';
import { LABELS } from '../../element/ResourceLabels';

const nameDef: EditorDef<BbCloudIp, string, BbCloudIpParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbCloudIp) => res.name || '',
    onValidate: null,
};
const reverse_dnsDef: EditorDef<BbCloudIp, string, BbCloudIpParams, null> = {
    emptyErrors: null,
    editUri: 'reverse_dns',
    onEdit: (res: BbCloudIp) => res.reverse_dns || '',
    onValidate: null,
};
const port_translatorsDef: EditorDef<BbCloudIp, $ReadOnlyArray<EditPortTranslator>, BbCloudIpParams, FormErrors> = {
    emptyErrors,
    editUri: 'port_translators',
    onEdit: (cloudIp: BbCloudIp) => cloudIp.port_translators.length ? [].concat(cloudIp.port_translators) : [emptyPortTranslator],
    onValidate:  (resource: ViewResourceProps<BbCloudIp, BbCloudIpParams>, value: $ReadOnlyArray<EditPortTranslator>) => validatePortTranslators(value),
}
const mappingDef: EditorDef<BbCloudIp, $ReadOnlyArray<string>, BbCloudIpParams, null> = {
    emptyErrors: null,
    editUri: 'map',
    onEdit: (cloudIp: BbCloudIp) => {
        if (cloudIp.server) return [cloudIp.server.id];
        if (cloudIp.load_balancer) return [cloudIp.load_balancer.id];
        if (cloudIp.database_server) return [cloudIp.database_server.id];
        if (cloudIp.server_group) return [cloudIp.server_group.id];
        return [];
    },
    onValidate:  (resource: ViewResourceProps<BbCloudIp, BbCloudIpParams>, value: $ReadOnlyArray<string>) => [null, { destination: value.length ? value[0] : '' }]
}


type CipDialogsHook = {
    actions: PanelHeaderActions,
    ...DeleteDialogHook,
    unmapDialog: DialogState<null>,
}

type CipDialogsProps = {
    name: string,
    hook: CipDialogsHook,
}

export const useCipDialogs = (
    mapped: boolean,
    simpleAction: (action: string, params?: ?$Shape<BbCloudIpParams>, nonPristine: ?BbCloudIp, method?: ?ResourceActionMethod) => void,
    deleteAction: () => void
): CipDialogsHook => {
    const [deleteCipActions, deleteDialog] = useDeleteDialog(!mapped, deleteAction);

    const unmapDialog = useDialog([ { label: 'Unmap', kind: 'primary', onSelect: () => simpleAction('unmap'), } ]);

    let mapActions: PanelHeaderActions = mapped
        ? {
            unmap: unmapDialog.show,
            remap: () => history.push('map/'),
        }
        : {
            unmap: null,
            map: () => history.push('map/'),
        }

    return {
        actions: {
            ...mapActions,
            ...deleteCipActions,
        },
        unmapDialog,
        deleteDialog,
    };
}


export const CipDialogs = ({name, hook, }: CipDialogsProps): React$Node => {
    return (
        <>
            <DeleteDialog name={name} dialog={hook.deleteDialog} />
            <Dialog
                title='Unmap Cloud IP?'
                dialog={hook.unmapDialog}
                render={() => <p>Are you sure you want to unmap {name}?</p>} />
        </>
    );
}


export const CipView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbCloudIp, BbCloudIpParams>('cloud_ip', id);
    const dispatch = useDispatch<Dispatch<CloudIpMapAction>>();
    const onMap = (resource: ViewResourceProps<BbCloudIp, BbCloudIpParams>, value: $Shape<BbCloudIpParams>) => {
        dispatch({
            type: 'CLOUD_IP_MAP',
            payload: {
                cloudIpId: id, 
                destination: value.destination || '',
                messagesId: resource.messagesId,
            }
        });
    };
    const { item: cloudIp,  } = resource;

    const port_translators = useResourceEditorModal(resource, port_translatorsDef, match.path);
    const reverse_dns = useResourceEditorModal(resource, reverse_dnsDef, match.path);
    const name = useResourceEditorModal(resource, nameDef, match.path);
    const mapping = useResourceEditorModal(resource, { ...mappingDef, onSave: onMap }, match.path);

    const mapped = cloudIp != null && cloudIp.status === 'mapped';
    const dialogs = useCipDialogs(
        mapped,
        resource.simpleAction, resource.deleteAction
    );

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.cloud_ip.listTitle}
            resourceName={cloudIp?.name || id}
            resource={resource}
            match={match}
            dialog={<CipDialogs name={cloudIp?.name || id} hook={dialogs} />}
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={cloudIp?.name || cloudIp?.id}
                            actions={dialogs.actions}
                            editTitleLink={name.editUri}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: cloudIp?.id,
                                status: cloudIp?.status,
                            }}
                            settings={[
                                [
                                    {
                                        name: 'IPv4 Address',
                                        summary: <span className='select-all'>{cloudIp?.public_ipv4}</span>,
                                        copyValue: cloudIp?.public_ipv4,
                                        hint: 'The permanent publicly routed IPv4 address for this Cloud IP',
                                    },
                                    {
                                        name: 'IPv6 Address',
                                        summary: <span className='select-all'>{cloudIp?.public_ipv6}</span>,
                                        copyValue: cloudIp?.public_ipv6,
                                        skeleton: <SkeletonBar size='lg' />,
                                        hint: 'The permanent publicly routed IPv6 address for this Cloud IP',
                                    },
                                ],
                                [
                                    {
                                        name: 'Mapping',
                                        summary: <CloudIpMappedResource ip={cloudIp} />,
                                        route: cloudIp ? 'map/' : null,
                                        hint: 'The resource to which this Cloud IP is "mapped" i.e to which packets are forwarded to',
                                        skeleton: <SkeletonChip />,
                                        settingsBtn: cloudIp
                                            ? {
                                                children: mapped ? 'Re-map' : 'Map',
                                                kind: 'secondary',
                                            }
                                            : null,
                                    },
                                    {
                                        name: 'Reverse DNS',
                                        summary: <span className='select-all'>{cloudIp?.reverse_dns}</span>,
                                        route: cloudIp ? reverse_dns.editUri : null,
                                    }
                                ],
                                [
                                    {
                                        name: 'Cost',
                                        summary: <MonthlyPrice price={getMonthlyPrice('cloudip')} />,
                                    }
                                ]
                            ]}
                        />
                    </Panel>

                    <CipPortTranslators
                        cacheStatus={resource.status}
                        editUri={port_translators.editUri}
                        portTranslators={cloudIp?.port_translators}
                    />
                </>
            }
            editors={[
                { editor: port_translators, render: () => <CipPortTranslatorsEdit editor={port_translators} cacheStatus={resource.status} /> },
                { editor: reverse_dns, render: () => <CipReverseDnsEdit editor={reverse_dns} cloudIp={cloudIp} /> },
                { editor: name, render: () => <NameEditPanel editor={name} />, },
                { editor: mapping, render: () => <CipMapResource editor={mapping} />, },
            ]}
        />
    );
};