// @flow

import type { BbCollectedImage, } from '../api/type';
import type { GroupedChoices } from '../component/element/lib/groupedItemState';

export const filterSupportImageTypes = (image: BbCollectedImage): boolean => (
    image.official
    && !/uefi/i.test(image.name)
    && !/minimal/i.test(image.name)
);

export const archMap = {
    'i686': 'x32',
    'x86_64': 'x64',
};

const isUbuntuLts = (version: ?Array<string>) => Array.isArray(version) && version.length > 1 && (Number(version[0]) % 2) === 0 && version[1] === '04';

export const getSupportedImages = (allImages: $ReadOnlyArray<BbCollectedImage>): GroupedChoices<BbCollectedImage> => {
    const result = {
        'ubuntu': {  label: 'Ubuntu', items: [], default: null, },
        'almalinux': {  label: 'AlmaLinux', items: [], default: null, },
        'debian': {  label: 'Debian', items: [], default: null, },
        'centos': {  label: 'CentOS', items: [], default: null, },
        'fedora': {  label: 'Fedora', items: [], default: null, },
        'flatcar': { label: 'Flatcar', items: [], default: null, },
        'coreos': {  label: 'Fedora CoreOS', items: [], default: null, },
        'freebsd': { label: 'FreeBSD', items: [], default: null, },
        'windows': { label: 'Windows', items: [], default: null, },
    };

    allImages
        .filter(filterSupportImageTypes)
        .reduce((acc, image: BbCollectedImage) => {
            let name = image.name.split('-');
            let distro = name[0].toLowerCase();
            if (distro === 'fedora' && name.length > 1 && name[1] === 'coreos') distro = 'coreos';
            switch(distro) {
            case 'ubuntu':
                if (name.length < 3) return acc;

                acc['ubuntu'].items.push({
                    id: image.id,
                    label: name[2] + ' ' + name[1].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[2],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'centos':
                if (name.length < 3) return acc;

                acc['centos'].items.push({
                    id: image.id,
                    label: name[3].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + name[1] + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'almalinux':
                if (name.length < 3) return acc;

                acc['almalinux'].items.push({
                    id: image.id,
                    label: name[3].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + name[1] + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'debian':
                if (name.length < 3) return acc;

                acc['debian'].items.push({
                    id: image.id,
                    label: name[1] + ' ' + name[3].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'fedora':
                if (name.length < 4) return acc;

                acc['fedora'].items.push({
                    id: image.id,
                    label: name[1] + ' ' + name[3].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'coreos':
                if (name.length < 5) return acc;

                acc['coreos'].items.push({
                    id: image.id,
                    label: name[2] + ' ' + name[4].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[2],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'flatcar':
                if (name.length < 3) return acc;

                acc['flatcar'].items.push({
                    id: image.id,
                    label: name[1].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            case 'freebsd':
                if (name.length < 4) return acc;

                acc['freebsd'].items.push({
                    id: image.id,
                    label: name[1] + ' ' + name[3].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                    version: name[1],
                    arch: image.arch,
                    value: image,
                });
                break;
            default:
                name = image.name.split(' ');
                distro = name[0].toLowerCase();
                switch(distro) {
                case 'coreos':
                    if (name.length < 2) return acc;

                    acc['coreos'].items.push({
                        id: image.id,
                        label: name[1] + ' ' + name[0].replace(/^[a-z]/, (x) => x.toUpperCase()) + ' ' + (archMap[image.arch] || 'unknown'),
                        version: name[1],
                        arch: image.arch,
                        value: image,
                    });
                    break;
                case 'windows':
                    if (name.length < 2) return acc;

                    acc['windows'].items.push({
                        id: image.id,
                        label: image.name.split(' ').slice(1).join(' ') + ' ' + (archMap[image.arch] || 'unknown'),
                        version: name[1],
                        arch: image.arch,
                        value: image,
                    });
                    break;
                default:
                    // can't help!
                    break;
                }
            }

            return acc;
        }, result);

    Object.keys(result).forEach((key: string) => {
        const distro = result[key];
        if (distro.items.length === 0) {
            // $FlowFixMe the returned type needs to have all the SupportedImages properties, strictly.
            delete result[key];
            return;
        }

        distro.items.sort((a, b): number => {
            const na = Number(a.version);
            const nb = Number(b.version);
            if (!isNaN(na) && !isNaN(nb)) {
                return nb - na;
            }
            return b.version.localeCompare(a.version);
        });

        if (key === 'ubuntu') {
            distro.items.forEach(imageDetails => {
                if (isUbuntuLts(imageDetails.version.split('.'))) {
                    const name = imageDetails.label.split(' ');
                    imageDetails.label = name[0] + ' LTS ' + name.slice(1).join(' ');
                    if (distro.default === null) {
                        distro.default = imageDetails.value;
                    }
                }
            });
        } else if (key === 'centos') {
            distro.items.forEach(imageDetails => {
                if (
                    distro.default === null
                    && imageDetails.label.split(' ')[0] === 'Server'
                ) {
                    distro.default = imageDetails.value;
                }
            });
        } else if (key === 'fedora') {
            distro.items.forEach(imageDetails => {
                if (
                    distro.default === null
                    && imageDetails.label.split(' ')[1] === 'Server'
                ) {
                    distro.default = imageDetails.value;
                }
            });
        } else if (key === 'flatcar') {
            distro.items.forEach(imageDetails => {
                if (imageDetails.version === 'lts') {
                    const name = imageDetails.label.split(' ');
                    imageDetails.label = 'LTS ' + name.slice(1).join(' ');
                    if (distro.default === null) {
                        distro.default = imageDetails.value;
                    }
                }
            });
        } else {
            if (distro.items.length) {
                distro.default = distro.items[0].value;
            }
        }
    });

    // another cast through any.
    // in this case, we're changing the Array<> that we build
    // here to a $ReadOnlyArray as far as our callers are concerned.
    // this is just the simplest way to avoid having
    // to recreate arrays for no reason other than to satisfy flowtype.
    return ((result: any): GroupedChoices<BbCollectedImage>);
};