// @flow
import { useMemo } from 'react';
import { formatDate, formatGbp, Status } from '../../element/Styled';
import { parseIsoDate } from '../../../lib/date';
import { Table, Tr, Td, Th } from '../../element/Table';
import { Panel, PanelBar, PanelHeader } from '../../element/Panel';
import { usePager } from '../../element/ArrayPager';
import { PagerBar } from '../../element/PagerBar';
import { Chip, } from '../../element/Chip';
import { Button } from '../../element/Button';
import { useObjectToSortedList } from '../../hoc/ListPage';
import { dateSort, numberSort, stringSort, descNumberSort, } from '../../element/Sort';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from '../../element/Skeleton';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';
import { Link } from 'react-router-dom';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import set from 'date-fns/set';
import add from 'date-fns/add';
import { useCurrentAccount } from '../../hoc/lib';
import { useTrialCreditSummary } from '../../hoc/Billing';

import type { ApolloError } from '@apollo/react-hooks';
import type { AccountBillingData, BbInvoice } from '../../hoc/Billing';
import type { ListSortDef } from '../../hoc/ListPage';

export type InvoiceTableProps = {
    loading: boolean,
    error: ?ApolloError,
    data: ?AccountBillingData,
};

type ParsedInvoice = {
    ...BbInvoice,
    numeric: number,
    createdOn: Date,
    dueOn: Date,
    total: number,
    balance: number,
    warn: boolean,
}

const sortDef: ListSortDef<ParsedInvoice> = {
    name: 'invoices',
    fields: {
        _default: descNumberSort<ParsedInvoice>('numeric'),
        id: numberSort<ParsedInvoice>('numeric'),
        createdOn: dateSort<ParsedInvoice>('createdOn'),
        dueOn: dateSort<ParsedInvoice>('dueOn'),
        status: stringSort<ParsedInvoice>('status'),
        total: numberSort<ParsedInvoice>('total'),
    }
};

const INVOICE_HIGHLIGHT_DAYS = 0;
const NO_INVOICES = [];

type Invoices = { [id: string]: ParsedInvoice };

export const InvoiceTable = ({ loading, error, data }: InvoiceTableProps): React$Node => {
    const account = useCurrentAccount();
    const allInvoices = data?.account?.invoices || NO_INVOICES;
    const invoicesCollection = useMemo((): Invoices => {
        const now = new Date();

        return allInvoices.reduce((acc, rawInvoice) => {
            return {
                ...acc,
                [rawInvoice.id]: {
                    ...rawInvoice,
                    numeric: Number(rawInvoice.number.substr(4)),
                    createdOn: parseIsoDate(rawInvoice.createdOn),
                    dueOn: parseIsoDate(rawInvoice.dueOn),
                    total: parseFloat(rawInvoice.total),
                    balance: parseFloat(rawInvoice.balance),
                    status: rawInvoice.status.toLowerCase(),
                    warn:
                        parseFloat(rawInvoice.balance) > 0
                        && differenceInCalendarDays(now, parseIsoDate(rawInvoice.dueOn)) > INVOICE_HIGHLIGHT_DAYS
                }
            };
        }, ({}: Invoices));
    }, [allInvoices]);
    const { items: allItems, Th: SortTh } = useObjectToSortedList(sortDef, invoicesCollection);

    const { items, pager } = usePager('invoices', allItems, data?.account?.invoices?.length || 0);

    const firstInvoiceDate: ?Date = account ? set(add(account.created_at, { months: 1 }), { hours: 0, minutes: 0, seconds: 0 }) : null;

    const trialCredit = useTrialCreditSummary(data?.account?.billingActivity?.categories);
    const trialRemaining = trialCredit?.[2];

    return (
        <Panel>
            <PanelHeader
                title='Invoices'
            />
            <PanelBar padding={false}>
            <Table>
                <thead>
                <Tr>
                    <SortTh field='id'>ID</SortTh>
                    <SortTh field='createdOn'>Date</SortTh>
                    <SortTh field='total'>Amount</SortTh>
                    <SortTh field='status'>Status</SortTh>
                    <Th actions={true}>&nbsp;</Th>
                </Tr>
                </thead>
                <tbody>
                {loading
                    ? <SkeletonListLoadingEntries>
                        <Tr>
                            <Td resourceName={true}><SkeletonChip /></Td>
                            <Td><SkeletonBar size='sm' /></Td>
                            <Td><SkeletonBar size='md' /></Td>
                            <Td><SkeletonBar size='sm' /></Td>
                            <Td actions={true}><SkeletonBar size='sm' /></Td>
                        </Tr>
                    </SkeletonListLoadingEntries>
                    : null}
                {error ? <Tr>
                    <Td colSpan='5'>Error: {error.message}</Td>
                </Tr> : null}
                {!loading && data ? items.map(invoice =>
                    <Tr key={invoice.id} highlighted={invoice.warn}>
                        <Td resourceName={true}>
                            <Chip
                                id={invoice.number}
                                name={'Invoice ' + invoice.numeric}
                                icon='resource/invoice'
                            />
                        </Td>
                        <Td>{formatDate(invoice.createdOn)}</Td>
                        <Td>&pound;{formatGbp(invoice.total)}</Td>
                        <Td><Status status={invoice.status} />
                            {invoice.balance > 0 && differenceInCalendarDays(new Date(), invoice.dueOn) > 1
                                ? <Pill>{differenceInCalendarDays(new Date(), invoice.dueOn)} days</Pill>
                                : null
                            }
                        </Td>
                        <Td actions={true}>
                            {invoice.balance > 0
                                ? <Link to={`payment/?amount=${invoice.balance}`}><Button kind='primary' size='sm' className='mr-2'>Pay Invoice</Button></Link>
                                : null
                            }
                            <a href={invoice.downloadUrl}>
                                <Button kind='tertiary' size='sm' type='button'>Download PDF</Button>
                            </a>
                        </Td>
                    </Tr>
                ) : null}
                {!loading && data && allInvoices.length === 0
                    ? <Tr empty={true}>
                        <Td colSpan='5' empty={true}>
                            {firstInvoiceDate
                                ? <span>
                                    Your first invoice will be issued around {formatDate(firstInvoiceDate)}
                                    {trialRemaining && trialRemaining > 0
                                        ? <Tooltip overlay={
                                            <div>
                                                You currently have &pound;{formatGbp(trialRemaining)} Free Trial Credit remaining and will only
                                                be billed if you use up all of this credit before {formatDate(firstInvoiceDate)} 
                                            </div>
                                        }>
                                            <Pill>?</Pill>
                                        </Tooltip>
                                        : null
                                    }
                                </span>
                                : "You don't currently have any Invoices"
                            }
                        </Td>
                    </Tr>
                    : null}
                </tbody>
            </Table>
            </PanelBar>
            <PagerBar {...pager} />
        </Panel>
    );
};