// @flow

import { Button } from '../../element/Button';
import { Link } from 'react-router-dom';

export function NoAccounts(): React$Node {
    return (
        <div className='p-12'>
            <p className='text-gray-600 max-w-xl'>You need to complete account signup
            before you can create any cloud resources.</p>
            <p className='mt-8'>
                <Link to="/signup/account">
                    <Button kind='primary'>Continue Account Sign-up</Button>
                </Link>
            </p>
        </div>
    );
}
