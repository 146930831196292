// @flow

import { Panel, PanelHeader, PanelSettingsBar } from '../../element/Panel';
import { Notice } from '../../element/Notice';
import { UserDataSummary } from './UserDataEditor';

import type { EditorModal } from '../../common/Editor';
import type { BbZone } from '../../../api/type';
import type { BbServer, UserDataEdit } from '../../../api/type.srv';
import type { SelectedZone } from './ZoneSelector';
import type { ResourceCacheStatus } from '../../../state/resource/type';
import type { SettingsItemProps } from '../../element/Setting/Settings';

type SrvAdvancedProps = {
    +cacheStatus?: ResourceCacheStatus,
    +zone: [?BbZone, EditorModal<SelectedZone, null, BbServer>],
    +user_data: [UserDataEdit, EditorModal<UserDataEdit, ?string, BbServer>],
    +user_ssh_key: ?[string, EditorModal<string, null, BbServer>],
    +disk_encrypted: [boolean, EditorModal<boolean, null, BbServer>],
    +isBlockStorageServer: ?boolean,
}

export const SrvAdvanced = ({ cacheStatus, zone, user_data, user_ssh_key, disk_encrypted, isBlockStorageServer }: SrvAdvancedProps): React$Node => {
    let settings: Array<SettingsItemProps> = [
        {
            name: 'Zone Placement',
            summary: isBlockStorageServer
                ? 'Auto Allocate'
                : (zone[0] ? zone[0].name : 'Auto Allocate'),
            hint: 'Zones facilitate high availability by ensuring that your Cloud Servers are located on separate physical hosts',
            route: zone[1].editUri,
            routeDisabledTooltip: isBlockStorageServer
                ? <div>Zone selection is currently disabled when using block storage types</div>
                : null
        },
        {
            name: 'User Data',
            summary: user_data[0].value ? <UserDataSummary edit={user_data[0]} /> : 'None',
            route: user_data[1].editUri,
        },
        {
            name: 'Disk Encryption',
            summary: isBlockStorageServer
                ? 'Disabled'
                : (disk_encrypted[0] ? 'Enabled' : 'Disabled'),
            route: disk_encrypted[1].editUri,
            routeDisabledTooltip: isBlockStorageServer
                ? <div>Disk Encryption is not currently supported with Block Storage Volumes (coming soon)</div>
                : null
        }
    ];

    if (user_ssh_key) {
        settings.push({
            name: 'SSH Public Key',
            summary: user_ssh_key[0].substring(0, 20),
            route: user_ssh_key[1].editUri,
            notice: <Notice type='error'>You do not have an SSH key in your profile. This is required to access the new server.</Notice>
        });
    }

    return (
        <Panel>
            <PanelHeader title={'Settings'} />
            <PanelSettingsBar
                cacheStatus={cacheStatus}
                settings={settings}
            />
        </Panel>
    )
}
