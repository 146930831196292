// @flow
import { useEffect, useState, useCallback } from 'react';
import { useEditorErrors, useMessagesDrivenEditor, useEditorDirect } from '../common/Editor';
import { useCurrentAccount } from './lib';
import { useDispatch } from 'react-redux';
import { RC_CACHED, RC_NOT_FOUND, RC_SUCCESS } from '../../state/resource/type';
import { useTriggeredMessages } from './Messages';
import { GB_TO_MB } from '../element/Styled';
import { historyBack, useResourceRoutes, historyNavigateOnCreate } from '../../lib/history';
import { validateVolume } from '../section/server/edit/lib';
import { Link } from 'react-router-dom';
import { useDialog } from '../element/Dialog';
import { ANIMATION_DELAY_MS } from '../element/animation';
import { useResourceFetch } from './ViewResource';
import { TooltipButton } from '../element/Button';
import { VOLUME_LABEL_REGEX } from '../section/volume/edit/lib';
import { useCreateVolume } from './CreateResource';
import { useFeatureLabel } from './Metadata';
import { NBS } from '../../api/feature_labels';

import type { Gigabytes, Megabytes } from '../../api/type.units';
import type { BbVolume, BbVolumeFilesystemType, BbVolumeParams, BbVolumePatchParams } from '../../api/type.volume';
import type { EditorModal, EditorDirect } from '../common/Editor';
import type { ResourceAction, ResourceSimpleAction } from '../../state/resource/type';
import type { DialogState } from '../element/Dialog';
import type { MessageHook } from './Messages';
import type { Dispatch } from 'redux';
import type { BbServer } from '../../api/type.srv';
import type { CreateResource } from './CreateResource';
import type { BbAccount } from '../../api/type.acc';
import type { PanelHeaderActions } from '../element/Panel';
import type { ViewResourceProps } from './ViewResource';

export type EditGigabytes = Gigabytes | string;

export type VolEditFilesystemType = BbVolumeFilesystemType | 'none';

export type VolCreateEdit = {
    +name: string,
    +size: EditGigabytes,
    +filesystem_type: VolEditFilesystemType,
    +filesystem_label: string
};

export function useVolumeResizeEditor(path: string, volume: ?BbVolume, editUri: ?string): [EditorModal<EditGigabytes, ?string, BbVolume>, ?Megabytes, ?React$Node] {
    const account = useCurrentAccount();
    const getRoute = useResourceRoutes();
    const dispatch = useDispatch<ResourceAction<BbVolume, BbVolume>>();
    const { next, messages } = useTriggeredMessages<BbVolume>();
    const errors = useEditorErrors<?string>(null);
    const [value, setValue, state, setState] = useMessagesDrivenEditor<BbVolume, EditGigabytes, ?string>(
        errors,
        path + (editUri || 'resize') + '/',
        volume ? RC_CACHED : RC_NOT_FOUND,
        messages,
        (): ?BbVolume => volume,
        (vol: BbVolume) => '',
    );

    const underMaxSize: boolean = volume != null && volume?.size < window.cgrConfig.VOLUME_LIMITS.max * GB_TO_MB;
    const moreStorageAvailable: boolean = account != null && account.block_storage_used < account.block_storage_limit;

    const disableEditorTooltip: ?React$Node =
              underMaxSize === false ? <div>This volume is already at the maximum size</div>
                  : moreStorageAvailable === false ? <div>Your account is using its full Block Storage allocation. <Link to={getRoute('limit', 'block_storage')}><TooltipButton>Request Increase</TooltipButton></Link></div>
                      : null;

    return [
        {
            status: state === 'editing' ? 'edit' : false,
            editUri: editUri || 'resize',
            value,
            setValue: (val: ?EditGigabytes) => setValue(val),
            messages,

            onCancel: () => {
                setState('initial');
                historyBack();
            },
            onSave: () => {
                if (value != null) {
                    const [error, parsed] = validateVolume(value, account, null, volume?.size);

                    if (error) {
                        errors.setErrors(error);
                    } else if (parsed) {
                        messages.clear();
                        errors.clearErrors();
                        setState('saving');
                        dispatch({
                            type: 'RESOURCE_SIMPLE_ACTION',
                            payload: {
                                id: volume?.id,
                                kind: 'volume',
                                action: 'resize',
                                method: 'POST',
                                params: {
                                    from: volume?.size,
                                    to: parsed * GB_TO_MB,
                                },
                                messagesId: next(),
                            }
                        });
                    }
                }
            },

            ...errors,

        },
        volume?.size,
        disableEditorTooltip,
    ];
}

export type VolumeDetachHook = {
    +dialog: DialogState<BbVolume>,
    +messages: MessageHook<BbVolume>,
}

export function useVolumeDetach(): VolumeDetachHook {
    const { next, messages } = useTriggeredMessages<BbVolume>();
    const dispatch = useDispatch<Dispatch<ResourceSimpleAction<BbVolume>>>();

    const dialog = useDialog<BbVolume>([{
        label: 'Detach Volume',
        kind: 'primary',
        color: 'red',
        onSelect: (volume: ?BbVolume) => {
            if (volume) {
                const messagesId = next();
                dispatch({
                    type: 'RESOURCE_SIMPLE_ACTION',
                    payload: {
                        id: volume.id,
                        kind: 'volume',
                        action: 'detach',
                        messagesId,
                    }
                });
            }
        }
    }]);

    return {
        dialog,
        messages,
    }
}

type VolumeCopyHook = {
    +actions: ?PanelHeaderActions,
    +dialog: DialogState<null>,
    +messages: MessageHook<BbVolume>,
    +accountHasSpace: boolean,
};

export function useVolumeCopy(resource: ViewResourceProps<BbVolume, BbVolumePatchParams>): VolumeCopyHook {
    const account: ?BbAccount = useCurrentAccount();
    const dispatch = useDispatch<Dispatch<ResourceSimpleAction<BbVolume>>>();
    const { next, messages } = useTriggeredMessages();
    const { item: volume } = resource;
    const accountHasSpace = volume == null || account == null || (account.block_storage_limit - account.block_storage_used > volume.size);
    const canCopy = volume != null && volume.status !== 'deleted' && volume.status !== 'creating' && accountHasSpace;
    const dialog = useDialog([{
        kind: 'primary',
        color: 'blue',
        label: 'Copy Volume',
        onSelect: canCopy
            ? () => {
                const messagesId = next();
                dispatch({
                    type: 'RESOURCE_SIMPLE_ACTION',
                    payload: {
                        kind: 'volume',
                        id: resource.id,
                        action: 'copy',
                        params: null,
                        messagesId,
                        method: 'POST',
                    }
                });
            }
            : null
    }]);

    const { resource: copied, status } = messages;
    useEffect(() => {
        if (account != null && copied != null && status === RC_SUCCESS) {
            historyNavigateOnCreate(account, copied.id, 'volume');
        }
    }, [copied, status, account]);

    return {
        actions: { copy: canCopy ? dialog.show : null },
        dialog,
        messages,
        accountHasSpace
    };
}

export type VolumeBootHook = {
    +dialog: DialogState<BbVolume>,
    +messages: MessageHook<BbVolume>,
}

export function useVolumeBoot(): VolumeBootHook {
    const { next, messages } = useTriggeredMessages<BbVolume>();
    const dispatch = useDispatch<Dispatch<ResourceSimpleAction<BbVolume>>>();

    const dialog = useDialog<BbVolume>([{
        label: 'Change Boot Volume',
        kind: 'primary',
        color: 'red',
        onSelect: (volume: ?BbVolume) => {
            if (volume) {
                const messagesId = next();
                dispatch({
                    type: 'RESOURCE_SIMPLE_ACTION',
                    payload: {
                        id: volume.id,
                        kind: 'volume',
                        action: 'attach',
                        params: {
                            server: volume.server?.id,
                            boot: true,
                        },
                        messagesId,
                    }
                });
            }
        }
    }]);

    return {
        dialog,
        messages,
    }

}

export type VolumeAttachEditorHook = {
    +editor: EditorModal<$ReadOnlyArray<string>, ?React$Node, BbVolume>,
    +confirmDialog: DialogState<null>,
    +selectedServer: ?BbServer,
    +attachedDialog: DialogState<BbVolume>,
};

const MAX_SERVER_VOLUMES = 6;

export function validateVolumeAttachmentToServer(volume: ?BbVolume, server: BbServer, mentionServer: boolean): ?React$Node {
    const conflictingSerial = volume != null
        ? server.volumes.find(attached => attached.id !== volume.id && attached.serial === volume.serial)
        : null;

    if (conflictingSerial != null) {
        return <p>The Volume cannot be attached to {server.id} because its serial <b className="font-mono">{conflictingSerial.serial}</b> is already
            in use by another attached Volume ({conflictingSerial.id}).</p>;
    }

    if (server.volumes.length === MAX_SERVER_VOLUMES) {
        return mentionServer
            ? <p>The Volume cannot be attached because {server.id} already has the maximum of {MAX_SERVER_VOLUMES} attached volumes</p>
            : <p>This Server already has the maximum of {MAX_SERVER_VOLUMES} attached Volumes</p>;
    }

    return null;
}

type ValidateVolumeAttachmentHook = {
    +selectionIsValidated: boolean,
    +selectedServer: ?BbServer,
    +selectedVolume: ?BbVolume,
}

type ValidateVolumeState = [
    ?string,     // ?serverId
    ?string,     // ?volumeId
    ?React$Node  // ?error for UI
];

function useValidateVolumeAttachment(serverId: ?string, volumeId: ?string, setErrors: (e: ?React$Node) => void): ValidateVolumeAttachmentHook {
    const { item: server, fetch: fetchServer } = useResourceFetch('server', serverId);
    useEffect(() => {
        if (serverId != null) fetchServer();
    }, [serverId, fetchServer]);

    const { item: volume, fetch: fetchVolume } = useResourceFetch('volume', volumeId);
    useEffect(() => {
        if (volumeId != null) fetchVolume();
    }, [volumeId, fetchVolume]);

    const [validated, setValidated] = useState<ValidateVolumeState>([null, null, null]);

    useEffect(() => {
        let nextValidated: ValidateVolumeState = [serverId, volumeId, null];
        if (server != null && server.id === serverId && volume != null && volume.id === volumeId) {
            nextValidated[2] = validateVolumeAttachmentToServer(volume, server, true);
        }
        setValidated(nextValidated);
    }, [serverId, volumeId, volume, server, setErrors]);

    const selectionIsValidated: boolean =
        serverId != null && volumeId != null
        && validated[0] === serverId && validated[1] === volumeId
    ;
    const currError = validated[2];

    useEffect(() => {
        if (selectionIsValidated) setErrors(currError);
        else setErrors(null);
    }, [selectionIsValidated, currError, setErrors]);

    return { selectionIsValidated, selectedServer: server, selectedVolume: volume, };
}

function useValidateAndConfirmAttachment(
    messages: ?MessageHook<BbVolume>,
    attachedDialog: DialogState<BbVolume>,
    editor: EditorModal<$ReadOnlyArray<string>, ?React$Node, BbVolume>,
    serverId: ?string,
    volumeId: ?string
) {
    const messagesStatus = messages?.status;
    const { show } = attachedDialog;

    const { selectionIsValidated, selectedServer, selectedVolume, } = useValidateVolumeAttachment(serverId, volumeId, editor.setErrors);

    useEffect(() => {
        if (messagesStatus === RC_SUCCESS && selectedVolume != null) {
            // wait for the page transition animation to finish...
            setTimeout(() => show(selectedVolume), ANIMATION_DELAY_MS);
        }
    }, [messagesStatus, show, selectedVolume]);

    const confirmDialog = useDialog(
        editor.errors == null
            ? [{
                color: 'blue',
                kind: 'primary',
                label: 'Attach Volume',
                onSelect: selectionIsValidated
                    ? () => {
                        if (editor.status === 'edit') editor.onSave();
                    }
                    : null,
            }]
            : []
    );
    return { selectedServer, confirmDialog };
}

function useVolumeAttachEditor(matchPath: string, getIds: (EditorModal<$ReadOnlyArray<string>, ?React$Node, BbVolume>) => [?string, ?string]): EditorModal<$ReadOnlyArray<string>, ?React$Node, BbVolume> {
    const dispatch = useDispatch<Dispatch<ResourceSimpleAction<BbVolume>>>();
    const errors = useEditorErrors<?React$Node>(null);
    const { next, messages } = useTriggeredMessages<BbVolume>();

    const [value, setValue, state, setState] = useMessagesDrivenEditor<BbVolume, $ReadOnlyArray<string>, ?React$Node>(
        errors,
        matchPath + 'attach/',
        RC_CACHED,
        messages,
        // hack here; this editor is the only one so far where we don't want to edit the existing value;
        // we know that the initial value for 'attached server' must be null. so to avoid creating
        // another specialisation of useMessagesDrivenEditor, just return true here and tell flow
        // that everything is fine.
        // $FlowFixMe as long as onEdit below just returns () => [], this can be hacked to be true
        () => true,
        // we always start with no volume selected
        () => [],
    );

    const editor = {
        status: state === 'editing' ? 'edit' : false,
        editUri: 'attach',
        messages,
        ...errors,

        value, setValue,

        onCancel: () => {
            setState('initial');
            historyBack();
        },
        onSave: () => {
            const [volumeId, serverId] = getIds(editor);
            if (value != null && value.length && volumeId != null && serverId != null) {
                const messagesId = next();
                setState('saving');
                dispatch({
                    type: 'RESOURCE_SIMPLE_ACTION',
                    payload: {
                        id: volumeId,
                        kind: 'volume',
                        action: 'attach',
                        params: { server: serverId, },
                        messagesId,
                        suppressErrorToast: true,
                    }
                });
            } else {
                setState('closing');
                historyBack();
            }
        },
    };

    return editor;
}

export const useVolumeToServerAttachEditor = (volume: ?BbVolume, matchPath: string): VolumeAttachEditorHook => {
    const attachedDialog = useDialog<BbVolume>([]);

    const editor = useVolumeAttachEditor(
        matchPath,
        (editor) => [volume?.id, editor?.value?.[0]]
    );

    const { selectedServer, confirmDialog } = useValidateAndConfirmAttachment(editor.messages, attachedDialog, editor, editor?.value?.[0], volume?.id);

    return {
        confirmDialog,
        editor,
        selectedServer,
        attachedDialog,
    };
};

export const useServerAttachVolumeEditor = (server: ?BbServer, matchPath: string): VolumeAttachEditorHook => {
    const attachedDialog = useDialog<BbVolume>([]);

    const editor = useVolumeAttachEditor(
        matchPath,
        (editor) => [editor?.value?.[0], server?.id]
    );

    const { selectedServer, confirmDialog } = useValidateAndConfirmAttachment(editor.messages, attachedDialog, editor, server?.id, editor?.value?.[0]);

    return {
        confirmDialog,
        editor,
        selectedServer,
        attachedDialog,
    };
};

type VolErrors = { size: ?string, name: ?string, filesystem_label: ?string, };

const emptyVolErrors: VolErrors = Object.freeze({ size: null, name: null, filesystem_label: null, });

const MIN_SIZE_VALIDATION_MESSAGE = 'Volumes must be at least ' + window.cgrConfig.VOLUME_LIMITS.min + 'GB';

type CreateVolumeFormHook = {
    +create: CreateResource<BbVolume, BbVolumeParams>,
    +name: EditorDirect<string, ?string>,
    +size: EditorDirect<EditGigabytes, ?string>,
    +filesystem_type: EditorDirect<VolEditFilesystemType, ?string>,
    +filesystem_label: EditorDirect<string, ?string>,

    +validateAndCreate: () => void,

    +availableBlockStorage: number,
    +parsedSize: number,
    +createDisabled: boolean,
    +availableBelowMin: boolean,

    +clear: () => void,
}
const DEFAULT_PARAMS = Object.freeze({
    name: '',
    size: window.cgrConfig.VOLUME_LIMITS.min,
    filesystem_type: 'ext4',
    filesystem_label: '',
});

export function useCreateVolumeForm(navigate: boolean): CreateVolumeFormHook {
    const create = useCreateVolume(navigate);
    const [errors, setErrors] = useState<VolErrors>(emptyVolErrors);
    const account: ?BbAccount = useCurrentAccount();

    const availableBlockStorage: Megabytes = account
        ? account.block_storage_limit - account.block_storage_used
        : -1; // just pretend there's no limit if we haven't grabbed the account yet.

    const createParamsState = useState<VolCreateEdit>(DEFAULT_PARAMS);

    const [createParams, setCreateParams] = createParamsState;

    const name = useEditorDirect<string, ?string>(
        createParams.name,
        (name: string) => setCreateParams({ ...createParams, name }),
        errors.name
    );

    const size = useEditorDirect<EditGigabytes, ?string>(
        createParams.size,
        (size: EditGigabytes) => setCreateParams({ ...createParams, size }),
        errors.size
    );
    const filesystem_type = useEditorDirect<VolEditFilesystemType, ?string>(
        createParams.filesystem_type,
        (filesystem_type: VolEditFilesystemType) => setCreateParams({ ...createParams, filesystem_type }),
        null,
    );
    const filesystem_label = useEditorDirect<string, ?string>(
        createParams.filesystem_label,
        (filesystem_label: string) => setCreateParams({ ...createParams, filesystem_label }),
        errors.filesystem_label,
    );

    const validateAndCreate = () => {
        let errors: VolErrors = { ...emptyVolErrors };

        if (isNaN(parseFloat(createParams.size))) {
            errors.size = 'Please enter a valid size in GB';
        } else if (createParams.size < window.cgrConfig.VOLUME_LIMITS.min) {
            errors.size = MIN_SIZE_VALIDATION_MESSAGE;
        }

        if (createParams.filesystem_label !== '' && !createParams.filesystem_label.match(VOLUME_LABEL_REGEX)) {
            errors.filesystem_label = 'Please enter a valid filesystem label';
        }

        setErrors(errors);

        if (errors.size == null && errors.name == null && errors.filesystem_label == null) {
            let params: BbVolumeParams = {
                name: createParams.name,
                size: parseFloat(createParams.size) * GB_TO_MB,
            };
            if (createParams.filesystem_type !== 'none') params.filesystem_type = createParams.filesystem_type;
            if (createParams.filesystem_label !== '') params.filesystem_label = createParams.filesystem_label;

            create.createResource(params);
        }
    };

    const parsedSize: number = parseFloat(createParams.size);
    const createDisabled = isNaN(parsedSize) || (availableBlockStorage < (parsedSize * GB_TO_MB));

    const clear = useCallback(() => {
        setCreateParams(DEFAULT_PARAMS);
    }, [setCreateParams]);

    const availableBelowMin = (account != null && (account.block_storage_limit - account.block_storage_used) < window.cgrConfig.VOLUME_LIMITS.min * GB_TO_MB);

    return {
        create,
        name,
        size,
        filesystem_type,
        filesystem_label,
        validateAndCreate,

        availableBlockStorage,
        parsedSize,
        createDisabled,
        availableBelowMin,

        clear
    };
}

export function useZoneSupportsBlockStorage(serverZoneId: ?string): boolean {
    const { fetch, item: zone } = useResourceFetch('zone', serverZoneId);
    useEffect(fetch, [fetch]);
    return useFeatureLabel(NBS, zone);
}