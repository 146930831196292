// @flow

import { Tr, Td } from './Table';
import { isShowDetailsResourceStatus } from './Skeleton';
import { LABELS } from './ResourceLabels';

import type { ResourceSearchHook } from './Search';
import type { TableContext } from './Table';
import type { LabelIndex } from './ResourceLabels';

/*****
 * No matching resources - when they're loaded but all filtered out.
 */

export type NoMatchingResourceTextProps = {
    +search: ResourceSearchHook<any>,
    +resourceName?: ?React$Node
}

export const NoMatchingResourceText = ({ search, resourceName }: NoMatchingResourceTextProps): React$Node => (
    typeof search.searchTerm !== 'string' || search.searchTerm === ''
        ? (<>No {resourceName || 'resources'} match selected filters</>)
        : (<>No {resourceName || 'resources'} match "{search.searchTerm}"</>)
);

export type NoMatchingResourceTrProps = {
    +search: ?ResourceSearchHook<any>,
    +colSpan: number,
    +kind: LabelIndex,
} | {
    +search: ?ResourceSearchHook<any>,
    +colSpan: number,
    +title: React$Node,
}

export const NoMatchingResourceTr = ({ search, colSpan, ...rest }: NoMatchingResourceTrProps): React$Node => (
    search && search?.filterRemovedAllItems
        ? <Tr empty={true}>
            <Td empty={true} colSpan={colSpan}>
                <NoMatchingResourceText search={search} resourceName={rest.kind ? LABELS[rest.kind]?.plural : rest.title} />
            </Td>
        </Tr>
        : null
);

type NoMatchingResourceNoticeProps = {
    +search: ?ResourceSearchHook<any>,
    +kind: LabelIndex,
}

export const NoMatchingResourceNotice = ({ search, kind }: NoMatchingResourceNoticeProps): React$Node => (
    search?.filterRemovedAllItems
        ? <p className='text-gray-600 text-sm py-6'>
            <NoMatchingResourceText search={search} resourceName={LABELS[kind]?.plural} />
        </p>
        : null
);

/*****
 * No resources - when there just aren't any in the account
 */

export type NoResourcesTextProps = {
    +title: React$Node,
    +context: TableContext,
};

export const NoResourcesText = ({ context, title, }: NoResourcesTextProps): React$Node => (
    <>
        {context === 'list' /* eg server list - "You don't currently have any cloud servers" */
            ? <>You don't currently have any {title}</>
            : null
        }
        {context === '' /* eg port translators edit list - "No port translators" */
            ? <>No {title}</>
            : null
        }
        {context === 'selector' /* eg attach volume to server - "No port translators" */
            ? <>No {title}</>
            : null
        }
        {context !== 'list' && context !== 'selector' && context !== '' /* eg cloud ip list - "No cloud IPs assigned" */
            ? <>No {title} {context}</>
            : null
        }
    </>
);

export type NoResourcesTrProps = {
    +status: number | Array<number>,
    +colSpan: number,
    +totalCount: ?number,
    +context: TableContext,
    +kind: LabelIndex,
} | {
    +status: number | Array<number>,
    +colSpan: number,
    +totalCount: ?number,
    +context: TableContext,
    +title: React$Node,
};

function getTitle(kind: ?LabelIndex, title: ?React$Node, context: TableContext): React$Node {
    return kind
        ? (LABELS[kind]?.plural || 'resources') + (kind === 'volume' && context === 'selector' ? ' available in this zone' : '')
        : title || 'resources';
}

export const NoResourcesTr = ({ status, colSpan, totalCount, context, ...rest }: NoResourcesTrProps): React$Node =>  (
    isShowDetailsResourceStatus(status) && totalCount === 0
        ? <Tr empty={true}>
            <Td empty={true} colSpan={colSpan}>
                <NoResourcesText context={context} title={getTitle(rest.kind, rest.title, context)} />
            </Td>
        </Tr>
        : null
);

type NoResourcesNoticeProps = {
    +status: number | Array<number>,
    +totalCount: ?number,
    +context: TableContext,
    +kind: LabelIndex,
}

export const NoResourcesNotice = ({ status, totalCount, kind, context, }: NoResourcesNoticeProps): React$Node => (
    isShowDetailsResourceStatus(status) && totalCount === 0
        ? <p className='text-gray-600 text-sm py-6'>
            <NoResourcesText context={context} title={getTitle(kind, null, context)} />
        </p>
        : null
);

