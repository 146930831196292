// @flow strict
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFakeOwnerCollab, useCurrentAccountId } from './lib';
import { SvgIcon } from '../element/SvgIcon';
import { RC_CACHED, RC_INITIAL } from '../../state/resource/type';
import { useResourceFetch } from './ViewResource';
import { BbResourceKinds } from '../../api/type';

import type { BbAllResourceTypes, BbResourceKind, BbCollectedResourceTypes } from '../../api/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { ResourceAction, ResourceCollection, ResourceFetched, } from '../../state/resource/type';
import type { CloudIpAction, } from '../../state/CloudIp/type';
import type { SortFields } from '../element/Sort';
import type { SortData, SortOrder, UiSortToggleField } from '../../state/Sort/type';
import type { BbAccount } from '../../api/type.acc';

export type ThOwnProps = {
    +field: string,
    +children: React$Node,
    +colSpan?: string | number,
    +className?: string,
};

export type ListSortDef<Collected> = {
    +name: string,
    +fields: SortFields<Collected>,
};

export type SortedObjectList<C: Object> = {
    items: Array<C>,
    Th: React$StatelessFunctionalComponent<ThOwnProps>,
}

export type ListPageProps<C: Object> = {
    ...SortedObjectList<C>,
    status: ResourceFetched,
    forceRefresh: () => void,
};

function getSortDir(field: string, sort: ?SortData) {
    if (sort && sort.field === field) {
        return sort.order;
    }
    return null;
}

function sortedArray<C>(uiSort: SortData, resources: $ReadOnlyArray<C>, sort: ListSortDef<C>): Array<C> {
    let sorter = sort.fields._default;
    let dir: SortOrder = 'asc';
    if (uiSort) {
        sorter = sort.fields[uiSort.field];
        dir = uiSort.order;
    }
    if (!sorter) {
        sorter = sort.fields._default;
        dir = 'asc';
    }

    return Array.from(resources).sort((a, b) => sorter(a, b) * (dir === 'asc' ? 1 : -1));
}

export const useObjectToSortedList = <Collected>(sort: ListSortDef<Collected>, resources: { [key: string]: Collected }): SortedObjectList<Collected> => {
    const unsortedItems = useMemo(() => Object.keys(resources).map(x => resources[x]), [resources]);
    return useSortedItems(sort, unsortedItems);
}

export function useSortedItems<Collected>(sort: ListSortDef<Collected>, unsortedItems: $ReadOnlyArray<Collected>): SortedObjectList<Collected> {
    const { name: sortName, fields } = sort;

    const currentSort = useSelector<CloudGuiState, any>((state: CloudGuiState) => state.Ui.sort[sort.name]);
    const dispatch = useDispatch<UseListDispatch<Collected>>();

    const items = useMemo(
        () => sortedArray<Collected>(currentSort, unsortedItems, sort),
        [currentSort, unsortedItems, sort]
    );

    const boundTh = useMemo(() => ({ field, children, className: extraClasses, ...rest }: ThOwnProps) => {
        const sortDir = getSortDir(field, currentSort);
        const prefixClass = 'c-resource-list__header';
        const sortableClass = fields[field] != null ? prefixClass+'--sortable ' : '';
        const sortedClass = sortDir != null ? (prefixClass + '--sorted-' + sortDir) : '';

        return (
            <th
                {...rest}
                className={prefixClass + ' ' + sortableClass + sortedClass + (extraClasses ? ' ' + extraClasses : '')}
            >
                <span className='c-resource-list__sort' onClick={() => dispatch({ type: 'SORT_TOGGLE_FIELD', payload: { sortName, field } })}
                >
                    {children}
                    {sortDir === 'asc'
                        ? <SvgIcon svg={'caret-up'} className='c-resource-list__sort-icon' />
                        : sortDir === 'desc' || sortableClass
                            ? <SvgIcon svg={'caret-down'} className='c-resource-list__sort-icon' />
                            : null
                    }
                </span>
            </th>
        );
    }, [currentSort, sortName, dispatch, fields]);

    return { items, Th: boundTh };
}

type UseListDispatch<Resource> = Dispatch<ResourceAction<BbAllResourceTypes, Resource> | CloudIpAction | UiSortToggleField>;

function useCollected<Collected: BbCollectedResourceTypes>(kind: BbResourceKind, resources: ResourceCollection<any, Collected>): { [id: string]: Collected } {
    const currAccount = useSelector<CloudGuiState, BbAccount>((state) => {
        const { Resource, Auth } = state;
        return Resource.account.full[Auth.currAccountId];
    })

    const { collected } = resources;

    return useMemo(() => {
        if (kind === 'collaboration') {
            let ownerCol = getFakeOwnerCollab(currAccount);
            const owner: ?{ [id: string]: Collected } = ownerCol
                // $FlowFixMe kind === 'collaboration' check here means this is valid
                ? { 'col-owner': ownerCol,  }
                : null;

            return {
                ...collected,
                ...owner
            };
        } else {
            return collected;
        }
    }, [kind, currAccount, collected]);
}

export const useListResource = <Collected: Object>(kind: BbResourceKind, sort: ListSortDef<Collected>): ListPageProps<Collected> => {
    const resources = useSelector<CloudGuiState, ResourceCollection<any, Collected>>((state: CloudGuiState) =>
        ((state.Resource[kind]: any): ResourceCollection<any, Collected>),
    );
    const { items, Th: boundTh } = useObjectToSortedList<Collected>(sort, useCollected<Collected>(kind, resources));

    const accountId = useCurrentAccountId();

    const dispatch = useDispatch<UseListDispatch<Collected>>();
    useEffect(() => {
        if (resources.fetched === RC_INITIAL) {
            dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind, } });
        }
    }, [accountId, dispatch, kind, resources.fetched]);

    return {
        items: resources.fetched === RC_CACHED ? items : [],
        status: resources.fetched,
        Th: boundTh,
        forceRefresh: () => {
            dispatch({ type: 'RESOURCE_COLLECTED_STATUS', payload: { kind, fetched: RC_INITIAL } });
        }
    };
};

export type ResourcesById<Collected: Object> = {
    resources: { [id: string]: Collected },
    cacheStatus: ResourceFetched,
};

export const useResourcesById = <Collected: Object>(kind: BbResourceKind): ResourcesById<Collected> => {
    const accountId = useCurrentAccountId();

    const dispatch = useDispatch<UseListDispatch<Collected>>();
    useEffect(() => {
        dispatch({ type: 'RESOURCE_FETCH_COLLECTED', payload: { kind, } });
    }, [accountId, dispatch, kind]);

    return useSelector<CloudGuiState, any>((state: CloudGuiState) => ({
        resources: state.Resource[kind].collected,
        cacheStatus: state.Resource[kind].fetched,
    }));
}

export function useSearchOnResourceId(kind: BbResourceKind, id: ?string) {
    const { fetch } = useResourceFetch(kind, id);

    useEffect(() => {
        if (id != null && fetch) {
            const matches = (new RegExp(`${BbResourceKinds[kind]}-[0-9a-z]{5}`)).exec(id);
            if (matches) fetch();
        }
    }, [kind, id, fetch]);
}
