// @flow

import { useMemo } from 'react';
import numeral from 'numeral';
import { formatGbp } from '../../element/Styled';
import { Separator } from '../../element/Separator';

import type { BillingActivity } from '../../hoc/Billing';

export type BillingActivityProps = {
    +activity: ?BillingActivity,
    +showingTrial: boolean,
}

const nameMap = {
    'Tax': 'VAT',
    'Discounts': 'Discounts & Credits',
    'Database Servers': 'Cloud SQL',
    'Free trial credit': 'Free Trial Credit used',
    'Orbit Storage (GB/month)': 'Orbit Storage',
    'Block Storage (GB/month)': 'Block Storage',

    // handle existing order items with odd naming (see #24306)
    'Network Volume Storage (GB)': 'Block Storage',
    'Orbit Storage (GB)': 'Orbit Storage',
    'Orbit storage (GB/month)': 'Orbit Storage',
};

type BillingRow = {
    name: string,
    amount: typeof numeral,
    type: 'discount' | 'charge' | 'total',
};

export const BillingActivityDisplay = ({ activity, showingTrial, }: BillingActivityProps): React$Node => {
    const categories = activity?.categories || null;
    const totalAmount = activity?.totalAmount;

    const [categoriesTotal, hasBilledActivity] = useMemo<[$ReadOnlyArray<BillingRow>, boolean]>(() => {
        let servers = {
            name: 'Cloud Servers',
            type: 'charge',
            amount: numeral(0),
        };
        let hasBilledActivity = false;

        let categoriesTotal: Array<BillingRow> = categories
            ? categories.reduce((acc, category) => {
                if (category.name === 'Discounts') {
                    return acc.concat(category.items.map(e => ({
                        name: nameMap[e.name] || e.name,
                        amount: numeral(parseFloat(e.amount)),
                        type: 'discount',
                    })));
                }
                if (category.name === 'Storage') {
                    return acc.concat(category.items.map(e => ({
                        name: nameMap[e.name] || e.name,
                        amount: numeral(parseFloat(e.amount)),
                        type: 'charge',
                    })));

                }

                const amount = category.items.reduce((a, i) => a.add(parseFloat(i.amount)), numeral(0));

                hasBilledActivity = hasBilledActivity || amount.value() > 0;

                if (category.name.toLowerCase().startsWith('cloud servers')) {
                    servers.amount.add(amount.value());
                    return acc;
                }

                return acc.concat({
                    name: nameMap[category.name] || category.name,
                    type: amount.value() < 0 ? 'discount' : 'charge',
                    amount,
                });
            }, [])
            : [];

        if (servers.amount.value() > 0) {
            categoriesTotal.unshift(servers);
        }

        categoriesTotal.push({
            name: 'Total',
            type: 'total',
            amount: numeral(totalAmount || '0'),
        });

        return [
            categoriesTotal,
            hasBilledActivity,
        ];
    }, [categories, totalAmount]);

    return (
        <>
            {showingTrial && hasBilledActivity
                ? <div className='mx-5 -mb-2'><Separator solid={true}>Current bill</Separator></div>
                : null
            }
            {hasBilledActivity
                ? <table className="c-billing-summary">
                    <tbody>

                    {categoriesTotal.map((c, i) =>
                        <tr key={i} className={'c-billing-summary__row  c-billing-summary__row--' + c.type}>
                            <td className="c-billing-summary__item">{c.name} {c.type === 'total' ? <i>(updated daily)</i> : null}</td>
                            <td className="c-billing-summary__item">&pound;{formatGbp(c.amount)}</td>
                        </tr>
                    )
                    }
                    </tbody>
                </table>
                : null}
            {!hasBilledActivity && !showingTrial
                ? <div className='m-6 text-gray-600 text-sm text-center'>
                   <i>There isn't currently any usage reported for this billing period</i>
                </div>
                : null
            }
        </>
    );
}
