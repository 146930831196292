// @flow

import { PanelBar } from './Panel';
import { Button } from './Button';
import { useCurrentAccount } from '../hoc/lib';
import { Notice } from './Notice';
import { AccountPending } from './LimitTooltip';

import type { BbAccount } from '../../api/type.acc';

export const PanelApiErrorBar = ({ forceRefresh, singular, plural, }: { forceRefresh?: () => void, singular: string, plural: string, title: string, listTitle?: string, }): React$Node => {
    const account: ?BbAccount = useCurrentAccount();

    if (account?.status === 'pending') {
        return (
            <PanelBar>
                <div className='text-center text-gray-600'>
                    <AccountPending resourceName={singular} />
                </div>
            </PanelBar>
        );
    }
    if (account?.status === 'closed') {
        return (
            <PanelBar>
                <Notice type="error">
                    This account is closed
                </Notice>
            </PanelBar>
        );
    }

    return (
        <PanelBar>
            Error accessing {plural}, please check your network connection
            {forceRefresh
                ? <> and <Button onClick={forceRefresh}>try again.</Button></>
                : '.'
            }
        </PanelBar>
    );
};