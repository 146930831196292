// @flow

import { Checkbox } from '../../../element/Checkbox';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';

import type { FormErrors } from '../../../common/lib';
import type { EditorModal } from '../../../common/Editor';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';
import type { OrbitEditMetaType, ContainerStaticSiteEdit } from '../def';

type OrbitPublicListingEditProps = {
    +editor: EditorModal<OrbitEditMetaType<ContainerStaticSiteEdit>, FormErrors, OrbitContainerMeta>
};


export const ContainerStaticSite = ({ editor, }: OrbitPublicListingEditProps): React$Node => {
    const { value, setValue, } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title='Static Website Mode'
                description='Configure this Orbit container to serve a static HTML website'
            />
            <PanelBar>
                <Checkbox
                    label='Enable Static Website Mode'
                    checked={value.edit.enabled}
                    onChange={(enabled) => setValue({...value, edit: { ...value.edit, enabled } })}
                />
                {value.rawMeta.rlistings.read && value.rawMeta.web.listings == null
                    ? <Notice>Enabling public static site will disable public orbit api listing</Notice>
                    : null
                }

                <div className='flex flex-col'>
                    <TextInput
                        label='Index filename'
                        value={value.edit.index}
                        onChange={(index) => setValue({...value, edit: { ...value.edit, index, genIndex: false } })}
                        disabled={!value.edit.enabled}
                        errorText={editor.errors.get('index')}
                        autoPopulated={value.edit.genIndex}
                        hint={value.edit.enabled ? 'The default index file to be served from the Orbit container root and subdirectories (Required)' : null}
                        className='mb-6'
                    />
                    <TextInput
                        label='Error pages'
                        value={value.edit.error}
                        onChange={(error) => setValue({...value, edit: { ...value.edit, error, genError: false } })}
                        disabled={!value.edit.enabled}
                        autoPopulated={value.edit.genError}
                        hint={value.edit.enabled ? <span>The files served when a 404 or 401 error occurs. <br/><br/><b>Note:</b> The error code is prepended to the filename specified below e.g 404error.html, 401error.html and both files must exist in the Orbit container root</span> : null}
                    />
                </div>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};

