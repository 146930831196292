// @flow
import { useState, useMemo } from 'react';
import { Panel, PanelBar, PanelHeader } from '../element/Panel';
import { Card } from '../element/Card';
import { CardGroup } from '../element/CardGroup';
import { ResourceChip } from '../element/Chip';
import { history, useResourceRoutes } from '../../lib/history';
import { Notice } from '../element/Notice';
import { serverGroupSelectorSearchDef, serverGroupSortFields } from '../section/server_group/def';
import { TextOnlySearchEditor } from './TextOnlySearch';
import { ServerGroupListTable } from '../section/server_group/ServerGroupList';
import { ResourceSelector } from './ResourceSelector';
import { isShowDetailsResourceStatus, SkeletonCardPanel } from '../element/Skeleton';
import { RC_CACHED } from '../../state/resource/type';
import { usePager } from '../element/ArrayPager';
import { PagerBar } from '../element/PagerBar';
import { BlankSortTh } from '../element/Table';
import { useResourcesById, } from '../hoc/ListPage';

import type { BbNestedServerGroup, BbServerGroup, } from '../../api/type';
import type { Editor } from './Editor';
import type { BbServer } from '../../api/type.srv';
import type { ResourceCacheStatus } from '../../state/resource/type';
import type { BbCloudIp } from '../../api/type.cip';

type ServerGroupSelectorProps = {
    +editor: Editor<$ReadOnlyArray<string>, null, BbServer> | Editor<$ReadOnlyArray<string>, null, BbCloudIp>,
};

export const ServerGroupSelector = ({ editor, }: ServerGroupSelectorProps): React$Node => {
    const { value, } = editor;

    return (
        <Panel>
            <PanelHeader
                title='Server Groups'
                description='To which Server Groups should this Cloud Server belong?'
            />
            <ResourceSelector
                selector={{
                    editor
                }}
                kind='server_group'
                sortFields={serverGroupSortFields}
                searchDef={serverGroupSelectorSearchDef}
                searchComponent={TextOnlySearchEditor}
                table={ServerGroupListTable}
                context='selector'
            >
                {Array.isArray(value) && value.length === 0
                    ? <Notice type='warning' icon='warning-fill'>
                        <b>Note</b> – Server Groups are used to allow network access to Cloud Servers via firewall rules, so
                        a Cloud Server won't have any network access until it is added to a Server Group
                    </Notice>
                    : null}
            </ResourceSelector>
        </Panel>
    );
};

type ResourceServerGroupViewProps = {
    cacheStatus: ResourceCacheStatus,
    groups: $ReadOnlyArray<BbNestedServerGroup>,
    editUri: ?string,
}

type ServerGroupListProps = {
    pagedItems: $ReadOnlyArray<BbNestedServerGroup>,
    cacheStatus: ResourceCacheStatus,
    totalCount: number,
}

const ServerGroupList = ({ pagedItems, cacheStatus, totalCount }: ServerGroupListProps) => {
    const { resources, cacheStatus: serverGroupsCacheStatus } = useResourcesById<BbServerGroup>('server_group');
    const items = useMemo((): $ReadOnlyArray<BbServerGroup> => isShowDetailsResourceStatus(serverGroupsCacheStatus)
        ? pagedItems.map(x => (
            resources[x.id]
            || {
                ...x,
                servers: [],
                firewall_policy: null,
            }
        ))
        : [],
        [pagedItems, resources, serverGroupsCacheStatus]
    );

    return (
        <ServerGroupListTable
            items={items}
            status={serverGroupsCacheStatus}
            totalCount={totalCount}
            context='list'
            Th={BlankSortTh}
        />
    );
};

export const ResourceServerGroupView = ({ groups, editUri, cacheStatus, }: ResourceServerGroupViewProps): React$Node => {
    const getRoute = useResourceRoutes();
    const { items, pager } = usePager('resourceServerGroups', groups, groups.length);
    const listGrid = useState('grid');

    return (
        <Panel>
            <PanelHeader
                title='Server Groups'
                actions={editUri ? { edit: () => history.push(editUri + '/'), } : null}
                mode={listGrid}
            />
            {listGrid[0] === 'grid'
                ? <PanelBar>
                    {cacheStatus === RC_CACHED && groups.length === 0
                        ? <Notice>Server is not in any groups.</Notice>
                        : null
                    }
                    <CardGroup>
                        {items.map(server_group => {
                            return (
                                <Card
                                    key={server_group.id}
                                    link={getRoute('server_group', server_group.id)}
                                >
                                    <ResourceChip resource={server_group} link={getRoute('server_group', server_group.id)}/>
                                </Card>
                            );
                        })}
                        {!isShowDetailsResourceStatus(cacheStatus)
                            ? <SkeletonCardPanel/>
                            : null
                        }
                    </CardGroup>

                </PanelBar>
                : null}
            {listGrid[0] === 'list'
                ? <ServerGroupList
                    pagedItems={items}
                    totalCount={groups.length}
                    cacheStatus={cacheStatus}
                />
                : null
            }
            <PagerBar {...pager} />
        </Panel>
    );
};
