// @flow

import { useState } from 'react';
import { ToggleGroup } from '../../element/ToggleGroup';
import { TextInput } from '../../element/Input';
import { Dialog, useDialog } from '../../element/Dialog';
import { getObjectDownloadUrl } from '../../hoc/orbit/Container';
import { useCurrentAccountId } from '../../hoc/lib';
import copy from 'copy-to-clipboard';
import { useRefreshComponent } from '../../common/lib';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import formatDistance from 'date-fns/formatDistance';

import type { OrbitObjectShareParams, OrbitAccessKeysHook } from '../../hoc/orbit/Container';
import type { OrbitObject } from '../../../api/type.orbit';
import type { DialogState } from '../../element/Dialog';
import type { StateUpdateFn } from 'react-hooks';

type ObjectTemporaryUrlHook = {
    +error: ?string,

    +createUrlDialog: DialogState<null>,
    +viewDialog: DialogState<[string, Date]>,

    +shareParams: OrbitObjectShareParams,
    +setShareParams: StateUpdateFn<OrbitObjectShareParams>,

    +autoFocusValidity: boolean,
    +setAutoFocusValidity: StateUpdateFn<boolean>,

    +show: () => Promise<void>,
}

type ObjectTemporaryUrlProps = {
    +hook: ObjectTemporaryUrlHook,
}

export function useObjectTemporaryUrl(containerName: string, object: ?OrbitObject, confirmCreateKeys: $PropertyType<OrbitAccessKeysHook, 'confirmCreateKeys'>): ObjectTemporaryUrlHook {
    const [error, setError] = useState<?string>(null);
    const [shareParams, setShareParams] = useState<OrbitObjectShareParams>({ seconds: 3600,  });
    const [autoFocusValidity, setAutoFocusValidity] = useState(false);
    const currAccountId = useCurrentAccountId() || '';

    const viewDialog = useDialog<[string, Date]>([]);

    const createUrlDialog = useDialog([{
        label: 'Create URL',
        kind: 'primary',
        onSelect: async () => {
            const obj = object;
            if (obj != null) {
                const time = parseFloat(shareParams.seconds);
                if (isNaN(time) || time <= 0) {
                    setError('Please enter a valid time');
                    return false;
                }

                const key = await confirmCreateKeys('share', obj);
                if (key != null) {
                    const [tempUrl, expires] = getObjectDownloadUrl(time, currAccountId, containerName, obj, key, 'GET');

                    viewDialog.show([tempUrl.toString(), expires]);
                }
            }
        },
    }]);

    return {
        error,
        createUrlDialog,
        viewDialog,
        shareParams,
        setShareParams,
        autoFocusValidity,
        setAutoFocusValidity,
        show: async () => {
            if (object != null) {
                const key = await confirmCreateKeys('share', object);
                if (key != null) {
                    createUrlDialog.show();
                }
            }
        }
    };
}

export const ObjectTemporaryUrlCreateDialog = ({ hook, }: ObjectTemporaryUrlProps): React$Node => {
    const {
        error,
        createUrlDialog,
        shareParams,
        setShareParams,
        autoFocusValidity,
        setAutoFocusValidity,
    } = hook;

    return (
        <Dialog
            dialog={createUrlDialog}
            title='Create Temporary URL'
            render={(): React$Node => {
                return (
                    <>
                        <div>
                            <p className='mb-5'>Create a shareable link to this file which will automatically expire
                                after the period you choose below.
                            </p>
                            <ToggleGroup
                                fullWidth={true}
                                addOther={''}
                                options={[
                                    {
                                        label: '1 hour',
                                        value: 3600,
                                    },
                                    {
                                        label: '1 day',
                                        value: 3600 * 24,
                                    },
                                    {
                                        label: '1 week',
                                        value: 3600 * 24 * 7,
                                    },
                                ]}
                                selected={shareParams.seconds}
                                onSelect={(seconds) => {
                                    setShareParams({ ...shareParams, seconds });
                                    setAutoFocusValidity(seconds === '');
                                }}
                            />
                            {typeof shareParams.seconds !== 'number'
                                ? <TextInput
                                    label='Expires in'
                                    value={shareParams.seconds}
                                    autoFocus={autoFocusValidity}
                                    onChange={(seconds) => setShareParams({ ...shareParams, seconds })}
                                    errorText={error}
                                    className='w-40 mt-4'
                                    postText='seconds'
                                    ellipsis={true}
                                />
                                : null}
                        </div>
                    </>
                );
            }}
        />
    );
}

type ObjectTemporaryUrlViewDialogProps = {
    +dialog: DialogState<[string, Date]>,
}

export const ObjectTemporaryUrlViewDialog = ({ dialog, }: ObjectTemporaryUrlViewDialogProps): React$Node => {
    const expiry: ?Date = dialog.data ? dialog.data[1] : null;

    // default to basically never forcing a refresh
    let delay: number = 999999999;
    let helperText = null;

    if (expiry != null) {
        const now = new Date();
        const difference = differenceInSeconds(expiry, now);
        if (difference >= 76) delay = 15000;
        else delay = 1000;

        helperText = 'Expires in ' + formatDistance(expiry, Date.now());
    }

    useRefreshComponent(delay);

    return (
        <Dialog
            dialog={dialog}
            title='Temporary URL Created'
            render={(data: ?[string, Date]): React$Node => {
                if (data == null) return null;
                const [url, ] = data;

                return (
                    <div>
                        <TextInput
                            label='URL'
                            value={url}
                            readOnly={true}
                            onChange={() => void 0}
                            autoPopulated={true}
                            autoFocus={true}
                            focusSelectAll={true}
                            postText='Copy'
                            helperText={helperText}
                            onPostInlayClick={() => copy(url)}
                            ellipsis={true}
                            className='w-full'
                        />
                    </div>
                );
            }}
        />
    );
}