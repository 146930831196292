// @flow
import { createLogic } from 'redux-logic';
import { addTimeout } from './lib';

import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type {
    FetchQueueAction,
    FetchQueuePushAction,
} from './type';

import type { ResourceFetchFull } from '../resource/type';
import type { ReduxLogic } from 'redux-logic';

/**
 * Ensures the collected representations of the given resource kind is downloaded
 * and cached in our state.
 */
export const FetchQueueLogic: ReduxLogic = createLogic({
    'type': ['FETCH_QUEUE_PUSH'],
    async process(
        deps: { getState: () => CloudGuiState, action: FetchQueuePushAction },
        dispatch: Dispatch<FetchQueueAction | ResourceFetchFull>,
        done: () => void
    ) {
        const { id, delay } = deps.action.payload;

        // don't clear out the existing timeout -
        // it needs to execute to finish up the logic.
        // we check for the IDs below anyway.

        let timeout: TimeoutID;
        await (new Promise((resolve) => {
            timeout = addTimeout(resolve, delay);
            dispatch({
                type: 'FETCH_QUEUE_SET_TIMEOUT',
                payload: {
                    id,
                    timeout,
                }
            });
        }));

        const afterSleepQueue = deps.getState().FetchQueue.queue[id];
        if (
            afterSleepQueue != null
            && afterSleepQueue.timeout === timeout
        ) {
            dispatch({
                type: 'FETCH_QUEUE_REMOVE',
                payload: {
                    id,
                }
            });
            dispatch({
                type: 'RESOURCE_FETCH_FULL',
                payload: {
                    kind: afterSleepQueue.kind,
                    id,
                }
            });
        }

        done();
    },
});

