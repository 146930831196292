// @flow
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UNKNOWN_DISK_SIZE } from '../../state/Build/type';
import { toast } from 'react-toastify';
import { forceFayeReconnect } from '../../api/events';

import type { BuildWithEstimate, BuildingResource, OrbitBulkDeletion } from '../../state/Build/type';
import type { CloudGuiState } from '../../state/cloudgui';
import type { ResourceState } from '../../state/resource/type';
import type { QueueAction } from '../../state/ActionQueue/type';

// in MB / s
const ORBIT_READ = 2048;
const MILLI_TO_SEC = 1000;
const LBA_INIT_TIME = 30;
const SRV_INIT_TIME = 10;
const DB_BUILD_TIME = 180;

// progress grows in linear time up to this value
const BREAK_LINEAR = 0.85;
// progress grows from BREAK_LINEAR to BREAK_OVERTIME squashed into the range BREAK_LINEAR...PROG_MAX
const BREAK_OVERTIME = 2.0;
// progress will never grow beyond PROG_MAX - and it shows a warning at this point.
export const PROGRESS_MAX = 0.95;

function withEstimate(now: number, b: BuildingResource, resources: ResourceState): BuildWithEstimate {
    const { id, resource_type, } = b;
    let expected: number = UNKNOWN_DISK_SIZE;
    let created_at: ?Date = null;

    switch(resource_type) {
    case 'server':
        const server = resources.server.full[id];
        expected = (server?.server_type?.disk_size || 4096) / ORBIT_READ;
        expected += SRV_INIT_TIME;
        created_at = server?.created_at;
        break;
    case 'database_server':
        const database_server = resources.database_server.full[id];
        expected = DB_BUILD_TIME;
        created_at = database_server?.created_at;
        break;
    case 'load_balancer':
        const load_balancer = resources.load_balancer.full[id];
        expected = LBA_INIT_TIME;
        created_at = load_balancer?.created_at;
        break;
    default:
        // nothing to do; we init with sensible defaults
        break;
    }

    if (expected === UNKNOWN_DISK_SIZE || created_at == null) {
        return {
            ...b,
            progress: expected,
        }
    }

    const started = Math.floor(created_at.getTime() / MILLI_TO_SEC);

    let progress = (now - started) / expected;

    if (progress > BREAK_LINEAR && progress <= BREAK_OVERTIME) progress = BREAK_LINEAR + ((PROGRESS_MAX - BREAK_LINEAR) * (progress - BREAK_LINEAR) / (BREAK_OVERTIME - BREAK_LINEAR));
    if (progress >= BREAK_OVERTIME) progress = PROGRESS_MAX;

    return {
        ...b,
        progress,
    };
}


export const useBuildingResources = (): [$ReadOnlyArray<BuildWithEstimate>, $ReadOnlyArray<OrbitBulkDeletion>, $ReadOnlyArray<QueueAction>] => {
    const [building, orbitDeleting, resources, pendingActions] = useSelector<
        CloudGuiState, [Map<string, BuildingResource>, Map<string, OrbitBulkDeletion>, ResourceState, $ReadOnlyArray<QueueAction>]
    >((s: CloudGuiState) => [
        s.Build.building,
        s.Build.deleting,
        s.Resource,
        s.ActionQueue.actions,
    ]);
    const now = Math.floor(Date.now() / MILLI_TO_SEC);

    const length = pendingActions.length;

    useEffect(() => {
        if (length > 0 && window) {
            const listener = (event: UIEvent) => {
                event.preventDefault();
                // $FlowFixMe Chrome requires returnValue to be set.
                event.returnValue = '';
                toast('There are pending API calls - please wait until these are cleared', { type: 'warning' });
                forceFayeReconnect();
            }

            window.addEventListener('beforeunload', listener);

            return () => window.removeEventListener('beforeunload', listener);
        }
    }, [length]);

    return useMemo(() => {
        const buildingProgress = [...building.values()]
            .filter(b => b.status === 'creating')
            .map(b => withEstimate(now, b, resources));

        return [
            buildingProgress,
            [...orbitDeleting.values()],
            pendingActions,
        ];
    }, [building, now, resources, orbitDeleting, pendingActions]);
};

export const useBuildingResource = (id: string): ?number => {
    const [building, resources] = useSelector<CloudGuiState, [?BuildingResource, ResourceState]>((s: CloudGuiState) => [
        s.Build.building.get(id),
        s.Resource,
    ]);
    const [now, setNow] = useState(() => Math.floor(Date.now() / MILLI_TO_SEC));
    useEffect(() => {
        if (building) {
            const interval = setInterval(() => {
                const next = Math.floor(Date.now() / MILLI_TO_SEC);
                setNow(next);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [building, setNow]);

    if (building && building.status === 'creating') {
        const { progress } = withEstimate(now, building, resources);
        return progress;
    }

    return null;
};