// @flow

import type { CloudIpMapDestKind, CloudIpMapResourceTypes } from './type';
import type { ResourceState } from '../resource/type';

export function getMappableResourceKind(id: string): CloudIpMapDestKind {
    switch(id.substring(0, 3)) {
    case 'srv': return 'server';
    case 'int': return 'interface';
    case 'lba': return 'load_balancer';
    case 'dbs': return 'database_server';
    case 'grp': return 'server_group';
    case 'tst': return 'test';
    default:
        throw new Error('not a cloud mappable resource type:' + id.substring(0,3));
    }
}

export function getResourceForDest(kind: CloudIpMapDestKind, resourceId: string, Resource: ResourceState): ?CloudIpMapResourceTypes {
    if (kind === 'interface') {
        const fullId: ?string = Object.keys(Resource['server'].full).find((x: string) => Resource['server'].full?.[x]?.interfaces?.[0]?.id === resourceId);
        if (fullId) return Resource['server'].full[fullId];
        const collectedId: ?string = Object.keys(Resource['server'].collected).find((x: string) => Resource['server'].collected?.[x]?.interfaces?.[0]?.id === resourceId);
        if (collectedId) return Resource['server'].collected[collectedId];

        return null;
    }

    return Resource[kind].full[resourceId] || Resource[kind].collected[resourceId];
}