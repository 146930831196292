// @flow
import RcTooltip from 'rc-tooltip';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right' | 'topRight' | 'bottomRight' | 'topLeft' | 'bottomLeft'

type RcTooltipProps = {
    +overlay: ?React$Node,
    overlayClassName?: string,
    trigger?: 'click' | 'hover',
    placement?: TooltipPlacement,
    mouseEnterDelay?: string,
    +onClick?: ?() => void,
    +visible?: boolean,
    +getTooltipContainer?: () => ?HTMLElement,
}

export type TooltipProps = {
    background?: 'light' | 'dark',
    children: React$Node,
    ...RcTooltipProps,
}

export const Tooltip = ({ background, overlayClassName: extraClasses, children, ...rest}: TooltipProps): React$Node => {
    const main = document.querySelector('.l-app__main');
    const tooltipProps: RcTooltipProps = {
        placement: 'top',
        overlayClassName: (background === 'light' ? 'rc-tooltip--light': '') + (extraClasses ? ' ' + extraClasses : ''),
        mouseEnterDelay: "0.2",
        ...(main
            ? { getTooltipContainer: () => main, }
            : null
        ),
        ...rest,
    }

    if (rest.overlay == null) return children;

    return <RcTooltip {...tooltipProps}>{children}</RcTooltip>
}