// @flow

import type { CloudIpAssignEntry, CloudIpState, CloudIpStateAction } from './type';
import type { StoreClearAction } from '../type';

const emptyState = ([]: Array<CloudIpAssignEntry>);

export const CloudIpReducer = (
    state: CloudIpState = emptyState,
    action: CloudIpStateAction | StoreClearAction
): CloudIpState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'CLOUD_IP_AUTO_ASSIGN_RESOURCE_ID':
        return [].concat(state, { ...action.payload });
    case 'CLOUD_IP_ASSIGNED_RESOURCE':
        return state.filter((i) => i.cloudIpId !== action.payload.cloudIpId);
    default:
        void (action: empty);
        break;
    }

    return state;
};