// @flow

import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid'

// Wrapper to avoid parseISO inanity
export const parseIsoDate = (date: string): ?Date => {
    // probably...
    if (typeof date === 'object') return date;
    const parsed: Date = parseISO(date);
    return isValid(parsed)
        ? parsed
        : null;
};

export const parseHttpDate = (date: string): ?Date => {
    if (typeof date === 'object') return date;
    // from https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
    if (!date.endsWith(' GMT')) return null;
    const parsed: Date = parse(date.slice(0, -4) + ' Z', 'E, dd LLL y HH:mm:ss X', new Date());
    return isValid(parsed)
        ? parsed
        : null;
}

