// @flow
import { useState } from 'react';
import { SvgIcon } from './SvgIcon';
import { cn } from './lib/classNames';

export type BannerProps = {
    children: React$Node,
    type?: 'info' | 'error' | 'warning',
    dismissable?: boolean | string,
}

// localStorage has to be a string...
const PREFIX = 'bb:notice:'
const IS_DISMISSED = 'true';

function useDismissableBanner(id: ?(boolean | string)) {
    const [dismissed, setDismissed] = useState(typeof id === 'boolean' ? false : id);
    const onDismiss = () => {
        setDismissed(true);
        if (typeof id === 'string' && localStorage) {
            localStorage.setItem(PREFIX + id, IS_DISMISSED);
        }
    }

    if (dismissed === true) return { dismissed: true, onDismiss };
    if (dismissed == null || dismissed === false) return { dismissed: false, onDismiss };

    if (typeof dismissed === 'string' && localStorage) {
        let storedDismissed = localStorage.getItem(PREFIX + dismissed);
        if (storedDismissed === IS_DISMISSED) {
            setDismissed(true);
            return { dismissed: true, onDismiss }
        }
    }

    return { dismissed: false, onDismiss }
}

export const Banner = ({ children, type, dismissable }: BannerProps): React$Node => {
    const { dismissed, onDismiss } = useDismissableBanner(dismissable);

    if (dismissed) return null;

    let classes = {
        'c-banner': true,
        'c-banner--info': type === 'info',
        'c-banner--error': type === 'error',
        'c-banner--warning': type === 'warning',
    }

    return (
        <div className={cn(classes)}>
            <div className='c-banner__content'>
                {children}
            </div>
            {dismissable
                ? <SvgIcon svg='cross' className='c-banner__dismiss' onClick={onDismiss} />
                : null
            }
        </div>
    );
};