// @flow
import { protocolLabel } from '../../../element/Styled';
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr } from '../../../element/NoResourceMessages';
import { Panel, PanelBar, PanelHeader } from '../../../element/Panel';
import { history } from '../../../../lib/history';
import { isShowDetailsResourceStatus, SkeletonBar, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { BbPortTranslator } from '../../../../api/type.cip';
import type { ResourceCacheStatus, } from '../../../../state/resource/type';

export type CipPortTranslatorsProps = {
    +editUri: string,
    +portTranslators: ?$ReadOnlyArray<BbPortTranslator>,
    +cacheStatus: ResourceCacheStatus,
};

export const CipPortTranslators = ({ editUri, portTranslators, cacheStatus, }: CipPortTranslatorsProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title='Port Translators'
                actions={{ edit: () => history.push(editUri + '/'), }}
            />
            <PanelBar padding={false}>
                <Table>
                    <thead>
                    <Tr>
                        <Th>From</Th>
                        <Th>To</Th>
                        <Th stretch={true}>Protocol</Th>
                    </Tr>
                    </thead>
                    <tbody>
                    {portTranslators?.map((portTranslator, idx: number) =>
                        <Tr key={idx}>
                            <Td>{portTranslator.incoming}</Td>
                            <Td>{portTranslator.outgoing}</Td>
                            <Td>{protocolLabel[portTranslator.protocol]}</Td>
                        </Tr>
                    )}
                    {!isShowDetailsResourceStatus(cacheStatus)
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td><SkeletonBar size='sm'/></Td>
                                <Td><SkeletonBar size='sm'/></Td>
                                <Td><SkeletonBar size='sm'/></Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : null
                    }
                    <NoResourcesTr status={cacheStatus} colSpan={3} totalCount={portTranslators?.length} context='list' title='port translators' />
                    </tbody>
                </Table>
            </PanelBar>
        </Panel>
    );
};

