// @flow
import { useEffect, useState } from "react";
import { useEditorDirect } from "../common/Editor";
import { useSelector } from "react-redux";

import type { BbResourceKind } from "../../api/type";
import type { CloudGuiState } from "../../state/cloudgui";
import type { IdSelectEditor } from "../common/ResourceSelector";
import type { StateUpdateFn } from "react-hooks";

export function isSelectable(status: ?string): boolean {
    return status !== 'deleting' && status !== 'deleted' && status !== 'failing' && status !== 'failed' && status !== 'unavailable';
}

type ResourceIdSelectorHook = {
    +editor: IdSelectEditor,
    +selected: $ReadOnlyArray<string>,
    +setSelected: StateUpdateFn<$ReadOnlyArray<string>>,
}
const NO_RESOURCES = [];

export function useResourceIdSelector(kind: BbResourceKind): ResourceIdSelectorHook {
    const [selected, setSelected] = useState<$ReadOnlyArray<string>>([]);
    const editor = useEditorDirect(
        selected,
        setSelected,
        null
    );

    const collected = useSelector((state: CloudGuiState) => state.Resource[kind]?.collected);
    const {setValue} = editor;
    const value = editor?.value || NO_RESOURCES;

    useEffect(() => {
        const knownIds = value.reduce((acc, id) => {
            if (
                !collected?.hasOwnProperty(id)
                || !isSelectable(collected[id].status || 'active')
            ) return acc;
            else return [...acc, id];
        }, []);

        if (knownIds.length !== value.length) {
            setValue(knownIds);
        }
    }, [collected, value, setValue]);

    return {
        editor,
        selected,
        setSelected,
    }
}