// @flow
import { useEffect } from 'react';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { useDialog, Dialog } from '../../../element/Dialog';
import { Notice } from '../../../element/Notice';
import { formatMegabytesSize, GB_TO_MB } from '../../../element/Styled';
import { PerGigPrice } from '../../../element/PerGigPrice';
import { SvgIcon } from '../../../element/SvgIcon';
import { Button } from '../../../element/Button';

import type { EditorModal } from '../../../common/Editor';
import type { EditGigabytes } from '../../../hoc/Volumes';
import type { Megabytes, Gigabytes } from '../../../../api/type.units';
import type { BbVolume } from '../../../../api/type.volume';


export type VolumeResizeEditProps = {
    +resourceTitle?: string,
    +panelTitle?: string,
    +editor: EditorModal<EditGigabytes, ?string, BbVolume>,
    +originalSize: ?Megabytes,
}


export const VolumeResizeEdit = ({ editor, originalSize }: VolumeResizeEditProps): React$Node => {
    const confirmDialog = useDialog([
        {
            color: 'blue',
            kind: 'primary',
            label: 'Resize Volume',
            onSelect: () => editor.status === 'edit' ? editor.onSave() : null,
        }
    ]);

    const { setValue } = editor;

    const errors = editor.errors;
    const { hide } = confirmDialog;
    useEffect(() => {
        if (errors != null) hide();
    }, [errors, hide]);

    const showDialog = () => {
        editor.setErrors(null);
        confirmDialog.show();
    }

    const numericValue: Gigabytes = parseFloat(editor.value);

    return (
        <>
            <Dialog
                title="Resize Volume?"
                dialog={confirmDialog}
                messages={editor.messages}
                footerLink={<a href='https://www.brightbox.com/docs/guides/volumes/resize-volume/' rel='noreferrer noopener' target='blank'>
                    <Button size='sm' kind='bare' preIcon='info-fill'>Resizing Volumes</Button></a>}
                render={() => (
                    <div>
                        {originalSize != null
                            ? <p>
                                Are you sure you want to resize this Volume from <b>{formatMegabytesSize(originalSize)}</b> to <b>{(numericValue)}GB</b>?
                            </p>
                            : <p>Are you sure you want to resize this Volume?</p>
                        }

                        <Notice type='warning' icon='info-fill' className='mb-4'>
                            <b>Note:</b> to avoid data loss, resizes to Volumes are permanent and can't be reduced in size later
                        </Notice>

                        <PerGigPrice label={'Block'} id={'blockstorage'} />
                    </div>
                )}
            />
            <Panel>
                <PanelHeader
                    title="Resize Volume"
                    description='What size should we increase this volume to?'
                />
                <PanelBar>
                    <div className='flex items-start'>
                        <TextInput
                            label="Current Size"
                            value={originalSize ? originalSize / GB_TO_MB : '-'}
                            onChange={() => void 0}
                            postText="GB"
                            className='w-36'
                            disabled={true}
                        />

                        <SvgIcon svg='arrow-right' className='-ml-2 mt-5 text-gray-500' />

                        <TextInput
                            label="New Size"
                            onChange={setValue}
                            autoFocus={true}
                            value={editor.value == null ? '' : editor.value}
                            errorText={editor.errors}
                            postText="GB"
                            className='w-36 ml-4'
                        />
                    </div>

                    <Notice size='sm' icon='info-fill'>After resizing the Volume
                        you'll need to <a href='https://www.brightbox.com/docs/guides/volumes/resize-volume/#extend-the-filesystem' rel='noopener noreferrer' target='blank'>extend its filesystem</a> to make use of
                        the additional space</Notice>

                </PanelBar>
                 <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{ onClick: editor.status === 'edit' ? showDialog : null }}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                    leftButton={{
                        kind: 'bare',
                        preIcon: 'info-fill',
                        children: <a href='https://www.brightbox.com/docs/guides/volumes/resize-volume/' rel='noopener noreferrer' target='blank'>Resizing Volumes</a>
                    }}
                />
            </Panel>
        </>
    );
};
