// @flow
import { LbaList } from './LbaList';
import { LbaView } from './LbaView';
import { LbaCreate } from './LbaCreate';
import { StandardResourceSection } from '../StandardResourceSection';

import type { Location, Match } from 'react-router-dom';

export const LbaSection = ({ match }: { location: Location, match: Match }): React$Node => (
    <StandardResourceSection match={match} list={LbaList} create={LbaCreate} view={LbaView} />
);
