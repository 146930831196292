// @flow
import { PanelSettingsBar } from '../../element/Panel';
import { history } from '../../../lib/history';
import { SkeletonBar } from '../../element/Skeleton';
import { ProgressBar } from '../../element/ProgressBar';
import { formatMegabytesSize } from '../../element/Styled';

import type { BbAccount } from '../../../api/type.acc';

export type AccountLimitsOwnProps = {
    account: ?BbAccount,
};

export const AccountLimits = (props: AccountLimitsOwnProps): React$Node => {
    const { account } = props;

    return (
        <PanelSettingsBar
            settings={[
                {
                    name: 'Cloud Servers',
                    summary: account ? <ProgressBar
                                            explanation={`Currently using ${account.ram_used ? formatMegabytesSize(account.ram_used) : 'none'} of your ${formatMegabytesSize(account.ram_limit)} limit`}
                                            max={account.ram_limit}
                                            value={account.ram_used}
                                            className='w-96'
                                            />
                                    : <SkeletonBar size='lg'/>,
                    settingsBtn: account ? {
                        children: 'Request Increase',
                        kind: 'secondary',
                        onClick: () => history.push('increase/server/'),
                    } : null,
                },
                {
                    name: 'Cloud IPs',
                    summary: account ? <ProgressBar
                                            explanation={`Currently using ${account.cloud_ips_used ? account.cloud_ips_used : 'none'} of your ${account.cloud_ips_limit} Cloud IP limit`}
                                            max={account.cloud_ips_limit}
                                            value={account.cloud_ips_used}
                                            className='w-96'
                                        />
                                    : <SkeletonBar size='lg'/>,
                    settingsBtn: account ? {
                        children: 'Request Increase',
                        kind: 'secondary',
                        onClick: () => history.push('increase/cloud_ip/'),
                    } : null,
                },
                {
                    name: 'Load Balancers',
                    summary: account ? <ProgressBar
                                            explanation={`Currently using ${account.load_balancers_used ? account.load_balancers_used : 'none'} of your ${account.load_balancers_limit} Load Balancer limit`}
                                            max={account.load_balancers_limit}
                                            value={account.load_balancers_used}
                                            className='w-96'
                                        />
                                    : <SkeletonBar size='lg'/>,
                    settingsBtn: account ? {
                        children: 'Request Increase',
                        kind: 'secondary',
                        onClick: () => history.push('increase/load_balancer/'),
                    } : null,
                },
                {
                    name: 'Cloud SQL',
                    summary: account ? <ProgressBar
                                            explanation={`Currently using ${account.dbs_ram_used ? formatMegabytesSize(account.dbs_ram_used) : 'none'} of your ${formatMegabytesSize(account.dbs_ram_limit)} limit`}
                                            max={account.dbs_ram_limit}
                                            value={account.dbs_ram_used}
                                            className='w-96'
                                        />
                                    : <SkeletonBar size='lg'/>,                
                    settingsBtn: account ? {
                        children: 'Request Increase',
                        kind: 'secondary',
                        onClick: () => history.push('increase/database_server/'),
                    } : null,
                },
                {
                    name: 'Block Storage',
                    summary: account ? <ProgressBar
                            explanation={`Currently using ${account.block_storage_used ? formatMegabytesSize(account.block_storage_used) : 'none'} of your ${formatMegabytesSize(account.block_storage_limit)} limit`}
                            max={account.block_storage_limit}
                            value={account.block_storage_used}
                            className='w-96'
                            />
                        : <SkeletonBar size='lg'/>,
                    settingsBtn: account ? {
                        children: 'Request Increase',
                        kind: 'secondary',
                        onClick: () => history.push('increase/block_storage/'),
                    } : null,
                },
            ]}
        />
    );
};
