// @flow
// **
// ** THIS IS A FILE GENERATED BY build-icons.js DO NOT MANUALLY EDIT **
// **
import addfill from './figma/add-fill.svg';
import add from './figma/add.svg';
import arrowdownleft from './figma/arrow-down-left.svg';
import arrowdownright from './figma/arrow-down-right.svg';
import arrowdown from './figma/arrow-down.svg';
import arrowleftdown from './figma/arrow-left-down.svg';
import arrowleftup from './figma/arrow-left-up.svg';
import arrowleft from './figma/arrow-left.svg';
import arrowright from './figma/arrow-right.svg';
import arrowupleft from './figma/arrow-up-left.svg';
import arrowupright from './figma/arrow-up-right.svg';
import arrowup from './figma/arrow-up.svg';
import asterisk from './figma/asterisk.svg';
import bell from './figma/bell.svg';
import bolt from './figma/bolt.svg';
import bookmark from './figma/bookmark.svg';
import braces from './figma/braces.svg';
import bulb from './figma/bulb.svg';
import caretdown from './figma/caret-down.svg';
import caretleft from './figma/caret-left.svg';
import caretright from './figma/caret-right.svg';
import caretup from './figma/caret-up.svg';
import clouddownload from './figma/cloud-download.svg';
import cloud from './figma/cloud.svg';
import code from './figma/code.svg';
import cog from './figma/cog.svg';
import copy from './figma/copy.svg';
import cross from './figma/cross.svg';
import curvedarrowright from './figma/curved-arrow-right.svg';
import curvedarrowupright from './figma/curved-arrow-up-right.svg';
import db_mysql from './figma/db/mysql.svg';
import db_postgres from './figma/db/postgres.svg';
import db_postgresql from './figma/db/postgresql.svg';
import disabled from './figma/disabled.svg';
import download from './figma/download.svg';
import ellipsishorizontal from './figma/ellipsis-horizontal.svg';
import ellipsisvertical from './figma/ellipsis-vertical.svg';
import exclamation from './figma/exclamation.svg';
import file_archive from './figma/file/archive.svg';
import file_audio from './figma/file/audio.svg';
import file_compressed from './figma/file/compressed.svg';
import file_css from './figma/file/css.svg';
import file_font from './figma/file/font.svg';
import file_go from './figma/file/go.svg';
import file_image from './figma/file/image.svg';
import file_javascript from './figma/file/javascript.svg';
import file_markdown from './figma/file/markdown.svg';
import file_pdf from './figma/file/pdf.svg';
import file_php from './figma/file/php.svg';
import file_python from './figma/file/python.svg';
import file_ruby from './figma/file/ruby.svg';
import file_sass from './figma/file/sass.svg';
import file_spreadsheet from './figma/file/spreadsheet.svg';
import file_svg from './figma/file/svg.svg';
import file_text from './figma/file/text.svg';
import file_video from './figma/file/video.svg';
import file from './figma/file.svg';
import flag from './figma/flag.svg';
import folder from './figma/folder.svg';
import ghost from './figma/ghost.svg';
import happy from './figma/happy.svg';
import hardware from './figma/hardware.svg';
import helpfill from './figma/help-fill.svg';
import help from './figma/help.svg';
import hide from './figma/hide.svg';
import home from './figma/home.svg';
import infofill from './figma/info-fill.svg';
import info from './figma/info.svg';
import label from './figma/label.svg';
import lifebelt from './figma/lifebelt.svg';
import locked from './figma/locked.svg';
import minus from './figma/minus.svg';
import newwindow from './figma/new-window.svg';
import note from './figma/note.svg';
import os_almalinux from './figma/os/almalinux.svg';
import os_centos from './figma/os/centos.svg';
import os_coreos from './figma/os/coreos.svg';
import os_debian from './figma/os/debian.svg';
import os_fedora from './figma/os/fedora.svg';
import os_flatcar from './figma/os/flatcar.svg';
import os_freebsd from './figma/os/freebsd.svg';
import os_ubuntu from './figma/os/ubuntu.svg';
import os_windows from './figma/os/windows.svg';
import pin from './figma/pin.svg';
import plus from './figma/plus.svg';
import pointing from './figma/pointing.svg';
import pulse from './figma/pulse.svg';
import question from './figma/question.svg';
import recycle from './figma/recycle.svg';
import registry from './figma/registry.svg';
import reset from './figma/reset.svg';
import resource_cloudip from './figma/resource/cloud-ip.svg';
import resource_cloudsql from './figma/resource/cloud-sql.svg';
import resource_cpu from './figma/resource/cpu.svg';
import resource_firewallinbound from './figma/resource/firewall-inbound.svg';
import resource_firewalloutbound from './figma/resource/firewall-outbound.svg';
import resource_firewall from './figma/resource/firewall.svg';
import resource_image from './figma/resource/image.svg';
import resource_invoice from './figma/resource/invoice.svg';
import resource_loadbalancer from './figma/resource/load-balancer.svg';
import resource_orbit from './figma/resource/orbit.svg';
import resource_servergroup from './figma/resource/server-group.svg';
import resource_servertype from './figma/resource/server-type.svg';
import resource_snapshot from './figma/resource/snapshot.svg';
import resource_volume from './figma/resource/volume.svg';
import rosette from './figma/rosette.svg';
import sad from './figma/sad.svg';
import search from './figma/search.svg';
import show from './figma/show.svg';
import slash from './figma/slash.svg';
import spacer from './figma/spacer.svg';
import sparkles from './figma/sparkles.svg';
import speech from './figma/speech.svg';
import spinner from './figma/spinner.svg';
import star from './figma/star.svg';
import swap from './figma/swap.svg';
import team from './figma/team.svg';
import thumb from './figma/thumb.svg';
import tick from './figma/tick.svg';
import trash from './figma/trash.svg';
import unlocked from './figma/unlocked.svg';
import updown from './figma/up-down.svg';
import upload from './figma/upload.svg';
import viewgrid from './figma/view-grid.svg';
import viewlist from './figma/view-list.svg';
import warningfill from './figma/warning-fill.svg';
import warning from './figma/warning.svg';
import world from './figma/world.svg';

export const Icons = {
    'add-fill': addfill,
    'add': add,
    'arrow-down-left': arrowdownleft,
    'arrow-down-right': arrowdownright,
    'arrow-down': arrowdown,
    'arrow-left-down': arrowleftdown,
    'arrow-left-up': arrowleftup,
    'arrow-left': arrowleft,
    'arrow-right': arrowright,
    'arrow-up-left': arrowupleft,
    'arrow-up-right': arrowupright,
    'arrow-up': arrowup,
    'asterisk': asterisk,
    'bell': bell,
    'bolt': bolt,
    'bookmark': bookmark,
    'braces': braces,
    'bulb': bulb,
    'caret-down': caretdown,
    'caret-left': caretleft,
    'caret-right': caretright,
    'caret-up': caretup,
    'cloud-download': clouddownload,
    'cloud': cloud,
    'code': code,
    'cog': cog,
    'copy': copy,
    'cross': cross,
    'curved-arrow-right': curvedarrowright,
    'curved-arrow-up-right': curvedarrowupright,
    'db/mysql': db_mysql,
    'db/postgres': db_postgres,
    'db/postgresql': db_postgresql,
    'disabled': disabled,
    'download': download,
    'ellipsis-horizontal': ellipsishorizontal,
    'ellipsis-vertical': ellipsisvertical,
    'exclamation': exclamation,
    'file/archive': file_archive,
    'file/audio': file_audio,
    'file/compressed': file_compressed,
    'file/css': file_css,
    'file/font': file_font,
    'file/go': file_go,
    'file/image': file_image,
    'file/javascript': file_javascript,
    'file/markdown': file_markdown,
    'file/pdf': file_pdf,
    'file/php': file_php,
    'file/python': file_python,
    'file/ruby': file_ruby,
    'file/sass': file_sass,
    'file/spreadsheet': file_spreadsheet,
    'file/svg': file_svg,
    'file/text': file_text,
    'file/video': file_video,
    'file': file,
    'flag': flag,
    'folder': folder,
    'ghost': ghost,
    'happy': happy,
    'hardware': hardware,
    'help-fill': helpfill,
    'help': help,
    'hide': hide,
    'home': home,
    'info-fill': infofill,
    'info': info,
    'label': label,
    'lifebelt': lifebelt,
    'locked': locked,
    'minus': minus,
    'new-window': newwindow,
    'note': note,
    'os/almalinux': os_almalinux,
    'os/centos': os_centos,
    'os/coreos': os_coreos,
    'os/debian': os_debian,
    'os/fedora': os_fedora,
    'os/flatcar': os_flatcar,
    'os/freebsd': os_freebsd,
    'os/ubuntu': os_ubuntu,
    'os/windows': os_windows,
    'pin': pin,
    'plus': plus,
    'pointing': pointing,
    'pulse': pulse,
    'question': question,
    'recycle': recycle,
    'registry': registry,
    'reset': reset,
    'resource/cloud-ip': resource_cloudip,
    'resource/cloud-sql': resource_cloudsql,
    'resource/cpu': resource_cpu,
    'resource/firewall-inbound': resource_firewallinbound,
    'resource/firewall-outbound': resource_firewalloutbound,
    'resource/firewall': resource_firewall,
    'resource/image': resource_image,
    'resource/invoice': resource_invoice,
    'resource/load-balancer': resource_loadbalancer,
    'resource/orbit': resource_orbit,
    'resource/server-group': resource_servergroup,
    'resource/server-type': resource_servertype,
    'resource/snapshot': resource_snapshot,
    'resource/volume': resource_volume,
    'rosette': rosette,
    'sad': sad,
    'search': search,
    'show': show,
    'slash': slash,
    'spacer': spacer,
    'sparkles': sparkles,
    'speech': speech,
    'spinner': spinner,
    'star': star,
    'swap': swap,
    'team': team,
    'thumb': thumb,
    'tick': tick,
    'trash': trash,
    'unlocked': unlocked,
    'up-down': updown,
    'upload': upload,
    'view-grid': viewgrid,
    'view-list': viewlist,
    'warning-fill': warningfill,
    'warning': warning,
    'world': world,
}

export type Icon = $Keys<typeof Icons>;
