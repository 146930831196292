// @flow
import { Panel, PanelHeader } from '../../element/Panel';
import { ResourceSelectedServers, ServerSelector } from '../../common/ServerSelector';

import type { IdSelectEditor } from '../../common/ResourceSelector';
import type { SelectedServersProps } from '../../common/ServerSelector';

type ServerGroupServerSelectorProps = { +editor: IdSelectEditor, +children?: ?React$Node, };

const DESCRIPTION = 'Which Cloud Servers should be included in this Server Group?';

export const ServerGroupServerSelector = ({ editor, children }: ServerGroupServerSelectorProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title='Choose Cloud Servers'
                description={DESCRIPTION}
            />
            <ServerSelector editor={editor} />
            {children}
        </Panel>
    );
}

export const ServerGroupServersView = (props: SelectedServersProps): React$Node => {
    return (
        <ResourceSelectedServers
            {...props}
            title='Cloud Servers'
        />
    );
};
