// @flow
import { Tooltip } from '../../../element/Tooltip';
import { ServerLimit, ImageMinimumRam, ServerShrinkHint, ServerDiskDoesntFit } from '../../../element/LimitTooltip';

type ServerLimitWrapperProps = {
    +min_ram: ?number,
    +ram: number,
    +availableMemory: number,

    +diskDoesntFit?: ?{
        +min_disk: number,
        +disk: number,
    },

    +needsToBeInactive?: boolean,
    +children: React$Node,
}
export const ServerLimitWrapper = ({ min_ram, ram, availableMemory, needsToBeInactive, diskDoesntFit, children }: ServerLimitWrapperProps): React$Node => {
    if (diskDoesntFit != null) {
        return (
            <Tooltip overlay={<ServerDiskDoesntFit min_disk={diskDoesntFit.min_disk} disk={diskDoesntFit.disk}  />}>
                {children}
            </Tooltip>
        );
    }

    if (availableMemory < ram) {
        return (
            <Tooltip overlay={<ServerLimit selectedType={true} />}>
                {children}
            </Tooltip>
        );
    }

    if (min_ram != null && ram < min_ram) {
        return (
            <Tooltip overlay={<ImageMinimumRam ram={ram} min_ram={min_ram} />}>
                {children}
            </Tooltip>
        );
    }

    if (needsToBeInactive) {
        return (
            <Tooltip overlay={<ServerShrinkHint />}>
                {children}
            </Tooltip>
        );

    }

    return children;
};