// @flow

import { useCurrentAccountId } from '../lib';
import { useDispatch, useSelector } from 'react-redux';
import { useTriggeredMessages, useMessages } from '../Messages';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDialog } from '../../element/Dialog';
import { RC_API_REQUEST, RC_SUCCESS, RC_INITIAL, RC_ORBIT_CONTAINER, RC_ORBIT_OBJECTS, RC_CACHED, RC_NOT_FOUND, RC_ERROR } from '../../../state/resource/type';
import { fetchOrbitAuth, FLIP_MODE_THRESHOLD } from '../../../state/Orbit/lib';
import { orbitUrl } from '../../../api/url';
import to from 'await-to-js';
import { orbit } from '../../../api/rest';
import { toast } from 'react-toastify';
import { emptyErrors } from '../../common/lib';
import { useEditorErrors, useMessagesDrivenEditor } from '../../common/Editor';
import { historyBack } from '../../../lib/history';
import { PAGING_MIN_SIZE, PAGING_MAX_SIZE } from '../../element/lib/paging';
import { createHmac } from 'crypto';
import { useOrbitAccountMeta } from '../Orbit';
import { genTempUrlKey } from './lib';
import { usePager } from '../../element/ArrayPager';
import { useSortedItems } from '../ListPage';
import { nullableDateSort, numberSort } from '../../element/Sort';
import { getPath } from '../../../state/Orbit/OrbitTree';
import { asyncTimeout } from '../../../lib/async';

import type { MessageHook } from '../Messages';
import type { Dispatch } from 'redux';
import type { MessageStateAction, MessageAction } from '../../../state/Message/type';
import type { DialogState } from '../../element/Dialog';
import type { ResourceFetched } from '../../../state/resource/type';
import type { OrbitContainerMeta, OrbitContainerHeaders, OrbitObject, BbOrbitContainer } from '../../../api/type.orbit';
import type { Match } from 'react-router';
import type { FormErrors } from '../../common/lib';
import type { EditorModal } from '../../common/Editor';
import type { OrbitContainerStatus, OrbitAction, ContainerObjectPage, OrbitTreeNode } from '../../../state/Orbit/type';
import type { ReadWriteFlag } from '../../section/orbit/def';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { ListSortDef, ThOwnProps, } from '../ListPage';
import type { PagerBarProps } from '../../element/PagerBar';

export type OrbitContainerMetaHook = {
    +container: {
        details: ?BbOrbitContainer,
        meta: ?OrbitContainerMeta,
    },
    +cacheStatus: OrbitContainerStatus,
    +baseViewUrl: string,
}

export type OrbitContainerView = {
    ...OrbitContainerMetaHook,
    +deleteAction: () => void,
    +updateMeta: (access: OrbitContainerHeaders, messagesId: string) => void,
    +setHistory: (access: OrbitContainerHeaders, messagesId: string) => void,
    +createAndGrantApiClient: (name: string, access: ReadWriteFlag, headers: OrbitContainerHeaders, messagesId: string) => void,
    +refresh: () => void,
}

// "full" mode fetches all objects in the container and sorts / pages them locally.
// "flip" mode uses the prefix and marker API params to download a page at at time.
export type ObjectsBrowserMode = '' | 'flip' | 'full';

type ModeObjects = {
    mode: ObjectsBrowserMode,
    flip: ?{
        +objects: ?$ReadOnlyArray<OrbitObject>,
        +next: ?string,
        +prev: ?string,
        +jumpToFirst: ?string,
    },
    full: ?{
        +objects: ?$ReadOnlyArray<OrbitTreeNode>,
        +allPrefixObjects: ?$ReadOnlyArray<OrbitTreeNode>,
        +Th: React$StatelessFunctionalComponent<ThOwnProps>,
        +pager: PagerBarProps,
    },
}

export type OrbitContainerObjectsView = {
    +pageSize: number,
    +setPageSize: (number) => void,
    +lastSeenCount: ?number,
    ...ModeObjects,
    +cacheStatus: OrbitContainerStatus,
    +refresh: () => void,
}


export type OrbitObjectShareParams = {
    +seconds: number | string,
}

export function useOrbitContainerMeta(containerName: string): OrbitContainerMetaHook {
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const accountId = useCurrentAccountId() || '';

    useEffect(() => {
        dispatch({ type: 'ORBIT_REFRESH_CONTAINER', payload: { container: containerName, isRetry: false, } });
    }, [containerName, accountId, dispatch]);
    const baseViewUrl = `/accounts/${accountId}/orbit/container/${containerName}/`;

    const [details, objects, meta, containersFetched] = useSelector((state: CloudGuiState) => {
        return [
            state.Orbit.containers.details.get(containerName),
            state.Orbit.containers.objects.get(containerName),
            state.Orbit.containers.meta.get(containerName),
            state.Orbit.fetched,
        ];
    });
    let cacheStatus: OrbitContainerStatus = RC_INITIAL;
    if (details) cacheStatus = RC_ORBIT_CONTAINER;
    if (Array.isArray(objects?.objects)) cacheStatus = RC_ORBIT_OBJECTS;
    if (details == null && containersFetched === RC_CACHED) cacheStatus = RC_NOT_FOUND;

    return {
        container: {
            details,
            meta,
        },
        cacheStatus,
        baseViewUrl,
    }
}

export function useOrbitContainerView(containerName: string): OrbitContainerView {
    const deleteMessagesId = 'orbit_delete_' + containerName;
    const deleteMessages = useMessages(deleteMessagesId);

    const dispatch = useDispatch<Dispatch<OrbitAction>>();

    const containerMetaHook = useOrbitContainerMeta(containerName);

    useEffect(() => {
        if (deleteMessages.status === RC_SUCCESS) {
            historyBack();
        }
    }, [deleteMessages]);

    return {
        ...containerMetaHook,
        deleteAction: () => {
            dispatch({
                type: 'ORBIT_DELETE_CONTAINER',
                payload: {
                    container: containerName,
                    messagesId: deleteMessagesId,
                }
            });
        },
        updateMeta: (headers: OrbitContainerHeaders, updateMessagesId: string) => {
            dispatch({
                type: 'ORBIT_CONTAINER_UPDATE_META',
                payload: {
                    container: containerName,
                    headers,
                    messagesId: updateMessagesId,
                },
            });
        },
        setHistory: (headers: OrbitContainerHeaders, updateMessagesId: string) => {
            dispatch({
                type: 'ORBIT_CONTAINER_SET_HISTORY',
                payload: {
                    container: containerName,
                    headers,
                    messagesId: updateMessagesId,
                },
            });
        },
        createAndGrantApiClient: (name: string, access: ReadWriteFlag, headers: OrbitContainerHeaders, updateMessagesId: string) => {
            dispatch({
                type: 'ORBIT_CONTAINER_NEW_API_CLIENT',
                payload: {
                    container: containerName,
                    apiClientName: name,
                    access,
                    headers,
                    messagesId: updateMessagesId,
                },
            });
        },
        refresh: () => {
            dispatch({ type: 'ORBIT_REFRESH_CONTAINER', payload: { container: containerName, isRetry: false, } });
        }
    };
}

export function getObjectDownloadUrl(lifetimeSec: number, currAccountId: string, containerName: string, object: OrbitObject, key: string, method: string): [URL, Date] {
    const now = Date.now();
    const expires = Math.floor(now / 1000) + lifetimeSec;
    const url = new URL(`${orbitUrl}/${currAccountId}/${containerName}/${object.name}`);

    const hmac_body = [method, expires, decodeURIComponent(url.pathname)].join('\n');
    const sig = createHmac('sha256', key).update(hmac_body).digest('hex');

    url.searchParams.append('temp_url_sig', sig);
    url.searchParams.append('temp_url_expires', '' + expires);

    return [
        url,
        new Date(now + lifetimeSec * 1000)
    ];
}

let abortContainerKeysPolling = null;

export async function addContainerKeys(currAccountId: string, containerName: string, object: OrbitObject, dispatch: Dispatch<OrbitAction | MessageAction>, messagesId: string): Promise<{ key: ?string, error: boolean, cancelled: boolean, }> {
    const localAbortFlag = abortContainerKeysPolling = Symbol();

    const newKey = genTempUrlKey();
    const newKey2 = genTempUrlKey();

    dispatch({
        type: 'MESSAGE_MESSAGE',
        payload: {
            id: messagesId,
            status: RC_API_REQUEST,
            resource: null,
        }
    });

    await fetchOrbitAuth();

    let [err,] = await to(orbit({
        url: `${orbitUrl}/${currAccountId}/${containerName}/`,
        method: 'PUT',
        headers: {
            'x-container-meta-temp-url-key': newKey,
            'x-container-meta-temp-url-key-2': newKey2,
        },
    }));

    dispatch({
        type: 'ORBIT_REFRESH_CONTAINER',
        payload: {
            container: containerName,
            isRetry: false,
        }
    });

    if (err) {
        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_ERROR,
                resource: ['set_key_failed'],
            }
        });

        return { key: null, error: true, cancelled: false };
    }

    let success = false;

    while(success !== true && localAbortFlag === abortContainerKeysPolling) {
        const [url, ] = getObjectDownloadUrl(5, currAccountId, containerName, object, newKey, 'HEAD');
        const [, response] = await to(fetch(url.toString(), { method: 'HEAD', }));
        if (response?.status === 200) {
            success = true;
            break;
        }
        await asyncTimeout(1500);
    }

    if (localAbortFlag !== abortContainerKeysPolling) {
        // don't dispatch a MESSAGE_MESSAGE - the canceller will just
        // clear() the message. Keeps the dialog state sane.
        return { key: null, error: false, cancelled: true };
    }

    if (!success) {
        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id: messagesId,
                status: RC_ERROR,
                resource: ['polling_failed'],
            }
        });

        return { key: null, error: true, cancelled: false };
    }

    dispatch({
        type: 'MESSAGE_MESSAGE',
        payload: {
            id: messagesId,
            status: RC_SUCCESS,
            resource: null,
        }
    });

    return { key: newKey, error: false, cancelled: false, };
}

export type ConfirmResolve = (confirmed: boolean) => void;
export type KeyUse = 'download' | 'share';

export type OrbitAccessKeysHook = {
    +confirmCreateKeys: (keyUse: KeyUse, object: OrbitObject) => Promise<?string>,
    +confirmKeysDialog: DialogState<KeyUse>,
    +messages: MessageHook<BbOrbitContainer>,
    +updateDelayed: boolean,
}

export function useOrbitAccessKeys(containerName: string): OrbitAccessKeysHook {
    const { next, messages } = useTriggeredMessages<BbOrbitContainer>();
    const dispatch = useDispatch<Dispatch<OrbitAction | MessageAction>>();
    const accountMeta = useOrbitAccountMeta();
    const currAccountId = useCurrentAccountId() || '';
    const [dialogResolve, setDialogResolve] = useState<?ConfirmResolve>(null);
    const { container } = useOrbitContainerMeta(containerName);
    const meta = container?.meta;
    const confirmKeysDialog = useDialog<KeyUse>([{
        label: (data: ?KeyUse) => {
            if (data === 'download') return 'Set Keys + Download File';
            if (data === 'share') return 'Set Keys + Create URL';
            return 'Set keys';
        },
        kind: 'primary',
        onSelect: (data: ?KeyUse) => {
            if (dialogResolve) dialogResolve(true);
        }
    }], () => {
        if (dialogResolve) dialogResolve(false);
        cancelPoll();
        messages.clear();
    });
    const [updateDelayed, setUpdateDelayed] = useState(false);
    const cancelPoll = useCallback(() => {
        abortContainerKeysPolling = Symbol();
    }, []);

    return {
        confirmCreateKeys: async (keyUse: KeyUse, object: OrbitObject): Promise<?string> => {
            let key = accountMeta?.tempUrlKey1 || accountMeta?.tempUrlKey2
                || meta?.tempUrlKey1 || meta?.tempUrlKey2;

            // dialog is already showing.
            if (key == null && dialogResolve != null) return null;

            if (key == null) {
                const [, confirmed] = await to(new Promise<boolean>((resolve) => {
                    setDialogResolve((): ConfirmResolve => resolve);
                    confirmKeysDialog.show(keyUse);
                }));
                setDialogResolve(null);

                if (!confirmed) return null;

                const messagesId = next();

                const timeout = setTimeout(() => {
                    setUpdateDelayed(true);
                }, 1500 * 5);

                const result = await addContainerKeys(currAccountId, containerName, object, dispatch, messagesId);

                clearTimeout(timeout);
                setUpdateDelayed(false);

                key = result.key;
            }

            return key;
        },
        confirmKeysDialog,
        messages,
        updateDelayed,
    };
}

const sort: ListSortDef<OrbitTreeNode> = {
    name: 'objects',
    fields: {
        _default: (a: OrbitTreeNode, b: OrbitTreeNode) => a.id.localeCompare(b.id),
        last_modified: nullableDateSort('last_modified'),
        bytes: numberSort('bytes'),
    }
}

function useModeObjects(containerName: string, mode: ObjectsBrowserMode, details: ?BbOrbitContainer, page: ?ContainerObjectPage, desiredPath: string): ModeObjects {
    const hasCachedPage = page != null && page.objects != null;
    const tree = page?.tree;
    const pathObjects: $ReadOnlyArray<OrbitTreeNode> = useMemo(() => {
        const node = tree != null ? getPath(tree, desiredPath) : null;
        if (node != null) return node.children;
        else return [];
    }, [desiredPath, tree]);

    const { items: sortedItems, Th } = useSortedItems<OrbitTreeNode>(sort, pathObjects);
    const pager = usePager('objects', hasCachedPage ? sortedItems : null, pathObjects?.length);

    switch(mode) {
    case '':
        break;
    case 'flip':
        // these will only be an api paged set of objects
        return {
            mode,
            flip: {
                objects: page?.objects,
                next: page?.nextMarker,
                prev: (Array.isArray(page?.prev) && page.prev.length > 0)
                    ? page.prev[page.prev.length - 1]
                    : null,
                jumpToFirst: (
                    page?.prev?.length === 0
                    && page?.marker != null
                    && page?.marker !== ''
                    && (page?.objects?.length || 0) > 0
                )
                    ? ''
                    : null,
            },
            full: null,
        };
    case 'full':
        return {
            mode,
            flip: null,
            full: {
                Th,
                objects: page?.objects != null ? pager.items : null,
                allPrefixObjects: page?.objects != null ? sortedItems : null,
                pager: pager.pager,
            },
        };
    default:
        void (mode: empty);
    }

    return {
        mode,
        flip: null,
        full: null,
    };
}

export function useOrbitContainerObjects(containerName: string, desiredPath: string, marker: string): OrbitContainerObjectsView {
    const [mode, setMode] = useState<ObjectsBrowserMode>('');
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const [lastSeenCount, setLastSeenCount] = useState<?number>(null);
    const [pageSize, setPageSize] = useState<number>(PAGING_MIN_SIZE);

    useEffect(() => {
        if (mode === 'flip') {
            dispatch({
                type: 'ORBIT_CONTAINER_SET_PREFIX',
                payload: {
                    container: containerName,
                    prefix: desiredPath,
                    marker,
                    pageSize,
                    useNewestFlag: false,
                    useDelimiter: true,
                }
            });
        }
    }, [mode, desiredPath, dispatch, pageSize, containerName, marker]);

    useEffect(() => {
        if (mode === 'full') {
            dispatch({
                type: 'ORBIT_CONTAINER_FETCH_ALL_OBJECTS',
                payload: {
                    container: containerName,
                    useNewestFlag: false,
                }
            });
        }
    }, [mode, dispatch, containerName]);

    const [details, page, containersFetched] = useSelector((state: CloudGuiState): [?BbOrbitContainer, ?ContainerObjectPage, ResourceFetched] => {
        return [
            state.Orbit.containers.details.get(containerName),
            state.Orbit.containers.objects.get(containerName),
            state.Orbit.fetched,
        ];
    });

    const totalObjects: ?number = details?.count;
    useEffect(() => {
        if (totalObjects != null) {
            setMode(() => {
                if (totalObjects < FLIP_MODE_THRESHOLD) return 'full';

                return 'flip';
            });
        }
    }, [totalObjects, setMode]);

    useEffect(() => {
        if (containersFetched === RC_INITIAL) dispatch({ type: 'ORBIT_FETCH_CONTAINERS' });
    }, [containerName, containersFetched, dispatch]);

    const modeObjects = useModeObjects(containerName, mode, details, page, desiredPath);
    const objects = modeObjects?.full?.objects || modeObjects?.flip?.objects;

    const objectCount = Array.isArray(objects)
        ? objects.length
        : null;

    useEffect(() => {
        if (objectCount !== null) setLastSeenCount(Math.min(PAGING_MAX_SIZE, Math.max(1, objectCount)));
    }, [objectCount, setLastSeenCount]);

    let cacheStatus: OrbitContainerStatus = RC_INITIAL;
    if (details) cacheStatus = RC_ORBIT_CONTAINER;
    if (
        Array.isArray(objects)
        && (
            mode === 'full'
            ||
            (
                mode === 'flip'
                && page?.prefix === desiredPath
            )
    )) cacheStatus = RC_ORBIT_OBJECTS;
    if (details == null && containersFetched === RC_CACHED) cacheStatus = RC_NOT_FOUND;

    return {
        cacheStatus,
        lastSeenCount,
        pageSize, setPageSize,
        ...modeObjects,
        refresh: () => {
            switch(mode) {
            case '': return;
            case 'flip':
                dispatch({
                    type: 'ORBIT_CONTAINER_SET_PREFIX',
                    payload: {
                        container: containerName,
                        prefix: desiredPath,
                        marker,
                        pageSize,
                        useNewestFlag: true,
                        useDelimiter: true,
                    }
                });
                return;
            case 'full':
                dispatch({
                    type: 'ORBIT_CONTAINER_FETCH_ALL_OBJECTS',
                    payload: {
                        container: containerName,
                        useNewestFlag: true,
                    }
                });
                return;
            default:
                void (mode: empty);
            }
        }
    };
}

type OrbitAddDirectoryHook = {
    dialog: DialogState<string>,
    messages: MessageHook<null>,
    error: ?string,
    +onSave: () => void,
}

export function useOrbitContainerAddDirectory(containerName: string, path: string, refresh: () => void): OrbitAddDirectoryHook {
    const accountId = useCurrentAccountId();
    const dispatch = useDispatch<Dispatch<MessageStateAction>>();
    const { next, messages } = useTriggeredMessages();
    const [error, setError] = useState<?string>(null);

    const createDirectory = async (data: ?string) => {
        if (data == null || data === '') {
            setError('Directory name is required');
            return false;
        }

        const id = next();

        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id,
                status: RC_API_REQUEST,
            }
        });

        await fetchOrbitAuth();

        const dest = [orbitUrl, accountId, containerName, path + (data || '')].join('/');
        let [err,] = await to(orbit({
            url: dest,
            method: 'PUT',
            headers: {
                'content-type': 'application/directory'
            },
            data: '',
        }));

        if (err) {
            toast(
                'Error making directory',
                { type: 'err', }
            );
        }
        dispatch({
            type: 'MESSAGE_MESSAGE',
            payload: {
                id,
                status: RC_SUCCESS,
            }
        });

        refresh();
    }

    const dialog = useDialog([
        {
            label: 'Create Directory',
            kind: 'primary',
            onSelect: createDirectory,
        },
    ]);

    return {
        dialog,
        messages,
        error,
        onSave: () => {
            dialog.setActioned(0);
            createDirectory(dialog.data);
        },
    };
}

export function useContainerMetaEditor<EditValue>(
    id: string,
    container: OrbitContainerView,
    editUri: string,
    onEdit: (meta: OrbitContainerMeta, match: Match) => EditValue,
    onValidate: (value: EditValue) => [?FormErrors, ?OrbitContainerHeaders],
    path: string,
    successToast: ?string,
    onSave: ?(value: EditValue, headers: OrbitContainerHeaders, messagesId: string) => void,
): EditorModal<EditValue, FormErrors, OrbitContainerMeta> {
    const { next, messages } = useTriggeredMessages<OrbitContainerMeta>();
    const errors = useEditorErrors(emptyErrors);
    // const clearMessages = resource.apiResult ? resource.apiResult.clear : null;
    const [value, setValue, state, setState] = useMessagesDrivenEditor<OrbitContainerMeta, EditValue, FormErrors>(
        errors,
        path + editUri + '/',
        container.cacheStatus,
        messages,
        () => container.container?.meta ? container.container.meta : null,
        onEdit
    );

    useEffect(() => {
        if (successToast && messages.status === RC_SUCCESS) {
            toast(successToast, { type: 'success' });
        }
    }, [messages, successToast]);

    return {
        status: state === 'editing' ? 'edit' : false,
        editUri,
        value,
        setValue: (val: ?EditValue) => setValue(val),
        messages,

        onCancel: () => {
            setState('initial');
            historyBack();
        },
        onSave: () => {
            if (value != null) {
                const [errs, saveVal] = onValidate(value);
                if (errs) {
                    errors.setErrors(errs);
                } else if (saveVal) {
                    messages.clear();
                    errors.clearErrors();
                    setState('saving');
                    onSave
                        ? onSave(value, saveVal, next())
                        : container.updateMeta(saveVal, next());
                }
            }
        },

        ...errors,

    };

}

