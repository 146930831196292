// @flow

import { useState, } from 'react';
import { useOrbitContainerCreate, useContainerNames } from '../../hoc/Orbit';
import { useEditorErrors, } from '../../common/Editor';
import { useCurrentAccountId } from '../../hoc/lib';
import { getContainerRegistryUrl } from '../../../api/adapter.orbit';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelBar, PanelButtonBar, } from '../../element/Panel';
import { TextInput } from '../../element/Input';
import { Notice } from '../../element/Notice';
import { historyBack } from '../../../lib/history';
import { PerGigPrice } from '../../element/PerGigPrice';
import { LABELS } from '../../element/ResourceLabels';

import type { BbOrbitContainerParams } from '../../../api/type.orbit';
import type { Match } from 'react-router';

export function ContainerRegistryCreate({ match }: { match: Match }): React$Node {
    const { create, messages } = useOrbitContainerCreate();
    const errors = useEditorErrors<?string>(null);
    const accountId = useCurrentAccountId() || '';
    const currContainers = useContainerNames();

    const [createParams, setCreateParams] = useState<BbOrbitContainerParams>({
        name: '',
    });

    const validateAndCreate = () => {
        let valid = true;

        if (createParams.name === '' || createParams.name.match(/\//)) {
            errors.setErrors('Please enter a valid name');
            valid = false;
        }

        if (currContainers.indexOf(createParams.name + '_ctrimages') !== -1) {
            errors.setErrors('A Container Repository with this name already exists');
            valid = false;
        }

        if (valid) {
            create({
                name: createParams.name + '_ctrimages',
            });
        }
    };

    const [containerUrl,] = getContainerRegistryUrl(accountId, (createParams.name !== '' ? createParams.name : '<container name>') + '_ctrimages');

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.container_registry.title}
            match={match}
            dialog={null}
            view={
                <>
                    <Panel>
                        <PanelHeader title='New Repository' />
                        <PanelBar>
                            <TextInput
                                label='Name'
                                value={createParams.name}
                                onChange={(name: string) => setCreateParams({ name })}
                                autoFocus={true}
                                postText='_ctrimages'
                                errorText={errors.errors}
                                className='w-96'
                            />
                            <Notice icon='registry'>
                                Repository URL<br/>
                                <pre><a target="_blank" rel='noopener noreferrer' href={containerUrl}>{containerUrl}</a></pre>                            </Notice>
                        </PanelBar>
                        <PanelButtonBar
                            cacheStatus={messages.status}
                            primaryButton={{
                                kind: 'primary',
                                onClick: validateAndCreate,
                                children: 'Create Repository',
                            }}
                            cancel={() => historyBack()}
                        >
                            <PerGigPrice id='orbit' label='Container Registry' />
                        </PanelButtonBar>
                    </Panel>
                </>
            }
        />
    );
}