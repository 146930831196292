// @flow

import { Panel, PanelHeader, PanelSettingsBar } from '../../../element/Panel';
import { TextSetting } from '../../../element/Setting/TextSetting';
import { Notice } from '../../../element/Notice';

import type { EditorModal } from '../../../common/Editor';
import type { BbCloudIp } from '../../../../api/type.cip';
import type { ResourceCacheStatus } from '../../../../state/resource/type';

export type CipMaintenanceProps = {
    +cloudIp: ?BbCloudIp,
    +reverse_dns: [string, EditorModal<string, null, BbCloudIp>],
    +cacheStatus: ResourceCacheStatus,
};

export const CipAdvanced = ({ cloudIp, reverse_dns, cacheStatus,  }: CipMaintenanceProps): React$Node => {
    let settings = [];
    settings.push({
            name: 'Reverse DNS',
            summary: reverse_dns[0],
            route: cloudIp ? reverse_dns[1].editUri : null,
        },
    );

    return (
        <Panel>
            <PanelHeader
                title='Settings'
            />
            <PanelSettingsBar
                cacheStatus={cacheStatus}
                settings={settings}
            >
            </PanelSettingsBar>
        </Panel>
    );
};

export const CipReverseDnsEdit = ({ editor, cloudIp, }: { editor: EditorModal<string, null, BbCloudIp>, cloudIp: ?BbCloudIp, }): React$Node => (
    <TextSetting
        title='Reverse DNS'
        label='DNS name'
        editor={editor}
        className='w-80'
        helperText={
            cloudIp?.reverse_dns.indexOf('brightbox.com') === -1
            ? 'Leave blank to reset to default'
            : null
        }
    >
        <Notice type='info' icon='pin'>
            The custom reverse DNS name must already resolve to <b className='mr-2'>{cloudIp?.public_ipv4}</b> <a href='https://www.brightbox.com/docs/reference/dns/#cloud-ip-reverse-dns' target='blank'>Learn more</a>
        </Notice>
    </TextSetting>
)
