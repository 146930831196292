// @flow
import { Link } from 'react-router-dom';
import { useCurrentAccountId, useCurrentUser } from '../hoc/lib';

type ResourceLinkProps = {
    id: string,
    className?: string,
}


export const ResourceLink = ({ id, className: extraClassName, ...rest }: ResourceLinkProps): React$Node => {
    const currAccountId = useCurrentAccountId() || '';
    const currUser = useCurrentUser();

    const prefix = id.substr(0, 3);

    switch(prefix) {
    case 'srv':
        return <Link {...rest} to={`/accounts/${currAccountId}/servers/${id}/`} className={extraClassName}>{id}</Link>
    case 'lba':
        return <Link {...rest} to={`/accounts/${currAccountId}/load_balancers/${id}/`} className={extraClassName}>{id}</Link>
    case 'dbs':
        return <Link {...rest} to={`/accounts/${currAccountId}/database_servers/${id}/`} className={extraClassName}>{id}</Link>
    case 'grp':
        return <Link {...rest} to={`/accounts/${currAccountId}/server_groups/${id}/`} className={extraClassName}>{id}</Link>
    case 'img':
        return <Link {...rest} to={`/accounts/${currAccountId}/images/${id}/`} className={extraClassName}>{id}</Link>
    case 'cip':
        return <Link {...rest} to={`/accounts/${currAccountId}/cloud_ips/${id}/`} className={extraClassName}>{id}</Link>
    case 'dbi':
        return <Link {...rest} to={`/accounts/${currAccountId}/database_snapshots/${id}/`} className={extraClassName}>{id}</Link>
    case 'cli':
        return <Link {...rest} to={`/accounts/${currAccountId}/settings/cli/${id}/`} className={extraClassName}>{id}</Link>
    case 'vol':
        return <Link {...rest} to={`/accounts/${currAccountId}/volumes/${id}/`} className={extraClassName}>{id}</Link>
    case 'acc':
        return <Link {...rest} to={`/accounts/${id}/`} className={extraClassName}>{id}</Link>
    case 'usr':
        if (currUser?.id === id) {
            return <Link {...rest} to={`/user/`} className={extraClassName}>{id}</Link>
        } else {
            return <Link {...rest} to={`/accounts/${currAccountId}/team/`} className={extraClassName}>{id}</Link>
        }
    default:
        return id;
    }
};