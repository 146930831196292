// @flow
import { ValueSelect } from "../../element/ValueSelect";
import { TextInput } from "../../element/Input";

import type {  SearchEditorHook } from "../../element/Search";
import type { ValueSelectOptions } from "../../element/ValueSelect";

type ContainerRegistrySearchEditorProps = {
    +editor: SearchEditorHook,
    +imageNameChoices: ValueSelectOptions<string>
}

export const ContainerRegistrySearchEditor = ({ editor, imageNameChoices }: ContainerRegistrySearchEditorProps): React$Node => {
    const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <>
            <TextInput
                size='sm'
                label='Filter by keyword'
                value={editor.fields.searchText.value}
                onChange={(value) => editor.setSearchValue('searchText', value)}
                preIcon={'search'}
                {...post}
            />
            <ValueSelect
                selected={editor.fields.imageName.value}
                onSelect={(value) => editor.setSearchValue('imageName', value)}
                options={imageNameChoices}
                size='sm'
            />
        </>
    );
};