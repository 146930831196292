// @flow

import { SvgIcon } from './SvgIcon';
import { getBackgroundFromString } from '../common/lib';
import { RelatedPopover } from './RelatedPopover';
import { Counter } from './Counter';

import type { BbNestedCloudIp } from '../../api/type.cip';
import type { BbNestedServer } from '../../api/type.srv';
import type { BbNestedServerGroup } from '../../api/type';
import type { BbFirewallRule } from '../../api/type.fwp';

export type RelatedArrayTypes = BbNestedCloudIp | BbNestedServer | BbNestedServerGroup | BbFirewallRule;
export type RelatedEditLink = {
    +kind: 'cloud_ips' | 'servers' | 'server_groups',
    +label: string,
    +id: string,
}

export type RelatedProps = {
    +id: string,
    +related: $ReadOnlyArray<RelatedArrayTypes>,
    +editLink?: ?RelatedEditLink,
};

const INDICATOR_LIMIT = 5;

export const RelatedIndicator = ({ id, related, editLink, ...rest }: RelatedProps): React$Node => {
    const sliced: $ReadOnlyArray<RelatedArrayTypes> = related.slice(0, INDICATOR_LIMIT);

    return (
        <div className='c-related-indicator' {...rest}>
            <ul className='c-related-indicator__list'>
                {sliced.map((item: RelatedArrayTypes) =>
                    <RelatedPopover
                        related={[item]}
                        editLink={editLink}
                        parentId={id}
                        key={item.id}
                    >
                        <li
                            className={'c-related-indicator__item'}
                            style={item.resource_type === 'server_group' ? getBackgroundFromString(item.id) : null}
                        />
                    </RelatedPopover>
                )}
            </ul>
            {related.length > INDICATOR_LIMIT ? <SvgIcon svg='ellipsis-horizontal' className='c-related-indicator__ellipsis' /> : null}
            {related.length > 1
                ? <RelatedPopover parentId={id} related={related} editLink={editLink}>
                        <Counter className='c-related-indicator__count'>{related.length}</Counter>
                </RelatedPopover>
                : null}
        </div>
    );
};
