// @flow

import { useEffect, useState } from 'react';
import { createSelector } from "reselect";
import { useTriggeredMessages } from '../../hoc/Messages';
import { useEditorDirect } from '../../common/Editor';
import { useDispatch, useSelector } from 'react-redux';
import { useResourceSearch } from '../../element/Search';
import { useSortedItems } from '../../hoc/ListPage';
import { usePager } from '../../element/ArrayPager';
import { useItemSelect } from '../../common/ResourceSelector';
import { nullableDateSort, stringSort } from '../../element/Sort';
import { useDeleteDialog } from '../../common/CommonDialogs';
import { RC_SUCCESS } from '../../../state/resource/type';
import { createSearchAction, getSearchSelectors } from "redux-search";
import { resourceSelector } from "../../../state/Search/search";

import type { Dispatch } from 'redux';
import type { OrbitBulkDeleteObjectsAction } from '../../../state/Orbit/type';
import type { ItemSelectHook, ItemSelectOptions } from '../../common/ResourceSelector';
import type { ContainerImageTags, ImageTag } from '../../hoc/orbit/ContainerRegistry';
import type { CloudGuiState } from '../../../state/cloudgui';
import type { ListSortDef, ThOwnProps } from '../../hoc/ListPage';
import type { SearchDefinition, SearchEditorHook } from '../../element/Search';
import type { PagerBarProps } from '../../element/PagerBar';
import type { DialogState } from '../../element/Dialog';

export type ImageTagList = {
    +selectOptions: ItemSelectOptions<ImageTag>,
    +multiDeleteDialog: DialogState<null>,

    items: $ReadOnlyArray<ImageTag>,
    +Th: React$StatelessFunctionalComponent<ThOwnProps>,
    +pager: PagerBarProps,
    +searchEditor: SearchEditorHook,

    +itemSelect: ?ItemSelectHook<ImageTag>,
    +deleteTag: (x: ImageTag) => void,
}

const sort: ListSortDef<ImageTag> = {
    name: 'crImageTags',
    fields: {
        _default: (a: ImageTag, b: ImageTag) => a.id.localeCompare(b.id),
        id: (a: ImageTag, b: ImageTag) => a.id.localeCompare(b.id),
        last_modified: nullableDateSort('last_modified'),
        sha256: stringSort('sha256'),
    }
}

const { result } = getSearchSelectors({ resourceName: 'crImageTags', resourceSelector });

const getSearchTextKind = createSelector(
    [result,],
    (result) => ({
        searchMatchIds: result,
    }));

const crImageSearchText = createSearchAction('crImageTags');

const imageSearchDef: SearchDefinition<ImageTag> = {
    name: 'crImageTags',
    fields: [
        {
            searchFieldName: 'searchText',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<ImageTag>) => {
                const { searchMatchIds, } = (getSearchTextKind(state): { searchMatchIds: Array<string> });
                return searchMatchIds.findIndex(x => x === item.id) !== -1;
            },
            setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                dispatch(crImageSearchText(nextValue));
            },
        },
        {
            searchFieldName: 'imageName',
            matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<ImageTag>) => {
                return item.name === fieldValue;
            },
        },
    ],
}

function objectPathsForTag(x: ImageTag): $ReadOnlyArray<string> {
    return [
        // delete the current/link file, which has the sha256 as its content
        x.id,
        // and delete the index files, which... also has the sha256 as its content.
        ...x.indexes,
    ]
}

export const useImageTagList = (container: string, imageTags: ContainerImageTags): ImageTagList => {
    const {editor: searchEditor, items: searchedItems} = useResourceSearch(imageSearchDef, imageTags.items);

    const {items: sortedItems, Th} = useSortedItems<ImageTag>(sort, searchedItems);
    const pager = usePager('imageTags', sortedItems, imageTags.items.length);

    const [selected, setSelected] = useState<$ReadOnlyArray<string>>([]);
    const selectionEditor = useEditorDirect(
        selected,
        setSelected,
        null
    );
    const pending = useSelector<CloudGuiState, ?Set<string>>(state => state.ActionQueue.orbitObjectActions.get(container));
    const { setValue: setSelection } = selectionEditor;

    const selectOptions: ItemSelectOptions<ImageTag> = {
        editor: selectionEditor,
        multiItemActions: [
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: () => multiDeleteDialog.show(),
                },
                disabledTooltip: null,
            },
        ],
        isPending: (c: ImageTag) => {
            return pending != null && pending.has(c.id);
        },
    };

    const itemSelect = useItemSelect<ImageTag>(
        imageTags.items, pager.items, selectOptions, 'list'
    );

    const dispatch = useDispatch<Dispatch<OrbitBulkDeleteObjectsAction>>();

    const { next, messages: deleteMessages, } = useTriggeredMessages();
    const [, multiDeleteDialog] = useDeleteDialog(
        true,
        () => {
            const selectedObjectPaths = new Set(selectionEditor.value || []);

            const deleteObjects = imageTags.items
                .filter(x => selectedObjectPaths.has(x.id))
                .flatMap((tag) => {
                    return objectPathsForTag(tag);
                });

            dispatch({
                type: 'ORBIT_BULK_DELETE_OBJECTS',
                payload: {
                    container,
                    objects: deleteObjects,
                    messagesId: next(),
                    refreshObjects: false,
                }
            });
        }
    );
    const { status: deleteStatus, objects: deletedObjects } = deleteMessages;

    useEffect(() => {
        if (deleteStatus === RC_SUCCESS) {
            setSelection([]);
            dispatch({
                type: 'ORBIT_REMOVE_CACHED_OBJECTS',
                payload: {
                    container,
                    objects: deletedObjects
                }
            })
        }
    }, [deleteStatus, setSelection, deletedObjects, dispatch, container])

    return {
        items: pager.items,
        Th: Th,
        pager: pager.pager,
        searchEditor,
        itemSelect,

        selectOptions,

        multiDeleteDialog,

        deleteTag: (tag: ImageTag) => {
            dispatch({
                type: 'ORBIT_BULK_DELETE_OBJECTS',
                payload: {
                    container,
                    objects: objectPathsForTag(tag),
                    messagesId: next(),
                    refreshObjects: false,
                }
            });
        }
    };
};