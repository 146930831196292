// @flow

import type { FormErrors } from '../../../common/lib';
import type { BbApiDbsAccessRule, BbDatabaseServerParams } from '../../../../api/type.dbs';
import type { SnapshotRetentionValues } from '../../../common/SnapshotRetentionEdit';
import type { DbsAccessControlRuleEdit } from "./DbsAccessControlTableRow";

// use the default prompt in the gui as the error message; the size of the input box
// is tied to the length of this prompt, so this prevents some ui resizing jankiness
export const CIDR_PROMPT = 'Provide a valid subnet in CIDR notation';

export function validateAccess(access: $ReadOnlyArray<DbsAccessControlRuleEdit>): [?FormErrors, ?$Shape<BbDatabaseServerParams>] {
    let errors = new Map<string, string>();
    let commit: Array<BbApiDbsAccessRule> = [];

    access.forEach(({ source, description }, idx) => {
        if (source.kind === 'addr' && source.value === '') {
            errors.set('addr_' + idx, CIDR_PROMPT);
        }

        let commitEntry: BbApiDbsAccessRule = {
            source: source.kind === 'any' ? source.kind : source.value,
        }
        // honcho sets a default if nothing has been entered.
        // for that to work, the prop to be absent in the request
        if (description !== '') {
            commitEntry.description = description;
        }

        commit.push(commitEntry);
    });

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? { access_control: commit } : null,
    ];
}

export function validateRetention(value: SnapshotRetentionValues): [?string, ?$Shape<BbDatabaseServerParams>] {
    if (value.automatic && (isNaN(value.count) || Number(value.count) <= 0)) {
        return ['You must retain at least 1 backup', null];
    }

    return [null, { snapshots_retention: value.automatic ? value.count : '' } ];
}

export const defaultSnapshotSchedule = '0 8 * * *';