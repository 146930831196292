// @flow

import React from "react";
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { useCountryListChoices } from '../../../hoc/lib';
import { ValueSelect } from '../../../element/ValueSelect';

import type { EditorModal } from '../../../common/Editor';
import type { BbAccountParams, BbAccount } from '../../../../api/type.acc';

type AccountAddressEditProps = {
    +editor: EditorModal<BbAccountParams, null, BbAccount>
}

export const AccountAddressEdit = ({ editor }: AccountAddressEditProps): React$Node => {
    const { value: editAccount, setValue: setEditAccount } = editor;

    const countryChoices = useCountryListChoices();

    if (!editAccount) return null;

    return (
        <Panel>
            <PanelHeader
                title={'Billing Address'}
                description={'Provide a billing address to be included on your invoices'}
            />

            <PanelBar>
                <TextInput
                    label='Address 1'
                    value={editAccount?.address_1 || ''}
                    onChange={(value) => setEditAccount({ ...editAccount, address_1: value })}
                />
                <br/>
                <TextInput
                    label='Address 2'
                    value={editAccount?.address_2 || ''}
                    onChange={(value) => setEditAccount({ ...editAccount, address_2: value })}
                />
                <br/>
                <TextInput
                    label='City'
                    value={editAccount?.city || ''}
                    onChange={(value) => setEditAccount({ ...editAccount, city: value })}
                />
                <br/>
                <TextInput
                    label='County'
                    value={editAccount?.county || ''}
                    onChange={(value) => setEditAccount({ ...editAccount, county: value })}
                />
                <br/>
                <TextInput
                    label='Postcode'
                    value={editAccount?.postcode || ''}
                    onChange={(value) => setEditAccount({ ...editAccount, postcode: value })}
                />
                <br/>
                <ValueSelect
                    label='Country'
                    options={countryChoices}
                    selected={editAccount?.country_code || ''}
                    onSelect={(value) => setEditAccount({ ...editAccount, country_code: value })}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    )
}
