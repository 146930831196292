// @flow

import { useState, useCallback } from 'react';
import type { ValueSelectChoice } from '../ValueSelect';

type InternalSelectedState<V> = {
    group: string,
    selectedForGroup: { [key: string]: V, },
    selected: ?V
};

export type GroupedItemProps<V> = {
    selectGroup: (group: string) => void,
    selectItem: (next: V, group: ?string) => void,
    internalSelected: InternalSelectedState<V>,
};

export type GroupDefinition<V> = {
    +label?: string,
    +default: V,
    +items: $ReadOnlyArray<ValueSelectChoice<V>>,
}

export type GroupedChoices<V> = {
    [groupName: string]: GroupDefinition<V>,
    ...
};

export const useGroupedItemState = <V>(selected: ?V, groups: GroupedChoices<V>, onSelect: (next: V) => void): GroupedItemProps<V> => {
    const [internalSelected, setSelected] = useState<InternalSelectedState<V>>(() => {
        const allGroups = Object.keys(groups);
        let selectedGroup = allGroups?.[0];

        let selectedForGroup = allGroups.reduce((acc, group) => {
            if (acc != null) return acc;

            let found = groups[group].items.find(x => x.value === selected);

            if (found) {
                selectedGroup = group;
                return {
                    [group]: found.value,
                }
            }

            return null;
        }, null) || ({}: any);

        return ({
            group: selectedGroup,
            selectedForGroup,
            selected
        }: InternalSelectedState<V>);
    });

    const selectGroup = (group: string) => {
        let switchTo = null;

        if (group in internalSelected.selectedForGroup) {
            switchTo = internalSelected.selectedForGroup[group];
        } else if (group in groups) {
            switchTo = groups[group].default;
        }

        if (switchTo) {
            setSelected({ ...internalSelected, selectedForGroup: { ...internalSelected.selectedForGroup, [group]: switchTo }, group, selected: switchTo });
            onSelect(switchTo);
        } else {
            // hmm - we should never get here.
            setSelected({ ...internalSelected, group });
        }
    };

    const selectItem = useCallback((next: V, group: ?string,) => {
        if (group) {
            setSelected((internalSelected) => ({
                selected: next,
                group,
                selectedForGroup: {
                    ...internalSelected.selectedForGroup,
                    [group]: next
                },
            }));
        } else {
            setSelected((internalSelected) => ({ ...internalSelected, selected: next }));
        }
        onSelect(next);
    }, [setSelected, onSelect]);

    return { selectGroup, selectItem, internalSelected };
};