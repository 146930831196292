// @flow
import { ServerSelector } from '../../../common/ServerSelector';
import { Panel, PanelHeader } from '../../../element/Panel';
import { VolAttachDialog } from '../VolDialogs';
import { HostVolumeConflictDialog } from '../../server/edit/HostVolumeConflictDialog';
import { TextOnlySearchEditor } from '../../../common/TextOnlySearch';


import type { VolumeAttachEditorHook } from '../../../hoc/Volumes';
import type { BbCollectedServer } from '../../../../api/type.srv';

type VolAttachToServerEditorProps = {
    ...VolumeAttachEditorHook,
    +volumeId: string,
    +volumeZoneId: string,
}

function notDeletedAndZoneMatches(volZoneId: string, server: BbCollectedServer): boolean {
    return (
        server.status !== 'deleted' && server.status !== 'deleting' && server.status !== 'failed'
        && server.zone?.id === volZoneId
    );
}
export function VolAttachToServerEditor({ editor, confirmDialog, selectedServer, volumeId, volumeZoneId, }: VolAttachToServerEditorProps): React$Node {
    return (
        <>
            <VolAttachDialog confirmDialog={confirmDialog} editor={editor} />
            <HostVolumeConflictDialog messages={editor.messages} serverId={selectedServer?.id} volumeId={volumeId} />
            <Panel>
                <PanelHeader
                    title='Attach Volume'
                    description='Choose a Cloud Server to attach this Volume'
                />
                <ServerSelector
                    editor={editor}
                    selectSingle={true}
                    confirmDialog={confirmDialog}
                    filter={notDeletedAndZoneMatches.bind(null, volumeZoneId)}
                    searchComponent={TextOnlySearchEditor}
                    leftButton={{
                        kind: 'bare',
                        preIcon: 'info-fill',
                        children: <a href='https://www.brightbox.com/docs/guides/volumes/attach-detach-volume/' target='blank' rel='noopener noreferrer'>Attaching Volumes</a>,
                    }}
                />
            </Panel>
        </>
    );
}

