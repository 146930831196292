// @flow

import { Table, Td, Th, Tr } from '../../../element/Table';
import { Panel, PanelBar, PanelHeader } from '../../../element/Panel';
import { history } from '../../../../lib/history';
import { isShowDetailsResourceStatus, SkeletonBar, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { BbLbaListener, } from '../../../../api/type.lba';
import type { ResourceCacheStatus } from '../../../../state/resource/type';

export type LbaListenerListProps = {
    +cacheStatus: ResourceCacheStatus,
    +editUri: ?string,
    +listeners: ?$ReadOnlyArray<BbLbaListener>,
    +needsHttps: boolean,
};

export const LbaListener = ({ editUri, needsHttps, cacheStatus, ...props }: LbaListenerListProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title='Listeners'
                actions={editUri ? { edit: () => history.push(editUri + '/'), } : null}
            />
            <PanelBar padding={false}>
                <Table>
                    <thead>
                    <Tr>
                        <Th>From</Th>
                        <Th>To</Th>
                        <Th>Protocol</Th>
                        <Th>Timeout</Th>
                        <Th stretch={true}>Proxy Protocol</Th>
                    </Tr>
                    </thead>
                    <tbody>
                    {(props.listeners || []).map((listener: BbLbaListener, idx: number) =>
                        <Tr key={idx}>
                            <Td>{listener.in}</Td>
                            <Td>{listener.out}</Td>
                            <Td>{listener.protocol.toUpperCase()}</Td>
                            <Td>{listener.timeout / 1000} <small className='text-gray-500'>sec</small></Td>
                            <Td>{listener.proxy_protocol || 'None'}</Td>
                        </Tr>
                    )}
                    {!isShowDetailsResourceStatus(cacheStatus)
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                                <Td><SkeletonBar size='sm' /></Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : null
                    }
                    </tbody>
                </Table>
            </PanelBar>
        </Panel>
    );
};

