// @flow

import type { FilterState, FilterStateAction } from './type';
import type { StoreClearAction } from '../type';

const emptyState: FilterState = new Map();

export const FilterReducer = (
    state: FilterState = emptyState,
    action: FilterStateAction | StoreClearAction
): FilterState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'FILTER_SET_FILTER_VALUE':
        const { filterName, field, value } = action.payload;
        return new Map([
            ...state,
            [
                filterName,
                new Map([
                    ...(state.get(filterName) || []),
                    [ field, value ],
                ])
            ]
        ]);
    default:
        void (action: empty);
        break;
    }

    return state;
};