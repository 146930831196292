// @flow
import { Link } from 'react-router-dom';
import { formatDate, RightArrow} from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceListPage } from '../../element/ResourceListPage';
import { TextAndZoneSearchEditor } from '../../common/TextAndZoneSearch';
import { ResourceChip } from '../../element/Chip';
import { dbsSearchDef, dbsSortFields, databaseServerSelectable } from './def';
import { ResourceCloudIpTd } from '../../common/ResourceCloudIps';
import { Tooltip } from '../../element/Tooltip';
import { Card } from '../../element/Card';
import { TypeCardContent } from '../../element/TypeCardContent';
import { Pill } from '../../element/Pill';
import { useDatabaseEngineTypes } from '../../hoc/Database';
import { getMonthlyPrice } from '../../../lib/pricing';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, SkeletonListRelated } from '../../element/Skeleton';
import { ItemSelector } from '../../element/ItemSelector';
import { useLockDeleteListActions } from '../../hoc/ListActions';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';

import type { BbCollectedDatabaseServer } from '../../../api/type.dbs';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';


export const DatabaseServerListTable = ({ Th: SortTh, items, search, itemSelect, status, totalCount, context, }: ResourceSelectorTableProps<BbCollectedDatabaseServer>): React$Node => {
    const listContext = context === 'list';
    const { engineLabel } = useDatabaseEngineTypes();

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={6}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='name'>Name</SortTh>
                        <SortTh field='database_engine'>Engine</SortTh>
                        <SortTh field='cloud_ips'>Cloud IPs</SortTh>
                        <SortTh field='disk_size'>Size</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((dbs: BbCollectedDatabaseServer) =>
                <Tr
                    key={dbs.id}
                    {...itemSelect?.rowProps(dbs)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={dbs} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}>
                        <ResourceChip concise={true} resource={dbs} link={itemSelect?.selectedLength === 0 ? `${dbs.id}/` : null} />
                    </Td>
                    <Td>{engineLabel(dbs.database_engine, dbs.database_version)}</Td>
                    <Td><ResourceCloudIpTd id={dbs.id} cloud_ips={dbs.cloud_ips} canAdd={!itemSelect && (dbs.status === 'creating' || dbs.status === 'active')} /></Td>
                    <Td>
                        {dbs.database_server_type.name}
                        <Tooltip background='light' overlay={
                            <Card>
                                <TypeCardContent
                                    id={dbs.database_server_type.id}
                                    ram={dbs.database_server_type.ram}
                                    cpus={dbs.database_server_type.cores}
                                    disk={dbs.database_server_type.disk_size}
                                    cost={getMonthlyPrice(dbs.database_server_type.id)}
                                    resourceTitle='Cloud SQL Instances'
                                />
                            </Card>
                        }>
                            <Pill>?</Pill>
                        </Tooltip>
                    </Td>
                    <Td>{formatDate(dbs.created_at)}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <Link to={`${dbs.id}/`}>
                                <RightArrow />
                            </Link>
                        </Td>
                        : null}
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonBar size='sm' /></Td>
                        <Td><SkeletonListRelated /></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={7} kind='database_server' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={7} context={context} kind='database_server' />
            </tbody>
        </Table>
    );
};

export const DbsList = (): React$Node => {
    const { deleteDialog, lockDialogs, ...selector } = useLockDeleteListActions('database_server', 'database servers', databaseServerSelectable);

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Cloud SQL Instances?`}
                confirmString='delete'
                render={() => (
                    <div>Are you sure you want to delete these Cloud SQL Instances?</div>
                )
                }
            />
            <LockDialogs name={'these Cloud SQL Instances'} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog} count={selector.editor?.value?.length}/>

            <ResourceListPage
                title='Cloud SQL Instances'
                kind='database_server'
                context='list'
                table={DatabaseServerListTable}
                searchDef={dbsSearchDef}
                searchComponent={TextAndZoneSearchEditor}
                sortFields={dbsSortFields}
                checkLimit='dbs_ram'
                selector={selector}
            />
        </>
    );
}