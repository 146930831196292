// @flow

import { useState, } from 'react';
import { AddButton, RemoveButton, } from '../../../element/Button';
import { Panel, PanelBar, PanelButtonBar, PanelHeader, } from '../../../element/Panel';
import { SettingsTable } from '../../../element/Setting/Settings';
import { ToggleGroup } from '../../../element/ToggleGroup';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';
import { FileInput } from '../../../element/FileInput';
import { Link } from 'react-router-dom';
import { useDialog, Dialog } from '../../../element/Dialog';
import { AcmeDomain, Status } from '../../../element/Styled';
import { Tooltip } from '../../../element/Tooltip';
import { useAcmeDomainUiState } from './lib';
import { AcmeDomainStatus, CertificateDetailsContent } from './LbaHttps';
import { Button } from '../../../element/Button';

import type { ValueSelectChoice } from '../../../element/ValueSelect';
import type { EditHttps } from './type';
import type { BbLba, BbLbaHttps, BbAcmeState, BbAcmeCert } from '../../../../api/type.lba';
import type { Editor } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { GuiAcmeDomain } from './lib';

type LbaHttpsEditProps = {
    +editor: Editor<EditHttps, FormErrors, BbLba>,
    +hasCloudIp: boolean,
    +acme?: ?BbAcmeState,
};

const httpsChoices: $ReadOnlyArray<ValueSelectChoice<BbLbaHttps>> = [
    { value: 'none', label: 'None' },
    { value: 'acme', label: 'Let\'s Encrypt HTTPS' },
    { value: 'pem', label: 'Custom HTTPS' },
];

type SubEditorProps = {
    +editHttps: EditHttps,
    +setEditHttps: (next: EditHttps) => void,
    +errors: FormErrors,
    +hasCloudIp: boolean,
    +acme?: ?BbAcmeState,
};

export const LbaHttpsEdit = ({ editor, hasCloudIp, acme }: LbaHttpsEditProps): React$Node => {
    const confirmHttpsDialog = useDialog([
        {
            color: 'red',
            kind: 'primary',
            label: 'Remove HTTPS',
            onSelect: () => editor.status === 'edit' ? editor.onSave() : null,
        }
    ]);
    const { value: editHttps } = editor;
    const [forcePemEditor, setForcePemEditor] = useState<boolean>(false);

    if (!editHttps) return null;

    const setMethod = (nextMethod: ?BbLbaHttps) => {
        let next = { ...editHttps, method: nextMethod || 'none' };
        if (nextMethod === 'pem') {
            // clear this out as the file inputs are added/removed according to selected method,
            // which might mean we have file content from a previous selection, but the file
            // inputs won't show it as selected.
            next.pem = ({}: any);
        }
        if (nextMethod === 'acme' && editHttps.domains.length === 0) {
            next.domains = [''];
        }
        editor.setValue(next);
    };

    const primaryClick = editor.status === 'edit'
        ? (editHttps.currentState.was_https && editHttps.method === 'none' ? confirmHttpsDialog.show : editor.onSave)
        : null;

    return (
        <>
            <Dialog
                dialog={confirmHttpsDialog}
                title='Remove HTTPS Settings?'
                render={() =>
                    <div>
                        <p>Are you sure you want to remove the existing HTTPS settings from this load balancer?</p>
                        <p>Any existing certificates will cease to work.</p>
                    </div>}
            />
            <Panel>
                <PanelHeader
                    title='HTTPS'
                    description='How should the load balancer handle HTTPS requests?'
                />
                <PanelBar>
                    <ToggleGroup
                        id='https_type'
                        selected={editHttps.method}
                        options={httpsChoices}
                        onSelect={(method: ?BbLbaHttps) => setMethod(method)}
                    />
                    {editHttps.method === 'none' && editor.status !== 'add'
                        ? <HttpsNoneEdit errors={editor.errors} editHttps={editHttps} setEditHttps={editor.setValue} hasCloudIp={hasCloudIp} />
                        : null
                    }
                    {editHttps.method === 'pem'
                        ? <HttpsPemEdit
                            errors={editor.errors}
                            editHttps={editHttps}
                            setEditHttps={editor.setValue}
                            hasCloudIp={hasCloudIp}
                            forceEditor={forcePemEditor}
                        />
                        : null}
                    {editHttps.method === 'acme'
                        ? <HttpsAcmeEdit errors={editor.errors} editHttps={editHttps} setEditHttps={editor.setValue} hasCloudIp={hasCloudIp} acme={acme}/>
                        : null}

                </PanelBar>
                {editor.status !== 'add'
                    ? <PanelButtonBar
                        cacheStatus={editor.messages?.status}
                        primaryButton={{
                            onClick: primaryClick,
                            disabled: editHttps.method === 'none' && editHttps.currentState.was_https && editHttps.currentState.hasOnlyHttpsListeners
                        }}
                        leftButton={
                            editHttps.method === 'pem' && editHttps.currentState.pem
                                ? {
                                    children: 'Replace Certificate',
                                    onClick: () => setForcePemEditor(true),
                                }
                                : undefined
                        }
                        cancel={editor.status === 'edit' ? editor.onCancel : null}
                    />
                    : null}
            </Panel>
        </>
    );
};

const HttpsNoneEdit = ({ editHttps, }: SubEditorProps): React$Node => {
    if (editHttps.currentState.was_https && editHttps.currentState.hasOnlyHttpsListeners) {
        return (
            <Notice type='warning' icon='locked'>
                <p className='mb-3'><b>Note</b> – HTTPS can't be disabled for this
                Load Balancer as it currently only has HTTPS listeners.<br/>
                (Disabling HTTPS removes all HTTPS listeners and Load Balancers
                require at least one listener)</p>
                <Link to='../listeners'><Button size='xs'>Edit Listeners?</Button></Link>
            </Notice>
        );
    }

    return null;
}


type HttpsPemEditProps = {
    ...SubEditorProps,
    forceEditor: boolean,
}
const HttpsPemEdit = ({ errors, editHttps, setEditHttps, forceEditor }: HttpsPemEditProps): React$Node => {

    return (
        <>
            {editHttps.currentState.pem != null
                ? <>
                    <CertificateDetailsContent
                        certificate={editHttps.currentState.pem}
                    />
                </>
                : null
            }
            {editHttps.currentState.pem == null || forceEditor === true
                ? <>
                    <FileInput
                        label='Certificate PEM File'
                        onChange={(filename, content) => setEditHttps({ ...editHttps, pem: { ...editHttps.pem, cert: { filename, content, } } })}
                        onClear={() => setEditHttps({ ...editHttps, pem: { ...editHttps.pem, cert: null } })}
                        errorText={errors.get('cert')}
                        value={editHttps.pem.cert}
                    />
                    <FileInput
                        label='Certificate Key File'
                        onChange={(filename, content) => setEditHttps({ ...editHttps, pem: { ...editHttps.pem, key: { filename, content } } })}
                        onClear={() => setEditHttps({ ...editHttps, pem: { ...editHttps.pem, key: null } })}
                        errorText={errors.get('key')}
                        value={editHttps.pem.key}
                    />
                </>
                : null}
        </>
    );
};

const HttpsAcmeEdit = ({ errors, editHttps, setEditHttps, hasCloudIp, acme }: SubEditorProps) => {
    const removeDialog = useDialog([{
        label: 'Remove Domain',
        kind: 'primary',
        color: 'red',
        onSelect: (data: ?[number, string]) => {
            if (data != null) removeAcmeDomain(data[0]);
        }
    }]);

    const setDomain = (idx: number, domain: string) => setEditHttps({
        ...editHttps,
        domains: [].concat(
            idx ? editHttps.domains.slice(0, idx) : [],
            domain,
            editHttps.domains.slice(idx + 1),
        ),
    });

    const addAcmeDomain = () => setEditHttps({
        ...editHttps,
        domains: editHttps.domains.concat('')
    });

    const removeAcmeDomain = (idx: number) => setEditHttps({
        ...editHttps,
        domains: [].concat(
            idx ? editHttps.domains.slice(0, idx) : [],
            editHttps.domains.slice(idx + 1),
        ),
    });

    const domains_error = errors.get('acme_domains');

    const mergedDomains = useAcmeDomainUiState(acme, hasCloudIp);
    const certificate: ?BbAcmeCert = acme?.certificate;

    return (
        <>
            <Dialog
                dialog={removeDialog}
                title='Remove Domain from Certificate'
                render={(data) => (
                    <>
                        <p>{data ? <AcmeDomain domain={data[1]} /> : 'This domain'} is present on the current Let's Encrypt certificate.</p>
                        <p>Are you sure you want to remove it?</p>
                    </>
                )}
            />

            {hasCloudIp
                ? <Notice icon='info'>
                    To generate a Let’s Encrypt certificate each domain name must already resolve to a Cloud IP mapped to this Load Balancer
                </Notice>
                : <HttpsAcmeCipWarning subEdit={true} />
            }

            {domains_error
                ? <Notice type='error'>{domains_error}</Notice>
                : null}

            <SettingsTable>
                <thead>
                <tr>
                    <th className='w-status'>Status</th>
                    <th>Domain Name</th>
                </tr>
                </thead>
                <tbody>
                {editHttps.domains.map((domain, idx) => {
                    const onCert = acme && acme.certificate && acme.certificate.domains.find(x => x === domain) != null;
                    const merged: ?GuiAcmeDomain = mergedDomains.find(x => x.identifier === domain);

                    return (
                        <tr key={idx}>
                            <td>
                                {certificate && merged
                                    ? <AcmeDomainStatus certificate={certificate} domain={merged} />
                                    : <Status status='unchecked' />
                                }
                            </td>
                            <td>
                                {onCert
                                    ? <AcmeDomain domain={domain}/>
                                    : <TextInput
                                        value={domain}
                                        size='sm'
                                        onChange={(value) => setDomain(idx, value)}
                                        errorText={errors.get(idx + '_domain')}
                                        label='Domain name'
                                    />
                                }
                                    <Tooltip
                                        overlay={editHttps.domains.length === 1
                                            ? <div>Let's Encrypt Certificates must have at least one domain</div>
                                            : <div>Remove domain</div>
                                    }>
                                    <RemoveButton
                                        disabled={editHttps.domains.length === 1}
                                        size='sm'
                                        className={onCert ? 'ml-4' : '-ml-2'}
                                        onClick={() => onCert
                                            ? removeDialog.show([idx, domain])
                                            : removeAcmeDomain(idx)
                                        }
                                    />
                                </Tooltip>
                            </td>
                        </tr>
                    );
                })}
                <tr>
                    <td colSpan='3'>
                        <AddButton onClick={addAcmeDomain}>Add Domain</AddButton>
                    </td>
                </tr>
                </tbody>
            </SettingsTable>
        </>
    );
};

export const HttpsAcmeCipWarning = ({ subEdit }: { subEdit: boolean }): React$Node => (
    <Notice icon="info" type="info">
        A Let's Encrypt certificate will be created once a Cloud IP has been mapped to the
        Load Balancer <Link to={subEdit ? '../cloud_ips/' : 'cloud_ips/'}  className='ml-3'><Button size='sm'>Map Cloud IP now?</Button></Link>

    </Notice>
);