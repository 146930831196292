// @flow

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDialog } from '../element/Dialog';
import { RC_SUCCESS } from '../../state/resource/type';

import type { CloudGuiState } from '../../state/cloudgui';
import type { SecretValue } from '../../state/Secret/type';
import type { DialogState } from '../element/Dialog';
import type { MessageHook } from './Messages';

export type SecretHook = {
    secret: ?SecretValue,
    clearSecret: () => void,
};

export const useSecret = (resource_id: string): SecretHook => {
    const secret = useSelector((state: CloudGuiState) => state.Secret.secrets.get(resource_id));
    const dispatch = useDispatch();

    return useMemo(() => ({
        secret,
        clearSecret: () => {
            dispatch({ type: 'SECRET_REMOVE', payload: { id: resource_id } });
        }
    }), [resource_id, secret, dispatch]);
};

export type SecretDisplayDialog<Data> = {
    +secretId: string,
    +secret: ?SecretValue,
    +dialog: DialogState<Data>
}

export const useSecretDisplayDialog = <Data>(resource_id: string): SecretDisplayDialog<Data> => {
    const secret = useSecret(resource_id);
    const newPasswordDialog = useDialog([], secret.clearSecret);

    useEffect(() => {
        if (secret.secret) {
            newPasswordDialog.show();
        }
    }, [secret, newPasswordDialog]);

    return {
        dialog: newPasswordDialog,
        secret: secret.secret,
        secretId: resource_id,
    };
};

export type SecretFromIdDialog<Data> = {
    +secretId: string,
    +secret: ?SecretValue,
    +dialog: DialogState<Data>,
}

export const useSecretFromIdDisplayDialog = <Data, Res>(key: string, messages: ?MessageHook<Res>): SecretFromIdDialog<Data> => {
    const dispatch = useDispatch();
    const secretId = useSelector<CloudGuiState, ?string>((state: CloudGuiState) => state.Secret.secretIds.get(key)) || '';
    const secret = useSecret(secretId);
    const newPasswordDialog = useDialog([], () => {
        secret.clearSecret();
        dispatch({
            type: 'SECRET_SET_ID',
            payload: {
                id: key,
                value: null,
            }
        });
    });

    const status = messages ? messages.status : RC_SUCCESS;

    useEffect(() => {
        if (secret.secret && status === RC_SUCCESS) {
            newPasswordDialog.show();
        }
    }, [secret, newPasswordDialog, status]);

    return {
        secretId,
        secret: secret.secret,
        dialog: newPasswordDialog,
    };
};