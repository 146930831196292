// @flow

import { formatGbp } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../../lib/history';
import { useTrialCreditSummary } from '../../hoc/Billing';

import type { BillingActivity } from '../../hoc/Billing';

export type TrialCreditDisplayProps = {
    +activity: ?BillingActivity,
    +trialDaysRemaining: number,
}


export const TrialCreditDisplay = ({ activity, trialDaysRemaining }: TrialCreditDisplayProps): React$Node => {
    const getRoute = useResourceRoutes();
    const summary = useTrialCreditSummary(activity?.categories);

    if (summary == null) return null;

    const [, price, remainingAmount] = summary;

    return (
        <div className='m-5 px-4 py-3 bg-gray-200 text-sm rounded-md text-center'>
            {remainingAmount === price && trialDaysRemaining >= 14
                ?  <span>You've got <b className='text-green-600'>£{formatGbp(remainingAmount)} Free Trial Credit</b> to use for the next {trialDaysRemaining} days</span>
                : null
            }

            {remainingAmount === price && trialDaysRemaining < 14
                ? <span>You've not used any your <b>£{price.toFixed()} credit</b> yet with only {trialDaysRemaining} days
                of your trial remaining – please <b><Link to={getRoute('support')}>get in touch</Link></b> if you need help getting started 👍</span>
                : null
            }

            {remainingAmount > 0 && remainingAmount < price
                ?  <span>You currently have <b className='text-green-600'>£{formatGbp(remainingAmount)} remaining</b> of your £{price.toFixed()} trial credit to use for the next {trialDaysRemaining} days</span>
                : null
            }

            {remainingAmount === 0
                ? <span>You've now used all up of your £{price.toFixed()} trial credit. If you weren't expecting
                that please <b><Link to={getRoute('support')}>get in touch</Link></b> with our support team 👍</span>
                : null
            }
        </div>
    );
}
