// @flow
import { Panel, PanelDetailsBar, PanelHeader, Detail, PanelBar } from '../../element/Panel';
import { history } from '../../../lib/history';
import format from 'date-fns/format';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';
import { Button } from '../../element/Button';
import { Notice } from '../../element/Notice';

import type { PaymentCardDetails } from '../../hoc/Billing';

type PaymentCardProps = {
    +paymentCard: ?PaymentCardDetails,
    +loading: boolean,
};

const CardExpiry = ({ paymentCard }: { paymentCard: PaymentCardDetails }): React$Node => {
    const { expiresOn } = paymentCard;

    const date = <>{expiresOn.substr(5, 2)} / {expiresOn.substr(0, 4)}</>;

    return expiresOn.substr(0, 7) < format(new Date(), 'yyyy-MM')
        ? <Tooltip overlay="Your card has expired – please add your updated card details">
            <span className="text-red-600">
                {date}
                <Pill color="red">Expired</Pill>
            </span>
        </Tooltip>
        : date;
};

export const PaymentCard = ({ paymentCard, loading, }: PaymentCardProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title="Card Details"
                actions={{
                    edit: () => history.push('card'),
                }}
            />
            {loading || paymentCard != null
                ? <PanelDetailsBar>
                    <Detail label="Type" value={paymentCard?.type} skeletonSize="xs"/>
                    <Detail label="Name" value={paymentCard?.name} skeletonSize="sm"/>
                    <Detail label="Card Number" value={paymentCard?.number?.replace(/\*\*\*\*/g, '•••• ')}/>
                    <Detail
                        label="Expiry"
                        value={paymentCard
                            ? <CardExpiry paymentCard={paymentCard}/>
                            : null
                        }
                        skeletonSize="xs"
                    />
                </PanelDetailsBar>
                : null
            }
            {!loading && paymentCard == null
                ? <PanelBar>
                    <Notice>
                        <p className='text-gray-600'>You don't currently have a payment card registered
                        <Button size='sm' onClick={() => history.push('card')} className='ml-4'>Add Card</Button></p>
                    </Notice>
                </PanelBar>
                : null
            }
        </Panel>
    );
};
