// @flow

import { useMemo } from 'react';
import { useResourcesById } from './ListPage';
import { useResourceIdSelector } from './ListSelection';
import { useDispatch } from 'react-redux';
import { useLockDialogs, useDeleteDialog } from '../common/CommonDialogs';
import { isAllocated } from '../../api/lib';

import type { Dispatch } from 'redux';
import type { ActionQueueAction } from '../../state/ActionQueue/type';
import type { ItemSelectOptions, ItemSelectTypes, SetOrFn } from '../common/ResourceSelector';
import type { DialogState } from '../element/Dialog';
import type { LockDialogsHook } from '../common/CommonDialogs';
import type { MultiSelectChoice } from '../element/Table';
import type { BbDatabaseSnapshot, BbCollectedDatabaseServer } from '../../api/type.dbs';
import type { BbCollectedImage } from '../../api/type';
import type { BbCollectedServer } from '../../api/type.srv';
import type { BbCollectedLba } from '../../api/type.lba';

export type LockDeleteListActions<C> = {
    ...ItemSelectOptions<C>,
    +deleteDialog: DialogState<null>,
    +lockDialogs: LockDialogsHook,
}

function trueFilter(x: any): boolean {
    return true;
}

type LockDeleteTypes = BbDatabaseSnapshot | BbCollectedImage | BbCollectedLba | BbCollectedServer | BbCollectedDatabaseServer;
type ListActionsKind = 'server' | 'database_snapshot' | 'image' | 'cloud_ip' | 'load_balancer' | 'database_server';

function lockedShortcutBuilder<C: ItemSelectTypes>(allItems: $ReadOnlyArray<C>, items: $ReadOnlyArray<C>, filter: ?(C) => boolean, setSelected: (SetOrFn) => void): $ReadOnlyArray<MultiSelectChoice> {
    const paged = items.length < allItems.length;

    const allSelectableLength = allItems.filter(x => !filter || filter(x)).length;
    const pageSelectableLength = items.reduce((acc, x) => !filter || filter(x) ? acc + 1 : acc, 0);

    let res: Array<MultiSelectChoice> = [];

    if (paged && pageSelectableLength > 0) {
        res.unshift(
            {
                children: `Select all on page (${pageSelectableLength} items)`,
                onClick: () => setSelected(new Set(items.filter(x => !filter || filter(x)).map(x => x.id))),
            },
        );
    }

    if (allSelectableLength > 0) {
        res.unshift({
            children: `Select all (${allSelectableLength} items)`,
            onClick: () => {
                setSelected((selected) => {
                    let nextSelected = new Set(selected);
                    allItems.filter(x => !filter || filter(x)).forEach(x => nextSelected.add(x.id));
                    return nextSelected;
                });
            },
        });
    }

    const [locked, unlocked] = allItems.filter(x => !filter || filter(x))
        .reduce(
            ([locked, unlocked], x) => [
                true === x.locked ? locked + 1 : locked,
                false === x.locked ? unlocked + 1 : unlocked,
            ],
            [0,0]
        );

    res.push({
        separator: res.length > 0,
        children: `Select locked (${locked} items)`,
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (x.locked || null) === true).map(x => x.id))),
        disabled: locked === 0,
    });
    res.push({
        children: `Select unlocked (${unlocked} items)`,
        // (!x.locked || null) looks weird, but it's needed to force flow to accept an alternative if there's no .locked property
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (!x.locked || null) === true).map(x => x.id))),
        disabled: unlocked === 0,
    });

    res.push({
        separator: true,
        children: 'Select none',
        onClick: () => setSelected(new Set())
    });

    return res;
}

export function useLockDeleteListActions<C: LockDeleteTypes>(
    kind: ListActionsKind,
    plural: string,
    selectableFilter: ?(C) => boolean
): LockDeleteListActions<C> {
    const { resources: res } = useResourcesById<C>(kind);
    const { selected, editor } = useResourceIdSelector(kind);

    const dispatch = useDispatch<Dispatch<ActionQueueAction>>();
    const lockDialogs = useLockDialogs(
        () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected,
                    kind,
                    method: 'PUT',
                    action: 'lock_resource',
                }
            });
        },
        () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected,
                    kind,
                    method: 'PUT',
                    action: 'unlock_resource',
                }
            });
        }
    );

    const [, deleteDialog] = useDeleteDialog(
        true,
        () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected,
                    kind,
                    method: 'DELETE',
                    action: null,
                }
            });
            editor.setValue([]);
        },
        'Delete',
    );

    const canDelete = useMemo(() => {
        return selected.reduce((acc, id) => {
            return acc && isAllocated(res?.[id]?.status || 'active') && !res?.[id]?.locked;
        }, true);

    }, [selected, res]);

    const canUnlock = useMemo(() => {
        return selected.reduce((acc, id) => {
            return acc || (isAllocated(res?.[id]?.status || 'active') && res?.[id]?.locked);
        }, false);
    }, [selected, res]);

    const canLock = useMemo(() => {
        return selected.reduce((acc, id) => {
            return acc || (isAllocated(res?.[id]?.status || 'active') && !res?.[id]?.locked);
        }, false);
    }, [selected, res]);

    return {
        editor,
        shortcutBuilder: lockedShortcutBuilder,
        multiItemActions: [
            {
                label: 'Lock',
                buttonProps: {
                    color: 'blue',
                    onClick: () => lockDialogs.lockDialog.show(),
                },
                disabledTooltip: canLock
                    ? null
                    : <div>Your selection does not include any unlocked {plural}.</div>
                ,
            },
            {
                label: 'Unlock',
                buttonProps: {
                    color: 'blue',
                    onClick: () => lockDialogs.unlockDialog.show(),
                },
                disabledTooltip: canUnlock
                    ? null
                    : <div>Your selection does not include any locked {plural}.</div>
                ,
            },
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: canDelete ? () => deleteDialog.show() : () => void 0,
                },
                disabledTooltip: canDelete
                    ? null
                    : <div>Your selection includes {plural} that cannot be deleted.</div>,
            },
        ],
        selectableFilter: selectableFilter || trueFilter,
        lockDialogs,
        deleteDialog,
    };
}