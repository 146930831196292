// @flow strict

import { createElement, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Panel} from './Panel';
import { Page, PageHeader, PageTitle } from './Page';
import { Button } from './Button';
import { animationRouteContext, pageTransition, pageVariants } from './animation';
import { motion } from "framer-motion";
import { useCurrentAccount } from '../hoc/lib';
import { CloudIpLimit, DatabaseLimit, LoadBalancerLimit, ServerLimit, BlockStorageLimit, AccountPending, AccountClosed } from './LimitTooltip';
import { Tooltip } from './Tooltip';
import { ResourceSelector } from '../common/ResourceSelector';
import { GB_TO_MB } from './Styled';
import { LABELS } from './ResourceLabels';

import type { ResourceSelectorProps } from '../common/ResourceSelector';
import type { BbCollectedResourceTypes, } from '../../api/type';

export type ResourceListPageProps<C: BbCollectedResourceTypes> = {
    ...ResourceSelectorProps<C>,

    +title?: React$Node,
    +add?: false | string,
    +checkLimit?: 'load_balancers'| 'dbs_ram' | 'cloud_ips' | 'ram' | 'block_storage',
    +addButtonText?: ?string,
};

const limitTooltips = {
    'load_balancers': LoadBalancerLimit,
    'dbs_ram': DatabaseLimit,
    'cloud_ips': CloudIpLimit,
    'ram': ServerLimit,
    'block_storage': BlockStorageLimit,
}

type ResourceListTitleProps = {
    +add?: false | string,
    +resourceName: string,
    +addButtonText?: ?string,

    +title: string,
    +limitOverlay?: ?React$Node
}

export const ResourceListTitle = ({ add, title, addButtonText, resourceName, limitOverlay }: ResourceListTitleProps): React$Node => {
    const disabled = limitOverlay != null;

    const addButton = add == null || typeof add === 'string'
        ? <Link to={disabled ? '#' : add || 'add/'}>
            <Button kind='primary' disabled={disabled}>
                {addButtonText || 'Create ' + resourceName}
            </Button>
        </Link>
        : null
    ;
    return (
        <PageHeader>
            <PageTitle>{title}</PageTitle>

            {limitOverlay && addButton
                ? <Tooltip overlay={limitOverlay}>{addButton}</Tooltip>
                : addButton
            }

        </PageHeader>
    );
};

export function useAtAccountLimit<C: BbCollectedResourceTypes>(checkLimit: ?$PropertyType<ResourceListPageProps<C>, 'checkLimit'>, resourceName: string): ?React$Node {
    const account = useCurrentAccount();

    const atAccountLimit = checkLimit && account
        ? account[checkLimit + '_limit'] - account[checkLimit + '_used'] <
        (checkLimit === 'block_storage' ? (window.cgrConfig.VOLUME_LIMITS.min * GB_TO_MB) : 1)
        : false;

    const limitTooltip = useMemo(
        () => account && checkLimit && atAccountLimit ? createElement(limitTooltips[checkLimit], null)  : null,
        [account, atAccountLimit, checkLimit]
    );

    const accountPendingTooltip = account?.status === 'pending'
        ? <AccountPending resourceName={resourceName} />
        : null;

    const accountClosedTooltip = account?.status === 'closed'
        ? <AccountClosed />
        : null;

    return accountClosedTooltip || accountPendingTooltip || limitTooltip;
}

export function ResourceListPage<C: BbCollectedResourceTypes>(props: ResourceListPageProps<C>): React$Node {
    const [ , , sectionAnim, ] = useContext(animationRouteContext);

    const {
        add,
        title,
        checkLimit,
        addButtonText,
        ...listProps
    } = props;

    const limitOverlay = useAtAccountLimit(checkLimit, LABELS[listProps.kind]?.plural);

    return (
        <motion.div
            style={{width:'100%'}}
            {...sectionAnim}
            variants={pageVariants}
            transition={pageTransition}
        >
            <Page>
                {typeof title === 'string'
                    ? <ResourceListTitle
                        add={add}
                        title={title}
                        resourceName={LABELS[listProps.kind]?.title}
                        limitOverlay={limitOverlay}
                        addButtonText={addButtonText}
                    />
                    : null
                }
                {typeof title !== 'string' && title != null
                    ? title
                    : null
                }
                <Panel>
                    <ResourceSelector
                        context={'list'}
                        {...listProps}
                    />
                </Panel>
            </Page>
        </motion.div>
    );
}


