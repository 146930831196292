// @flow
import { createLogic } from 'redux-logic';

import type { CloudGuiState } from '../cloudgui';
import type { Dispatch } from 'redux';
import type {
    SecretAction,
} from './type';
import type { ResourceAddFull } from '../resource/type';
import type { BbFullResourceTypes, BbCollectedResourceTypes, } from '../../api/type';
import type { ReduxLogic } from 'redux-logic';

/**
 * Waits for resources added to the state, and if it sees a known secret,
 * plucks it out and stores it associated with the resource ID.
 *
 * The theory being the UI is expecting a secret to appear and has a hook waiting
 * for one, which will clear it as needed.
 */
export const SecretRegisterLogic: ReduxLogic = createLogic({
    'type': ['RESOURCE_ADD_FULL'],
    async process(
        deps: {
            getState: () => CloudGuiState,
            action: ResourceAddFull<BbFullResourceTypes, BbCollectedResourceTypes>
        },
        dispatch: Dispatch<SecretAction>,
        done: () => void
    ) {
        let resource = deps.action.payload.full;

        if (resource.resource_type === 'database_server' && resource.admin_password) {
            dispatch({
                type: 'SECRET_SET',
                payload: {
                    id: resource.id,
                    value: resource.admin_password,
                }
            });
        }

        if (
            (resource.resource_type === 'application' || resource.resource_type === 'api_client')
            && resource.secret
        ) {
            dispatch({
                type: 'SECRET_SET',
                payload: {
                    id: resource.id,
                    value: resource.secret,
                }
            });
        }

        done();
    },
});
