// @flow
import { boolSort, dateSort, numberSort, stringSort } from '../../element/Sort';
import type { SortFields } from '../../element/Sort';

import type { BbCollectedImage, } from '../../../api/type';

export const imageSortFields: SortFields<BbCollectedImage> = {
    _default: stringSort<BbCollectedImage>('name'),
    id: stringSort<BbCollectedImage>('id'),
    name: stringSort<BbCollectedImage>('name'),
    source_type: stringSort<BbCollectedImage>('source_type'),
    created_at: dateSort<BbCollectedImage>('created_at'),
    disk_size: numberSort<BbCollectedImage>('disk_size'),
    public: boolSort<BbCollectedImage>('public'),
};