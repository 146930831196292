// @flow

import { Chip, } from '../../element/Chip';
import { useOrbitContainers } from '../../hoc/Orbit';
import { formatOrbitSize, displayBytesSize } from '../../element/Styled';
import type { ResourceCacheStatus } from '../../../state/resource/type';
import type { OrbitContainerMeta, OrbitAccountMeta } from '../../../api/type.orbit';
import type { OrbitContainersHook } from '../../hoc/Orbit';
import type { SettingsItemProps } from '../../element/Setting/Settings';
import { SkeletonBar } from '../../element/Skeleton';

type OrbitContainerAdvancedProps = {
    +id: string,
    +cacheStatus?: ResourceCacheStatus,
    +isContainerRegistry: boolean,
    +meta: ?OrbitContainerMeta,
    +listingsEditUri: string,
    +tempUrlKeyEditUri: string,
    +staticSiteEditUri: string,
    +historyEditUri: string,
    +quotaEditUri: string,
}

function summarisePublicListing(meta: ?OrbitContainerMeta) {
    if (!meta) return '';
    if (meta.rlistings.read && meta.web.listings === 'true') return 'Enabled';
    else if (meta.rlistings.read && meta.web.listings !== 'false') return 'Orbit API exposed';
    else if (meta.web.listings === 'true' && !meta.rlistings.read) return 'Disabled (missing .rlistings)';

    return 'Disabled';
}

function summariseStaticSite(meta: ?OrbitContainerMeta) {
    if (!meta) return '';

    if (typeof meta.web.index === 'string' && meta.web.index !== '' && meta.rlistings.read) {
        return 'Enabled';
    } else {
        return 'Disabled';
    }
}

export function summariseTempUrlKeys(meta: ?(OrbitContainerMeta | OrbitAccountMeta), type: 'account' | 'container'): string {
    if (!meta) return '';

    const idx = (meta.tempUrlKey1 == null ? '0' : '1') + (meta.tempUrlKey2 == null ? '0' : '1');

    switch(idx) {
    case '00': return `No ${type} temporary URL keys`;
    case '10': return 'Key 1 set';
    case '01': return 'Key 2 set';
    case '11': return 'Key 1 and 2 set';
    default: return '';
    }
}

function summariseArchive(meta: ?OrbitContainerMeta, containers: OrbitContainersHook): React$Node {
    if (!meta) return '';

    if (meta.archive.history == null && meta.archive.versions == null) return 'None';
    if (meta.archive.history != null) {
        const container = containers.containers[meta.archive.history];

        return (
            <Chip
                icon='resource/orbit'
                id={container ? formatOrbitSize(container.bytes) : null}
                name={meta.archive.history}
            />
        );
    }
    if (meta.archive.versions != null) {
        return 'Versions Enabled';
    }

    return '';
}

function summariseQuota(meta: ?OrbitContainerMeta, containers: OrbitContainersHook): React$Node {
    if (!meta) return '';
    let out = [];

    if (meta.quota.bytes) out.push(displayBytesSize(meta.quota.bytes));
    if (meta.quota.count) out.push(meta.quota.count + ' objects');

    if (out.length) return out.join(', ');

    return 'Disabled';
}

export function useOrbitContainerSettings({ id, isContainerRegistry, cacheStatus, meta, listingsEditUri, staticSiteEditUri, tempUrlKeyEditUri, historyEditUri, quotaEditUri, }: OrbitContainerAdvancedProps): ?$ReadOnlyArray<SettingsItemProps> {
    const containers = useOrbitContainers();

    if (id === 'images' || isContainerRegistry) return null;

    return [
        {
            name: 'Public Object Listings',
            summary: meta
                ? summarisePublicListing(meta)
                : <SkeletonBar size='sm'/>,
            route: listingsEditUri,
            hint: 'Allow public visibility of the list of objects in this Orbit container',
        },
        {
            name: 'Static Website Mode',
            summary: meta
                ? summariseStaticSite(meta)
                : <SkeletonBar size='sm'/>,
            route: staticSiteEditUri,
            hint: 'Configure this Orbit container to serve a static HTML website',
        },
        {
            name: 'Quotas',
            summary: meta
                ? summariseQuota(meta, containers)
                : <SkeletonBar size='md'/>,
            route: quotaEditUri,
            hint: 'Quotas restrict the total size and/or number of objects in an Orbit container',
        },
        {
            name: 'Temporary URL Keys',
            summary: meta
                ? summariseTempUrlKeys(meta, 'container')
                : <SkeletonBar size='md'/>,
            route: tempUrlKeyEditUri,
            hint: 'Temporary URL keys are used to generate temporary shareable links to Orbit objects',
            settingsBtn: meta && meta.tempUrlKey1 == null && meta.tempUrlKey2 == null
                ? { children: 'Add Keys', kind: 'secondary' }
                : { children: 'Edit' },
        },
        {
            name: 'Object Archiving',
            summary: meta
                ? summariseArchive(meta, containers)
                : <SkeletonBar size='sm'/>,
            route: historyEditUri,
            hint: 'Object Archiving copies older versions of objects to a separate Orbit Container whenever they are updated or deleted',
        },
    ];
}
