// @flow

import { Panel, PanelHeader } from '../../../element/Panel';
import { ResourceSelectedServers, ServerSelector } from '../../../common/ServerSelector';

import type { IdSelectEditor } from '../../../common/ResourceSelector';
import type { SelectedServersProps } from '../../../common/ServerSelector';

type LbaNodeSelectorProps = { +editor: IdSelectEditor, };

const DESCRIPTION = 'To which Cloud Servers should this Load Balancer distribute requests?';


export const LbaNodeSelector = ({ editor }: LbaNodeSelectorProps): React$Node => {
    return (
        <Panel>
            <PanelHeader
                title='Backend Nodes'
                description={DESCRIPTION}
            />
            <ServerSelector editor={editor}/>
        </Panel>
    );
};


export const LbaNodeView = (props: SelectedServersProps): React$Node => {
    return (
        <ResourceSelectedServers
            {...props}
                title='Backend Nodes'
        />
    );
};
