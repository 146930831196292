// @flow
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from './Panel';
import { TextInput } from './Input';
import { Textarea } from './Textarea';

import type { Editor } from '../common/Editor';

export type NameDescriptionPanelProps = {
    +resourceTitle?: string,
    +panelTitle?: string,
    +editor: Editor<{ name: string, description: string}, null, any>,
    +nameRequired?: boolean,
    +nameNotice?: React$Node,
    +children?: ?React$Node,
}


export const NameDescriptionEditPanel = ({ resourceTitle, editor, nameRequired, nameNotice, panelTitle, children }: NameDescriptionPanelProps): React$Node => {
    const { value, setValue } = editor;

    if (!value) return null;

    return (
        <Panel>
            <PanelHeader title={panelTitle ? panelTitle : 'Name'} description={resourceTitle ? `What shall we call your new ${resourceTitle}?` : null}/>
            <PanelBar>
                <div className='max-w-sm space-y-6'>
                    <TextInput
                        label='Name'
                        optional={!nameRequired}
                        value={value.name || ''}
                        onChange={(name) => setValue({...value, name})}
                        autoFocus={editor.status !== 'add'}
                        errorText={editor.errors}
                        className='w-full'
                    />
                    {nameNotice}
                    <Textarea
                        label='Description'
                        optional={true}
                        value={value.description ||  ''}
                        onChange={(description) => setValue({...value, description})}
                        className='w-full'
                    />
                </div>
            </PanelBar>
            {editor.status !== 'add'
                ? <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                />
                : null
            }
            {children}
        </Panel>
    );
};