// @flow

import { useState } from 'react';
import { Notice } from './Notice';
import { Button } from './Button';
import { cn } from './lib/classNames';
import { SvgIcon } from './SvgIcon';

type FileChange = (filename: string, content: string) => void;

export type FileData = { content: string, filename: string };

type FileInputProps = {
    label: React$Node,
    value: ?FileData,
    onChange: FileChange,
    onClear: () => void,
    errorText?: ?string,
}

export const FileInput = ({ label, value, onChange, onClear, errorText, }: FileInputProps): React$Node => {
    const [hoverClass, setHoverClass] = useState<boolean>(false);

    const setFile = (f: FileList) => {
        if (f && f.length) {
            const file = f[0];
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent) => {
                // $FlowFixMe the target of a ProgressEvent does have a .result...
                onChange(file.name, e.target.result);
            };
            reader.readAsBinaryString(file);
        } else {
            onClear();
        }
    };

    const className = cn({
        'c-file-input': true,
        'c-file-input--drag-over': hoverClass,
    });

    const handleDragOver = (e: DragEvent) => {
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
        e.stopPropagation();
        e.preventDefault();
        setHoverClass(true);
    }

    const handleDragExit = (e: DragEvent) => {
        setHoverClass(false);
    }

    const handleDrop = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setHoverClass(false);

        if (e.dataTransfer && e.dataTransfer.files.length) {
            const file = e.dataTransfer.files[0];
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent) => {
                // $FlowFixMe the target of a ProgressEvent does have a .result...
                onChange(file.name, e.target.result);
            };
            reader.readAsText(file);
        } else {
            onClear();
        }
    }

    return (
        <div className={className} onDragOver={handleDragOver} onDragExit={handleDragExit} onDragLeave={handleDragExit} onDrop={handleDrop}>
            <h4 className='c-file-input__title'><SvgIcon svg='upload' className='mr-2' />{label}</h4>
            {value
                ? <>
                    <span className='c-file-input--preview'>{value.filename}</span>
                    <Button kind='tertiary' preIcon='trash' onClick={onClear} className='c-file-input__clear' />
                </>
                : <>
                    <label htmlFor='file' className='c-file-input__label'>Drag and drop file here or <strong className='c-button c-button--small ml-1'>Choose File</strong></label>
                    <input
                        type='file'
                        id='file'
                        className='c-file-input__control'
                        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => setFile(e.target.files)}
                    />
                    </>
            }
            {errorText
                ? <Notice type='error'>{errorText}</Notice>
                : null
            }

        </div>
    )
}