// @flow

import type { ActionQueueState, ActionQueueAction } from './type';
import type { StoreClearAction } from '../type';

const emptyState: ActionQueueState = {
    actions: [],
    running: false,
    currentId: null,
    orbitObjectActions: new Map(),
};

export function ActionQueueReducer(
    state: ActionQueueState = emptyState,
    action: ActionQueueAction | StoreClearAction
): ActionQueueState {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'ACTION_QUEUE_ADD':
        return {
            ...state,
            actions: [
                ...state.actions,
                action.payload.action,
            ],
        };
    case 'ACTION_QUEUE_MULTIPLE':
        const { ids, ...common } = action.payload;
        
        return {
            ...state,
            actions: [
                ...state.actions,
                ...ids.map(id => ({
                    id,
                    ...common,
                })),
            ]
        };

    case 'ACTION_QUEUE_SHIFT':
        let next = [...state.actions];
        const shifted = next.shift();
        return {
            ...state,
            currentId: shifted?.id,
            actions: next,
        }
    
    
    case 'ACTION_QUEUE_RUNNING':
        return {
            ...state,
            running: action.payload.running,
            ...(action.payload.running
                ? null
                : { currentId: null }
            )
        }
    case 'ACTION_QUEUE_ADD_ORBIT_OBJECTS':
        return {
            ...state,
            orbitObjectActions: new Map([
                ...state.orbitObjectActions,
                [
                    action.payload.container,
                    new Set([
                        ...(state.orbitObjectActions.get(action.payload.container) || []),
                        ...action.payload.objects,
                    ])
                ]
            ]),
        }
    case 'ACTION_QUEUE_REMOVE_ORBIT_OBJECTS':
        const nextObjects = new Set([...(state.orbitObjectActions.get(action.payload.container) || []),]);
        action.payload.objects.forEach(obj => nextObjects.delete(obj));
        return {
            ...state,
            orbitObjectActions: new Map([
                ...state.orbitObjectActions,
                [
                    action.payload.container,
                    nextObjects
                ]
            ]),
        }
    default:
        void (action: empty);
        break;
    }

    return state;
}
