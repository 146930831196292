// @flow
import { useDeleteDialog, DeleteDialog, RemoveDialog } from '../../common/CommonDialogs';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { BYTES_TO_GB, formatOrbitSize, formatDateTime } from '../../element/Styled';
import { Panel,  PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useOrbitContainerView, useContainerMetaEditor } from '../../hoc/orbit/Container';
import { useFileUpload } from '../../hoc/orbit/Upload';
import { useOrbitDeleteAccess } from '../../hoc/orbit/Access';
import { useOrbitContainerSettings } from './OrbitContainerAdvanced';
import { OrbitContainerAccess } from './OrbitContainerAccess';
import { NotFound } from '../home/NotFound';
import { RC_NOT_FOUND } from '../../../state/resource/type';
import { Notice } from '../../element/Notice';
import { getOrbitContainerUrl } from '../../../api/adapter.orbit';
import { useCurrentAccountId } from '../../hoc/lib';
import {
    validatePublicListingsType,
    validateContainerAccessEntry,
    UriFragmentContainerAccessEdit,
    emptyEdit,
    validateTempUrlKeys,
    orbitMetaToTempKeyEditor,
    validateStaticSite,
    validateHistory,
    validateQuota
} from './def';
import { OrbitPublicListingEdit } from './edit/OrbitPublicListingEdit';
import { ContainerAccessForm } from './edit/ContainerAccessForm';
import { ContainerTempUrlKeysEdit } from './edit/ContainerTempUrlKeysEdit';
import { ContainerHistoryForm } from './edit/ContainerHistoryForm';
import { ContainerStaticSite } from './edit/ContainerStaticSite';
import { ContainerQuotaForm } from './edit/ContainerQuotaForm';
import { SvgIcon } from '../../element/SvgIcon';
import { useDialog, Dialog } from '../../element/Dialog';
import { OrbitUploadDialog } from './OrbitUploadDialog';
import { SkeletonBar } from '../../element/Skeleton';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../../lib/history';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';
import { DropFileOverlay } from '../../element/DropFileOverlay';
import { LABELS } from '../../element/ResourceLabels';

import type {
    OrbitEditMetaType,
    ContainerPublicListingEdit,
    ContainerAccessEdit,
    TempUrlKeys,
    ContainerStaticSiteEdit,
    ContainerHistoryEdit,
    ContainerQuotaEdit
} from './def';
import type { Match } from 'react-router';
import type { OrbitContainerMeta, ContainerAccess, OrbitContainerHeaders, } from '../../../api/type.orbit';

export function ContainerAccessDeleteName(a: ?ContainerAccess): string {
    if (!a) return 'this access';

    switch(a.type) {
    case 'api': return 'this API client access';
    case 'http': return 'this HTTP referrer';
    case 'any-referrer': return  'public read access';
    case 'team':
        break;
    case 'other':
        break;
    default:
        void (a.type: empty);
    }

    return 'this access';
}

export const OrbitContainerView = ({ id, match, }: { id: string, match: Match }): React$Node => {
    const accountId = useCurrentAccountId() || '';
    const containerView = useOrbitContainerView(id);
    const { container, deleteAction, cacheStatus, refresh, } = containerView;
    const { deleteAccessAction } = useOrbitDeleteAccess(id);
    const getRoute = useResourceRoutes();

    const isContainerRegistry = id.endsWith('_ctrimages');
    const containerDeletable: boolean = (container?.details?.count || 0) === 0;
    const [actions, deleteDialog] = useDeleteDialog(containerDeletable, deleteAction);
    const [, deleteAccessDialog] = useDeleteDialog<ContainerAccess>(true, deleteAccessAction, 'Remove');
    const publicListingsDialog = useDialog([ {
        label: (t: ?OrbitEditMetaType<ContainerPublicListingEdit>) => (t?.edit?.listings ? 'Enable' : 'Disable'),
        kind: 'primary',
        onSelect: () => publicListingsEditor.onSave(),
    } ]);

    const addAccessEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerAccessEdit>>(
        id, containerView,  'access/add',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: {
                type: '',
                initial: null,
                access: 'read',
                apiClient: '',
                referrer: '',
                allowDomain: 'allow',
                other: '',
                newApiClientName: '',
            },
        }),
        (value: OrbitEditMetaType<ContainerAccessEdit>) => validateContainerAccessEntry(value.rawMeta, value.edit),
        match.path, 'Access rule added successfully'
    );

    const editAccessEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerAccessEdit>>(
        id, containerView,  'access/:edit([a-z]+:.+)/',
        (meta: OrbitContainerMeta, match: Match) => {
            const edit = UriFragmentContainerAccessEdit(match.params.edit || '');

            return ({
                rawMeta: meta,
                edit: edit || emptyEdit,
            });
        },
        (value: OrbitEditMetaType<ContainerAccessEdit>) => validateContainerAccessEntry(value.rawMeta, value.edit),
        match.path, 'Access rule edited successfully'
    );

    const publicListingsEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerPublicListingEdit>>(
        id, containerView,  'public_listings',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: {
                listings: meta.web.listings === 'true' && meta.rlistings.read,
                css: meta.web.css || '',
            },
        }),
        (value: OrbitEditMetaType<ContainerPublicListingEdit>) => validatePublicListingsType(value.rawMeta, value.edit),
        match.path, null
    );
    const staticSiteEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerStaticSiteEdit>>(
        id, containerView,  'static_site',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: {
                enabled: meta.rlistings.read && typeof meta.web.index === 'string' && meta.web.index !== '',
                index: meta.web.index || 'index.html',
                error: meta.web.error || 'error.html',
                genIndex: (meta.web.index == null || meta.web.index === ''),
                genError: (meta.web.error == null || meta.web.error === ''),
            },
        }),
        (value: OrbitEditMetaType<ContainerStaticSiteEdit>) => validateStaticSite(value.rawMeta, value.edit),
        match.path, null
    );

    const tempUrlKeyEditor = useContainerMetaEditor<OrbitEditMetaType<TempUrlKeys>>(
        id, containerView, 'temp_url_keys',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: orbitMetaToTempKeyEditor(meta)
        }),
        (value: OrbitEditMetaType<TempUrlKeys>) => validateTempUrlKeys(value.rawMeta, value.edit),
        match.path, null
    );
    const historyEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerHistoryEdit>>(
        id, containerView, 'history',
        (meta: OrbitContainerMeta) => ({
            rawMeta: meta,
            edit: ({
                mode: meta.archive.history == null ? 'create' : 'existing',
                create_container: id + '_archive',
                gen_create: true,
                existing_container: meta.archive.history || '',
            }),
        }),
        (value: OrbitEditMetaType<ContainerHistoryEdit>) => validateHistory(value.rawMeta, value.edit),
        match.path, null,
        (value: OrbitEditMetaType<ContainerHistoryEdit>, headers: OrbitContainerHeaders, messagesId: string) => {
            containerView.setHistory(headers, messagesId);
        }
    );
    const quotaEditor = useContainerMetaEditor<OrbitEditMetaType<ContainerQuotaEdit>>(
        id, containerView, 'quota',
        (meta: OrbitContainerMeta) => {
            let gb = meta.quota.bytes ?
                (meta.quota.bytes / BYTES_TO_GB).toFixed(2).replace(/0+$/, '')
                : ''
            ;
            if (gb.endsWith('.')) gb = gb.slice(0, -1);

            return ({
                rawMeta: meta,
                edit: ({
                    bytes: meta.quota.bytes != null ? '' + gb : '',
                    count: meta.quota.count != null ? '' + meta.quota.count : '',
                }),
            });
        },
        (value: OrbitEditMetaType<ContainerQuotaEdit>) => validateQuota(value.rawMeta, value.edit),
        match.path, null
    );
    const { confirmUploadDialog, setFileValue, uploadMessages, hoverClass, uploadProgress, uploadableFiles} = useFileUpload(id, '', refresh);

    const containerSettings = useOrbitContainerSettings({
        id, isContainerRegistry,
        meta: container?.meta,
        listingsEditUri: publicListingsEditor.editUri,
        tempUrlKeyEditUri: tempUrlKeyEditor.editUri,
        staticSiteEditUri: staticSiteEditor.editUri,
        historyEditUri: historyEditor.editUri,
        quotaEditUri: quotaEditor.editUri,
    });

    if (cacheStatus === RC_NOT_FOUND) {
        return <NotFound />;
    }

    const containerUrl = getOrbitContainerUrl(accountId, id);

    let settings = [
        [
            {
                name: 'Total Objects',
                summary: <>
                    {container?.details?.count != null
                        ? container.details.count
                        : <SkeletonBar size='sm'/>
                    }
                    {isContainerRegistry
                        ? <Tooltip overlay={<div>Total number of Orbit objects in this container. See the <Link to={getRoute('container_registry', id)}>Container Registry view</Link> for Docker details.</div>}>
                            <Pill>?</Pill>
                        </Tooltip>
                        : null
                    }
                </>,
                route: 'browse/',
                settingsBtn: {
                    children: 'View Object Browser',
                    kind: 'secondary',
                },
            },
            {
                name: 'Total Size',
                summary: container?.details != null
                    ? formatOrbitSize(container.details.bytes)
                    : <SkeletonBar size='sm'/>,
            },
            {
                name: 'Last Modified',
                summary: container?.details != null
                    ? formatDateTime(container.details.last_modified)
                    : <SkeletonBar size='md'/>
            },
            {
                name: 'Container URL',
                summary: <a href={containerUrl} target='_blank' rel='noopener noreferrer'>{containerUrl}</a>,
                copyValue: containerUrl,

            },
        ],
    ];
    if (containerSettings != null) settings.push(containerSettings);

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.container.listTitle}
            match={match}
            dialog={
                <>
                    <DeleteDialog name={`container "${id}"`} dialog={deleteDialog} />
                    <RemoveDialog name={ContainerAccessDeleteName} dialog={deleteAccessDialog} title='Remove Access?' />
                    <Dialog
                        dialog={publicListingsDialog}
                        title={(t: ?OrbitEditMetaType<ContainerPublicListingEdit>) => (t?.edit?.listings ? 'Enable' : 'Disable') + ' Public Object Listings?'}
                        render={(t: ?OrbitEditMetaType<ContainerPublicListingEdit>) =>
                            <div>
                                {t?.edit?.listings && !t?.rawMeta?.web.listings
                                    ? <Notice type='warning' icon='info'><b>Note:</b> Public Read Access is required for Public Object Listings and will be automatically enabled</Notice>
                                    : null
                                }
                                {!t?.edit?.listings && t?.rawMeta?.web.listings
                                    ? <Notice type='warning' icon='info'>
                                        <b>Note:</b> Disabling Public Object Listings doesn't automatically disable Public Read Access –
                                        you'll need to remove this manually if no longer required.
                                </Notice>
                                    : null
                                }
                            </div>
                        }
                    />
                    <OrbitUploadDialog
                        dialog={confirmUploadDialog}
                        messages={uploadMessages}
                        setFileValue={setFileValue}
                        uploadProgress={uploadProgress}
                        uploadableFiles={uploadableFiles}
                    />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            title={id}
                            description={
                                id === 'images'
                                    ? <span className='italic'><SvgIcon className='text-gray-400 align-bottom' svg='star'/> The images container is a special Orbit container where Cloud Server and Cloud SQL snapshots are stored</span>
                                    : isContainerRegistry
                                        ? <span className='italic'><SvgIcon className='text-gray-400 align-bottom' svg='registry'/> This Orbit container is a <Link to={getRoute('container_registry', id)}>Container Repository</Link></span>
                                        : null
                            }
                            actions={actions}
                            disableDeleteHint={
                                id === 'images'
                                    ? "The images container can't be deleted"
                                    : containerDeletable
                                        ? null
                                        : 'Containers with objects cannot be deleted'
                            }
                        />
                        <PanelMultiSettingsBar
                            settings={settings}
                        />

                    </Panel>

                    {container?.details
                        ? <OrbitContainerAccess
                            editDisabled={id === 'images'}
                            meta={container.meta}
                            onDeleteEntry={(entry: ContainerAccess) => deleteAccessDialog.show(entry)}
                            containerRegistry={isContainerRegistry}
                        />
                        : null
                    }

                    {hoverClass
                        ? <DropFileOverlay destination={id} container={id}/>
                        : null
                    }

                </>
            }
            editors={[
                { editor: editAccessEditor, render: () => <ContainerAccessForm editor={editAccessEditor} addEdit='edit' />, },
                { editor: addAccessEditor, render: () => <ContainerAccessForm editor={addAccessEditor} addEdit='add' />, },
                { editor: publicListingsEditor, render: () => <OrbitPublicListingEdit editor={publicListingsEditor} confirmDialog={publicListingsDialog} />, },
                { editor: tempUrlKeyEditor, component: ContainerTempUrlKeysEdit },
                { editor: staticSiteEditor, component: ContainerStaticSite, },
                { editor: historyEditor, render: () => <ContainerHistoryForm editor={historyEditor} container={id} />, },
                { editor: quotaEditor, component: ContainerQuotaForm, },
            ]}
        />
    )
}