// @flow
import { useContext, useMemo, } from 'react';
import { displaySize, formatDate, formatDateTime, mapSourceType, RightArrow } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { imageSortFields } from './def';
import { NavLink } from 'react-router-dom';
import { createImageSearch } from './ImageSearch';
import { ResourceChip } from '../../element/Chip';
import { animationRouteContext, pageTransition, pageVariants } from '../../element/animation';
import { useListResource } from '../../hoc/ListPage';
import { useResourceSearch } from '../../element/Search';
import { usePager } from '../../element/ArrayPager';
import { motion } from 'framer-motion';
import { Page, } from '../../element/Page';
import { ResourceListTitle } from '../../element/ResourceListPage';
import { RC_ERROR, RC_FETCHING } from '../../../state/resource/type';
import { PanelApiErrorBar } from '../../element/PanelApiErrorBar';
import { Panel, PanelBar, PanelSearchBar, PanelHeader } from '../../element/Panel';
import { PagerBar } from '../../element/PagerBar';
import { useCurrentAccount } from '../../hoc/lib';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries } from '../../element/Skeleton';
import { useTabs } from '../../element/UiStateHooks';
import { ItemSelector } from '../../element/ItemSelector';
import { useLockDeleteListActions } from '../../hoc/ListActions';
import { useItemSelect } from '../../common/ResourceSelector';
import { Dialog } from '../../element/Dialog';
import { LockDialogs } from '../../common/CommonDialogs';
import { LABELS } from '../../element/ResourceLabels';

import type { BbCollectedImage, } from '../../../api/type';
import type { ListSortDef } from '../../hoc/ListPage';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';

const { searchDef, ImageSearch } = createImageSearch('image:list');
// if these hashes are changed, then useImageLinkTitle in ImageView will
// also need updating to reflect them, and the tab parameters passed
// to ImageListTable below.
const hashMap = new Map<string, number>([
    ['#mine', 0],
    ['#official', 1],
    ['#all', 2],
]);

type ImageListTableProps = {
    ...ResourceSelectorTableProps<BbCollectedImage>,
    +title?: string,
    +tab?: string,
};

export const ImageListTable = ({ items, Th: SortTh, status, totalCount, title, search, context, itemSelect, ...rest }: ImageListTableProps): React$Node => {
    const tab = rest?.tab || null;
    const listContext = context === 'list';

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={6}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field='name'>Name</SortTh>
                        <SortTh field='disk_size'>Size</SortTh>
                        <SortTh field='source_type'>Type</SortTh>
                        <SortTh field='created_at'>Created</SortTh>
                        <SortTh field='public'>Visibility</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((image: BbCollectedImage) =>
                <Tr
                    key={image.id}
                    {...itemSelect?.rowProps(image)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={image} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}><ResourceChip resource={image} link={itemSelect?.selectedLength === 0 ? {pathname: `${image.id}/`, state: tab ? { tab } : null} : null} /></Td>
                    <Td>{displaySize(image.disk_size)}</Td>
                    <Td>{mapSourceType(image.source_type, image.source_trigger)}</Td>
                    <Td><span title={formatDateTime(image.created_at)}>{formatDate(image.created_at)}</span></Td>
                    <Td>{image.public ? 'Public' : 'Private'}</Td>
                    {listContext
                        ? <Td actions={true}>
                            <NavLink to={{ pathname: `${image.id}/`, state: { tab } }}>
                                <RightArrow/>
                            </NavLink>
                        </Td>
                        : null
                    }
                </Tr>
            )}
            {status === RC_FETCHING
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td><SkeletonBar size='md'/></Td>
                        <Td><SkeletonBar size='xs'/></Td>
                        <Td><SkeletonBar size='xs'/></Td>
                        <Td><SkeletonBar size='xs'/></Td>
                        {listContext ? <Td actions={true}>&nbsp;</Td> : null}
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={itemSelect ? 7 : 6} title={title || 'images'}/>
            <NoResourcesTr
                status={status}
                totalCount={totalCount}
                colSpan={itemSelect ? 7 : 6}
                context={context}
                title={title || 'Images'}
            />

            </tbody>
        </Table>
    );
};

const sortDef: ListSortDef<BbCollectedImage> = { name: 'imageList', fields: imageSortFields };

function imageStatusFilter(img: BbCollectedImage): boolean {
    return img.status === 'available';
}

export const ImageList = (): React$Node => {
    const [, , sectionAnim,] = useContext(animationRouteContext);
    const currAccount = useCurrentAccount();
    const currAccountId = currAccount?.id || '';
    const allowWindows = currAccount && currAccount.windows_servers_limit > 0;
    const tabs = useTabs(hashMap);
    const { deleteDialog, lockDialogs, ...selector } = useLockDeleteListActions('image', 'images', imageStatusFilter);

    const name = 'imageList';

    const { items: unfilteredImages, ...list } = useListResource<BbCollectedImage>('image', sortDef);
    const allItems = unfilteredImages.filter(x => allowWindows || !(x.official && x.name.startsWith('Windows')))
    const resourceSearch = useResourceSearch(searchDef, allItems);

    const [totalMineCount, totalOfficialCount] = useMemo(() => {
        return allItems.reduce(([mine, official], i: BbCollectedImage) => {
            if (i.owner === currAccountId) mine++;
            if (i.official) official++;
            return [mine, official];
        }, [0, 0]);
    }, [allItems, currAccountId]);

    const official = resourceSearch.items.filter(x => x.official && (allowWindows || !x.name.startsWith("Windows")));
    const mine = resourceSearch.items.filter(x => x.owner === currAccountId);

    const officialPager = usePager(name + 'Official', official, official.length);
    const minePager = usePager(name + 'Mine', mine, mine.length);
    const allPager = usePager(name, resourceSearch.items, allItems.length);

    const itemSelect = useItemSelect<BbCollectedImage>(mine, minePager.items, selector, 'list');

    return (
        <>
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Server Images?`}
                confirmString='delete'
                render={() => (
                    <div>Are you sure you want to delete these Images?</div>
                )
                }
            />
            <LockDialogs name={'these Server Images'} lockDialog={lockDialogs.lockDialog} unlockDialog={lockDialogs.unlockDialog} count={selector.editor?.value?.length} />

            <motion.div
                style={{ width: '100%' }}
                {...sectionAnim}
                variants={pageVariants}
                transition={pageTransition}
            >
                <Page>
                    <ResourceListTitle title={'Server Images'} resourceName='Image' add={false}/>

                    <Panel>
                        {list.status === RC_ERROR
                            ? <>
                                <PanelHeader title='Server Images' />
                                <PanelApiErrorBar forceRefresh={list.forceRefresh} {...LABELS['image']} />
                            </>
                            : <Tabs {...tabs}>
                                <TabList>
                                    <Tab>My Images</Tab>
                                    <Tab>Official Images</Tab>
                                    <Tab>All</Tab>
                                </TabList>
                                <PanelSearchBar search={<ImageSearch editor={resourceSearch.editor}/>}/>
                                <TabPanel>
                                    <PanelBar padding={false}>
                                        <ImageListTable
                                            items={minePager.items}
                                            Th={list.Th}
                                            status={list.status}
                                            totalCount={totalMineCount}
                                            search={{ ...resourceSearch, filterRemovedAllItems: totalMineCount > 0 && minePager.items.length === 0 }}
                                            title='Images or Snapshots'
                                            tab='mine'
                                            context='list'
                                            itemSelect={itemSelect}
                                        />
                                    </PanelBar>
                                    <PagerBar {...minePager.pager} />
                                </TabPanel>
                                <TabPanel>
                                    <PanelBar padding={false}>
                                        <ImageListTable
                                            items={officialPager.items}
                                            Th={list.Th}
                                            status={list.status}
                                            totalCount={totalOfficialCount}
                                            search={{ ...resourceSearch, filterRemovedAllItems: totalOfficialCount > 0 && officialPager.items.length === 0 }}
                                            title='Official Images'
                                            tab='official'
                                            context='list'
                                        />
                                    </PanelBar>
                                    <PagerBar {...officialPager.pager} />
                                </TabPanel>
                                <TabPanel>
                                    <PanelBar padding={false}>
                                        <ImageListTable
                                            items={allPager.items}
                                            Th={list.Th}
                                            status={list.status}
                                            totalCount={allItems.length}
                                            title='Images'
                                            tab='all'
                                            search={resourceSearch}
                                            context='list'
                                        />
                                    </PanelBar>
                                    <PagerBar {...allPager.pager} />
                                </TabPanel>
                            </Tabs>
                        }
                    </Panel>

                </Page>
            </motion.div>
        </>
    );
};
