// @flow

import { Checkbox } from '../../../element/Checkbox';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { TextInput } from '../../../element/Input';
import { Notice } from '../../../element/Notice';

import type { FormErrors } from '../../../common/lib';
import type { EditorModal } from '../../../common/Editor';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';
import type { OrbitEditMetaType, ContainerPublicListingEdit } from '../def';
import type { DialogState } from '../../../element/Dialog';

type OrbitPublicListingEditProps = {
    +editor: EditorModal<OrbitEditMetaType<ContainerPublicListingEdit>, FormErrors, OrbitContainerMeta>,
    +confirmDialog: DialogState<OrbitEditMetaType<ContainerPublicListingEdit>>,
};


export const OrbitPublicListingEdit = ({ editor, confirmDialog, }: OrbitPublicListingEditProps): React$Node => {
    const { value, setValue, } = editor;
    if (value == null) return null;

    return (
        <Panel>
            <PanelHeader
                title='Public Object Listings'
                description='Allow public visibility of the list of objects in this Orbit container'
            />
            <PanelBar>
                <Checkbox
                    label='Enable Public Object Listings'
                    checked={value.edit.listings}
                    onChange={(listings) => setValue({...value, edit: { ...value.edit, listings: listings } })}
                />
                {value.rawMeta.rlistings.read && value.rawMeta.web.listings == null
                    ? <Notice>Enabling public listings will disable public orbit api listing</Notice>
                    : null
                }
                <div className={value.edit.listings ? null : 'hidden'}>
                <TextInput
                    label='Custom Stylesheet'
                    value={value.edit.css}
                    onChange={(css) => setValue({...value, edit: { ...value.edit, css } })}
                    disabled={!value.edit.listings}
                    optional={true}
                />
                </div>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{
                    onClick: editor.status === 'edit'
                    ? () => (
                        value.edit.listings !== value.rawMeta.rlistings.read
                            ? confirmDialog.show(value)
                            : editor.onSave()
                    )
                    : null
                }}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};

