// @flow
import type { DialogState } from '../element/Dialog';
import { useDialog } from '../element/Dialog';
import { useListResource } from '../hoc/ListPage';
import { selectorSortFields } from '../section/user/AccountList';
import { ResourceChip, } from '../element/Chip';
import { useDispatch } from 'react-redux';
import type { SearchEditorHook } from '../element/Search';
import { useResourceSearch } from '../element/Search';
import { accountSearch, statusToActive } from './def';
import { useCurrentAccountId } from '../hoc/lib';
import { TextInput } from '../element/Input';
import { Card } from '../element/Card';
import { CardGroup } from '../element/CardGroup';

import type { AuthAction } from '../../state/auth/type';
import type { Dispatch } from 'redux';
import type { BbCollectedAccount } from '../../api/type.acc';
import type { ListSortDef } from '../hoc/ListPage';

export type AccountSwitchHook = {
    +dialog: DialogState<BbCollectedAccount>,
    +onShowSelectAccount: () => void,
    +selected: ?string,
    +setSelected: (BbCollectedAccount) => void,
}


export function useAccountSwitch(): AccountSwitchHook {
    const dispatch = useDispatch<Dispatch<AuthAction>>();
    const dialog = useDialog<BbCollectedAccount>([]);
    const currAccountId = useCurrentAccountId();

    return {
        dialog,
        onShowSelectAccount: () => {
            dialog.show();
        },
        selected: currAccountId,
        setSelected: (account: BbCollectedAccount) => {
            dispatch({ type: 'AUTH_USER_SWITCH_ACCOUNT', payload: { account } });
            dialog.hide();
        },

    };
}

const sortDef: ListSortDef<BbCollectedAccount> = { name: 'accountSelector', fields: selectorSortFields };

const AccountSearch = ({ editor }: { +editor: SearchEditorHook }) => {
    const postIcon = editor.fields.searchText.value !== ''
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

    return (
        <div className='flex w-full mt-2 mb-6'>
            <TextInput
                size='sm'
                label='Filter by keyword'
                preIcon={'search'}
                {...postIcon}
                value={editor.fields.searchText.value}
                onChange={(value) => editor.setSearchValue('searchText', value)}
                autoFocus={true}
            />
        </div>
    );
};

export const AccountSwitchDialog = ({ accountSwitch }: { +accountSwitch: AccountSwitchHook }): React$Node => {
    const accounts = useListResource<BbCollectedAccount>('account', sortDef);
    const activeAccounts = accounts.items.filter(acc => statusToActive(acc.status));
    const { editor, items } = useResourceSearch<BbCollectedAccount>(accountSearch, activeAccounts);
    const hasInactive = accounts.items.findIndex(x => !statusToActive(x.status)) !== -1;

    return (
        <div className='c-account-switcher'>
            {activeAccounts.length > 4 || hasInactive
                ? <AccountSearch editor={editor}/>
                : null
            }

            <CardGroup className='c-account-switcher__card-group'>
                {items.map((account) =>
                    <Card
                        key={account.id}
                        selected={account.id === accountSwitch.selected}
                        selectable={true}
                        linkStyle={true}
                        onClick={() => {
                            accountSwitch.setSelected(account);
                            // hmm - perhaps dialog should have 'setData'?
                        }}
                    >
                        <ResourceChip resource={account}/>
                    </Card>
                )}
            </CardGroup>
        </div>
    );
};

