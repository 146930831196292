// @flow
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { resourceSelector } from '../../../state/Search/search';
import { createSelector } from 'reselect';
import { TextInput } from '../../element/Input';

import type { CloudGuiState } from '../../../state/cloudgui';
import type { Dispatch } from 'redux';
import type { SearchDefinition, SearchEditorHook } from '../../element/Search';
import type { BbCloudIp } from '../../../api/type.cip';
import type { SearchIndexes } from '../../../state/Search/search';

export const createCloudIpSearch = (name: SearchIndexes): { CloudIpSearch: React$StatelessFunctionalComponent<any>, searchDef: SearchDefinition<BbCloudIp> } => {
    const { result } = getSearchSelectors({ resourceName: name, resourceSelector });

    const getSearchText = createSelector(
        [result,],
        (result) => ({
            searchMatchIds: result,
        }));

    const searchText = createSearchAction(name);

    const searchDef = {
        name,
        fields: [
            {
                searchFieldName: 'searchText',
                matches: (state: CloudGuiState, fieldValue: string, item: $ReadOnly<BbCloudIp>) => {
                    const { searchMatchIds, } = (getSearchText(state): { searchMatchIds: Array<string> });
                    return searchMatchIds.findIndex(x => x === item.id) !== -1;
                },
                setValue: (nextValue: string, dispatch: Dispatch<any>) => {
                    dispatch(searchText(nextValue));
                },
            }
        ],
    };

    const CloudIpSearch = ({ editor }: { editor: SearchEditorHook }) => {

        const post = editor.fields.searchText.value
        ? { postIcon: 'cross', onPostInlayClick: () => editor.setSearchValue('searchText', ''), }
        : { postIcon: 'spacer', };

        return (
            <>
                    <TextInput
                        label='Filter by keyword'
                        size='sm'
                        preIcon='search'
                        value={editor.fields.searchText.value}
                        onChange={(v: string) => editor.setSearchValue('searchText', v)}
                        {...post}
                    />
            </>);
    };

    return {
        CloudIpSearch,
        searchDef,
    }
};
