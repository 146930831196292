// @flow
import { useEffect } from 'react';
import { SvgIcon } from './SvgIcon';
import { cn } from './lib/classNames';
import { useFocusHoverStyle } from './Input';
import { getTrimmedName } from './Chip';

import type { TextInputSize } from './Input';
import { ANIMATION_DELAY_MS } from './animation';

export type ValueSelectChoice<V> = {
    +label: React$Node,
    +value: V,
    +description?: React$Node,
    +default?: boolean,
};

export type ValueSelectGroup<V> = {
    +groupLabel: string,
    +options: $ReadOnlyArray<ValueSelectChoice<V>>,
}

export type ValueSelectOptions<V> = $ReadOnlyArray<ValueSelectChoice<V> | ValueSelectGroup<V>>;

export type ValueSelectProps<V> = {
    +selected: V,
    +options: ValueSelectOptions<V>,
    +onSelect: (value: V) => void,
    +size?: TextInputSize,
    +id?: string,
    +errorText?: ?string,
    +label?: React$Node,
    +className?: ?string,
    +readOnly?: boolean,
    +autoFocus?: boolean,
}

type WrappedSelectProps = {
    +value: string | number,
    +onChange: (e: SyntheticInputEvent<HTMLSelectElement>) => void,
    +children: React$Node,
    size?: TextInputSize,
    +id?: string,
    +errorText?: ?string,
    +helperText?: ?string,
    +label?: React$Node,
    +className?: ?string,
    +readOnly?: boolean,
    +autoFocus?: boolean,
}

function WrappedSelect({ value, onChange, children, size, errorText, helperText, label, className: extraClasses, autoFocus, readOnly, ...rest }: WrappedSelectProps) {
    const { ref: inputRef, classNames: hoverClasses } = useFocusHoverStyle('c-input');

    const classes = {
        'c-input': true,
        'c-input--select': true,
        'c-input--completed': true,
        'c-input--sm': (size === 'sm'),
        'c-input--xs': (size === 'xs'),
        'c-input--has-error': !!errorText,
        'c-input--is-disabled': readOnly,
        ...hoverClasses,
    };

    // using the inputs autofocus={true} attribute breaks the animations
    // somehow the browser overrides the transform() applied by framer to
    // bring the input fully into view immediately.
    // so we delay any autofocus by the animation time...
    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => inputRef.current?.focus(), ANIMATION_DELAY_MS)
        }
        // we only do this on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cn(classes) + ' ' + (extraClasses || '')} {...rest}>
            <div className='c-input__outline'></div>
            <div className='c-input__content'>
                {label
                    ? <label className='c-input__label'>{label}</label>
                    : null
                }
                <select
                    ref={inputRef}
                    className='c-input__control'
                    value={value}
                    onChange={onChange}
                    disabled={readOnly}

                >
                    {children}
                </select>
                <SvgIcon className='c-input__inlay c-input__inlay--post-icon' svg={'caret-down'} size={5}/>
            </div>
            {helperText && !errorText
                ? <div className='c-input__helper-text'>{helperText}</div>
                : null
            }
            {errorText
                ? <div className='c-input__helper-text'>{errorText}</div>
                : null}
        </div>
    );
}

export function ValueSelect<V>({ options, selected, onSelect, ...rest }: ValueSelectProps<V>): React$Node {
    const selectedKey = options.reduce((acc, option, idx) => {
        if (acc !== -1) return acc;

        if (option.options) {
            const subIndex = option.options.findIndex(sub => sub.value === selected);
            if (subIndex !== -1) {
                return `${idx}_${subIndex}`;
            }
        } else if (option.value === selected) {
            return idx;
        }

        return -1;
    }, -1);

    const select = (key: string) => {
        if (isNaN(key)) {
            const split = key.split('_');
            let idx = Number(split[0]);
            let subIdx = Number(split[1]);

            if (idx < options.length && options[idx].options && subIdx < options[idx].options.length) {
                onSelect(options[idx].options[subIdx].value);
            }
        } else {
            const idx = Number(key);
            if (idx >= 0 && idx < options.length && !options[idx].options) {
                onSelect(options[idx].value);
            }
        }
    };

    return (
        <WrappedSelect
            value={selectedKey === -1 ? '' : selectedKey}
            onChange={(e: SyntheticInputEvent<HTMLSelectElement>) => select(e.target.value)}
            {...rest}
        >
            {options.map((item, key) => item.options ?
                <optgroup label={item.groupLabel} key={key}>
                    {item.options.map((subItem, subKey) => <option value={`${key}_${subKey}`} key={subKey}>{getTrimmedName(subItem.label)}</option>)}
                </optgroup>
                : <option value={key} key={key}>{getTrimmedName(item.label)}</option>
            )}
            {process.env.NODE_ENV === 'development' && selectedKey === -1
                ? <option value={-1}>Unknown value in ValueSelect: {JSON.stringify(selected)}</option>
                : null}
        </WrappedSelect>
    );
}

export const toLabelLookup = <V>(acc: Object, val: ValueSelectChoice<V>): Object => {
    acc[val.value] = val.label;
    return acc;
};