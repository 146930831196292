// @flow
import { useMemo } from 'react';
import { dateSort, idNameSort, } from '../../element/Sort';
import { textOnlySearch } from '../../common/TextOnlySearch';
import { useResourceIdSelector } from '../../hoc/ListSelection';
import { useResourcesById } from '../../hoc/ListPage';
import { useDispatch } from 'react-redux';
import { useDeleteDialog, useLockDialogs } from '../../common/CommonDialogs';
import { useDialog } from '../../element/Dialog';
import { Button } from '../../element/Button';
import { isDeleted } from '../../../api/lib';

import type { SortFields } from '../../element/Sort';
import type { SearchDefinition } from '../../element/Search';
import type { BbVolume } from '../../../api/type.volume';
import type { ItemSelectOptions, SetOrFn } from '../../common/ResourceSelector';
import type { DialogState } from '../../element/Dialog';
import type { Dispatch } from 'redux';
import type { ActionQueueAction } from '../../../state/ActionQueue/type';
import type { MultiSelectChoice } from '../../element/Table';

export const volSearchDef: SearchDefinition<BbVolume> = textOnlySearch<BbVolume>('volume');

export const volSortFields: SortFields<BbVolume> = {
    _default: idNameSort<BbVolume>(),
    name: idNameSort<BbVolume>(),
    created_at: dateSort<BbVolume>('created_at'),
    size: (a: BbVolume, b: BbVolume) => a.size - b.size,
    attached_to: (a: BbVolume, b: BbVolume) => {
        // if both attached then sort by server details
        if (a.server && b.server) return (a.server?.name || a.server?.id).localeCompare(b.server?.name || b.server?.id)

        const aServer = !!a.server;
        const bServer = !!b.server;

        // if only one is a server then sort it together with other server-having ones
        if (aServer !== bServer) return aServer ? -1 : 1;

        // else fallback to the volume details.
        return (a.name || a.id).localeCompare(b.name || b.id);
    },
};


type VolumeListActions = {
    ...ItemSelectOptions<BbVolume>,
    +deleteDialog: DialogState<null>,
    +unlockDialog: DialogState<null>,
    +lockDialog: DialogState<null>,
    +detachDialog: DialogState<null>,
}

function volumeSelectableFilter(vol: BbVolume) {
    return vol.status !== 'deleted';
}

function volumeShortcutBuilder(allItems: $ReadOnlyArray<BbVolume>, items: $ReadOnlyArray<BbVolume>, filter: ?(BbVolume) => boolean, setSelected: (SetOrFn) => void): $ReadOnlyArray<MultiSelectChoice> {
    const paged = items.length < allItems.length;

    const allSelectableLength = allItems.filter(x => !filter || filter(x)).length;
    const pageSelectableLength = items.reduce((acc, x) => !filter || filter(x) ? acc + 1 : acc, 0);

    let res: Array<MultiSelectChoice> = [];

    if (paged && pageSelectableLength > 0) {
        res.unshift(
            {
                children: `Select all on page (${pageSelectableLength} items)`,
                onClick: () => setSelected(new Set(items.filter(x => !filter || filter(x)).map(x => x.id))),
            },
        );
    }

    if (allSelectableLength > 0) {
        res.unshift({
            children: `Select all (${allSelectableLength} items)`,
            onClick: () => {
                setSelected((selected) => {
                    let nextSelected = new Set(selected);
                    allItems.filter(x => !filter || filter(x)).forEach(x => nextSelected.add(x.id));
                    return nextSelected;
                });
            },
        });
    }

    const [locked, unlocked] = allItems.filter(x => !filter || filter(x))
        .reduce(
            ([locked, unlocked], x) => [
                true === x.locked ? locked + 1 : locked,
                false === x.locked ? unlocked + 1 : unlocked,
            ],
            [0,0]
        );

    const [attached, detached] = allItems.filter(x => !filter || filter(x))
        .reduce(
            ([attached, detached], x) => [
                'attached' === x.status ? attached + 1 : attached,
                'attached' !== x.status ? detached + 1 : detached,
            ],
            [0,0]
        );

    res.push({
        separator: res.length > 0,
        children: `Select locked (${locked} items)`,
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (x.locked || null) === true).map(x => x.id))),
        disabled: locked === 0,
    });
    res.push({
        children: `Select unlocked (${unlocked} items)`,
        // (!x.locked || null) looks weird, but it's needed to force flow to accept an alternative if there's no .locked property
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (!x.locked || null) === true).map(x => x.id))),
        disabled: unlocked === 0,
    });

    res.push({
        separator: res.length > 0,
        children: `Select attached (${attached} items)`,
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (x.status === 'attached')).map(x => x.id))),
        disabled: attached === 0,
    });
    res.push({
        children: `Select detached (${detached} items)`,
        // (!x.locked || null) looks weird, but it's needed to force flow to accept an alternative if there's no .locked property
        onClick: () => setSelected(new Set(items.filter(x => (!filter || filter(x)) && (x.status !== 'attached')).map(x => x.id))),
        disabled: detached === 0,
    });

    res.push({
        separator: true,
        children: 'Select none',
        onClick: () => setSelected(new Set())
    });

    return res;
}

const BootVolumeWarning = ({ verb }: { verb: string }): React$Node => (
    <div>Your selection includes boot Volumes which must be replaced on their servers before they can be {verb}</div>
);

export const useVolumeListActions = (): VolumeListActions => {
    const { selected, editor } = useResourceIdSelector('volume');
    const { resources: volumes } = useResourcesById<BbVolume>('volume');
    const dispatch = useDispatch<Dispatch<ActionQueueAction>>();

    const [, deleteDialog] = useDeleteDialog(
        true,
        () => {
            selected.forEach(id => {
                dispatch({
                    type: 'ACTION_QUEUE_ADD',
                    payload: {
                        action: {
                            kind: 'volume',
                            id,
                            method: 'DELETE',
                            action: null,
                        }
                    }
                });

            });
            editor.setValue([]);
        },
        'Delete',
    );

    const detachDialog = useDialog([{
        label: 'Detach',
        kind: 'primary',
        color: 'red',
        onSelect: () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected.filter(x => volumes[x]?.status === 'attached'),
                    kind: 'volume',
                    method: 'POST',
                    action: 'detach',
                }
            });
        }
    }])

    const hasAttachedVolumes: boolean = useMemo(
        () => selected.findIndex(
            id => volumes[id]?.status === 'attached'
        ) !== -1,
        [selected, volumes]
    );

    const hasBootVolumes: boolean = useMemo(
        () => selected.findIndex(
            id => volumes[id]?.status === 'attached' && volumes[id]?.boot
        ) !== -1,
        [selected, volumes]
    );

    const lockDialogs = useLockDialogs(
        () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected,
                    kind: 'volume',
                    method: 'PUT',
                    action: 'lock_resource',
                }
            });
        },
        () => {
            dispatch({
                type: 'ACTION_QUEUE_MULTIPLE',
                payload: {
                    ids: selected,
                    kind: 'volume',
                    method: 'PUT',
                    action: 'unlock_resource',
                }
            });
        }
    );

    const hasLockedVolumes = useMemo(() => {
        return selected.reduce((acc, id) => {
            return acc || (!isDeleted(volumes?.[id]?.status || 'active') && volumes?.[id]?.locked);
        }, false);
    }, [selected, volumes]);

    const hasUnlockedVolumes = useMemo(() => {
        return selected.reduce((acc, id) => {
            return acc || (!isDeleted(volumes?.[id]?.status || 'active') && !volumes?.[id]?.locked);
        }, false);
    }, [selected, volumes]);


    return {
        editor,
        shortcutBuilder: volumeShortcutBuilder,
        multiItemActions: [
            {
                label: 'Delete',
                buttonProps: {
                    color: 'red',
                    onClick: hasAttachedVolumes || hasLockedVolumes ? null : () => deleteDialog.show(),
                },
                disabledTooltip:
                    hasBootVolumes
                        ? <BootVolumeWarning verb='deleted' />
                        : hasAttachedVolumes
                            ? <div>
                                Your selection includes attached Volumes which must be detached before they can be deleted<br/>
                                <Button className='my-2' color='white' size='xs' onClick={() => detachDialog.show()}>Detach Now?</Button>
                            </div>
                            : hasLockedVolumes
                                ? <div>Your selection includes locked Volumes which must be unlocked before they can be deleted</div>
                                : null
                ,
            },
            {
                label: 'Detach',
                buttonProps: {
                    color: 'red',
                    onClick: hasAttachedVolumes ? () => detachDialog.show() : null,
                },
                disabledTooltip:
                    hasBootVolumes
                        ? <BootVolumeWarning verb='detached' />
                        : !hasAttachedVolumes
                            ? <div>There are no attached Volumes in your selection</div>
                            : null
                ,
            },
            {
                label: 'Lock',
                buttonProps: {
                    color: 'blue',
                    onClick: () => lockDialogs.lockDialog.show(),
                },
                disabledTooltip: hasUnlockedVolumes
                    ? null
                    : <div>Your selection does not include any unlocked Volumes.</div>
                ,
            },
            {
                label: 'Unlock',
                buttonProps: {
                    color: 'blue',
                    onClick: () => lockDialogs.unlockDialog.show(),
                },
                disabledTooltip: hasLockedVolumes
                    ? null
                    : <div>Your selection does not include any locked Volumes.</div>
                ,
            },
        ],
        selectableFilter: volumeSelectableFilter,
        deleteDialog,
        detachDialog,
        ...lockDialogs,
    };
};