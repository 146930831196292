// @flow

import { SvgIcon } from './SvgIcon';
import { Tooltip } from './Tooltip';


import type { ListGridHook } from './UiStateHooks';

type ListGridSelectorProps = {
    mode: ListGridHook,
}

export const ListGridSelector = ({ mode }: ListGridSelectorProps): React$Node => {
    return (
        <div className='c-list-grid-selector'>
            <Tooltip overlay='Grid view'>
                <SvgIcon
                    onClick={() => mode[1]('grid')}
                    svg={'view-grid'}
                    className={'c-list-grid-selector__mode' + (mode[0] === 'grid' ? ' c-list-grid-selector__mode--is-active' : '')}
                />
            </Tooltip>
            <Tooltip overlay='List view'>
                <SvgIcon
                    onClick={() => mode[1]('list')}
                    svg={'view-list'}
                    className={'c-list-grid-selector__mode' + (mode[0] === 'list' ? ' c-list-grid-selector__mode--is-active' : '')}
                />
            </Tooltip>
        </div>
    );
};