// @flow
import { stringToTarget } from '../../../../api/adapter';

import type {
    BbFirewallRule,
    BbFirewallRuleParams,
    BbFirewallRuleProtocol,
    EditFirewallRule,
    EditFirewallRuleProtocol,
} from '../../../../api/type.fwp';
import type { ValueSelectOptions } from '../../../element/ValueSelect';
import type { FormErrors } from '../../../common/lib';
import type { BbTargetKinds } from '../../../../api/type';

const csvNumbers = /^[0-9,-:]+$/;

export const convertFirewallRuleToEditRule = (r: BbFirewallRule): EditFirewallRule => {
    const source = r.source ? stringToTarget(r.source) : null;
    const destination = r.destination ? stringToTarget(r.destination) : null;

    let protocol: EditFirewallRuleProtocol;
    if (r.protocol == null) {
        protocol = 'any';
    } else if (r.protocol === 'tcp' || r.protocol === 'udp' || r.protocol === 'icmp') {
        protocol = r.protocol;
    } else {
        protocol = 'other';
    }

    const templateIdx = r.destination === null
        ? inboundMatchesTemplate(r)
        : outboundMatchesTemplate(r);

    return {
        id: r.id,
        templateIdx,
        description: r.description,
        protocol,
        protocolNumber: (!isNaN(r.protocol) && r.protocol) ? r.protocol.toString() : '',
        source,
        source_port: (r.source_port || '').toString(),
        destination,
        destination_port: (r.destination_port || '').toString(),
        icmp_type_name: r.icmp_type_name || '',
    };
};

export const validateFirewallRule = (rule: EditFirewallRule): [ ?FormErrors, ?BbFirewallRuleParams ] => {
    let errors: FormErrors = new Map<string, string>();

    if (rule.protocol === 'other' && (rule.protocolNumber === '' || isNaN(rule.protocolNumber))) {
        errors.set('protocol', 'Enter valid protocol number');
    }

    if (
        (rule.protocol === 'tcp' || rule.protocol === 'udp')
        && (rule.source_port !== '')
        && (
            !csvNumbers.test(rule.source_port)
            || rule.source_port.length > 255
        )

    ) {
        errors.set('source_port', 'Enter valid source port');
    }

    if (
        (rule.protocol === 'tcp' || rule.protocol === 'udp')
        && (rule.destination_port !== '')
        && (
            !csvNumbers.test(rule.destination_port)
            || rule.destination_port.length > 255
        )
    ) {
        errors.set('destination_port', 'Enter valid destination port');
    }

    if (rule.protocol === 'icmp' && rule.icmp_type_name === '') {
        errors.set('protocol', 'Select valid ICMP message type');
    }

    if (rule.source && (rule.source.kind === '' || (rule.source.kind !== 'any' && rule.source.value === ''))) {
        errors.set('source', 'Enter valid identifier');
    }

    if (rule.destination && (rule.destination.kind === '' || (rule.destination.kind !== 'any' && rule.destination.value === ''))) {
        errors.set('destination', 'Enter valid identifier');
    }

    if (rule.source_port && rule.destination_port) {
        errors.set('source_port', 'Only enter source or destination port');
        errors.set('destination_port', 'Only enter source or destination port');
    }

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? convertEditRuleToApiRule(rule) : null,
    ];
}

export const convertEditRuleToApiRule = (editRule: EditFirewallRule): BbFirewallRuleParams => {
    const { protocol: ep } = editRule;

    let protocol: BbFirewallRuleProtocol = null;
    switch(ep) {
    case 'any':
        protocol = null;
        break;
    case 'other':
        protocol = editRule.protocolNumber;
        break;
    case 'udp':
    case 'tcp':
    case 'icmp':
        protocol = ep;
        break;
    default:
        void (ep: empty);
    }

    return {
        description: editRule.description !== '' ? editRule.description : null,

        source: editRule.source?.kind === 'any' ? 'any' : editRule.source?.value,
        destination: editRule.destination?.kind === 'any' ? 'any' : editRule.destination?.value,

        source_port: (protocol === 'tcp' || protocol === 'udp') ? (editRule.source_port || '') : void 0,
        destination_port: (protocol === 'tcp' || protocol === 'udp') ? (editRule.destination_port || '') : void 0,

        icmp_type_name: protocol === 'icmp' ? editRule.icmp_type_name : void 0,
        protocol
    };
};

type InboundTemplate = {
    description: string,

    source: BbTargetKinds,
    source_port: string,
    protocol: EditFirewallRuleProtocol,
    destination_port: string,
    icmp_type_name: string,
}

type OutboundTemplate = {
    description: string,

    destination: BbTargetKinds,
    destination_port: string,
    protocol: EditFirewallRuleProtocol,
    source_port: string,
    icmp_type_name: string,
}

export const inboundTemplates: Array<InboundTemplate> = [
    {
        description: 'HTTP',
        source: 'any',
        source_port: '',
        destination_port: '80',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'HTTPS',
        source: 'any',
        source_port: '',
        destination_port: '443',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'SSH',
        source: 'any',
        source_port: '',
        destination_port: '22',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'MySQL',
        source: 'any',
        source_port: '',
        destination_port: '3306',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'PostgreSQL',
        source: 'any',
        source_port: '',
        destination_port: '5432',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'RDP',
        source: 'any',
        source_port: '',
        destination_port: '3389',
        protocol: 'tcp',
        icmp_type_name: '',
    },
    {
        description: 'IMCP Ping',
        source: 'any',
        source_port: '',
        destination_port: '',
        protocol: 'icmp',
        icmp_type_name: 'echo-request',
    },
    {
        description: 'All Inbound Traffic',
        source: 'any',
        source_port: '',
        destination_port: '',
        protocol: 'any',
        icmp_type_name: '',
    },
];

export const outboundTemplates: Array<OutboundTemplate> = [
    {
        description: 'All Outbound Traffic',
        destination: 'any',
        destination_port: '',
        source_port: '',
        protocol: 'any',
        icmp_type_name: '',
    },
    {
        description: 'Allow Ping',
        destination: 'any',
        destination_port: '',
        source_port: '',
        protocol: 'icmp',
        icmp_type_name: 'echo-reply',
    },
];

export const InboundTemplateChoices: ValueSelectOptions<number> = [
    { label: 'Custom', value: -1 },
    {
        groupLabel: 'Preset Rules',
        options: inboundTemplates.map((rule, idx) => ({ label: rule.description, value: idx, })),
    },
];

export const OutboundTemplateChoices: ValueSelectOptions<number> = [
    { label: 'Custom', value: -1 },
    {
        groupLabel: 'Preset Rules',
        options: outboundTemplates.map((rule, idx) => ({ label: rule.description, value: idx, })),
    },
];

export const inboundMatchesTemplate = (toFind: BbFirewallRule): number => {
    return inboundTemplates.findIndex((template: InboundTemplate) => {
        return (
            toFind.source === template.source
            && (toFind.source_port === null ? '' : toFind.source_port) === template.source_port
            && (toFind.destination_port === null ? '' : toFind.destination_port) === template.destination_port
            && (toFind.protocol === null ? 'any' : toFind.protocol) === template.protocol
            && (toFind.icmp_type_name === null ? '' : toFind.icmp_type_name) === template.icmp_type_name
        )
    });
}

export const outboundMatchesTemplate = (toFind: BbFirewallRule): number => {
    return outboundTemplates.findIndex((template: OutboundTemplate) => {
        return (
            toFind.destination === template.destination
            && (toFind.destination_port === null ? '' : toFind.destination_port) === template.destination_port
            && (toFind.source_port === null ? '' : toFind.source_port) === template.source_port
            && (toFind.protocol === null ? 'any' : toFind.protocol) === template.protocol
            && (toFind.icmp_type_name === null ? '' : toFind.icmp_type_name) === template.icmp_type_name
        )
    });
}