// @flow

import { Link } from 'react-router-dom';
import { PanelBar } from './Panel';
import { Button } from './Button';
import { ValueSelect } from './ValueSelect';
import { PAGING_PAGE_SIZE_CHOICES } from './lib/paging';
import { FLIP_MODE_THRESHOLD } from '../../state/Orbit/lib';

export type FlipPagerBarProps = {
    nextLink: ?string,
    prevLink: ?string,
    pageSize: number,
    setPageSize: (pageSize: number) => void,
    jumpToFirst: ?string,
};


export const FlipPagerBar = ({ nextLink, prevLink, pageSize, setPageSize, jumpToFirst, }: FlipPagerBarProps): React$Node => {
    return (
        <PanelBar className='c-panel__section--pager'>

            <div className='c-pager'>
                <div className='c-pager__options'>
                    <div className='c-pager__page-size'>
                        <ValueSelect
                            size='xs'
                            selected={pageSize}
                            onSelect={(v) => setPageSize(Number(v))}
                            options={PAGING_PAGE_SIZE_CHOICES}
                        />
                    </div>
                </div>

                <div className='c-pager__nav'>
                    {jumpToFirst
                        ? <Link to={jumpToFirst}>
                            <Button size='sm' kind='tertiary' preIcon='arrow-up-left'>Jump to first page</Button>
                        </Link>
                        : null
                    }
                    <Link to={prevLink || ''} className={prevLink == null ? 'a--disabled' : ''}>
                        <Button size='sm' color='dark-grey' preIcon='caret-left' disabled={!prevLink} className='c-pager__button'>Prev</Button>
                    </Link>
                    <Link to={nextLink || ''} className={nextLink == null ? 'a--disabled' : ''}>
                        <Button size='sm' color='dark-grey' postIcon='caret-right' disabled={!nextLink} className='c-pager__button'>Next</Button>
                    </Link>
                </div>
            </div>
        </PanelBar>
    );
};

export const ManyObjectsWarning: React$Node = (
    <div>
        For performance reasons, in-browser sorting is only enabled for Orbit
        containers with fewer than {FLIP_MODE_THRESHOLD} total objects.
        For more information, see the <a href='https://www.brightbox.com/docs/reference/orbit/' target='_blank' rel='noopener noreferrer'>Orbit documentation</a>
    </div>
);