// @flow

import { cn } from './lib/classNames';

type PillProps = {
    children: React$Node,
    color?: 'med' | 'dark' | 'red',
    className?: string,
}

export const Pill = ({ className: extraClassName, color, children, ...props }: PillProps): React$Node => {
    const classes = {
        'c-pill': true,
        'c-pill--med': (color === 'med'),
        'c-pill--dark': (color === 'dark'),
        'c-pill--red': (color === 'red'),
    };

    const className = cn(classes) + ' ' + (extraClassName || '');

    return (
        <span className={className} {...props}>
            {children}
        </span>
    );
};