// @flow
import { useState, } from 'react';
import { LoadBalancerLimit } from '../../element/LimitTooltip';
import { useCurrentAccount } from '../../hoc/lib';
import { useCreateResourceEditorModal, useEditorDirect, } from '../../common/Editor';
import { LbaNodeSelector } from './edit/LbaNodes';
import { listHasHttpsListener } from './edit/LbaHttps';
import { emptyListener, LbaListenerEdit, } from './edit/LbaListenerEdit';
import { LbaAdvanced, LbaHttpsRedirectEdit, LbaPolicyEdit, LbaSslMinVerEdit, } from './edit/LbaAdvanced';
import { nodesFromSelected, validateDetails, validateHealthcheck, validateHttps, validateListeners } from './edit/lib';
import { ResourceCloudIpEdit, useCreateResourceCloudIps } from '../../common/ResourceCloudIps';
import { Button } from '../../element/Button';
import { NameEditPanel } from '../../element/NameEditPanel';
import { emptyErrors } from '../../common/lib';
import { useCreateResource } from '../../hoc/CreateResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { LbaHttpsEdit } from './edit/LbaHttpsEdit';
import { LbaHealthcheckEdit } from './edit/LbaHealthcheckEdit';
import { Tooltip } from '../../element/Tooltip';
import { RC_API_REQUEST, RC_CACHED, } from '../../../state/resource/type';
import { PanelResourceCreateButtons } from '../../element/Panel';
import { PerMonthPrice } from '../../element/PerMonthPrice';
import { LABELS } from '../../element/ResourceLabels';

import type { EditHealthcheck, EditHttps, EditListener } from './edit/type';
import type { BbLba, BbLbaParams, BbLbaPolicy, BbLbaHealthcheck, BbLbaSslMinVer } from '../../../api/type.lba';
import type { Match } from 'react-router-dom';
import type { FormErrors } from '../../common/lib';
import type { EditorCreateDef } from '../../common/Editor';
import type { UseStateHook } from 'react-hooks';

export type LbaCreateParams = {
    +name: string,
    +policy: BbLbaPolicy,

    +server_ids: $ReadOnlyArray<string>,

    +listeners: $ReadOnlyArray<EditListener>,
    +https: EditHttps,
    +healthcheck: EditHealthcheck,
    +ssl_minimum_version: BbLbaSslMinVer,
};

type LbaErrors = {
    detailsErrors: $ReadOnlyArray<string>,
    healthcheckErrors: FormErrors,
    httpsErrors: FormErrors,
    listenerErrors: FormErrors,
};


const policyDef: EditorCreateDef<LbaCreateParams, BbLbaPolicy, BbLbaPolicy, null> = {
    emptyErrors: null,
    onEdit: (params: LbaCreateParams) => params.policy,
    onValidate: null,
    onSave: ([, set]: UseStateHook<LbaCreateParams>, policy: BbLbaPolicy) => set(p => ({...p, policy})),
    editUri: 'policy'
}

const healthcheckDef: EditorCreateDef<LbaCreateParams, EditHealthcheck, { healthcheck: BbLbaHealthcheck }, FormErrors> = {
    emptyErrors,
    onEdit: (params: LbaCreateParams) => params.healthcheck,
    onValidate: (p: LbaCreateParams, value: EditHealthcheck) => validateHealthcheck(value),
    onSave: ([, set]: UseStateHook<LbaCreateParams>, { healthcheck }) => set(p => ({...p, healthcheck})),
    editUri: 'healthcheck'
}
const https_redirectDef: EditorCreateDef<LbaCreateParams, boolean, boolean, null> = {
    emptyErrors: null,
    onEdit: (params: LbaCreateParams) => params.https.https_redirect,
    onValidate: null,
    onSave: ([, set]: UseStateHook<LbaCreateParams>, https_redirect) => set(p => ({ ...p, https: { ...p.https, https_redirect } })),
    editUri: 'https_redirect'
}
const ssl_minimum_versionDef: EditorCreateDef<LbaCreateParams, BbLbaSslMinVer, BbLbaSslMinVer, null> = {
    emptyErrors: null,
    onEdit: (params: LbaCreateParams) => params.ssl_minimum_version,
    onValidate: null,
    onSave: ([, set]: UseStateHook<LbaCreateParams>, ssl_minimum_version) => set(p => ({ ...p, ssl_minimum_version })),
    editUri: 'ssl_minimum_version'
}


export const LbaCreate = ({ match }: { match: Match }): React$Node => {
    const account = useCurrentAccount();
    const create = useCreateResource<BbLba, BbLbaParams>('lba_create', 'load_balancer');

    const createParamsState = useState<LbaCreateParams>({
        name: '',
        policy: 'least-connections',
        server_ids: [],
        listeners: [
            { ...emptyListener, in: 80, out: 80, protocol: 'http', }
        ],
        https: {
            // not fussed about setting/unsetting this when creating,
            // so just leave it false
            currentState: {
                was_https: false,
                hasOnlyHttpsListeners: false,
                pem: null,
            },
            method: 'none',
            pem: {},
            domains: [''],
            https_redirect: false,
        },
        healthcheck: {
            type: 'http',
            request: '/',
            port: 80,
            interval: 5000,
            timeout: 5000,
            threshold_up: 3,
            threshold_down: 3,
        },
        ssl_minimum_version: 'TLSv1.2',
    });
    const [createParams, setCreateParams] = createParamsState;
    const [errors, setErrors] = useState<LbaErrors>({
        detailsErrors: [],
        healthcheckErrors: emptyErrors,
        httpsErrors: emptyErrors,
        listenerErrors: emptyErrors,
    });

    const hasHttpsListener = listHasHttpsListener(createParams.listeners);
    const needsHttps = createParams.https.method !== 'none';
    const setHttps = (https: EditHttps) => {
        let next = { ...createParams, https };
        if (https.method !== 'none' && !hasHttpsListener) {
            next.listeners = [].concat(createParams.listeners, { ...emptyListener, in: 443, out: 80, protocol: 'https', });
        }
        setCreateParams(next);
    };
    const cloudIp = useCreateResourceCloudIps();
    const nodes = useEditorDirect(
        createParams.server_ids,
        (server_ids: $ReadOnlyArray<string>) => setCreateParams({...createParams, server_ids}),
        null
    );
    const listeners = useEditorDirect(
        createParams.listeners,
        (listeners) => setCreateParams({...createParams, listeners}),
        errors.listenerErrors
    );
    const https = useEditorDirect(
        createParams.https,
        (https) => setHttps(https),
        errors.httpsErrors
    );
    const policy = useCreateResourceEditorModal(createParamsState, policyDef, match.path)
    const healthcheck = useCreateResourceEditorModal(createParamsState, healthcheckDef, match.path);
    const https_redirect = useCreateResourceEditorModal(createParamsState, https_redirectDef, match.path);
    const ssl_minimum_version = useCreateResourceEditorModal(createParamsState, ssl_minimum_versionDef, match.path);
    const name = useEditorDirect(
        createParams.name,
        (name: string) => setCreateParams({...createParams, name}),
        null
    );


    const validateAndCreate = () => {
        let [ detailsErrors, detailsCommit ] = validateDetails(createParams);
        let [ healthcheckErrors, healthcheckCommit ] = validateHealthcheck(createParams.healthcheck);
        let [ httpsErrors, httpsCommit ] = validateHttps(createParams.https);
        let [ listenerErrors, listenerCommit ] = validateListeners(needsHttps, createParams.listeners);

        setErrors({
            detailsErrors,
            healthcheckErrors: healthcheckErrors || emptyErrors,
            httpsErrors: httpsErrors || emptyErrors,
            listenerErrors: listenerErrors || emptyErrors
        });

        if (detailsCommit && healthcheckCommit && httpsCommit && listenerCommit) {
            const params: BbLbaParams = {
                ...detailsCommit,
                ...httpsCommit,
                ...nodesFromSelected(createParams.server_ids),
                healthcheck: healthcheckCommit.healthcheck,
                ...listenerCommit,
            };

            create.createResource(params, cloudIp.editor.value);
        }
    };

    return (
        <ResourceAddViewRoute
            listTitle={LABELS.load_balancer.listTitle}
            resourceName='Load Balancer'
            match={match}
            dialog={null}
            view={
                <>
                    <LbaNodeSelector editor={nodes} />

                    <ResourceCloudIpEdit
                        edit={cloudIp}
                        resourceId={''}
                        resourceTitle='Load Balancer'
                    />

                    <LbaHttpsEdit
                        editor={https}
                        hasCloudIp={true}
                    />

                    <LbaListenerEdit
                        editor={listeners}
                        needsHttps={needsHttps}
                        cacheStatus={RC_CACHED}
                    />

                    <LbaAdvanced
                        active={true}
                        https_redirect={[createParams.https.https_redirect, https_redirect]}
                        policy={[createParams.policy, policy]}
                        healthCheck={[createParams.healthcheck, healthcheck]}
                        ssl_minimum_version={[createParams.ssl_minimum_version, ssl_minimum_version]}
                        needsHttps={needsHttps}
                    />

                    <NameEditPanel
                        resourceTitle={'Load Balancer'}
                        editor={name}
                    >
                        <PanelResourceCreateButtons
                            leftContent={<PerMonthPrice label='Load Balancers' id='loadbalancer' inDialog={false} />}
                        >
                            {account && account.load_balancers_limit === account.load_balancers_used
                                ? <Tooltip overlay={<LoadBalancerLimit/>}>
                                    <Button kind="primary" disabled={true}>Create Load Balancer</Button>
                                </Tooltip>
                                : <Button kind="primary" state={create.status === RC_API_REQUEST ? 'loading' : ''} onClick={validateAndCreate}>Create Load Balancer</Button>
                            }
                        </PanelResourceCreateButtons>
                    </NameEditPanel>
                </>
            }
            editors={[
                { editor: policy, component: LbaPolicyEdit, },
                { editor: healthcheck, component: LbaHealthcheckEdit, },
                { editor: https_redirect, component: LbaHttpsRedirectEdit, },
                { editor: ssl_minimum_version, component: LbaSslMinVerEdit, },
            ]}
        />
    );
};

