// @flow

import { useCallback, useEffect, useMemo } from 'react';
import { useEditorErrors, useMessagesDrivenEditor } from '../common/Editor';
import { useDispatch } from 'react-redux';
import { RC_CACHED, RC_NOT_FOUND, RC_SUCCESS } from '../../state/resource/type';
import { useTriggeredMessages } from './Messages';
import { historyBack } from '../../lib/history';
import { useDialog } from '../element/Dialog';
import { useFeatureLabel } from './Metadata';
import { RESIZE } from '../../api/feature_labels';

import type { EditorModal } from '../common/Editor';
import type { ResourceAction } from '../../state/resource/type';
import type { BbServer, BbCollectedServer } from '../../api/type.srv';
import type { BbServerType } from '../../api/type';
import type { DialogState } from '../element/Dialog';
import type { BbDatabaseServer, BbCollectedDatabaseServer, BbDatabaseType } from '../../api/type.dbs';
import type { DbsSimpleAction } from '../section/database_server/def';
import type { DialogAction } from '../element/Dialog';

export type ServerTypeValue<T> = {
    +selected: ?T,
    +current: T,
}

type ResourceTypeEditorHook<R: BbServer | BbDatabaseServer, T> = {
    +canResize: boolean,
    +editor: EditorModal<ServerTypeValue<T>, ?string, R>,
    +onValidate: () => ?string,
    +serverTypeChangedDialog: DialogState<ServerTypeValue<T>>,
}

export function useDatabaseServerResize(path: string, resource: ?BbDatabaseServer, simpleAction: DbsSimpleAction): ResourceTypeEditorHook<BbDatabaseServer, BbDatabaseType> {
    const canResize = useFeatureLabel(RESIZE, resource);

    const actions = useMemo(() => [
        {
            label: 'Restart Now',
            color: 'blue',
            kind: 'primary',
            onSelect: () => {
                simpleAction('reset');
            }
        }
    ], [simpleAction]);

    return useResourceTypeEditor<BbDatabaseServer, BbCollectedDatabaseServer, BbDatabaseType>(
        path, resource, false, actions, canResize
    );
}

export function useServerResize(path: string, resource: ?BbServer): ResourceTypeEditorHook<BbServer, BbServerType> {
    return useResourceTypeEditor<BbServer, BbCollectedServer, BbServerType>(
        path, resource, true, [], true
    )
}

function useResourceTypeEditor<
    R: BbServer | BbDatabaseServer,
    C: BbCollectedServer | BbCollectedDatabaseServer,
    T: BbServerType | BbDatabaseType,
>(path: string, resource: ?R, allowShrink: boolean, restartedDialogActions: Array<DialogAction<ServerTypeValue<T>>>, canResize: boolean): ResourceTypeEditorHook<R, T> {
    const errors = useEditorErrors<?string>(null);
    const { next, messages } = useTriggeredMessages<R>();
    const dispatch = useDispatch<ResourceAction<R, C>>();

    const [value, setValue, state, setState] = useMessagesDrivenEditor<R, ServerTypeValue<T>, ?string>(
        errors,
        path + 'resize',
        resource ? RC_CACHED : RC_NOT_FOUND,
        messages,
        (): ?R => resource,
        (resource: R) => ({
            selected: null,
            // $FlowFixMe no way to make R/C/T be 'dependent' types, so just ignore this flow problem.
            current: resource.server_type ?? resource.database_server_type,
        })
    );

    const status = resource?.status;
    const { setErrors } = errors;

    const onValidate = useCallback((): ?string => {
        let error = null;
        if (value == null) {
            error = 'No value';
        } else if (value.selected == null) {
            // won't really be seen; the button gets disabled anyway.
            error = 'You must select a new server type';
        } else if (allowShrink && value.selected.ram < value.current.ram && status !== 'inactive') {
            // another one that won't be seen; we disable these types and show a tooltip.
            error = 'Server must be stopped before it can be made smaller';
        } else if (!allowShrink && value.selected.ram < value.current.ram) {
            error = 'You cannot reduce the size of the server';
        }

        setErrors(error);

        return error;
    }, [value, status, setErrors, allowShrink]);

    const serverTypeChangedDialog = useDialog<ServerTypeValue<T>>(restartedDialogActions);
    const { show } = serverTypeChangedDialog;

    const { status: apiResultStatus } = messages;
    useEffect(() => {
        if (apiResultStatus === RC_SUCCESS) {
            show(value);
        }
    }, [value, show, apiResultStatus])

    return {
        canResize,
        onValidate,
        serverTypeChangedDialog,
        editor: {
            status: state === 'editing' ? 'edit' : false,
            editUri: 'resize',
            value,
            setValue: (val: ?ServerTypeValue<T>) => setValue(val),
            messages,

            onCancel: () => {
                setState('initial');
                historyBack();
            },
            onSave: () => {
                if (value != null) {
                    const { selected } = value;
                    if (selected == null) {
                        errors.setErrors('Please select a new server type');
                    } else {
                        messages.clear();
                        errors.clearErrors();
                        setState('saving');
                        dispatch({
                            type: 'RESOURCE_SIMPLE_ACTION',
                            payload: {
                                id: resource?.id,
                                kind: resource?.resource_type,
                                action: 'resize',
                                method: 'POST',
                                params: {
                                    new_type: selected.id,
                                },
                                messagesId: next(),
                                suppressErrorToast: true,
                            }
                        });
                    }
                }
            },

            ...errors,
        }
    };
}
