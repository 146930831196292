// @flow

import { Client } from 'faye';
import { usersUrl, eventStreamUrl } from './url';
import { clearToken, fetchKioskToken } from './kiosk';
import { toast } from 'react-toastify';
import to from 'await-to-js';
import { request } from './rest';
import { Reconnecting } from '../component/element/Reconnecting';

import type { Store } from 'redux';
import type { CloudGuiState } from '../state/cloudgui';

type Subscription = {
    ...Promise<any>,
    cancel: () => void,
    ...
}

const infraReadQuery = `mutation { issueJsonWebToken(input: { scopes:[INFRASTRUCTURE_READ], duration: 30 }) { token { token } } }`;

class FayeWrapper {
    _store: Store<CloudGuiState, any>;
    _client: ?typeof Client
    _accountId: ?string;
    _sub: ?{
        id: string,
        sub: Subscription;
    }
    _shownErrorToast: boolean;

    constructor(store: Store<CloudGuiState, any>) {
        this._store = store;
        this._client = null;
        this._accountId = null;
        this._sub = null;
        this._shownErrorToast = false

        if (window) {
            window.addEventListener('beforeunload', () => this.disconnect(false));
        }
    }

    async _getEventsToken(): Promise<string> {
        let token = await fetchKioskToken(infraReadQuery);
        if (token === '' && !this._shownErrorToast) {
            this._shownErrorToast = true;
            toast(<Reconnecting msg='Could not fetch event stream token' />, { type: 'error', toastId: 'faye', autoClose: false, });
        }
        return token;
    }

    disconnect(forgetAccount: boolean) {
        const { _client } = this;
        if (_client) {
            _client.unbind('transport:down');
            _client.unbind('transport:up');
            if (this._sub) this._sub.sub.cancel();
            _client.disconnect();
            this._client = null;
            this._sub = null;
            if (forgetAccount) this._accountId = null;
        }
    }

    async setAccountId(id: ?string): Promise<void> {
        if (this._sub) {
            this._sub.sub.cancel();
            this._sub = null;
        }

        if (id) {
            this._accountId = id;
            return this.connect();
        }

        return Promise.resolve();
    }


    async connect(): Promise<void> {
        if (this._client === null) {
            const client = new Client(eventStreamUrl, { retry: 5 });
            client.disable('autodisconnect');
            const ext = {
                outgoing: (message, callback) => {
                    if (message.channel === '/meta/subscribe') {
                        this._getEventsToken()
                            .then((token) => {
                                message.ext = { auth_token: token };
                                callback(message);
                            })
                            .catch(() => {
                                // let faye handle the connection-with-no-auth problem.
                                callback(message);
                            })
                    } else {
                        callback(message);
                    }
                },
                incoming: (message, callback) => {
                    // we have this in case a computers clock is not correct.
                    // we can't rely on our payload.exp < now() logic in that case.
                    // we can reliably look out for this failure and just clear the token,
                    // which will trigger the kiosk wrapper to get another one.
                    if (message.successful === false && message.error === 'Please supply valid security token') {
                        clearToken(infraReadQuery);
                    }
                    callback(message);
                }
            };
            client.addExtension(ext);

            const transportDownHandler = async () => {
                // it's only an unexpected disconnection if a rest api
                // request succeeds.  if the rest api request fails,
                // chances are the computer has come back from sleep
                // and the session has timed out anyway.
                const [err,] = await to(request({
                    url: usersUrl,
                    accountId: false,
                    nested: false,
                }));
                if (!err && !this._shownErrorToast) {
                    this._shownErrorToast = true;
                    toast(<Reconnecting msg='Event stream disconnected' />, { type: 'warning', toastId: 'faye', autoClose: false, });
                }
                this._store.dispatch({ type: 'AUTH_SET_FIELD', payload: { eventsConnected: false } });
                if (this._sub) {
                    this._sub.sub.cancel();
                    this._sub = null;
                }
            }
            client.bind('transport:down', transportDownHandler);
            client.bind('transport:up', () => this._connectAccount());

            // this call is needed on the 2nd and later creations of a Client
            // for a given URL to ensure faye connects. The first time it's a
            // no-op.
            client.connect();

            this._client = client;
        } else {
            return this._connectAccount();
        }
    }

    async _connectAccount(): Promise<void> {
        if (!this._store.getState().Auth.scopes.has('infrastructure')) {
            // don't try to resubscribe to an account until we're logged
            // in to the API and can actually fetch a token to subscribe
            // to accounts.
            return Promise.resolve();
        }

        const accountId = this._accountId;
        const client = this._client
        if (accountId && client && this._sub?.id !== this._accountId) {
            if (this._sub) this._sub.sub.cancel();

            // this is useful to test the notices; exposing the client
            // lets you run window.__currentFayeClient._dispatcher._transport._socket.close()
            if (process.env.NODE_ENV === 'development') {
                window.__currentFayeClient = client;
            }

            const sub = client.subscribe(`/account/${accountId}`, (message: Object) => {
                // Make sure we don't get any timing problem events from the wrong account
                // Wouldn't look great in the UI as the rest of the code will
                // go off and try to fetch the relevant resource, probably 404, etc.
                if (accountId !== this._accountId) {
                    return;
                }
                this._store.dispatch({
                    type: 'RESOURCE_EVENT_RAW',
                    payload: {
                        message
                    }
                })
            });
            sub.then(
                () => {
                    this._shownErrorToast = false;
                    toast.dismiss('faye');
                    this._store.dispatch({ type: 'AUTH_SET_FIELD', payload: { eventsConnected: true } });
                },
                () => {
                    this._store.dispatch({ type: 'AUTH_SET_FIELD', payload: { eventsConnected: false } });
                    // don't spam toasts. and don't bother with one if the account ID has changed before
                    // this sub got established; perhaps rapidly switching between accounts will cancel this sub
                    // and end up in here?
                    if (!this._shownErrorToast && this._accountId === accountId) {
                        this._shownErrorToast = true;
                        toast(<Reconnecting msg='Event stream connection failed' />, { type: 'warning', toastId: 'faye', autoClose: false, });
                    }
                }
            );
            this._sub = {
                id: accountId,
                sub
            };
        }
    }
}

let wrapper: ?FayeWrapper = null;

export function setStore(store: Store<CloudGuiState, any>): void {
    if (!wrapper) wrapper = new FayeWrapper(store);
}

export async function setAccountId(id: ?string): Promise<void> {
    if (wrapper) return wrapper.setAccountId(id);
    return Promise.resolve();
}

export function disconnectFaye(forgetState: boolean) {
    if (wrapper) {
        wrapper.disconnect(forgetState);
    }
}

export function checkEventConnection() {
    if (wrapper) wrapper.connect();
}

export function forceFayeReconnect() {
    const w = wrapper;
    if (w) {
        w.disconnect(false);
        setTimeout(() => {
            w.connect();
        }, 100);
    }
}