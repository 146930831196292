// @flow
import { cloudIpsSort, idNameSort, stringSort, dateSort } from '../../element/Sort';
import { isSelectable } from '../../hoc/ListSelection';

import type { SortFields } from '../../element/Sort';
import type { BbCollectedServer } from '../../../api/type.srv';

export const serverSortFields: SortFields<BbCollectedServer> = {
    _default: stringSort<BbCollectedServer>('id'),
    id: idNameSort<BbCollectedServer>(),
    cloud_ips: cloudIpsSort<BbCollectedServer>('cloud_ips'),
    created_at: dateSort<BbCollectedServer>('created_at'),
    server_groups: (a: BbCollectedServer, b: BbCollectedServer) => {
        let res = a.server_groups.length - b.server_groups.length;
        if (res === 0 && a.server_groups.length > 0) {
            const ag = a.server_groups.sort((ga, gb) => (ga.name || ga.id).localeCompare(gb.name || gb.id));
            const bg = b.server_groups.sort((ga, gb) => (ga.name || ga.id).localeCompare(gb.name || gb.id));
            res = (ag[0].name || ag[0].id).localeCompare(bg[0].name || bg[0].id);
        }

        return res;
    },
    server_type: (a: BbCollectedServer, b: BbCollectedServer) => a.server_type.disk_size - b.server_type.disk_size,
};

export function serverSelectableFilter(server: BbCollectedServer): boolean {
    return isSelectable(server.status);
}

