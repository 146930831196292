// @flow
import { useMemo } from 'react';
import { PanelHeader, Panel, PanelButtonBar, PanelBar } from '../../../element/Panel';
import { ValueSelect } from '../../../element/ValueSelect';
import { TextInput } from '../../../element/Input';
import { ToggleGroup } from '../../../element/ToggleGroup';
import { historyBack, useResourceRoutes } from '../../../../lib/history';
import { useResourcesById } from '../../../hoc/ListPage';
import { useCurrentAccountId } from '../../../hoc/lib';
import { Tooltip } from '../../../element/Tooltip';
import { Notice } from '../../../element/Notice';
import { ResourceChipFetch } from '../../../element/Chip';
import { Card } from '../../../element/Card';
import { emptyEdit, validateContainerAccessEntry } from '../def';

import type { BbApiClient } from '../../../../api/type.cli';
import type { OrbitEditMetaType, ContainerAccessEdit, ContainerAccessEntryType } from '../def';
import type { EditorModal } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { OrbitContainerMeta } from '../../../../api/type.orbit';
import type { ValueSelectChoice, ValueSelectOptions } from '../../../element/ValueSelect';
import type { ToggleValueSelectChoice } from '../../../element/ToggleGroup';

type ContainerAccessAddProps = {
    +editor: EditorModal<OrbitEditMetaType<ContainerAccessEdit>, FormErrors, OrbitContainerMeta>,
    +containerRegistry?: boolean,
    +addEdit: 'add' | 'edit',
}

const allAccessTypeOptions: $ReadOnlyArray<ToggleValueSelectChoice<ContainerAccessEntryType>> = [
    { label: 'Public Read Access', value: 'any-referrer', },
    { label: 'API Client', value: 'api', },
    { label: 'HTTP Referrer', value: 'http', },
    { label: 'Other', value: 'other' },
];

const containerRegistryAccessTypeOptions: $ReadOnlyArray<ToggleValueSelectChoice<ContainerAccessEntryType>> = [
    { label: 'Public Read Access', value: 'any-referrer', },
    { label: 'New API Client', value: 'new-api', },
    { label: 'Existing API Client', value: 'api', },
];


const readWriteOptions = [
    { label: 'Select...', value: '', },
    { label: 'Read only', value: 'read', },
    { label: 'Write only', value: 'write', },
    { label: 'Read + Write', value: 'readwrite', },
];

const pushPullOptions = [
    { label: 'Select...', value: '', },
    { label: 'Pull only', value: 'read', },
    { label: 'Push only', value: 'write', },
    { label: 'Push + Pull', value: 'readwrite', },
];

const allowOptions = [
    { label: 'Allow', value: 'allow', },
    { label: 'Deny', value: 'deny', },
];

function useApiClientOptions(): Array<ValueSelectChoice<string>> {
    const { resources } = useResourcesById<BbApiClient>('api_client');
    const accountId = useCurrentAccountId() || '';

    return useMemo(() => {
        return Object.keys(resources || {})
            .filter(id => resources[id].permissions_group === 'storage')
            .reduce((acc: Array<ValueSelectChoice<string>>, entry: string) => {
                acc.push({
                    label: resources[entry].id + (resources[entry].name ? ' (' + resources[entry].name + ')' : ''),
                    value: accountId + ':' + resources[entry].id,
                });
                return acc;
            }, ([{
                label: 'Select...', value: '',
            }]: Array<ValueSelectChoice<string>>));

    }, [resources, accountId]);
}

export function buildAccessTypeOptions(containerRegistry: ?boolean, value: ?OrbitEditMetaType<ContainerAccessEdit>): $ReadOnlyArray<ToggleValueSelectChoice<ContainerAccessEntryType>> {
    let next: $ReadOnlyArray<ToggleValueSelectChoice<ContainerAccessEntryType>> = (containerRegistry ? containerRegistryAccessTypeOptions : allAccessTypeOptions)
        .map(
            (templateOption) => {
                let nextOption = { ...templateOption };
                if (value) {
                    switch (templateOption.value) {
                        case 'any-referrer':
                            // see if this is already enabled, and disable if so.
                            const [errors,] = validateContainerAccessEntry(value.rawMeta, {
                                ...emptyEdit,
                                type: templateOption.value,
                            });
                            if (errors?.has('type')) {
                                nextOption = {
                                    ...nextOption,
                                    disabledTooltip: <p>An access rule for Public Read Access already exists on this
                                        container.</p>
                                };
                            }
                            break;
                        case 'new-api':
                        case 'api':
                        case 'http':
                        case 'other':
                            // these settings don't get enabled/disabled as options
                            break;
                        default:
                            throw new Error('New access type needs disable check');
                    }
                }

                return nextOption;
            }
        );

    return next;
}

export const ContainerAccessForm = ({ editor, containerRegistry, addEdit, }: ContainerAccessAddProps): React$Node => {
    const { value, setValue, onSave, errors } = editor;
    const apiClients = useApiClientOptions();
    const getRoute = useResourceRoutes();

    const availableAccessTypeOptions = useMemo(
        () => buildAccessTypeOptions(containerRegistry, value),
        [containerRegistry, value]
    );

    if (value == null) return null;

    const isReadOnly = value.edit.type === 'any-referrer' || value.edit.type === 'http';
    const hasAnyAccess = value.rawMeta.access.findIndex(x => x.type === 'any-referrer') !== -1;

    return (
        <Panel>
            <PanelHeader
                title={addEdit === 'add' ? 'Add Access Rule' : 'Edit Access'}
            />
            <PanelBar>
                <h3>Access Type</h3>
                {value.edit.initial == null
                    ? <ToggleGroup
                        label="Type"
                        selected={value.edit.type === '' ? null : value.edit.type}
                        options={availableAccessTypeOptions}
                        errorText={errors?.get('type')}
                        onSelect={(type) => setValue({
                            ...value,
                            edit: {
                                ...value.edit,
                                type,
                                access: (type === 'any-referrer' || type === 'http') ? 'read' : value.edit.access
                            },
                        })}
                    />
                    : <Tooltip overlay="You cannot change the type of existing rules.">
                        <ValueSelect
                            label="Type"
                            selected={value.edit.type}
                            readOnly={true}
                            options={((availableAccessTypeOptions: any): ValueSelectOptions<ContainerAccessEntryType>)}
                            errorText={errors?.get('type')}
                            onSelect={() => void 0}
                        />
                    </Tooltip>
                }


                {value.edit.type === 'api' && addEdit === 'add'
                    ? <>
                        <h3>{containerRegistry ? 'Existing ' : null}API Client</h3>
                        <ValueSelect
                            label='API Client'
                            options={apiClients}
                            errorText={errors?.get('apiClient')}
                            selected={value.edit.apiClient}
                            onSelect={(apiClient) => setValue({
                                ...value,
                                edit: {
                                    ...value.edit,
                                    apiClient
                                }
                            })}
                        />
                    </>
                    : null
                }

                {value.edit.type === 'api' && addEdit === 'edit'
                    ? <>
                        <h3>API Client</h3>
                        <Card link={getRoute('api_client', value.edit.apiClient.substr(10))}>
                            <ResourceChipFetch id={value.edit.apiClient.substr(10)} />
                        </Card>
                    </>
                    : null
                }

                {value.edit.type === 'new-api' && addEdit === 'add'
                    ? <>
                        <h3>New API Client</h3>
                        <TextInput
                            label='New'
                            autoPopulated={true}
                            focusSelectAll={true}
                            className='mb-2 w-80'
                            value={value.edit.newApiClientName}
                            onChange={(newApiClientName) => setValue({
                                ...value,
                                edit: {
                                    ...value.edit,
                                    type: 'new-api',
                                    newApiClientName
                                }
                            })}
                        />
                    </>
                    : null
                }

                {value.edit.type === 'http'
                    ? <>
                        <h3>HTTP Referrer</h3>

                        {hasAnyAccess
                            ? <Notice type='info' icon='info'>This Orbit container currently has <b>Public Read Access</b> enabled which allows all HTTP Referrers</Notice>
                            : null
                        }

                        <TextInput
                            label='Domain'
                            errorText={errors?.get('referrer')}
                            value={value.edit.referrer}
                            onChange={(referrer) => setValue({
                                ...value,
                                edit: {
                                    ...value.edit,
                                    referrer,
                                }
                            })}
                        />
                        <ToggleGroup
                            options={allowOptions}
                            selected={value.edit.allowDomain}
                            onSelect={(allowDomain) => setValue({
                                ...value,
                                edit: {
                                    ...value.edit,
                                    allowDomain,
                                }
                            })}
                        />

                    </>
                    : null
                }

                {value.edit.type === 'other'
                    ? <>
                        <h3>Other</h3>
                        <TextInput
                            label='Other'
                            errorText={errors?.get('other')}
                            value={value.edit.other}
                            onChange={(other) => setValue({
                                ...value,
                                edit: {
                                    ...value.edit,
                                    other,
                                }
                            })}
                        />
                    </>
                    : null
                }

                {value.edit.type !== ''
                    ? <>
                        <h3>Access</h3>
                        {isReadOnly
                            ? <Tooltip overlay='This type of rule can only be read-only.'>
                                <ValueSelect
                                    label='Access'
                                    options={containerRegistry ? pushPullOptions : readWriteOptions}
                                    selected={'read'}
                                    readOnly={true}
                                    onSelect={() => void 0}
                                />
                            </Tooltip>
                            : <ValueSelect
                                label='Access'
                                options={containerRegistry ? pushPullOptions : readWriteOptions}
                                selected={isReadOnly ? 'read' : value.edit.access}
                                readOnly={isReadOnly}
                                onSelect={(access) => setValue({
                                    ...value,
                                    edit: {
                                        ...value.edit,
                                        access
                                    }
                                })}
                            />
                        }
                    </>
                    : null}

            </PanelBar>

            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? onSave : null}}
                cancel={editor.status === 'edit' ? historyBack : null}
            />
        </Panel>
    );
};