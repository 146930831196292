// @flow

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import to from 'await-to-js';
import { useMessages, useTriggeredMessages } from './Messages';
import { DELETE_COLLAB_MESSAGES } from '../../state/resource/DependentResourceLogic';
import { request } from '../../api/rest';
import { userCollaborationsUrl } from '../../api/url';
import { resourceMetas, adaptCollaboration } from '../../api/adapter';
import { handleApiError } from '../../lib/ErrorHandling';
import { RC_API_REQUEST, RC_ERROR, RC_SUCCESS, RC_INITIAL, RC_FETCHING, RC_CACHED } from '../../state/resource/type';

import type { Dispatch } from 'redux';
import type { ResourceDeleteCollaborationAction, ResourceFetched, } from '../../state/resource/type';
import type { BbCollaboration } from '../../api/type';
import type { AuthGetAccount } from '../../state/auth/type';
import type { MessageHook } from './Messages';
import type { MessageAction } from '../../state/Message/type';


type DeleteCollaboration = {
    deleteCollaboration: (userId: string, accountId: string) => void,
    messages: MessageHook<BbCollaboration>,
}

export const useDeleteCollaboration = (): DeleteCollaboration => {
    const messages = useMessages<BbCollaboration>(DELETE_COLLAB_MESSAGES);
    const dispatch = useDispatch<Dispatch<ResourceDeleteCollaborationAction | AuthGetAccount>>();

    const deleteCollaboration = (userId: string, accountId: string) => {
        dispatch({
            type: 'RESOURCE_DELETE_COLLABORATION',
            payload: {
                accountId,
                userId
            }
        });
    };

    useEffect(() => {
        if (messages.status === RC_SUCCESS) {
            dispatch({ type: 'AUTH_GET_ACCOUNT' });
        }
    }, [dispatch, messages.status]);

    return {
        deleteCollaboration,
        messages,
    }
};

export type PendingCollaborations = {
    messages: MessageHook<BbCollaboration>,
    collaborations: Array<BbCollaboration>,
    cacheStatus: ResourceFetched,
    accept: (collab: BbCollaboration) => Promise<boolean>,
    reject: (collab: BbCollaboration) => Promise<boolean>,
};

export const usePendingCollaborations = (): PendingCollaborations => {
    const [ collaborations, setCollaborations ] = useState<Array<BbCollaboration>>([]);
    const [ generation, setGeneration ] = useState(0);
    const dispatch = useDispatch<Dispatch<AuthGetAccount | MessageAction>>();
    const { messages, next } = useTriggeredMessages();
    const [cacheStatus, setCacheStatus] = useState<ResourceFetched>(RC_INITIAL);

    useEffect(() => {
        let didCancel = false;

        async function fetch() {
            setCacheStatus(RC_FETCHING);

            let [ err, response ] = await to(request({
                url: userCollaborationsUrl,
                accountId: false,
            }));

            if (didCancel) {
                return;
            }

            if (response && response.status === 200) {
                setCollaborations(response.data
                    .filter(raw => raw.status === 'pending')
                    .map(resourceMetas['collaboration'].adapt.full)
                );
                setCacheStatus(RC_CACHED);
            }

            if (err) {
                setCacheStatus(RC_ERROR);
                handleApiError(err);
            }
        }

        fetch();

        return () => { didCancel = true; };
    }, [generation]);

    return {
        messages,
        collaborations,
        cacheStatus,
        accept: async (collab: BbCollaboration) => {
            const messageId = next();
            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messageId,
                    status: RC_API_REQUEST,
                }
            });

            let [ err, response ] = await to(request({
                url: userCollaborationsUrl + '/' + collab.id + '/accept',
                method: 'POST',
                accountId: false,
            }));

            if (err) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messageId,
                        status: RC_ERROR,
                        messages: err.response?.data?.errors
                    }
                });
                return false;
            }

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messageId,
                    status: RC_SUCCESS,
                    resource: adaptCollaboration(response.data),
                }
            });

            dispatch({ type: 'AUTH_GET_ACCOUNT' });
            setGeneration(generation + 1);
            return true;
        },
        reject: async (collab: BbCollaboration) => {
            const messageId = next();

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messageId,
                    status: RC_API_REQUEST,
                }
            });

            let [ err, response, ] = await to(request({
                url: userCollaborationsUrl + '/' + collab.id + '/reject',
                method: 'POST',
                accountId: false,
            }));

            if (err) {
                dispatch({
                    type: 'MESSAGE_MESSAGE',
                    payload: {
                        id: messageId,
                        status: RC_ERROR,
                        messages: err.response?.data?.errors
                    }
                });
                return false;
            }

            dispatch({
                type: 'MESSAGE_MESSAGE',
                payload: {
                    id: messageId,
                    status: RC_SUCCESS,
                    resource: adaptCollaboration(response.data),
                }
            });

            dispatch({ type: 'AUTH_GET_ACCOUNT' });
            setGeneration(generation + 1);

            return true;
        },
    }
};