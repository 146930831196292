// @flow

import type { Icon } from './icons';

const CONTENT_TYPE_MAP: Map<string, Icon> = new Map([
    ['application/directory', 'folder'],
    ['image/*', 'file/image'],
    ['image/svg+xml', 'file/svg'],
    ['application/pdf', 'file/pdf'],
    ['application/zip', 'file/archive'],
    ['application/tar', 'file/archive'],
    ['application/vnd.rar', 'file/archive'],
    ['text/*', 'file/text'],
    ['text/markdown', 'file/markdown'],
    ['text/x-python-script', 'file/python'],
    ['text/x-ruby-script', 'file/ruby'],
    ['text/php', 'file/php'],
    ['text/xml', 'code'],
    ['text/javascript', 'file/javascript'],
    ['text/css', 'file/css'],
    ['audio/*', 'file/audio'],
    ['video/*', 'file/video'],
    ['text/csv', 'file/spreadsheet'],
    ['application/vnd.ms-excel', 'file/spreadsheet'],
    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'file/spreadsheet'],  
]);

export function getIconForContentType(contentType: string): Icon {
    let svg: ?Icon = CONTENT_TYPE_MAP.get(contentType);
    if (svg == null) {
        let contentAnyIcon = contentType.split('/')[0] + '/*';
        svg = CONTENT_TYPE_MAP.get(contentAnyIcon)
    }

    return svg || 'file';
}