// @flow

import { Panel, PanelBar, PanelHeader } from '../../element/Panel';
import { useCurrentAccountId } from '../../hoc/lib';
import { Table, Tr, Td, Th } from '../../element/Table';
import { ContainerAccessToUriFragment } from './def';
import { Link } from 'react-router-dom';
import { Button } from '../../element/Button';
import { ResourceChip, Chip } from '../../element/Chip';
import { SkeletonListLoadingEntries, SkeletonChip, SkeletonBar } from '../../element/Skeleton';
import { useMergedContainerAccess } from '../../hoc/orbit/Access';
import { usePager } from '../../element/ArrayPager';
import { PagerBar } from '../../element/PagerBar';
import { Tooltip } from '../../element/Tooltip';
import { Pill } from '../../element/Pill';
import { useResourceRoutes } from '../../../lib/history';

import type { OrbitContainerMeta, ContainerAccessType, ContainerAccess } from '../../../api/type.orbit';

type OrbitContainerAccessProps = {
    +editDisabled: boolean,
    +meta: ?OrbitContainerMeta,
    +onDeleteEntry: (entry: ContainerAccess) => void,
    +containerRegistry?: boolean,
}

function accessTypeLabel(access: ContainerAccessType): string {
    switch(access) {
    case 'api': return 'API Client';
    case 'team': return 'Team';
    case 'http': return 'HTTP Referrer';
    case 'any-referrer': return 'Public';
    case 'other': return 'Other';
    default:
        void (access: empty);
        return '';
    }
}

const ReadWriteFlags = ({ read, write }: { read: boolean, write: boolean }) => (
    <>
        {read ? 'Read' : null}
        {read && write ? ' + ' : null}
        {write ? 'Write' : null}
    </>
)

const PushPullFlags = ({ read, write }: { read: boolean, write: boolean }) => (
    <>
        {write ? 'Push' : null}
        {read && write ? ' + ' : null}
        {read ? 'Pull' : null}
    </>
)


export const OrbitContainerAccess = ({ meta, onDeleteEntry, editDisabled, containerRegistry, }: OrbitContainerAccessProps): React$Node => {
    const currAccountId = useCurrentAccountId();
    const { items: allItems, Th: SortTh, loading } = useMergedContainerAccess(meta, false);
    const pager = usePager('orbitContainerAccess', allItems, allItems.length);
    const { items: entries } = pager;
    const getRoute = useResourceRoutes();

    const Flags = containerRegistry ? PushPullFlags : ReadWriteFlags;

    return (
        <Panel>
            <PanelHeader
                title='Access Control'
                actions={{ add_access: editDisabled ? null : 'access/add', }}
            />
            <PanelBar padding={false}>
                <Table>
                    <thead>
                    <Tr>
                        <SortTh field='id'>Name</SortTh>
                        <SortTh field='type'>Type</SortTh>
                        <SortTh field='access'>Access</SortTh>
                        <Th actions={true}>&nbsp;</Th>
                    </Tr>
                    </thead>
                    <tbody>
                    {loading
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td><SkeletonChip/></Td>
                                <Td><SkeletonChip/></Td>
                                <Td><SkeletonBar size='md'/></Td>
                                <Td actions={true}><SkeletonBar size='md'/></Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : entries.map((entry: ContainerAccess) => [
                            entry.type === 'team'
                                ? <Tr key={entry.id}>
                                    <Td resourceName={true}>
                                        <Chip
                                            name='Team'
                                            icon='team'
                                            id={currAccountId}
                                        />
                                    </Td>
                                    <Td>{accessTypeLabel(entry.type)}</Td>
                                    <Td>
                                        <>
                                            <Flags read={true} write={true} />
                                            <Tooltip overlay='Users on your Account have implicit access to Orbit containers'>
                                                <Pill>?</Pill>
                                            </Tooltip>
                                        </>
                                    </Td>
                                    <Td actions={true}>
                                        <Link to={getRoute('team')}><Button kind='tertiary' size='sm'>Manage Team</Button></Link>
                                    </Td>
                                </Tr>
                                : null,
                            entry.type === 'api'
                                ? <Tr key={entry.id}>
                                    <Td resourceName={true}>
                                        {entry.full
                                            ? <Chip
                                                name='API Clients'
                                                icon='braces'
                                                />
                                            : entry.client && !entry.full
                                                ? <ResourceChip resource={entry.client}/> /* this will always be the case, since this is from the 'merged' hook */
                                                : entry.id
                                        }
                                    </Td>
                                    <Td>
                                        {entry.full
                                            ? 'API Clients (Full)'
                                            : accessTypeLabel(entry.type) + (
                                                entry.accountId !== currAccountId
                                                    ? ` (in account ${entry.accountId})`
                                                    : ' (Orbit)'
                                            )
                                        }
                                    </Td>
                                    <Td>
                                        {entry.full
                                            ? <>
                                                <Flags read={true} write={true} />
                                                <Tooltip overlay='"Full" API clients have implicit access to Orbit containers'>
                                                    <Pill>?</Pill>
                                                </Tooltip>
                                            </>
                                            : null
                                        }
                                        <Flags read={entry.read} write={entry.write} />
                                    </Td>
                                    <Td actions={true}>
                                        {!entry.full
                                            ? <>
                                                <Link to={`access/${ContainerAccessToUriFragment(entry)}`}>
                                                    <Button kind='tertiary' size='sm'>Edit</Button>
                                                </Link>
                                                <Button kind='tertiary' size='sm' color='red' onClick={() => onDeleteEntry(entry)}>Remove</Button>
                                            </>
                                            : <Link to={getRoute('api_client')}><Button kind='tertiary' size='sm'>Manage API Clients</Button></Link>
                                        }
                                    </Td>
                                </Tr>
                                : null,
                            entry.type === 'http'
                                ? <Tr key={entry.id}>
                                    <Td resourceName={true}>
                                        <Chip
                                            name={(entry.read ? 'Allow' : 'Deny') + ': ' + entry.referrer}
                                            icon='world'
                                        />
                                    </Td>
                                    <Td>{accessTypeLabel(entry.type)}</Td>
                                    <Td>
                                        {entry.read
                                            ? <>
                                                <Flags read={true} write={false} />
                                                <Tooltip overlay='HTTP Referrer access is always read only'>
                                                    <Pill>?</Pill>
                                                </Tooltip>
                                            </>
                                            : 'None'
                                        }
                                    </Td>
                                    <Td actions={true}>
                                        <Link to={`access/${ContainerAccessToUriFragment(entry)}`}>
                                            <Button kind='tertiary' size='sm'>Edit</Button>
                                        </Link>
                                        <Button kind='tertiary' size='sm' color='red' onClick={() => onDeleteEntry(entry)}>Remove</Button>
                                    </Td>
                                </Tr>
                                : null,
                            entry.type === 'any-referrer'
                                ? <Tr key={entry.id}>
                                    <Td resourceName={true}>
                                        <Chip
                                            name={(entry.read ? '' : 'Deny ') + 'Public Read Access'}
                                            icon='world'
                                        />
                                    </Td>
                                    <Td>{accessTypeLabel(entry.type) || ''}</Td>
                                    <Td>
                                        {entry.read
                                            ? <>
                                                <Flags read={true} write={false} />
                                                <Tooltip overlay='Public Access is always read only'>
                                                    <Pill>?</Pill>
                                                </Tooltip>
                                            </>
                                            : 'None'
                                        }
                                    </Td>
                                    <Td actions={true}>
                                        <Tooltip overlay='Public Access can only be read only'>
                                            <Button kind='tertiary' size='sm' disabled={true}>Edit</Button>
                                        </Tooltip>
                                        {meta?.web.listings == null && meta?.web.index == null
                                            ? <Button kind='tertiary' size='sm' color='red' onClick={() => onDeleteEntry(entry)}>Remove</Button>
                                            : <Tooltip placement='topRight' overlay='Public read access is required for Static Website Mode and Public Object Listings'>
                                                <Button kind='tertiary' size='sm' color='red' disabled={true} onClick={() => void 0}>Remove</Button>
                                            </Tooltip>
                                        }

                                    </Td>
                                </Tr>
                                : null,
                            entry.type === 'other'
                                ? <Tr key={entry.id}>
                                    <Td>Other: {entry.id}</Td>
                                    <Td>{accessTypeLabel(entry.type)}</Td>
                                    <Td>
                                        <Flags read={entry.read} write={entry.write} />
                                    </Td>
                                    <Td actions={true}>
                                        <Link to={`access/${ContainerAccessToUriFragment(entry)}`}>
                                            <Button kind='tertiary' size='sm'>Edit</Button>
                                        </Link>
                                        <Button kind='tertiary' size='sm' color='red' onClick={() => onDeleteEntry(entry)}>Remove</Button>
                                    </Td>
                                </Tr>
                                : null
                        ])
                    }
                    </tbody>

                </Table>
            </PanelBar>
            <PagerBar {...pager.pager} />
        </Panel>
    );
};