// @flow strict
import { useEffect, useState, useMemo, } from 'react';
import { useDispatch, } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useListResource } from '../hoc/ListPage';
import { cloudIpSortFields } from '../section/cloud_ip/def';
import { Panel, PanelBar, PanelButtonBar, PanelHeader, PanelSearchBar, } from '../element/Panel';
import { useSelectorListGrid } from '../element/UiStateHooks';
import { ResourceChip } from '../element/Chip';
import { CardGroup } from '../element/CardGroup';
import { Card } from '../element/Card';
import { SelectorSummary } from '../element/SelectorSummary';
import { useCurrentAccount } from '../hoc/lib';
import { useEditorDirect, useEditorErrors, } from './Editor';
import { Notice } from '../element/Notice';
import { createCloudIpSearch } from '../section/cloud_ip/CipSearch';
import { useResourceSearch } from '../element/Search';
import { Tooltip } from '../element/Tooltip';
import { emptyErrors } from './lib';
import { history, historyBack, useResourceRoutes } from '../../lib/history';
import { RC_CACHED, RC_ERROR, RC_FETCHING, RC_SUCCESS, } from '../../state/resource/type';
import { PanelApiErrorBar } from '../element/PanelApiErrorBar';
import { useCloudIpMessages } from '../hoc/Messages';
import { Button } from '../element/Button';
import { RelatedPopover } from '../element/RelatedPopover';
import { Counter } from '../element/Counter';
import { SkeletonCardPanel, } from '../element/Skeleton';
import { Table, Td, Tr } from '../element/Table';
import { usePager } from '../element/ArrayPager';
import { PagerBar } from '../element/PagerBar';
import { Checkbox } from '../element/Checkbox';
import { CloudIpListTable } from '../section/cloud_ip/CipList';
import { useCipCreateDialog, CipCreateConfirmDialog } from '../section/cloud_ip/CipCreateConfirmDialog';
import { useDialog, Dialog } from '../element/Dialog';
import { Spinner } from '../element/Spinner';
import { Bar } from '../element/Styled';
import { LABELS } from '../element/ResourceLabels';

import type { Dispatch } from 'redux';
import type { BbCloudIp, BbCloudIpParams, BbNestedCloudIp, CipAssignParams, } from '../../api/type.cip';
import type { EditorDirect, EditorModal } from './Editor';
import type { FormErrors } from './lib';
import type { BbAccount } from '../../api/type.acc';
import type { ResourceCacheStatus, } from '../../state/resource/type';
import type { CloudIpMessages, } from '../hoc/Messages';
import type { ListPageProps, ListSortDef } from '../hoc/ListPage';
import type { DialogState } from '../element/Dialog';
import type { CloudIpMapAction } from '../../state/CloudIp/type';

const { searchDef, CloudIpSearch, } = createCloudIpSearch('cloud_ip:selector');

type CipList = ListPageProps<BbCloudIp>;

export type ResourceCipCreate = {
    cloudIps: ListPageProps<BbCloudIp>,
    editor: EditorDirect<CipAssignParams, FormErrors>,
    apiMessages: null,
};

export type ResourceCipEdit = {
    +cloudIps: ListPageProps<BbCloudIp>,
    +editor: EditorModal<CipAssignParams, FormErrors, BbCloudIp>,
    +apiMessages: CloudIpMessages,
    +unmapDialog: DialogState<CipAssignParams>,
};

function getCipAssignParamsForResourceId(resourceId: string, items: Array<BbCloudIp>): CipAssignParams {
    const ids = items.filter(ip => isMappedTo(ip, resourceId)).map(ip => ip.id);

    return {
        ids,
    };
}

function validateCipAssign(params: CipAssignParams, account: ?BbAccount, cloudIps: CipList, resourceId: string): [?FormErrors, ?CipAssignParams] {
    let errors = new Map();

    cloudIps.items.forEach((ip: BbCloudIp) => {
        const wasAssigned = isMappedTo(ip, resourceId);
        const isAssigned = params.ids.indexOf(ip.id) !== -1;
        const stillAvailable = wasAssigned || isAvailable(ip);

        if (isAssigned && !stillAvailable) {
            errors.set(ip.id, `${ip.id} has already been mapped elsewhere`);
        }
    });

    return [
        errors.size === 0 ? null : errors,
        errors.size === 0 ? params : null,
    ];
}

function cipAssignHasUnmap(value: CipAssignParams, cloudIps: CipList, resourceId: string): boolean {
    for (let i = 0; i < cloudIps.items.length; i++) {
        const ip = cloudIps.items[i];
        const wasAssigned = isMappedTo(ip, resourceId);
        const isAssigned = value.ids.indexOf(ip.id) !== -1;

        if (wasAssigned === true && isAssigned === false) return true;
    }

    return false;
}

function saveCipAssign(value: CipAssignParams, cloudIps: CipList, cipAction: CipActions, resourceId: string): boolean {
    let changed = false;
    cloudIps.items.forEach((ip: BbCloudIp) => {
        const wasAssigned = isMappedTo(ip, resourceId);
        const isAssigned = value.ids.indexOf(ip.id) !== -1;

        if (wasAssigned !== isAssigned) {
            changed = true;
            if (isAssigned) {
                cipAction.map(ip);
            } else {
                cipAction.unmap(ip);
            }
        }
    });

    return changed;
}

type CipActions = {
    map: (cip: BbCloudIp) => void,
    unmap: (cip: BbCloudIp) => void,
    messages: CloudIpMessages,
}

export const useCloudIpActions = (resourceId: string, destResourceId: ?string): CipActions => {
    const dispatch = useDispatch<Dispatch<CloudIpMapAction>>();
    const messagesPrefix = 'cip_' + resourceId;

    return {
        map: (cip: BbCloudIp) => {
            dispatch({
                type: 'CLOUD_IP_MAP',
                payload: {
                    cloudIpId: cip.id,
                    destination: destResourceId || resourceId,
                    messagesId: messagesPrefix + cip.id,
                }
            });
        },
        unmap: (cip: BbCloudIp) => {
            dispatch({
                type: 'CLOUD_IP_MAP',
                payload: {
                    cloudIpId: cip.id,
                    destination: null,
                    messagesId: messagesPrefix + cip.id,
                }
            });
        },
        messages: useCloudIpMessages(messagesPrefix),
    };
};

export const useCreateResourceCloudIps = (): ResourceCipCreate => {
    const cloudIps = useListResource<BbCloudIp, BbCloudIpParams, BbCloudIp>('cloud_ip', sort);
    const [value, setValue] = useState<CipAssignParams>({ ids: [] });

    const editor = useEditorDirect<CipAssignParams, FormErrors>(value, setValue, emptyErrors);

    return {
        cloudIps,
        editor,
        apiMessages: null,
    };
};

export const useEditResourceCloudIps = (resourceId: string, path: string, destResourceId?: string): ResourceCipEdit => {
    const cloudIps = useListResource<BbCloudIp, BbCloudIpParams, BbCloudIp>('cloud_ip', sort);
    const account = useCurrentAccount();
    const cipAction = useCloudIpActions(resourceId, destResourceId);
    const errors = useEditorErrors(emptyErrors);
    const [state, setState] = useState('initial');
    const [value, setValue] = useState<?CipAssignParams>(null);
    const { clearErrors } = errors;
    const unmapDialog = useDialog<CipAssignParams>([
        {
            label: 'Unmap Cloud IP',
            kind: 'primary',
            color: 'red',
            onSelect: (t: ?CipAssignParams) => {
                if (t) {
                    if (saveCipAssign(t, cloudIps, cipAction, resourceId)) {
                        setState('saving');
                    } else {
                        setState('closing');
                        historyBack();
                    }
                }
            }
        }
    ]);

    const shouldEdit = useRouteMatch(path + 'cloud_ips/') !== null;

    // page loads on /cloud_ips/ URLs need us to wait until the IPs are downloaded
    // before determining the initial editor state - this does that.
    useEffect(() => {
        if (shouldEdit && state === 'initial' && cloudIps.status === RC_CACHED) {
            setState('editing');
            clearErrors();
            cipAction.messages.summary.clear();
            setValue(getCipAssignParamsForResourceId(resourceId, cloudIps.items));
        }
        if (state === 'editing' && !shouldEdit) {
            setState('initial');
        }
    }, [shouldEdit, state, resourceId, cloudIps.status, cloudIps.items, account, clearErrors, cipAction.messages.summary]);

    useEffect(() => {
        if (cipAction.messages.summary && cipAction.messages.summary.status === RC_SUCCESS && state === 'saving') {
            setState('closing');
            historyBack();
        }
        if (cipAction.messages.summary && cipAction.messages.summary.status === RC_ERROR && state === 'saving') {
            setState('editing');
        }
    }, [cipAction.messages.summary, state]);

    useEffect(() => {
        if (state === 'closing' && !shouldEdit) {
            setState('initial');
        }
    }, [state, shouldEdit]);

    const editor: EditorModal<CipAssignParams, FormErrors, BbCloudIp> = {
        ...errors,
        status: state === 'editing' ? 'edit' : false,
        editUri: 'cloud_ips',
        messages: cipAction.messages.summary,

        value,
        setValue,

        onCancel: historyBack,
        onSave: () => {
            if (value) {
                let [validateErrors, commit] = validateCipAssign(value, account, cloudIps, resourceId);
                if (validateErrors) {
                    errors.setErrors(validateErrors);
                } else if (commit) {
                    if (cipAssignHasUnmap(commit, cloudIps, resourceId)) {
                        unmapDialog.show(commit);
                    } else if (saveCipAssign(commit, cloudIps, cipAction, resourceId)) {
                        setState('saving');
                    } else {
                        setState('closing');
                        historyBack();
                    }
                }
            }
        }
    };

    return {
        cloudIps,
        editor,
        unmapDialog,
        apiMessages: cipAction.messages,
    };
};

type ResourceCloudIpsProps = {
    +resourceTitle: string,
    +resourceId: string,
    +edit: ResourceCipEdit | ResourceCipCreate,
};

const isMappedTo = (cloudIp: BbCloudIp, resourceId: string): boolean =>
    (cloudIp.server != null && cloudIp.server.id === resourceId)
    || (cloudIp.interface != null && cloudIp.interface.id === resourceId)
    || (cloudIp.load_balancer != null && cloudIp.load_balancer.id === resourceId)
    || (cloudIp.database_server != null && cloudIp.database_server.id === resourceId)
    || (cloudIp.mapping_resource_id != null && cloudIp.mapping_resource_id === resourceId)
;
const isAvailable = (cloudIp: BbCloudIp): boolean => cloudIp.status === 'unmapped';

export const ResourceCloudIpEdit = ({ edit, resourceId, resourceTitle }: ResourceCloudIpsProps): React$Node => {
    const { cloudIps, editor, } = edit;
    const account = useCurrentAccount();
    const getRoute = useResourceRoutes();
    const [newIps, setNewIps] = useState<$ReadOnlyArray<string>>([]);

    const { value } = editor;

    const listGrid = useSelectorListGrid('grid');

    const allCloudIps = cloudIps.items;
    let filteredItems = useMemo(() => allCloudIps
        .filter((ip) =>
            (
                isAvailable(ip) ||
                isMappedTo(ip, resourceId) ||
                (value && value.ids.indexOf(ip.id) !== -1)
            )
        )
    , [allCloudIps, resourceId, value]);
    let resourceHasIps = !!cloudIps.items.find((ip) => isMappedTo(ip, resourceId));

    const { editor: searchEditor, items: searchedItems } = useResourceSearch(searchDef, filteredItems);
    const { items, pager } = usePager('resourceCloudIps', searchedItems, filteredItems.length);

    const setIpSelected = (ip: BbCloudIp, select: boolean) => {
        if (value) {
            if (select) {
                editor.setValue({ ...value, ids: [].concat(value.ids, ip.id) });
            } else {
                const idx = value.ids.findIndex(s => s === ip.id);
                if (idx !== -1) {
                    editor.setValue({ ...value, ids: [].concat(value.ids.slice(0, idx), value.ids.slice(idx + 1)) });
                }
            }
        }
    };

    const atCipLimit = !!(account && account.cloud_ips_used >= account.cloud_ips_limit);

    const selectedCount = value ? value.ids.length : 0;
    const hasUnmapped = cloudIps.items && (cloudIps.items.findIndex(x => x.status === 'unmapped') !== -1);

    const allocateMsg = !hasUnmapped && value && !resourceHasIps
        ? atCipLimit
            ? <Notice icon="info" type="warning">You've used your current allocation of Cloud IPs – to add more please create an <Link
                to={getRoute('limit', 'cloud_ip')}><b>account limit increase request</b></Link></Notice>
            : <Notice icon="arrow-left-down">You don't currently have any Cloud IPs available to choose</Notice>
        : null;

    const cipCreate = useCipCreateDialog({
        onCreate: (account, resource) => {
            setIpSelected(resource, true);
            setNewIps([...newIps, resource.id]);
        }
    });

    const messages: ?CloudIpMessages = edit.apiMessages || null;
    const showSearch = cloudIps.items && filteredItems.length >= 5;

    return (
        <>
            <CipCreateConfirmDialog dialog={cipCreate.dialog} apiResult={cipCreate.apiResult}/>
            {edit.unmapDialog
                ? <Dialog
                    dialog={edit.unmapDialog}
                    title='Unmap Cloud IP?'
                    render={() => (
                        <div>
                            Are you sure you want to remove the Cloud IP from this {resourceTitle}?
                        </div>
                    )}
                />
                : null
            }
            <Panel>
                <PanelHeader
                    title="Cloud IP addresses"
                    description={editor.status
                        ? 'Which publicly routed IP addresses should be mapped to this ' + resourceTitle + '?'
                        : null
                    }
                    mode={showSearch ? null : listGrid}
                />

                {showSearch && cloudIps.status !== RC_ERROR
                    ? <PanelSearchBar search={<CloudIpSearch editor={searchEditor}/>} mode={listGrid}/>
                    : null
                }

                {cloudIps.status === RC_ERROR
                    ? <PanelApiErrorBar forceRefresh={cloudIps.forceRefresh} {...LABELS['cloud_ip']}/>
                    : null
                }

                {listGrid[0] === 'grid' && cloudIps.status !== RC_ERROR
                    ? <PanelBar>
                        {allocateMsg}

                        {items.length > 0
                            ? <CardGroup>
                                {items.map((ip: BbCloudIp) => {
                                    const ipSelected = value ? value.ids.indexOf(ip.id) !== -1 : false;
                                    const ipStillAvailable = isAvailable(ip) || isMappedTo(ip, resourceId);
                                    const ipError: ?string = (ipSelected && !ipStillAvailable)
                                        ? 'This IP address has been assigned to another resource since you selected it.'
                                        : messages?.messages?.[ip.id]?.status === RC_ERROR
                                            ? messages?.messages?.[ip.id]?.messages[0]
                                            : null;

                                    const isNew = newIps.findIndex(x => x === ip.id) !== -1;

                                    const card = (
                                        <Card
                                            key={ip.id}
                                            selected={ipSelected}
                                            selectable={ipStillAvailable}
                                            onClick={value ? () => setIpSelected(ip, ipStillAvailable ? !ipSelected : false) : null}
                                            hasError={!!ipError}
                                        >
                                            <ResourceChip resource={ip} isNew={isNew} inline={true} />
                                        </Card>
                                    );

                                    if (ipError) {
                                        return <Tooltip key={ip.id} overlay={ipError}>{card}</Tooltip>;
                                    } else {
                                        return card;
                                    }
                                })}
                            </CardGroup>
                            : null
                        }
                        {cloudIps.status === RC_FETCHING
                            ? <CardGroup><SkeletonCardPanel/></CardGroup>
                            : null}
                    </PanelBar>
                    : null}

                {listGrid[0] === 'list' && cloudIps.status !== RC_ERROR
                    ? <PanelBar padding={false}>
                        <Table>
                            <thead>
                            <tr>
                                {editor.status ? <th>&nbsp;</th> : null}
                                <th>Name</th>
                                <th>IP Addresses</th>
                                <th>Reverse DNS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.length > 0
                                ? items.map((ip: BbCloudIp) => {
                                    const ipSelected = editor.status && value ? value.ids.indexOf(ip.id) !== -1 : false;
                                    const toggle = editor.status && value ? () => setIpSelected(ip, !ipSelected) : null;
                                    const isNew = newIps.findIndex(x => x === ip.id) !== -1;

                                    return (
                                        <Tr key={ip.id} selected={ipSelected} onClick={toggle} selectable={true}>
                                            {editor.status
                                                ? <Td selector={true}>
                                                    <Checkbox
                                                        color={'blue'}
                                                        checked={ipSelected}
                                                        onChange={toggle}
                                                    />
                                                </Td>
                                                : null
                                            }
                                            <Td onClick={toggle}>
                                                <ResourceChip resource={ip} isNew={isNew}/>
                                            </Td>
                                            <Td>
                                                <span className="u-data--ip-address">{ip.public_ipv4}</span><br/>
                                                <span className="u-data--ip-address">{ip.public_ipv6}</span>
                                            </Td>
                                            <Td>{ip.reverse_dns}</Td>
                                        </Tr>
                                    );
                                })
                                : <tr>
                                    <td colSpan="4">
                                        {allocateMsg}
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </PanelBar>
                    : null}

                <PagerBar {...pager} />

                {editor.status !== 'add'
                    ? <PanelButtonBar
                        cacheStatus={edit.apiMessages ? edit.apiMessages.summary.status : null}
                        primaryButton={{ onClick: editor.status === 'edit' ? editor.onSave : null }}
                        cancel={editor.status === 'edit' ? editor.onCancel : null}
                        leftButton={{
                            children: 'Add New Cloud IP',
                            kind: 'bare',
                            preIcon: 'add-fill',
                            disabled: atCipLimit,
                            onClick: () => cipCreate.dialog.show(),
                        }}
                        leftButtonTooltip={atCipLimit
                            ? {
                                overlay: 'You\'ve reached your account limit for Cloud IPs so we can\'t create a new one right now'
                            }
                            : null
                        }
                    >
                        <Bar />
                        <SelectorSummary selected={selectedCount} />
                    </PanelButtonBar>
                    : null}
                {editor.status === 'add'
                    ? <PanelButtonBar
                        leftButton={{
                            children: 'Add Cloud IP',
                            kind: 'bare',
                            preIcon: 'add-fill',
                            disabled: atCipLimit,
                            onClick: () => cipCreate.dialog.show(),
                        }}
                        leftButtonTooltip={atCipLimit
                            ? {
                                overlay: 'You\'ve reached your account limit for Cloud IPs so we can\'t create a new one right now'
                            }
                            : null
                        }
                    >
                        <Bar />
                        <SelectorSummary selected={selectedCount} />
                    </PanelButtonBar>
                        : null}
            </Panel>
        </>
    );
};

export const sort: ListSortDef<BbCloudIp> = {
    name: 'cloud_ip_resource',
    fields: cloudIpSortFields
};


type ResourceCloudIpsViewProps = {
    +cacheStatus: ResourceCacheStatus,
    +resourceId: string,
    +resourceTitle: string,
    +editUri: ?string,
}

export const ResourceCloudIpView = ({ resourceId, editUri, }: ResourceCloudIpsViewProps): React$Node => {
    const cloudIps = useListResource<BbCloudIp, BbCloudIpParams, BbCloudIp>('cloud_ip', sort);
    const listGrid = useSelectorListGrid('grid');
    const allItems = cloudIps.items.filter((ip: BbCloudIp) => isMappedTo(ip, resourceId));
    const { items, pager } = usePager('cloudIpView', allItems, allItems.length);
    const getRoute = useResourceRoutes();

    return (
        <Panel>
            <PanelHeader
                title="Cloud IPs"
                mode={listGrid}
                actions={editUri ? { edit: () => history.push(editUri + '/'), } : null}
            />

            {cloudIps.status === RC_ERROR
                ? <PanelApiErrorBar forceRefresh={cloudIps.forceRefresh} {...LABELS['cloud_ip']} />
                : null
            }

            {listGrid[0] === 'grid' && cloudIps.status !== RC_ERROR
                ? <PanelBar>
                    {cloudIps.status === RC_CACHED && items.length === 0
                        ? <Notice>No cloud IPs mapped.</Notice>
                        : null
                    }
                    <CardGroup>
                        {cloudIps.status === RC_FETCHING
                            ? <SkeletonCardPanel/>
                            : null
                        }
                        {items.map((ip: BbCloudIp) => (
                            <Card
                                key={ip.id}
                                link={getRoute('cloud_ip', ip.id)}
                            >
                                <ResourceChip resource={ip} link={getRoute('cloud_ip', ip.id)} inline={true} />
                                {ip.status === 'unmapped'
                                    ? <Spinner size='sm' color='gray' inline={true} />
                                    : null
                                }
                            </Card>
                        ))}
                    </CardGroup>
                </PanelBar>
                : null}

            {listGrid[0] === 'list' && cloudIps.status !== RC_ERROR
                ? <CloudIpListTable
                    status={cloudIps.status}
                    Th={cloudIps.Th}
                    totalCount={allItems.length}
                    items={items}
                    context="mapped"
                />
                : null}

            <PagerBar {...pager} />
        </Panel>
    );
};

export const ResourceCloudIpTd = ({ id, cloud_ips, canAdd }: { id: string, cloud_ips: $ReadOnlyArray<BbNestedCloudIp>, canAdd: boolean }): React$Node => (
    <>
        {cloud_ips.length === 0 && canAdd
            ? <Link to={`${id}/cloud_ips/`}>
                <Button kind="bare">+ Add</Button>
            </Link>
            : null
        }
        {cloud_ips.length === 0 && !canAdd
            ? 'None'
            : null
        }
        {cloud_ips.length === 1
            ? <RelatedPopover related={cloud_ips} editLink={{ kind: 'cloud_ips', id, label: 'Edit Cloud IPs' }}>
                {cloud_ips[0].status === 'unmapped'
                    ? <span className='text-gray-500'>
                        <span className="u-data--ip-address">
                            {cloud_ips[0].public_ipv4} <Spinner size='sm' inline={true} color='gray' />
                        </span>
                    </span>
                    : <span className="u-data--ip-address">{cloud_ips[0].public_ipv4}</span>
                }
            </RelatedPopover>
            : null
        }
        {cloud_ips.length > 1
            ? <>
                {cloud_ips[0].status === 'unmapped'
                    ? <span className='text-gray-500'>
                        <span className="u-data--ip-address">
                            {cloud_ips[0].public_ipv4} <Spinner size='sm' inline={true} color='gray' />
                        </span>
                    </span>
                    : <span className="u-data--ip-address">{cloud_ips[0].public_ipv4}</span>
                }                <RelatedPopover related={cloud_ips} editLink={{ kind: 'cloud_ips', id, label: 'Edit Cloud IPs' }}>
                    <Counter className="c-related-indicator__count">{cloud_ips.length}</Counter>
                </RelatedPopover>
            </>
            : null
        }
    </>
);
