// @flow
import gql from 'graphql-tag';
import { mapSeries, ticksFromRange, RANGE_QUERY } from '../../hoc/Metrics';
import { MetricsPanel } from './MetricsPanel';
import numeral from "numeral";

import type { ChartInputs } from './types';
import type {
    LbaFrontendConnectionsTotalMetrics,
    LbaFrontendHttpResponsesRateMetrics,
    LbaFrontendRequestsRateMetrics,
    LbaFrontendTrafficMetrics,
} from './types';

type LbaMetricsProps = {
    id: string,
}

const LBA_FRONTEND_CONNECTIONS = {
    title: 'Connections',
    tooltip: 'Total number of connections to the load balancer frontend',
    colorOffset: 0,
    yAxisFormatter: (v) => numeral(v).format('0.[00]'),
    query: {
        query: gql`
            query lbsFrontendConnectionsStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                loadBalancer(id: $resourceId, range: $range) {
                    ${RANGE_QUERY}
                    frontend {
                        connectionsTotal {
                            timestamps
                            values
                        }
                    }
                }
            }
        }
        `,
        adapter: (id: string, raw: LbaFrontendConnectionsTotalMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.loadBalancer.range,
                ['s0', raw.metrics.loadBalancer.frontend.connectionsTotal],
            );

            return {
                data: [
                    ['Connections'],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
}

const LBA_FRONTEND_HTTP_RESPONSES = {
    title: 'HTTP Responses',
    tooltip: 'Rate of HTTP responses sent by the Load Balancer to clients',
    colorOffset: 1,
    yAxisFormatter: (v) => numeral(v).format('0.[00]'),
    query: {
        query: gql`
            query lbsFrontendHttpResponsesStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                loadBalancer(id: $resourceId, range: $range) {
                    ${RANGE_QUERY}
                    frontend {
                        httpResponsesRate {
                            http1xx {
                                timestamps
                                values
                            }
                            http2xx {
                                timestamps
                                values
                            }
                            http3xx {
                                timestamps
                                values
                            }
                            http4xx {
                                timestamps
                                values
                            }
                            http5xx {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        }
        `,
        adapter: (id: string, raw: LbaFrontendHttpResponsesRateMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.loadBalancer.range,
                ['s0', raw.metrics.loadBalancer.frontend.httpResponsesRate.http1xx],
                ['s1', raw.metrics.loadBalancer.frontend.httpResponsesRate.http2xx],
                ['s2', raw.metrics.loadBalancer.frontend.httpResponsesRate.http3xx],
                ['s3', raw.metrics.loadBalancer.frontend.httpResponsesRate.http4xx],
                ['s4', raw.metrics.loadBalancer.frontend.httpResponsesRate.http5xx],
            );

            return {
                data: [
                    [
                        '1xx',
                        '2xx',
                        '3xx',
                        '4xx',
                        '5xx',
                    ],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
}

const LBA_FRONTEND_REQUESTS_RATE = {
    title: 'HTTP Requests',
    tooltip: 'Total number of HTTP requests to the load balancer frontend expressed in requests per second',
    colorOffset: 2,
    yAxisFormatter: (v) => numeral(v).format('0.[00]'),
    query: {
        query: gql`
            query lbsFrontendRequestsRateStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                loadBalancer(id: $resourceId, range: $range) {
                    ${RANGE_QUERY}
                    frontend {
                        requestsRate {
                            timestamps
                            values
                        }
                    }
                }
            }
        }
        `,
        adapter: (id: string, raw: LbaFrontendRequestsRateMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.loadBalancer.range,
                ['s0', raw.metrics.loadBalancer.frontend.requestsRate],
            );

            return {
                data: [
                    ['Requests'],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
}

const LBS_FRONTEND_TRAFFIC = {
    title: 'Network Data',
    tooltip: 'Traffic sent and received by the load balancer to clients in bytes per second',
    colorOffset: 1,
    yAxisFormatter: (v) => numeral(v).format('0.[00] b'),
    query: {
        query: gql`
            query lbsFrontendTraffic($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                loadBalancer(id: $resourceId, range: $range) {
                    ${RANGE_QUERY}
                    frontend {
                        traffic{
                            receiveBytesRate {
                                timestamps
                                values
                            }
                            transmitBytesRate {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        }
        `,
        adapter: (id: string, raw: LbaFrontendTrafficMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.loadBalancer.range,
                ['s0', raw.metrics.loadBalancer.frontend.traffic.receiveBytesRate],
                ['s1', raw.metrics.loadBalancer.frontend.traffic.transmitBytesRate],
            );

            return {
                data: [
                    [`Received`, `Sent`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        }
    }
}



export const LbaMetrics = ({ id }: LbaMetricsProps): React$Node => {
    const CHARTS = [
        LBA_FRONTEND_CONNECTIONS,
        LBS_FRONTEND_TRAFFIC,
        LBA_FRONTEND_REQUESTS_RATE,
        LBA_FRONTEND_HTTP_RESPONSES,
    ];

    return (
        <MetricsPanel id={id} charts={CHARTS} />
    );
}
