// @flow

import {Fragment} from 'react';

import { toLabelLookup, ValueSelect } from '../element/ValueSelect';
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../element/Panel';
import { ToggleGroup } from '../element/ToggleGroup';
import { defaultSnapshotSchedule } from '../section/database_server/edit/lib';
import { Notice } from '../element/Notice';
import { SkeletonBar } from '../element/Skeleton';

import type { EditorModal } from './Editor';
import type { BbDatabaseServer } from '../../api/type.dbs';
import type { BbServer } from '../../api/type.srv';


export type SnapshotEditProps = {
    +editor: EditorModal<string, null, BbDatabaseServer> | EditorModal<string, null, BbServer>,
    +kind: 'server' | 'database_server',
};

type Frequency = 'never' | 'daily' | 'weekly' | 'monthly';

const periodDescriptor = {
    'daily': 'day',
    'weekly': 'week',
    'monthly': 'month',
};

const frequencyChoices = [
    { label: 'Never', value: 'never' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
];

const hourChoices = [
    { label: '00:00', value: '0' },
    { label: '01:00', value: '1' },
    { label: '02:00', value: '2' },
    { label: '03:00', value: '3' },
    { label: '04:00', value: '4' },
    { label: '05:00', value: '5' },
    { label: '06:00', value: '6' },
    { label: '07:00', value: '7' },
    { label: '08:00', value: '8' },
    { label: '09:00', value: '9' },
    { label: '10:00', value: '10' },
    { label: '11:00', value: '11' },
    { label: '12:00', value: '12' },
    { label: '13:00', value: '13' },
    { label: '14:00', value: '14' },
    { label: '15:00', value: '15' },
    { label: '16:00', value: '16' },
    { label: '17:00', value: '17' },
    { label: '18:00', value: '18' },
    { label: '19:00', value: '19' },
    { label: '20:00', value: '20' },
    { label: '21:00', value: '21' },
    { label: '22:00', value: '22' },
    { label: '23:00', value: '23' },
];
// eugh - duplicated from weekdayChoices because these are strings...
const cronWeekdayChoices = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '0', label: 'Sunday' },
];
const cronDomChoices = [
    { value: '1', label: '1st' },
    { value: '2', label: '2nd' },
    { value: '3', label: '3rd' },
    { value: '4', label: '4th' },
    { value: '5', label: '5th' },
    { value: '6', label: '6th' },
    { value: '7', label: '7th' },
    { value: '8', label: '8th' },
    { value: '9', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '28', label: '28th' },
];

const hourLabel = hourChoices.reduce(toLabelLookup, {});
const weekdayLabel = cronWeekdayChoices.reduce(toLabelLookup, {});
const domLabel = cronDomChoices.reduce(toLabelLookup, {});

const createTime = (frequency: Frequency, hour: ?string, dom: ?string, dow: ?string) => {
    if (hour === '' || hour == null) {
        hour = (Math.floor(Math.random() * 25) - 1).toString();
    }
    if (dow === '*' || dow === '' || dow == null) {
        dow = '1';
    }
    if (dom === '*' || dom === '' || dom == null) {
        dom = '1';
    }

    switch(frequency) {
    case 'never':
        return '';
    case 'daily':
        return `0 ${hour} * * *`;
    case 'weekly':
        return `0 ${hour} * * ${dow}`;
    case 'monthly':
        return `0 ${hour} ${dom} * *`;
    default:
        void (frequency: empty);
        return defaultSnapshotSchedule;
    }
};

function getFrequency(dom, dow): Frequency {
    let frequency = 'never';
    if (dom === '*' && dow === '*') {
        frequency = 'daily';
    } else if (dom === '*') {
        frequency = 'weekly';
    } else if (dow === '*') {
        frequency = 'monthly';
    }

    return frequency;
}

export const DbsSnapshotHint = (): React$Node => <div>Cloud SQL snapshots of this instance will be created
    automatically according to the schedule below. <a href='https://www.brightbox.com/docs/reference/cloud-sql/#snapshots' target='blank'>Learn more</a></div>

export const ServerSnapshotHint = (): React$Node => (
    <div>Snapshots of this server will be created automatically according to the
        schedule below. <a href="https://www.brightbox.com/docs/reference/cloud-servers/#snapshots" target="blank">Learn more</a>
    </div>
);

export const SnapshotEdit = ({ editor, kind, }: SnapshotEditProps): React$Node => {
    const { value, setValue } = editor;
    const [ , h, dom, , dow] = (value || '').split(' ');
    let frequency: Frequency = getFrequency(dom, dow);

    return (
        <Panel>
            <PanelHeader title={'Automated Snapshot Schedule'} />
            <PanelBar>
                <Notice type='info'>
                    {kind === 'server' ? <ServerSnapshotHint /> : null}
                    {kind === 'database_server' ? <DbsSnapshotHint /> : null}
                </Notice>
                {!editor.status
                    ? <div><SkeletonBar size='lg'/></div>
                    : <ToggleGroup
                        selected={value != null ? frequency : null}
                        options={frequencyChoices}
                        onSelect={(f: Frequency) => setValue(createTime(f, h, dom, dow))}
                    />
                }
                {!editor.status
                    ? <div><SkeletonBar size='lg' /></div>
                    : null
                }
                 <div className='text-gray-600'>
                {frequency === 'never'
                    ? <p className='text-gray-600 mt-4'>Don't schedule any automated snapshots.</p>
                    : <>
                        <i>Create a snapshot each {periodDescriptor[frequency]} at</i>
                        <ValueSelect
                            label={<span>Time <span className='text-gray-500 font-normal'>(UTC)</span></span>}
                            selected={h}
                            options={hourChoices}
                            onSelect={(next: string) => setValue(createTime(frequency, next, dom, dow))}
                            autoFocus={true}
                            className='ml-3'
                        />
                    </>}
                {frequency === 'weekly'
                    ? <>
                        <i className='-ml-3'>every</i>
                        <ValueSelect
                            label='Weekday'
                            selected={dow}
                            options={cronWeekdayChoices}
                            onSelect={(next: string) => setValue(createTime(frequency, h, dom, next))}
                            className='ml-3'
                        />
                    </>
                    : null}
                {frequency === 'monthly'
                    ? <>
                        <i className='-ml-3'>on the</i>
                        <ValueSelect
                            label='Date'
                            selected={dom}
                            options={cronDomChoices}
                            onSelect={(next: string) => setValue(createTime(frequency, h, next, dow))}
                            className='ml-3'
                        />
                        <i className='-ml-3'> of the month</i>
                    </>
                    : null}
                </div>
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};

export const snapshotDisplay = (snapshots_schedule: ?string): string => {
    const [, h, dom, , dow] = (snapshots_schedule || '').split(' ');
    let frequency: Frequency = getFrequency(dom, dow);

    switch(frequency) {
    case 'never':
        return 'Never';
    case 'daily':
        return `Daily at ${hourLabel[h]}`;
    case 'weekly':
        return `Weekly at ${hourLabel[h]} on ${weekdayLabel[dow]}`;
    case 'monthly':
        return `Monthly at ${hourLabel[h]} on the ${domLabel[dom]}`;
    default:
        void (frequency: empty);
        return '';
    }
};