// @flow

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import Plausible from 'plausible-tracker';

const PLAUSIBLE_KEY = window?.cgrConfig?.PLAUSIBLE_KEY || 'gb1s.brightbox.com';
const PLAUSIBLE_API_HOST = window?.cgrConfig?.PLAUSIBLE_API_HOST || 'https://plausible.io';

// $FlowFixMe I don't see why this export is un-typeable, since Plausible is typed.
export const { trackEvent } = Plausible({
    domain: PLAUSIBLE_KEY,
    apiHost: PLAUSIBLE_API_HOST,
    trackLocalhost: process.env.NODE_ENV !== 'production',
});

// Record basic path-based events
export function usePlausible() {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        switch(pathname) {
        case '/signup':
            trackEvent('Signup Page Viewed'); break;
        default:
            // no analytics
            break;
        }
    }, [pathname]);
}
