// @flow


export type PageProps = {
    +children: ?React$Node,

    +onDragOver?: (e: DragEvent) => void,
    +onDragExit?: (e: DragEvent) => void,
    +onDragLeave?: (e: DragEvent) => void,
    +onDrop?: (e: DragEvent) => void,
};

export const Page = ({ children, ...rest }: PageProps): React$Node => (
    <div className='c-page' {...rest}>
        {children}
    </div>
);

export const PageHeader = ({ children }: PageProps): React$Node => (
    <div className='c-page__header'>{children}</div>
);

export const PageTitle = ({ children }: PageProps): React$Node => (
    <h1 className='c-page__title'>{children}</h1>
);