// @flow
import { useEffect, useState, useMemo, } from 'react';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useViewResource, } from '../../hoc/ViewResource';
import { ResourceCloudIpEdit, ResourceCloudIpView, useEditResourceCloudIps } from '../../common/ResourceCloudIps';
import { DbsAccessControl } from './edit/DbsAccessControl';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Dialog, useDialog, } from '../../element/Dialog';
import { useSecretDisplayDialog } from '../../hoc/Secret';
import { useResourceEditorModal } from '../../common/Editor';
import { emptyErrors } from '../../common/lib';
import { validateAccess, validateRetention } from './edit/lib';
import { useResourceLockDialogs, LockDialogs, DeleteDialog, useDeleteDialog, SnapshotCreatingDialog } from '../../common/CommonDialogs';
import { DbsMaintenanceEdit, weekdayToLabel, hourToLabel, MaintenanceHint } from './edit/DbsMaintenanceEdit';
import { SnapshotEdit, snapshotDisplay, DbsSnapshotHint } from '../../common/SnapshotEdit';
import { DbsAccessControlEdit, editAccess } from './edit/DbsAccessControlEdit';
import { NameEditPanel } from '../../element/NameEditPanel';
import { RC_FETCHING } from '../../../state/resource/type';
import { Notice } from '../../element/Notice';
import { SkeletonChip, } from '../../element/Skeleton';
import { Chip } from '../../element/Chip';
import { useDatabaseEngineTypes } from '../../hoc/Database';
import { TypeSummary } from '../../element/TypeSummary';
import { HiddenPassword } from '../../element/HiddenPassword';
import { formatUtcDateTime } from '../../element/Styled';
import { getLockSetting } from '../../element/Setting/Settings';
import { useResourcesById } from '../../hoc/ListPage';
import { SnapshotRetentionEdit, DEFAULT_RETENTIONS } from '../../common/SnapshotRetentionEdit';
import { useBuildingResource } from '../../hoc/Build';
import { SecretDisplay } from '../../element/SecretDisplay';
import { getMonthlyPrice } from '../../../lib/pricing';
import { MonthlyPrice } from '../../element/MonthlyPrice';
import { isAllocated, isDeleted } from '../../../api/lib';
import { PerGigPrice } from '../../element/PerGigPrice';
import { TextInput } from '../../element/Input';
import copy from 'copy-to-clipboard';
import { DbsMetrics } from '../../common/Metrics/DbsMetrics';
import { useDatabaseServerResize } from '../../hoc/ResizeResource';
import { DatabaseTypeEdit } from './edit/DatabaseTypeEdit';
import { useCurrentAccount } from '../../hoc/lib';
import { Button } from '../../element/Button';
import { LABELS } from '../../element/ResourceLabels';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../../lib/history';

import type { PanelHeaderActions } from '../../element/Panel';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { DialogState } from '../../element/Dialog';
import type { EditorDef } from '../../common/Editor';
import type { FormErrors } from '../../common/lib';
import type { DeleteDialogHook, LockDialogsHook } from '../../common/CommonDialogs';
import type { BbDatabaseServer, BbDatabaseServerParams, BbDatabaseSnapshot, BbDatabaseType, } from '../../../api/type.dbs';
import type { SnapshotRetentionValues } from '../../common/SnapshotRetentionEdit';
import type { DbsMaintenanceParams } from './type';
import type { MessageHook } from '../../hoc/Messages';
import type { Match } from 'react-router';
import type { BbAccount } from '../../../api/type.acc';
import type { Megabytes } from '../../../api/type.units';
import type { ServerTypeValue } from '../../hoc/ResizeResource';
import type { DbsSimpleAction } from './def';

import type { DbsAccessControlRuleEdit } from "./edit/DbsAccessControlTableRow";

type DbsViewResource = ViewResourceProps<BbDatabaseServer, BbDatabaseServerParams>;

const isActive = (status: ?string) => status === 'active';

const dbsToMaintenance = (dbs: ?BbDatabaseServer): DbsMaintenanceParams => (
    { weekday: dbs ? dbs.maintenance_weekday : 0, hour: dbs ? dbs.maintenance_hour : 0 }
)

const nameDef: EditorDef<BbDatabaseServer, string, BbDatabaseServerParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbDatabaseServer) => res.name || '',
    onValidate: null,
};
const maintenanceDef: EditorDef<BbDatabaseServer, DbsMaintenanceParams, BbDatabaseServerParams, null> = {
    emptyErrors: null,
    editUri: 'maintenance',
    onEdit: (dbs: BbDatabaseServer) => dbsToMaintenance(dbs),
    onValidate: (r: DbsViewResource, val: DbsMaintenanceParams) => {
        return [
            null,
            {
                maintenance_weekday: val.weekday,
                maintenance_hour: val.hour,
            }
        ]
    },
}
const snapshots_scheduleDef: EditorDef<BbDatabaseServer, string, BbDatabaseServerParams, null> = {
    emptyErrors: null,
    editUri: 'snapshots_schedule',
    onEdit: (dbs: BbDatabaseServer) => dbs.snapshots_schedule || '',
    onValidate: null,
}
const snapshots_retentionDef: EditorDef<BbDatabaseServer, SnapshotRetentionValues, BbDatabaseServerParams, ?string> = {
    emptyErrors: null,
    editUri: 'snapshots_retention',
    onEdit: (dbs: BbDatabaseServer) => ({
        automatic: Number(dbs.snapshots_retention) > 0,
        count: dbs.snapshots_retention || DEFAULT_RETENTIONS,
    }),
    onValidate: (resource, value) => validateRetention(value),
}
const accessDef: EditorDef<BbDatabaseServer, $ReadOnlyArray<DbsAccessControlRuleEdit>, BbDatabaseServerParams, FormErrors> = {
    emptyErrors,
    editUri: 'access_control',
    onEdit: (dbs: BbDatabaseServer) => editAccess(dbs.access_control),
    onValidate: (resource, access: $ReadOnlyArray<DbsAccessControlRuleEdit>) => validateAccess(access)
};

type DbsDialogsHook = {
    +actions: PanelHeaderActions,
    ...LockDialogsHook,
    ...DeleteDialogHook,
    +snapshotDialog: DialogState<null>,
    +snapshotDetailsDialog: DialogState<BbDatabaseSnapshot>,
    +resetPasswordDialog: DialogState<null>,
    +editable: boolean,
    +resetDialog: DialogState<null>,
}

export const useDbsDialogs = (
    dbs: ?BbDatabaseServer,
    simpleAction: DbsSimpleAction,
    deleteAction: () => void
): DbsDialogsHook => {
    const active = isActive(dbs?.status);
    const allocated = !isDeleted(dbs?.status);
    const { panelActions: lockActions, ...lockDialogs } = useResourceLockDialogs(active ? dbs?.locked : null, simpleAction);
    const [ deleteActions, deleteDialog ] = useDeleteDialog(active && !dbs?.locked, deleteAction);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const snapshotDialog = useDialog([{
        label: 'Create Snapshot',
        kind: 'primary',
        onSelect: () => {
            simpleAction('snapshot');
            setShowDetails(true);
        },
    }]);
    const snapshotDetailsDialog = useDialog([]);
    const { show } = snapshotDetailsDialog;

    const { resources: allSnapshots } = useResourcesById('database_snapshot');
    const creatingSnapshot: ?BbDatabaseSnapshot = useMemo(() => {
        const id = Object.keys(allSnapshots).find(key => allSnapshots[key].source === dbs?.id && allSnapshots[key].status === 'creating');
        if (id) return allSnapshots[id];

        return null;
    }, [allSnapshots, dbs]);

    useEffect(() => {
        if (creatingSnapshot && showDetails) {
            show(creatingSnapshot);
            setShowDetails(false);
        }
    }, [creatingSnapshot, show, showDetails, setShowDetails]);

    const resetPasswordDialog = useDialog([{
        label: 'Reset Password',
        color: 'red',
        kind: 'primary',
        onSelect: () => {
            simpleAction('reset_password');
        }
    }]);

    const resetDialog = useDialog([{
        label: 'Restart',
        color: 'blue',
        kind: 'primary',
        onSelect: () => {
            simpleAction('reset');
        }
    }]);

    return {
        actions: {
            ...deleteActions,
            snapshot: active && !creatingSnapshot
                ? snapshotDialog.show
                : null,
            ...(active ? lockActions : null),
            restart: active && !creatingSnapshot
                ? resetDialog.show
                : null,
        },

        ...lockDialogs,
        deleteDialog,
        snapshotDialog,
        snapshotDetailsDialog,
        resetPasswordDialog,
        resetDialog,
        editable: allocated,
    }
}

type DbsDialogsProps = {
    +name: string,
    +hook: DbsDialogsHook,
    +messages: MessageHook<BbDatabaseServer>,
    +serverTypeChangedDialog: DialogState<ServerTypeValue<BbDatabaseType>>,
};

const DbsDialogs = ({name, hook, messages, serverTypeChangedDialog, }: DbsDialogsProps): React$Node => {
    return (
        <>
            <LockDialogs name={name} lockDialog={hook.lockDialog} unlockDialog={hook.unlockDialog} />
            <DeleteDialog
                dialog={hook.deleteDialog}
                name={name}
            />

            <Dialog
                title='Create Database Snapshot?'
                dialog={hook.snapshotDialog}
                messages={messages}
                render={() =>
                    <>
                        <p>Please confirm you want to create a snapshot of {name}</p>
                        <PerGigPrice label={'Snapshot'} id={'orbit'}/>
                    </>
                }
            />
            <SnapshotCreatingDialog
                name={name}
                dialog={hook.snapshotDetailsDialog}
            />

            <Dialog
                title='Reset Admin Password?'
                dialog={hook.resetPasswordDialog}
                render={() =>
                    <div>
                        <p>Are you sure you want to reset the admin password for this Cloud SQL instance?</p>
                        <p>All uses of the existing password will need to be updated.</p>
                    </div>
                }
            />

            <Dialog
                title='Restart Cloud SQL instance?'
                dialog={hook.resetDialog}
                messages={messages}
                render={() =>
                    <div>
                        <p>Are you sure you want to restart this Cloud SQL instance?</p>
                    </div>
                }
            />

            <Dialog
                title='Cloud SQL Instance Resized'
                dialog={serverTypeChangedDialog}
                footerLink={<a href='https://www.brightbox.com/docs/guides/control-panel/resize-cloud-sql/' rel='noreferrer noopener' target='blank'>
                    <Button size='sm' kind='bare' preIcon='info-fill'>Resizing Cloud SQL Instances</Button></a>}
                messages={messages}
                render={() => (
                    <>
                        <p><b>{name}</b> has been resized.</p>

                        <Notice type='info' icon='info-fill'>
                            <p><b>Note</b> – increased SSD storage and vCPU
                            allocations are usable immediately but the instance
                            will need to be restarted to make full use of increased
                            RAM.</p>
                            <p>You can restart the instance now, or click "Cancel"
                            if you plan to restart later or wait until the instance
                            is automatically restarted during its next maintenance
                            window.</p>
                        </Notice>
                    </>
                )}
            />
        </>
    );
}

function useAvailableMemoryForDatabaseServerResize(dbs: ?BbDatabaseServer): Megabytes {
    const account: ?BbAccount = useCurrentAccount();

    if (!account || !dbs) return 0;

    return account.dbs_ram_limit - account.dbs_ram_used + dbs.database_server_type.ram;
}

export const DbsView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbDatabaseServer, BbDatabaseServerParams>('database_server', id);
    const cloudIp = useEditResourceCloudIps(id, match.path);
    const { item: dbs, simpleAction, deleteAction, } = resource;
    const progress = useBuildingResource(id);
    const { engineLabel } = useDatabaseEngineTypes();

    const name = useResourceEditorModal(resource, nameDef, match.path);
    const access = useResourceEditorModal(resource, accessDef, match.path);
    const maintenance = useResourceEditorModal(resource, maintenanceDef, match.path);
    const snapshots_schedule = useResourceEditorModal(resource, snapshots_scheduleDef, match.path);
    const snapshots_retention = useResourceEditorModal(resource, snapshots_retentionDef, match.path);
    const newPassword = useSecretDisplayDialog(id);
    const { canResize, editor: server_type, onValidate: onTypeValidate, serverTypeChangedDialog, } = useDatabaseServerResize(
        match.path, dbs, simpleAction,
    );
    const getRoute = useResourceRoutes();

    const availableMemory = useAvailableMemoryForDatabaseServerResize(dbs);

    const dialogs = useDbsDialogs(dbs, simpleAction, deleteAction);

    const showDetail = isActive(dbs?.status) || resource.status === RC_FETCHING;
    const active = isActive(dbs?.status);

    return (
        <ResourceAddViewRoute
            resourceName={dbs?.name || dbs?.id || ''}
            listTitle={LABELS.database_server.listTitle}
            resource={resource}
            match={match}
            dialog={
                <>
                    <Dialog
                        dialog={newPassword.dialog}
                        title='Cloud SQL Admin Password'
                        render={() =>
                            <>
                                <TextInput
                                    label='Username'
                                    value={dbs?.admin_username || ''}
                                    readOnly={true}
                                    onChange={() => void 0}
                                    autoPopulated={true}
                                    focusSelectAll={true}
                                    postText='Copy'
                                    onPostInlayClick={() => copy(dbs?.admin_username || '')}
                                    className='w-full mb-5 mr-3'
                                />
                                <SecretDisplay secret={newPassword.secret} label='Password' />
                                <Notice type='warning' icon='info'>Please store the new admin password securely before closing this dialog as it won't be viewable afterwards.</Notice>
                            </>
                    } />
                    <DbsDialogs
                        name={dbs?.name || id}
                        hook={dialogs}
                        messages={resource.apiResult}
                        serverTypeChangedDialog={serverTypeChangedDialog}
                    />
                </>
            }
            view={
                <>
                    <Panel>
                        <PanelHeader
                            cacheStatus={resource.status}
                            title={dbs?.name || dbs?.id}
                            actions={dialogs.actions}
                            editTitleLink={dialogs.editable ? name.editUri : null}
                        />
                        <PanelMultiSettingsBar
                            cacheStatus={resource.status}
                            details={{
                                id: dbs?.id,
                                status: dbs?.status,
                                progress,
                                kind: 'database_server',
                                created_at: dbs?.created_at,
                            }}
                            settings={[
                                [
                                    {
                                        name: 'Database Engine',
                                        summary: dbs
                                            ? <Chip
                                                engine={dbs.database_engine}
                                                name={engineLabel(dbs.database_engine, dbs.database_version)}
                                            />
                                            : null,
                                        skeleton: <SkeletonChip nameOnly={true} />,
                                    },
                                    {
                                        name: 'Size',
                                        summary: dbs
                                            ? <TypeSummary
                                                ram={dbs.database_server_type.ram}
                                                cpus={dbs.database_server_type.cores}
                                                disk={dbs.database_server_type.disk_size}
                                            />
                                            : null,
                                        ...(isAllocated(dbs?.status)
                                            ? {
                                                route: server_type.editUri,
                                                settingsBtn: {
                                                    kind: 'secondary',
                                                    children: 'Resize',
                                                },
                                                routeDisabledTooltip: canResize
                                                    ? null
                                                    : <div>
                                                        <p>This Cloud SQL instance does not support automatic resizing.</p>
                                                        <Link to={getRoute('support') + '?p=' + encodeURI(`I need help resizing Cloud SQL instance ${id}`)} className='inline-block ml-3'>
                                                            <Button
                                                                size='sm'
                                                                color='white'
                                                                postIcon='caret-right'
                                                            >Contact Support</Button>
                                                        </Link>
                                                    </div>
                                            }
                                            : null
                                        ),
                                    },

                                    {
                                        name: 'Cost',
                                        summary: dbs
                                            ? <MonthlyPrice price={getMonthlyPrice(dbs.database_server_type.id)} />
                                            : null
                                    },
                                ],
                                [
                                    {
                                        name: 'Admin Username',
                                        summary: dbs?.admin_username || '',
                                        copyValue: dbs?.admin_username || '',
                                    },
                                    {
                                        name: 'Admin Password',
                                        summary: <HiddenPassword/>,
                                        settingsBtn: dialogs.editable
                                            ? {
                                                onClick: () => dialogs.resetPasswordDialog.show(),
                                                children: 'Reset Password',
                                                kind: 'secondary',
                                            }
                                            : null,
                                    },
                                ],
                                [
                                    {
                                        name: 'Maintenance Window',
                                        summary: dbs
                                            ? `${weekdayToLabel[dbs.maintenance_weekday]} between ${hourToLabel[dbs.maintenance_hour]}`
                                            : null,
                                        route: dialogs.editable ? maintenance.editUri : null,
                                        hint: <MaintenanceHint/>,
                                    },
                                    {
                                        name: 'Snapshot Schedule',
                                        summary: dbs
                                            ? snapshotDisplay(dbs.snapshots_schedule) + (dbs.snapshots_schedule_next_at ? ` (next at ${formatUtcDateTime(dbs.snapshots_schedule_next_at)})` : '')
                                            : null,
                                        route: dialogs.editable ? snapshots_schedule.editUri : null,
                                        hint: <DbsSnapshotHint/>,
                                    },
                                    {
                                        name: 'Snapshot Retention',
                                        summary: dbs?.snapshots_retention != null && dialogs.editable
                                            ? 'Keep the  newest ' + Number(dbs.snapshots_retention) + ' snapshots'
                                            : 'Keep all automated snapshots',
                                        route: dialogs.editable ? snapshots_retention.editUri : null,
                                        hint: <div>The number of automated snapshots to be kept (older snapshots will be deleted)</div>,
                                    },
                                    getLockSetting(!!dbs && isAllocated(dbs.status), dbs?.locked, dialogs.unlockDialog.show, dialogs.lockDialog.show),
                                ]
                            ]}
                        />
                    </Panel>

                    <DbsMetrics id={id} />

                    <ResourceCloudIpView
                        cacheStatus={resource.status}
                        resourceId={id}
                        resourceTitle={'Cloud SQL'}
                        editUri={active || dbs?.status === 'creating' ? cloudIp.editor.editUri : null}
                    />

                    {showDetail ?
                        <DbsAccessControl
                            cacheStatus={resource.status}
                            editUri={access.editUri}
                            access={dbs?.access_control}
                        />
                        : null}

                </>
            }
            editors={[
                {
                    editor: cloudIp.editor,
                    render: () =>
                        <ResourceCloudIpEdit
                            resourceId={id}
                            resourceTitle={'Cloud SQL'}
                            edit={cloudIp}
                        />,
                },
                { editor: maintenance, component: DbsMaintenanceEdit, },
                { editor: snapshots_schedule, render: () => <SnapshotEdit editor={snapshots_schedule} kind='database_server' />, },
                { editor: snapshots_retention, component: SnapshotRetentionEdit, },
                { editor: access, render: () => <DbsAccessControlEdit editor={access} cacheStatus={resource.status} />, },
                { editor: name, render: () => <NameEditPanel editor={name} />},
                {
                    editor: server_type,
                    render: () => dbs
                        ? <DatabaseTypeEdit
                            editor={server_type}
                            availableMemory={dbs ? availableMemory : -1}
                            name={dbs.name || dbs.id || 'this Cloud SQL'}
                            id={id}
                            onValidate={onTypeValidate}
                        />
                        : null
                    ,
                },
            ]}

        />
    );
};

