// @flow
import { useEffect, useState } from 'react';
import axios from 'axios';
import to from 'await-to-js';
import { RC_INITIAL, RC_NOT_FOUND, RC_SUCCESS } from '../../state/resource/type';

type GravatarCheckStatus = typeof RC_INITIAL | typeof RC_NOT_FOUND | typeof RC_SUCCESS;

let cache = new Map<string, GravatarCheckStatus>();

export const useGravatar = (gravatar_url: ?string): GravatarCheckStatus => {
    const [check, setCheck] = useState<{ status: GravatarCheckStatus, url: string }>({
        url: gravatar_url || '',
        status: cache.get(gravatar_url || '') || RC_INITIAL,
    });

    useEffect(() => {
        let cancel = false;
        if (typeof gravatar_url === 'string' && gravatar_url !== '') {
            const cachedStatus = cache.get(gravatar_url);
            if (cachedStatus != null) {
                setCheck({ url: gravatar_url, status: cachedStatus, });
            } else {
                const go = async () => {
                    let [err,] = await to(axios({
                        url: 'https://www.gravatar.com/avatar/' + gravatar_url + '?s=42&d=404',
                        headers: {
                            accept: 'image/*',
                        }
                    }));

                    if (cancel) {
                        return;
                    }

                    if (err) {
                        setCheck({ url: gravatar_url, status: RC_NOT_FOUND, });
                    } else {
                        setCheck({ url: gravatar_url, status: RC_SUCCESS, });
                        cache.set(gravatar_url, RC_SUCCESS);
                    }
                };

                setCheck({ url: gravatar_url, status: RC_INITIAL, });
                go();

                return () => {
                    cancel = true;
                };
            }
        }
    }, [gravatar_url]);

    return check.status;
};
