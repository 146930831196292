// @flow

import { Dialog, useDialog } from '../element/Dialog';
import { Button } from '../element/Button';
import { Card } from '../element/Card';
import { ResourceChipFetch } from '../element/Chip';
import { Link } from 'react-router-dom';
import { useResourceRoutes } from '../../lib/history';
import { PerGigPrice } from '../element/PerGigPrice';

import type { ResourceActionMethod, } from '../../state/resource/type';
import type { PanelHeaderActions } from '../element/Panel';
import type { DialogState, DialogProps } from '../element/Dialog';
import type { BbLockableTypes, BbImage } from '../../api/type';
import type { BbDatabaseSnapshot } from '../../api/type.dbs';
import type { MessageHook } from '../hoc/Messages';
import type { BbServer } from '../../api/type.srv';

export type LockDialogsHook = {
    +lockDialog: DialogState<null>,
    +unlockDialog: DialogState<null>,
}

export type DeleteDialogHook = {
    +deleteDialog: DialogState<null>,
}

type LockDialogProps = {
    ...LockDialogsHook,
    +name: string,
    +count?: ?number,
}

type DeleteDialogProps<Data> = {
    ...$Diff<DialogProps<Data>, { render: $PropertyType<DialogProps<Data>, 'render'> }>,

    +name: string | (t: ?Data) => string,
    +confirmString?: string,
    render?: $PropertyType<DialogProps<Data>, 'render'>
}

export function useLockDialogs(onLock: () => void, onUnlock: () => void): LockDialogsHook {
    const lockDialog = useDialog([ { label: 'Lock', kind: 'primary', onSelect: onLock , } ]);
    const unlockDialog = useDialog([ { label: 'Unlock', kind: 'primary', onSelect: onUnlock, } ]);

    return {
        lockDialog,
        unlockDialog,
    }
}

type ResourceLockDialog = {
    ...LockDialogsHook,
    panelActions: {
        unlock: $PropertyType<PanelHeaderActions, 'unlock'>,
    },
}

export const useResourceLockDialogs = <Resource: BbLockableTypes, Params>(
    locked: ?boolean,
    simpleAction: (action: string, params?: ?$Shape<Params>, nonPristine: ?Resource, method?: ?ResourceActionMethod) => void,
): ResourceLockDialog => {
    const dialogs = useLockDialogs(
        () => simpleAction('lock_resource', null, null, 'PUT'),
        () => simpleAction('unlock_resource', null, null, 'PUT')
    );

    return {
        ...dialogs,
        panelActions: {
            unlock: locked ? dialogs.unlockDialog.show : null,
        }
    }
}

export const LockDialogs = ({name, lockDialog, unlockDialog, count: nullableCount, }: LockDialogProps): React$Node => {
    const count = nullableCount || 0;

    return (
        <>
            <Dialog
                dialog={lockDialog}
                title={() => count > 0 ? `Lock ${count} resources?` :'Lock resource'}
                render={() => <p>Are you sure you want to lock {name}?</p>}
                footerLink={<a target='_new' href="https://www.brightbox.com/blog/2014/11/14/protecting-cloud-resources/"><Button size='sm' kind='bare'>What does locking do?</Button></a>}
            />
            <Dialog
                dialog={unlockDialog}
                    title={() => count > 0 ? `Unlock ${count} resources?` :'Unlock resource'}
                render={() => <p>Are you sure you want to unlock {name}?</p>}
            />
        </>
    );
}

export const useDeleteDialog = <Data = null>(deletable: boolean, deleteAction: (t: ?Data) => void, label: string = 'Delete'): [PanelHeaderActions, DialogState<Data>] => {
    const deleteDialog = useDialog<Data>([ { label, kind: 'primary', color: 'red', onSelect: (t: ?Data) => deleteAction(t), } ]);

    let actions: PanelHeaderActions = {
        delete: null,
    };

    if (deletable) actions['delete'] = deleteDialog.show;

    return [
        actions,
        deleteDialog,
    ]
}

export function DeleteDialog<Data>({ name, dialog, title, messages, ...rest }: DeleteDialogProps<Data>): React$Node {
    return (
        <Dialog
            dialog={dialog}
            title={title || 'Are you sure you want to delete?'}
            messages={messages}
            render={() => <p>Are you sure you want to delete {typeof name === 'function' ? name(dialog.data) : name}?</p>}
            {...rest}
        />
    );
}

export function RemoveDialog<Data>({ name, dialog, title, messages }: DeleteDialogProps<Data>): React$Node {
    return (
        <Dialog
            dialog={dialog}
            title={title || 'Are you sure you want to remove?'}
            messages={messages}
            render={() => <p>Are you sure you want to remove {typeof name === 'function' ? name(dialog.data) : name}?</p>}
        />
    );
}

type AppDialogsHook = {
    actions: PanelHeaderActions,
    ...DeleteDialogHook,
    resetPasswordDialog: DialogState<null>,
}

type AppDialogsProps = {
    name: string,
    ...DeleteDialogHook,
    resetPasswordDialog: DialogState<null>,
}

export const useAppApiDialogs = (
    active: boolean,
    simpleAction: (action: string, params?: any, nonPristine: any, method?: ?ResourceActionMethod) => void,
    deleteAction: () => void
): AppDialogsHook => {
    const [deleteAppActions, deleteDialog] = useDeleteDialog(active, deleteAction);

    const resetPasswordDialog = useDialog([{
        label: 'Reset Secret',
        kind: 'primary',
        color: 'red',
        onSelect: () => {
            simpleAction('reset_secret');
        }
    }]);

    return {
        actions: deleteAppActions,
        resetPasswordDialog,
        deleteDialog
    };
};

export const AppApiDialogs = ({ name, deleteDialog, resetPasswordDialog, }: AppDialogsProps): React$Node => {
    return (
        <>
            <DeleteDialog name={name} dialog={deleteDialog}/>

            <Dialog dialog={resetPasswordDialog} title='Reset API Client Secret?' render={() =>
                <div>
                    <p>Are you sure you want to reset the secret?</p>
                    <p>All uses of the existing secret must be replaced.</p>
                </div>
            }/>
        </>
    );
};

type CreateSnapshotDialogProps<Data: BbServer | BbImage> = {
    +dialog: DialogState<null>,
    +messages: ?MessageHook<Data>,
    +name: string,
}

export const CreateSnapshotDialog = <Data: BbServer | BbImage>({ dialog, messages, name, }: CreateSnapshotDialogProps<Data>): React$Node => (
    <Dialog
        title='Create Snapshot?'
        dialog={dialog}
        messages={messages}
        render={() =>
            <>
                <p>Please confirm you want to create a snapshot of {name}</p>
                <PerGigPrice label={'Snapshot'} id={'orbit'} />
            </>
        }
    />
)

export const SnapshotCreatingDialog = <Data: BbImage | BbDatabaseSnapshot>({ dialog, }: { dialog: DialogState<Data>, name: string, }): React$Node => {
    const getRoute = useResourceRoutes();
    const link = dialog.data ? getRoute(dialog.data.resource_type, dialog.data.id) : '';

    return (
        <Dialog
            dialog={dialog}
            footerLink={
                <Link to={link}>
                    <Button size='sm' kind='secondary'>View Snapshot</Button>
                </Link>}
            title={'Snapshot started'}
            render={(data: ?(BbImage | BbDatabaseSnapshot)) =>
                <>

                {data
                    ? <Card link={link}>
                        <ResourceChipFetch id={data.id} fallback={data} />
                    </Card>
                    : null
                }
            </>
        } />
    )
}

