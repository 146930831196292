// @flow

import { RC_INITIAL } from '../resource/type';

import type { GuiState, GuiDatabaseEngineAction } from './type';
import type { ResourceAddCollected, ResourceSetCollected, ResourceAddFull } from '../resource/type';
import type { AuthSetSelectedAccount } from '../auth/type';
import type { StoreClearAction } from '../type';
import type { BbCollectedResourceTypes, BbAllResourceTypes } from '../../api/type';
import type { GuiClientIpAction } from './type';

const emptyState: GuiState = {
    showCipGroupMap: false,
    clientIp: {
        cacheStatus: RC_INITIAL,
        clientIp: null,
    },
    databaseEngines: {
        cacheStatus: RC_INITIAL,
        engineTypes: null,
        defaultVersion: null,
    }
};

type GuiReducerAction<F: BbAllResourceTypes, C: BbCollectedResourceTypes> =
    ResourceAddCollected<C> | ResourceSetCollected<C> | ResourceAddFull<F, C> |
    AuthSetSelectedAccount | StoreClearAction |
    GuiClientIpAction | GuiDatabaseEngineAction
;

export function GuiReducer<F: BbAllResourceTypes, C: BbCollectedResourceTypes>(
    state: GuiState = emptyState,
    action: GuiReducerAction<F, C>
): GuiState {
    switch(action.type) {
    case 'STORE_CLEAR':
    case 'AUTH_SELECT_ACCOUNT':
        return emptyState;
    case 'RESOURCE_ADD_COLLECTED':
    case 'RESOURCE_SET_COLLECTED':
        const showC = action.payload.kind === 'cloud_ip'
            // $FlowFixMe these must be IPs
            && !!action.payload.resources.find(ip => ip.server_group != null)

        return {
            ...state,
            showCipGroupMap: state.showCipGroupMap || showC
        };
    case 'RESOURCE_ADD_FULL':
        const showF = action.payload.full.resource_type === 'cloud_ip'
            // $FlowFixMe these won't be nested cloud IPs
            && action.payload.full.server_group != null;

        return {
            ...state,
            showCipGroupMap: state.showCipGroupMap || showF
        };
    case 'GUI_CLIENT_IP':
        return {
            ...state,
            clientIp: action.payload,
        }
    case 'GUI_DATABASE_ENGINE':
        return {
            ...state,
            databaseEngines: action.payload,
        }
    default:
        void (action: empty);
        break;
    }

    return state;
}