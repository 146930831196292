// @flow
import { useEffect, useMemo } from 'react';
import { DatabaseLimit } from '../../../element/LimitTooltip';
import { useListResource } from '../../../hoc/ListPage';
import { Panel, PanelBar, PanelHeader } from '../../../element/Panel';
import { Card } from '../../../element/Card';
import { CardGroup } from '../../../element/CardGroup';
import { TypeCardContent } from '../../../element/TypeCardContent';
import { getMonthlyPrice } from '../../../../lib/pricing';
import { Tooltip } from '../../../element/Tooltip';
import { isShowDetailsResourceStatus, SkeletonTypeCardPanel } from '../../../element/Skeleton';
import { Notice } from '../../../element/Notice';

import type { BbDatabaseType, } from '../../../../api/type.dbs';
import type { EditorDirect } from '../../../common/Editor';
import type { ListSortDef } from '../../../hoc/ListPage';

export type DatabaseTypeSelectOwnProps = {
    +editor: EditorDirect<?BbDatabaseType, null>,
    +availableMemory: number,
    +current?: ?BbDatabaseType,
};

type LimitWrapperProps = {
    +ram: number,
    +availableMemory: number,
    +children: React$Node
}

export const DatabaseServerLimitWrapper = ({ ram, availableMemory, children }: LimitWrapperProps): React$Node => {
    if (availableMemory < ram) {
        return (
            <Tooltip overlay={<DatabaseLimit selectedType={true} />}>
                {children}
            </Tooltip>
        );
    }

    return children;
}

// Used in the 'DBS Create' page, so it has a Panel wrapper
export const DatabaseTypePanel = (props: DatabaseTypeSelectOwnProps): React$Node => {
    return (
        <Panel>
            <PanelHeader title={'Choose a size'} />
            <DatabaseTypeSelector {...props} />
        </Panel>
    );
}

// Use by DatabaseTypePanel above, or in the DatabaseTypeEdit, so there's no panel wrapper around the core bits.
export const DatabaseTypeSelector = ({ editor, availableMemory, current, }: DatabaseTypeSelectOwnProps): React$Node => {
    const { value: selected, setValue: onSelect } = editor;
    const list = useListResource<BbDatabaseType>('database_type', typeSort);
    const { items, status } = list;

    // when the editor has no value, and the DB types are loaded, and the available memory is calculated
    // (via the account limits and the potential current type usage) then this auto selects
    // the best choice. it doesn't choose a type if 'current', eg when resizing a database server
    useEffect(() => {
        if (selected == null && Array.isArray(items) && items.length > 0 && availableMemory > -1 && current == null) {
            let serverType: ?BbDatabaseType = items.find(x => x.default);
            if (serverType != null && serverType.ram > availableMemory) {
                serverType = items.reduce((selected, x) => (
                    x.ram <= availableMemory                          // account limit passes
                    && (
                        selected === null                             // always choose something
                        || selected.ram > x.ram                       // prefer smaller types
                    )
                        ? x
                        : selected
                ), null);
            }
            if (serverType != null) onSelect(serverType);
        }
    }, [ selected, items, onSelect, availableMemory, current ]);

    const atAccountLimit = useMemo(() => {
        return isShowDetailsResourceStatus(status) && availableMemory !== -1 && items.findIndex(x => x.ram <= availableMemory) === -1;
    }, [availableMemory, status, items]);

    return (
        <>
            {atAccountLimit
                ? <PanelBar border={false}>
                    <Notice type='warning'>
                        <DatabaseLimit />
                    </Notice>
                </PanelBar>
                : null
            }
            <PanelBar>
                <CardGroup>
                    {(availableMemory > -1 ? items : []).map((t: BbDatabaseType) => {
                        const isCurrent = current?.id === t.id;
                        const isDisabled = availableMemory < t.ram || isCurrent || (current != null && current.ram >= t.ram);

                        return (
                            <DatabaseServerLimitWrapper ram={t.ram} availableMemory={availableMemory} key={t.id}>
                                <Card
                                    selectable={!isCurrent && (current == null || current.ram < t.ram)}
                                    selected={selected === t && !isCurrent}
                                    hasError={selected === t && availableMemory === 0}
                                    onClick={isCurrent || isDisabled ? null : () => onSelect(t)}
                                    disabled={isDisabled}
                                    current={current?.id === t.id}
                                >
                                    <TypeCardContent
                                        id={t.id}
                                        ram={t.ram}
                                        cpus={t.cores}
                                        disk={t.disk_size}
                                        cost={getMonthlyPrice(t.id)}
                                        resourceTitle='Cloud SQL Instances'
                                    />
                                </Card>
                            </DatabaseServerLimitWrapper>
                        );
                    }
                )}
                {!isShowDetailsResourceStatus(status) || availableMemory === -1
                    ? <SkeletonTypeCardPanel count={5} />
                    : null
                }
                </CardGroup>
            </PanelBar>
        </>
    );
};

export const typeSort: ListSortDef<BbDatabaseType> = {
    name: 'database_type_select',
    fields: {
        _default: (a: BbDatabaseType, b: BbDatabaseType): number => a.ram - b.ram,
    }
};
