// @flow
import { useState, useEffect, useContext, } from 'react';
import { Textarea } from '../../element/Textarea';
import { useAuthedMutation } from '../../hoc/graphql';
import gql from 'graphql-tag';
import { apiClient } from '../../../api/graphql';
import { useCurrentAccountId, useCurrentAccount } from '../../hoc/lib';
import { useOrbitAccountMeta } from '../../hoc/Orbit';
import { Panel, PanelHeader, PanelBar, PanelButtonBar } from '../../element/Panel';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { historyBack, } from '../../../lib/history';
import { toast } from 'react-toastify';
import { formatGbp } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { Notice } from '../../element/Notice';
import { accountBillingContext } from '../../hoc/Billing';
import { AccountResourceUsage } from '../home/AccountResourceUsage';

import type { Match } from 'react-router';
import { PayLink } from './AccountStatusBanner';

const SEND_BILLING_QUERY_MUTATION = gql`
mutation sbm($accountId: ID!, $message: String!) {
    sendBillingMessage(input:{
        accountId: $accountId,
        message: $message
    }) {
        message
    }
}`;

function useSupportQuery() {
    const accountId = useCurrentAccountId();
    const [error, setError] = useState<?string>('');
    const [sendBillingMessage, { loading, called, error: mutError }] = useAuthedMutation(
        SEND_BILLING_QUERY_MUTATION,
        {
            client: apiClient,
            // we are handling these with a toast, so just catch and ignore the promise error.
            onError: () => void 0,
        }
    );

    const submit = (message: string) => {
        setError('');

        if (message.length > 10) {
            sendBillingMessage({
                variables: {
                    accountId,
                    message,

                }
            });
        } else {
            setError('Please enter a support request');
        }
    };

    useEffect(() => {
        if (called && !loading && !mutError) {
            toast('Support request submitted.', { type: 'success' });
            historyBack();
        }
    }, [called, mutError, loading]);

    useEffect(() => {
        if (error || mutError) {
            const message = error || (mutError != null ? String(mutError) : null) || '';
            if (message !== '') toast(message, { type: 'error' });
        }
    }, [error, mutError]);

    return {
        loading,
        submit,
        error,
        setError,
    };
}

export const SupportQuery = ({ match }: { match: Match }): React$Node => {
    const { loading, submit, error, setError } = useSupportQuery();
    const [message, setMessage] = useState(() => {
        const searchMatch = new URLSearchParams(window.location.search);
        const preset = searchMatch.get('p');
        return preset || '';
    });

    const trySubmit = () => {
        if (message.length > 10) {
            submit(message);
        } else {
            setError('Please enter a support request');
        }
    }

    return (
        <ResourceAddViewRoute
            match={match}
            dialog={null}
            view={
                <Panel>
                    <PanelHeader
                        title='Support Request'
                    />
                    <PanelBar>

                        <p>If you are experiencing problems please contact our support team using the form below.</p>

                        <p>Please provide as much information as possible to help us identify the root cause.</p>

                        <Textarea
                            label='How can we help?'
                            rows={10}
                            className='w-full'
                            value={message}
                            onChange={(msg) => setMessage(msg)}
                            errorText={error}
                        />


                    </PanelBar>

                    <PanelButtonBar
                        primaryButton={{
                            onClick: loading ? null : trySubmit,
                            children:'Send Request',
                        }}
                        cancel={historyBack}
                    />
                </Panel>
            }
        />
    );
};

export const CloseAccountRequest = ({ match }: { match: Match }): React$Node => {
    const { loading, submit, error, } = useSupportQuery();
    const [message, setMessage] = useState('');
    const [, billingResult] = useContext(accountBillingContext);

    const account = useCurrentAccount();
    const accountOrbit = useOrbitAccountMeta();

    if (!account) return null;

    const hasResources = (account.servers_used > 0) || (account.dbs_instances_used > 0) || (account.load_balancers_used > 0) || (account.cloud_ips_used > 0);
    const hasOrbitUsage = (accountOrbit?.bytes || 0) > 0;

    const balance = (billingResult?.data && Number(billingResult?.data?.account?.currentBalance) > 0)
        ? <Notice type='warning' icon='warning-fill'>
            <b>Please note</b> – we can't currently close your account as there is currently an outstanding balance of £{formatGbp(billingResult?.data?.account?.currentBalance)}
            <PayLink account={account} color='dark-grey' amount={billingResult?.data?.account?.currentBalance} />
        </Notice>
        : null

    const doSubmit = () => {
        let supportMessage = `Account Closure Request for ${account.id}\n\n`;
        supportMessage += 'Cancellation reason:\n';
        if (message !== '') {
            supportMessage += message;
        } else {
            supportMessage += 'None provided';
        }
        supportMessage += `\n\n##hs_customer_id:${account.id}##`
        supportMessage += `\n\n##hs_category:34##`

        submit(supportMessage);
    }

    return (
        <ResourceAddViewRoute
            match={match}
            dialog={null}
            view={
                <Panel>
                    <PanelHeader
                        title='Close Account'
                    />
                    <PanelBar>
                        <p className='mb-8 max-w-2xl'>
                            We're sorry that you're thinking of leaving! – if you're experiencing a particular problem using Brightbox
                            please do <Link to={`/accounts/${account.id}/support/`}><b>let us know</b></Link> before
                            closing your account, we'd love the opportunity to help you get back on track 🙂
                        </p>

                        {balance}

                        {hasResources || hasOrbitUsage
                            ? <>
                                <Notice type='warning' icon='info'>
                                    <b>Please note</b> – we can't currently close your account as you have
                                    the following existing resources which need to be deleted first
                                </Notice>
                                <div className='ml-6 max-w-2xl'>
                                    <AccountResourceUsage onlyWithUsage={true} />
                                </div>
                            </>
                            : null
                        }

                        {!hasResources && !balance
                            ? <div className='max-w-2xl'>
                                <h4 className='mb-2'>Still want to leave?</h4>
                                <p className='mb-4'>Thanks so much for being a Brightbox customer!</p>
                                <p className='mb-8'>We'd love to hear any feedback or comments that you'd like to 
                                give us – anything you wish we provided that we don't currently, or anything
                                we do or have done you wished we hadn't?</p>
                                <Textarea
                                    label='Feedback or Comments?'
                                    optional={true}
                                    rows={4}
                                    value={message}
                                    onChange={(msg) => setMessage(msg)}
                                    errorText={error}
                                    className='max-w-lg'
                                />
                            </div>
                            : null
                        }

                    </PanelBar>

                    <PanelButtonBar
                        primaryButton={{
                            onClick: loading || hasResources || balance ? null : doSubmit,
                            color: 'red',
                            children: 'Close Account',
                            disabled: loading || hasResources || balance != null,
                        }}
                        cancel={historyBack}
                    />
                </Panel>
            }
        />
    );
};
