// @flow

import React from "react";
import { historyBack } from '../../lib/history';
import { Button } from './Button';

type BackLinkProps = {
    children: React$Node,
};


export const BackLink = ({children}: BackLinkProps): React$Node => (
    <div className='c-back-link'>
        <Button onClick={historyBack} kind='tertiary' color='grey' preIcon='arrow-left'>
            {children}
        </Button>
    </div>
)