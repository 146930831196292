// @flow
import { useState, useEffect, } from 'react';
import { useViewResource, } from '../../hoc/ViewResource';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { Panel, PanelHeader, PanelMultiSettingsBar } from '../../element/Panel';
import { useResourceEditorModal } from '../../common/Editor';
import { ImageArchEdit, ImageCompatibilityEdit, ImagePublicPrivateEdit, ImageStatusEdit, isEditableImageStatus } from './edit/ImageAdvanced';
import { useCurrentAccountId } from '../../hoc/lib';
import { NameEditPanel } from '../../element/NameEditPanel';
import { LockDialogs, useResourceLockDialogs, useDeleteDialog, DeleteDialog } from '../../common/CommonDialogs';
import { history } from '../../../lib/history';
import { wholeSize, mapSourceType } from '../../element/Styled';
import { ResourceChipFetch } from '../../element/Chip';
import { getLockSetting } from '../../element/Setting/Settings';
import { TextSetting } from '../../element/Setting/TextSetting';
import { useBuildingResource } from '../../hoc/Build';
import { isAllocated } from '../../../api/lib';

import type { BbImage, BbImageParams, BbImageStatus, } from '../../../api/type';
import type { Match } from 'react-router-dom';
import type { EditorDef } from '../../common/Editor';
import type { LockDialogsHook } from '../../common/CommonDialogs';
import type { DialogState } from '../../element/Dialog';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { PanelHeaderActions } from '../../element/Panel';
import type { SettingsItemProps } from '../../element/Setting/Settings';

const nameDef: EditorDef<BbImage, string, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'name',
    onEdit: (res: BbImage) => res.name || '',
    onValidate: null,
};
const descriptionDef: EditorDef<BbImage, string, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'description',
    onEdit: (res: BbImage) => res.description || '',
    onValidate: null,
};
const publicDef: EditorDef<BbImage, boolean, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'public',
    onEdit: (image: BbImage) => image.public,
    onValidate: null,
}
const archDef: EditorDef<BbImage, string, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'arch',
    onEdit: (image: BbImage) => image.arch,
    onValidate: null,
}
const compatibility_modeDef: EditorDef<BbImage, boolean, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'compatibility_mode',
    onEdit: (image: BbImage) => image.compatibility_mode,
    onValidate: null,
}
const statusDef: EditorDef<BbImage, BbImageStatus, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'status',
    onEdit: (image: BbImage) => image.status,
    onValidate: null,
}
const usernameDef: EditorDef<BbImage, string, BbImageParams, null> = {
    emptyErrors: null,
    editUri: 'username',
    onEdit: (image: BbImage) => image.username,
    onValidate: null,
}


type ImageDialogsHook = { editable: false, actions: null, }
    | {
    editable: boolean,
    actions: PanelHeaderActions,
    dialogs: {
        lockDialog: DialogState<null>,
        unlockDialog: DialogState<null>,
        deleteDialog: DialogState<null>,
    },
};

function useImageDialogs(owner: boolean, resource: ViewResourceProps<BbImage, BbImageParams>): ImageDialogsHook {
    const { item: cloudIp } = resource;

    const editable = isEditableImageStatus(cloudIp?.status || 'available') && owner;

    const { panelActions: lockActions, ...lockDialogs } = useResourceLockDialogs(editable ? cloudIp?.locked : null, resource.simpleAction);
    const [deleteActions, deleteDialog] = useDeleteDialog(
        cloudIp != null && cloudIp.status !== 'deleted' && cloudIp.status !== 'creating' && !cloudIp.locked,
        resource.deleteAction
    );

    if (!owner) {
        return { editable: false, actions: null };
    }

    return {
        editable,
        actions: {
            ...deleteActions,
            ...(editable ? lockActions : null),
        },
        dialogs: {
            ...lockDialogs,
            deleteDialog,
        },
    };
}

const ImageDialogs = ({ name, deleteDialog, ...dialogs }: { name: string, ...LockDialogsHook, deleteDialog: DialogState<null> }): React$Node => {
    return (
        <>
            <LockDialogs name={name} {...dialogs} />
            <DeleteDialog dialog={deleteDialog} name={name} />
        </>
    )
}

function useImageLinkTitle(image: ?BbImage, owner: boolean): string {
    const [returnAnchor, ] = useState(history.location.state?.tab);
    const { replace } = history;
    const { pathname } = history.location;

    useEffect(() => {
        if (image != null && returnAnchor == null) {
            replace(pathname, { tab: owner ? 'mine' : 'official' });
        }
    }, [image, returnAnchor, owner, replace, pathname]);

    const stateTab = history.location.state?.tab;

    useEffect(() => {
        if (stateTab == null && returnAnchor != null) {
            replace(pathname, { tab: stateTab });
        }
    }, [returnAnchor, stateTab, replace, pathname]);

    // these cases must match the hashMap in ImageList.
    switch(history.location.state?.tab){
    case 'mine':
        return 'My Images';
    case 'official':
        return 'Official Images';
    case 'all':
    default:
        return 'All Images';
    }
}

export const ImageView = ({ id, match }: { id: string, match: Match }): React$Node => {
    const resource = useViewResource<BbImage, BbImageParams>('image', id);
    const { item: image, } = resource;
    const currentAccountId = useCurrentAccountId();
    const owner = image?.owner === currentAccountId;
    const listTitle = useImageLinkTitle(image, owner);
    const progress = useBuildingResource(id);

    const dialogs = useImageDialogs(owner, resource);
    const { editable, actions } = dialogs;

    const name = useResourceEditorModal(resource, nameDef, match.path);
    const publicEditor = useResourceEditorModal(resource, publicDef, match.path);
    const arch = useResourceEditorModal(resource, archDef, match.path);
    const username = useResourceEditorModal(resource, usernameDef, match.path);
    const compatibility_mode = useResourceEditorModal(resource, compatibility_modeDef, match.path);
    const status = useResourceEditorModal(resource, statusDef, match.path);
    const description = useResourceEditorModal(resource, descriptionDef, match.path);
    const create_from_image = image?.status === 'available' ? `/accounts/${currentAccountId || ''}/servers/add/?image=${image?.id}` : null;

    let settings: $ReadOnlyArray<Array<SettingsItemProps>> = [
        [
            {
                name: 'Image Size',
                summary: image ? wholeSize(image.disk_size) : null,
            },
            {
                name: 'Image Type',
                summary: image ? mapSourceType(image.source_type, image.source_trigger) : null,
            },
        ],
        [
            {
                name: 'Default Username',
                summary: image ? (image?.username || '(not set)') : null,
                route: dialogs.editable ? username.editUri : null,
                hint: 'The default user which can be used to access Cloud Servers created from this image via SSH'
            },
            {
                name: 'Architecture',
                summary: image?.arch,
                route: dialogs.editable ? arch.editUri : null,
            },
            {
                name: 'Compatibility Mode',
                summary: image ? (image.compatibility_mode ? 'compatibility' : 'virtio') : null,
                route: dialogs.editable ? compatibility_mode.editUri : null,
            },
            {
                name: 'Visibility',
                summary: image ? (image.public ? 'Public' : 'Private') : null,
                route: dialogs.editable ? publicEditor.editUri : null,
            },
            {
                name: 'Description',
                summary: image ? (image.description || '-'): null,
                route: dialogs.editable ? description.editUri : null,
            },
        ]
    ];
    if (image && image.source && image.source_type === 'snapshot') {
        settings[0].push({
            name: 'Snapshot Source',
            summary: <ResourceChipFetch id={image.source} />,
            hint: 'The Cloud Server from which this snapshot was taken'
        });
    }
    if (dialogs.editable) {
        settings[1].push(getLockSetting(!!image && isAllocated(image.status), image?.locked, dialogs.dialogs.unlockDialog.show, dialogs.dialogs.lockDialog.show));
    }

    return (
        <ResourceAddViewRoute
            listTitle={listTitle}
            resourceName={image?.name || image?.id || ''}
            resource={resource}
            match={match}
            dialog={dialogs.editable ? <ImageDialogs name={image?.name || id} {...dialogs.dialogs} /> : null}
            view={<>
                <Panel>
                    <PanelHeader
                        cacheStatus={resource.status}
                        title={image?.name || image?.id}
                        editTitleLink={editable ? name.editUri : null}
                        actions={{
                            ...actions,
                            create_from_image
                        }}
                    />
                    <PanelMultiSettingsBar
                        cacheStatus={resource.status}
                        details={{
                            id: image?.id,
                            status: image?.status,
                            progress,
                            created_at: image?.created_at,
                        }}
                        settings={settings}
                    />
                </Panel>


            </>
            }
            editors={[
                { editor: publicEditor, component: ImagePublicPrivateEdit, },
                { editor: arch, component: ImageArchEdit, },
                { editor: username, render: () => <TextSetting editor={username} title='Username' /> , },
                { editor: compatibility_mode, component: ImageCompatibilityEdit, },
                { editor: status, component: ImageStatusEdit, },
                { editor: name, render: () => <NameEditPanel editor={name} />},
                { editor: description, render: () => <TextSetting editor={description} title='Description' label='Image Description' textarea={true} /> , },
            ]}
        />
    );
};
