// @flow

import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { cn } from './lib/classNames';
import { SvgIcon } from './SvgIcon';
import { Spinner } from './Spinner';

import type { Icon } from '../../assets/icons';
import Tooltip from 'rc-tooltip';

type CopyLinkProps = {
    children?: React$Node,
    value: ?string,
}

export type ButtonProps = {
    children?: ?React$Node,
    type?: 'button' | 'submit',
    kind?: string,
    size?: string,
    color?: ?string,
    disabled?: boolean,
    onClick?: ?(e: SyntheticUIEvent<HTMLButtonElement>) => boolean | void,
    preIcon?: Icon,
    postIcon?: Icon,
    state?: ?string,
    className?: string,
    autoFocus?: ?boolean,
    +hoverVisible?: ?boolean,
}

type CopyButtonProps = {
    ...ButtonProps,
    +value: ?string,
}

export const Button = ({ children, disabled, size, type, kind, color, preIcon, postIcon, state, className: extraClassName, onClick, hoverVisible, ...rest }: ButtonProps): React$Node => {


    let classes = {
        'c-button': true,
        'c-button--red': (color === 'red'),
        'c-button--green': (color === 'green'),
        'c-button--grey': (color === 'grey'),
        'c-button--dark-grey': (color === 'dark-grey'),
        'c-button--white': (color === 'white'),
        'c-button--primary': (kind === 'primary'),
        'c-button--secondary': (kind === 'secondary'),
        'c-button--tertiary': (kind === 'tertiary'),
        'c-button--bare': (kind === 'bare'),
        'c-button--loading': (state === 'loading'),
        'c-button--disabled': disabled,
        'c-button--small': (size === 'sm'),
        'c-button--xs': (size === 'xs'),
        'c-button--icon-only': !children,
        'c-button--hover-visible': hoverVisible === true,
    }

    const className = cn(classes) + ' ' + (extraClassName || '');

    return (
        <button
            type={type || 'button'} disabled={state === 'loading'}
            className={className}
            onClick={disabled ? null : onClick}
            {...rest}
        >
            {preIcon
                ? <SvgIcon className='c-button__icon c-button__icon--pre' svg={preIcon} />
                : null
            }
            <span className='c-button__text'>{children}</span>
            {postIcon
                ? <SvgIcon className='c-button__icon c-button__icon--post' svg={postIcon} />
                : null
            }
            {state === 'loading'
                ? <Spinner size='sm' className='c-button__spinner' />
                : null
            }
        </button>
    )
}


export const AddButton = (props: ButtonProps): React$Node => {
    return (
        <Button
            kind='bare'
            size='small'
            color='blue'
            preIcon='add-fill'
            {...props}
        />
    )
}

export const RemoveButton = (props: ButtonProps): React$Node => {
    const icon: Icon = (props.disabled ? 'locked' : 'trash');

    return (
        <Button
            preIcon={icon}
            kind='tertiary'
            size='sm'
            color='grey'
            {...props}
        />
    )
}

export const CopyButton = ({ children, className, value, ...rest }: CopyButtonProps): React$Node => (
    <CopyLink value={value}>
        <Button size='sm' kind='tertiary' {...rest} className={className}>{children || 'Copy'}</Button>
    </CopyLink>
);

export const CopyLink = ({ children, value, ...rest }: CopyLinkProps): React$Node => {
    const [clicked, setClicked] = useState<boolean>(false);

    const onClick = (event: SyntheticUIEvent<any>) => {
        copy(value || '');
        setClicked(true);
        // Hide the "clicked" indicator after 5 seconds, because when this is used in a tooltip,
        // the tooltip is only re-created by React if you change page etc.
        setTimeout(() => setClicked(false), 5000);
        event.preventDefault();
        event.stopPropagation();
    }

    return (
    <Tooltip overlay={clicked ? <>Copied</> : 'Copy to clipboard'} placement='top'>
        <a className={'c-copy-link' + (clicked ? ' c-copy-link--clicked' : '')} onClick={onClick} {...rest}>
            {children || <SvgIcon svg='copy' className='c-copy-link__icon'/>}
        </a>
        </Tooltip>
    );
}

export const TooltipButton = (props: ButtonProps): React$Node => {
    return (
        <Button
            kind='secondary'
            size='xs'
            color='white'
            className='my-2'
            {...props}
        />
    )
}
