// @flow
import gql from 'graphql-tag';
import numeral from 'numeral';
import { mapSeries, ticksFromRange, RANGE_QUERY } from '../../hoc/Metrics';
import { MetricsPanel } from './MetricsPanel';
import { cpuPercentConfig, interfaceRatesConfig } from './lib';

import type { ChartInputs, ServerVolAggByteMetrics, ServerVolAggIoMetrics } from './types';

type ServerMetricsProps = {
    id: string,
}

const CPU_PERCENT = cpuPercentConfig('server');
const NETWORK = interfaceRatesConfig('server', 'Cloud Server');
const VOL_AGG_BYTES = {
    title: 'Disk IO',
    measurement: 'Bytes',
    tooltip: 'Total aggregated read and write throughput performed across all attached storage, expressed in bytes per second',
    colorOffset: 3,

    yAxisFormatter: (v) => numeral(v).format('0.[00] b'),

    query: {
        query: gql`
            query serverVolBytesStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    server(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        volumes {
                            readBytesRate {
                                timestamps
                                values
                            }
                            writeBytesRate {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: ServerVolAggByteMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.server.range,
                ['s0', raw.metrics.server.volumes.readBytesRate],
                ['s1', raw.metrics.server.volumes.writeBytesRate],
            );

            return {
                data: [
                    [`Read`, `Write`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },

    }
};
const VOL_AGG_IO = {
    title: 'Disk IO',
    measurement: 'IOPS',
    tooltip: 'Total aggregated read and write operations performed across all attached storage, expressed in IOPS (input/output operations per second)',
    colorOffset: 5,

    yAxisFormatter: (v) => numeral(v).format('0.[00]'),

    query: {
        query: gql`
            query serverVolIopsStats($resourceId: ID!, $range: MetricRangeType) {
                metrics {
                    server(id: $resourceId, range: $range) {
                        ${RANGE_QUERY}
                        volumes {
                            readIoRate {
                                timestamps
                                values
                            }
                            writeIoRate {
                                timestamps
                                values
                            }
                        }
                    }
                }
            }
        `,
        adapter: (id: string, raw: ServerVolAggIoMetrics, rangeFilter: string): ChartInputs => {
            const [chartData, range] = mapSeries(
                raw.metrics.server.range,
                ['s0', raw.metrics.server.volumes.readIoRate],
                ['s1', raw.metrics.server.volumes.writeIoRate],
            );

            return {
                data: [
                    [`Read`, `Write`],
                    chartData,
                ],
                range,
                ticks: ticksFromRange(rangeFilter, range),
            };
        },
    }
};

const CHARTS = [CPU_PERCENT, NETWORK, VOL_AGG_BYTES, VOL_AGG_IO]

// const CHARTS = {
//     'cpu_seconds': {
//         query: gql`
//             query serverCpuSecondsStats($resourceId: ID!, $range: MetricRangeType) {
//                 metrics {
//                     server(id: $resourceId, range: $range) {
//                         ${RANGE_QUERY}
//                         cpu {
//                             rate {
//                                 timestamps
//                                 values
//                             }
//                         }
//                     }
//                 }
//             }
//         `,
//         adapter: (id: string, raw: CpuSecondsMetric, rangeFilter: string): ChartInputs => {
//             const [chartData, range] = mapSeries(
//                 raw.metrics.server.range,
//                 ['s0', raw.metrics.server.cpu.rate]
//             );
//
//             return {
//                 data: [
//                     [`CPU Time (seconds)`],
//                     chartData,
//                 ],
//                 range,
//                 ticks: ticksFromRange(rangeFilter, range),
//             };
//         },
//         yAxisFormatter: (v) => numeral(v).format('0.[000]'),
//
//     },
// };

export const ServerMetrics = ({ id }: ServerMetricsProps): React$Node => {
    return (
        <MetricsPanel id={id} charts={CHARTS} />
    );
};