// @flow

import {Fragment} from 'react';
import { RadioGroupSetting } from '../../../element/Setting/RadioGroupSetting';
import { SvgIcon } from '../../../element/SvgIcon';

import type { EditorModal } from '../../../common/Editor';
import type { BbImage, BbImageStatus, } from '../../../../api/type';
import type { ValueSelectChoice } from '../../../element/ValueSelect';

export const isEditableImageStatus = (status: BbImageStatus): boolean => status === 'available' || status === 'deprecated';

export const archOptions: $ReadOnlyArray<ValueSelectChoice<string>> = [
    { label: 'x32', value: 'i686', },
    { label: 'x64', value: 'x86_64', },
];

export const publicPrivateBooleanOptions: $ReadOnlyArray<ValueSelectChoice<boolean>> = [
    {   label: 'Private',
        value: false,
        description: 'The image will only available for use by this account' },
    {
        label: 'Public',
        value: true,
    description: <><SvgIcon svg='warning'/> This image and any data within it will be available to all Brightbox users</>,
    },
];

export const compatibilityOptions: $ReadOnlyArray<ValueSelectChoice<boolean>> = [
    { label: 'virtio', value: true, },
    { label: 'compatibility', value: false, },
];

const statusOptions: $ReadOnlyArray<ValueSelectChoice<BbImageStatus>> = [
    { label: 'Available', value: 'available', },
    { label: 'Deprecated', value: 'deprecated', },
];

export const ImagePublicPrivateEdit = ({ editor }: { +editor: EditorModal<boolean, null, BbImage> }): React$Node => (
    <RadioGroupSetting
        title='Image Visibility'
        choices={publicPrivateBooleanOptions}
        editor={editor}
    />
);

export const ImageArchEdit = ({ editor }: { +editor: EditorModal<string, null, BbImage> }): React$Node => (
    <RadioGroupSetting
        title='Image Architecture'
        choices={archOptions}
        editor={editor}
    />
);

export const ImageCompatibilityEdit = ({ editor }: { +editor: EditorModal<boolean, null, BbImage> }): React$Node => (
    <RadioGroupSetting
        title='Compatibility'
        choices={compatibilityOptions}
        editor={editor}
    />
);

export const ImageStatusEdit = ({ editor }: { +editor: EditorModal<BbImageStatus, null, BbImage> }): React$Node => (
    <RadioGroupSetting
        title='Status'
        choices={statusOptions}
        editor={editor}
    />
);