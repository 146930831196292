// @flow

import type { BbAllResourceTypes, BbResourceKind } from '../../api/type';

export type ResourceAndKind = [ BbResourceKind, ?BbAllResourceTypes ];

export const checkResourceAccess = (accountId: string, kind: BbResourceKind, data: Object): boolean => {
    switch(kind) {
    case 'account':
    case 'application':
    case 'database_type':
    case 'firewall_rule':
    case 'interface':
    case 'server_type':
    case 'user':
    case 'volume':
    case 'zone':
        // if the API returned these, we can access them.
        return true;
    case 'image':
        return data.public || data.owner === accountId;

    case 'api_client':
    case 'cloud_ip':
    case 'collaboration':
    case 'database_server':
    case 'database_snapshot':
    case 'event':
    case 'firewall_policy':
    case 'load_balancer':
    case 'server':
    case 'server_group':
    case 'test':
    case 'test_unmapped':
        return accountId === data.account.id

    default:
        void (kind: empty);
    }

    // we should never get here.
    return true;
};

let fetchId = 1;

export const nextFetchId = (): number => fetchId++;
