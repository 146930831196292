// @flow

import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../Panel';
import { RadioGroup } from '../RadioGroup';

import type { ValueSelectChoice } from '../ValueSelect';
import type { EditorModal } from '../../common/Editor';
import type { BbZone } from '../../../api/type';

type RadioGroupSettingProps<V, E> = {
    title: string,
    description?: string,
    choices: $ReadOnlyArray<ValueSelectChoice<V>>,
    editor: EditorModal<V, E, any>,
    children?: ?React$Node,
}

export const RadioGroupSetting = <V: string | number | boolean | BbZone, E>({ title, description, choices, editor, children, }: RadioGroupSettingProps<V, E>): React$Node => {
    if (editor.value == null) return null;

    return (
        <Panel>
            <PanelHeader title={title} description={description}/>
            <PanelBar>
                {children}
                <RadioGroup
                    selected={editor.value}
                    options={choices}
                    onSelect={(v: V) => editor.setValue(v)}
                />
            </PanelBar>
            <PanelButtonBar
                cacheStatus={editor.messages?.status}
                primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                cancel={editor.status === 'edit' ? editor.onCancel : null}
            />
        </Panel>
    );
};