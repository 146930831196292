// @flow
import { to } from 'await-to-js';
import { request } from './rest';
import { kioskUrl } from './url';

let tokenCache = new Map<string, string>();

export async function fetchKioskToken(query: string): Promise<string> {
    let invalid = true;
    const token = tokenCache.get(query) || '';

    if (token !== '') {
        const [, b64payload] = token.split('.');
        const payload = JSON.parse(atob(b64payload));
        invalid = (
            // weird
            !payload.exp
            // within a second of expiring
            || (payload.exp - 1) <= Math.floor(Date.now() / 1000)
        );
    }

    if (invalid) {
        const [err, tokenResponse] = await to(request({
            url: kioskUrl,
            method: 'POST',
            data: { query }
        }));

        if (!err && tokenResponse && tokenResponse.status === 200 && tokenResponse.data && tokenResponse.data.data.issueJsonWebToken) {
            tokenCache.set(query, tokenResponse.data.data.issueJsonWebToken.token.token);
            return tokenResponse.data.data.issueJsonWebToken.token.token;
        }
    }

    return token;
}

export function clearToken(query: string) {
    tokenCache.delete(query);
}

export function clearTokens() {
    tokenCache = new Map<string, string>();
}

export function test_setToken(query: string, token: string) {
    tokenCache.set(query, token);
}