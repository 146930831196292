// @flow

import { useContext } from 'react';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { animationRouteContext } from '../../element/animation';
import { AnimatePresence } from 'framer-motion';
import { OrbitContainerList } from './OrbitContainerList';
import { OrbitContainerView } from './OrbitContainerView';
import { OrbitContainerCreate } from './OrbitContainerCreate';
import { AnimNotFound } from '../home/NotFound';
import { OrbitContainerObjects } from './OrbitContainerObjects';
import { OrbitObjectView } from './OrbitObjectView';

import type { Match } from 'react-router-dom';

export const OrbitSection = ({ match: accountMatch, }: { match: Match }): React$Node => {
    const [section, custom,] = useContext(animationRouteContext);
    const location = useLocation();

    return (
        <AnimatePresence custom={custom}>
            <Switch key={section} location={location}>
                <Route exact path={`${accountMatch.path}`} component={OrbitContainerList}/>
                <Route exact path={`${accountMatch.path}add/`} component={OrbitContainerCreate} />

                <Route exact path={`${accountMatch.path}container/:id/objects/`} render={({ match: idMatch, }) => idMatch.params.id
                    ? <OrbitContainerObjects id={idMatch.params.id} match={idMatch} isObjects={true}/>
                    : <div>Error in react-router?</div>
                }/>
                <Route exact path={`${accountMatch.path}container/:id/browse/`} render={({ match: idMatch, }) => idMatch.params.id
                    ? <OrbitContainerObjects id={idMatch.params.id} match={idMatch} isObjects={false}/>
                    : <div>Error in react-router?</div>
                }/>

                <Route exact path={`${accountMatch.path}container/:id/objects/:name(.+)/`} render={({ match: idMatch, }) => idMatch.params.id && idMatch.params.name
                    ? <OrbitObjectView container={idMatch.params.id} name={idMatch.params.name} match={idMatch}/>
                    : <div>Error in react-router?</div>
                }/>
                <Route exact path={`${accountMatch.path}container/:id/browse/:name(.+)/`} render={({ match: idMatch, }) => idMatch.params.id && idMatch.params.name
                    ? <OrbitObjectView container={idMatch.params.id} name={idMatch.params.name} match={idMatch}/>
                    : <div>Error in react-router?</div>
                }/>

                <Route path={`${accountMatch.path}container/:id/`} render={({ match: idMatch, }) => idMatch.params.id
                    ? <OrbitContainerView id={idMatch.params.id} match={idMatch}/>
                    : <div>Error in react-router?</div>
                }/>
                <Route><AnimNotFound /></Route>
            </Switch>
        </AnimatePresence>
    );
};