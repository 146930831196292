// @flow

import { createContext, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useCurrentAccount, useCurrentUser } from '../hoc/lib';

import type { Match, Location } from 'react-router-dom';

export const ANIMATION_DELAY_MS = 200;

export const pageVariants = {
    // when an animated thing is "in", it's just showing in the normal way,
    // so we just hardcode its "position" as 0.
    in: { x: '0vw', position: 'absolute', top: 0 },
    // when it's going out, it could be going left or right
    // eg - the 'view' page goes out left when sub-editing, and goes
    // out right when going back to the 'list' page.
    out: (out: number): any => ({ x: (out * 100) + 'vw', position: 'absolute', top: 0  }),
    // initial is the same as out - it could be going left or right depending on its
    // context, but it doesn't accept the 'custom' param, so we just have two variants...
    initialLeft: ({ x: '-100vw', position: 'absolute', top: 0 }),
    initialRight: ({ x: '100vw', position: 'absolute', top: 0 }),
};

export const navVariants = {
    // when an animated thing is "in", it's just showing in the normal way,
    // so we just hardcode its "position" as 0.
    in: { x: '0vw', position: 'static', top: 0 },
    // when it's going out, it could be going left or right
    // eg - the 'view' page goes out left when sub-editing, and goes
    // out right when going back to the 'list' page.
    out: (out: number): any => ({ x: (out * 100) + 'vw', position: 'absolute', top: 0  }),
    // initial is the same as out - it could be going left or right depending on its
    // context, but it doesn't accept the 'custom' param, so we just have two variants...
    initialLeft: ({ x: '-100vw', position: 'absolute', top: 0 }),
    initialRight: ({ x: '100vw', position: 'absolute', top: 0 }),
};

export const pageTransition = {
    type: 'tween',
    ease: 'easeIn',
    duration: ANIMATION_DELAY_MS / 1000,
};

export type RouteAnimation = {
    initial: 'initialLeft' | 'initialRight' | false,
    animate: 'in',
    exit: 'out',
};

type AnimContext = [string, number, RouteAnimation, RouteAnimation];

export const animationRouteContext: React$Context<AnimContext> = createContext<AnimContext>([
    '',
    0,
    { initial: false, animate: 'in', exit: 'out', },
    { initial: false, animate: 'in', exit: 'out', },
]);
animationRouteContext.displayName = 'Anim';

// let matches = [];

const I_ACCOUNT_ID = 2;
const I_KIND = 3;
const I_ID = 4;
const I_SUBEDIT = 5;
const I_SUBSUBEDIT = 6;

type CloudGuiRouteAnimation = {
    location: Location,
    section: string,
    custom: number,
    sectionAnim: RouteAnimation,
    subEditAnim: RouteAnimation,
}

// returns [kind, id, subedit]
function getIdSub(split: Array<string>): [string, string, string] {
    if (split.length > I_ID && split[I_ID] === 'cli') {
        return [
            split?.[I_KIND] || '',
            split?.[I_SUBEDIT] || '',
            split?.[I_SUBSUBEDIT] || '',
        ]
    } else {
        return [
            split?.[I_KIND] || '',
            split?.[I_ID] || '',
            split?.[I_SUBEDIT] || '',
        ]
    }
}

export const ACCOUNT_MATCH_STR = '/accounts/:accountId/:kind?/:id?/:subedit?/:subsubedit?';
export const PROFILE_MATCH_STR = '/:user(user)/:editOrAppOrCollab?/:id?/:appOrCollabEdit?';

export function useCloudGuiRouteAnimation(): CloudGuiRouteAnimation {
    const accountMatch = useRouteMatch(ACCOUNT_MATCH_STR);
    // this naming is pretty painful, but the verbosity will make it easier to remember in 6 months time...
    const profileMatch = useRouteMatch(PROFILE_MATCH_STR);
    // In fact - I suspect this crazy checking of params could be simplified
    // to just checking if the matched param count increases or decreases,
    // but this works right now so not really worth the time.

    const [prevMatch, setPrevMatch] = useState<?Match>(null);
    const currAccount = useCurrentAccount();
    const currUser = useCurrentUser();

    const location = useLocation();
    const { pathname: url } = location;

    let [custom, sectionAnim, subEditAnim] = useMemo(() => {
        let sectionAnim: RouteAnimation = {
            initial: false,
            animate: 'in',
            exit: 'out'
        };
        let subEditAnim: RouteAnimation = {
            initial: false,
            animate: 'in',
            exit: 'out'
        };

        let out = 1;

        if (!currAccount && !currUser) {
            // Don't try to do any animation before the user and account are loaded,
            // and don't remember any state that's later used to determine transitions.
        } else if (accountMatch) {

            // on a first render of the app, skip the initial animation
            if ((prevMatch != null && !prevMatch.params.profile) && prevMatch.url !== accountMatch.url) {
                const prev = prevMatch.url.split('/');
                const curr = accountMatch.url.split('/');

                const [prevKind, prevId, ] = getIdSub(prev);
                const [currKind, currId, currSub] = getIdSub(curr);

                // if only the :id? and :subedit? portions are changing, then we
                // use the 'initial' animations to move around.
                if (
                    prev.length > 4
                    && curr.length > 4
                    && prev[I_ACCOUNT_ID] === curr[I_ACCOUNT_ID]
                    && prevKind === currKind
                ) {
                    // moving between eg "add/" and "add/buffer_size",
                    // or between "lba-12345/" and "lba-12345/servers"
                    if (prevId === currId) {
                        if (currSub !== '') {
                            subEditAnim.initial = 'initialRight';
                            out = -1;
                        } else {
                            subEditAnim.initial = 'initialLeft';
                            out = 1;
                        }
                    } else if (prevId === '') {
                        // we're moving from 'list' to 'add'/'idx-xxxxx'
                        out = -1;
                        sectionAnim.initial = 'initialRight';
                        subEditAnim.initial = false;
                    } else {
                        // we're moving from 'add'/'idx-xxxxx' to 'list'
                        out = 1;
                        sectionAnim.initial = 'initialLeft';
                    }
                }
            }

            if (prevMatch == null || prevMatch.url !== accountMatch.url) {
                setPrevMatch(accountMatch);
                // matches.push([prevMatch, accountMatch, location, animationValue, out]);
            }
        } else if (profileMatch) {
            if ((prevMatch != null && !prevMatch.params.accountId) && prevMatch.url !== profileMatch.url) {
                if (
                    // moving from profile/ to profile/application/id/
                    !prevMatch.params.editOrAppOrCollab
                    && profileMatch.params.editOrAppOrCollab
                    && profileMatch.params.id
                ) {
                    sectionAnim.initial = 'initialRight';
                    out = -1;
                } else if (
                    // moving from profile/ to profile/name/
                    (!prevMatch.params.editOrAppOrCollab && profileMatch.params.editOrAppOrCollab)
                    // moving from profile/application/app-12345/name/
                    || (!prevMatch.params.appOrCollabEdit && profileMatch.params.appOrCollabEdit)
                ) {
                    subEditAnim.initial = 'initialRight';
                    out = -1;
                } else {
                    subEditAnim.initial = 'initialLeft';
                    out = 1;
                }
            }

            if (prevMatch == null || prevMatch.url !== profileMatch.url) {
                setPrevMatch(profileMatch);
                // matches.push([prevMatch, accountMatch, location, animationValue, out]);
            }
        }

        return [out, sectionAnim, subEditAnim];
        // for the useMemo params, accountMatch and prevValue are only
        // controlled within the closure, so these deps are fine.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, currAccount, currUser]);

    const section = accountMatch
        ? accountMatch.params.id
            // $FlowFixMe if id is set, then kind and accountId must also be.
            ? `/accounts/${accountMatch.params.accountId}/${accountMatch.params.kind}/${accountMatch.params.id}/`
            // $FlowFixMe if accountMatch is set, then accountId must also be.
            : `/accounts/${accountMatch.params.accountId}/${accountMatch.params.kind || ''}/`
        : (profileMatch && profileMatch.params.editOrAppOrCollab === 'application')
            ? `/user/application`
            : profileMatch
                ? '/user'
                : '';

    return {
        location,
        section,
        custom,
        sectionAnim,
        subEditAnim,
    }
}

// export const AnimationMatchDebug = () => (
//     <div className='text-xs'>
//         {matches.map(([p, m, l, v, cu]) =>
//             <pre>
//                 {JSON.stringify(p && p.url == m.url)},
//                 {JSON.stringify(m.params)},
//                 {JSON.stringify(cu)},
//                 {JSON.stringify(v)}
//                 {/*p:{p ? p.url : ''}<br/>*/}
//                 {/*c: {c ? c.url : ''}<br/>*/}
//                 {/*l: {JSON.stringify(l)}<br/>*/}
//                 {/*v:{JSON.stringify(v)}<br/>*/}
//                 {/*custom: {cu}<br/>*/}
//                 {/*<hr/>*/}
//             </pre>
//         )}
//     </div>
// );