// @flow


import { useViewFirewallRule, useViewResource } from '../../hoc/ViewResource';
import { Panel, PanelButtonBar, PanelHeader } from '../../element/Panel';
import { useResourceEditorModal } from '../../common/Editor';
import { emptyErrors } from '../../common/lib';
import { convertFirewallRuleToEditRule, validateFirewallRule } from '../firewall_policies/edit/lib';
import { ResourceAddViewRoute } from '../../element/ResourceAddViewRoute';
import { dirMap, FirewallRuleForm } from './FirewallRuleForm';
import { useDeleteDialog, DeleteDialog } from '../../common/CommonDialogs';

import type { Match } from 'react-router';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { EditorDef } from '../../common/Editor';
import type { FormErrors } from '../../common/lib';
import type { BbFirewallRule, BbFirewallRuleParams, EditFirewallRule, } from '../../../api/type.fwp';
import type { BbServerGroup } from '../../../api/type';

const editRuleDef: EditorDef<BbFirewallRule, EditFirewallRule, BbFirewallRuleParams, FormErrors> = {
    emptyErrors,
    editUri: '',
    onEdit: (fwr: BbFirewallRule) => convertFirewallRuleToEditRule(fwr),
    onValidate: (resource: ViewResourceProps<BbFirewallRule, BbFirewallRuleParams>, value: EditFirewallRule) => validateFirewallRule(value),
};

export const FirewallRuleEdit = ({ id, match }: { id: string, match: Match }): React$Node => {
    const serverGroupId = match.params.server_group_id || ''
    const resource = useViewFirewallRule(id, serverGroupId);
    const groupResource = useViewResource<BbServerGroup, null>('server_group', serverGroupId)
    const { item: group } = groupResource;
    const editor = useResourceEditorModal(resource, editRuleDef, match.path);
    const [, deleteDialog] = useDeleteDialog(true, resource.deleteAction);

    if (!editor.value) return null;

    const { value: rule } = editor;

    const direction: $Keys<typeof dirMap> = (rule?.source ? 'in' : 'out');

    return (
        <ResourceAddViewRoute
            listTitle={group?.name || group?.id || ''}
            resourceName={rule?.id}
            resource={resource}
            match={match}
            dialog={<DeleteDialog name={'this firewall rule'} dialog={deleteDialog} />}
            view={
                <Panel>
                    <PanelHeader
                        title={`Edit ${dirMap[direction].toLowerCase()} firewall rule`}
                    />

                    <FirewallRuleForm editor={editor} />

                    <PanelButtonBar
                        cacheStatus={editor.messages?.status}
                        primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                        cancel={editor.status === 'edit' ? editor.onCancel : null}
                        leftButton={{ children: 'Delete Rule', color: 'red', onClick: deleteDialog.show }}
                    />
                </Panel>
            }
        />
    );

};

