// @flow

import type { BbResourceKind } from '../../api/type';

export type BuildingResource = {
    +id: string,
    +resource_type: BbResourceKind,
    +status: string,
}

export type OrbitBulkDeletion = {
    +id: string,
    // this is a bit unreliable, because 'selected' dirs
    // will appear twice in the count. so don't show this to a user.
    // the ui will always dispatch equal amounts of add/remove objects,
    // so we can use it to show/hide the progress bar though.
    +count: number,
    +completed: number,
}

export type BuildWithEstimate = {
    ...BuildingResource,
    progress: number,
}

export type BuildState = {
    +building: Map<string, BuildingResource>,
    +deleting: Map<string, OrbitBulkDeletion>,
}

// we can't tell for snapshots
export const UNKNOWN_DISK_SIZE = -1;