// @flow
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RightArrow } from '../../element/Styled';
import { Table, Td, Th, Tr, TrHeader } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { ResourceChip } from '../../element/Chip';
import { ResourceListPage } from '../../element/ResourceListPage';
import { cloudIpSortFields, useCloudIpListActions, } from './def';
import { createCloudIpSearch } from './CipSearch';
import { CopyLink, Button } from '../../element/Button';
import { CloudIpMappedResource } from './CipMappedResource';
import { RC_CACHED } from '../../../state/resource/type';
import { SkeletonBar, SkeletonChip, SkeletonListLoadingEntries, } from '../../element/Skeleton';
import { useResourceRoutes } from '../../../lib/history';
import { PageHeader, PageTitle } from '../../element/Page';
import { useCurrentAccount } from '../../hoc/lib';
import { Tooltip } from '../../element/Tooltip';
import { CloudIpLimit } from '../../element/LimitTooltip';
import { CipCreateConfirmDialog, useCipCreateDialog } from './CipCreateConfirmDialog';
import { ItemSelector } from '../../element/ItemSelector';
import { Dialog } from '../../element/Dialog';

import type { BbCloudIp, } from '../../../api/type.cip';
import type { ResourceSelectorTableProps } from '../../common/ResourceSelector';

export const CloudIpListTable = ({ items, Th: SortTh, search, status, totalCount, context, itemSelect, }: ResourceSelectorTableProps<BbCloudIp>): React$Node => {
    const getRoute = useResourceRoutes();
    const listContext = context === 'list';

    return (
        <Table>
            <thead>
            <TrHeader
                itemSelect={itemSelect}
                context={context}
                actionColSpan={4}
                hasItems={items.length > 0}
                columns={
                    <>
                        <SortTh field={'id'}>Name</SortTh>
                        <SortTh field={'public_ipv4'}>IP Addresses</SortTh>
                        <SortTh field={'destination'}>Destination</SortTh>
                        {listContext ? <Th actions={true}>&nbsp;</Th> : null}
                    </>
                }
            />
            </thead>
            <tbody>
            {items.map((ip: BbCloudIp) =>
                <Tr
                    key={ip.id}
                    {...itemSelect?.rowProps(ip)}
                >
                    {itemSelect
                        ? <Td selector={true}>
                            <ItemSelector item={ip} itemSelect={itemSelect} listContext={listContext} />
                        </Td>
                        : null}
                    <Td resourceName={true}>
                        <ResourceChip concise={true} resource={ip} link={itemSelect?.selectedLength === 0 ? getRoute('cloud_ip', ip.id) : null}/>
                    </Td>
                    <Td>
                        <span className='u-data--ip-address'>{ip.public_ipv4}</span><CopyLink value={ip.public_ipv4} />  <br/>
                        <span className='u-data--ip-address'>{ip.public_ipv6}</span><CopyLink value={ip.public_ipv6} />
                    </Td>
                    <Td resourceName={true}><CloudIpMappedResource ip={ip} showAddButton={true}/></Td>
                    {listContext ?
                        <Td actions={true}>
                            <Link to={getRoute('cloud_ip', ip.id)}><RightArrow/></Link>
                        </Td>
                        : null
                    }
                </Tr>
            )}
            {status !== RC_CACHED
                ? <SkeletonListLoadingEntries>
                    <Tr>
                        {itemSelect ? <Td selector={true}>&nbsp;</Td> : null}
                        <Td resourceName={true}><SkeletonChip /></Td>
                        <Td>
                            <SkeletonBar size='md' /><br/>
                            <SkeletonBar size='xl' />
                        </Td>
                        <Td><SkeletonChip /></Td>
                        {listContext
                            ? <Td actions={true}>&nbsp;</Td>
                            : null
                        }
                    </Tr>
                </SkeletonListLoadingEntries>
                : null
            }
            <NoMatchingResourceTr search={search} colSpan={5} kind='cloud_ip' />
            <NoResourcesTr status={status} totalCount={totalCount} colSpan={5} context={context} kind='cloud_ip' />
            </tbody>
        </Table>
    );
};

const { searchDef, CloudIpSearch, } = createCloudIpSearch('cloud_ip:list');

export const CipList = (): React$Node => {
    const account = useCurrentAccount();
    const { dialog, apiResult } = useCipCreateDialog({
        navigate: true,
    });

    const atLimit = account && account.cloud_ips_used === account.cloud_ips_limit;

    useEffect(() => {
        if (window.location.search.match('add')) {
            dialog.show();
        }
    // run at mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { deleteDialog, unmapDialog, ...selector } = useCloudIpListActions();

    return (
        <>
            <CipCreateConfirmDialog
                dialog={dialog}
                apiResult={apiResult}
            />
            <Dialog
                dialog={deleteDialog}
                title={() => `Delete ${selector.editor?.value?.length || 'these'} Cloud IPs?`}
                confirmString='delete'
                render={() => (<div>Are you sure you want to delete these Cloud IPs?</div>)}
            />
            <Dialog
                dialog={unmapDialog}
                title={() => `Unmap ${selector.editor?.value?.length || 'these'} Cloud IPs?`}
                confirmString='unmap'
                render={() => (<div>Are you sure you want to un-map these Cloud IPs?</div>)}
            />

            <ResourceListPage
                title={
                    <PageHeader>
                        <PageTitle>Cloud IPs</PageTitle>

                        {atLimit
                            ? <Tooltip overlay={<CloudIpLimit/>}>
                                <Button kind="primary" disabled={true}>Create Cloud IP</Button>
                            </Tooltip>
                            : <Button kind="primary" onClick={() => dialog.show()}>Create Cloud IP</Button>
                        }
                    </PageHeader>
                }
                kind="cloud_ip"
                context='list'
                table={CloudIpListTable}
                searchDef={searchDef}
                searchComponent={CloudIpSearch}
                checkLimit='cloud_ips'
                sortFields={cloudIpSortFields}
                selector={selector}
            />
        </>
    );
};