// @flow

import { useLazyQuery } from '@apollo/react-hooks';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import addDays from 'date-fns/addDays';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { RC_INITIAL, RC_CACHED, RC_FETCHING, RC_ERROR } from '../../state/resource/type';
import { apiClient } from '../../api/graphql';
import { formatDateTime } from '../element/Styled';

import type {
    ResourceChartQuery,
    RechartDataHook,
    ChartInputs,
    MetricError,
    MetricRange,
    Metrics,
    RechartDataPoints,
    RechartRange,
    JsTimestamp
} from '../common/Metrics/types';
import type { ResourceFetched } from '../../state/resource/type';

export function useGraphqlMetric<Metric>(config: ResourceChartQuery<Metric>, resourceId: string, rangeFilter: string): RechartDataHook {
    const [chartInput, setChartInput] = useState<?ChartInputs>(null);
    const [metricError, setMetricError] = useState<?MetricError>(null);
    const [cacheStatus, setCacheStatus] = useState<ResourceFetched>(RC_INITIAL);
    const { adapter } = config;

    const variables = useMemo(() => ({
        'resourceId': resourceId,
        'range': rangeFilter,
    }), [resourceId, rangeFilter]);

    const [fetch, raw] = useLazyQuery<Metric, { resourceId: string, range: string }>(config.query, {
        client: apiClient,
    });

    const { loading, data, error, } = raw;

    useEffect(() => {
        if (!loading && data) {
            const nextChartInput = adapter(resourceId, data, rangeFilter);
            setCacheStatus(RC_CACHED);
            setChartInput(nextChartInput);
        } else if (!loading && error) {
            const uiError = error.graphQLErrors.find((err) =>
                err.extensions?.code === 'METRICS_CURRENTLY_INACCESSIBLE'
            );
            setMetricError(uiError ? uiError.extensions.code : null);
            setCacheStatus(RC_ERROR);
        } else if (loading) {
            setCacheStatus(RC_FETCHING);
            setChartInput(null);
        }
    }, [resourceId, loading, data, error, setChartInput, adapter, rangeFilter]);

    const refresh = useCallback(() => {
        fetch({ variables });
    }, [fetch, variables]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return { chartInput, cacheStatus, error: metricError, refresh };
}

type MappableSeries = [
    string, // id
    Metrics,
];

export function mapSeries(range: MetricRange, ...series: MappableSeries[]): [RechartDataPoints, RechartRange] {
    // the raw start range is something like dddd-mm-yy hh:ii:42
    // I don't think it's likely, but belt and braces - take the lowest timestamp in
    // the data too and generate the range with that if it's lower.
    const rawStart: JsTimestamp = Math.min(
        new Date(range.start).getTime(),
        ...(series.map(([, v]) => v.timestamps[0] * 1000).filter(x => !!x))
    );
    // don't just rely on the reported date; take the maximum timestamp in the array if it's beyond that range.
    // this saves us having to check index <= chartData.length for every point.
    const rawEnd: JsTimestamp = Math.max(
        new Date(range.end).getTime(),
        ...(series.map(([, v]) => 1 + (v.timestamps[v.timestamps.length - 1] * 1000)).filter(x => !!x))
    );
    const jsStep: JsTimestamp = range.step * 1000;

    // but prom seems to return timestamps starting from ...hh:ii:30.
    // and we have to account for js milli vs unix plain old seconds
    const promStart = rawStart - (rawStart % jsStep);

    const chartData = new Array(Math.ceil((rawEnd - promStart) / jsStep));

    const emptyDatas = series.reduce(
        (acc, [id]) => {
            acc[id] = 0;
            return acc;
        },
        {}
    );

    // now populate the full expected array
    for (let i = 0, time = promStart; i < chartData.length; i += 1, time += jsStep) {
        chartData[i] = {
            time,
            label: formatDateTime(new Date(time)),
            ...emptyDatas
        };
    }

    series.forEach(([id, metrics]) => {
        metrics.timestamps.forEach((v, i) => {
            const index = ((v * 1000) - promStart) / jsStep;
            chartData[index][id] = metrics.values[i];
        });
    });

    const outRanges = {
        start: promStart,
        end: rawEnd,
    };

    return [chartData, outRanges];
}

export function ticksFromRange(rangeFilter: string, range: $PropertyType<ChartInputs, 'range'>): $ReadOnlyArray<number> {
    let res = [];
    let next = new Date(range.start);

    switch(rangeFilter) {
    default:
    case 'HOURS_1':
    case 'HOURS_2':
        next = addMinutes(next, 15);
        do {
            res.push(next.getTime());
            next = addMinutes(next, 15);
        } while (isBefore(next, range.end) && res.length < 100);
        break;
    case 'HOURS_168':
    case 'HOURS_336':
        next.setHours(0, 0);
        next = addDays(next, 1);
        do {
            res.push(next.getTime());
            next = addDays(next, 1);
        } while (isBefore(next, range.end) && res.length < 100);
        break;
    }

    return res;
}

export const RANGE_QUERY = 'range { start end step }';