// @flow

import { PanelBar } from '../../element/Panel';
import { ValueSelect } from '../../element/ValueSelect';
import { Textarea } from '../../element/Textarea';
import { FwrAccessControl, portCriteriaHint, sourceOrDestPortOnly } from "../firewall_policies/edit/FwrAccessControl";
import { InboundTemplateChoices, inboundTemplates, OutboundTemplateChoices, outboundTemplates, } from '../firewall_policies/edit/lib';
import { TextInput } from '../../element/Input';
import { Tooltip } from '../../element/Tooltip';

import type { BbFirewallRule,  EditFirewallRule, } from '../../../api/type.fwp';
import type { BbTargetKinds,  } from '../../../api/type';
import type { Editor, } from '../../common/Editor';
import type { FormErrors } from '../../common/lib';

const protocolChoices = [
    { label: 'Any', value: 'any', },
    { label: 'TCP', value: 'tcp', },
    { label: 'UDP', value: 'udp', },
    { label: 'ICMP', value: 'icmp', },
    { label: 'Other', value: 'other' },
];

const icmpChoices = [
    { label: 'Select...', value: '' },
    { label: 'echo-request', value: 'echo-request' },
    { label: 'echo-reply', value: 'echo-reply' },
];

type SourceDest = 'source' | 'destination';

const inOutOptions = [
    { label: 'Inbound', value: 'source' },
    { label: 'Outbound', value: 'destination' },
]

export const dirMap = {
    'in': 'Inbound',
    'out': 'Outbound',
};

export const FirewallRuleForm = ({ editor }: { editor: Editor<EditFirewallRule, FormErrors, BbFirewallRule> }): React$Node => {
    if (!editor.value) return null;

    const { value: rule } = editor;

    const [templates, templateOptions] = rule.source === null
        ? [outboundTemplates, OutboundTemplateChoices]
        : [inboundTemplates, InboundTemplateChoices];

    const direction: $Keys<typeof dirMap> = (rule.source ? 'in' : 'out');

    const setEditRuleField = (val: $Shape<EditFirewallRule>) => editor.setValue({ ...editor.value, ...val });
    const setDirection = (dir: SourceDest) => {
        if (rule) {
            editor.setValue({
                ...rule,
                source: null,
                destination: null,
                // $FlowFixMe computed union problems.
                [dir]: { kind: 'any', value: '' }
            });
        }
    }

    const setTemplate = (templateIdx: number) => {
        if (templateIdx >= 0) {
            const target: $Shape<EditFirewallRule> = (direction === 'in')
                ? { source: { kind: 'any', value: '' } }
                : { destination: { kind: 'any', value: '' } };

            editor.setValue({
                ...rule,
                ...templates[templateIdx],
                ...target,
                templateIdx,
            });
        } else {
            editor.setValue({
                ...rule,
                templateIdx,
            });
        }
    };


    return (
        <PanelBar>
            {editor.status === 'add'
                ? <> 
                <div>
                    <h3>Direction</h3>
                    <p className='text-gray-600 text-sm'>Specify the direction of traffic to be allowed by this firewall rule.</p>

                    <ValueSelect
                        label='Direction'
                        options={inOutOptions}
                        selected={rule.source === null ? 'destination' : 'source'}
                        onSelect={(sourceDest) => setDirection(sourceDest)}
                        className='my-3'
                    />
                </div>
                <hr />
                </>

                : null
            }


            <div>
                <h3>Traffic type</h3>
                <p className='text-gray-600 text-sm'>Specify the type of traffic to be allowed by this firewall rule.</p>
                
                <div className='flex flex-wrap items-start'>
                <ValueSelect
                    label='Rule Type'
                    options={templateOptions}
                    selected={rule.templateIdx}
                    onSelect={(templateIdx) => setTemplate(templateIdx)}
                    className='my-3'
                />

                <ValueSelect
                    label='Protocol'
                    options={protocolChoices}
                    selected={rule.protocol}
                    onSelect={(protocol) => setEditRuleField({ protocol })}
                    readOnly={rule.templateIdx !== -1}
                    className='my-3'
                />

                {direction === 'in' && (rule.protocol === 'tcp' || rule.protocol === 'udp')
                    ? <Tooltip overlay={rule.source_port ? sourceOrDestPortOnly : null}>
                            <TextInput
                            label='Port'
                            errorText={editor.errors?.get('destination_port')}
                            value={rule.destination_port}
                            onChange={(destination_port: string) => setEditRuleField({ destination_port })}
                            disabled={rule.templateIdx !== -1 || !!rule.source_port}
                            hint={portCriteriaHint}
                            className='w-32 my-3'
                        />
                    </Tooltip>
                    : null}

                {direction === 'out' && (rule.protocol === 'tcp' || rule.protocol === 'udp')
                    ? <Tooltip overlay={rule.destination_port ? sourceOrDestPortOnly : null}>
                        <TextInput
                            label='Source Port'
                            errorText={editor.errors?.get('source_port')}
                            value={rule.source_port}
                            onChange={(source_port: string) => setEditRuleField({ source_port })}
                            disabled={rule.templateIdx !== -1 || !!rule.destination_port}
                            hint={portCriteriaHint}
                            className='w-32 my-3'
                        />
                    </Tooltip>
                    : null}

                {rule.protocol === 'icmp'
                    ? <ValueSelect
                        label='ICMP Type'
                        options={icmpChoices}
                        selected={rule.icmp_type_name}
                        onSelect={(icmp_type_name: string) => setEditRuleField({ icmp_type_name })}
                        readOnly={rule.templateIdx !== -1}
                        className='my-3'
                    />
                    : null}

                {rule.protocol === 'other'
                    ? <TextInput
                        label='Protocol Number'
                        errorText={editor.errors?.get('protocol')}
                        value={rule.protocolNumber}
                        onChange={(protocolNumber: string) => setEditRuleField({ protocolNumber })}
                        disabled={rule.templateIdx !== -1}
                        hint={<span>Enter a valid 8-bit IP protocol number (see <a href='https://en.wikipedia.org/wiki/List_of_IP_protocol_numbers' target='blank'>full list</a>)</span>}
                        className='w-40 my-3'
                    />
                    : null}
                </div>

            </div>
            <hr />
            <div>
            {direction === 'in'
                ? <>
                    <h3>Source</h3>
                    <p className='text-gray-600 text-sm'>Specify the source of traffic to be allowed by this firewall rule.</p>
                </>
                : <>
                    <h3>Destination</h3>
                    <p className='text-gray-600 text-sm'>Specify the destination of traffic to be allowed by this firewall rule.</p>
                </>
            }
                <div className='flex flex-wrap items-start'>

                {rule.source
                    ? <FwrAccessControl
                        label='Source'
                        selected={rule.source}
                        errorText={editor.errors?.get('source')}
                        onChange={(kind: BbTargetKinds, value: string) => setEditRuleField({ source: { kind, value }, templateIdx: -1 })}
                    />
                    : null
                }

                {rule.destination
                    ? <FwrAccessControl
                        label='Destination'
                        selected={rule.destination}
                        errorText={editor.errors?.get('destination')}
                        onChange={(kind: BbTargetKinds, value: string) => setEditRuleField({ destination: { kind, value }, templateIdx: -1 })}
                    />
                    : null
                }

                {direction === 'out' && (rule.protocol === 'tcp' || rule.protocol === 'udp')
                    ? <Tooltip overlay={rule.source_port ? sourceOrDestPortOnly : null}>
                        <TextInput
                            label='Port'
                            value={rule.destination_port}
                            disabled={!!rule.source_port}
                            errorText={editor.errors?.get('destination_port')}
                            hint={portCriteriaHint}
                            onChange={(destination_port: string) => setEditRuleField({ destination_port })}
                            className='w-32 my-3'
                        />
                    </Tooltip>
                    : null}

                {direction === 'in' && (rule.protocol === 'tcp' || rule.protocol === 'udp')
                    ? <Tooltip overlay={rule.destination_port ? sourceOrDestPortOnly : null}>
                        <TextInput
                            label='Source Port'
                            value={rule.source_port}
                            disabled={!!rule.destination_port}
                            errorText={editor.errors?.get('source_port')}
                            onChange={(source_port: string) => setEditRuleField({ source_port })}
                            hint={portCriteriaHint}
                            className='w-32 my-3'
                        />
                    </Tooltip>
                    : null}
                </div>
            </div>
            <hr/>
            <div>
                <h3>Description</h3>
                <p className="text-gray-600 text-sm">Specify an optional description for this firewall rule.</p>
                <Textarea
                    value={rule.description || ''}
                    focusSelectAll={true}
                    onChange={(description: string) => setEditRuleField({ description })}
                    className="w-100 my-3"
                />
            </div>
        </PanelBar>
    );

};

