// @flow

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMessages, useTriggeredMessages } from './Messages';
import { RC_SUCCESS, RC_INITIAL, RC_CACHED } from '../../state/resource/type';
import { historyBack, historyNavigateOnCreate } from '../../lib/history';
import { useCurrentAccount, } from './lib';
import { orbitMetaToTempKeyEditor } from '../section/orbit/def';
import { emptyErrors } from '../common/lib';
import { useEditorErrors, useMessagesDrivenEditor } from '../common/Editor';

import type { BbOrbitContainerParams, BbOrbitContainer, OrbitAccountMeta } from '../../api/type.orbit';
import type { MessageHook } from './Messages';
import type { ResourceFetched } from '../../state/resource/type';
import type { TempUrlKeys } from '../section/orbit/def';
import type { FormErrors } from '../common/lib';
import type { EditorModal } from '../common/Editor';
import type { Dispatch } from 'redux';
import type { OrbitAction, OrbitFetchContainersAction, ContainersState, OrbitContainersDetailCollection } from '../../state/Orbit/type';
import type { CloudGuiState } from '../../state/cloudgui';

type OrbitContainerCreate = {
    create: (params: BbOrbitContainerParams) => void,
    messages: MessageHook<BbOrbitContainer>,
}

export type OrbitAccountSettingsHook = ?OrbitAccountMeta;

export type OrbitContainersHook = {
    fetched: ResourceFetched,
    containers: { [id: string]: BbOrbitContainer },
}

function mapToObj<V>(inputMap: Map<string, V>): { [string]: V } {
    let obj = {};

    inputMap.forEach(function(value, key){
        obj[key] = value
    });

    return obj;
}


export function useOrbitContainers(): OrbitContainersHook {
    const [allDetails, fetched] = useSelector<CloudGuiState, [OrbitContainersDetailCollection, ResourceFetched]>((state: CloudGuiState) => [
        state.Orbit.containers.details, state.Orbit.fetched
    ]);
    const dispatch = useDispatch<Dispatch<OrbitFetchContainersAction>>();

    const containers = useMemo(() => mapToObj(allDetails), [allDetails]);

    useEffect(() => {
        if (fetched === RC_INITIAL) {
            dispatch({ type: 'ORBIT_FETCH_CONTAINERS' });
        }
    }, [dispatch, fetched]);

    return {
        containers,
        fetched,
    };
}

export function useOrbitContainerCreate(): OrbitContainerCreate {
    const dispatch = useDispatch();
    const messagesId = 'create_container';
    const account = useCurrentAccount();
    const messages = useMessages<BbOrbitContainer>(messagesId);

    useEffect(() => {
        if (messages.status === RC_SUCCESS && messages.resource && account) {
            historyNavigateOnCreate(account, messages.resource.name, 'container');
        }
    }, [messages, account]);

    // we only do this to clear messages on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => messages.clear, []);

    return {
        create: (params: BbOrbitContainerParams) => {
            dispatch({
                type: 'ORBIT_CREATE_CONTAINER',
                payload: {
                    params,
                    messagesId,
                }
            });
        },
        messages,
    };
}


export function useContainerNames(): $ReadOnlyArray<string> {
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const [ fetched, containers ]: [ ResourceFetched, ContainersState ] = useSelector((state: CloudGuiState) => [
        state.Orbit.fetched, state.Orbit.containers,
    ]);

    useEffect(() => {
        if (fetched === RC_INITIAL) {
            dispatch({ type: 'ORBIT_FETCH_CONTAINERS' });
        }
    }, [fetched, dispatch]);

    return Array.from(containers.details.keys());
}

export const useOrbitAccountMeta = (): OrbitAccountSettingsHook => {
    const { next } = useTriggeredMessages();
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const accountMeta = useSelector((state: CloudGuiState) => state.Orbit.account);

    useEffect(() => {
        dispatch({
            type: 'ORBIT_FETCH_ACCOUNT_META',
            payload: {
                isRetry: false,
                messagesId: next(),
            }
        })
        // on mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return accountMeta;
}

const TEMP_KEYS_EDIT_URI = 'temp_url_keys';

export const useOrbitAccountMetaEditor = (accountMeta: ?OrbitAccountMeta, path: string): EditorModal<TempUrlKeys, FormErrors, OrbitAccountMeta> => {
    const dispatch = useDispatch<Dispatch<OrbitAction>>();
    const errors = useEditorErrors(emptyErrors);
    const { next, messages, } = useTriggeredMessages();
    const [value, setValue, state, setState] = useMessagesDrivenEditor<OrbitAccountMeta, TempUrlKeys, FormErrors>(
        errors,
        path + TEMP_KEYS_EDIT_URI + '/',
        accountMeta === null ? RC_INITIAL : RC_CACHED,
        messages,
        () => accountMeta,
        (settings) => orbitMetaToTempKeyEditor(settings),
    );

    return {
        status: state === 'editing' ? 'edit' : false,
        editUri: TEMP_KEYS_EDIT_URI,
        value,
        setValue: (val: ?TempUrlKeys) => setValue(val),
        messages,

        onCancel: () => {
            setState('initial');
            historyBack();
        },
        onSave: () => {
            if (value != null) {
                messages.clear();
                errors.clearErrors();
                setState('saving');

                const headers = {
                    'x-account-meta-temp-url-key': value.tempUrlKey1,
                    'x-account-meta-temp-url-key-2': value.tempUrlKey2,
                }

                dispatch({
                    type: 'ORBIT_ACCOUNT_UPDATE_META',
                    payload: {
                        headers,
                        messagesId: next(),
                    },
                });
            }
        },

        ...errors,
    };

};


