// @flow
import { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { animationRouteContext } from '../../element/animation';

type NotFoundProps = {
    extra?: ?React$Node;
}

export const NotFound = ({ extra }: NotFoundProps): React$Node => {
    return (
        <div>
            <div className='alert alert-danger mt-3'>Not found{extra}</div>
        </div>
    );
};

export const AnimNotFound = (): React$Node => {
    const [, , sectionAnim, subEditAnim,] = useContext(animationRouteContext);
    return (
        <motion.div {...sectionAnim}>
            <AnimatePresence>
                <motion.div {...subEditAnim}>
                    <NotFound/>
                </motion.div>
            </AnimatePresence>
        </motion.div>
    );
};