// @flow
import { useMemo } from 'react';
import { Panel, PanelHeader } from '../../element/Panel';
import { Table, Th, Td, Tr } from '../../element/Table';
import { ResourceChip, ResourceChipFetch } from '../../element/Chip';
import { DropdownButton } from '../../element/DropdownButton';
import { Button } from '../../element/Button';
import { Tooltip } from '../../element/Tooltip';
import { SkeletonListLoadingEntries, SkeletonChip, SkeletonBar, isShowDetailsResourceStatus } from '../../element/Skeleton';
import { useResourcesById } from '../../hoc/ListPage';
import { formatMegabytesSize } from '../../element/Styled';
import { useResourceRoutes, history } from '../../../lib/history';
import { Link } from 'react-router-dom';
import {
    useVolumeDetach,
    useVolumeBoot,
    validateVolumeAttachmentToServer,
    useZoneSupportsBlockStorage
} from '../../hoc/Volumes';
import { VolDetachDialog, VolBootDialog } from '../volume/VolDialogs';
import { ZoneNbsRestrictionTooltip } from '../../element/LimitTooltip';

import type { BbServer, BbServerParams } from '../../../api/type.srv';
import type { ViewResourceProps } from '../../hoc/ViewResource';
import type { BbVolume, BbNestedVolume } from '../../../api/type.volume';

type ServerVolumeViewProps = {
    +resource: ViewResourceProps<BbServer, BbServerParams>,
}

export const ServerVolumeView = ({ resource }: ServerVolumeViewProps): React$Node => {
    const { item: server } = resource;

    const serverZoneCanUseVolumes = useZoneSupportsBlockStorage(server?.zone?.id);

    const accountVolumes = useResourcesById<BbVolume>('volume');
    const getRoute = useResourceRoutes();
    const volumeDetach = useVolumeDetach();
    const volumeBoot = useVolumeBoot();

    const serverIsActive = server?.status === 'active';
    const isNbsServer = (server?.server_type?.storage_type === 'network');

    const attachedVolumes = server?.volumes || [];

    const disableAttachHint = useMemo(() => {
            if (server == null) return null;

            if (!serverZoneCanUseVolumes) {
                return <ZoneNbsRestrictionTooltip serverId={server?.id} zoneHandle={server?.zone?.handle} />
            }

            return validateVolumeAttachmentToServer(null, server, false);
        },
        [server, serverZoneCanUseVolumes]
    );

    function getDisableBootTooltip(volume: BbNestedVolume | BbVolume): React$Node {
        if (!isNbsServer) return <span>Boot volumes can't be changed for servers using local SSD storage</span>;
        if (isNbsServer && volume.boot) return <span>This Volume is already the boot volume</span>;
        if (isNbsServer && !volume.boot && serverIsActive) return <span>The boot volume can't be changed while the Cloud Server is active</span>;
    }

    return (
        <>
            <VolDetachDialog detach={volumeDetach}/>
            <VolBootDialog boot={volumeBoot} />

            <Panel>
                <PanelHeader
                    title='Volumes'
                    actions={{ attach_volume: disableAttachHint? null : () => history.push('attach/') }}
                    disableAttachHint={disableAttachHint}
                />
                <Table>
                    <thead>
                    <tr>
                        <Th>Name</Th>
                        <Th>Storage Type</Th>
                        <Th>Size</Th>
                        <Th>Encryption</Th>
                        <Th>Serial</Th>
                        <Th>Image</Th>
                        <Th actions={true}></Th>
                    </tr>
                    </thead>
                    <tbody>
                    {attachedVolumes.map(nested => {
                        const volume = accountVolumes.resources[nested.id] || nested;

                        return (
                            <tr key={volume.id}>
                                <Td resourceName={true}>
                                    <ResourceChip
                                        concise={true}
                                        resource={volume}
                                        link={volume.storage_type === 'network' ? getRoute('volume', volume.id) : null}
                                    />
                                </Td>
                                <Td>{volume.storage_type}</Td>
                                <Td>{formatMegabytesSize(volume.size)}</Td>
                                <Td>{volume.encrypted ? 'Yes' : 'No'}</Td>
                                <Td><code className="text-sm select-all">{volume.serial}</code></Td>
                                <Td>
                                    {/*
                                      * collected volume.image can be populated when source_type == raw and source == null - the "Blank Disk Image"
                                      * so prefer to use volume.image if it's populated, otherwise try falling back to a ResourceChipFetch
                                      */}
                                    {volume.image
                                        ? <ResourceChip resource={volume.image}/>
                                        : volume.source_type === 'image' && volume.source != null
                                            ? <ResourceChipFetch id={volume.source} kind='image' />
                                            : '-'
                                    }
                                </Td>
                                <Td actions={true}>
                                    <Tooltip
                                        placement='left'
                                        overlay={
                                            volume.storage_type === 'local'
                                                ? <>
                                                   Local storage volumes can't be managed independently.
                                                   To add more storage you can either attach
                                                   additional block storage volumes or resize the cloud
                                                   server itself.
                                                </>
                                                : null
                                        }
                                    >
                                    <DropdownButton
                                        postIcon={'ellipsis-horizontal'}
                                        kind="tertiary"
                                        disabled={volume.storage_type === 'local'}
                                        color="grey"
                                        dropdown={
                                            <>
                                                <Link to={`volume_resize/${volume.id}/`}>
                                                    <Button size="sm" kind="tertiary" className='w-full'>Resize</Button>
                                                </Link>
                                                <Tooltip placement='left' overlay={getDisableBootTooltip(volume)}>
                                                    <Button
                                                        size="sm"
                                                        kind="tertiary"
                                                        disabled={volume.boot || serverIsActive || !isNbsServer}
                                                        onClick={!volume.boot && !serverIsActive ? () => volumeBoot.dialog.show(volume) : null}
                                                    >
                                                        Make Boot Volume
                                                    </Button>
                                                </Tooltip>

                                                <Link to={getRoute('volume', volume.id)}>
                                                    <Button size="sm" kind="tertiary" className="w-full">View Volume</Button>
                                                </Link>
                                                <hr/>
                                                <Tooltip placement='left' overlay={
                                                    volume.boot
                                                        ? 'This Volume is the boot volume and can\'t be detached from the Cloud Server'
                                                        : null
                                                }>
                                                    <Button
                                                        size="sm" color="red" kind="tertiary"
                                                        disabled={volume.boot}
                                                        onClick={!volume.boot ? () => volumeDetach.dialog.show(volume) : null}
                                                    >
                                                        Detach
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                    </Tooltip>
                                </Td>
                            </tr>
                        );
                    }
                    )}
                    {!isShowDetailsResourceStatus(resource.status)
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td resourceName={true}><SkeletonChip/></Td>
                                <Td><SkeletonBar size="sm"/></Td>
                                <Td><SkeletonChip/></Td>
                                <Td><SkeletonBar size="md"/></Td>
                                <Td actions={true}>&nbsp;</Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : null
                    }
                    </tbody>
                </Table>
            </Panel>
        </>
    );
};