// @flow

import { store } from '../../state/cloudgui';
import type { BbCollectedAccount } from '../../api/type.acc';

type SortFunction<T: Object> = (a: T, b: T) => number;

export type SortFields<T: Object> = {
    _default: (a: T, b: T) => number,
    [field: string]: SortFunction<T>,
    ...
}

const ipToInt = (ipv4: string) => ipv4.split('.')
    .reduce(function(ipInt, octet) { return (ipInt<<8) + parseInt(octet, 10)}, 0) >>> 0;


export const stringSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => (a[f] || '').localeCompare(b[f] || '');
export const boolSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => (a[f] ? 1 : 0) - (b[f] ? 1 : 0);
export const idNameSort = <T: {id: string, name: string, ...} | {id: string, name: ?string, ...}>(): SortFunction<T> => (a: T, b: T): number => ((a.name || a.id).localeCompare(b.name || b.id));
export const dateSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => a[f].getTime() - b[f].getTime();
export const nullableDateSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => {
    const hasA: boolean = a[f] != null;
    const hasB: boolean = b[f] != null;
    if (hasA && hasB) return a[f].getTime() - b[f].getTime();
    if (hasA !== hasB) return hasA ? -1 : 1;
    return idNameSort()(a, b);
}
export const numberSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => a[f] - b[f];
export const descNumberSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => b[f] - a[f];
export const ipv4Sort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => ipToInt(a[f]) - ipToInt(b[f])
export const cloudIpsSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => {
    const aVal = a[f].length > 0 ? ipToInt(a[f][0].public_ipv4) : 0;
    const bVal = b[f].length > 0 ? ipToInt(b[f][0].public_ipv4) : 0;
    return aVal - bVal;
}

export const arrayLengthSort = <T: Object>(f: $Keys<T>): SortFunction<T> => <T: Object>(a: T, b: T) => a[f].length - b[f].length;


export const relationshipSort = (): SortFunction<BbCollectedAccount> => (a: BbCollectedAccount, b: BbCollectedAccount): number => {
    const user = store.getState().Auth.currUser?.id;
    const aVal = (a.owner.id === user) ? 1 : 0;
    const bVal = (b.owner.id === user) ? 1 : 0;

    return aVal - bVal;
}