// @flow

import { Notice } from '../../../element/Notice';
import { Dialog } from '../../../element/Dialog';
import { useSupportDialog } from '../../../hoc/SupportDialog';

import type { MessageHook } from '../../../hoc/Messages';

type HostVolumeConflictDialogProps = {
    messages: ?MessageHook<any>,
    serverId: ?string,
    volumeId: ?string,
};

function getPresetMessage(volId: string, serverId: string): string {
    return `I'm having trouble attaching ${volId} to ${serverId} due to a host_volume_support_failure and would like to arrange a migration.`;
}

const HOST_VOLUME_SUPPORT_FAILURE = 'host_volume_support_failure';

export const HostVolumeConflictDialog = ({ messages, volumeId, serverId }: HostVolumeConflictDialogProps): React$Node => {
    const supportDialog = useSupportDialog(
        messages,
        HOST_VOLUME_SUPPORT_FAILURE,
        () => volumeId && serverId  ? getPresetMessage(volumeId, serverId) : null,
    );

    return (
        <Dialog
            title="Attach Volume Failed"
            dialog={supportDialog}
            render={() => (
                <div className="space-y-4">
                    <Notice type="warning" icon="info-fill">
                        Unfortunately, we couldn't attach the volume as the cloud server's current host doesn't support attaching block storage volumes.
                    </Notice>
                    <p>Please click below to contact support and we can arrange a migration to a different host.</p>
                </div>
            )}
        />
    );
};
