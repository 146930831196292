// @flow
/* global geoip2 */

import { useEffect } from 'react';
import { RC_FETCHING, RC_ERROR, RC_CACHED, RC_INITIAL } from '../state/resource/type';
import { useSelector, useDispatch } from 'react-redux';

import type { ClientIpAddressHook, GuiClientIpAction } from '../state/Gui/type';
import type { CloudGuiState } from '../state/cloudgui';
import type { Dispatch } from 'redux';

const ERROR: ClientIpAddressHook = {
    cacheStatus: RC_ERROR,
    clientIp: null,
};


export function useClientIpAddress(): ClientIpAddressHook {
    const geoipLoaded = typeof geoip2;
    const dispatch = useDispatch<Dispatch<GuiClientIpAction>>();

    const result = useSelector<CloudGuiState, ClientIpAddressHook>(state => state.Gui.clientIp);
    const { cacheStatus } = result;

    useEffect(
        () => {
            if (geoipLoaded !== 'undefined' && cacheStatus === RC_INITIAL) {
                dispatch({
                    type: 'GUI_CLIENT_IP',
                    payload: {
                        cacheStatus: RC_FETCHING,
                        clientIp: null,
                    },
                });

                geoip2.country(
                    (apiResponse) => {
                        const addr = apiResponse?.traits?.ip_address;
                        if (typeof addr  === 'string') {
                            dispatch({
                                type: 'GUI_CLIENT_IP',
                                payload: {
                                    cacheStatus: RC_CACHED,
                                    clientIp: addr,
                                }
                            });
                        } else {
                            dispatch({
                                type: 'GUI_CLIENT_IP',
                                payload: ERROR
                            });
                        }
                    },
                    () => {
                        dispatch({
                            type: 'GUI_CLIENT_IP',
                            payload: ERROR
                        });
                    },
                );

            }
        },
        [geoipLoaded, cacheStatus, dispatch]
    );

    return result;
}