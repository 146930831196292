// @flow
import { cn } from './lib/classNames';
import { UNKNOWN_DISK_SIZE } from '../../state/Build/type';

type ProgressBarProps = {
    +explanation: React$Node,
    +color?: string,
    +className?: string,
    +max: number,
    +value: number,
}

export const ProgressBar = ({ className: extraClassName, color, max, value, explanation, ...props }: ProgressBarProps): React$Node => {
    const progress = (value === UNKNOWN_DISK_SIZE) ? 100 : (value/max)*100;

    let classes = {
        'c-progress': true,
        'c-progress--blue': (color === 'blue'),
        'c-progress--green': (color === 'green'),
        'c-progress--shimmer': (value === UNKNOWN_DISK_SIZE),
        'c-progress--has-explanation': !!explanation,
    }

    const className = cn(classes) + ' ' + (extraClassName || '');

    return (
        <div className={className} {...props}>
            <div className='c-progress__bar'>
                <div className='c-progress__indicator' style={{width: progress+'%'}}></div>
            </div>

            {explanation
                ? <div className='c-progress__explanation'>{explanation}</div>
                : null
            }
            
        </div>
    );
};