// @flow
import type { BbServerType, BbCollectedImage, BbImage } from '../../../../api/type';

export function serverTypeIsDisabled(server_type: BbServerType, availableMemory: number, selectedImage: ?BbCollectedImage | ?BbImage, current: ?BbServerType, isInactive: boolean): [boolean, boolean, boolean] {
    const needsToBeInactive = current != null && current.ram > server_type.ram && !isInactive;
    const diskDoesntFit = (current != null && current.storage_type === "local" && current.disk_size > server_type.disk_size);
    const disabled = (
        availableMemory < server_type.ram
        || (selectedImage?.min_ram != null && selectedImage.min_ram > server_type.ram)
        || current?.id === server_type.id
        || diskDoesntFit
        || needsToBeInactive
    );

    return [needsToBeInactive, diskDoesntFit, disabled];
}