// @flow
import { useContext } from 'react';
import { formatGbp } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { Banner } from '../../element/Banner';
import { Button } from '../../element/Button';
import { accountBillingContext } from '../../hoc/Billing';
import { useResourceRoutes } from '../../../lib/history';
import { SvgIcon } from '../../element/SvgIcon';

import type { BbCollectedAccount, BbAccount } from '../../../api/type.acc';
import type { AccountBillingData } from '../../hoc/Billing';

export type AccountStatusBannerProps = {
    +account: BbCollectedAccount,
    +data: AccountBillingData,
};

export const PayLink = ({ account, color, amount }: { +account: BbCollectedAccount | BbAccount, color?: string, amount?: null|string|number, }): React$Node => (
    <Link to={`/accounts/${account.id}/billing/payment` + (amount ? '?amount=' + amount : '')} className='inline-block ml-3'>
        <Button
            size='sm'
            color={color || 'white'}
            postIcon='caret-right'
        >Make Payment</Button>
    </Link>
);

const PendingBanner = ({ account, data }: AccountStatusBannerProps) => {
    const getRoute = useResourceRoutes();

    return (
        <Banner type='info'>
                {data.account.paymentCard == null
                    ? <div>
                        <b className='mr-1'>Activate your account to get started</b> (2 mins)
                        <SvgIcon svg='curved-arrow-right' className='mx-2' />  
                        <Link to={getRoute('card')}>
                            <Button size='sm' color='white' postIcon='caret-right'>Add Card Details</Button>
                        </Link>
                    </div>
                    : <b>
                        Your account is awaiting activation 
                         <Link to={`/accounts/${account.id}/support/`} className='inline-block ml-3'>
                            <Button
                                size='sm'
                                color='white'
                                postIcon='caret-right'
                            >Contact Support</Button>
                        </Link>
                    </b>           
                }
        </Banner>
    );
};

const OutstandingBalanceBanner = ({ data, account }: AccountStatusBannerProps) => (
    <Banner type='warning'>
        <b>Your account has an outstanding balance of &pound;{formatGbp(data.account.currentBalance)}</b>
        <PayLink account={account} amount={data.account.currentBalance} />
    </Banner>
);

const OverdueBalanceBanner = ({ data, account }: AccountStatusBannerProps) => (
    <Banner type='warning'>
        <b>Your account is overdue with an outstanding balance of &pound;{formatGbp(data.account.currentBalance)}</b>
        <PayLink account={account} amount={data.account.currentBalance} />
    </Banner>
);

const WarningBalanceBanner = ({ data, account }: AccountStatusBannerProps) => (
    <Banner type='error'>
        <b><SvgIcon svg='warning-fill' className='mr-1' /> Warning: your account is overdue with an outstanding balance of &pound;{formatGbp(data.account.currentBalance)}</b>
        <PayLink account={account} amount={data.account.currentBalance} />
    </Banner>
);

const SuspendedBanner = ({ data, account }: AccountStatusBannerProps) => (
    <Banner type='warning'>
            {parseFloat(data.account.currentBalance) > 0
                ? <b><SvgIcon svg='warning-fill' className='mr-1' />
                    This account is currently suspended with an outstanding balance of &pound;{formatGbp(data.account.currentBalance)}
                    <PayLink account={account} amount={data.account.currentBalance} />
                </b>
                : <b>
                    <SvgIcon svg='warning-fill' className='mr-1' />
                    There is currently a problem with your account
                    <Link to={`/accounts/${account.id}/support/`} className='inline-block ml-3'>
                        <Button
                            size='sm'
                            color='white'
                            postIcon='caret-right'
                        >Contact Support</Button>
                    </Link>
                </b>
            }
    </Banner>
);

const ClosedAccountBanner = ({ account }: AccountStatusBannerProps) => (
    <Banner type='error'>
        <b><SvgIcon svg='disabled' className='mr-2' />This account is {account.status}</b>
    </Banner>
);


export const AccountStatusBanner = (): React$Node => {
    const [account, billingResult] = useContext(accountBillingContext);
    const data = billingResult?.data;

    if (!account || !data) return null;
    
    const { status } = account;

    const currBalance = parseFloat(data.account.currentBalance);

    switch(status) {
    case 'pending':
        return <PendingBanner account={account} data={data}/>;
    case 'active':
        if (currBalance > 0) return <OutstandingBalanceBanner account={account} data={data}/>;
        break;
    case 'overdue':
        if (currBalance > 0) return <OverdueBalanceBanner account={account} data={data}/>;
        break;
    case 'warning':
        if (currBalance > 0) return <WarningBalanceBanner account={account} data={data}/>;
        break;
    case 'suspended':
        return <SuspendedBanner account={account} data={data}/>;
    case 'closed':
    case 'terminated':
    case 'deleted':
        return <ClosedAccountBanner account={account} data={data}/>;
    default:
        void (status: empty);
    }

    return null;
};