// @flow
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useListResource, useSearchOnResourceId } from '../hoc/ListPage';
import { useResourceSearch } from '../element/Search';
import { usePager } from '../element/ArrayPager';
import { useSelectorListGrid } from '../element/UiStateHooks';
import { PagerBar } from '../element/PagerBar';
import { PanelBar, PanelButtonBar, PanelSearchBar } from '../element/Panel';
import { Card } from '../element/Card';
import { CardGroup } from '../element/CardGroup';
import { ResourceChip } from '../element/Chip';
import { RC_ERROR, } from '../../state/resource/type';
import { PanelApiErrorBar } from '../element/PanelApiErrorBar';
import { isShowDetailsResourceStatus, SkeletonCardPanel } from '../element/Skeleton';
import { SelectionDropdown } from '../element/Table';
import { Tooltip } from '../element/Tooltip';
import { pushEscHandler, removeEscHandler } from '../../lib/history';
import { useSelector } from 'react-redux';
import { NoMatchingResourceNotice, NoResourcesNotice } from '../element/NoResourceMessages';
import { LABELS } from '../element/ResourceLabels';

import type { SearchDefinition, SearchEditorHook, ResourceSearchHook } from '../element/Search';
import type { BbCollectedResourceTypes, BbCollectedImage, } from '../../api/type';
import type { ListSortDef, ThOwnProps, } from '../hoc/ListPage';
import type { SortFields } from '../element/Sort';
import type { EditorDirect, EditorModal } from './Editor';
import type { BbLba } from '../../api/type.lba';
import type { BbDatabaseServer, BbDatabaseSnapshot } from '../../api/type.dbs';
import type { BbServer } from '../../api/type.srv';
import type { BbCloudIp } from '../../api/type.cip';
import type { ListGridHook } from '../element/UiStateHooks';
import type { CheckboxProps } from '../element/Checkbox';
import type { TableContext, TrProps, MultiSelectChoice } from '../element/Table';
import type { ResourceFetched } from '../../state/resource/type';
import type { ButtonProps } from '../element/Button';
import type { CloudGuiState } from '../../state/cloudgui';
import type { OrbitObject, ObjectId } from '../../api/type.orbit';
import type { OrbitTreeNode } from '../../state/Orbit/type';
import type { DialogState } from '../element/Dialog';
import type { BbVolume } from '../../api/type.volume';
import type { TooltipProps } from '../element/Tooltip';
import type { PanelSearchBarProps } from '../element/Panel';
import type { ImageTag } from '../hoc/orbit/ContainerRegistry';

export type SetOrFn = Set<string> | (Set<string> => Set<string>);

export type IdSelectEditor = EditorDirect<$ReadOnlyArray<string>, null>
    | EditorDirect<$ReadOnlyArray<string>, ?React$Node>
    | EditorModal<$ReadOnlyArray<string>, null, BbServer>
    | EditorModal<$ReadOnlyArray<string>, null, BbDatabaseServer>
    | EditorModal<$ReadOnlyArray<string>, null, BbCloudIp>
    | EditorModal<$ReadOnlyArray<string>, null, BbLba>
    | EditorModal<$ReadOnlyArray<string>, null, BbCollectedImage>
    | EditorModal<$ReadOnlyArray<string>, null, BbDatabaseSnapshot>
    | EditorModal<$ReadOnlyArray<string>, ?React$Node, BbVolume>
    ;

export type ItemSelectTypes = BbCollectedResourceTypes | OrbitObject | OrbitTreeNode | ImageTag;

export type SelectionShortcutBuilder<C: ItemSelectTypes> = (
    allItems: $ReadOnlyArray<C>,
    items: $ReadOnlyArray<C>,
    filter: ?(C) => boolean,
    setSelected: (SetOrFn) => void
) => $ReadOnlyArray<MultiSelectChoice>;

export type ListAction = {
    +label: React$Node,
    +buttonProps: ButtonProps,
    +disabledTooltip: ?React$Node,
}

export type ItemSelectHook<C> = {
    // +selected: Set<string>,
    +selectedLength: number,
    +isSelected: (item: { +id: string, ... } | { +id: ObjectId, ... }) => boolean,
    +setSelected: (item: C, selected: boolean, event: MouseEvent) => void,
    +selectable: (item: C) => boolean,
    +itemHoverVisible: boolean,
    +multi: ?{
        +headerCheckbox: $Shape<CheckboxProps>,
        +selectionShortcuts: $ReadOnlyArray<MultiSelectChoice>,
        +itemActions: ?$ReadOnlyArray<ListAction>,
        +isPending: (item: C) => boolean,
    },
    +rowProps: (item: C, overrides?: $Shape<TrProps>) => $Shape<TrProps>,
}

export type ResourceSelectorTableProps<C: BbCollectedResourceTypes> = {
    +items: $ReadOnlyArray<C>,
    +Th: React$StatelessFunctionalComponent<ThOwnProps>,
    +search?: ResourceSearchHook<C>,
    +status: ResourceFetched,
    +totalCount: number,
    +context: TableContext,
    +itemSelect?: ?ItemSelectHook<C>,
}

export type ItemSelectOptions<C: ItemSelectTypes> = {
    // stores the selection
    +editor: IdSelectEditor,
    // general behaviour
    +selectSingle?: true,
    +preventEmptySelection?: boolean,
    +selectableFilter?: (C) => boolean,
    // initially used to override orbit object pending status
    +isPending?: (item: C) => boolean,
    // selection shortcut builder, or else uses the default
    +shortcutBuilder?: ?SelectionShortcutBuilder<C>,
    // on the 'list' pages, bulk delete/unmap/etc actions
    +multiItemActions?: $ReadOnlyArray<ListAction>,
}

type ResourceSelectorResourceKinds =
    'image' | 'server_group' | 'server' | 'api_client' |
    'cloud_ip' | 'volume' | 'account' | 'application' |
    'collaboration' | 'load_balancer' | 'database_server' | 'database_snapshot'
;

export type ResourceSelectorProps<C: BbCollectedResourceTypes> = {
    +selector?: ItemSelectOptions<C>,

    +kind: ResourceSelectorResourceKinds,
    +sortFields: SortFields<C>,
    +searchDef: SearchDefinition<C>,
    +searchComponent: React$ComponentType<{ +editor: SearchEditorHook }>,
    +searchIds?: boolean,
    +listGrid?: ListGridHook,
    +context?: TableContext,

    +table?: React$ComponentType<ResourceSelectorTableProps<C>>,
    +render?: (props: ResourceSelectorTableProps<C>) => React$Node,

    +itemsFilter?: (item: C) => boolean,
    +disableDefaultGroup?: React$Node,
    +children?: React$Node,
    +addButton?: $PropertyType<PanelSearchBarProps, 'addButton'>,
    +addConfirmDialog?: $PropertyType<PanelSearchBarProps, 'addConfirmDialog'>,

    confirmDialog?: ?DialogState<null>,

    +leftButton?: ButtonProps,
    +leftButtonTooltip?: ?$Diff<$Shape<TooltipProps>, { children: React$Node }>,
    +newSet?: Set<string>,
}

export function amendSelection<C: ItemSelectTypes>(nextSelected: Set<string>, allItems: $ReadOnlyArray<C>, filter: ?(C) => boolean, selected: boolean) {
    let toProcess: Array<C> = allItems.filter(x => !filter || filter(x));
    while(toProcess.length) {
        const item = toProcess.pop();
        if (selected) nextSelected.add(item.id);
        else nextSelected.delete(item.id);

        if (Array.isArray(item.children)) {
            // $FlowFixMe C will only ever be OrbitTreeNode in this case, so the filter would work...
            toProcess.push(...item.children.filter(x => !filter || filter(x)));
        }
    }
}

function buildSelectionShortcuts<C: ItemSelectTypes>(allItems: $ReadOnlyArray<C>, items: $ReadOnlyArray<C>, filter: ?(C) => boolean, setSelected: (SetOrFn) => void): $ReadOnlyArray<MultiSelectChoice> {
    // FIXME for flip containers, should always be true
    const unknownMax = false;
    // FIXME for flip containers, should always be true
    const paged = items.length < allItems.length;

    const allSelectableLength = allItems.filter(x => !filter || filter(x)).length;
    const pageSelectableLength = items.reduce((acc, x) => !filter || filter(x) ? acc + 1 : acc, 0);

    let res: Array<MultiSelectChoice> = [];

    if (unknownMax !== true) {
        res.unshift(            {
            children: `Select all (${allSelectableLength} items)`,
            onClick: () => {
                setSelected((selected) => {
                    let nextSelected = new Set(selected);
                    amendSelection(nextSelected, allItems, filter, true);
                    return nextSelected;
                });
            },
        });
    }

    if (paged && pageSelectableLength > 0) {
        res.unshift(
            {
                children: `Select all on page (${pageSelectableLength} items)`,
                onClick: () => setSelected(new Set(items.filter(x => !filter || filter(x)).map(x => x.id))),
            },
        );
    }

    res.push({
        children: 'Select none',
        onClick: () => setSelected(new Set())
    });

    return res;
}

export function useItemSelect<C: ItemSelectTypes>(allItems: $ReadOnlyArray<C>, pageItems: $ReadOnlyArray<C>, selector: ?ItemSelectOptions<C>, context: TableContext): ?ItemSelectHook<C> {
    const editorValue = selector?.editor?.value;
    const setEditorValue = selector?.editor?.setValue;
    const filter = selector?.selectableFilter;

    const [selectedValues, setSelectedValues] = useState<[?$ReadOnlyArray<string>, Set<string>]>([editorValue, new Set(editorValue || [])]);
    const [actionQueue, currentActionId] = useSelector<CloudGuiState, [Set<string>, ?string]>(
        (g: CloudGuiState) => [new Set(g.ActionQueue.actions.map(x => x.id)), g.ActionQueue.currentId]
    );

    const [, selected] = selectedValues;

    useEffect(() => {
        if (editorValue !== selectedValues[0]) {
            setSelectedValues([editorValue, new Set(editorValue)]);
        }
    }, [editorValue, selectedValues])

    const setSelected = useCallback((next: SetOrFn) => {
        if (typeof next === 'function') {
            setSelectedValues(([, selected]) => {
                const nextSet = next(selected);
                const nextEditorValue = [...nextSet.values()];

                if (setEditorValue) setEditorValue(nextEditorValue);

                return [nextEditorValue, nextSet];
            });
        } else {
            const nextEditorValue = [...next.values()];
            setSelectedValues([nextEditorValue, next]);
            if (setEditorValue) setEditorValue(nextEditorValue);
        }
    }, [setSelectedValues, setEditorValue]);

    const isSelected = useCallback((item: { +id: string, ... } | { +id: ObjectId, ... }) => selected.has(item.id), [selected]);

    const [lastChangedIndex, setLastChangedIndex] = useState<number | null>(null);
    useEffect(() => setLastChangedIndex(null), [pageItems]);

    useEffect(() => {
        const h = () => selector?.editor.setValue([]);
        pushEscHandler(h);
        return () => removeEscHandler(h);
        // only run this on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedLength = selected.size;

    const multiIcon = selectedLength > 0 && selectedLength !== allItems.length
        ? 'minus'
        : 'tick'
    ;

    // handle clicking the table header checkbox
    function onHeaderCheckboxChange(nextSelected: boolean) {
        if (nextSelected) {
            let nextSelected = new Set();
            amendSelection(nextSelected, pageItems, filter, true);
            setSelected(nextSelected);
        } else {
            const entirePageSelected = pageItems.filter(x => !filter || filter(x)).findIndex(x => !selected.has(x.id)) === -1;
            if (entirePageSelected) setSelected(new Set());
            else {
                let nextSelected = new Set(selected);
                amendSelection(nextSelected, pageItems, filter, true);
                setSelected(nextSelected);
            }
        }
    }

    const builder = selector?.shortcutBuilder || buildSelectionShortcuts;
    let selectionShortcuts = useMemo(
        () => builder(allItems, pageItems, filter, setSelected),
        [allItems, pageItems, filter, setSelected, builder]
    );


    function setItemSelected(item: C, nextSelected: boolean, event: MouseEvent) {
        const index = pageItems.findIndex(x => x === item);
        if (index !== -1) {
            if (nextSelected && event.shiftKey && lastChangedIndex !== null && lastChangedIndex !== index && !selector?.selectSingle) {
                let nextSet = new Set(selected);
                const dir = lastChangedIndex < index ? -1 : 1;

                for (let l = index; l >= 0 && l < pageItems.length && l !== lastChangedIndex; l += dir) {
                    amendSelection(nextSet, [pageItems[l]], filter, nextSelected);
                }

                setSelected(nextSet);
            } else {
                if (!(selector?.preventEmptySelection && selected.has(item.id) && selected.size === 1)) {
                    let nextSet = selector?.selectSingle ? new Set() : new Set(selected);
                    amendSelection(nextSet, [item], filter, nextSelected);
                    setSelected(nextSet);
                }
            }
            setLastChangedIndex(index);
        }
    }

    if (selector == null) return null;

    return {
        selectedLength,
        isSelected,
        setSelected: setItemSelected,
        itemHoverVisible: context === 'list' && selectedLength === 0,
        multi: !selector.selectSingle
            ? {
                headerCheckbox: {
                    color: 'blue',
                    checked: selectedLength > 0,
                    hoverVisible: context === 'list' && selectedLength === 0,
                    onChange: (next) => onHeaderCheckboxChange(next),
                    icon: multiIcon,
                },
                selectionShortcuts,
                itemActions: selector.multiItemActions,
                isPending: selector.isPending || ((x: C) => {
                    const id = x.id;
                    return actionQueue.has(id) || currentActionId === id
                }),
            }
            : null,
        selectable: (x: C) => !filter || filter(x),
        rowProps: (x: C, overrides?: $Shape<TrProps>): $Shape<TrProps> => ({
            selected: selected.has(x.id),
            selectable: selectedLength > 0,
            disabled: filter && !filter(x),
            onClick: selector.selectSingle || selectedLength > 0 ? (event) => setItemSelected(x, (!filter || filter(x)) && !selected.has(x.id), event) : null,
            ...overrides,
        }),
    };
}

export function ResourceSelector<C: BbCollectedResourceTypes>({
    kind, selector, table: Table, render, sortFields, searchDef, searchComponent: Search,
    itemsFilter, children, listGrid: lgProp, addButton, addConfirmDialog, context,
    disableDefaultGroup, searchIds,
    confirmDialog,
    leftButton, leftButtonTooltip, newSet
}: ResourceSelectorProps<C>): React$Node {
    const sort = useMemo<ListSortDef<C>>(() => ({ name: kind + 'Select', fields: sortFields }), [kind, sortFields]);
    const list = useListResource<C>(kind, sort);
    const { items: listItems } = list;
    const rawItems = useMemo(() => itemsFilter ? listItems.filter(itemsFilter) : listItems, [itemsFilter, listItems]);
    const search = useResourceSearch(searchDef, rawItems);
    const { editor: searchEditor, items: searchedItems } = search;
    const pager = usePager(kind + 'Selector', searchedItems, rawItems.length);
    const lgLocal = useSelectorListGrid(context === 'list' ? 'list' : 'grid');
    useSearchOnResourceId(kind, searchIds ? search.searchTerm : null);

    const listGrid = lgProp || lgLocal;

    const itemSelect = useItemSelect(rawItems, pager.items, selector, context || '');
    const editor = selector?.editor;

    const onSave = confirmDialog != null
        ? confirmDialog.show
        : (editor?.status === 'edit' ?  editor.onSave : null);

    const labels = LABELS[kind];

    return (
        <>
             <PanelSearchBar
                search={<Search editor={searchEditor}/>}
                addButton={addButton}
                addConfirmDialog={addConfirmDialog}
                mode={context === 'list' ? null : listGrid}
            />

            {list.status === RC_ERROR
                ? <PanelApiErrorBar {...labels} />
                : null
            }

            {listGrid[0] === 'grid' && list.status !== RC_ERROR
                ? <PanelBar>
                    {children}
                    <NoMatchingResourceNotice search={search} kind={kind} />
                    <NoResourcesNotice
                        status={list.status}
                        totalCount={rawItems.length}
                        context={context || 'list'}
                        kind={kind}
                    />

                    <CardGroup>
                        {!isShowDetailsResourceStatus(list.status)
                            ? <SkeletonCardPanel/>
                            : pager.items.map((item) => {
                                if (disableDefaultGroup && item.resource_type === 'server_group' && item.default) {
                                    return (
                                        <Tooltip overlay={disableDefaultGroup}>
                                            <Card
                                                key={item.id}
                                                disabled={true}
                                                selectable={true}
                                                selected={false}
                                            >
                                                <ResourceChip resource={item}/>
                                            </Card>
                                        </Tooltip>
                                    );
                                }
                                return (
                                    <Card
                                        key={item.id}
                                        selectable={true}
                                        {...(itemSelect
                                            ? ({
                                                selected: itemSelect.isSelected(item),
                                                onClick: (event) => itemSelect.setSelected(item, !itemSelect.isSelected(item), event),
                                            })
                                            : null
                                        )}
                                    >
                                        <ResourceChip resource={item} isNew={newSet?.has(item.id)} />
                                    </Card>
                                );
                            })}
                    </CardGroup>
                </PanelBar>
                : null
            }
            {listGrid[0] === 'list' && list.status !== RC_ERROR
                ? <PanelBar padding={false}>
                    {children}
                    {Table
                        ? <Table
                            items={pager.items}
                            Th={list.Th}
                            search={search}
                            status={list.status}
                            totalCount={rawItems.length}
                            context={context || 'list'}
                            itemSelect={itemSelect}
                        />
                        : null
                    }
                    {render
                        ? render({
                            items: pager.items,
                            Th: list.Th,
                            search,
                            status: list.status,
                            totalCount: rawItems.length,
                            context: context || 'list',
                            itemSelect,
                        })
                        : null
                    }
                </PanelBar>
                : null}

            <PagerBar {...pager.pager} />

            {editor && editor.status !== 'add'
                ? <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: onSave ? () => onSave() : null}}
                    leftButton={leftButton}
                    leftButtonTooltip={leftButtonTooltip}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                >
                    {isShowDetailsResourceStatus(list.status) && !selector?.selectSingle
                        ? <SelectionDropdown selectionShortcuts={itemSelect?.multi?.selectionShortcuts || []} selected={itemSelect?.selectedLength || 0} />
                        : null
                    }
                </PanelButtonBar>
                : null}
            {editor?.status === 'add' && selector && !selector?.selectSingle && context !== 'list'
                ? <PanelBar className='c-panel__section--button-bar'>
                    {isShowDetailsResourceStatus(list.status)
                        ? <SelectionDropdown selectionShortcuts={itemSelect?.multi?.selectionShortcuts || []} selected={itemSelect?.selectedLength || 0} />
                        : null
                    }
                    <div className='c-panel__section--button-bar__spacer'>&nbsp;</div>
                </PanelBar>
                : null
            }

        </>
    );
}