// @flow

import { useCallback, useEffect, useMemo } from "react";
import { Table, Td, Th, Tr } from '../../../element/Table';
import { NoResourcesTr } from '../../../element/NoResourceMessages';
import {
    useAccessControlOptions,
    useFetchAccessControlResources
} from "../../../hoc/AccessControl";
import { DbsAccessControlTableRow, SET_TO_DEFAULT_SERVER_GROUP } from "./DbsAccessControlTableRow";
import { Panel, PanelBar, PanelButtonBar, PanelHeader } from '../../../element/Panel';
import { stringToTarget } from '../../../../api/adapter';
import { isShowDetailsResourceStatus, SkeletonBar, SkeletonListLoadingEntries } from '../../../element/Skeleton';

import type { Editor, } from '../../../common/Editor';
import type { FormErrors } from '../../../common/lib';
import type { BbDatabaseAccessRule, BbDatabaseServer } from '../../../../api/type.dbs';
import type { ResourceCacheStatus } from '../../../../state/resource/type';
import type { DbsAccessControlRuleEdit } from "./DbsAccessControlTableRow";
import type { AccessControlInclude, AccessResourcesHook } from "../../../hoc/AccessControl";

export type DbsAccessControlEditProps = {
    +editor: Editor<$ReadOnlyArray<DbsAccessControlRuleEdit>, FormErrors, BbDatabaseServer>,
    +cacheStatus: ResourceCacheStatus,
};

export const DEFAULT_ACCESS: DbsAccessControlRuleEdit = {
    source: { kind: 'grp', value: SET_TO_DEFAULT_SERVER_GROUP, },
    description: '',
};

export const editAccess = (accesses: $ReadOnlyArray<BbDatabaseAccessRule>): $ReadOnlyArray<DbsAccessControlRuleEdit> =>
    accesses && accesses.length
        ? accesses.reduce((acc: Array<DbsAccessControlRuleEdit>, { source, description }: BbDatabaseAccessRule): Array<DbsAccessControlRuleEdit> => {
            if (source == null) return acc;
            acc.push({ source: stringToTarget(source), description });
            return acc;
        }, [])
        : [ { ...DEFAULT_ACCESS } ]
;

const DB_ACCESS_INCLUDE: AccessControlInclude = {
    any: true,
    server: true,
    serverGroup: true,
    address: true,
    unknown: true,
    clientIp: true,
};

function useDbsAcrRulePatch(
    acr: AccessResourcesHook,
    rules: $ReadOnlyArray<DbsAccessControlRuleEdit>,
    onChange: (idx: number, value: DbsAccessControlRuleEdit) => void,
): void {
    const { processing, options,  } = acr;

    useEffect(() => {
        if (!processing) {
            // some grp- rules could be cross-account rules. change them to 'other' so they are a text input rather
            // than the current-account-groups-dropdown, which won't have the group in it.
            const accountGroupIds = new Set(options['grp'].map(x => x.value));

            // when creating a dbs, we don't necessarily have the groups cached, so wait for them to
            // be ready, then if we're a SET_TO_DEFAULT_SERVER_GROUP, set it.
            const defaultGroup = options['grp'].find(grp => grp.label === 'default');

            rules.forEach((rule, idx) => {
                if (rule.source.kind === 'grp' && !accountGroupIds.has(rule.source.value)) {
                    onChange(idx, { ...rule, source: { ...rule.source, kind: 'other', } })
                }
                if (rule.source.value === SET_TO_DEFAULT_SERVER_GROUP && defaultGroup) {
                    onChange(idx, { ...rule, source: { kind: 'grp', value: defaultGroup.value } })
                }
            });
       }
    }, [processing, rules, options, onChange]);
}

export const DbsAccessControlEdit = ({ editor, cacheStatus, }: DbsAccessControlEditProps): React$Node => {
    const { setValue: setEditAccess, value: rawValue } = editor;
    const { cacheStatus: targetCacheStatus } = useFetchAccessControlResources();
    const editAccess = useMemo(() =>
        isShowDetailsResourceStatus(targetCacheStatus) && Array.isArray(rawValue)
            ? rawValue
            : [],
        [targetCacheStatus, rawValue]
    );

    const setEditAccessValue = useCallback(
        (idx: number, value: DbsAccessControlRuleEdit) => {
            setEditAccess([].concat(
                idx ? editAccess.slice(0, idx) : [],
                value,
                editAccess.slice(idx + 1),
            ));
        },
        [setEditAccess, editAccess]
    );

    const addAccess = () => {
        editor.setValue([].concat(editAccess, DEFAULT_ACCESS));
    };

    const removeAccess = (idx: number) => {
        editor.setValue([].concat(
            idx ? editAccess.slice(0, idx) : [],
            editAccess.slice(idx + 1),
        ));
    };

    const acr = useAccessControlOptions(DB_ACCESS_INCLUDE);
    useDbsAcrRulePatch(acr, editAccess, setEditAccessValue);

    return (
        <Panel>
            <PanelHeader
                title='Access control'
                description='Allow traffic to and from this Cloud SQL instance (you can change this later)'
            />

            <PanelBar padding={false}>
                <Table editor={true}>
                    <thead>
                        <Tr>
                            <Th>Source Type</Th>
                            <Th>Source</Th>
                            <Th stretch={true}>Description</Th>
                            <Th>&nbsp;</Th>
                        </Tr>
                    </thead>
                    <tbody>
                    {!isShowDetailsResourceStatus([cacheStatus, targetCacheStatus])
                        ? <SkeletonListLoadingEntries>
                            <Tr>
                                <Td><SkeletonBar size='lg'/></Td>
                                <Td><SkeletonBar size='lg'/></Td>
                                <Td><SkeletonBar size='lg'/></Td>
                                <Td><SkeletonBar size='sm'/></Td>
                            </Tr>
                        </SkeletonListLoadingEntries>
                        : editAccess.map((thisAccess, idx: number) => (
                            <DbsAccessControlTableRow
                                key={idx}
                                item={thisAccess}
                                errorText={editor.errors?.get('addr_' + idx)}
                                onChange={value => { setEditAccessValue(idx, value) }}
                                onRemove={() => removeAccess(idx)}
                                accessControlResources={acr}
                            />
                        )
                    )}
                    </tbody>
                    <NoResourcesTr
                        totalCount={editAccess.length}
                        status={[cacheStatus, targetCacheStatus]}
                        kind='dbs_access'
                        colSpan={3}
                        context=''
                    />
                </Table>
            </PanelBar>
            {editor.status !== 'add'
                ? <PanelButtonBar
                    cacheStatus={editor.messages?.status}
                    primaryButton={{onClick: editor.status === 'edit' ? editor.onSave : null}}
                    cancel={editor.status === 'edit' ? editor.onCancel : null}
                    leftButton={{ children: 'Add Access Rule', kind: 'bare', preIcon: 'add-fill', onClick: addAccess }}
                />
                : <PanelButtonBar
                    leftButton={{ children: 'Add Access Rule', kind: 'bare', preIcon: 'add-fill', onClick: addAccess }}
                />}
        </Panel>
    );
};

