// @flow

import { useMemo, useContext } from 'react';
import { useOrbitContainers } from '../../hoc/Orbit';
import { useObjectToSortedList } from '../../hoc/ListPage';
import { orbitSortFields, orbitContainerSearchDef } from '../orbit/def';
import { useResourceSearch } from '../../element/Search';
import { usePager } from '../../element/ArrayPager';
import { motion } from 'framer-motion';
import { pageVariants, pageTransition, animationRouteContext } from '../../element/animation';
import { Page } from '../../element/Page';
import { ResourceListTitle } from '../../element/ResourceListPage';
import { Panel, PanelSearchBar, PanelBar } from '../../element/Panel';
import { TextOnlySearchEditor } from '../../common/TextOnlySearch';
import { Table, Tr, Th, Td } from '../../element/Table';
import { NoResourcesTr, NoMatchingResourceTr } from '../../element/NoResourceMessages';
import { Chip } from '../../element/Chip';
import { formatOrbitSize, formatDateTime, RightArrow } from '../../element/Styled';
import { Link } from 'react-router-dom';
import { isShowDetailsResourceStatus, SkeletonListLoadingEntries, SkeletonChip, SkeletonBar } from '../../element/Skeleton';
import { PagerBar } from '../../element/PagerBar';
import { useAccountStatusCreateOverlays } from '../../hoc/orbit/lib';

import type { BbOrbitContainer } from '../../../api/type.orbit';
import type { ListSortDef } from '../../hoc/ListPage';

const containerRegistrySortDef: ListSortDef<BbOrbitContainer> = {
    name: 'OrbitContainersList',
    fields: orbitSortFields,
}
export function ContainerRegistryList(): React$Node {
    const [, , sectionAnim,] = useContext(animationRouteContext);
    const { containers, fetched } = useOrbitContainers();
    const [totalCount, registries] = useMemo(
        () => {
            const registries = Object.keys(containers).filter(x => x.endsWith('_ctrimages'));

            return [
                registries.length,
                registries.reduce((acc, x) => {
                    acc[x] = containers[x];
                    return acc;
                }, ({}))
            ];
        },
        [containers]
    );
    const { items: sortedItems, Th: SortTh } = useObjectToSortedList<BbOrbitContainer>(containerRegistrySortDef, registries);
    const resourceSearch = useResourceSearch(orbitContainerSearchDef, sortedItems);
    const { items } = resourceSearch;
    const pager = usePager('OrbitContainersList', items, items.length);
    const limitOverlay = useAccountStatusCreateOverlays('Repository');

    return (
        <motion.div
            style={{ width: '100%' }}
            {...sectionAnim}
            variants={pageVariants}
            transition={pageTransition}
        >
            <Page>
                <ResourceListTitle
                    resourceName={'Repository'}
                    title={'Docker Registry'}
                    limitOverlay={limitOverlay}
                />

                <Panel>
                    <PanelSearchBar
                        search={<TextOnlySearchEditor editor={resourceSearch.editor}/>}
                    />
                    <PanelBar padding={false}>
                        <Table>
                            <thead>
                            <Tr>
                                <SortTh field='name'>Name</SortTh>
                                <SortTh field='bytes'>Size</SortTh>
                                <SortTh field='last_modified'>Last Modified</SortTh>
                                <Th actions={true}>&nbsp;</Th>
                            </Tr>
                            </thead>

                            <tbody>
                            {pager.items.map((container, name) =>
                                container
                                    ? <Tr key={name}>
                                        <Td resourceName={true}>
                                            <Chip
                                                name={container.name.substr(0, container.name.length - 10)}
                                                id={formatOrbitSize(container.bytes)}
                                                icon='registry'
                                                isImagesContainer={container.name === 'images'}
                                                link={`container/${container.name}/`}
                                            />
                                        </Td>
                                        <Td>{formatOrbitSize(container.bytes)}</Td>
                                        <Td>{formatDateTime(container.last_modified)}</Td>
                                        <Td actions={true}>
                                            <Link to={`container/${container.name}/`}>
                                                <RightArrow />
                                            </Link>
                                        </Td>
                                    </Tr>
                                    : <Tr key={name}>
                                        <Td>{name}</Td>
                                        <Td>&nbsp;</Td>
                                        <Td>&nbsp;</Td>
                                        <Td actions={true}>
                                            <Link to={`container/${name}/`}>
                                                <RightArrow />
                                            </Link>
                                        </Td>
                                    </Tr>
                            )}
                            {!isShowDetailsResourceStatus(fetched)
                                ? <SkeletonListLoadingEntries>
                                    <Tr>
                                        <Td resourceName={true}><SkeletonChip /></Td>
                                        <Td><SkeletonBar /></Td>
                                        <Td><SkeletonBar /></Td>
                                        <Td actions={true}>&nbsp;</Td>
                                    </Tr>
                                </SkeletonListLoadingEntries>
                                : null
                            }
                            <NoMatchingResourceTr kind='repository' search={resourceSearch} colSpan={4} />
                            <NoResourcesTr kind='repository' status={fetched} colSpan={4} totalCount={totalCount} context='list' />
                            </tbody>
                        </Table>
                    </PanelBar>
                    <PagerBar {...pager.pager} />
                </Panel>
            </Page>
        </motion.div>
    )
}