// @flow

import type { BuildState, BuildingResource, OrbitBulkDeletion } from './type';
import type { StoreClearAction } from '../type';
import type { AuthSetSelectedAccount } from '../auth/type';
import type { ResourceAddFull } from '../resource/type';
import type { BbFullResourceTypes, BbCollectedResourceTypes, } from '../../api/type';
import type { ActionQueueAddOrbitObjects, ActionQueueRemoveOrbitObjects } from '../ActionQueue/type';

const emptyState: BuildState = {
    building: new Map<string, BuildingResource>(),
    deleting: new Map<string, OrbitBulkDeletion>(),
};

type BuildReducerAction = StoreClearAction | AuthSetSelectedAccount
    | ResourceAddFull<BbFullResourceTypes, BbCollectedResourceTypes>
    | ActionQueueAddOrbitObjects | ActionQueueRemoveOrbitObjects
;


export const BuildReducer = (
    domain: BuildState = emptyState,
    action: BuildReducerAction,
): BuildState => {
    switch(action.type) {
    case 'STORE_CLEAR':
        return emptyState;
    case 'AUTH_SELECT_ACCOUNT':
        return emptyState;
    case 'RESOURCE_ADD_FULL':
        const { full } = action.payload;
        const inBuilding = domain.building.get(full.id);
        const status = (full.status || '');

        if (inBuilding || status === 'creating') {
            return {
                ...domain,
                building: new Map([
                    ...domain.building,
                    [
                        action.payload.full.id,
                        {
                            id: action.payload.full.id,
                            resource_type: action.payload.kind,
                            status,
                        },
                    ]
                ])
            };
        }
        break;
    case 'ACTION_QUEUE_ADD_ORBIT_OBJECTS':
        let addCurr = domain.deleting.get(action.payload.container);

        const baseCount = addCurr?.count || 0;
        return {
            ...domain,
            deleting: new Map([
                ...domain.deleting,
                [
                    action.payload.container,
                    {
                        id: action.payload.container,
                        count: baseCount + action.payload.objects.length,
                        completed: addCurr?.completed || 0,
                    }
                ]
            ]),
        }
    case 'ACTION_QUEUE_REMOVE_ORBIT_OBJECTS':
        let curr = domain.deleting.get(action.payload.container);
        if (curr == null) {
            return domain;
        }

        let completed = curr.completed + action.payload.objects.length;
        if (completed === curr.count) {
            const deleting = new Map([...domain.deleting]);
            deleting.delete(action.payload.container);
            return {
                ...domain,
                deleting,
            }
        } else {
            return {
                ...domain,
                deleting: new Map([
                    ...domain.deleting,
                    [
                        action.payload.container,
                        {
                            ...curr,
                            completed,
                        }
                    ]
                ]),
            }
        }
    default:
        // eslint-disable-next-line no-unused-expressions
        void (action: empty);
    }

    return domain;
};
